import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

// styled
import { WrapPopupEditPassport } from "./index.style";

// data
import {
  dataGender,
  dataPassportStatus,
} from "../../../../../../../../../../../data/dataCustomer";

// images
import IcClose from "../../../../../../../../../../../assets/images/IcClose";
import IcTransPhotoDefault from "../../../../../../../../../../../assets/images/IcTransPhotoDefault";
import IcUploadWhite from "../../../../../../../../../../../assets/images/IcUploadWhite";

// component
import InputNameInfo from "../ControlPopup/InputNameInfo";
import DatePickerItemEdit from "../ControlPopup/DatePickerItemEdit";
import InputDropdownGender from "../ControlPopup/InputDropdownGender";
import InputNumberID from "../ControlPopup/InputNumberID";
import InputPlaceOfIssue from "../ControlPopup/InputPlaceOfIssue";
import Button from "../../../../../../../../../../Control/Button";
import InputLocation from "../ControlPopup/InputLocation";
import InputNumberIDPassport from "../ControlPopup/InputNumberIDPasport";
import InputHometown from "../ControlPopup/InputHometown";

// redux
import { getListEkycHistoryByCustomer, updatePassportByCustomer } from "../../../../../../../../../../../redux/customer/customerDetail/action";
import { ERROR_INPUT_VALIDATE } from "../../../../../../../../../../../constant/keys";
import { useOnClickOutside } from "../../../../../../../../../../../hook/useClickOutside";


const PopupEditInfoPASSPORT = ({ item, details, onClickClosePopup }) => {
  const refPop = useRef()
  const [isErrCustomerPhoto, setIsErrCustomerPhoto] = useState(false);
  const dispatch = useDispatch()

  const [data, setData] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      uid: details.id,
      cardType: item.ekycType,
      id: item.ekycInfoPassport.id,
      dueDate: item.ekycInfoPassport.due_date,
      issueDate: item.ekycInfoPassport.issue_date,
      issuedAt: item.ekycInfoPassport.issued_at,
      name: item.ekycInfoPassport.name,
      dob: item.ekycInfoPassport.dob,
      gender: item.ekycInfoPassport.gender,
      nationality: item.ekycInfoPassport.nationality,
      placeOfBirth: item.ekycInfoPassport.place_of_birth,
      personNumber: item.ekycInfoPassport.person_number
    }
  );

  const handleSetValueName = (val) => {
    setData({ name: val });
  };

  const handleSetValueNumberID = (val) => {
    setData({ id: val });
  };

  const handleSetValueNumberIDPassport = (val) => {
    setData({ personNumber: val });
  };

  const handleSetValueHomeTown = (val) => {
    setData({ issuedAt: val });
  };

  const handleSetValuePlaceOfIssue = (val) => {
    setData({ placeOfBirth: val });
  };

  const handleSetValueLocation = (val) => {
    setData({ nationality: val });
  };

  const handleSetValueDropdown = (val) => {
    setData({ gender: val.key });
  };

  
  const handleValidationInput = (value, callback) => {
    if (!value?.trim() || 0 === value.length) {
      callback(ERROR_INPUT_VALIDATE);
      return;
    }
  };
  
  const callbackSuccess = () => {
    dispatch(getListEkycHistoryByCustomer({ uid: details.id }))
  }

  const handleSubmit = () => {
    const {
      uid,
      cardType,
      id,
      dueDate,
      issueDate,
      issuedAt,
      name,
      dob,
      gender,
      nationality,
      placeOfBirth,
      personNumber
    } = data;
    const dataRequest = {
      uid,
      cardType,
      id,
      dueDate,
      issueDate,
      issuedAt,
      name,
      dob,
      gender,
      nationality,
      placeOfBirth,
      personNumber
    };

    if(!data.id || !data.name || !data.placeOfBirth || !data.issuedAt || !data.personNumber || !data.nationality ) return
    
    dispatch(updatePassportByCustomer({
      ...dataRequest,
      dob: moment(moment(dob, 'DD/MM/YYYY').toDate()).format('DD/MM/YYYY'),
      issueDate: moment(moment(issueDate, 'DD/MM/YYYY').toDate()).format('DD/MM/YYYY'),
      dueDate: moment(moment(dueDate, 'DD/MM/YYYY').toDate()).format('DD/MM/YYYY')
    }, callbackSuccess))

    onClickClosePopup();
  };

  useEffect(() => {
    setIsErrCustomerPhoto(false);
  }, [item.img]);

  useOnClickOutside(refPop, ()=>onClickClosePopup())
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      onClickClosePopup();
    }
});

  return (
    <WrapPopupEditPassport ref={refPop}>
      <div className="popup-header">
        <div className="popup-title">
          Tùy chỉnh giấy tờ
          <span className="title-value">
            {dataPassportStatus[data.cardType]?.text || "-"}
          </span>
        </div>
        <IcClose className="icon" onClick={onClickClosePopup} />
      </div>
      <div className="info-title">Hình ảnh giấy tờ</div>
      <div className="popup-img">
        <div className="info-img">
          <div className="img-value">Mặt trước</div>
          {isErrCustomerPhoto ? (
            <div className="wrapper">
              <IcTransPhotoDefault />
            </div>
          ) : (
              <img
                src={item.images.frontUrl || ""}
                alt="face kyc"
                onError={() => setIsErrCustomerPhoto(true)}
              />
            )}
        </div>
      </div>
      <div className="info-title">Thông tin cá nhân</div>
      <div className="popup-info">
        <div className="info-container">
          <div className="info-item">
            <div className="info-text">Họ tên</div>
            <InputNameInfo
              className="info-input"
              handleSetValueName={handleSetValueName}
              value={data.name}
              handleValidationUser={handleValidationInput}
            />
          </div>
          <div className="info-item">
            <div className="info-text">Ngày sinh</div>
            <DatePickerItemEdit
                selected={moment(data?.dob, 'DD/MM/YYYY').toDate()}
                onChange={(date) =>
                  setData({
                    dob: date
                      ? moment(date).startOf("day").toDate()
                      : data?.dob,
                  })
                }
                dateFormat="dd/MM/yyyy"
                showTimeSelect={false}
            />
          </div>
          <div className="info-item">
            <div className="info-text">Giới tính</div>
            <InputDropdownGender
              valueDropdown={handleSetValueDropdown}
              listDropDown={Object.values(dataGender)}
              objChoice={data.gender.toUpperCase()}
              className="input-dropdown"
            />
          </div>
        </div>
        <div className="info-container">
          <div className="info-item">
            <div className="info-text">Quốc tịch</div>
            <InputLocation
              className="info-input-item"
              handleSetValueName={handleSetValueLocation}
              value={data.nationality}
              handleValidationUser={handleValidationInput}
            />
          </div>
          <div className="info-item">
            <div className="info-text">Nơi sinh</div>
            <InputPlaceOfIssue
              className="info-input-item"
              handleSetValueName={handleSetValuePlaceOfIssue}
              value={data.placeOfBirth}
              handleValidationUser={handleValidationInput}
            />
          </div>
        </div>
      </div>
      <div className="info-title">Thông tin giấy tờ</div>
      <div className="info-ekyc">
        <div className="info-container">
          <div className="info-item">
            <div className="info-text">Số Passport</div>
            <InputNumberID
              className="info-input-item"
              handleSetValueName={handleSetValueNumberID}
              value={data.id}
              handleValidationUser={handleValidationInput}
            />
          </div>
          <div className="info-item">
            <div className="info-text">Ngày cấp</div>
            <DatePickerItemEdit
                selected={moment(data?.issueDate, 'DD/MM/YYYY').toDate()}
                onChange={(date) =>
                  setData({
                    issueDate: date
                      ? moment(date).startOf("day").toDate()
                      : data?.issueDate,
                  })
                }
                dateFormat="dd/MM/yyyy"
                showTimeSelect={false}
              />
          </div>
        </div>
        <div className="info-container">
          <div className="info-item">
            <div className="info-text">Nơi cấp</div>
            <InputHometown
              className="info-input"
              handleSetValueName={handleSetValueHomeTown}
              value={data.issuedAt}
              handleValidationUser={handleValidationInput}
            />
          </div>
        </div>
      </div>
      <div className="info-ekyc">
        <div className="info-container">
          <div className="info-item">
            <div className="info-text">Ngày hết hạn</div>
            <DatePickerItemEdit
                selected={moment(data?.dueDate, 'DD/MM/YYYY').toDate()}
                onChange={(date) =>
                  setData({
                    dueDate: date
                      ? moment(date).startOf("day").toDate()
                      : data?.dueDate,
                  })
                }
                dateFormat="dd/MM/yyyy"
                showTimeSelect={false}
              />
          </div>
          <div className="info-item">
            <div className="info-text">Số giấy tờ tuỳ thân</div>
            <InputNumberIDPassport
              className="info-input-item"
              handleSetValueName={handleSetValueNumberIDPassport}
              value={data.personNumber}
              handleValidationUser={handleValidationInput}
            />
          </div>
        </div>
      </div>
      <div className="info-btn">
        <Button
          text="Lưu lại"
          iconBtn={<IcUploadWhite />}
          className="btn-save"
          onClick={handleSubmit}
        />
      </div>
    </WrapPopupEditPassport>
  );
};

export default PopupEditInfoPASSPORT;
