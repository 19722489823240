import React from 'react'

const IcLockWhiteMini = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7258 8.32422H4.27578C3.5302 8.32422 2.92578 8.92863 2.92578 9.67422V14.3992C2.92578 15.1448 3.5302 15.7492 4.27578 15.7492H13.7258C14.4714 15.7492 15.0758 15.1448 15.0758 14.3992V9.67422C15.0758 8.92863 14.4714 8.32422 13.7258 8.32422Z" fill="white" />
            <path d="M5.625 8.325V5.625C5.625 4.72989 5.98058 3.87145 6.61351 3.23851C7.24645 2.60558 8.10489 2.25 9 2.25C9.89511 2.25 10.7536 2.60558 11.3865 3.23851C12.0194 3.87145 12.375 4.72989 12.375 5.625V8.325" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcLockWhiteMini
