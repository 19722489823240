import styled from 'styled-components'

const WrapSelectDate = styled.button`
    outline: none;
    border: none;
    background: none;
    width: 100%;
    text-align: left;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;

    &.have-value {
        color: #313131;
    }

    &.empty {
        color: #33ADFF;
    }

    &:hover {
        cursor: pointer;
    }
`;

const WrapDatePickerItem = styled.div`
    .react-datepicker-popper[data-placement^=bottom] {
        padding-top: 25px;
        z-index: 10;
    }
`

export {
    WrapSelectDate,
    WrapDatePickerItem
}