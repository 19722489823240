import React from 'react'

const IcNoDataDevice = (props) => {
    return (
        <svg {...props} width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="70" cy="70" r="70" fill="#B7B7B7"/>
        <path d="M86.667 36.668H53.3337C49.6518 36.668 46.667 39.6527 46.667 43.3346V96.668C46.667 100.35 49.6518 103.335 53.3337 103.335H86.667C90.3489 103.335 93.3337 100.35 93.3337 96.668V43.3346C93.3337 39.6527 90.3489 36.668 86.667 36.668Z" fill="#B7B7B7"/>
        <path d="M70 90H70.0333" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcNoDataDevice
