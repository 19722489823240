import styled from 'styled-components'
import { FilterControlStore } from '../../Page/TransactionV2/Bank/FilterTransactionBank/index.styles';

const WrapSelectStatus = styled.div`
    flex-grow: 1;
    margin-left: 21px;
`;

const WrapInputSearchDevice = styled.div`
    display: flex;
    .input-search-device{
        width: 432px;
        height: 36px;
    }
`

const FilterControlStoreDevice = styled(FilterControlStore)`
    width: 100%;
    border: none;
    outline: none;
    padding: 9px 0;

`


export {
    WrapSelectStatus,
    WrapInputSearchDevice,
    FilterControlStoreDevice
}