import React from 'react'

const IcDeviceBgBlue = (props) => {
    return (
        <svg {...props} width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5703_47873)">
        <circle cx="18" cy="18.5" r="18" fill="#EFF9FF"/>
        <path d="M22.2498 10H13.7498C12.8109 10 12.0498 10.7611 12.0498 11.7V25.3C12.0498 26.2389 12.8109 27 13.7498 27H22.2498C23.1887 27 23.9498 26.2389 23.9498 25.3V11.7C23.9498 10.7611 23.1887 10 22.2498 10Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 23.6016H18.0085" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_5703_47873">
        <rect width="36" height="36" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default IcDeviceBgBlue
