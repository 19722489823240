import React, { useEffect, useState } from "react";

// component
import IcFacePrintDefault from "../../../../../../assets/images/IcFacePrintDefault";
import IcPlayCircle from "../../../../../../assets/images/IcPlayCircle";
import IcTransPhotoDefault from "../../../../../../assets/images/IcTransPhotoDefault";
import IcTransVerifyVideo from "../../../../../../assets/images/IcTransVerifyVideo";
import IcZoomIn from "../../../../../../assets/images/IcZoomIn";
import { convertDateTime } from "../../../../../../helper/convertDateTime";
import Portal from "../../../../../Control/Portal";
import PopupShowMedia, {
  IMAGE,
  VIDEO,
} from "../../TransactionHistory/TransHistoryItem/PopupShowMedia";

//host
import {
  apiSupplierLogo,
  apiTransactionPhoto,
  apiTransactionVerifyVideo,
} from "../../../../../../constant/host";

// styles
import { WrapCustomerItem } from "./index.styles";
import IcMerchantLogoDefault from "../../../../../../assets/images/IcMerchantLogoDefault";

const CustomerItem = ({ data, isExpandByDefault = false }) => {
  const [showMedia, setShowMedia] = useState(null);
  const [isErrCustomerLogo, setIsErrCustomerLogo] = useState(false);
  const [isErrCustomerPhoto, setIsErrCustomerPhoto] = useState(false);
  const [isErrChannel, setIsErrChannel] = useState(false);

  useEffect(() => {
    setIsErrCustomerLogo(false);
  }, [data?.id]);

  useEffect(() => {
    setIsErrCustomerPhoto(false);
  }, [data?.id]);

  const _handleZoomPhoto = () => {
    if (isErrCustomerPhoto) return;
    setShowMedia({
      type: IMAGE,
      url: apiTransactionPhoto(data.id),
    });
  };

  const _handleZoomVideo = () => {
    if (isErrCustomerPhoto) return;
    setShowMedia({
      type: VIDEO,
      url: apiTransactionVerifyVideo(data.id),
    });
  };

  const gender = data?.gender === 0 ? "Nam" : "Nữ";

  return (
    <WrapCustomerItem isExpandByDefault={isExpandByDefault}>
      <div className="wrap-infos">
        <div className="general-infos">
          <div className="left-infos">
            <div className="wrap-id-trans">
              <span className="id-name">Profile ID:</span>
              <span className="id-value" title={data?.profileId || ""}>
                {data?.profileId || "Null"}
              </span>
            </div>
            <div className="wrap-id-trans">
              <span className="id-name">Biometirc ID:</span>
              <span className="id-value" title={data?.id || ""}>
                {data?.id || "Null"}
              </span>
            </div>
            <div className="wrap-id-trans">
              <span className="id-name">Kênh khách hàng:</span>
              <span className="id-value" title={data?.supplierShortName || ""}>
                {data?.supplierShortName || "Null"}
              </span>
            </div>
          </div>
          <div
            className="payment-time"
            title={
              data?.createAt
                ? convertDateTime(data.createAt, "HH:mm - DD/MM/YYYY")
                : ""
            }
          >
            {data?.createAt
              ? convertDateTime(data.createAt, "HH:mm - DD/MM/YYYY")
              : "Null"}
          </div>
        </div>
      </div>
      <div className="wrap-details">
        <div className="wrap-infos payment-location-infos">
          <div className="title">Thông tin khách hàng</div>
          <div className="row payment-location-row">
            <div className="payment-location">
              <div className="wrap-logo">
                {isErrCustomerLogo ? (
                  <IcFacePrintDefault />
                ) : (
                  <img
                    className="logo"
                    src={data?.id ? apiTransactionPhoto(data.id) : ""}
                    alt="logo"
                    onError={() => setIsErrCustomerLogo(true)}
                  />
                )}
              </div>
              <div className="wrap-location-infos">
                <div className="name" title={data?.fullname || ""}>
                  {data?.fullname || "-"}
                </div>
                <div className="address">Họ tên</div>
              </div>
            </div>
            <div className="payment-value">
              <div className="wrap-logo">
                {isErrChannel ? (
                  <IcMerchantLogoDefault className="logo" />
                ) : (
                  <img
                    className="logo"
                    src={data?.supplier ? apiSupplierLogo(data.supplier) : ""}
                    alt="logo"
                    onError={() => setIsErrChannel(true)}
                  />
                )}
              </div>
              <div className="value-item">
                <div
                  className="value-bank"
                  title={data?.supplierShortName || ""}
                >
                  {data?.supplierShortName || "-"}
                </div>
                <div className="unit">Ngân hàng</div>
              </div>
              <div className="value-item">
                <div className="value" title={data?.dob ? data?.dob : ""}>
                  {data?.dob || "-"}
                </div>
                <div className="unit">Ngày tháng năm sinh</div>
              </div>
              <div className="value-item">
                <div
                  className="value"
                  title={data?.phoneNumber ? data?.phoneNumber : ""}
                >
                  {data?.phoneNumber || "-"}
                </div>
                <div className="unit">Số điện thoại</div>
              </div>
              <div className="value-item">
                <div className="value" title={data?.email ? data?.email : ""}>
                  {data?.email || "-"}
                </div>
                <div className="unit">Email đăng ký</div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap-infos face-recognize-infos">
          <div className="title">
            <p>Hình ảnh đăng ký</p>
          </div>
          <div className="row face-recognize">
            <div className="wrap-media face-img" onClick={_handleZoomPhoto}>
              {isErrCustomerPhoto ? (
                <div className="wrapper">
                  <IcTransPhotoDefault />
                </div>
              ) : (
                <img
                  src={data?.id ? apiTransactionPhoto(data.id) : ""}
                  alt="face recognize"
                  onError={() => setIsErrCustomerPhoto(true)}
                />
              )}
              <div className="tools">
                <IcZoomIn />
              </div>
            </div>
            <div className="wrap-media recog-video" onClick={_handleZoomVideo}>
              {isErrCustomerPhoto ? (
                <div className="wrapper">
                  <IcTransVerifyVideo />
                </div>
              ) : (
                <>
                  <img
                    src={data?.id ? apiTransactionPhoto(data.id) : ""}
                    alt="video recognize"
                    onError={() => setIsErrCustomerPhoto(true)}
                  />
                  <div className="overlay">
                    <IcPlayCircle />
                  </div>
                </>
              )}
            </div>
            {!!showMedia && (
              <Portal>
                <PopupShowMedia
                  mediaType={showMedia.type}
                  url={showMedia.url}
                  closePopup={() => setShowMedia(null)}
                />
              </Portal>
            )}
          </div>
        </div>
      </div>
    </WrapCustomerItem>
  );
};

export default CustomerItem;
