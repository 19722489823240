import React from 'react'

const IcBankBIDV = (props) => {
    return (
        <svg {...props} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="15.9609" y="0.460938" width="22.6274" height="22.6274" transform="rotate(45 15.9609 0.460938)" fill="white"/>
        <path d="M13.4966 6.44531L13.4941 18.9569H16.1084L18.5051 18.9594L18.5075 6.44776L16.1084 6.44531" fill="#EC2227"/>
        <path d="M10.748 8.9375H13.2523V16.5126H10.748V8.9375ZM20.1047 16.5126L18.7523 16.5151L18.7548 8.93995H20.1096C21.484 8.93995 22.5307 10.6394 22.538 12.7238C22.5527 14.7642 21.484 16.5126 20.1047 16.5126Z" fill="#214497"/>
        <path d="M15.9963 31.47L15.989 31.4725L1.0149 16.4988L15.9963 1.52261L15.9988 1.52751L30.9802 16.5061L15.9963 31.47ZM0 16.4988L0.132062 16.6337L15.3996 31.8992L15.9988 32.5L16.5979 31.8992L32 16.5037L16.0037 0.5" fill="#EC2227"/>
        <path d="M15.9959 23.722L8.77665 16.5H1.68945L14.1666 28.9797L14.2987 29.1146L15.9959 30.8091L30.3097 16.5H23.2201" fill="#EC2227"/>
        </svg>

    )
}

export default IcBankBIDV
