import * as types from './type'

import { dataSwiftCodeBankFacepay } from '../../../constant/dataSwiftCodeBank'
import { dataSelectionTime } from '../../../components/Main/Content/Page/TransactionV2/Bank/FilterTransactionBank/DropdownFilterTime'
import { dataSelectionFacepayTransaction } from '../../../components/Main/Content/Page/TransactionV2/Bank/FilterTransactionBank/DropdownFilterBank'
import { dataSelectCheckbox, dataSelectCheckBoxExportFacepay } from '../../../data/dataSelectCheckbox'
import { currentDay, startDay } from '../listTransactionBank/listTransactionBankReducer'
// import { dataSelectionFacepayTransaction } from '../../../components/Main/Content/Page/TransactionV2/FilterTransaction/DropdownFilterPacepayTerminal'

const intitialState = {
    listTransactionFacepay: [],
    condFiltersTransactionFacepay: {
        limit: 15,
        lastItemId: "",
        bankTrxCode: "",
        facepayTrxCode: "",
        merchantTrxCode: "",
        phoneNumber: "",
        profileId: "",
        code: "",
        storeId: ["*"],
        OptionSelectCheckbox : dataSelectCheckbox,
        supplier: dataSwiftCodeBankFacepay,
        begin: startDay,
        end: currentDay,
        OptionSelect: dataSelectionFacepayTransaction[0],
        OptionSelectTime: dataSelectionTime[0],
    },

    condFiltersExportTransactionFacepay: {
        isFacepay: true,
        begin: startDay,
        end: currentDay,
        OptionSelectCheckboxExportFacepay: dataSelectCheckBoxExportFacepay
    },

    totalFacepay: {
        totalAmount: 0,
        totalFailed: 0,
        totalSuccess: 0,
        totalTransaction: 0
    }
}

const listTransactionFacepayReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_LIST_TRANSACTION_PACEPAY_TERMINAL:
            return {
                ...state,
                listTransactionFacepay: action.payload.isScroll ? [...state.listTransactionFacepay, ...action.payload.listTransactionFacepay] : action.payload.listTransactionFacepay,
                totalFacepay: action.payload.totalFacepay
            }
        case types.UPDATE_CONDFILTERS_TRANSACTION_FACEPAY:
                return {
                    ...state,
                    condFiltersTransactionFacepay: {
                        ...state.condFiltersTransactionFacepay,
                        ...action.condFiltersTransactionFacepay
                    }
                }
        case types.RESET_CONDFILTERS_TRANSACTION_FACEPAY: 
                return {
                    ...state,
                    condFiltersTransactionFacepay: intitialState.condFiltersTransactionFacepay
                }
        default:
            return state
    }
}

export default listTransactionFacepayReducer