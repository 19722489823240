import styled from 'styled-components'

const WrapDropdownControl = styled.div`
    position: relative;
    z-index: 1;
    min-width: 98px;
    margin-left: 6px;

    .wrap-title-dropdown {
        display: flex;
        align-items: center;
        .value {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #313131;
            flex-grow: 1;
        }
    
        .arrow-down {
            margin-left: 6px;
        }
    }

    &:hover {
        cursor: pointer;
    }
`;

export {
    WrapDropdownControl
}