import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

//styles
import { WrapUserAnalytics } from './index.styles'

// helper
import { FACEPAY_PORTAL_LOGIN } from '../../../../../../../helper/localStore'
import checkTimeTransactionComplete from '../../../../../../../helper/checkTimeTransactionComplete';
import numberRoundToTwo from '../../../../../../../helper/numberRoundToTwo'
import numberFormatToThousand from '../../../../../../../helper/numberFormatToThousand'

// action
import { getCustomerAnalytic, getCustomerBankAnalytic, resetCustomerAnalytic } from '../../../../../../../redux/customer/customerAnalytic/action';
import { LOADING_CUSTOMER_ANALYTIC } from '../../../../../../../redux/customer/customerAnalytic/types';

// control
import LoadingLocal from '../../../../../../Control/LoadingLocal'
import { BANK, FACEPAY } from '../../../../../../../constant/keys';
import { resetListCustomerBankBacklog, resetListCustomerFacepayBacklog, resetUpdateListBacklogCustomerCondsFilter } from '../../../../../../../redux/customer/customerBacklog/action';
import { resetListTransactionBankOfCustomer, resetTransactionOfCustomerCondsFilter } from '../../../../../../../redux/customer/customerTransactionHistory/actionV2';
import ChartPercentageV2, {legendsV2, listColorsV2} from './ChartPercentageV2';

const adminUser = JSON.parse(localStorage.getItem(FACEPAY_PORTAL_LOGIN))

export default function UserAnalytics() {

  const dispatch = useDispatch()
  const [dataSelected, setDataSelected] = useState([])

  const { details, dataAnalytic, reqType, isLoading } = useSelector((state) => ({
    details: state.customerReducer.customerDetailReducer.details,
    dataAnalytic: state.customerReducer.customerAnalyticReducer.dataAnalytic,
    reqType: state.customerReducer.listCustomerReducer.reqType,

    isLoading: state.loading[LOADING_CUSTOMER_ANALYTIC],
  }))

  useEffect(() => {
    dispatch(resetUpdateListBacklogCustomerCondsFilter())
    dispatch(resetTransactionOfCustomerCondsFilter())
    
    dispatch(resetListCustomerFacepayBacklog())
    dispatch(resetListCustomerBankBacklog())
    dispatch(resetListTransactionBankOfCustomer())
  }, [])
  
  useEffect(() => {

    switch (reqType) {
      case FACEPAY:
        if(details?.id){
          dispatch(getCustomerAnalytic({
            uid: details?.id,
            adminId: adminUser?.uuid,
            adminUserName: adminUser?.username
          }))
        }
        break;
    
      case BANK:
        if(details?.id){
          dispatch(getCustomerBankAnalytic({
            bioId: details?.id,
            adminId: adminUser?.uuid,
            adminUserName: adminUser?.username
          }))
        }
        break;
    }

  },[details])

  useEffect(() => {
    const dataPercent = dataAnalytic?.analyzeTransactionPercent?.map((item) => numberRoundToTwo(item.percent))
    setDataSelected(dataPercent)
  }, [dataAnalytic])
  
  return (
    <WrapUserAnalytics>
      {
        isLoading ? <LoadingLocal /> : (
          <>
            <div className="wrap-content">
              <p className="wrap-title top">Giao dịch</p>
              <div className="wrap-transaction">
                <div className="content" title="Thời gian trung bình&#13;&#10;khách hàng quay lại&#13;&#10;sử dụng dịch vụ">
                  <p className="value">{dataAnalytic?.averageBetweenTwoTransaction > 100 ? ">100" : numberRoundToTwo(dataAnalytic?.averageBetweenTwoTransaction) || 0} ngày</p>
                  <p className="text frist">TG trung bình giữa 2 giao dịch liên tiếp</p>
                </div>
                <div className="content" title="Thời gian trung bình từ&#13;&#10;khi bắt đầu 1 giao dịch&#13;&#10;đến khi kết thúc 1 giao&#13;&#10;dịch của tất cả giao dịch&#13;&#10;thành công.">
                  <p className="value">{dataAnalytic?.averagePaymentTime ? checkTimeTransactionComplete(dataAnalytic?.averagePaymentTime) : '00:00'}</p>
                  <p className="text">Thời gian GD trung bình</p>
                </div>
                <div className="content" title="Tổng số tiền khách hàng&#13;&#10;đã thanh toán">
                  <p className="value">{dataAnalytic?.totalAmount ? numberFormatToThousand(dataAnalytic?.totalAmount) : 0}</p>
                  <p className="text">Tổng số tiền (vnđ)</p>
                </div>
                <div className="content" title="Thời gian giao dịch&#13;&#10;gần nhất">
                  <p className="value">{numberRoundToTwo(dataAnalytic?.sinceLastPayment) || 0} ngày</p>
                  <p className="text">Thời gian gần nhất</p>
                </div>
                <div className="content" title="Số tiền trung bình&#13;&#10;tổng số đơn hàng">
                  <p className="value">{dataAnalytic?.averageMoneyTransaction ? numberFormatToThousand(Math.floor(dataAnalytic?.averageMoneyTransaction)) : 0}</p>
                  <p className="text">Trung bình đơn hàng (vnđ)</p>
                </div>
              </div>
            </div>
            <div className="wrap-content">
              <p className="wrap-title">Phân tích giao dịch</p>
              <div className="wrap-chart-container">
                <ChartPercentageV2
                  listPercentage={dataSelected || []}
                />
                <div className="wrap-chart-legend">
                  {
                    legendsV2.map((legend, index) => (
                        <div className="legend" key={index}>
                          {!dataSelected ? <div className="legend-percent">-</div> : <div className='legend-percent'>{dataSelected[index]}%</div>}
                          <div className="legent-value">
                            <span style={{ background: listColorsV2[index] }} />
                            <span>{legend}</span>
                          </div>
                        </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </>
        )
      }
    </WrapUserAnalytics>
  )
}
