import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapPopupApproved } from './index.styles'

// images
import IcClose from '../../../../../../../assets/images/IcClose'
import IcConfirm from '../../../../../../../assets/images/IcConfirm'
import IcCloseOutlineGray from '../../../../../../../assets/images/IcCloseOutlineGray'

// Control
import Button from '../../../../../../Control/Button'

// redux
import { businessApproved, getBusinessDetail, getListBusiness } from '../../../../../../../redux/business/listBusinessManage/action'

// hoook
import { useOnClickOutside } from '../../../../../../../hook/useClickOutside'

const PopupApproved = ({ businessInfo, onClickClosePopupDetails, onClickClosePopupApprove }) => {

    const dispatch = useDispatch()
    const refPopup = useRef()

    const { condFiltersBusiness } = useSelector((state) => ({
        condFiltersBusiness: state.businessReducer.listBusinessManage.condFiltersBusiness,
    }))

    const dataRequest = (condFiltersBusiness, isScroll, listBussiness) => {
        const checkIsSuccessAction = condFiltersBusiness.optionSelectCheckboxBusiness.reduce((a, v) => ({ ...a, [v.key]: v.isSelect }), {})
        return {
            ...checkIsSuccessAction,
            isDenied: false,
            businessCode: condFiltersBusiness.businessCode,
            phoneNumber: condFiltersBusiness.phoneNumber,
            ownerName: condFiltersBusiness.ownerName,
            businessName: condFiltersBusiness.businessName,
            begin: condFiltersBusiness.begin,
            end: condFiltersBusiness.end,
            limit: condFiltersBusiness.limit,
            lastItem: isScroll ? listBussiness.at(-1).id : condFiltersBusiness.lastItem,
        }
    }

    const handleClickApprove = () => {
        const callbackSucess = () => {
            dispatch(getBusinessDetail({ businessID: businessInfo.id }))
            dispatch(getListBusiness(dataRequest(condFiltersBusiness, false), false))
            onClickClosePopupDetails()
        }
        dispatch(businessApproved({ businessID: businessInfo.id }, callbackSucess))
    }

    useOnClickOutside(refPopup, () => onClickClosePopupApprove())


    return (
        <WrapPopupApproved ref={refPopup}>
            <div className='wrap-header'>
                <div className='wrap-header-text'>
                    Xác nhận phê duyệt
                </div>
                <IcClose className='wrap-header-icon' onClick={onClickClosePopupApprove} />
            </div>
            <div className='wrap-content'>
                <div className='wrap-content-value'>
                    Bạn vui lòng xác nhận phê duyệt hồ sơ: <span>{businessInfo.businessCode}</span>
                </div>
                <div className='wrap-content-warning'>
                    *Thông tin phê duyệt sẽ không thể chỉnh sửa
                </div>
            </div>
            <div className='wrap-btn'>
                <Button
                    onClick={handleClickApprove}
                    iconBtn={<IcConfirm />}
                    className='btn-approved'
                    text="Phê duyệt"
                />
                <Button
                    onClick={onClickClosePopupApprove}
                    iconBtn={<IcCloseOutlineGray />}
                    className='btn-cancel'
                    text="Hủy bỏ"
                />
            </div>
        </WrapPopupApproved>
    )
}

export default PopupApproved