import React from 'react'

const IcDeviceStatusActive = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#33ADFF"/>
        <g clipPath="url(#clip0_5703_47865)">
        <path d="M11.6665 7.22266H13.3331C13.6979 7.22266 14.0591 7.29451 14.3961 7.4341C14.7331 7.5737 15.0394 7.77831 15.2973 8.03625C15.5552 8.29419 15.7599 8.60041 15.8994 8.93742C16.039 9.27444 16.1109 9.63565 16.1109 10.0004C16.1109 10.3652 16.039 10.7264 15.8994 11.0634C15.7599 11.4005 15.5552 11.7067 15.2973 11.9646C15.0394 12.2226 14.7331 12.4272 14.3961 12.5668C14.0591 12.7064 13.6979 12.7782 13.3331 12.7782H11.6665M8.33312 12.7782H6.66645C6.30167 12.7782 5.94046 12.7064 5.60344 12.5668C5.26642 12.4272 4.9602 12.2226 4.70226 11.9646C4.18133 11.4437 3.88867 10.7371 3.88867 10.0004C3.88867 9.26372 4.18133 8.55718 4.70226 8.03625C5.2232 7.51531 5.92974 7.22266 6.66645 7.22266H8.33312" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.77734 10H12.2218" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_5703_47865">
        <rect width="13.3333" height="13.3333" fill="white" transform="translate(3.33301 3.33203)"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default IcDeviceStatusActive
