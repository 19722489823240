import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// css
import { WrapComfirmRefund } from "./index.styles";
import { OverlayFullScreen } from "../../../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles";

// image
import IcConfirm from '../../../../../../../../../../../assets/images/IcConfirm' 
import IcCheckBoxActive from "../../../../../../../../../../../assets/images/IcCheckBoxActive";
import IcClose from "../../../../../../../../../../../assets/images/IcClose";
import { transactionRefund } from "../../../../../../../../../../../redux/transactionV2/transactionOther/action";

// component
import Button from "../../../../../../../../../../Control/Button";

// hook
import { useOnClickOutside } from "../../../../../../../../../../../hook/useClickOutside";

// redux
import { getListTransactionBank } from "../../../../../../../../../../../redux/transactionV2/listTransactionBank/action";
import { CUSTOMER, TRANSACTION } from "../../../../../../../../../../../constant/path";
import { getListTransactionBankOfCustomer, getListTransactionFacepayOfCustomer } from "../../../../../../../../../../../redux/customer/customerTransactionHistory/actionV2";
import { BANK, FACEPAYTERMINAL, WALLET_FACEPAY } from "../../../../../../../TransactionV2";
import { getListTransactionFacepay } from "../../../../../../../../../../../redux/transactionV2/listTransactionFacepayTerminal/action";
import { getListTransactionFacepayWallet } from "../../../../../../../../../../../redux/transactionV2/listTransactionWalletFacepay/action";

const PopupConfirmApprove = ({ dataTransacion, onClickClosePopupComfirm, detailsTrans, reqType }) => {

  const refPopup = useRef(null)
  const dispatch = useDispatch()

  const { condFilters, isScroll, condFiltersTransactionBank, condFiltersTransactionFacepay, condFiltersTransactionFacepayWallet } = useSelector((state) => ({
    // BANK
    condFiltersTransactionBank: state.transactionReducer.listTransactionBankReducer.condFiltersTransactionBank,

    // FacepayTerminal
    condFiltersTransactionFacepay: state.transactionReducer.listTransactionFacepayReducer.condFiltersTransactionFacepay, 

    // WalletFacepay
    condFiltersTransactionFacepayWallet: state.transactionReducer.listTransactionFacepayWalletReducer.condFiltersTransactionFacepayWallet,

    // Customer TransactionHistory
    condFilters: state.customerReducer.customerTransactionHistoryReducer.condFilters,
    isScroll: state.customerReducer.customerTransactionHistoryReducer.isScroll,

  }));

  const dataRequestTransactionBank = (condFiltersTransactionBank) => {
    const checkIsSuccessCheckbox = condFiltersTransactionBank.OptionSelectCheckbox.reduce((a, v) => ({...a, [v.key] : v.isSelect}),{})
    
    return {
      ...checkIsSuccessCheckbox,
      limit: condFiltersTransactionBank.limit,
      lastItemId: condFiltersTransactionBank.lastItemId,
      facepayTrxCode: condFiltersTransactionBank.facepayTrxCode,
      bankTrxCode: condFiltersTransactionBank.bankTrxCode,
      merchantTrxCode: condFiltersTransactionBank.merchantTrxCode,
      profileId: condFiltersTransactionBank.profileId,
      phoneNumber: condFiltersTransactionBank.phoneNumber,
      code: condFiltersTransactionBank.code,
      storeId: condFiltersTransactionBank.storeId,
      supplier: condFiltersTransactionBank.supplier.filter((item) => item.isSelect).map((item) => item.key),
      begin: condFiltersTransactionBank.begin,
      end: condFiltersTransactionBank.end,
    }
  }

  const dataRequestTransactionFacepay = (condFiltersTransactionFacepay) => {
    const checkIsSuccessCheckbox = condFiltersTransactionFacepay.OptionSelectCheckbox.reduce((a, v) => ({...a, [v.key] : v.isSelect}),{})
    return {
      ...checkIsSuccessCheckbox,
      limit:  condFiltersTransactionFacepay.limit,
      lastItemId: condFiltersTransactionFacepay.lastItemId,
      facepayTrxCode: condFiltersTransactionFacepay.facepayTrxCode,
      bankTrxCode: condFiltersTransactionFacepay.bankTrxCode,
      merchantTrxCode: condFiltersTransactionFacepay.merchantTrxCode,
      profileId: condFiltersTransactionFacepay.profileId,
      phoneNumber: condFiltersTransactionFacepay.phoneNumber,
      code: condFiltersTransactionFacepay.code,
      storeId: condFiltersTransactionFacepay.storeId,
      supplier: condFiltersTransactionFacepay.supplier.filter((item) => item.isSelect).map((item) => item.key),
      begin: condFiltersTransactionFacepay.begin,
      end: condFiltersTransactionFacepay.end,
    }
  }

  const dataRequestFacepayWallet = (condFiltersTransactionFacepayWallet) => {
    const checkIsSuccessCheckboxOption = condFiltersTransactionFacepayWallet.OptionSelectCheckbox.reduce((a, v) => ({...a, [v.key] : v.isSelect}), {})
    const checkIsSuccessCheckboxTypeTransaction = condFiltersTransactionFacepayWallet.OptionSelectCheckboxTypeTransaction.reduce((a, v) => ({...a, [v.key] : v.isSelect}), {})
    return {
      ...checkIsSuccessCheckboxOption,
      ...checkIsSuccessCheckboxTypeTransaction,
      limit: condFiltersTransactionFacepayWallet.limit,
      lastItem: condFiltersTransactionFacepayWallet.lastItem,
      paymeTxID: condFiltersTransactionFacepayWallet.paymeTxID,
      facepayTrxCode: condFiltersTransactionFacepayWallet.facepayTrxCode,
      phoneNumber: condFiltersTransactionFacepayWallet.phoneNumber,
      profileId: condFiltersTransactionFacepayWallet.profileId,
      begin: condFiltersTransactionFacepayWallet.begin,
      end: condFiltersTransactionFacepayWallet.end,
    }
  }

  const _handleRefund = () => {
    dispatch(transactionRefund({
        supplier: dataTransacion.supplier,
        trxCode: dataTransacion.facepayTransCode,
        remark: "", 
        terminalId: ""
    }))
    onClickClosePopupComfirm()
    switch (detailsTrans) {
      case CUSTOMER:
        if (condFilters.bioId) {
          dispatch(getListTransactionBankOfCustomer(
            {
              bioId: condFilters.bioId,
              facepayTrxCode: condFilters.facepayTrxCode,
              begin: condFilters.begin,
              end: condFilters.end,
              isSuccess: condFilters.isSuccess,
              isFail: condFilters.isFail,
              limit: condFilters.limit,
              skip: condFilters.skip
            }, isScroll))
            return
        }
        if(condFilters.uid){
          dispatch(getListTransactionFacepayOfCustomer(
            {
              uid: condFilters.uid,
              supplier: condFilters.supplier,
              facepayTrxCode: condFilters.facepayTrxCode,
              begin: condFilters.begin,
              end: condFilters.end,
              isSuccess: condFilters.isSuccess,
              isFail: condFilters.isFail,
              limit: condFilters.limit,
              skip: condFilters.skip
            }, isScroll))
            return
        }
        break;
    
      case TRANSACTION:
        if(reqType === FACEPAYTERMINAL){
          dispatch(getListTransactionFacepay(dataRequestTransactionFacepay(condFiltersTransactionFacepay), false))
          return 
        }
        if(reqType === BANK){
          dispatch(getListTransactionBank(dataRequestTransactionBank(condFiltersTransactionBank), false))
          return
        }
        if(reqType === WALLET_FACEPAY){
          dispatch(getListTransactionFacepayWallet(dataRequestFacepayWallet(condFiltersTransactionFacepayWallet), false))
          return
        }
        break;

      default:
        return null
    }
  }

  useOnClickOutside(refPopup, ()=> onClickClosePopupComfirm())
  
  return (
    <OverlayFullScreen>
      <WrapComfirmRefund ref={refPopup}>
        <div className="icon-close">
          <IcClose onClick={onClickClosePopupComfirm}/>
        </div>
        <div className="comfirm-title">Xác nhận hoàn tiền</div>
          <div className="confirm-value">
            Bạn vui lòng xác nhận hoàn tiền giao dịch <span>{`"${dataTransacion.facepayTransCode}"`}</span>
          </div>
          <Button
            iconBtn={<IcCheckBoxActive />}
            type="submit"
            text="Xác nhận"
            className="btn-approved"
            onClick={_handleRefund}
          />
          <Button
            iconBtn={<IcClose />}
            type="submit"
            text="Hủy bỏ"
            className="btn-cancel"
            onClick={onClickClosePopupComfirm}
          />
      </WrapComfirmRefund>
    </OverlayFullScreen>
  );
};

export default PopupConfirmApprove;
