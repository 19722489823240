import React from 'react'

const IcRefreshCircle = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={16} height={16} rx={4} fill="#33ADFF" />
            <g clipPath="url(#clip0_10029_9122)">
                <path d="M12.5835 11.3334V8.83337H10.0835" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.4165 4.66663V7.16663H5.9165" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.5373 6.74999C11.326 6.15282 10.9669 5.61891 10.4934 5.19808C10.0199 4.77725 9.44756 4.48323 8.82972 4.34343C8.21188 4.20364 7.56869 4.22263 6.96017 4.39865C6.35166 4.57466 5.79765 4.90195 5.34984 5.34999L3.4165 7.16666M12.5832 8.83332L10.6498 10.65C10.202 11.098 9.64801 11.4253 9.0395 11.6013C8.43098 11.7773 7.7878 11.7963 7.16996 11.6565C6.55211 11.5168 5.97975 11.2227 5.50628 10.8019C5.03281 10.3811 4.67366 9.84716 4.46234 9.24999" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_10029_9122">
                    <rect width={10} height={10} fill="white" transform="translate(3 3)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcRefreshCircle