import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//style
import { WrapBusinessProfile } from './index.styles'

//image
import IcMinimize from '../../../../../../../assets/images/IcMinimize'
import IcMaximize from '../../../../../../../assets/images/IcMaximize'
import IcBusinessCode from '../../../../../../../assets/images/IcBusinessCode'
import IcBusinessAvatar from '../../../../../../../assets/images/IcBussinessAvatar'
import IcBusinessEmail from '../../../../../../../assets/images/IcBusinessEmail'
import IcBusinessStore from '../../../../../../../assets/images/IcBusinessStore'
import IcBusinessReferral from '../../../../../../../assets/images/IcBusinessReferral'
import IcBusinessAdd from '../../../../../../../assets/images/IcBusinessAdd'
import IcBusinessPhone from '../../../../../../../assets/images/IcBusinessPhone'
import IcBusinessIdentify from '../../../../../../../assets/images/IcBusinessIdentify'
import IcBusinessTime from '../../../../../../../assets/images/IcBusinessTime'
import IcRefreshBlue from '../../../../../../../assets/images/IcRefeshBlue'
import IcNotifyErrorRed from '../../../../../../../assets/images/IcNotifyErrRed'
import IcArrowRight from '../../../../../../../assets/images/IcArrowRight'
import IcExportFile from '../../../../../../../assets/images/IcExportFile'
import IcRefreshCircle from '../../../../../../../assets/images/IcRefreshCircle'

//component
import Button from '../../../../../../Control/Button'
import downloadFileByLink from '../../../../../../../helper/downloadFileByLink'
import { convertDateTime } from '../../../../../../../helper/convertDateTime'
import IcLicenseUrlDefault from '../../../../../../../assets/images/IcLicenseUrlDefault'
import IcLogoMerchantDefault from '../../../../../../../assets/images/IcLogoMerchantDefault'
import IcAvararDefault from '../../../../../../../assets/images/IcAvatarDefault'
import { REQUIRE_LICENSE_UPDATE } from '../../../../../../../constant/keys'
//action
import { businessRequestUpdate, resetListBacklogBusiness, setIsShowPopupUpdateRequest } from '../../../../../../../redux/business/listBusinessManage/action'
import IcField from '../../../../../../../assets/images/IcField'
import IcInfoGray from '../../../../../../../assets/images/IcInfoGray'
import IcFilePdf from '../../../../../../../assets/images/IcFilePdf'
import IcFileWord from '../../../../../../../assets/images/IcFileWord'

const BussinessProfile = () => {

    const dispatch = useDispatch()

    const { businessInfo, userInfo } = useSelector((state) => ({
        businessInfo: state.businessReducer.listBusinessManage.businessDetail.businessInfo,
        userInfo: state.businessReducer.listBusinessManage.businessDetail.userInfo,
    }))

    const [licenseList, setLicenseList] = useState([])
    const [ekycList, setEkycList] = useState([])
    const [isExpand, setIsExpand] = useState(false)
    const [ObjSelect, setObjSelect] = useState({})
    const [isErrImgBusiness, setIsErrImgBusiness] = useState(false)
    const [isErrImgAvatar, setIsErrImgAvatar] = useState(false)

    useEffect(() => {
        dispatch(resetListBacklogBusiness())
    }, [])

    useEffect(() => {
        setIsErrImgBusiness(false)
    }, [businessInfo.businessLogo])

    useEffect(() => {
        setIsErrImgAvatar(false)
    }, [userInfo.thumbnailUrl])

    useEffect(() => {
        let licenseArr = []
        if (businessInfo?.licenseFile?.length > 0) {
            licenseArr = businessInfo.licenseFile.map((item) => {
                return item
            })
        }
        let ekycArr = []
        if (userInfo?.ekycInfo?.ekycUrl?.length > 0) {
            ekycArr = userInfo.ekycInfo.ekycUrl.map((item) => {
                return {
                    type: "IMG",
                    url: item,
                }
            })
        }
        setLicenseList(licenseArr)
        setEkycList(ekycArr)
        // if (licenseArr.length !== 0 || ekycArr.length !== 0) {
        //     setObjSelect(
        //         [...licenseArr[0], ...ekycArr][0])
        // }
        if (licenseArr.length !== 0 && licenseArr[0].length !== 0) {
            setObjSelect(licenseArr[0][0])
        }
        else if(ekycArr.length !== 0){
            setObjSelect(ekycArr[0])
        }
    }, [
        businessInfo,
        businessInfo.licenseFile,
        userInfo,
        userInfo.ekycInfo,
        userInfo.ekycInfo.ekycUrl,
    ])

    const _handleRequestUpdate = () => {
        dispatch(setIsShowPopupUpdateRequest({ isShowPopupUpdateRequest: true }))
    }

    const _handleSelectImage = (item) => {
        setObjSelect(item)
    }

    const _handleExportFile = () => {
        const data = ObjSelect.url ? ObjSelect.url : ekycList[0].url
        downloadFileByLink(data, false)
    }

    const _handleSetIsExpand = (item) => {
        setIsExpand(true)
        _handleSelectImage(item)
    }

    return (
        <WrapBusinessProfile
            className={businessInfo.censorshipReason.length !== 0 ? "is-reason" : ""}>
            {
                businessInfo.censorshipReason.length !== 0 &&
                <div className="wrap-list-reason">
                    <div className='wrap-list'>
                        <IcNotifyErrorRed className="error-icon" />
                        <div className="errro-text">
                            {`${businessInfo.censorshipReason.length} lý do từ chối`}
                        </div>
                    </div>
                    <IcArrowRight
                        className='ic-arrow' />
                    <div className='wrap-reason'>
                        {
                            businessInfo.censorshipReason.map((item, index) => (
                                <div key={index} className='reason'>
                                    {item}
                                </div>

                            ))
                        }
                    </div>
                </div>
            }
            <div className={isExpand ? 'wrap-content expand' : 'wrap-content'}>
                <div className='wrap-identify'>
                    <div className='wrap-title'>
                        <p>Giấy tờ cá nhân</p>
                        {
                            isExpand ?
                                <IcMinimize
                                    className="icon-expand"
                                    onClick={() => setIsExpand(false)} />
                                :
                                <IcMaximize
                                    className="icon-expand"
                                    onClick={() => setIsExpand(true)} />
                        }
                    </div>
                    <div className={isExpand ? 'wrap-list-personal-papers expand' : 'wrap-list-personal-papers'}>
                        {
                            isExpand && (licenseList.length !== 0 || ekycList.length !== 0) ?
                                ObjSelect.type === "IMG" ?
                                    < img
                                        className={businessInfo.censorshipReason.length !== 0 ? 'img-select reason' : 'img-select'}
                                        src={ObjSelect.url} alt="image" />
                                    : ObjSelect.type === "IMAGE" ?
                                        <>
                                            < img
                                                className={businessInfo.censorshipReason.length !== 0 ? 'img-select reason' : 'img-select'}
                                                src={ObjSelect.url} alt="image" />
                                            <div className='wrap-icon-info'>
                                                <IcInfoGray />.
                                                <div className='tool-tip'>
                                                    <p>{ObjSelect.fileName}</p>
                                                    <p>{`Ngày tải lên: ${convertDateTime(ObjSelect.uploadedAt, "HH:mm - DD/MM/YYYY")}`}</p>
                                                </div>
                                            </div>
                                        </>
                                        : ObjSelect.type === "PDF" ?
                                            <>
                                                <iframe
                                                    className={businessInfo.censorshipReason.length !== 0 ? 'img-select reason' : 'img-select'}
                                                    src={`${ObjSelect.url}#toolbar=0`}
                                                    width="176px" height="176px">
                                                </iframe>
                                                <div className='wrap-icon-info'>
                                                    <IcInfoGray />.
                                                    <div className='tool-tip'>
                                                        <p>{ObjSelect.fileName}</p>
                                                        <p>{`Ngày tải lên: ${convertDateTime(ObjSelect.uploadedAt, "HH:mm - DD/MM/YYYY")}`}</p>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <iframe
                                                    className={businessInfo.censorshipReason.length !== 0 ? 'img-select reason' : 'img-select'}
                                                    src={`https://docs.google.com/gview?url=${ObjSelect.url}&embedded=true#toolbar=0&navpanes=0&scrollbar=0`}
                                                    width="176px" height="176px">
                                                </iframe>
                                                <div className='wrap-icon-info'>
                                                    <IcInfoGray />.
                                                    <div className='tool-tip'>
                                                        <p>{ObjSelect.fileName}</p>
                                                        <p>{`Ngày tải lên: ${convertDateTime(ObjSelect.uploadedAt, "HH:mm - DD/MM/YYYY")}`}</p>
                                                    </div>
                                                </div>
                                            </>
                                : null
                        }
                        <div className={isExpand ? "list-personal expand" : "list-personal"}>
                            {
                                licenseList.length === 0 &&
                                <div className={isExpand ? 'licenseUrl-default expand' : "licenseUrl-default "}
                                    onClick={() => setIsExpand(true)}>
                                    <IcLicenseUrlDefault />
                                </div>
                            }
                            {
                                licenseList.map((data, index) => {
                                    return (
                                        data.map((item, j) => {
                                            return (
                                                <div className='list-image' key={j}>
                                                    {
                                                        index === 0 &&
                                                        <IcRefreshCircle className={isExpand ? 'ic-refresh expand' : 'ic-refresh'} />
                                                    }
                                                    <div
                                                        className={isExpand ? 'wrap-image expand' : 'wrap-image'}
                                                        onClick={() => _handleSetIsExpand(item)} >
                                                        <div className={isExpand ? 'wrap-icon-info expand' : 'wrap-icon-info'}>
                                                            <IcInfoGray />.
                                                            <div className='tool-tip'>
                                                                <p>{item.fileName}</p>
                                                                <p>{`Ngày tải lên: ${convertDateTime(item.uploadedAt, "HH:mm - DD/MM/YYYY")}`}</p>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.type === "IMAGE"
                                                                ?
                                                                <img
                                                                    className={`license image-identify${isExpand ? " expand" : ""}${isExpand && item.url === ObjSelect.url ? " active" : ""} `}
                                                                    src={item.url}
                                                                    alt="image"
                                                                />
                                                                : item.type === "PDF"
                                                                    ? <IcFilePdf className={`license image-identify${isExpand ? " expand" : ""}${isExpand && item.url === ObjSelect.url ? " active" : ""} `} />
                                                                    : <IcFileWord className={`license image-identify${isExpand ? " expand" : ""}${isExpand && item.url === ObjSelect.url ? " active" : ""} `} />
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                })
                            }
                            {
                                ekycList.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={isExpand ? 'wrap-image expand' : 'wrap-image'}
                                            onClick={() => setIsExpand(true)}>
                                            <img
                                                className={`ekyc image-identify${isExpand ? " expand" : ""}${isExpand && item.url === ObjSelect.url ? " active" : ""} `}
                                                src={item.url}
                                                alt="image"
                                                onClick={() => _handleSelectImage(item)} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={isExpand ? 'wrap-btn-request expand' : 'wrap-btn-request'}>
                        {
                            businessInfo.censorshipState !== REQUIRE_LICENSE_UPDATE &&
                            <Button
                                text="Yêu cầu cập nhật"
                                iconBtn={<IcRefreshBlue />}
                                className="btn-request"
                                onClick={_handleRequestUpdate} />
                        }
                        <IcExportFile
                            className={isExpand ? 'ic-export expand' : 'ic-export'}
                            onClick={_handleExportFile} />
                    </div>
                </div>
                <div className='wrap-business-info'>
                    <div className='wrap-block'>
                        <p className='title-info'>Thông tin doanh nghiệp</p>
                        <div className='wrap-info'>
                            <div className='wrap-logo'>
                                {
                                    isErrImgBusiness ?
                                        <IcLogoMerchantDefault className="logo" />
                                        :
                                        <img
                                            className={isExpand ? 'logo expand' : 'logo'}
                                            src={businessInfo.businessLogo}
                                            alt="logo"
                                            onError={() => setIsErrImgBusiness(true)}
                                        />
                                }
                                <div className='wrap-name'>
                                    <p className={isExpand ? 'name expand' : 'name'} title={businessInfo.storeName}>
                                        {businessInfo.storeName || "-"}
                                    </p>
                                    <p className={isExpand ? 'type expand' : 'type'} title={businessInfo.businessTypeName}>
                                        {businessInfo.businessTypeName || "-"}
                                    </p>
                                </div>
                            </div>
                            <div className={isExpand ? 'wrap-business-content expand' : 'wrap-business-content'}>
                                <div className='block-content'>
                                    <IcField className='icon' />
                                    <div className='wrap-text'>
                                        <p className={isExpand ? 'text expand' : 'text'}>
                                            Tên doanh nghiệp
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={businessInfo.businessName}>
                                            {businessInfo.businessName || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessCode className='icon' />
                                    <div className='wrap-text'>
                                        <p className={isExpand ? 'text expand' : 'text'} title="Mã số doanh nghiệp/ MST / CMND">
                                            Mã số doanh nghiệp/ MST / CMND
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={businessInfo.licenseCode}>
                                            {businessInfo.licenseCode || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessAvatar className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Người đại diện
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={businessInfo.ownerName}>
                                            {businessInfo.ownerName || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessStore className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Số cửa hàng
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'}>
                                            {businessInfo.storeNumber || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessEmail className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Email doanh nghiệp
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={businessInfo.email}>
                                            {businessInfo.email || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessReferral className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Mã giới thiệu
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={businessInfo.referralCode}>
                                            {businessInfo.referralCode || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessAdd className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Địa chỉ doanh nghiệp
                                        </p>
                                        <p className={isExpand ? 'value address' : 'value'} title={businessInfo.address}>
                                            {businessInfo.address || "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='wrap-block'>
                        <p className='title-info'>Tài khoản Facepay</p>
                        <div className='wrap-info'>
                            <div className='wrap-avatar'>
                                {
                                    isErrImgAvatar ?
                                        <IcAvararDefault className="avatar" />
                                        :
                                        <img
                                            className="avatar"
                                            src={userInfo.thumbnailUrl}
                                            alt="avatar"
                                            onError={() => setIsErrImgAvatar(true)}
                                        />
                                }
                                <div className='wrap-name'>
                                    <p className='name'>ProfileID</p>
                                    <p className={isExpand ? 'type expand' : 'type'} title={userInfo.profileID}>
                                        {userInfo.profileID || "-"}
                                    </p>
                                </div>
                            </div>
                            <div className={isExpand ? 'wrap-business-content expand' : 'wrap-business-content'}>
                                <div className='block-content'>
                                    <IcBusinessIdentify className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Giấy tờ tùy thân
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={userInfo.ekycInfo.cardID}>
                                            {userInfo.ekycInfo.cardID || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessAvatar className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Username
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={userInfo.userName}>
                                            {userInfo.userName || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessAvatar className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Họ và tên
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={userInfo.userFullName}>
                                            {userInfo.userFullName || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessEmail className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Email đăng ký
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={userInfo.email}>
                                            {userInfo.email || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessPhone className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Số điện thoại
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={userInfo.phoneNumber}>
                                            {userInfo.phoneNumber || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessTime className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Thời gian đăng ký
                                        </p>
                                        <p className={isExpand ? 'value expand' : 'value'} title={userInfo.registeredAt ? convertDateTime(userInfo.registeredAt, "HH:mm - DD/MM/YYYY") : "-"}>
                                            {userInfo.registeredAt ? convertDateTime(userInfo.registeredAt, "HH:mm - DD/MM/YYYY") : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='block-content'>
                                    <IcBusinessAdd className='icon' />
                                    <div className='wrap-text'>
                                        <p className='text'>
                                            Nơi cư trú
                                        </p>
                                        <p className={isExpand ? 'value address' : 'value'} title={userInfo.ekycInfo.address}>
                                            {userInfo.ekycInfo.address || "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WrapBusinessProfile >
    )
}

export default BussinessProfile