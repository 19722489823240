import React from 'react'

const IcRefreshWaiting = (props) => {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={12} cy={12} r={12} fill="#FFBA33" />
            <g clipPath="url(#clip0_5284_29306)">
                <path d="M4.66699 17.332V13.332H8.66699" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.333 6.66797V10.668H15.333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.34033 9.99842C6.67844 9.04295 7.25308 8.18869 8.01064 7.51537C8.76819 6.84205 9.68397 6.3716 10.6725 6.14793C11.6611 5.92426 12.6902 5.95465 13.6638 6.23627C14.6374 6.51789 15.5238 7.04157 16.2403 7.75842L19.3337 10.6651M4.66699 13.3318L7.76033 16.2384C8.47682 16.9553 9.36324 17.479 10.3369 17.7606C11.3105 18.0422 12.3396 18.0726 13.3281 17.8489C14.3167 17.6252 15.2325 17.1548 15.99 16.4815C16.7476 15.8082 17.3222 14.9539 17.6603 13.9984" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5284_29306">
                    <rect width={16} height={16} fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcRefreshWaiting
