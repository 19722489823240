import styled from "styled-components";

const WrapFilterTransaction = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgb(0 0 0 / 15%);
`

const WrapFilterTransactionSearch = styled.div`
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input-transaction{
        width: 330px;
        height: 36px;

        input{
            background: none;
        }
    }
    .btn-reset{
        background: none;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #33ADFF;
        padding: 8px 0 8px 9px;
    }
`

const FilterControlTime = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 33px;
    border: 0.5px solid #B7B7B7;
    box-sizing: border-box;
    padding: 9px 14px;
    width: 330px;
    height: 36px;
    .arrow-right {
        transform: rotate(-90deg);
        flex-shrink: 0;
        cursor: pointer;
    }
`

const FilterControlStore = styled.div`
    border-radius: 33px;
    border: 0.5px solid #B7B7B7;
    box-sizing: border-box;
    padding: 9px 14px;
    width: 360px;
    height: 36px;

    position: relative;
    .store-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Open Sans';
        font-style: normal;
        .title{
            display: flex;
            align-items: center;
            gap: 4px;
            .value-title{
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #313131;
            }
        }
        .value{
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
            cursor: pointer;

            display: flex;
            width: 231px;
            justify-content: center;
        }
    }
`

const WrapFilterTransactionControl = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

`

const WrapFilterTransactionCheckbox = styled.div`
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    .filter-checkbok{
        display: flex;
        align-items: center;
        gap: 14px;
        .info-check{
            display: flex;
            align-items: center;
            gap: 6px;
        }
        .info-check:nth-child(3){
            border-left: 1px solid #B7B7B7;
            padding-left: 14px;
        }
        .info-check:nth-child(6){
            border-left: 1px solid #B7B7B7;
            padding-left: 14px;
        }
        .filter-checkbok-text{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            display: flex;
            align-items: center;
            letter-spacing: -0.01em;
            color: #313131;
        }
        .filter-checkbok-bank{
            position: relative;
        }

        &.checkbok-wallet{
            border-right: 1px solid #B7B7B7;
            padding-right: 20px;
        }
    }
`

export {
    WrapFilterTransaction,
    WrapFilterTransactionSearch,
    FilterControlTime,
    WrapFilterTransactionControl,
    FilterControlStore,
    WrapFilterTransactionCheckbox
}