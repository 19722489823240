import React from 'react'

const IcNewRegister = (props) => {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={12} cy={12} r={12} fill="#33ADFF" />
            <path d="M15.6 5.25H8.4C7.55992 5.25 7.13988 5.25 6.81901 5.41349C6.53677 5.5573 6.3073 5.78677 6.16349 6.06901C6 6.38988 6 6.80992 6 7.65V18.75H18V7.65C18 6.80992 18 6.38988 17.8365 6.06901C17.6927 5.78677 17.4632 5.5573 17.181 5.41349C16.8601 5.25 16.4401 5.25 15.6 5.25Z" fill="white" />
            <path d="M18 18.75V7.65C18 6.80992 18 6.38988 17.8365 6.06901C17.6927 5.78677 17.4632 5.5573 17.181 5.41349C16.8601 5.25 16.4401 5.25 15.6 5.25H8.4C7.55992 5.25 7.13988 5.25 6.81901 5.41349C6.53677 5.5573 6.3073 5.78677 6.16349 6.06901C6 6.38988 6 6.80992 6 7.65V18.75M19.5 18.75H4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.625 8.25H10.6875M8.625 11.25H10.6875M8.625 14.25H10.6875M13.3125 8.25H15.375M13.3125 11.25H15.375M13.3125 14.25H15.375" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcNewRegister