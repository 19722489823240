import IcNewRegister from "../assets/images/IcNewRegister"
import IcRefreshWaitingMini from "../assets/images/IcRefreshWaitingMini"
import IcUpdateDoc from "../assets/images/IcUpdateDoc"
import IcUserRegisMini from "../assets/images/IcUserRegisMini"
import IcWaitUpdate from "../assets/images/IcWaitUpdate"
import { BUSINESS_REJECT, BUSINESS_WAITING, BUSINESS_WAITING_UPDATE } from "../constant/keys"

export const headerWaitingCensorship = [
    "Document ID",
    "Faceprint",
    "Họ tên",
    "Số điện thoại",
    "Email",
    "Cảnh báo trùng khớp",
    "Thời gian chờ",
]

export const listNote = [
    {
        icon: <IcUserRegisMini />,
        text: "Đăng ký mới"
    },
    {
        icon: <IcRefreshWaitingMini />,
        text: "Chờ duyệt lại"
    },
]


// BUSINESS
export const listNoteBusiness = [
    {
        icon: <IcNewRegister />,
        text: "Đăng ký mới",
        key: BUSINESS_WAITING
    },
    {
        icon: <IcWaitUpdate />,
        text: "Chờ cập nhật",
        key: BUSINESS_WAITING_UPDATE
    },
    {
        icon: <IcUpdateDoc />,
        text: "Đã cập nhật",
        key: BUSINESS_REJECT
    },
]

export const headerWaitingBusiness = [
    "BusinessID",
    "Tên cửa hàng",
    "Tên doanh nghiệp",
    "Người đại diện",
    "Số điện thoại",
    "ProfileID",
    "Hạn update/ duyệt",
    "Ngày đăng ký hồ sơ",
]