import React from 'react'

const IcLock = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#F45C5C"/>
        <path d="M16.6667 11.332H7.33333C6.59695 11.332 6 11.929 6 12.6654V17.332C6 18.0684 6.59695 18.6654 7.33333 18.6654H16.6667C17.403 18.6654 18 18.0684 18 17.332V12.6654C18 11.929 17.403 11.332 16.6667 11.332Z" fill="white"/>
        <path d="M8.66602 11.332V8.66536C8.66602 7.78131 9.01721 6.93346 9.64233 6.30834C10.2674 5.68322 11.1153 5.33203 11.9993 5.33203C12.8834 5.33203 13.7312 5.68322 14.3564 6.30834C14.9815 6.93346 15.3327 7.78131 15.3327 8.66536V11.332" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcLock
