import React, { useEffect, useRef, useState } from "react";

//style
import { WrapBackLogContent, WrapBackLogItem } from "./index.styles";

//image
import IcNoteBlue from "../../../../../../../../assets/images/IcNoteBlue";

//help
import { convertDateTime } from "../../../../../../../../helper/convertDateTime";
import convertTypeBacklogToStr from "../../../../../../../../helper/convertTypeBacklogToStr";

// component
import PopupAttachNote from "../../TransactionHistory/PopupAttachNote";
import { POPUP_NOTE_BACKLOG } from "../../../../../../../../constant/keys";
import { useDispatch, useSelector } from "react-redux";
import { hidePopupNote, showPopupNote } from "../../../../../../../../redux/customer/customerNote/action";

const BackLogItem = ({ data, isShowPopup, setIsShowPopup, listRef }) => {
    
    const { id, uid, type, info, metrics, createdAt } = data;

    const dispatch = useDispatch()
    const refNote = useRef(null);
    const refPopup = useRef(null);
    const [isOutList, setIsOutList] = useState("");
    const [contentBacklog, setContentBacklog] = useState("")

    const { details, reqType, isShowPopupNote } = useSelector((state) => ({
        details: state.customerReducer.customerDetailReducer.details,
        reqType: state.customerReducer.listCustomerReducer.reqType,
        isShowPopupNote: state.customerReducer.customerNoteReducer.isShowPopupNote
    }))

    const _handleOpenPopup = () => {
        dispatch(showPopupNote())
        setIsShowPopup(id);
    };

    const _onClickClosePopup = () => {
        dispatch(hidePopupNote())
        setIsShowPopup("")
    }

    useEffect(() => {
        setContentBacklog(convertTypeBacklogToStr(data.type, data.info, 'wrap-content'))
    },[data])
    
    useEffect(() => {
        _onClickClosePopup()
    },[details?.id, reqType])

    useEffect(() => {
        if (listRef && id === isShowPopup) {
            const listRect = listRef.current.getBoundingClientRect(); // ref list
            const { height, top } = listRect;
            
            const noteRect = refNote.current.getBoundingClientRect()
            const PopupRect = refPopup.current.getBoundingClientRect(); // ref popup

            const hightY = height + top;
            const isOutY = PopupRect.top + PopupRect.height;
            if (isOutY > hightY) {
                let newTopPopup = noteRect.bottom - 184
                if (newTopPopup < top) {
                  let delta = top - newTopPopup
                  setIsOutList(`${delta}%`)
                }else{
                  setIsOutList("0px")
                }
            }
        } else {
            setIsOutList("");
        }
    }, [listRef, isShowPopup]);    

    useEffect(() => {
        if(isShowPopupNote){
          listRef.current?.addEventListener('scroll', _onClickClosePopup)
        }
        return () => {
            listRef.current?.removeEventListener('scroll', _onClickClosePopup)
          }
      },[isShowPopupNote])

    return (
        <WrapBackLogContent ref={refNote} propsPopup={isOutList}>        
            <WrapBackLogItem className={`${contentBacklog.style} ${id === isShowPopup ? "active" : ""}`} >
                {contentBacklog.str}
                <div className="wrap-content">
                    <div className="time">
                        {createdAt ? convertDateTime(createdAt, "HH:mm - DD/MM/YYYY") : "-"}
                    </div>
                    <IcNoteBlue className="icon-note" onClick={_handleOpenPopup} />
                </div>
            </WrapBackLogItem>
            {
                id === isShowPopup && isShowPopupNote && (
                    <div className="wrap-content-popup" ref={refPopup}>
                        <PopupAttachNote
                            isShowContentPopup={POPUP_NOTE_BACKLOG}
                            data={data}
                            onClickClosePopup={_onClickClosePopup}
                        />
                    </div>
                )
            }
        </WrapBackLogContent>
    );
};

export default BackLogItem;
