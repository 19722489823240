// facepay terminal
export const GET_LIST_TRANSACTION_PACEPAY_TERMINAL = 'GET_LIST_TRANSACTION_PACEPAY_TERMINAL'
export const GET_LIST_TRANSACTION_PACEPAY_TERMINAL_ERR = 'GET_LIST_TRANSACTION_PACEPAY_TERMINAL_ERR'

export const UPDATE_CONDFILTERS_TRANSACTION_FACEPAY = 'UPDATE_CONDFILTERS_TRANSACTION_FACEPAY'
export const RESET_CONDFILTERS_TRANSACTION_FACEPAY = 'RESET_CONDFILTERS_TRANSACTION_FACEPAY'

export const EXPORT_LIST_TRANSACTION_PACEPAY_TERMINAL_ERR = 'EXPORT_LIST_TRANSACTION_PACEPAY_TERMINAL_ERR'



