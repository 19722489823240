import React from 'react'

const IcLockBgGray = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#B7B7B7"/>
        <g clipPath="url(#clip0_7446_64572)">
        <path d="M13.8889 9.44141H6.11111C5.49746 9.44141 5 9.93887 5 10.5525V14.4414C5 15.0551 5.49746 15.5525 6.11111 15.5525H13.8889C14.5025 15.5525 15 15.0551 15 14.4414V10.5525C15 9.93887 14.5025 9.44141 13.8889 9.44141Z" fill="white"/>
        <path d="M7.22168 9.44141V7.21918C7.22168 6.48247 7.51434 5.77593 8.03527 5.255C8.55621 4.73406 9.26275 4.44141 9.99946 4.44141C10.7362 4.44141 11.4427 4.73406 11.9636 5.255C12.4846 5.77593 12.7772 6.48247 12.7772 7.21918V9.44141" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_7446_64572">
        <rect width="13.3333" height="13.3333" fill="white" transform="translate(3.33301 3.33203)"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default IcLockBgGray
