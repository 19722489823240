import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//style
import { WrapBackLog } from './index.styles'

// images
import IcRefreshBlue from '../../../../../../../assets/images/IcRefeshBlue'
import IcCloseOutlineRed from '../../../../../../../assets/images/IcCloseOutlineRed'
import IcInputUserActive from '../../../../../../../assets/images/IcInputUserActive'
import IcDefaultBacklog from '../../../../../../../assets/images/IcDefaultBacklog'

// constant
import { ADMIN_REJECT_BUSINESS, ADMIN_REQUEST_UPDATE_LICENSE } from '../../../../../../../constant/keys'
import { convertDateTime } from '../../../../../../../helper/convertDateTime'

// redux
import { getListBacklogBusiness } from '../../../../../../../redux/business/listBusinessManage/action'

import EmptyDataControl from '../../../../../../Control/EmptyDataControl'
import useScrollEnd from '../../../../../../../hook/useScrollEnd'

const BackLog = () => {
    
    const dispatch = useDispatch()
    const listRef = useRef()

    const { businessInfo, listBacklogBusiness } = useSelector((state) => ({
        businessInfo: state.businessReducer.listBusinessManage.businessDetail.businessInfo,
        listBacklogBusiness: state.businessReducer.listBusinessManage.listBacklogBusiness,
    }))

    useEffect(() => {
        dispatch(getListBacklogBusiness({
            businessID: businessInfo.id,
            limit: 15,
            lastItem: ""
        }, false))
    },[])

    
  useScrollEnd(listRef, (callback) => {
    dispatch(getListBacklogBusiness({
        businessID: businessInfo.id,
        limit: 15,
        lastItem: listBacklogBusiness.at(-1).id
    }, true, callback))
  }, [listBacklogBusiness], [listBacklogBusiness.length])

    return (
        <WrapBackLog>
            <div className='backlog-content'>
                {
                    listBacklogBusiness.length === 0 ? (
                        <div className="wrap-empty">
                            <EmptyDataControl
                                icon={<IcDefaultBacklog />}
                                text={"Tài khoản chưa cập nhật dữ liệu"}
                            />
                        </div>
                    ) : (
                        <div className="wrap-list-backlog" ref={listRef}>
                            <div className="list-backlog">
                                {
                                    listBacklogBusiness.map((item, index) => (
                                        <div className='backlog-item' key={index}>
                                            {
                                                item.type === ADMIN_REQUEST_UPDATE_LICENSE && (
                                                    <>
                                                        <div className='backlog-item-reason'>
                                                            <div className='backlog-item-reason-icon'>
                                                                <IcRefreshBlue /> 
                                                            </div>
                                                            <div className='backlog-item-reason-value'>
                                                                Yêu cầu cập nhật giấy tờ
                                                            </div>
                                                        </div>
                                                        <div className='backlog-item-note'>
                                                            <p className='backlog-item-note-time'>
                                                                {convertDateTime(item.createdAt, "HH:mm - DD/MM/YYYY")}
                                                            </p>
                                                            <p className='backlog-item-note-user'>
                                                                <IcInputUserActive />
                                                                {item.adminUserName}
                                                            </p>
                                                        </div>                    
                                                    </>
                
                                                )
                                            }
                                            {
                                                item.type === ADMIN_REJECT_BUSINESS && (
                                                    <>
                                                        <div className='backlog-item-reason reason-reject'>
                                                            <div className='backlog-item-reason-icon'>
                                                                <IcCloseOutlineRed /> 
                                                                <div className='backlog-item-reason-value'>
                                                                    Hồ sơ bị từ chối
                                                                </div>
                                                            </div>
                                                            <div className='backlog-item-reason-value'>
                                                                {item.reasons.map((item, index) => (
                                                                    <div key={index}
                                                                    className='reason-reject'>
                                                                        {item}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className='backlog-item-note'>
                                                            <p className='backlog-item-note-time'>
                                                                {convertDateTime(item.createdAt, "HH:mm - DD/MM/YYYY")}
                                                            </p>
                                                            <p className='backlog-item-note-user'>
                                                                <IcInputUserActive />
                                                                {item.adminUserName}
                                                            </p>
                                                        </div>                    
                                                    </>
                                                )
                                            }
                
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </WrapBackLog>
    )
}

export default BackLog