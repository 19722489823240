import styled, { css, keyframes } from 'styled-components'

const expandAnimation = keyframes`
    from {
        max-height: 0;
    }

    to {
        max-height: 500px;
    }
`

const WrapTransHistoryItem = styled.div`
    ${props =>
        !props.isExpandByDefault && props.isExpand
            ? css`border: 1px solid #33ADFF;`
            : ''
    }
    
    .wrap-details {
        overflow: hidden;
        transform-origin: top;
        animation: ${expandAnimation} 0.25s ease-in-out;
    }
    
    .wrap-infos {
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        background: #FFFFFF;
        padding: 16px 23px 16px 20px;
        /* width: calc(1280px - 160px - 30px); */
        box-sizing: border-box;

        .general-infos {
            /* min-height: 56px; */
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-infos {
                display: flex;
                .wrap-id-trans {
                    display: flex;
                    align-items: center;
                    font-family: "Open Sans";
                    font-style: normal;
                    color: #313131;
                    letter-spacing: -0.01em;
                    min-width: 0;
                    &:first-of-type {
                        margin-left: 10px;
                    }
                    &:not(:first-of-type) {
                        margin-left: 24px;
                    }

                    .id-name {
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 18px;
                        white-space: nowrap;
                    }

                    .id-value {
                        margin-left: 8px;
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 20px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        /* display: inline-block; */
                        max-width: 24ch;
                    }
                }
            }

            .btn-refund {
                background: #F45C5C;
            }

            .refunded {
                margin-left: 20px;
                font-family: Open Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #B7B7B7;
                span {
                    color: #F45C5C;
                }
            }

            ${props =>
                !props.isExpandByDefault
                    ? css`&:hover {
                            cursor: pointer;
                        }`
                    : ''
            }
        }

        .refunded-infos {
            margin-top: 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > * {
                display: flex;
                align-items: center;
                span {
                    margin-left: 3px;
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                }
            }

            .reason-refund {
                span {
                    color: #F45C5C;
                }
            }

            .time-refund {
                span {
                    color: #777777;
                }
            }
        }

        &.payment-location-infos {
            min-height: 128px;
            background: ${props => !props.isExpandByDefault ? '#EFF9FF' : '#FFFFFF'};
            box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
            .row {
                &.payment-location-row {
                    margin-top: 25px;
                    display: grid;
                    flex-direction: column;
                    grid-template-columns: 350px 1fr;
                    .payment-location {
                        display: flex;
                        align-items: center;

                        .wrap-logo {
                            height: 36px;

                            .logo {
                                height: 100%;
                                object-fit: contain;
                                border-radius: 50%;
                            }
                        }

                        .wrap-location-infos {
                            margin-left: 10px;
                            font-family: "Open Sans";
                            font-style: normal;
                            width:80%;
                            .name {
                                width: 100%;
                                font-weight: 600;
                                font-size: 15px;
                                line-height: 20px;
                                letter-spacing: -0.01em;
                                color: #313131;

                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: inline-block;
                            }

                            .address {
                                width: 100%;
                                margin-top: 4px;
                                font-weight: normal;
                                font-size: 11px;
                                line-height: 15px;
                                letter-spacing: -0.02em;
                                color: #777777;

                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: inline-block;
                            }
                        }
                    }


                    .payment-value {
                        font-family: "Open Sans";
                        font-style: normal;
                        text-align: right;

                        .value {
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 27px;
                            letter-spacing: -0.01em;
                            color: #313131;
                        }

                        .unit {
                            margin-top: 4px;
                            font-weight: normal;
                            font-size: 11px;
                            line-height: 15px;
                            letter-spacing: -0.02em;
                            color: #777777;
                        }
                    }
                }
            }
        }

        &.face-recognize-infos {
            min-height: 308px;
            background: ${props => !props.isExpandByDefault ? '#EFF9FF' : '#FFFFFF'};

            .row {
                &.face-recognize {
                    margin-top: 26px;

                    .wrap-media {
                        position: relative;
                        width: 228px;

                        img {
                            border-radius: 3px;
                            display: block;
                            width: 100%;
                            object-fit: contain;
                            box-sizing: border-box;
                        }
                        .wrapper{
                            width: 228px;
                            height: 228px;
                            background: #F7F7F7;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;   
                            pointer-events: auto;
                            cursor: auto;
                        }
                        &.face-img {                           
                            img {
                                border: 2px solid #33ADFF;
                            }
                            .tools {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                display: flex;
                                /* justify-content: space-between; */
                                justify-content: flex-end;
                                align-items: center;
                                width: 100%;
                                padding: 0 12px 11px 11px;
                                box-sizing: border-box;

                                .accuracy {
                                    font-family: "Open Sans";
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 15px;
                                    line-height: 20px;
                                    letter-spacing: -0.01em;
                                    color: #33ADFF;
                                }
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        &.recog-video {
                            margin-left: 30px;
                            .overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: rgba(0, 0, 0, 0.4);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 3px;

                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }

        .title {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
            display: flex;
            flex-direction: row;
            gap: 24px;
            .wrap-id-trans {
                display: flex;
                align-items: center;
                font-family: "Open Sans";
                font-style: normal;
                color: #313131;
                letter-spacing: -0.01em;
                min-width: 0;
                &:first-of-type {
                    margin-left: 12px;
                }
                &:not(:first-of-type) {
                    margin-left: 24px;
                }

                .id-name {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 18px;
                    white-space: nowrap;
                }

                .id-value {
                    margin-left: 8px;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    /* display: inline-block; */
                    max-width: 24ch;
                }
            }
        }

        .row {
            display: flex;
        }
    }

    .not-enough-money {
        min-height: 40px;
        padding: 0 24px;
        box-sizing: border-box;
        background: #FFEEEE;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        
        span {
            margin-left: 4px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #F45C5C;
        }
    }
`;

export {
    WrapTransHistoryItem
}