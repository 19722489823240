import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDatabase, ref, onValue, off } from "firebase/database";

// component
import Content from './Content'
import Header from './Header'
import SideMenu from './SideMenu'
import Loading from './Loading';

// styles
import { WrapMain } from './index.styles'

// action
import { getListReason } from '../../redux/censorship/listReason/action';
import { DEV_ENV, env, WEE_ENV } from '../../constant/environmentTypes'

// constant
import { refNotifyDevice } from '../../constant/host';

// firebase
import app, { getFileStorage } from '../../firebase/firebase'
import { getListFirebaseDevices, resetIsCheckcSnapshot, resetIsCheckcSreenshot } from '../../redux/deviceV2/listDevicesByAdmin/action';
import Notify from '../Notification/Notify';
import checkStatusOnline from '../../helper/checkStatusOnline';

const Main = () => {
    let isListenDevice = useRef(true)

    let currentTime = Date.now()

    const dispatch = useDispatch()

    const { isLoading, isCheckcSreenshot, listFirebase, isCheckSnapshot, listCheckUrlScreen, prevURL } = useSelector(state => ({
        isLoading: state.loading.isLoading,
        listFirebase: state.deviceReducer.listDevicesByAdmin.listFirebase,

        // check Sreen
        isCheckcSreenshot: state.deviceReducer.listDevicesByAdmin.isCheckcSreenshot,
        isCheckSnapshot: state.deviceReducer.listDevicesByAdmin.isCheckSnapshot,
        listCheckUrlScreen: state.deviceReducer.listDevicesByAdmin.listCheckUrlScreen,
        prevURL: state.deviceReducer.listDevicesByAdmin.prevURL
    }))
    
    // useSocket()
    useEffect(() => {
        
        if (![DEV_ENV, WEE_ENV].includes(env)) return;
        
        dispatch(getListReason())
        let startDevice = null
        _startListenFirebaseDevice(startDevice)
        if(!isListenDevice.current) isListenDevice.current = true

        return () => {
            startDevice && off(startDevice)
        } 
        
    }, []);


    useEffect(() => {
        let timer = null
        let listUrlScreen = []

        listFirebase.forEach(device => {
            listUrlScreen.push(isCheckcSreenshot ? device.screenShot : device.snapShot)
        })

        if(listUrlScreen.includes(prevURL)) return
        if(isCheckcSreenshot || isCheckSnapshot) {
            listFirebase.map(device => {
                // screenShot
                if(device.screenShot.length > 0 && isCheckcSreenshot){
                    if(listCheckUrlScreen === device.serialNumber){
                        if(device.screenShot) {
                            getFileStorage(device.screenShot, (url) => {
                                window.open(url, '_blank')
                                dispatch(resetIsCheckcSreenshot())
                            })
                            dispatch(resetIsCheckcSreenshot())
                        }
                    }
                    return
                }

                // snapShot
                if(device.snapShot.length > 0 && isCheckSnapshot){
                    if(listCheckUrlScreen === device.serialNumber){
                        if(device.snapShot){
                            getFileStorage(device.snapShot, (url) => {
                                window.open(url, '_blank')
                                dispatch(resetIsCheckcSnapshot())
                            })
                        }
                        dispatch(resetIsCheckcSnapshot())
                    }
                    return
                }
            })
            return
        }
        
        timer = setInterval(() => {
            if(isCheckcSreenshot) {
                dispatch(resetIsCheckcSreenshot())
                return
            }
            
            if(isCheckSnapshot) {
                dispatch(resetIsCheckcSnapshot())
                return
            }
        }, 30000);

        return () => {
            clearInterval(timer)
        }
    },[listFirebase, isCheckcSreenshot, isCheckSnapshot])

    const _startListenFirebaseDevice = (startDevice) => {
        let timer = null

        // console.log(app)
        const db = getDatabase();
        // console.log(db)
        startDevice = ref(db, refNotifyDevice);
        // console.log(startDevice)
        onValue(startDevice, (snapshot) => {
            // console.log(snapshot)
            // if(!isListenDevice.current) return
            // console.log('_startListenFirebaseDevice')
            const data = snapshot.val();
            if (data) {
                const array = Object.values(data)
                dispatch(getListFirebaseDevices(array))
                if(timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(() => {
                    const checkStatusLastOnline = array.map((item) => {
                        return {
                            ...item,
                            status: checkStatusOnline(currentTime, item.time)
                        }
                    })
                    dispatch(getListFirebaseDevices(checkStatusLastOnline))
                }, 30000)
            }
            // isListenDevice.current = false
        });
    }

    return (
        <WrapMain>
            <Header className="header" />
            <SideMenu />
            <Content />
            <Notify />
            { isLoading && <Loading />}
            {/* { isShow && <div><img></img></div>} */}
        </WrapMain>
    )
}

export default Main
