import styled from "styled-components";

const WrapItemBusiness = styled.div`
    display: grid;
    grid-template-columns: minmax(120px,150px) minmax(120px,1fr) minmax(150px,1fr) minmax(100px,180px) minmax(120px,180px) minmax(130px,185px) 120px 150px;
    align-items: center;
    grid-gap: 10px;
    height: 60px;
    background: #FFFFFF;
    margin-bottom: 8px;
    box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13.5px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #313131;
    padding: 1px 12px 1px 14px;
    box-sizing: border-box;
    cursor: pointer;
    div{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }  
    .wrap-status{
        display: flex;
        align-items: center;
        gap: 4px;
        svg{
            width: 20px;
            height: 20px;
        }
        .logo{
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }
    .time-register{
        color: #777777;
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .time-expired-new{
        font-weight: 600;
        color: #33ADFF;
    }
    .time-expired-denied{
        font-weight: 600;
        color: #F45C5C;
    }
    .time-expired-gray{
        color: #777777;
    }
`

export {
    WrapItemBusiness
}