import * as types from './types'

const initialState = {
    dataAnalytic: {}
}

const customerAnalyticReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CUSTOMER_ANALYTIC:
        case types.GET_CUSTOMER_BANK_ANALYTIC:
            return {
                ...state,
                dataAnalytic: action.payload.dataAnalytic
            }
        case types.RESET_CUSTOMER_ANALYTIC:
        case types.RESET_CUSTOMER_BANK_ANALYTIC:
            return {
                ...state,
                dataAnalytic: {}
            }
        default:
            return state
    }
}

export default customerAnalyticReducer