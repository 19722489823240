import React from 'react'

const IcDisableAccount = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#F45C5C"/>
        <path d="M14.666 15.3346L17.9993 12.0013L14.666 8.66797" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 12H10" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 18H7.33333C6.97971 18 6.64057 17.8595 6.39052 17.6095C6.14048 17.3594 6 17.0203 6 16.6667V7.33333C6 6.97971 6.14048 6.64057 6.39052 6.39052C6.64057 6.14048 6.97971 6 7.33333 6H10" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcDisableAccount
