import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// style
import { WrapTransactionWalletItem } from './index.styles'

// image
import IcArrowRight from '../../../../../../../assets/images/IcArrowRight'
import IcFacePrintDefault from '../../../../../../../assets/images/IcFacePrintDefault'
import Logo_DefautBank from "../../../../../../../assets/images/Default_Bank.png";

// helpter
import { convertDateTime } from '../../../../../../../helper/convertDateTime'
import numberFormatToThousand from '../../../../../../../helper/numberFormatToThousand'

// component
import Portal from '../../../../../../Control/Portal'
import PopupDetailWalletDepositLinkCard from './PopupDetailWallet/PopupDetailWalletDepositLinkCard'
import PopupDetailWalletTranfer from './PopupDetailWallet/PopupDetailWalletTranfer'

// host
import { apiGetBankCustomerAvatar } from '../../../../../../../constant/host'

// data
import getValueWalletFacepay from '../../../../../../../data/dataWalletFacepay'
import { dataBankType, dataLogoBank, dataLogoMerchant } from '../../../../../../../data/dataBankType'
import { BANKCARD, DEPOSIT, DEPOSIT_LINKCARD, FACEPAY, LINK_CARD, TRANSFER_FROM_FACEPOS, TRANSFER_TO_BANK, TRANSFER_TO_BANK_WALLET_FACEPOS, TRANSFER_TO_WALLET, WALLET, LINKED, BANKACCOUNT } from '../../../../../../../constant/keys'
import IcArrowGreenRight from '../../../../../../../assets/images/IcArrowGreenRight'
import { TRANSACTION } from '../../../../../../../constant/path'


const TransactionWalletItem = ({ item }) => {

    const [isShowPopupWalletFacepay, setIsShowPopupWalletFacepay] = useState("")
    
    const [isErrImg, setIsErrImg] = useState(false)

    const { listBank, reqType } = useSelector((state) => ({
        listBank: state.transactionReducer.transactionOtherReducer.listBank,
        reqType: state.transactionReducer.transactionOtherReducer.reqType
    }))
    const logoMerchent = dataLogoBank(listBank)
    
    useEffect(() => {
        setIsErrImg(false)
    }, [item.thumbnailId])

    const handleClickShowPopupDetail = (type) => {
        if(type === LINK_CARD || type === DEPOSIT) {
            setIsShowPopupWalletFacepay(DEPOSIT_LINKCARD)
            return
        }
        if(type === TRANSFER_TO_WALLET || type === TRANSFER_TO_BANK || type === TRANSFER_FROM_FACEPOS) {
            setIsShowPopupWalletFacepay(TRANSFER_TO_BANK_WALLET_FACEPOS)
            return
        }
    }
    
    return (
        <>
            <WrapTransactionWalletItem onClick={() => handleClickShowPopupDetail(item.transactionType.toUpperCase())}>
                <div className="wrap-item facepayTx">
                    {getValueWalletFacepay(item.transactionType.toUpperCase(), item.paymentInfo.status).iconWallet}
                    <div className="item-text bold">#{item.paymentInfo.facepayTrxCode || "-"}</div>
                </div>
                <div className="wrap-item">
                    <div className='item-icon'>
                        {
                            isErrImg ? (
                                <IcFacePrintDefault className="face" />
                            ) : (
                                <img 
                                    className='face-id'
                                    src={item.paymentInfo.thumbnailUrl} 
                                    alt="avatar"
                                    onError={() => setIsErrImg(true)}
                                />
                            )
                        }
                    </div>
                    <div className="item-text" title={item.paymentInfo.profileId}>{item.paymentInfo.profileId || "-"}</div>
                </div>
                <div className="wrap-item">
                    <div className="item-text" title={item.paymentInfo.userName}>{item.paymentInfo.userName || "-"}</div>
                </div> 
                <div className="wrap-item">
                    <div className="item-icon" 
                        title={item.from.supplier === FACEPAY ? dataLogoMerchant[item.from.supplier]?.text : logoMerchent[item.from.supplier]?.bankName
                        }
                    >
                        {
                            item.from.supplier === FACEPAY ? dataBankType[item.from.supplier]?.icon : logoMerchent[item.from.supplier]?.icon || <img src={Logo_DefautBank} className="icon" />
                        }
                    </div>
                    <div className="item-text">
                        {
                            item.from.type?.toUpperCase() === WALLET ? item.from.userInfo.phone : item.from.accountInfo.holderNumber || "-"
                        }
                    </div>
                </div>
                <div className='wrap-item'>
                    <IcArrowGreenRight />
                </div>
                <div className="wrap-item">
                    <div className="item-icon"
                        title={item.to.supplier === FACEPAY ? dataLogoMerchant[item.to.supplier]?.text : logoMerchent[item.to.supplier]?.bankName
                        }
                    >
                        {
                            item.to.supplier === FACEPAY ? dataBankType[item.to.supplier]?.icon : logoMerchent[item.to.supplier]?.icon || <img src={Logo_DefautBank} className="icon" />
                        }
                    </div>
                    <div className="item-text">
                        {
                            item.to.type?.toUpperCase() === WALLET ? item.to.userInfo.phone : item.to.accountInfo.holderNumber || "-"
                        }
                    </div>
                </div>

                <div className="item-text bold">{numberFormatToThousand(item.paymentInfo.transAmount) || 0}</div>
                <div className="item-text">{convertDateTime(item.paymentInfo.createdAt, "HH:mm - DD/MM/YYYY")}</div>
                <div className="wrap-item status">
                    {
                        item.paymentInfo.status === 1 ? <div className='success'>Thành công</div> : <div className='failed'>Thất bại</div>
                    }
                    <IcArrowRight className="icon-arrow" />
                </div>

            </WrapTransactionWalletItem>
            {
                isShowPopupWalletFacepay === DEPOSIT_LINKCARD && (
                    <Portal>
                        <PopupDetailWalletDepositLinkCard
                            reqType={reqType}
                            detailsTrans={TRANSACTION}
                            dataTransaction={item}
                            logoMerchent={logoMerchent}
                            onClickClosePopup={() => setIsShowPopupWalletFacepay("")}
                        />
                    </Portal>
                )
            }
            {
                isShowPopupWalletFacepay === TRANSFER_TO_BANK_WALLET_FACEPOS && (
                    <Portal>
                        <PopupDetailWalletTranfer
                            reqType={reqType}
                            detailsTrans={TRANSACTION}
                            dataTransaction={item}
                            logoMerchent={logoMerchent}
                            onClickClosePopup={() => setIsShowPopupWalletFacepay("")}
                        />
                    </Portal>
                )
            }

        </>
    )
}

export default TransactionWalletItem
