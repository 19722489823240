
// lỗi thanh toán update 29/11/2022
export const dataErrorFacePos = {
    ['06']: {text: "Tài khoản đang tạm khóa"},
    ['08']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['09']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['10']: {text: "Khuôn mặt chưa được đăng ký"},
    ['11']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['21']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['40']: {text: "Giao dịch không thành công do yêu cầu không hợp lệ"},
    ['43']: {text: "Giao dịch không thành công do yêu cầu không hợp lệ"},
    ['46']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['53']: {text: "Giao dịch không thành công do lỗi dịch vụ Facepay không khả dụng"}, 
    ['2701010']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['600']: {text: "Tài khoản Facepay hiện đang bị khóa"},
    ['601']: {text: "Tài khoản Facepay hiện đang bị khóa"},
    ['1110']: {text: "Tài khoản Facepay chưa được định danh"},
    ['1104']: {text: "Tài khoản Facepay chưa được xác thực"},
    ['1116']: {text: "Số tiền giao dịch chưa đạt mức tối thiểu"},
    ['1117']: {text: "Số tiền giao dịch vượt quá mức tối đa"},
    ['1133']: {text: "Tài khoản Facepay đang ngừng hoạt động"},
    ['1136']: {text: "Tài khoản không có thông tin ví"},
    ['319001']: {text: "Số tiền vượt quá hạn mức thanh toán"},
    ['319002']: {text: "Số dư không khả dụng"},
    ['319003']: {text: "Thông tin người nhận không đúng, vui lòng kiểm tra lại"},
    ['319005']: {text: "Hệ thống đang bảo trì"},
    ['319902']: {text: "Tài khoản Facepay chưa được định danh"},
    ['319904']: {text: "Thông tin người nhận không đúng, vui lòng kiểm tra lại"},
    ['319905']: {text: "Số điện thoại này chưa kích hoạt ví Facepay"},
    ['319906']: {text: "Tài khoản không được cấp quyền chuyển"},
    ['319907']: {text: "Phương thức chuyển tiền không được hỗ trợ"},
    ['319101']: {text: "Mã xác thực không đúng"},
    ['319102']: {text: "Số điện thoại đã sai mã xác nhận nhiều lần"},
    ['319103']: {text: "Hệ thống đang bảo trì"},
    ['319105']: {text: "Mã xác nhận đã hết hạn"},
    ['319106']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['-1']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['-2']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['-99']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['-100']: {text: "Giao dịch không thành công do chưa hoàn tất"},
    ['-102']: {text: "Khách hàng chưa nhập đúng số điện thoại"},
    ['-101']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['-103']: {text: "Khách hàng cancel giao dịch do không được áp dụng khuyến mãi"},

}