import styled from 'styled-components'

const WrapWaitingCensorship = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    .filter{
        background: #FFFFFF;
        min-height: 60px;
        box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
    }
`
const WrapPageItem = styled.div`
    padding: 0 30px;
    min-width: calc(1280px - 100px);
    box-sizing: border-box;
`


const WrapWaitingList = styled(WrapPageItem)`
    height: 100%;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 30px; */
    .wrap-page-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-header{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            color: #313131;
            padding: 22px 0 23px 8px;
        }
        .list-note{
            display: flex;
            align-items: center;
            .note{
                display: flex;
                align-items: center;
                padding-left: 16px;
                p{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #313131;
                    padding-left: 4px;
                }
            }
        }
    }
`

const TableWaitingList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    .wrap-empty {
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
    }
`

const TableHeader = styled.div`    
    display: grid;
    grid-template-columns: minmax(120px, 150px) minmax(80px, 110px) minmax(180px, 1fr) minmax(113px, 1fr) minmax(280px, 1fr) minmax(155px, 185px) 100px ; 
    grid-gap: 10px; 
    padding: 0 12px 0 18px;
    box-sizing: border-box;
    width: 100%;
    .table-header {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #313131;
    }

`

const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;    
   
    .wrap-list-waiting {
        width: calc(100% + 10px);
        flex-grow: 1;
        overflow: overlay;
        margin-top: 18px;
        margin-left: 10px;
        position: relative;
        .list-waiting {
            width: calc(100% - 10px);
            display: flex;
            flex-direction: column;
            position: absolute;
            /* min-width: 1120px; */
        }
    }
    
`
export { WrapWaitingCensorship, WrapWaitingList, WrapPageItem, TableWaitingList, TableHeader, TableContent }