import styled from "styled-components";

const WrapPopupSubmitUpdate = styled.div`
    width: 380px;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    background: #FFFFFF;
    border-radius: 4px;
    position: absolute;
    /* top: 36px;
    right: 90px;
    z-index: 1; */

    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    .wrap-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-icon{
            cursor: pointer;
        }

        &-text{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #313131;
        }
    }

    .wrap-content{
        margin: 8px 0 20px 0;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        &-value{
            color: #313131;
            margin-bottom: 20px;
            span{
                font-weight: 600;
            }
        }
        &-warning{
            color: #F45C5C;
        }
    }
    .wrap-content-note{
        resize: none;
        width: calc(100% - 22px);
        height: calc(104px - 8px);
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #B7B7B7;

        padding: 8px 8px 8px 12px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        outline: none;

        margin-bottom: 28px;
    }
    .active{
        background: #FFFFFF;
        color: #313131;
    }
    .wrap-btn{
        display: flex;
        justify-content: flex-end;

        .btn-approved{
            width: 98px;
            height: 32px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            display: flex;
            align-items: center;
            letter-spacing: -0.01em;
            color: #FFFFFF;
            background: #33ADFF;
            border-radius: 4px;
            &.disabled{
                background: #F7F7F7;
                color: #B7B7B7;
            }
        }
    }
`

export {
    WrapPopupSubmitUpdate
}