import * as types from './type'
import Axios from 'axios';
import { hostCustomer } from '../../../constant/host'
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors'
import axios from 'axios';

const apiGetListStoreName = `${hostCustomer}/transaction/getstore`
const apiGetListBank = `${hostCustomer}/banks/getAll`
const apiTransactionRefund = `${hostCustomer}/banks/vtb/refund`


let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let isCalling = false

export const reqTypeTransaction = ({ reqType = "FACEPAY_TERMINAL" , callbackSuccess}) => async dispatch => {
    dispatch({
        type: types.CHANGE_TAB_TRANSACTION,
        reqType
    })
    callbackSuccess && callbackSuccess()
}

export const getListStoreName = (
    data = {
        name: [],
    },
    callbackSuccess, 
    callbackError
    ) => async dispatch => {
        
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}
    
    if(isCalling) return
    isCalling = true
    try {
        const res = await Axios.post(apiGetListStoreName, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.GET_LIST_STORE_NAME,
                payload: {
                    listStoreName: data.data.list.map((item) => {
                        return {
                            ...item,
                            isSelect: true
                        }
                    }) || [],
                    total: data.data.total
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_LIST_STORE_NAME_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_STORE_NAME_ERR))
    }
    isCalling = false
}

export const updateListStoreNameTemp = (listStoreName, callbackSuccess) => async dispatch => {
    dispatch({
        type: types.UPDATE_LIST_STORE_NAME_TEMP,
        payload: {
            listStoreName: listStoreName || []
        }
    })
    callbackSuccess && callbackSuccess()
}

export const resetListStoreName = (callbackSuccess) => async dispatch => {
    dispatch({type: types.RESET_LIST_STORE_NAME })
    callbackSuccess && callbackSuccess()
}

export const resetCondFiltersStoreName = (callbackSuccess) => async dispatch => {
    dispatch({type: types.RESET_STORE_NAME_CONDFILTERS })
    callbackSuccess && callbackSuccess()
}

export const updateListStoreNameCondFilters = (    
    {condFiltersStoreName = {
        name: '',
        OptionSelectStore: {
            id: 1,
            name: "",
            isSelect: true,
            totalStoreSelect: 0
        }
    },
    callbackSuccess,}
) => async dispatch => {

    dispatch({
        type: types.UPDATE_LIST_STORE_NAME_CONDFILTERS,
        condFiltersStoreName,
    })
    callbackSuccess && callbackSuccess()   
}

export const isCheckSearchStore = () => dispatch => {
    dispatch({
        type: types.IS_CHECK_SEARCH_STORE_NAME
    })
}

export const resetIsCheckSearchStore = () => dispatch => {
    dispatch({
        type: types.RESET_IS_CHECK_SEARCH_STORE_NAME
    })
}

// show popup
export const showPopupDropdownStoreName = () => async dispatch => {
    dispatch({
        type: types.SHOW_POPUP_DROPDOWN_STORE_NAME
    })
}

// hide popup
export const hidePopupDropdownStoreName = () => async dispatch => {
    dispatch({type: types.HIDE_POPUP_DROPDOWN_STORE_NAME})
}

export const getListBank = () => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`

    try {
        const res = await axios.post(apiGetListBank, headerDataRequest)
        const { data } = res

        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.GET_LIST_BANK,
                payload: {
                    listBank: data.data
                }
            })
        }else{
            dispatch({
                type: types.GET_LIST_BANK_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_BANK_ERR))
    }
}

// Refund

export const transactionRefund = (
    data = {
        supplier: "",
        trxCode: "",
        remark: "", 
        terminalId: ""
    },
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await axios.post(apiTransactionRefund, dataRequest)
        const { data } = res

        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.TRANSACTION_REFUND_SUCCESS,
                payload: {
                    ...data
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.TRANSACTION_REFUND_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.TRANSACTION_REFUND_ERR))
    }
}

