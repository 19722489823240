import React from 'react'

const IcSettingGray = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_12653_3068)">
        <path d="M9.85416 4.43746C9.73964 4.55429 9.6755 4.71136 9.6755 4.87496C9.6755 5.03855 9.73964 5.19563 9.85416 5.31246L10.8542 6.31246C10.971 6.42697 11.1281 6.49112 11.2917 6.49112C11.4553 6.49112 11.6123 6.42697 11.7292 6.31246L14.0854 3.95621C14.3997 4.6507 14.4948 5.42448 14.3582 6.17442C14.2216 6.92437 13.8596 7.61486 13.3206 8.15388C12.7816 8.6929 12.0911 9.05485 11.3411 9.19149C10.5912 9.32814 9.8174 9.23298 9.12291 8.91871L4.80416 13.2375C4.55552 13.4861 4.21829 13.6258 3.86666 13.6258C3.51503 13.6258 3.1778 13.4861 2.92916 13.2375C2.68052 12.9888 2.54083 12.6516 2.54083 12.3C2.54083 11.9483 2.68052 11.6111 2.92916 11.3625L7.24791 7.04371C6.93364 6.34921 6.83848 5.57544 6.97512 4.82549C7.11176 4.07555 7.47371 3.38505 8.01273 2.84603C8.55175 2.30701 9.24225 1.94506 9.99219 1.80842C10.7421 1.67178 11.5159 1.76693 12.2104 2.08121L9.86041 4.43121L9.85416 4.43746Z" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_12653_3068">
        <rect width="15" height="15" fill="white" transform="translate(0.666656 0.5)"/>
        </clipPath>
        </defs>
    </svg>

  )
}

export default IcSettingGray