import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//style
import { WrapPopupEditCCCD } from "./index.style";

//data
import {
  dataGender,
  dataPassportStatus,
} from "../../../../../../../../../../../data/dataCustomer";

//image
import IcClose from "../../../../../../../../../../../assets/images/IcClose";
import IcTransPhotoDefault from "../../../../../../../../../../../assets/images/IcTransPhotoDefault";
import IcUploadWhite from "../../../../../../../../../../../assets/images/IcUploadWhite";

//component
import InputNameInfo from "../ControlPopup/InputNameInfo";
import InputDropdownGender from "../ControlPopup/InputDropdownGender";
import InputPlaceOfIssue from "../ControlPopup/InputPlaceOfIssue";
import InputLocation from "../ControlPopup/InputLocation";
import InputNumberID from "../ControlPopup/InputNumberID";
import DatePickerItemEdit from "../ControlPopup/DatePickerItemEdit";
import Button from "../../../../../../../../../../Control/Button";
import InputHometown from "../ControlPopup/InputHometown";

// redux
import { getListEkycHistoryByCustomer, updateIdCardByCustomer } from "../../../../../../../../../../../redux/customer/customerDetail/action";
import { BANK, ERROR_INPUT_VALIDATE, FACEPAY } from "../../../../../../../../../../../constant/keys";
import { getListCustomerFacepayBacklog, resetListCustomerFacepayBacklog } from "../../../../../../../../../../../redux/customer/customerBacklog/action";
import { useOnClickOutside } from "../../../../../../../../../../../hook/useClickOutside";

const PopupEditInfoCCCD = ({ item, details, onClickClosePopup }) => {
  const refPop = useRef()
  const [isErrCustomerPhoto, setIsErrCustomerPhoto] = useState(false);
  const dispatch = useDispatch()

  const { reqType, condFiltersBacklog } = useSelector((state) => ({
    reqType: state.customerReducer.listCustomerReducer.reqType,
    condFiltersBacklog: state.customerReducer.customerBacklogReducer.condFilters
  }))

  const [data, setData] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      uid: details.id,
      cardType: item.ekycType,
      id: item.ekycInfoId.id,
      dueDate: item.ekycInfoId.due_date,
      issueDate: item.ekycInfoId.issue_date,
      issuedAt: item.ekycInfoId.issued_at,
      name: item.ekycInfoId.name,
      dob: item.ekycInfoId.dob,
      gender: item.ekycInfoId.gender,
      hometown: item.ekycInfoId.hometown,
      address: item.ekycInfoId.address
    }
  );

  const handleSetValueName = (val) => {
    setData({ name: val });
  };

  const handleSetValueNumberID = (val) => {
    setData({ id: val });
  };

  const handleSetValueHomeTown = (val) => {
    setData({ issuedAt: val });
  };

  const handleSetValuePlaceOfIssue = (val) => {
    setData({ hometown: val });
  };

  const handleSetValueLocation = (val) => {
    setData({ address: val });
  };

  const handleSetValueDropdown = (val) => {
    setData({ gender: val.key });
  };

  const handleValidationInput = (value, callback) => {
    if (!value?.trim() || 0 === value.length) {
      callback(ERROR_INPUT_VALIDATE);
      return;
    }
  };

  const callbackSuccess = () => {
    dispatch(getListEkycHistoryByCustomer({ uid: details.id }))
    switch (reqType) {
      case FACEPAY:
          const callbackSuccessFacepayBacklog = () => {
              if(condFiltersBacklog.uid){
                  dispatch(getListCustomerFacepayBacklog({
                      adminId: condFiltersBacklog.adminId,
                      adminUserName: condFiltersBacklog.adminUserName,
                      uid: condFiltersBacklog.uid,
                      type: condFiltersBacklog.type.key,
                      skip: condFiltersBacklog.skip,
                      limit: condFiltersBacklog.limit,
                      begin: condFiltersBacklog.begin,
                      end: condFiltersBacklog.end
                  }))
              }
          }
          dispatch(resetListCustomerFacepayBacklog(callbackSuccessFacepayBacklog))
          break;
      case BANK:
          // const callbackSuccessBankBacklog = () => {
          //     if(condFiltersBacklog.bioId){
          //         dispatch(getListCustomerBankBacklog({
          //             adminId: condFiltersBacklog.adminId,
          //             adminUserName: condFiltersBacklog.adminUserName,
          //             bioId: condFiltersBacklog.bioId,
          //             type: condFiltersBacklog.type.key,
          //             skip: condFiltersBacklog.skip,
          //             limit: condFiltersBacklog.limit,
          //             begin: condFiltersBacklog.begin,
          //             end: condFiltersBacklog.end
          //         }))
          //     }
          // }
          // dispatch(resetListCustomerBankBacklog(callbackSuccessBankBacklog))
          break;
   }
  }

  const handleSubmit = () => {
    const {
      uid,
      cardType,
      id,
      dueDate,
      issueDate,
      issuedAt,
      name,
      dob,
      gender,
      hometown,
      address
    } = data;
    const dataRequest = {
      uid,
      cardType,
      id,
      dueDate,
      issueDate,
      issuedAt,
      name,
      dob,
      gender,
      hometown,
      address
    };
    if (!data.id || !data.name || !data.hometown || !data.address || !data.issuedAt ) return

    dispatch(updateIdCardByCustomer({
      ...dataRequest,
      dob: moment(moment(dob, 'DD/MM/YYYY').toDate()).format('DD/MM/YYYY'),
      issueDate: moment(moment(issueDate, 'DD/MM/YYYY').toDate()).format('DD/MM/YYYY'),
      dueDate: moment(moment(dueDate, 'DD/MM/YYYY').toDate()).format('DD/MM/YYYY')
    }, callbackSuccess))

    onClickClosePopup();
  };

  useEffect(() => {
    setIsErrCustomerPhoto(false);
  }, [item.img]);

  useOnClickOutside(refPop,()=>onClickClosePopup())
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      onClickClosePopup();
    }
});

  return (
    <WrapPopupEditCCCD ref={refPop}>
      <div className="popup-header">
        <div className="popup-title">
          Tùy chỉnh giấy tờ
          <span className="title-value">
            {dataPassportStatus[data.cardType]?.text || "-"}
          </span>
        </div>
        <IcClose className="icon" onClick={onClickClosePopup} />
      </div>
      <div className="info-title">Hình ảnh giấy tờ</div>
      <div className="popup-img">
        <div className="info-img">
          <div className="img-value">Mặt trước</div>
          {isErrCustomerPhoto ? (
            <div className="wrapper">
              <IcTransPhotoDefault />
            </div>
          ) : (
              <img
                src={item.images.frontUrl || ""}
                alt="face kyc"
                onError={() => setIsErrCustomerPhoto(true)}
              />
            )}
        </div>
        <div className="info-img">
          <div className="img-value">Mặt sau</div>
          {isErrCustomerPhoto ? (
            <div className="wrapper">
              <IcTransPhotoDefault />
            </div>
          ) : (
              <img
                src={item.images.backUrl || ""}
                alt="face kyc"
                onError={() => setIsErrCustomerPhoto(true)}
              />
            )}
        </div>
      </div>
      <div className="info-title">Thông tin cá nhân</div>
      <div className="popup-info">
        <div className="info-container">
          <div className="info-item">
            <div className="info-text">Họ tên</div>
            <InputNameInfo
              className="info-input"
              handleSetValueName={handleSetValueName}
              value={data?.name}
              handleValidationUser={handleValidationInput}
            />
          </div>
          <div className="info-item">
            <div className="info-text">Ngày sinh</div>
            <DatePickerItemEdit
                selected={moment(data?.dob, 'DD/MM/YYYY').toDate()}
                onChange={(date) =>
                  setData({
                    dob: date
                      ? moment(date).startOf("day").toDate()
                      : data?.dob,
                  })
                }
                dateFormat="dd/MM/yyyy"
                showTimeSelect={false}
              />
          </div>
          <div className="info-item">
            <div className="info-text">Giới tính</div>
            <InputDropdownGender
              valueDropdown={handleSetValueDropdown}
              listDropDown={Object.values(dataGender)}
              objChoice={data?.gender.toUpperCase()}
              className="input-dropdown"
            />
          </div>
        </div>
        <div className="info-container">
          <div className="info-item">
            <div className="info-text">Nguyên quán</div>
            <InputPlaceOfIssue
              className="info-input"
              handleSetValueName={handleSetValuePlaceOfIssue}
              value={data?.hometown}
              handleValidationUser={handleValidationInput}
            />
          </div>
          <div className="info-item">
            <div className="info-text">Địa chỉ thường trú</div>
            <InputLocation
              className="info-input"
              handleSetValueName={handleSetValueLocation}
              value={data?.address}
              handleValidationUser={handleValidationInput}
            />
          </div>
        </div>
      </div>
      <div className="info-title">Thông tin giấy tờ</div>
      <div className="info-ekyc">
        <div className="info-container">
          <div className="info-item">
            <div className="info-text">Số giấy tờ</div>
            <InputNumberID
              className="info-input-item"
              handleSetValueName={handleSetValueNumberID}
              value={data?.id}
              handleValidationUser={handleValidationInput}
            />
          </div>
          <div className="info-item">
            <div className="info-text">Ngày cấp</div>
            <DatePickerItemEdit
                selected={moment(data?.issueDate, 'DD/MM/YYYY').toDate()}
                onChange={(date) =>
                  setData({
                    issueDate: date
                      ? moment(date).startOf("day").toDate()
                      : data?.issueDate,
                  })
                }
                dateFormat="dd/MM/yyyy"
                showTimeSelect={false}
              />
          </div>
        </div>
        <div className="info-container">
          <div className="info-item">
            <div className="info-text">Nơi cấp</div>
            <InputHometown
              className="info-input"
              handleSetValueName={handleSetValueHomeTown}
              value={data?.issuedAt}
              handleValidationUser={handleValidationInput}
            />
          </div>
        </div>
      </div>
      <div className="info-ekyc">
        <div className="info-container">
          <div className="info-item">
            <div className="info-text">Ngày hết hạn</div>
            <DatePickerItemEdit
                selected={moment(data?.dueDate, 'DD/MM/YYYY').toDate()}
                onChange={(date) =>
                  setData({
                    dueDate: date
                      ? moment(date).startOf("day").toDate()
                      : data?.dueDate,
                  })
                }
                dateFormat="dd/MM/yyyy"
                showTimeSelect={false}
              />
          </div>
        </div>
      </div>
      <div className="info-btn">
        <Button
          text="Lưu lại"
          iconBtn={<IcUploadWhite />}
          className="btn-save"
          onClick={handleSubmit}
        />
      </div>
    </WrapPopupEditCCCD>
  );
};

export default PopupEditInfoCCCD;
