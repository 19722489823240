import React from 'react'

const IcDeviceBacklog = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#F45C5C"/>
        <path d="M15.334 5.33203H8.66732C7.93094 5.33203 7.33398 5.92898 7.33398 6.66536V17.332C7.33398 18.0684 7.93094 18.6654 8.66732 18.6654H15.334C16.0704 18.6654 16.6673 18.0684 16.6673 17.332V6.66536C16.6673 5.92898 16.0704 5.33203 15.334 5.33203Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 16H12.0067" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcDeviceBacklog
