import styled from "styled-components";

const WrapPopupApproved = styled.div`
    width: 260px;
    padding: 16px;
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);

    position: absolute;
    top: 36px;
    right: 90px;

    z-index: 1;
    .wrap-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-icon{
            cursor: pointer;
        }

        &-text{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #313131;
        }
    }

    .wrap-content{
        margin: 8px 0 20px 0;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        &-value{
            color: #313131;
            margin-bottom: 20px;
            span{
                font-weight: 600;
            }
        }
        &-warning{
            color: #F45C5C;
        }
    }
    .wrap-btn{
        display: flex;
        flex-direction: column;
        gap: 8px;
        button{
            width: 100%;
        }
        .btn-cancel{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #B7B7B7;
            background: #F7F7F7;
        }
    }
`

export {
    WrapPopupApproved
}