import React from 'react'

const IcFilterBlue = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 13.5H6.75" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.5 9H4.5" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.75 4.5H2.25" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcFilterBlue
