import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";

//styles
import { WrapCustomerItem } from './index.styles'

//image
import IcFacePrintDefault from '../../../../../../../assets/images/IcFacePrintDefault';
import IcLockWhiteMini from '../../../../../../../assets/images/IcLockWhiteMini';
import IcAlertRed from '../../../../../../../assets/images/IcAlertRed';

//data
import { dataBankType } from '../../../../../../../data/dataBankType';

// constant
import { apiGetBankCustomerAvatar } from '../../../../../../../constant/host';
import { FACEPAY } from '../../../../../../../constant/keys';

export default function CustomerItem({ keySelected, data }) {

  const { accountType, thumbnailId, thumbnailUrl, fullName, phone, isLock, kycStatus } = data

  const [isErrImg, setIsErrImg] = useState(false)
  const [images, setImages] = useState('')
  
  useEffect(() => {
    setIsErrImg(false)
  }, [thumbnailId])

  useEffect(() => {
    if(accountType !== FACEPAY && data){
      setImages(apiGetBankCustomerAvatar(thumbnailId))
    }
  },[data])

  return (
    <WrapCustomerItem className={data.id === keySelected ? "active" : ""}>
      <div className="block-left">
        <div className={isLock ? "lock wrap-avatar" : "wrap-avatar"}>
          {
            (accountType !== FACEPAY && !images) || isErrImg ?
              <IcFacePrintDefault className="face" />
              :
              <img
                className="face"
                src={accountType === FACEPAY ? thumbnailUrl : images}
                alt="facePrint"
                onError={() => setIsErrImg(true)}
              />
          }
          <div className="icon-bank">
            {
              kycStatus && kycStatus?.state === "REJECTED" ? 
              <IcAlertRed/>
              :
              accountType && dataBankType[accountType].icon
            }
          </div>
          {
            isLock && <IcLockWhiteMini className="icon-lock" />
          }

        </div>
        <div className="wrap-fullname">
          <p className="fullname" title={fullName}>{fullName || "-"}</p>
          <p className="type">{accountType && dataBankType[accountType].text}</p>
        </div>
      </div>
      <div className="phone">{phone || "-"}</div>
    </WrapCustomerItem>
  )
}
