import styled from 'styled-components'

const WrapPageItem = styled.div`
    padding: 0 30px;
    min-width: calc(1280px - 100px);
    box-sizing: border-box;
`

const WrapAdminTransactionList = styled(WrapPageItem)`
    height: 100%;
    display: flex;
    flex-direction: column;
    background: red;
`

export { WrapAdminTransactionList }