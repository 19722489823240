import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

// style
import { WrapPopupTransHistoryDetailsWalletDepositLinkCard } from './index.style';
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles';

// image
import IcCloseWhite from '../../../../../../../../../assets/images/IcCloseWhite'
import Logo_DefautBank from "../../../../../../../../../assets/images/Default_Bank.png";
import IcNoteBlue from '../../../../../../../../../assets/images/IcNoteBlue';
import IcSearchDefault from '../../../../../../../../../assets/images/IcSearchDefault';
import IcArrowLeftRight from '../../../../../../../../../assets/images/IcArrowLeftRight';
import IcTimeOutlineGray from '../../../../../../../../../assets/images/IcTimeOutlineGray';
import { PVCOMBANK, VIETTINBANK } from '../../../../../../../../../constant/dataBank';
import IcRefundDoneBgWhite from '../../../../../../../../../assets/images/IcRefundDoneBgWhite';
import IcCloseRedMini from '../../../../../../../../../assets/images/IcCloseRedMini';
import IcAmountDiscountTrans from '../../../../../../../../../assets/images/IcAmountDiscountTrans';
import IcPromotionTrans from '../../../../../../../../../assets/images/IcPromotionTrans';
import IcCheckBgBlue from '../../../../../../../../../assets/images/IcCheckBgBlue';
import { convertDateTime } from '../../../../../../../../../helper/convertDateTime';
import IcRefundWhite from '../../../../../../../../../assets/images/IcRefundWhite';
import IcRefundGray from '../../../../../../../../../assets/images/IcRefundGray';
import IcNotifyError from '../../../../../../../../../assets/images/IcNotifyError';
import IcAmountTrans from '../../../../../../../../../assets/images/IcAmountTrans';
import IcUserBlueDefault from '../../../../../../../../../assets/images/IcUserBlueDefault';
import IcFacePrintDefault from '../../../../../../../../../assets/images/IcFacePrintDefault';
import IcPhoneBlueDefault from '../../../../../../../../../assets/images/IcPhoneBlueDefault';
import IcEmailBgBlue from '../../../../../../../../../assets/images/IcEmailBgBlue';
import IcCalendaDefault from '../../../../../../../../../assets/images/IcCalendaDefault';
import IcLinkedID from '../../../../../../../../../assets/images/IcLinkedID';
import IcCardDefault from '../../../../../../../../../assets/images/IcCardDefault';

//hook
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'

// constant
import { apiGetBankCustomerAvatar } from '../../../../../../../../../constant/host';
import { ACCOUNTANT, CTG, FACEPAY, LINK_CARD, OCB, POPUP_NOTE_TRANSACTION, SMARTPAY, TPBANK } from '../../../../../../../../../constant/keys';

//component
import EmptyDataControl from '../../../../../../../../Control/EmptyDataControl'
import Button from '../../../../../../../../Control/Button'
import PopupAttachNote from '../../../../../CustomerV2/CustomerInfoExpand/TransactionHistory/PopupAttachNote';

import { dataBankType, dataLogoMerchant } from '../../../../../../../../../data/dataBankType';

// helper
import numberFormatToThousand from '../../../../../../../../../helper/numberFormatToThousand';
import PopupConfirmApprove from '../../../../../CustomerV2/CustomerInfoExpand/TransactionHistory/TransHistoryItem/TransHistoryDetailsItem/TransactionDetail/PopupComfirmApprove';
import { mapErrorTransaction } from '../../../../../../../../../helper/mapErrorTransaction';



const PopupDetailWalletDepositLinkCard = ({ dataTransaction, onClickClosePopup, logoMerchent, reqType, detailsTrans }) => {

    const refPopup = useRef()

    const [isErrImg, setIsErrImg] = useState(false)
    const [isShowPopupNote, setIsShowPopupNote] = useState(false)
    const [isShowPopupComfirmRefund, setIsShowPopupComfirmRefund] = useState(false)

    const accountant = localStorage.getItem('FACEPAY_PORTAL_LOGIN')
    let accountantShowRefund = JSON.parse(accountant)

    useEffect(() => {
        setIsErrImg(false)
    }, [dataTransaction.to.userInfo.thumbnailId])

    useOnClickOutside(refPopup, ()=> {
        if(isShowPopupComfirmRefund) return
        onClickClosePopup()
    })

    window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          onClickClosePopup();
        }
    });
   
    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupTransHistoryDetailsWalletDepositLinkCard ref={refPopup}>
                    <IcCloseWhite className="icon-close" onClick={onClickClosePopup} />
                    <div className={dataTransaction.paymentInfo.status === 1 ? "wrap-status" : "wrap-status fail"}>
                        <div className="content">
                            {dataTransaction.paymentInfo.status === 1 ? <IcCheckBgBlue className="icon" /> : <IcCloseRedMini className="icon" />}
                            <p className="facepay-id">{dataTransaction.paymentInfo.facepayTrxCode}</p>
                        </div>
                        <div className="content">
                            <div className='value'>
                                {
                                    dataTransaction.transactionType.toUpperCase() === LINK_CARD ? 'Liên kết thẻ' : "Nạp tiền"
                                }
                            </div>
                            <div className="time">{convertDateTime(dataTransaction.paymentInfo.createdAt, "HH:mm:ss - DD/MM/YYYY") || "-"}</div>
                            {
                                dataTransaction.paymentInfo.refund === 1 ? (
                                    <div>
                                        <IcRefundDoneBgWhite />
                                        <p>Đã hoàn tiền</p>
                                    </div>
                                )
                                : 
                                accountantShowRefund.privileges
                                .includes(ACCOUNTANT) ? 
                                (
                                    <Button
                                    text="Hoàn tiền"
                                    iconBtn={dataTransaction.paymentInfo.supplier === CTG ? <IcRefundWhite /> : <IcRefundGray />}
                                    className={dataTransaction.paymentInfo.supplier === CTG ? "btn-refund" : "disable"}
                                    onClick={() => setIsShowPopupComfirmRefund(true)}
                                    isDisable={dataTransaction.paymentInfo.supplier === CTG ? false : true}
                                    />
                                ) : ""
                            }
                        </div>
                    </div>
                    {
                        dataTransaction.paymentInfo.status === 0 &&
                        <div className="wrap-reason">
                            <IcNotifyError className="error-icon" />
                            <div className="errro-text">
                                {mapErrorTransaction({
                                    supplier: dataTransaction.paymentInfo.supplier,
                                    paymentCode: dataTransaction.paymentInfo.paymentCode,
                                    paymentDescription: dataTransaction.paymentInfo.paymentDescription
                                })}
                            </div>
                        </div>
                    }
                    {
                        dataTransaction.paymentInfo.refund === 1 ? (
                            <div className='wrap-refund'>
                                <div className='refund'>
                                    <div className='icon'>
                                        <IcArrowLeftRight />
                                    </div>
                                    <p>{`${dataTransaction.paymentInfo.refundBy || "-"} đã thực hiện hoàn tiền`}</p>
                                </div>
                                <div className='refund'>
                                    <div className='icon'>
                                        <IcTimeOutlineGray />
                                    </div>
                                    <p className='refund-time'>{dataTransaction.paymentInfo.refundAt ? convertDateTime(dataTransaction.paymentInfo.refundAt, "HH:mm:ss - DD/MM/YYYY") : "-"}</p>
                                </div>
                            </div>
                        ) : ""
                    }
                    <div className="wrap-info-transaction">
                        <div className="info-transaction">
                            <div className="block-info">
                                <div className="wrap-value-title">
                                    <p className="title">Thông tin giao dịch</p> 
                                    <div className="wrap-value-note">
                                        <IcNoteBlue className="ic-note" onClick={() => setIsShowPopupNote(true)} />
                                        <p className="note">Ghi chú</p>
                                        {isShowPopupNote &&
                                        <PopupAttachNote 
                                            isStylePopup={true}
                                            isShowContentPopup={POPUP_NOTE_TRANSACTION}
                                            onClickClosePopup={() => setIsShowPopupNote(false)}
                                            data={dataTransaction}
                                        />}
                                    </div>
                                </div>
                                <div className="wrap-info">
                                    <div className="info">
                                        <div className="icon">
                                            {dataTransaction.paymentInfo.supplier === FACEPAY ? dataBankType[dataTransaction.paymentInfo.supplier]?.icon : logoMerchent[dataTransaction.paymentInfo.supplier]?.icon || <img src={Logo_DefautBank} className="icon" />}
                                        </div>
                                        <div className="content">
                                            <p className="value">
                                            {
                                                dataTransaction.paymentInfo.supplier === FACEPAY ? dataLogoMerchant[dataTransaction.paymentInfo.supplier]?.text : logoMerchent[dataTransaction.paymentInfo.supplier]?.bankName
                                            }
                                            </p>
                                            <p className="text">Ngân hàng</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcAmountTrans className="icon" />
                                        <div className="content">
                                            <p className="value">{dataTransaction.paymentInfo.transAmount || 0}</p>
                                            <p className="text">Số tiền thanh toán</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcAmountDiscountTrans className="icon" />
                                        <div className="content">
                                            <p className="value">{numberFormatToThousand(dataTransaction.paymentInfo.discount) || 0}</p>
                                            <p className="text">Số tiền khấu trừ (đ)</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcPromotionTrans className="icon" />
                                        <div className="content">
                                            <p className="value">{dataTransaction.paymentInfo.promotionCode || "-"}</p>
                                            <p className="text">Mã khuyến mãi</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcAmountTrans className="icon" />
                                        <div className="content">
                                            <p className="value">{numberFormatToThousand(dataTransaction.paymentInfo.originAmount) || 0}</p>
                                            <p className="text">Số tiền gốc</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-info">
                                <p className="title">Tài khoản liên kết</p>
                                <div className="wrap-info">
                                <div className="info">
                                        <IcLinkedID className="icon" />
                                        <div className="content">
                                            <p className="value">{dataTransaction.from.accountInfo?.linkedId || "-"}</p>
                                            <p className="text">LinkedID</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcAmountTrans className="icon" />
                                        <div className="content">
                                            <p className="value">{dataTransaction.from.accountInfo.holderNumber || "-"}</p>
                                            <p className="text">Số thẻ</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcUserBlueDefault className="icon" />
                                        <div className="content">
                                            <p className="value">{dataTransaction.from.accountInfo.holderName || "-"}</p>
                                            <p className="text">Chủ thẻ</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcCardDefault className="icon" />
                                        <div className="content">
                                            <p className="value">{dataTransaction.from.accountInfo.dayAt ? convertDateTime(dataTransaction.from.accountInfo.dayAt, "MM/YY") : "-"}</p>
                                            <p className="text">Ngày đăng ký</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-info-customer">
                                <p className="title">Khách hàng</p>
                            {
                                dataTransaction.to.userInfo.profileId ? (
                                    <>
                                        <div className="wrap-info-customer">
                                            <div className="info">
                                                {
                                                    isErrImg ?
                                                        <IcFacePrintDefault className="icon" />
                                                        :
                                                        <img
                                                            className="icon"
                                                            src={dataTransaction.to.userInfo.thumbnailUrl}
                                                            alt="facePrint"
                                                            onError={() => setIsErrImg(true)}

                                                        />
                                                }
                                                <div className="content">
                                                    <p className="value">{dataTransaction.to.userInfo.profileId || "-"}</p>
                                                    <p className="text">Profile ID</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcUserBlueDefault className="icon" />
                                                <div className="content">
                                                    <p className="value">{dataTransaction.to.userInfo.fullName || "-"}</p>
                                                    <p className="text">Họ tên</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcPhoneBlueDefault className="icon" />
                                                <div className="content">
                                                    <p className="value">{dataTransaction.to.userInfo.phone || "-"}</p>
                                                    <p className="text">Số điện thoại</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcUserBlueDefault className="icon" />
                                                <div className="content">
                                                    <p className="value" title={dataTransaction.to.userInfo.userName}>{dataTransaction.to.userInfo.userName || "-"}</p>
                                                    <p className="text">Username</p>
                                                </div>
                                            </div>

                                            <div className="info">
                                                <IcEmailBgBlue className="icon" />
                                                <div className="content">
                                                    <p className="value" title={dataTransaction.to.userInfo.email}>{dataTransaction.to.userInfo.email || "-"}</p>
                                                    <p className="text">Email đăng ký</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcCalendaDefault className="icon" />
                                                <div className="content">
                                                    <p className="value" >{dataTransaction.to.userInfo.registeredAt ?  convertDateTime(dataTransaction.to.userInfo.registeredAt, "HH:mm - DD/MM/YYYY") : "-"}</p>
                                                    <p className="text">Ngày đăng ký</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='info-customer'>Chi tiết khách hàng</p>            
                                    </>                       
                                ): (
                                    <EmptyDataControl
                                        icon={<IcSearchDefault />}
                                        text={"Không tìm được thông tin khách hàng"}
                                    />
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </WrapPopupTransHistoryDetailsWalletDepositLinkCard>
            </div>
            {
                isShowPopupComfirmRefund ? 
                <PopupConfirmApprove 
                    detailsTrans={detailsTrans}
                    dataTransacion={dataTransaction}
                    onClickClosePopupComfirm={() => setIsShowPopupComfirmRefund(false)}
                    reqType={reqType}
                /> : ""
            }
        </OverlayFullScreen>
    )
}

export default PopupDetailWalletDepositLinkCard
