import React from 'react'

const IcCalendaDefault = (props) => {
    return (
        <svg {...props} width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6062_61178)">
        <circle cx="18" cy="18.5" r="18" fill="#EFF9FF"/>
        <path d="M23.9496 11.6992H12.0496C11.1107 11.6992 10.3496 12.4603 10.3496 13.3992V25.2992C10.3496 26.2381 11.1107 26.9992 12.0496 26.9992H23.9496C24.8885 26.9992 25.6496 26.2381 25.6496 25.2992V13.3992C25.6496 12.4603 24.8885 11.6992 23.9496 11.6992Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.3496 16.8008H25.6496" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.3994 10V13.4" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.5996 10V13.4" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_6062_61178">
        <rect width="36" height="36" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default IcCalendaDefault
