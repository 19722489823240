import React from 'react'

const IcStoreMini = (props) => {
    return (
        <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6071_67042)">
                <circle cx={16} cy="16.5" r={16} fill="#EFF9FF" />
                <g clipPath="url(#clip1_6071_67042)">
                    <path d="M9.20117 15.7461V19.2078C9.20117 24.0572 9.20117 24.0572 12.5993 24.0572H18.0408C22.8012 24.0572 22.8012 24.0572 22.8012 20.5955V15.7461" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.9995 16.5009C17.3822 16.5009 18.4022 15.3751 18.2662 13.9924L17.7675 8.94531H14.2391L13.7329 13.9924C13.5969 15.3751 14.6169 16.5009 15.9995 16.5009Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20.7681 16.5009C22.2943 16.5009 23.4125 15.2618 23.2614 13.7431L23.0498 11.6653C22.7778 9.70087 22.0223 8.94531 20.0427 8.94531H17.7383L18.2672 14.2418C18.3956 15.4884 19.5214 16.5009 20.7681 16.5009Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.1945 16.5009C12.4412 16.5009 13.567 15.4884 13.6878 14.2418L13.8541 12.572L14.2167 8.94531H11.9123C9.93274 8.94531 9.17718 9.70087 8.90518 11.6653L8.70118 13.7431C8.55007 15.2618 9.66829 16.5009 11.1945 16.5009Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16.0002 20.2773C14.7384 20.2773 14.1113 20.9045 14.1113 22.1662V24.0551H17.8891V22.1662C17.8891 20.9045 17.262 20.2773 16.0002 20.2773Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_6071_67042">
                    <rect width={32} height={32} fill="white" transform="translate(0 0.5)" />
                </clipPath>
                <clipPath id="clip1_6071_67042">
                    <rect width="18.1333" height="18.1333" fill="white" transform="translate(6.93359 7.43359)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcStoreMini
