// FACEPAY
export const GET_CUSTOMER_ANALYTIC = 'GET_CUSTOMER_ANALYTIC'
export const GET_CUSTOMER_ANALYTIC_ERR = 'GET_CUSTOMER_ANALYTIC_ERR'
export const RESET_CUSTOMER_ANALYTIC = 'RESET_CUSTOMER_ANALYTIC'

// BANK
export const GET_CUSTOMER_BANK_ANALYTIC = 'GET_CUSTOMER_BANK_ANALYTIC'
export const GET_CUSTOMER_BANK_ANALYTIC_ERR = 'GET_CUSTOMER_BANK_ANALYTIC_ERR'
export const RESET_CUSTOMER_BANK_ANALYTIC = 'RESET_CUSTOMER_BANK_ANALYTIC'

export const LOADING_CUSTOMER_ANALYTIC = 'LOADING_CUSTOMER_ANALYTIC'