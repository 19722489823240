import React from 'react'

//styles
import * as styles from './index.styles'

// component
import Info from './Info'
import MoreInfo from './MoreInfo'

export default function CustomerDetail() {
  return (
    <styles.Bound>
      <p className="title">Chi tiết khách hàng</p>
      <div className='wrap-detail'>
        <Info />
        <MoreInfo />
      </div>
    </styles.Bound>
  )
}
