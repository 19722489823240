import React from "react";

//styled
import { WrapControlBlockItem } from "./index.styles";

// image
import IcCheckBgBlue from "../../../../../../../../assets/images/IcCheckBgBlue";
import IcCloseRedMini from "../../../../../../../../assets/images/IcCloseRedMini";
import IcCornerRight from "../../../../../../../../assets/images/IcCornerRight";

//constants
import {
  BACKLOG_NOTE,
  CUSTOMER_NOTE,
  PAYMENT_NOTE,
  SUCCESS,
} from "../../../../../../../../constant/keys";

// helper
import convertTypeBacklogToStr from "../../../../../../../../helper/convertTypeBacklogToStr";
import numberFormatToThousand from "../../../../../../../../helper/numberFormatToThousand";
import { convertDateTime } from "../../../../../../../../helper/convertDateTime";

const ControlBlockItem = ({ item }) => {
  return (
    <WrapControlBlockItem>

      {item.type === PAYMENT_NOTE && (
        <div className="block-success">
          <div className={item.state === SUCCESS ? "success-title" : "reject-title"} >
                <div className="note-payment-content">
                  <div className="note-payment-title">
                    <div className="success">
                      {item.state === SUCCESS ? <IcCheckBgBlue /> : <IcCloseRedMini />}
                      <span className="value">
                        {item.fbTransactionCode || "-"}
                      </span>
                    </div>
                    <div className="value-amount">
                      {numberFormatToThousand(item.amount) || 0}
                    </div>
                    <div className="line-success"></div>
                  </div>
                  <div className="note-payment-desc">
                    <div className="text">Lịch sử giao dịch</div>
                    <div className="text">
                      {convertDateTime(
                        item.createAt,
                        "HH:mm - DD/MM/YYYY"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {
                item.noteGroups.map((item, index) => (
                  <div className={`${index >= 1 && 'note-backlog-content'}`} key={index}>
                    <div className="note-backlog">
                      <div className="note-backlog-icon">
                        {index >= 1 && <IcCornerRight />}
                      </div>
                        <div className="note-backlog-text">
                          {item.message}
                        </div>
                    </div>
                    <div className="note-user">
                      <div className="value-user">{item.adminUserName}</div>
                      <div className="value-user">{convertDateTime(
                        item.createAt,
                        "HH:mm - DD/MM/YYYY"
                      )}</div>
                    </div>
                  </div>
                ))
              }
          {/* <div className="note-user">
              <div className="value-user">{item.adminUserName}</div>
              <div className="value-user">
                {convertDateTime(item.updateAt, "HH:mm - DD/MM/YYYY")}
              </div>
          </div> */}
        </div>
      )}

      {item.type === CUSTOMER_NOTE && (
        <div className="block-success">
          {
            item.noteGroups?.map((item, index) => (
              <div className={`${index >= 1 && 'note-backlog-content'}`} key={index}>
                <div className="note-backlog">
                  <div className="note-backlog-text">
                    {item.message}
                  </div>
                </div>
                <div className="note-user">
                  <div className="value-user">{item.adminUserName}</div>
                  <div className="value-user">{convertDateTime(
                        item.createAt,
                        "HH:mm - DD/MM/YYYY"
                      )}</div>
                </div>
              </div>
            ))
          }
          {/* <div className="note-user">
            <div className="value-user">{item.adminUserName}</div>
            <div className="value-user">
              {convertDateTime(
                item.updateAt,
                "HH:mm - DD/MM/YYYY"
              )}
            </div>
          </div> */}
        </div>
      )}

      {item.type === BACKLOG_NOTE && (
        <div className="block-success">
          <div className={`refresh-title ${convertTypeBacklogToStr(item.backlogType).style}`}>
                <div className="note-left">
                  {
                    convertTypeBacklogToStr((item.backlogType),(item.info),"success").str
                  }
                  <div
                    className={`line-refresh ${
                      convertTypeBacklogToStr(item.backlogType).style
                    }`}
                  ></div>
                  <div className="note-right">
                    <div className="text">Backlog tài khoản</div>
                    <div className="text">
                      {convertDateTime(item.createAt, "HH:mm - DD/MM/YYYY")}
                    </div>
                  </div>
                </div>
              </div>
              {
                item.noteGroups.map((item, index) => (
                  <div className={`${index >= 1 && 'note-backlog-content'}`} key={index}>
                    <div className="note-backlog">
                      <div className="note-backlog-icon">
                        {index >= 1 && <IcCornerRight />}
                      </div>
                      <div className="note-backlog-text">
                        {item.message}
                      </div>
                    </div>
                    <div className="note-user">
                      <div className="value-user">{item.adminUserName}</div>
                      <div className="value-user">{convertDateTime(
                        item.createAt,
                        "HH:mm - DD/MM/YYYY"
                      )}</div>
                    </div>
                  </div>
                ))
              }
          {/* <div className="note-user">
            <div className="value-user">{item.adminUserName}</div>
            <div className="value-user">
              {convertDateTime(item.updateAt, "HH:mm - DD/MM/YYYY")}
            </div>
          </div> */}
        </div>
      )}
    </WrapControlBlockItem>
  );
};

export default ControlBlockItem;
