import styled from 'styled-components'

//style
import { WrapPageItem } from '../WaitingCensorship/index.styles'

const WrapDetail = styled(WrapPageItem)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    /* padding-bottom: 150px; */
    overflow: hidden;
    .title-detail{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        color: #313131;
        padding: 22px 0 12px 0;
    }
`
export { WrapDetail }