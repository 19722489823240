
import { combineReducers } from "redux";

// reducer
import loginReducer from './login/loginReducer'
import transactionReducer from './transactionV2/transactionReducer'
import analyticReducer from './analytic'
import notifiesReducer from './notifies'
import merchantReducer from './merchant'
import bankReducer from './bank'
import settingReducer from './setting'
import customerReducer from './customer'
import permissionReducer from './permission'
import censorshipReducer from './censorship'
import loading from './loading'
import deviceReducer from './deviceV2'
import businessReducer from './business'

// types
import { LOGOUT } from "./login/types";

const app = combineReducers({
  loginReducer,
  transactionReducer,
  analyticReducer,
  notifiesReducer,
  deviceReducer,
  merchantReducer,
  bankReducer,
  settingReducer,
  customerReducer,
  permissionReducer,
  censorshipReducer,
  loading,
  businessReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) state = undefined

  return app(state, action);
};

export default rootReducer;