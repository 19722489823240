import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//style
import { OverlayFullScreen } from '../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPage, WrapBusinesManagerDetails } from './index.styled'
//image
import IcCloseWhite from '../../../../../../assets/images/IcCloseWhite'
import IcNewRegister from '../../../../../../assets/images/IcNewRegister'
import IcWaitUpdate from '../../../../../../assets/images/IcWaitUpdate'
import IcUpdateDoc from '../../../../../../assets/images/IcUpdateDoc'
import IcRefreshBlue from '../../../../../../assets/images/IcRefeshBlue'
import IcCloseCircleRed from '../../../../../../assets/images/IcCloseCircleRed'
import IcInfoCircle from '../../../../../../assets/images/IcInfoCircle'
import IcEditBlue from '../../../../../../assets/images/IcEditBlue'

//help
import { convertDateTime } from '../../../../../../helper/convertDateTime'
import { DEV_SV, PROD_SV, QC_SV, SBX_SV } from '../../../../../../constant/environmentTypes'
import { BACKLOG, BUSSINESS_PPOFILE, REQUIRE_LICENSE_UPDATE, NEW, ACCEPTED, NOTE, LICENSE_UPDATED, DENIED, PENDING } from '../../../../../../constant/keys'

//component
import Button from '../../../../../Control/Button'
import TabListMenu from '../../../../../Control/TabListMenu'
import BackLog from './BackLog'
import BussinessProfile from './BusinessProfile'
import PopupLocked from './PopupLocked'
import PopupApproved from './PopupApproved'
import PopupUpdateRequest from './BusinessProfile/PopupUpdateRequest'
import BusinessNote from './BusinessNote'
import { getListNoteBusiness, setIsShowPopupUpdateRequest } from '../../../../../../redux/business/listBusinessManage/action'
import { useOnClickOutside } from '../../../../../../hook/useClickOutside'

const dataMenu = [
    {
        title: 'Hồ sơ doanh nghiệp',
        key: BUSSINESS_PPOFILE,
        component: {
            type: 'BUSSINESS_PPOFILE',
            comp: <BussinessProfile />
        },
        envServerValid: [
            DEV_SV, QC_SV, PROD_SV, SBX_SV
        ]
    },
    {
        title: 'Backlog',
        key: BACKLOG,
        component: {
            type: 'BACKLOG',
            comp: <BackLog />
        },
        envServerValid: [
            DEV_SV, QC_SV, PROD_SV, SBX_SV
        ]
    },
]

const BussinessManagerDetails = ({ onClickClosePopup }) => {

    const dispatch = useDispatch()
    const refPopup = useRef()

    const { businessInfo, reasonRejectBusiness, isShowPopupUpdateRequest, listNoteBusiness } = useSelector((state) => ({
        businessInfo: state.businessReducer.listBusinessManage.businessDetail.businessInfo,
        reasonRejectBusiness: state.censorshipReducer.listReasonReducer.reasonRejectBusiness,
        isShowPopupUpdateRequest: state.businessReducer.listBusinessManage.isShowPopupUpdateRequest,
        listNoteBusiness: state.businessReducer.listBusinessManage.listNoteBusiness
    }))

    const [component, setComponent] = useState(null)
    const [isShowPopup, setIsShowPopup] = useState("")

    useEffect(() => {
        dispatch(getListNoteBusiness({
            businessID: businessInfo.id,
            limit: 15,
            lastItem: ""
        }))
    }, [])

    const _handleChangeTab = (component) => {
        setComponent(component.comp)
    }

    const _handleClickBtn = (key) => {
        if (key === ACCEPTED) {
            setIsShowPopup(key)
            return
        }
        if (key === DENIED) {
            setIsShowPopup(key)
            return
        }
        if (key === NOTE) {
            setIsShowPopup(key)
            return
        }
    }

    useOnClickOutside(refPopup, () => {
        if (isShowPopupUpdateRequest) return
        onClickClosePopup()
        dispatch(setIsShowPopupUpdateRequest({ isShowPopupUpdateRequest: false }))
    })

    window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
            onClickClosePopup()
            dispatch(setIsShowPopupUpdateRequest({ isShowPopupUpdateRequest: false }))
        }
    })

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPage>
                    <IcCloseWhite className="icon-close" onClick={onClickClosePopup} />
                    <WrapBusinesManagerDetails ref={refPopup}>
                        <div className='wrap-business-details'>
                            <div className="wrap-status">
                                <div className="content">
                                    {
                                        businessInfo.censorshipState === NEW
                                            ? <IcNewRegister className="icon" />
                                            : businessInfo.censorshipState === REQUIRE_LICENSE_UPDATE
                                                ? < IcWaitUpdate className="icon" />
                                                : businessInfo.censorshipState === LICENSE_UPDATED
                                                    ? <IcUpdateDoc className="icon" />
                                                    : null
                                    }
                                    <p className="merchant-id">
                                        {businessInfo.businessCode ? `BusinessID: ${businessInfo.businessCode}` : "-"}</p>
                                </div>
                                <div className="content">
                                    {
                                        businessInfo.censorshipStatus === DENIED &&
                                        <div className='wait-update'>
                                            <div className='refuse-request'>
                                                <IcCloseCircleRed />
                                                Đã từ chối hồ sơ
                                            </div>
                                            <p className={'user-name denied'}>{businessInfo.censorshipAdmin}</p>
                                            <div className="time">
                                                {businessInfo.lastCSUpdateAt ? convertDateTime(businessInfo.lastCSUpdateAt, "HH:mm - DD/MM/YYYY") : "-"}
                                            </div>
                                        </div>
                                    }
                                    {
                                        businessInfo.censorshipStatus === PENDING ?
                                            businessInfo.censorshipState === REQUIRE_LICENSE_UPDATE ?
                                                <div className='wait-update'>
                                                    <div className='update-request'>
                                                        <IcRefreshBlue />
                                                        Đã gửi yêu cầu cập nhật giấy tờ
                                                    </div>
                                                    <p className={'user-name'}>{businessInfo.censorshipAdmin}</p>
                                                    <div className="time">
                                                        {businessInfo.lastCSUpdateAt ? convertDateTime(businessInfo.lastCSUpdateAt, "HH:mm - DD/MM/YYYY") : "-"}
                                                    </div>
                                                </div>
                                                :

                                                <div className='wrap-btn'>
                                                    <Button
                                                        text="Phê duyệt"
                                                        className="btn-approve"
                                                        onClick={() => _handleClickBtn(ACCEPTED)}
                                                    />
                                                    <Button
                                                        text="Từ chối"
                                                        className="btn-refuse"
                                                        onClick={() => _handleClickBtn(DENIED)}
                                                    />
                                                    {
                                                        isShowPopup === DENIED &&
                                                        <PopupLocked
                                                            reason={reasonRejectBusiness}
                                                            businessId={businessInfo.id}
                                                            onClickClosePopup={() => setIsShowPopup("")}
                                                        />
                                                    }
                                                    {
                                                        isShowPopup === ACCEPTED &&
                                                        <PopupApproved
                                                            businessInfo={businessInfo}
                                                            onClickClosePopup={() => setIsShowPopup("")}
                                                        />
                                                    }
                                                </div>
                                            : <></>
                                    }
                                </div>
                            </div>
                            <div className='wrap-tab-list'>
                                <div className='tab-list'>
                                    <TabListMenu
                                        componentSelected={_handleChangeTab}
                                        data={dataMenu}
                                    />
                                </div>
                                <div className='note'>
                                    <p className='text-note'>
                                        {
                                            businessInfo.censorshipState === LICENSE_UPDATED
                                                ?
                                                "Cập nhật hồ sơ"
                                                :
                                                "Đăng ký mới"
                                        }
                                    </p>
                                    <div className='time-note'>
                                        <IcInfoCircle className='ic-info' />
                                        <p>{businessInfo.lastBOUpdateAt ? convertDateTime(businessInfo.lastBOUpdateAt, "HH:mm - DD/MM/YYYY") : "-"}</p>
                                    </div>
                                    <div className='wrap-ic-note' onClick={() => _handleClickBtn(NOTE)}>
                                        {listNoteBusiness.length !== 0 && <p className='circle-red'></p>}
                                        <IcEditBlue />
                                    </div>
                                </div>
                            </div>
                            {component}
                        </div>
                        {
                            isShowPopup === NOTE &&
                            <BusinessNote
                                setIsShowPopup={setIsShowPopup}
                                onClickClosePopup={onClickClosePopup}
                            />
                        }
                    </WrapBusinesManagerDetails>
                </WrapPage>
                {
                    isShowPopupUpdateRequest &&
                    <PopupUpdateRequest
                        businessInfo={businessInfo}
                    />
                }
            </div>
        </OverlayFullScreen>
    )
}

export default BussinessManagerDetails