import React from 'react'

const IcCamera = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.3334 12.6667C15.3334 13.0203 15.1929 13.3594 14.9428 13.6095C14.6928 13.8595 14.3536 14 14 14H2.00002C1.6464 14 1.30726 13.8595 1.05721 13.6095C0.807163 13.3594 0.666687 13.0203 0.666687 12.6667V5.33333C0.666687 4.97971 0.807163 4.64057 1.05721 4.39052C1.30726 4.14048 1.6464 4 2.00002 4H4.66669L6.00002 2H10L11.3334 4H14C14.3536 4 14.6928 4.14048 14.9428 4.39052C15.1929 4.64057 15.3334 4.97971 15.3334 5.33333V12.6667Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.00001 11.3333C9.47277 11.3333 10.6667 10.1394 10.6667 8.66667C10.6667 7.19391 9.47277 6 8.00001 6C6.52725 6 5.33334 7.19391 5.33334 8.66667C5.33334 10.1394 6.52725 11.3333 8.00001 11.3333Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IcCamera