import React from 'react'

const IcFacepayMini = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.3335 16.6654L12.2036 7.78266L7.76855 3.33203H3.3335L7.76855 7.78266L3.3335 12.2333V16.6654ZM12.7502 12.2333H8.32445L12.7688 7.78266L8.32445 3.33203H12.7688L16.6668 7.78266L12.7502 12.2333Z" fill="#33ADFF" />
        </svg>
    )
}

export default IcFacepayMini
