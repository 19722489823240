import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//style
import * as styles from "./index.styles";

// image
import IcDeviceBgBlue from "../../../../../../../../assets/images/IcDeviceBgBlue";
import IcTimeBlue from "../../../../../../../../assets/images/IcTimeBlue";
import IcDeviceStatusGray from "../../../../../../../../assets/images/IcDeviceStatusGray";
import IcDeviceStatusActive from "../../../../../../../../assets/images/IcDeviceStatusActive";
import IcNoDataDevice from "../../../../../../../../assets/images/IcNoDataDevice";

// helper
import { convertDateTime } from "../../../../../../../../helper/convertDateTime";

// data
// import { dataDevice } from "../../../../../../../../mockData/dataDevice";
// import {
//   CONNECT,
//   dataDeviceStatus,
// } from "../../../../../../../../data/dataCustomer";

// component
import EmptyDataControl from "../../../../../../../Control/EmptyDataControl";

// redux
import { getListDeviceLogByCustomer, resetListDeviceHistoryCustomer, resetListEkycHistoryCustomer, resetListVerifyHistoryCustomer } from "../../../../../../../../redux/customer/customerDetail/action";
import { LOADING_GET_LIST_DEVICE_CUSTOMER } from "../../../../../../../../redux/customer/customerDetail/types";
import LoadingLocal from "../../../../../../../Control/LoadingLocal";
import { FACEPAY } from '../../../../../../../../constant/keys'


export default function Device() {
  const dispatch = useDispatch();

  const { details, listDevice, isTypeLoading, reqType } = useSelector((state) => ({
    details: state.customerReducer.customerDetailReducer.details,
    listDevice: state.customerReducer.customerDetailReducer.listDevice,

    reqType: state.customerReducer.listCustomerReducer.reqType,

    isTypeLoading: state.loading[LOADING_GET_LIST_DEVICE_CUSTOMER],
  }));

  useEffect(() => {
    dispatch(resetListVerifyHistoryCustomer())
    dispatch(resetListEkycHistoryCustomer())
  },[])

  useEffect(() => {
    if(reqType !== FACEPAY) return
    if (details?.id) {
      dispatch(getListDeviceLogByCustomer({ uid: details.id }));
    }else{
      dispatch(resetListDeviceHistoryCustomer())
    }
  }, [details]);

  return (
    <styles.Bound>
      { isTypeLoading ? (
          <LoadingLocal />
        ) : (
          listDevice?.length === 0  && !isTypeLoading ? (
            <div className="wrap-emty">
              <EmptyDataControl
                icon={<IcNoDataDevice />}
                text="Tài khoản chưa cập nhật dữ liệu"
              />
            </div>
          ) : (
            <div className="wrap-list-device">
              {listDevice?.map((item, index) => (
                <div className="wrap-container" key={index}>
                  <div className="wrap-header">
                    <div className="header-left">
                      {index === 0 ? (
                        <div className="type-active">
                          <IcDeviceStatusActive className="type-icon"/>
                          <div className="type-text">Đang liên kết</div>
                        </div>
                      ) : (
                        <div className="type-pending">
                          <IcDeviceStatusGray className="type-icon"/>
                          <div className="type-text">Đã liên kết</div>
                        </div>
                      )}
                      <div className="value">{item.deviceName || "-"}</div>
                    </div>
                    <div className="info-time">
                      Đăng nhập lần cuối
                      <span className="value-time">
                        {item.lastLogin
                          ? convertDateTime(item.lastLogin, "HH:mm - DD/MM/YYYY")
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="wrap-content">
                    <div className="device-title">Thông tin thiết bị</div>
                    <div className="device">
                      <div className="device-item">
                        <IcDeviceBgBlue />
                        <div className="device-info">
                          <div className="device-value">{item.deviceId || "-"}</div>
                          <div className="device-text">DeviceID</div>
                        </div>
                      </div>
                      <div className="device-item">
                        <IcDeviceBgBlue />
                        <div className="device-info">
                          <div className="device-value">
                            {item.deviceName || "-"}
                          </div>
                          <div className="device-text">Tên thiết bị</div>
                        </div>
                      </div>
                      <div className="device-item">
                        <IcTimeBlue />
                        <div className="device-info">
                          <div className="device-value">
                            {convertDateTime(item.linkedAt, "HH:mm - DD/MM/YYYY") ||
                              "-"}
                          </div>
                          <div className="device-text">Thời gian liên kết</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        )
      }
    </styles.Bound>
  );
}
