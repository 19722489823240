import styled from "styled-components";

const WrapInfoKycCCCD = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
    .info-item{
        display: flex;
        align-items: center;
        gap: 10px;
        height: 100%;
        .value{
            width: 300px;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #313131;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .text{
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #777777;
        }
    }

`

export {
    WrapInfoKycCCCD
}