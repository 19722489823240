import styled from "styled-components";

const WrapPopupDeviceDetail = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .disable{  
        color: #B7B7B7 !important;
    }

    .popup-detail{
        width: calc(500px - 24px);
        height: calc(537px - 24px);
        background: #F7F7F7;
        position: relative;
        padding: 12px;
        .icon-close{
            position: absolute;
            top: -25px;
            right: 5px;
            cursor: pointer;
        }

        .detail-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 13px;
            .title-left{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
                display: flex;
                align-items: center;
                gap: 4px;
            }
            .title-right{
                display: flex;
                align-items: center;
                .color-status{
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    letter-spacing: -0.01em;
                    color: ${props => props.colorByStatus}
                }
                .icon-status{
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: ${props => props.colorByStatus};
                    margin: 0 4px 0 12px;
                }
                .normal{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.01em;
                }
            }
        }

        .detail-content{
            height: calc(479px - 24px);
            padding: 12px;
            background: #FFF;
            border-radius: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .menu{
                display: flex;
                flex-direction: column;
            }
            .detail-content-top{
                display: flex;
                flex-direction: column;

                .detail-device{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .device-icon{
                        display: flex;
                        align-items: center;
                        gap: 6px;
                    }
                    .image {
                        width: 60px;
                        height: 60px;
                        display: flex;
                        justify-content: center;
                        border-radius: 50%;
                        flex-shrink: 0;
                        border: 1px solid ${props => props.colorByStatus || "transparent"};
                        img {
                            height: 100%;
                        }
                    }
                    &-left{
                        display: flex;
                        gap: 6px;
                    }

                    &-right{
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        .logo{
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                        }
                        
                    }
                    .device-detail{
                        display: flex;
                        flex-direction: column;
                        font-family: 'Open Sans';
                        font-style: normal;
                        .merchant{
                            font-weight: 400;
                            font-size: 11px;
                            line-height: 15px;
                            letter-spacing: -0.02em;
                            color: #777777;
                        }
                        .merchant-name{
                            font-weight: 600;
                            font-size: 13.5px;
                            line-height: 18px;
                            letter-spacing: -0.01em;
                            color: #313131;
                        }
                        .device-code{
                            display: flex;
                            align-items: center;
                            gap: 6px;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 27px;
                            letter-spacing: -0.01em;
                            color: #313131;
                        }
                        .device-name{
                            font-weight: 400;
                            font-size: 13.5px;
                            line-height: 18px;
                            letter-spacing: -0.01em;
                            color: #313131;
                            margin-top: 6px;
                        }
                    }
                }
                
            }
            
        }

        .device-feature{
            display: flex;
            align-items: center;
            justify-content:space-between;
            margin: 20px 0 34px 0;
            &-detail{
                display: flex;
                align-items: center;
                gap: 3px;
                cursor: pointer;
                .feature-icon{
                    display: flex;
                    align-items: center;
                    width: 15px;
                    height: 15px;
                }
                .feature-value{
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #33ADFF;
                }
            }
        }

    }
`

export {
    WrapPopupDeviceDetail
}