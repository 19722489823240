import React from 'react'

const IcCloseOutlineGray = (props) => {
  return (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10255_1736)">
        <path d="M6.99999 12.8333C10.2217 12.8333 12.8333 10.2217 12.8333 7C12.8333 3.77834 10.2217 1.16667 6.99999 1.16667C3.77833 1.16667 1.16666 3.77834 1.16666 7C1.16666 10.2217 3.77833 12.8333 6.99999 12.8333Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.75 5.25L5.25 8.75M5.25 5.25L8.75 8.75" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_10255_1736">
        <rect width="14" height="14" fill="white"/>
        </clipPath>
        </defs>
    </svg>

  )
}

export default IcCloseOutlineGray