const LOCKOTP = 'LockOtp'
const CENCORSHIP = 'Cencorship'
const UPDATEPIN = 'UpdatePin'
const LOCKACCBYPIN = 'LockAccByPin'
const LOCKFROMSYSTEM = 'LockFromSystem'
const EKYCINFO = 'EkycInfo'
const LICKCARDOTP = 'LickCardOtp'
const LINKCARD = 'LinkCard'
const UNLOCKFROMSYSTEM = 'UnLockFromSystem'
const LOGINSTATUS = 'LoginStatus'

export const dataSelectionFacepayBacklog = [
    {
        text: "Tất cả",
        key: ""
    },
    {
        text: "Cập nhật mã PIN",
        key: UPDATEPIN
    },
    {
        text: "Trạng thái đăng nhập",
        key: LOGINSTATUS
    },
    {
        text: "Mở khóa từ hệ thống",
        key: UNLOCKFROMSYSTEM
    },
    {
        text: "Khóa từ hệ thống",
        key: LOCKFROMSYSTEM
    },
    {
        text: "Liên kết thẻ ngân hàng",
        key: LINKCARD
    },
    {
        text: "Khóa đăng nhập OTP",
        key: LOCKOTP
    },
    {
        text: "Thông tin eKYC",
        key: EKYCINFO
    },
    {
        text: "Xác thực OTP liên kết thẻ",
        key: LICKCARDOTP
    },
    {
        text: "Khóa tài khoản do sai PIN",
        key: LOCKACCBYPIN
    },
    {
        text: "Kiểm duyệt tài khoản",
        key: CENCORSHIP
    },
]

export const dataSelectionBankBacklog = [
    {
        text: "Tất cả",
        key: ""
    },
    {
        text: "Trạng thái đăng nhập",
        key: LOGINSTATUS
    },
    {
        text: "Khóa từ hệ thống",
        key: LOCKFROMSYSTEM
    },
    {
        text: "Mở khóa từ hệ thống",
        key: UNLOCKFROMSYSTEM
    }

]