import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styled
import { WrapTransaction } from './index.styles'

// component
import { DEV_SV, PROD_SV, QC_SV, SBX_SV } from '../../../../../constant/environmentTypes'
import TabListMenu from '../../../../Control/TabListMenu'
import Bank from './Bank'
import FacepayTerminal from './FacepayTerminal'
import WalletFacepay from './WalletFacepay'

// redux
import { getListBank, getListStoreName, reqTypeTransaction } from '../../../../../redux/transactionV2/transactionOther/action'

// helper
import numberRoundToTwo from '../../../../../helper/numberRoundToTwo'
import numberFormatToThousand from '../../../../../helper/numberFormatToThousand'

export const FACEPAYTERMINAL = 'FACEPAY_TERMINAL'
export const BANK = 'BANK'
export const WALLET_FACEPAY = 'WALLET_FACEPAY'

const dataTab = [
    {
        title: 'Facepay Terminal',
        key: FACEPAYTERMINAL,
        component: {
            type: 'FACEPAY_TERMINAL',
            comp: <FacepayTerminal />
        },
        envServerValid: [
            DEV_SV, QC_SV, SBX_SV, PROD_SV
        ]
    },
    {
        title: 'Ngân hàng',
        key: BANK,
        component: {
            type: 'BANK',
            comp: <Bank />
        },
        envServerValid: [
            DEV_SV, QC_SV, SBX_SV, PROD_SV
        ]
    },
    {
        title: 'Ví Facepay',
        key: WALLET_FACEPAY,
        component: {
            type: 'WALLET_FACEPAY',
            comp: <WalletFacepay />
        },
        envServerValid: [
            DEV_SV, QC_SV, SBX_SV, PROD_SV
        ]
    },

]

const TransactionV2 = () => {

    const dispatch = useDispatch()
    const [component, setComponent] = useState(null)

    const { totalBank, reqType, totalFacepay, totalFacepayWallet } = useSelector((state) => ({
        // bank
        totalBank: state.transactionReducer.listTransactionBankReducer.totalBank,
        // facepay
        totalFacepay: state.transactionReducer.listTransactionFacepayReducer.totalFacepay,
        // wallet
        totalFacepayWallet: state.transactionReducer.listTransactionFacepayWalletReducer.totalFacepayWallet,

        reqType: state.transactionReducer.transactionOtherReducer.reqType,

    }))
    
    useEffect(() => {
        if(reqType === BANK || reqType === FACEPAYTERMINAL) {
            dispatch(getListStoreName({
                name: [""]
              }))
        }
        dispatch(getListBank())
    }, [])

    const handleChangeTab = (component) => {
        const callbackSuccess = () => {
            setComponent(component.comp)
        }
        dispatch(reqTypeTransaction({
            reqType: component.type,
            callbackSuccess
        }))
    }

    return (
        <WrapTransaction>
            <div className="list-menu">
                <TabListMenu data={dataTab} componentSelected={handleChangeTab}/>
                {
                    reqType === BANK && (
                        <div className="total-transaction">
                            <div className='text'>
                                <p>Tổng doanh số: </p>
                                <p className="bold">{numberFormatToThousand(totalBank.totalAmount)}</p>
                            </div>
                            <div className='text'>
                                <p>Tổng số: </p>
                                <p className="blue">{numberFormatToThousand(totalBank.totalTransaction)}</p>
                            </div>
                            <div className='text'>
                                <p>Thành công: </p>
                                <p className="green">{`${totalBank.totalSuccess} (${numberRoundToTwo(totalBank.totalSuccess/totalBank.totalTransaction * 100 || 0)}%)`}</p>
                            </div>
                            <div className='text'>
                                <p>Thất bại: </p>
                                <p className="red">{`${totalBank.totalFailed}
                                (${numberRoundToTwo(totalBank.totalFailed/totalBank.totalTransaction * 100 || 0)}%)`}</p>
                            </div>
                        </div>
                    )
                }
                {
                    reqType === FACEPAYTERMINAL && (
                        <div className="total-transaction">
                            <div className='text'>
                                <p>Tổng doanh số: </p>
                                <p className="bold">{numberFormatToThousand(totalFacepay.totalAmount)}</p>
                            </div>
                            <div className='text'>
                                <p>Tổng số: </p>
                                <p className="blue">{numberFormatToThousand(totalFacepay.totalTransaction)}</p>
                            </div>
                            <div className='text'>
                                <p>Thành công: </p>
                                <p className="green">{`${totalFacepay.totalSuccess} (${numberRoundToTwo(totalFacepay.totalSuccess/totalFacepay.totalTransaction * 100 || 0)}%)`}</p>
                            </div>
                            <div className='text'>
                                <p>Thất bại: </p>
                                <p className="red">{`${totalFacepay.totalFailed}
                                (${numberRoundToTwo(totalFacepay.totalFailed/totalFacepay.totalTransaction * 100 || 0)}%)`}</p>
                            </div>
                        </div>
                    )
                }
                {
                    reqType === WALLET_FACEPAY && (
                        <div className="total-transaction">
                            <div className='text'>
                                <p>Tổng số: </p>
                                <p className="blue">{numberFormatToThousand(totalFacepayWallet.totalTransaction)}</p>
                            </div>
                            <div className='text'>
                                <p>Thành công: </p>
                                <p className="green">{`${totalFacepayWallet.totalSuccess} (${numberRoundToTwo(totalFacepayWallet.totalSuccess/totalFacepayWallet.totalTransaction * 100 || 0)}%)`}</p>
                            </div>
                            <div className='text'>
                                <p>Thất bại: </p>
                                <p className="red">{`${totalFacepayWallet.totalFailed}
                                (${numberRoundToTwo(totalFacepayWallet.totalFailed/totalFacepayWallet.totalTransaction * 100 || 0)}%)`}</p>
                            </div>
                        </div>
                    )
                }
            </div>
            {component}
        </WrapTransaction>
    )
}

export default TransactionV2
