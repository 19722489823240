import Axios from 'axios'
//host
import { host } from '../../../constant/host'
//type
import * as types from './types'

// helper
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import autoDownloadFromBlob from '../../../helper/autoDownloadFromBlob'
import mapErrors from '../../../helper/mapErrors'

// constant
import * as keys from '../../../constant/keys'

// import mapErrors from '../../../helpper/mapErrors'

const apiGetListTransaction = `${host}/transaction/filter`
const apiGetTransSearchMerchantId = `${host}/transaction/search/byMerchantTxId`
const apiGetTransSearchBankId = `${host}/transaction/search/byBankTxId`
const apiGetTransSearchFacepayId = `${host}/transaction/search/byFacepayTxId`
const apiGetTransSearchInterval = `${host}/transaction/search/byInterval`
const apiGetCountExportTransaction = `${host}/transaction/countExportData`
const apiExportTransaction = `${host}/transaction/export`

export const getListTransaction = ({ filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        searchValue: null,
        keyFilter: '',
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetListTransaction, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_TRANSACTION,
                payload: {
                    list: data.transactionList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_TRANSACTION_ERR,
                payload: {
                    ...data
                }
            })
        }

    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_TRANSACTION_ERR))
    }
}

export const getTransSearchMerchantId = ({ merchantTrxCode, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        searchValue: { value: merchantTrxCode },
        keyFilter: keys.MERCHANT_ID,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        merchantTrxCode,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetTransSearchMerchantId, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_TRANSACTION_SEARCH_MERCHANTTX_ID,
                payload: {
                    list: data.transactionList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_TRANSACTION_SEARCH_MERCHANTTX_ID_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_TRANSACTION_SEARCH_MERCHANTTX_ID_ERR))
    }
}

export const getTransSearchBankId = ({ bankTrxCode, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        searchValue: { value: bankTrxCode },
        keyFilter: keys.BANK_ID,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        bankTrxCode,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetTransSearchBankId, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_TRANSACTION_SEARCH_BANKTX_ID,
                payload: {
                    list: data.transactionList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_TRANSACTION_SEARCH_BANKTX_ID_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_TRANSACTION_SEARCH_BANKTX_ID_ERR))
    }
}

export const getTransSearchFacepayId = ({ facepayTrxCode, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        searchValue: { value: facepayTrxCode },
        keyFilter: keys.FACEPAY_ID,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        facepayTrxCode,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetTransSearchFacepayId, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_TRANSACTION_SEARCH_FACEPAYTX_ID,
                payload: {
                    list: data.transactionList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_TRANSACTION_SEARCH_FACEPAYTX_ID_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_TRANSACTION_SEARCH_FACEPAYTX_ID_ERR))
    }
}

export const getTransSearchInterval = ({ startTime, closeTime, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        searchValue: { startTime, closeTime },
        keyFilter: keys.INTERVAL,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        startTime,
        closeTime,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetTransSearchInterval, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_TRANSACTION_SEARCH_INTERVAL,
                payload: {
                    list: data.transactionList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_TRANSACTION_SEARCH_INTERVAL_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_TRANSACTION_SEARCH_INTERVAL_ERR))
    }
}

export const resetListTransaction = (callbackSuccess) => async dispatch => {
    dispatch({
        type: types.RESET_LIST_TRANSACTION,
    })
    callbackSuccess && callbackSuccess()
}

export const getCountExportTransaction = ({ startTime, closeTime }, callbackSuccess) => async dispatch => {
    const dataRequest = JSON.stringify({
        startTime,
        closeTime
    })

    try {
        const res = await Axios.post(apiGetCountExportTransaction, dataRequest)
        const { data } = res;
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_COUNT_EXPORT_TRANSACTION,
            })
            callbackSuccess && callbackSuccess(data.total)
        }
        else {
            dispatch({
                type: types.GET_COUNT_EXPORT_TRANSACTION_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch (err) {
        dispatch(mapErrors(err, types.GET_COUNT_EXPORT_TRANSACTION_ERR))
    }
}

export const exportTransaction = ({ startTime, closeTime }, callbackSuccess) => async dispatch => {
    const dataRequest = JSON.stringify({
        startTime,
        closeTime
    })

    try {
        const res = await Axios.post(apiExportTransaction, dataRequest, {
            responseType: 'blob',
        })
        const { data } = res
        dispatch({
            type: types.EXPORT_TRANSACTION
        })
        autoDownloadFromBlob(data, 'Transaction')
        callbackSuccess && callbackSuccess()
    }
    catch (err) {
        dispatch(mapErrors(err, types.EXPORT_TRANSACTION_ERR))
    }
}