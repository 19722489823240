import React from 'react'

const IcBusinessCode = (props) => {
    return (
        <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width={32} height={32} rx={16} fill="#EFF9FF" />
            <path d="M16.75 9H11.5C11.1022 9 10.7206 9.15804 10.4393 9.43934C10.158 9.72064 10 10.1022 10 10.5V22.5C10 22.8978 10.158 23.2794 10.4393 23.5607C10.7206 23.842 11.1022 24 11.5 24H20.5C20.8978 24 21.2794 23.842 21.5607 23.5607C21.842 23.2794 22 22.8978 22 22.5V14.25L16.75 9Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.75 9V14.25H22" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBusinessCode