import styled from 'styled-components'

const WrapDropdownMultiChoice = styled.div`

    .combo-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 9px 4px 9px;
        box-sizing: border-box;
        width: 100%;
        background: #EFF9FF;
        border-radius: 20px;
        cursor: pointer;
        .value {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            color: #313131;
        }
        .icon {
            margin-left: 3px;
        }

        &:hover {
            cursor: pointer;
        }
    }
    .wrap-dropdown {
        /* visibility: hidden; */
        position: absolute;
        top: 30px;
        right: 0;
        padding-top: 2.5px;
        z-index: 3;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        .wrap-selection {
            // max-height: calc(48px * 4);
            overflow: overlay;
            display: flex;
            flex-direction: column;
            align-items: center;
        }  

        .checkbox {
            width: 140px;
            height: 48px;
            box-sizing: border-box;
            padding: 17.25px 7.25px 17.25px 14.25px;
            background: #FFFFFF;
            box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);

            .text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100px;
                flex-grow: 1;
                padding-left: 7.25px;
            }

            &:last-child {
                border-radius: 0 0 4px 4px;
            }
        }
    }

`
export { WrapDropdownMultiChoice }