
import IcRefuseRedMini from "../assets/images/IcRefuseRedMini"
import IcRefuseYellowMini from "../assets/images/IcRefuseYellowMini"

export const headerRejectionProfile = [
    "Document ID",
    "Faceprint",
    "Họ tên",
    "Số điện thoại",
    "Email",
    "Người kiểm duyệt",
    "Thời gian kiểm duyệt",
]

export const listNote = [
    {
        icon: <IcRefuseYellowMini />,
        text: "Từ chối 1 lần"
    },
    {
        icon: <IcRefuseRedMini />,
        text: "Từ chối nhiều lần"
    },
]

export const listRejectionProfile = [
    {
        id: "62737c1f49c1fe8245dasdas07",
        type: "REFUSE_ONE_TIME",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        admin: "user.name@facepay.vn",
        waitTime: "2021-07-16T03:42:20.279Z"
    },
    {
        id: "62737c1f49csde8245dejgld",
        type: "REFUSE_MANY_TIMES",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        admin: "user.name@facepay.vn",
        waitTime: "2021-07-16T03:42:20.279Z"
    },
    {
        id: "62737c149c1fe8245dasdas07",
        type: "REFUSE_ONE_TIME",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        admin: "user.name@facepay.vn",
        waitTime: "2021-07-16T03:42:20.279Z"
    },
    {
        id: "62737c1f49cse8245dejgld",
        type: "REFUSE_MANY_TIMES",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        admin: "user.name@facepay.vn",
        waitTime: "2021-07-16T03:42:20.279Z"
    },
    {
        id: "62737c1f9c1fe8245dasdas07",
        type: "REFUSE_ONE_TIME",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        admin: "user.name@facepay.vn",
        waitTime: "2021-07-16T03:42:20.279Z"
    },
    {
        id: "62737c1f49sde8245dejgld",
        type: "REFUSE_MANY_TIMES",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        admin: "user.name@facepay.vn",
        waitTime: "2021-07-16T03:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe845dasdas07",
        type: "REFUSE_ONE_TIME",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        admin: "user.name@facepay.vn",
        waitTime: "2021-07-16T03:42:20.279Z"
    },
    {
        id: "6737c1f49csde8245dejgld",
        type: "REFUSE_MANY_TIMES",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        admin: "user.name@facepay.vn",
        waitTime: "2021-07-16T03:42:20.279Z"
    },
]

export const detaisRejectionProfile = {
    id: "62737c1f49c1fe8245d0b8072",
    type: "REFUSE_ONE_TIME",
    documentId: "FA#27947",
    sendTime: "2021-08-20T15:05:24.088Z",
    admin: "user.name@facepay.vn",
    approvedTime: "2021-08-20T15:05:24.088Z",
    reason: [
        {
            key: 1,
            text: "Hình ảnh không đủ sắc nét, bị nhiễu.",
        },
        {
            key: 2,
            text: "Hình ảnh khuôn mặt của người đăng ký không nằm giữa màn hình.",
        },
        {
            key: 3,
            text: "Hình ảnh chụp trong môi trường không đạt điều kiện ( thiếu sáng hoặc dư sáng).",
        },
        {
            key: 4,
            text: "Hình ảnh có phụ kiện che một phần khuôn mặt như kính răm, khẩu trang,...",
        },
        {
            key: 5,
            text: "Không phải là cùng một người trong ảnh kiểm duyệt trước đó.",
        },
        {
            key: 6,
            text: "Không phải là cùng một người trong ảnh kiểm duyệt trước đó.Không phải là cùng một người trong ảnh kiểm duyệt trước đó.Không phải là cùng một người trong ảnh kiểm duyệt trước đó.",
        },
    ],
    detail: {
        fullName: "Trần Nguyên Khang",
        phoneNumber: "0909123456",
        email: "khang.tran@weedigital.vn"
    },
    face: {
        img: "https://picsum.photos/228/228",
        listMatch: [
            {
                avatar: "https://picsum.photos/36/36",
                fullName: "Đỗ Hoài Nam",
                phoneNumber: "0909123456"
            },
            {
                avatar: "https://picsum.photos/36/36",
                fullName: "Trần An Ninh",
                phoneNumber: "0909123456"
            }
        ]
    }


}