import React from 'react'

const IcInfoCircle = (props) => {
    return (
        <svg {...props} width={11} height={12} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9464_54943)">
                <path d="M5.49999 10.5833C8.03129 10.5833 10.0833 8.53126 10.0833 5.99996C10.0833 3.46865 8.03129 1.41663 5.49999 1.41663C2.96868 1.41663 0.916656 3.46865 0.916656 5.99996C0.916656 8.53126 2.96868 10.5833 5.49999 10.5833Z" stroke="#777777" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.5 7.83333V6" stroke="#777777" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.5 4.16663H5.50458" stroke="#777777" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_9464_54943">
                    <rect width={11} height={11} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcInfoCircle