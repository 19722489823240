import React from 'react'

const IcTransferToBank = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23 10.4209H17.2105" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.6841 6.94727L17.2104 10.421L20.6841 13.8946" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 17.3843H2.64678C2.07036 17.3843 1.78214 17.3842 1.56197 17.4964C1.36831 17.5951 1.21086 17.7526 1.11218 17.9462C1 18.1664 1 18.4546 1 19.031L1 19.8544C1 20.4309 1 20.7191 1.11218 20.9392C1.21086 21.1329 1.36831 21.2904 1.56197 21.389C1.78214 21.5012 2.07036 21.5012 2.64678 21.5012H17.8795C18.456 21.5012 18.7442 21.5012 18.9643 21.389C19.158 21.2904 19.3155 21.1329 19.4141 20.9392C19.5263 20.7191 19.5263 19.4309 19.5263 18.8544C19.5263 17.538 18.5088 17.3258 18 17.3843ZM18 17.3843V15M14 9.15033H2.64678C2.07036 9.15033 1.78214 9.15033 1.56197 9.03815C1.36831 8.93947 1.21086 8.78202 1.11218 8.58836C1 8.36819 1 8.07998 1 7.50355L1 6.35438C1 5.88302 1 5.64734 1.08352 5.45286C1.15719 5.28131 1.27614 5.13304 1.42761 5.02391C1.59935 4.90018 1.82941 4.84906 2.28955 4.74681L9.90592 3.05428C10.0392 3.02465 10.1059 3.00984 10.1732 3.00393C10.2331 2.99869 10.2932 2.99869 10.3531 3.00393C10.4204 3.00984 10.4871 3.02465 10.6204 3.05428L18.2368 4.74681M3.05848 9.15033V17.3843M7.69006 9.15033V17.3843M12.8363 9.15033V17.3843" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="21" cy="21" r="7.5" fill="#33ADFF" stroke="white"/>
      <path d="M24.1114 18.6665L19.8336 22.9443L17.8892 20.9998" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcTransferToBank