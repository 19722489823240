import styled from 'styled-components'

const WrapNotify = styled.div`
    position: fixed;
    top: 0;
    width: 100vw;
    min-width: 1280px;
    z-index: 99;
    /* min-width: 100vw; */
    /* max-height: 100vh;
    overflow: auto; */
    
    /* & > * {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    } */
`;

export {
    WrapNotify
}