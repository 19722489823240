import React, { useState } from 'react'

//styles
import * as styles from './index.styles'

//component
import TabListMenu from '../../../../../Control/TabListMenu'
import TransactionHistory from './TransactionHistory'
import CallHistory from './CallHistory'
import UserAnalytics from './UserAnalytics'
import BackLog from './BackLog'

//constant
import { DEV_SV, QC_SV, PROD_SV, SBX_SV } from '../../../../../../constant/environmentTypes'

const dataTab = [
  {
    title: 'Lịch sử giao dịch',
    key: 'trans-history',
    component: <TransactionHistory />,
    envServerValid: [
      DEV_SV,
      QC_SV,
      PROD_SV,
      SBX_SV
    ]
  },
  {
    title: 'Lịch sử cuộc gọi',
    key: 'call-history',
    component: <CallHistory />,
    envServerValid: [
      DEV_SV,
      // QC_SV,
      // PROD_SV
    ]
  },
  {
    title: 'Phân tích khách hàng',
    key: 'analytics',
    component: <UserAnalytics />,
    envServerValid: [
      DEV_SV,
      QC_SV,
      SBX_SV,
      PROD_SV
    ]
  },
  {
    title: 'Backlog tài khoản',
    key: 'backlog',
    component: <BackLog />,
    envServerValid: [
      DEV_SV,
      QC_SV,
      PROD_SV,
      SBX_SV
    ]
  },
]

export default function CustomerInfoExpand() {

  const [component, setComponent] = useState(null)

  const _handleChangeTab = (component) => {
    // console.log(component)
    setComponent(component)
  }

  return (
    <styles.Bound>
        <p className="title">Dữ liệu mở rộng</p>
        <div className='block info-expand'>
          <TabListMenu data={dataTab} componentSelected={_handleChangeTab} />
          {component}
        </div>
    </styles.Bound>
  )
}
