import React, { useState, useEffect } from "react";

//style
import { WrapFaceRecognizeInfo } from "./index.styles";

//image
import IcTransPhotoDefault from "../../../../../../../../../assets/images/IcTransPhotoDefault";
import IcTransVerifyVideo from "../../../../../../../../../assets/images/IcTransVerifyVideo";
import IcPlayCircle from "../../../../../../../../../assets/images/IcPlayCircle";

//component
import PopupShowMedia, {
  IMAGE,
  VIDEO,
} from "../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia";
import Portal from "../../../../../../../../Control/Portal";
import EmptyDataControl from "../../../../../../../../Control/EmptyDataControl";
import { ACTIVE } from "../../../../../../../../../data/dataCustomer";

const FaceRecognizeCustom = ({ item }) => {
  const [showMedia, setShowMedia] = useState(null);
  const [isErrCustomerPhoto, setIsErrCustomerPhoto] = useState(false);
  const [isErrCustomerVideo, setIsErrCustomerVideo] = useState(false);

  useEffect(() => {
    setIsErrCustomerPhoto(false);
  }, [item.fullFaceUrl]);

  const _handleZoomPhoto = () => {
    if (isErrCustomerPhoto) return;
    setShowMedia({
      type: IMAGE,
      url: item.fullFaceUrl,
    });
  };

  const _handleZoomVideo = () => {
    if (isErrCustomerVideo) return;
    setShowMedia({
      type: VIDEO,
      url: item.id,
    });
  };

  return (
    <WrapFaceRecognizeInfo>
      <div className="face-infos">
        <div className="title-face">
          <div className="title-value">Hình ảnh đăng ký</div>
          <div className="title-img">
            Image Proof:
            <span className="value"> {item.bioId || "-"}</span>
          </div>
        </div>
        <div className="face-registration">
          {item.fullFaceUrl ? (
            <>
              <div className="wrap-media face-img" onClick={_handleZoomPhoto}>
                {isErrCustomerPhoto ? (
                  <div className="wrapper">
                    <IcTransPhotoDefault />
                  </div>
                ) : (
                  <img
                    src={item.fullFaceUrl || ""}
                    alt="face registration"
                    onError={() => setIsErrCustomerPhoto(true)}
                  />
                )}
              </div>
              <div
                className="wrap-media recog-video"
                onClick={_handleZoomVideo}
              >
                {isErrCustomerVideo ? (
                  <div className="wrapper">
                    <IcTransVerifyVideo />
                  </div>
                ) : (
                  <>
                    <img
                      src={""}
                      alt="video registration"
                      onError={() => setIsErrCustomerVideo(true)}
                    />
                    <div className="overlay">
                      <IcPlayCircle />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="wrap-media face-img">
                {!isErrCustomerPhoto && (
                  <div className="wrapper">
                    <IcTransPhotoDefault />
                  </div>
                )}
              </div>
              <div className="wrap-media recog-video">
                {!isErrCustomerVideo && (
                  <div className="wrapper">
                    <IcTransVerifyVideo />
                  </div>
                )}
              </div>
            </>
          )}
          {!!showMedia && (
            <Portal>
              <PopupShowMedia
                mediaType={showMedia.type}
                url={showMedia.url}
                closePopup={() => setShowMedia(null)}
              />
            </Portal>
          )}
        </div>
      </div>
    </WrapFaceRecognizeInfo>
  );
};

export default FaceRecognizeCustom;
