import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

//style
import { WrapDetail } from './index.styles'

//component
// import PageHeader from '../../PageHeader'
import WaitingCensorDetailsItem from './WaitingCensorDetailItem'

//action
import { getDetailsCensorship, resetDetailCensorship } from '../../../../../../redux/censorship/detailCensorship/action'

//constant
import * as path from "../../../../../../constant/path";
import * as userStatus from '../../../../../../constant/userStatus'
import { resetListWaitingCensorship, resetWaitingCondsFilter } from '../../../../../../redux/censorship/listWaitingCensorship/action'

const WaitingCensorshipDetail = () => {

    const { censorshipId } = useParams();

    const dispatch = useDispatch()
    const history = useHistory()

    const { details } = useSelector(state => ({
        details: state.censorshipReducer.detailCensorshipReducer.details,
    }))

    useEffect(() => {
        dispatch(resetListWaitingCensorship())
        dispatch(resetWaitingCondsFilter())
    }, [])

    useEffect(() => {
        if (!details) return
        if (!details.user) return
        if (![userStatus.PENDING, userStatus.REPENDING].includes(details.user.status)) {
            history.push(`/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}`)
        }
        return () => {

        }
    }, [details])

    useEffect(() => {
        dispatch(getDetailsCensorship({ uid: censorshipId }))
        return () => {
            dispatch(resetDetailCensorship())
        }
    }, [])

    return (
        <WrapDetail>
            <p className="title-detail">Thông tin hồ sơ</p>
            <WaitingCensorDetailsItem data={details} />
        </WrapDetail>
    )
}

export default WaitingCensorshipDetail
