import React from 'react'

const IcBusinessAdd = (props) => {
    return (
        <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={32} height={32} rx={16} fill="#EFF9FF" />
            <path d="M22.75 14.5C22.75 19.75 16 24.25 16 24.25C16 24.25 9.25 19.75 9.25 14.5C9.25 12.7098 9.96116 10.9929 11.227 9.72703C12.4929 8.46116 14.2098 7.75 16 7.75C17.7902 7.75 19.5071 8.46116 20.773 9.72703C22.0388 10.9929 22.75 12.7098 22.75 14.5Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 16.75C17.2426 16.75 18.25 15.7426 18.25 14.5C18.25 13.2574 17.2426 12.25 16 12.25C14.7574 12.25 13.75 13.2574 13.75 14.5C13.75 15.7426 14.7574 16.75 16 16.75Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBusinessAdd