import React from 'react'

const IcUploadGray = (props) => {
  return (
    <svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.125 9.875V12.375C13.125 12.7065 12.9933 13.0245 12.7589 13.2589C12.5245 13.4933 12.2065 13.625 11.875 13.625H3.125C2.79348 13.625 2.47554 13.4933 2.24112 13.2589C2.0067 13.0245 1.875 12.7065 1.875 12.375V9.875" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.625 5.5L7.5 2.375L4.375 5.5" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 2.375V9.875" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcUploadGray