import styled from 'styled-components'

const WrapBankCustomerList = styled.div`
    display: flex;
    flex-grow: 1;
    background: #fff;
    /* box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.07), inset -0.5px 0px 0px rgba(0, 0, 0, 0.15); */
    flex-direction: column;
    padding-top: 13px;
    position: relative;

    .wrap-empty {
        flex-grow: 1;
        display: flex;
        align-items: center;
        p{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            color: #B7B7B7;
        }
    }

`
const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
    /* padding: 0 20px; */
    box-sizing: border-box;
    
    .wrap-list-customer {
        width: calc(100% + 10px);
        flex-grow: 1;
        overflow: overlay;
        position: relative;
        margin-left: 10px;
        .list-customer {
            width: calc(100% - 10px);
            display: flex;
            flex-direction: column;
            position: absolute;
        }
    }
    
`
export { WrapBankCustomerList, TableContent } 