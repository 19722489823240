import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styled 
import { TableContentBusiness, TableHeaderBusiness, TableWaitingListBusiness, WrapWaitingListBusiness } from '../BusinessWaiting/index.styles';
import { WrapBussinessReject } from './index.styles'

// component
import FilterBusinessManage from '../FilterBusiness'
import BusinessItem from '../BusinessWaiting/BusinessItem';

// data
import EmptyDataControl from '../../../../../Control/EmptyDataControl';

// images
import IcCensorshipDefault from '../../../../../../assets/images/IcCensorshipDefault';

// hook
import useScrollEnd from '../../../../../../hook/useScrollEnd';

// redux
import { getListBusiness, resetBusinessDetail, resetCondFiltersBusiness, resetListNoteBusiness } from '../../../../../../redux/business/listBusinessManage/action';

import { headerWaitingBusiness, listNoteBusiness } from '../../../../../../data/dataWaitingCensorship'
import BussinessManagerDetails from '../BusinessManagerDetails';
import IcDefaultBusiness from '../../../../../../assets/images/IcDefaultBusiness';
import { BUSINESS_WAITING_UPDATE } from '../../../../../../constant/keys';
const BusinessWaiting = () => {

  const listRef = useRef(null)
  const dispatch = useDispatch()

  const [isShowPopup, setIsShowPopup] = useState(false)

  const { condFiltersBusiness, total, listBussinessReject } = useSelector((state) => ({
    condFiltersBusiness: state.businessReducer.listBusinessManage.condFiltersBusiness,
    listBussinessReject: state.businessReducer.listBusinessManage.listBussiness,
    total: state.businessReducer.listBusinessManage.total,
  }))

  const dataRequest = (condFiltersBusiness, isScroll, listBussiness) => {
    const checkIsSuccessAction = condFiltersBusiness.optionSelectCheckboxBusiness.reduce((a, v) => ({ ...a, [v.key]: v.isSelect }), {})

    return {
      ...checkIsSuccessAction,
      isDenied: true,
      businessCode: condFiltersBusiness.businessCode,
      phoneNumber: condFiltersBusiness.phoneNumber,
      ownerName: condFiltersBusiness.ownerName,
      businessName: condFiltersBusiness.businessName,
      begin: condFiltersBusiness.begin,
      end: condFiltersBusiness.end,
      limit: condFiltersBusiness.limit,
      lastItem: isScroll ? listBussiness.at(-1).id : condFiltersBusiness.lastItem,
    }
  }

  useEffect(() => {
    dispatch(getListBusiness(dataRequest(condFiltersBusiness, false), false))
  }, [condFiltersBusiness])

  useScrollEnd(listRef, (callback) => {
    dispatch(getListBusiness(dataRequest(condFiltersBusiness, true, listBussinessReject), true, callback))
  }, [listBussinessReject], [listBussinessReject.length])

  const _handleClosePopup = () => {
    setIsShowPopup(false)
    dispatch(resetBusinessDetail())
    dispatch(resetListNoteBusiness())
  }
 
  return (
    <WrapBussinessReject>
      <FilterBusinessManage isShowSelectCheckbox={true} />
      <WrapWaitingListBusiness>
        <TableWaitingListBusiness>
          {listBussinessReject?.length === 0 ? (
            <div className="wrap-empty">
              <EmptyDataControl
                icon={<IcDefaultBusiness />}
                text={"Hiện tại chưa có doanh nghiệp mới"}
              />
            </div>
          ) : (
            <>
              <div className="wrap-page-header">
                <p className="title-header">Danh sách hồ sơ ({total ? total : 0})</p>
                <div className="list-note">
                  {listNoteBusiness.map((item, index) => (
                    <div className="note" key={index}>
                      {item.key !== BUSINESS_WAITING_UPDATE ? item.icon : ""}
                      <p>{item.key !== BUSINESS_WAITING_UPDATE ? item.text : ""}</p>
                    </div>
                  ))}
                </div>
              </div>
              <TableHeaderBusiness>
                {headerWaitingBusiness.map((item, index) => (
                  <div className="table-header" key={index} >
                    {item}
                  </div>
                ))}
              </TableHeaderBusiness>
              <TableContentBusiness>
                <div className="wrap-list-waiting" ref={listRef}>
                  <div className="list-waiting">
                    {listBussinessReject?.map((item, index) => (
                      <BusinessItem key={index} data={item} setIsShowPopup={setIsShowPopup} />
                    ))}
                  </div>
                </div>
              </TableContentBusiness>
            </>
          )}
        </TableWaitingListBusiness>
      </WrapWaitingListBusiness>
      {
        isShowPopup &&
        <BussinessManagerDetails
          onClickClosePopup={_handleClosePopup} />
      }
    </WrapBussinessReject>
  )
}

export default BusinessWaiting