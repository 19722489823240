import axios from 'axios';
import * as types from './types'

import mapErrors from '../../../helper/mapErrors';

// host
import { hostCustomer } from '../../../constant/host'

// helper
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import { disableLoading, enableLoading } from '../../loading/action';
import autoDownloadFromBlob from '../../../helper/autoDownloadFromBlob';

// SPRIN 1
const apiGetListBusiness = `${hostCustomer}/business/censorship`
const apiBusinessDetail = `${hostCustomer}/business/censorship/detail`
const apiBusinessReject = `${hostCustomer}/business/reject`
const apiBusinessApproved = `${hostCustomer}/business/approve`

// SPRIN 2
const apiBusinessRequestUpdate = `${hostCustomer}/business/request-update-license`
const apiGetListBacklogBusiness = `${hostCustomer}/business/censorship/backlogs`
const apiExportListBusiness = `${hostCustomer}/business/censorship/export`
const apiBusinessAddNote = `${hostCustomer}/business/censorship/add-note`
const apiGetListNoteBusiness = `${hostCustomer}/business/censorship/notes`

let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
}

let isCalling = false

const dataRequestApi = {
    isDenied: "",
    businessCode: "",
    phoneNumber: "",
    ownerName: "",
    businessName: "",
    begin: "",
    end: "",
    limit: "",
    lastItem: "",
    isNew: "", // đăng kí mới
    isRequireLicenseUpdate: "", // chờ cập nhật
    isLicenseUpdated: "", // cập nhật giấy tờ
}

export const updatecondFiltersBusiness = (
    condFiltersBusiness = dataRequestApi,
    callbackSuccess
) => async dispatch => {
    dispatch({
        type: types.UPDATE_CONDFILTER_BUSINESS,
        condFiltersBusiness
    })
    callbackSuccess && callbackSuccess()
}

export const getListBusiness = (
    data = dataRequestApi,
    isScroll,
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    if (isCalling) return
    isCalling = true

    !isScroll && dispatch(enableLoading())

    const dataRequest = { ...headerDataRequest, data }

    try {
        const res = await axios.post(apiGetListBusiness, dataRequest)
        const { data } = res

        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_BUSINESS,
                payload: {
                    listBussiness: data.data.list || [],
                    total: data.data.total,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
            isCalling = false
        } else {
            dispatch({
                type: types.GET_LIST_BUSINESS_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()

        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_BUSINESS_ERR))
    }

    isCalling = false
    !isScroll && setTimeout(() => {
        dispatch(disableLoading())
    }, 500);
}

export const resetCondFiltersBusiness = (callbackSuccess) => async dispatch => {
    dispatch({ type: types.RESET_CONDFILTER_BUSINESS })
    callbackSuccess && callbackSuccess()
}

export const resetListBusiness = (callbackSuccess) => async dispatch => {
    dispatch({ type: types.RESET_LIST_BUSINESS })
    callbackSuccess && callbackSuccess()
}


export const getBusinessDetail = (data = { businessID: "" }, callbackSuccess, callbackError) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = { ...headerDataRequest, data }

    try {
        const res = await axios.post(apiBusinessDetail, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_DETAIL_BUSINESS,
                payload: {
                    businessDetail: data.data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_DETAIL_BUSINESS_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_DETAIL_BUSINESS_ERR))
    }
}

export const resetBusinessDetail = (callbackSuccess) => async dispatch => {
    dispatch({ type: types.RESET_DETAIL_BUSINESS })
    callbackSuccess && callbackSuccess()
}

export const businessReject = (
    data = {
        businessID: "",
        reasons: []
    },
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = { ...headerDataRequest, data }

    try {
        const res = await axios.post(apiBusinessReject, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.BUSINESS_REJECT,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.BUSINESS_REJECT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.BUSINESS_REJECT_ERR))
    }
}


export const businessApproved = (
    data = {
        businessID: "",
    },
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = { ...headerDataRequest, data }

    try {
        const res = await axios.post(apiBusinessApproved, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.BUSINESS_APPROVED,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.BUSINESS_APPROVED_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.BUSINESS_APPROVED_ERR))
    }
}

export const setIsShowPopupUpdateRequest = ({ isShowPopupUpdateRequest }) => {
    return {
        type: types.SHOW_POPUP_UPDATE_REQUEST,
        isShowPopupUpdateRequest,
    }
}

export const businessRequestUpdate = (
    data = {
        businessID: "",
        reason: ""
    },
    callbackSuccess,
    callbackError
) => async dispatch => {

    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = { ...headerDataRequest, data }

    try {
        const res = await axios.post(apiBusinessRequestUpdate, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.BUSINESS_REQUEST_UPDATE,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.BUSINESS_REQUEST_UPDATE_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.BUSINESS_REQUEST_UPDATE_ERR))
    }
}

export const getListBacklogBusiness = (
    data = {
        businessID: "",
        limit: "",
        lastItem: ""
    },
    isScroll,
    callbackSuccess,
    callbackError
) => async dispatch => {

    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = { ...headerDataRequest, data }

    try {
        const res = await axios.post(apiGetListBacklogBusiness, dataRequest)
        const { data } = res

        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_BACKLOG_BUSINESS,
                payload: {
                    listBacklogBusiness: data.data.list || [],
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_LIST_BACKLOG_BUSINESS_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_BACKLOG_BUSINESS_ERR))
    }
}

export const resetListBacklogBusiness = (callbackSuccess) => async dispatch => {
    dispatch({ type: types.RESET_LIST_BACKLOG_BUSINESS })
    callbackSuccess && callbackSuccess()
}

export const exportListBusiness = (
    data = {
        begin: "",
        end: ""
    },
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = { ...headerDataRequest, data }
    try {
        const res = await axios.post(apiExportListBusiness, dataRequest, {
            responseType: 'blob'
        })
        const { data } = res
        autoDownloadFromBlob(data, 'business')
        callbackSuccess && callbackSuccess()
    } catch (error) {
        dispatch(mapErrors(error, types.EXPORT_LIST_BUSINESS_ERR))
        callbackError && callbackError()
    }
}

export const businessAddNote = (
    data = {
        adminID: "",
        adminUserName: "",
        createdAt: "",
        businessID: "",
        text: "",
        attachment: {
            fileName: "",
            fileData: ""
        }
    },
    callbackSuccess,
    callbackError
) => async dispatch => {

    headerDataRequest.reqId = `${new Date().getTime()}`
    if (isCalling) return
    isCalling = true
    const dataRequest = { ...headerDataRequest, data }

    try {
        const res = await axios.post(apiBusinessAddNote, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.BUSINESS_ADD_NOTE,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
            isCalling = false
        } else {
            dispatch({
                type: types.BUSINESS_ADD_NOTE_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.BUSINESS_ADD_NOTE_ERR))
    }
    isCalling = false

}

export const getListNoteBusiness = (
    data = {
        businessID: "",
        limit: "",
        lastItem: ""
    },
    isScroll,
    callbackSuccess,
    callbackError
) => async dispatch => {

    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = { ...headerDataRequest, data }

    try {
        const res = await axios.post(apiGetListNoteBusiness, dataRequest)
        const { data } = res

        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_NOTE_BUSINESS,
                payload: {
                    listNoteBusiness: data.data.list || [],
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_LIST_NOTE_BUSINESS_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_NOTE_BUSINESS_ERR))
    }
}

export const resetListNoteBusiness = (callbackSuccess) => async dispatch => {
    dispatch({ type: types.RESET_LIST_NOTE_BUSINESS })
    callbackSuccess && callbackSuccess()
}