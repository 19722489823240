import React, { useState, useReducer, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sha256 } from 'js-sha256';

// styles
import { WrapLogin } from './index.styles'

//image
import LogoLogin from '../../../assets/images/LogoLogin'
import BackgroundLogin from '../../../assets/images/bg_login.png'

//component
import Input from '../../Control/Input';
import InputPassword from '../../Control/InputPassword';
import Button from '../../Control/Button';

// action
import { loginActicon } from '../../../redux/login/action';

// constant
import { MAIN } from '../../../constant/path';

//image
import IcInputUser from '../../../assets/images/IcInputUser';
import IcInputUserActive from '../../../assets/images/IcInputUserActive'
import IcInputUserError from '../../../assets/images/IcInputUserError'
import IcInputLock from '../../../assets/images/IcInputLock'
import IcInputLockError from '../../../assets/images/IcInputLockError'
import IcInputLockActive from '../../../assets/images/IcInputLockActive'
import IcButtonLogin from '../../../assets/images/IcButtonLogin';
import { resetDeviceCondsFilter, resetListDevicesByAdminId } from '../../../redux/deviceV2/listDevicesByAdmin/action';
import { resetListWaitingCensorship, resetWaitingCondsFilter } from '../../../redux/censorship/listWaitingCensorship/action';
import { resetListRejectProfile, resetRejectCondsFilter } from '../../../redux/censorship/listRejectProfile/action';
import { resetBankCustomerCondsFilter, resetFacepayCustomerCondsFilter, resetListCustomerBank, resetListCustomerFacepay } from '../../../redux/customer/listCustomer/actionV2';
import { resetListDeviceHistoryCustomer, resetListEkycHistoryCustomer, resetListVerifyHistoryCustomer } from '../../../redux/customer/customerDetail/action';

const iconUser = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}
const iconPassword = {
    icon: IcInputLock,
    iconActive: IcInputLockActive,
    iconError: IcInputLockError,
}

const Login = () => {

    const history = useHistory();
    const dispatch = useDispatch()

    const { loggedIn } = useSelector(state => ({
        loggedIn: state.loginReducer.loggedIn
    }))

    const [textErrorForm,setTextErrorForm] = useState("")
    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            username: "",
            pwd: "",
        }
    )

    useEffect(() => {
        dispatch(resetListDevicesByAdminId())
        dispatch(resetDeviceCondsFilter())
        dispatch(resetListWaitingCensorship())
        dispatch(resetWaitingCondsFilter())
        dispatch(resetListRejectProfile())
        dispatch(resetRejectCondsFilter())
        dispatch(resetFacepayCustomerCondsFilter())
        dispatch(resetListCustomerFacepay())
        dispatch(resetListVerifyHistoryCustomer())
        dispatch(resetListEkycHistoryCustomer())
        dispatch(resetListDeviceHistoryCustomer())
        dispatch(resetListCustomerBank())
        dispatch(resetBankCustomerCondsFilter())
    },[])

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setStateForm({
            [name]: value
        })
    }

    const SubmitFormSuccess = () =>{
        // console.log("success")
        setTextErrorForm("")
    }

    const SubmitFormError = () =>{
        // console.log("error")
        setTextErrorForm("Tài khoản hoặc Password không đúng")
    }

    const submitForm = (e) => {
        e.preventDefault();

        setStateForm({
            username: stateForm.username,
            pwd: stateForm.pwd,
        })
        const dataLogin = {
            username: stateForm.username,
            pwd: sha256(stateForm.pwd),
        };
        dispatch(loginActicon(history, dataLogin,SubmitFormSuccess,SubmitFormError))
    }

    useEffect(() => {
        if(!loggedIn) return;
        history.push(`/${MAIN}`)

    }, [loggedIn]);

    const _handleValidationUser = (value, callback) =>{
        if (!value.trim() || 0 === value.length) {
            callback("Tài khoản không được để trống")
            return
        }
        if (value.indexOf(' ') >= 0) {
            callback("Tài khoản không chứa khoản trắng")
            return
        }
    }

    const _handleValidationUPassword = (value, callback) =>{
        if(value.length === 0){
            callback("Mật khẩu không được để trống")
        }
    }
    

    return (
        <WrapLogin>
            <div className="wrap-inner-login">
                <div className="login-form">
                    <LogoLogin className="icon-logo" />
                    <h2 className="title">Welcome back</h2>
                    <p className="note">Login your account</p>
                    <form className="form" onSubmit={submitForm} encType="multipart/form-data">
                        <Input
                            placeholder="Username"
                            inputType="TEXT"
                            name="username"
                            value={stateForm.username}
                            required
                            onChange={handleChangeValue}
                            iconInput={iconUser}
                            handleValidationUser={_handleValidationUser}
                        />
                        <InputPassword
                            placeholder="Password"
                            type="password"
                            inputType="PWD"
                            name="pwd"
                            required
                            value={stateForm.pwd}
                            onChange={handleChangeValue}
                            iconInput={iconPassword}
                            handleValidationUPassword={_handleValidationUPassword}
                        />
                        <div className="error">{textErrorForm}</div>
                        <Button 
                            type="submit" 
                            text="Login" 
                            iconBtn={<IcButtonLogin />}
                            className="btn-login"
                        />
                    </form>
                    <footer className="footer">
                        {/* <p>Trợ giúp</p>
                        <p>Liên hệ với chúng tôi</p>
                        <p>Điều khoản sử dụng</p> */}
                    </footer>
                </div>
                <div className="bg_login">
                    <p>No Cash - No Card - No Phone</p>
                    <img src={BackgroundLogin} alt="bg_login" />
                </div>
            </div>
        </WrapLogin>
    )
}

export default Login
