import React from 'react'

const IcDeviceOutlineBlue = (props) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#33ADFF"/>
        <path d="M15.75 4.5H8.25C7.42157 4.5 6.75 5.17157 6.75 6V18C6.75 18.8284 7.42157 19.5 8.25 19.5H15.75C16.5784 19.5 17.25 18.8284 17.25 18V6C17.25 5.17157 16.5784 4.5 15.75 4.5Z" fill="white"/>
        <path d="M12 16.5H12.0075" stroke="#33ADFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcDeviceOutlineBlue