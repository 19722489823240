import React from 'react'

const IcCheckWhite = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8337 6.46407V7.00074C12.8329 8.25865 12.4256 9.48263 11.6724 10.4901C10.9193 11.4976 9.86058 12.2347 8.65428 12.5913C7.44799 12.948 6.15873 12.9052 4.97877 12.4692C3.79881 12.0333 2.79137 11.2276 2.10672 10.1724C1.42206 9.11709 1.09687 7.86877 1.17964 6.61359C1.2624 5.3584 1.7487 4.16359 2.56599 3.20736C3.38328 2.25113 4.48778 1.58471 5.71477 1.30749C6.94176 1.03027 8.22548 1.1571 9.3745 1.66907" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.8333 2.33203L7 8.1712L5.25 6.4212" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCheckWhite
