import { DELETE_DEVICE, DEVICE_ID, DEVICE_NAME, DEVICE_VERSION, EXIT_APP, REBOOT, SCREENSHOT, SNAPSHOT, STATUS_ID, STORE_LOCATION, UPDATE_FACEPAY, UPDATE_LAUNCHER } from "../constant/keys";

//image 
import IcCamera from '../assets/images/IcCamera'
import IcSetting from "../assets/images/IcSetting";
import IcUploadBlue from "../assets/images/IcUploadBlue";
import IcDelete from "../assets/images/IcDetete"
import IcCameraGray from "../assets/images/IcCameraGray"
import IcSettingGray from "../assets/images/IcSettingGray"
import IcUploadGray from "../assets/images/IcUploadGray"
import IcDeleteGray from "../assets/images/IcDeleteGray";


export const dataFeatureDevice = [
    {
        id: 1,
        text: 'Chụp màn hình',
        key: SCREENSHOT,
        icon: <IcCamera />,
        iconDisable: <IcCameraGray />,
        isDisable: false,
        isShowPopupDeviceDetail: true,
        isCheckShowFeatureDevice: false
    },
    {
        id: 2,
        text: 'Chụp camera trước',
        key: SNAPSHOT,
        icon: <IcCamera />,
        iconDisable: <IcCameraGray />,
        isDisable: false,
        isShowPopupDeviceDetail: true,
        isCheckShowFeatureDevice: false
    },
    {
        id: 3,
        text: 'Reboot thiết bị',
        key: REBOOT,
        icon: <IcSetting />,
        iconDisable: <IcSettingGray />,
        isDisable: false,
        isShowPopupDeviceDetail: true,
        isCheckShowFeatureDevice: true
    },
    {
        id: 4,
        text: 'Reset App',
        key: EXIT_APP,
        icon: <IcSetting />,
        iconDisable: <IcSettingGray />,
        isDisable: false,
        isShowPopupDeviceDetail: true,
        isCheckShowFeatureDevice: true
    },
    {
        id: 5,
        text: 'Cập nhật App',
        key: UPDATE_FACEPAY,
        icon: <IcUploadBlue />,
        iconDisable: <IcUploadGray />,
        isDisable: false,
        isShowPopupDeviceDetail: false,
        isCheckShowFeatureDevice: true
    },
    {
        id: 6,
        text: 'Cập nhật Launcher',
        key: UPDATE_LAUNCHER,
        icon: <IcUploadBlue />,
        iconDisable: <IcUploadGray />,
        isDisable: false,
        isShowPopupDeviceDetail: false,
        isCheckShowFeatureDevice: true
    },
    {
        id: 7,
        text: 'Xóa thiết bị',
        key: DELETE_DEVICE,
        icon: <IcDelete />,
        iconDisable: <IcDeleteGray />,
        isDisable: true,
        isShowPopupDeviceDetail: false,
        isCheckShowFeatureDevice: true
    },
]


export const dataSelection = [
    {
        text: "DeviceID",
        key: DEVICE_ID
    },
    {
        text: "Trạng thái",
        key: STATUS_ID
    },
    {
        text: "Tên thiết bị",
        key: DEVICE_NAME
    },
    {
        text: "Tên cửa hàng",
        key: STORE_LOCATION
    },
    {
        text: "Phiên bản",
        key: DEVICE_VERSION
    },
    
]