import React from 'react'

const IcUserRegisMini = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={8} cy={8} r={8} fill="#33ADFF" />
            <path d="M12 12.5V11.5C12 10.9696 11.7893 10.4609 11.4142 10.0858C11.0391 9.71071 10.5304 9.5 10 9.5H6C5.46957 9.5 4.96086 9.71071 4.58579 10.0858C4.21071 10.4609 4 10.9696 4 11.5V12.5" fill="white" />
            <path d="M8 7.5C9.10457 7.5 10 6.60457 10 5.5C10 4.39543 9.10457 3.5 8 3.5C6.89543 3.5 6 4.39543 6 5.5C6 6.60457 6.89543 7.5 8 7.5Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcUserRegisMini
