import React, { useEffect, useReducer, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapCreateNewBank } from './index.styles'
import { OverlayFullScreen } from '../../Page/TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

//help
import fileToBase64 from '../../../../../helper/fileToBase64'

//component
import Button from '../../../../Control/Button'
import IcCreateNew from '../../../../../assets/images/IcCreateNew'
import IcClose from '../../../../../assets/images/IcClose'
import DragAndDropImage from '../../../../Control/DragAndDropImage'
import InputBankName from './InputBankName'
import InputBankCode from './InputBankCode'
import InputBankShortName from './InputBankShortName'
import { useOnClickOutside } from '../../../../../hook/useClickOutside'

//action
import { createNewBank, getListBank } from '../../../../../redux/bank/listBank/action'


const CreateNewBank = ({ onClickIconClose, setIsShowModalAddBank }) => {

    const dispatch = useDispatch()
    const refNewBank = useRef(null)

    const {condFilters} = useSelector(state => ({
        condFilters: state.bankReducer.listBankReducer.condFilters
    }))

    const [faceImg, setFaceImg] = useState(null);
    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            bankName: "",
            bankShortName: "",
            bankCode: ""
        }
    )

    useEffect(() => {
        const { bankName, bankShortName, bankCode } = stateForm
        const isEnable = bankName && bankShortName && bankCode && !!faceImg
        setIsDisabledBtnCreate(!isEnable)
        return () => {
        }
    }, [stateForm, faceImg])

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setStateForm({
            [name]: value
        })
    }

    const callbackSuccess = () => {
        onClickIconClose && onClickIconClose()
        dispatch(getListBank({ ...condFilters }))
    }

    const _handleSubmit = async () => {
        const base64 = await fileToBase64(faceImg)
        const { bankName, bankShortName, bankCode } = stateForm

        const dataRequest = {
            logo: base64.split(',')[1],
            bankName,
            bankShortName,
            bankCode
        }
        // console.log("dataRequest :", dataRequest)
        dispatch(createNewBank(
            dataRequest, callbackSuccess, null            
        ))

    }   

    useOnClickOutside(refNewBank, () => setIsShowModalAddBank(false))
    window.addEventListener("keydown",(e)=>{
        if (e.key === "Escape"){
            onClickIconClose()
        }
    })

    return (
        <OverlayFullScreen >
            <div className="wrap-inner-screen" >
                <WrapCreateNewBank ref={refNewBank}>
                    <IcClose className="icon-close" onClick={onClickIconClose} />
                    <p className="title">Tạo mới Banks</p>
                    <p className="description">Bổ sung thông tin tạo Banks</p>
                    <div className="image">
                        <DragAndDropImage
                            file={faceImg}
                            handleRemoveFile={() => setFaceImg(null)}
                            handleDropFiles={files => setFaceImg(files[0])}
                        />
                    </div>
                    <InputBankName
                        valueInput={stateForm.bankName}
                        onChange={handleChangeValue}
                    />
                    <InputBankShortName
                        valueInput={stateForm.bankShortName}
                        onChange={handleChangeValue}
                    />
                    <InputBankCode
                        valueInput={stateForm.bankCode}
                        onChange={handleChangeValue}
                    />
                    <Button
                        text="Tạo mới"
                        iconBtn={<IcCreateNew />}
                        className="btn-creteNew"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapCreateNewBank>
            </div>
        </OverlayFullScreen>
    )
}

export default CreateNewBank
