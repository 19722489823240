import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

//style
import { WrapCustTransHistory } from './index.styles'

//component
import PageHeader from '../PageHeader'
import TransHistoryItem from '../TransactionHistory/TransHistoryItem'

//action
import { clearHistory, getCustomerHistory } from '../../../../../redux/customer/customerTransactionHistory/action'
import useScrollEnd from '../../../../../hook/useScrollEnd'

const CustTransHistory = () => {
    const listRef = useRef()
    const dispatch = useDispatch()
    const { custId } = useParams()

    const [expandId, setExpandId] = useState("");

    const { customerHistory, totalTransHis } = useSelector(state => ({
        customerHistory: state.customerReducer.customerTransactionHistoryReducer.list,
        totalTransHis: state.customerReducer.customerTransactionHistoryReducer.total
    }))

    useEffect(() => {
        dispatch(getCustomerHistory({
            id: custId,
            filterFrom: "",
            filterSkip: 0,
            filterSize: 20
        }))
        return () => {
            dispatch(clearHistory())
        }
    }, [])

    useScrollEnd(listRef, (callback) => {
        dispatch(getCustomerHistory({
            id: custId,
            filterFrom: customerHistory[0]?.id,
            filterSkip: customerHistory.length,
            filterSize: 20
        }, true, callback))
    }, [customerHistory], [customerHistory.length])

    return (
        <WrapCustTransHistory>
            <PageHeader>Lịch sử giao dịch ({totalTransHis ? totalTransHis : 0})</PageHeader>
            <div className="wrap-list-history" ref={listRef}>
                <div className="list-history">
                    {
                        customerHistory.map((item, i) => (
                            <TransHistoryItem
                                key={i}
                                data={item}
                                isExpand={item.id === expandId}
                                setIsExpand={() =>
                                    setExpandId(pre => pre === item.id ? "" : item.id)
                                }
                            />
                        ))
                    }
                </div>
            </div>
        </WrapCustTransHistory>
    )
}

export default CustTransHistory
