import styled from "styled-components";

const WrapPage = styled.div`
    position: relative;
    display: flex;
    .icon-close{
        position: absolute;
        top: -25px;
        right: 5px;
        cursor: pointer;
    }
`

const WrapBusinesManagerDetails = styled.div`
    display: flex;
    .wrap-business-details{
        width: 800px;
        height: 624px;
        background: #FFFFFF;
        border-radius: 4px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        position: relative;   
        .wrap-status{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #F7F7F7;
            height: 52px;
            padding: 12px;
            box-sizing: border-box;
            border-radius: 4px 4px 0 0;
            .content{
                display: flex;
                align-items: center;
                font-family: 'Open Sans';
                font-style: normal;
                .merchant-id{                
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #313131;
                    padding-left: 8px;
                }
                .wait-update{
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    .refuse-request{
                        display: flex;
                        align-items: center;
                        gap: 4px;                
                        color: #F45C5C;
                    }
                    .update-request{    
                        display: flex;
                        align-items: center;
                        gap: 4px;                
                        color: #33ADFF;
                    }
                    .user-name{
                        color: #313131;
                        &.denied{
                            color: #777777;
                        }
                    }
                    .time{
                        color: #313131;
                    }
                }
                .wrap-btn{
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    position:  relative;
                    .btn-approve{
                        width: 84px;
                        height: 32px;
                        background: #33ADFF;
                        border-radius: 4px;
                    }
                    .btn-refuse{
                        width: 84px;
                        height: 32px;
                        background: #F45C5C;
                        border-radius: 4px;
                    }                
                }
            }
        }
        .wrap-tab-list{        
            height: 32px;
            background: #F7F7F7;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 12px 0 8px;
            box-sizing: border-box;
            .tab-list{
                display: flex;
                flex: 1;
                flex-direction: column;
                height: 100%;
                margin-top: 11px;           
            }
            .note{
                display: flex;
                align-items: center;
                gap: 8px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;            
                .text-note{
                    color: #313131;
                }
                .time-note{
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    font-weight: 400;
                    color: #777777;
                    .ic-info{
                        /* padding: 2px 0 0 0; */
                    }
                }
                .wrap-ic-note{
                    position: relative;
                    cursor: pointer;

                    .circle-red{
                        background: #F45C5C;
                        border-radius: 11px;
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }
    }
`

export { WrapPage, WrapBusinesManagerDetails }