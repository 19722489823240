import React from 'react'

const IcTimeBlue = (props) => {
    return (
        <svg {...props} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="#EFF9FF"/>
        <path d="M18 26.5C22.6944 26.5 26.5 22.6944 26.5 18C26.5 13.3056 22.6944 9.5 18 9.5C13.3056 9.5 9.5 13.3056 9.5 18C9.5 22.6944 13.3056 26.5 18 26.5Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 12.9023V18.0023L21.4 19.7023" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcTimeBlue
