import styled from 'styled-components'

const WrapTransHistoryContent = styled.div`
    position: relative;
    margin-bottom: 8px;
    .wrap-content-popup{
        position: absolute;
        /* top: ${props => props.propsPopup ? props.propsPopup : "100%"}; */
        right: 0;
        bottom: ${props => props.propsPopup};
        z-index: 10;
    }
`

const WrapTransHistoryItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(247, 247, 247, 0.5);
    border-radius: 4px;
    padding: 12px 12px 11px 8px;
    box-sizing: border-box;
    /* position: relative; */
    .wrap-content{
        display: flex;
        flex-direction: column;
        font-family: 'Open Sans';
        font-style: normal;
        align-items:flex-start;
        
        &.right{
            align-items: flex-end;
        }
        .content{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 16px;
        }
        .icon{
            margin-right: 4px;
        }       
        .value{            
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #777777;
            &.bold{
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;            
                color: #313131;                                
            }
        }
        .icon-note{
            margin-left: 5px;
            display: none;
        }
    }
    .wrap-content-img{
        position: absolute;
        visibility: ${props => !props.isVisible ? ' visible' : 'hidden'};
        top: ${props => props.posPopup.top};
        left: ${props => props.posPopup.left};
        right: ${props => props.posPopup.right};
        bottom: ${props => props.posPopup.bottom};
        display: none;
        z-index: 1;
    }
    &.active{
        border: 2px solid #33ADFF;
        height: 74px;
        box-sizing: border-box;
        .wrap-content{
            .icon-note{
                display: block;
            }
        }
    }
    &:hover{
        cursor: pointer;
        border: 2px solid #33ADFF;
        height: 74px;
        box-sizing: border-box;
        .wrap-content{
            .icon-note{
                display: block;
            }
        }
        .wrap-content-img{
            display: block;
        }
    }
`


export { WrapTransHistoryContent, WrapTransHistoryItem }