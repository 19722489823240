// data lỗi Facepay

export const dataErrorSmartpay = {
    ["201"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["202"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["203"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["204"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["205"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["206"]:{text: "Số tiền giao dịch vượt hạn mức hoặc không đủ số dư."},
    ["207"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["208"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["209"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["210"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["211"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["212"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["213"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["214"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["215"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["216"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["217"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["218"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["219"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["220"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["221"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["222"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["223"]:{text: "Khuôn mặt không hợp lệ"},
    ["224"]:{text: "Khuôn mặt chưa được đăng ký"},
    ["225"]:{text: "Quá thời gian để thực hiện giao dịch"},
    ["226"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["227"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["228"]:{text: "Giao dịch không thành công do lỗi hệ thống Smartpay"},
    ["229"]:{text: "Giao dịch không thành công do vượt quá hạn mức thanh toán trong ngày. "},
    ["230"]:{text: "Khách hàng chưa đăng ký dịch vụ Facepay trên app Smartpay"},
    ["231"]:{text: "Không tìm thấy số điện thoại khách hàng nhập ở hệ thống smartpay"},
    ["-99"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-100"]: {text: "Giao dịch không thành công do chưa hoàn tất."},
    ["-101"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-102"]: {text: "Khách hàng chưa nhập đúng số điện thoại"},
    ["-103"]: {text: "Khách hàng cancel giao dịch do không được áp dụng khuyến mãi"},

}   