import React from 'react'

const IcConfirm = (props) => {
    return (
        <svg {...props} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3327 6.46407V7.00074C13.332 8.25865 12.9246 9.48263 12.1715 10.4901C11.4183 11.4976 10.3596 12.2347 9.15331 12.5913C7.94702 12.948 6.65775 12.9052 5.47779 12.4692C4.29783 12.0333 3.2904 11.2276 2.60574 10.1724C1.92109 9.11709 1.59589 7.86877 1.67866 6.61359C1.76143 5.3584 2.24772 4.16359 3.06501 3.20736C3.8823 2.25113 4.98681 1.58471 6.21379 1.30749C7.44078 1.03027 8.72451 1.1571 9.87352 1.66907" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.3333 2.33203L7.5 8.1712L5.75 6.4212" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export default IcConfirm
