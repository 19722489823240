import styled from 'styled-components'

const Bound = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: overlay;
    position: relative;
    .wrap-emty{
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
        p{
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #B7B7B7;
        }
    }
    .wrap-list-kyc{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        .reject-reason{
            width: 100%;
            height: 38px;
            background-color: #FFEEEE;
            border-radius: 4px;
            display: flex;
            align-items: center;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #F45C5C;
            padding: 0 13.33px;
            .icon-alert{
                margin-right: 5.33px;
            }
        }
        .wrap-container{
            margin-bottom: 20px;
            .wrap-info{
                display: grid;
                grid-template-columns: 100px 1fr 130px;
                align-items: center;
                border-radius: 4px;
                background: rgba(247,247,247,0.5);
                padding: 8px;
                .type-active{
                    width: 97px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    background: #EFF9FF;
                    border-radius: 20px;
                }
                .type-old{
                    width: 97px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    background: #F7F7F7;
                    border-radius: 20px;
                }
                .type-icon{
                    padding-top: 2px;
                }
                .type-text{
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
                .value{
                    font-weight: 600;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
                .info-email{
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    .email{
                        font-weight: 400;
                        font-size: 13.5px;
                        line-height: 18px;
                        letter-spacing: -0.01em;
                        color: #313131;
                    }
                }
                .value-time{
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
            }
            .info-register{
                padding: 0 17px 12px 20px;
                .info-title{
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: -0.02em;
                    color: #313131;
                    margin-bottom: 16px;
                }
            }
        } 
    }
`

export {
    Bound
}