import React from 'react'

const IcNoDataCustomCensoship = (props) => {
    return (
        <svg {...props} width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="70.5" cy="70" r="70" fill="#B7B7B7"/>
        <path d="M38 55.3333V44.3333C38 42.3884 38.7726 40.5232 40.1479 39.1479C41.5232 37.7726 43.3884 37 45.3333 37H56.3333M85.6667 37H96.6667C98.6116 37 100.477 37.7726 101.852 39.1479C103.227 40.5232 104 42.3884 104 44.3333V55.3333M104 84.6667V95.6667C104 97.6116 103.227 99.4768 101.852 100.852C100.477 102.227 98.6116 103 96.6667 103H85.6667M56.3333 103H45.3333C43.3884 103 41.5232 102.227 40.1479 100.852C38.7726 99.4768 38 97.6116 38 95.6667V84.6667" stroke="#B7B7B7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M89 67.6C89 80.8548 80.9411 94 71 94C61.0589 94 53 80.8548 53 67.6C53 54.3452 61.0589 46 71 46C80.9411 46 89 54.3452 89 67.6Z" fill="#B7B7B7" stroke="#B7B7B7" strokeWidth="4"/>
        </svg>

    )
}

export default IcNoDataCustomCensoship
