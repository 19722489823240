import React from 'react'
import { NINETY_DAY, OTHER, SEVEN_DAY, TODAY, TWENTY_EIGHT_DAY, YESTERDAY } from '../../../../../../../../constant/keys'
import DropdownControl from '../../../../../../../Control/DropdownControl'

export const dataSelectionTime = [
    {
        text: "Tuỳ chọn",
        key: OTHER
    },
    {
        text: "Hôm nay",
        key: TODAY
    },
    {
        text: "Hôm qua",
        key: YESTERDAY
    },
    {
        text: "7 ngày qua",
        key: SEVEN_DAY
    },
    {
        text: "28 ngày qua",
        key: TWENTY_EIGHT_DAY
    },
    {
        text: "90 ngày qua",
        key: NINETY_DAY
    },
]



const DropdownFilterTime = ({filterCond: { text, key }, setFilterCond }) => {
    
    const handleSetValueSelect = (val) => {
        setFilterCond(val)
    }
    
    return (
        <DropdownControl
            dataSelection={dataSelectionTime}
            valueSelected={{key, text}}
            handleSetValueSelect={handleSetValueSelect}
        />
    )
}

export default DropdownFilterTime
