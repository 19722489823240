import React from 'react'

const IcCornerRight = (props) => {
    return (
        <svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.375 6.75L12.5 9.875L9.375 13" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.5 3V7.375C2.5 8.03804 2.76339 8.67393 3.23223 9.14277C3.70107 9.61161 4.33696 9.875 5 9.875H12.5" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default IcCornerRight
