export const MERCHANT_ID = "MERCHANT_ID"
export const BANK_ID = "BANK_ID"
export const FACEPAY_ID = "FACEPAY_ID"
export const INTERVAL = "INTERVAL"

export const NoneAuth = 0
export const SystemAdmin = 1
export const ACCOUNTANT = 2
export const Management = 3


export const BIOMETRIC_ID = "BIOMETRIC_ID"

export const DEFAULT_VAL = "-"

export const ERROR_INPUT_VALIDATE = 'Không được để trống thông tin này'

// Backlog
export const FACEPAY_ACTIVATED = 'FACEPAY_ACTIVATED' // BANK
export const FIRST_TIME_PAYMENT = 'FIRST_TIME_PAYMENT' // BANK
export const LOCK_ACCOUNT_FROM_ADMIN = 'LOCK_ACCOUNT_FROM_ADMIN' // BANK
export const UNLOCK_ACCOUNT_FROM_ADMIN = 'UNLOCK_ACCOUNT_FROM_ADMIN' // BANK
export const OTP_FAIL_3_TIMES = 'OTP_FAIL_3_TIMES'
export const WAIT_APPROVED_FACE = 'WAIT_APPROVED_FACE'
export const REJECTED_FACE = 'REJECTED_FACE'
export const REUPLOAD_AVATAR = 'REUPLOAD_AVATAR'
export const OTP_CARD_SUCCESS = 'OTP_CARD_SUCCESS'
export const UPDATE_PIN = 'UPDATE_PIN'
export const LOGIN_ANOTHER_DEVICE = 'LOGIN_ANOTHER_DEVICE'
export const DISABLE_ACCOUNT = 'DISABLE_ACCOUNT'
export const UNLINK_BANK_ACCOUNT = 'UNLINK_BANK_ACCOUNT'
export const LINK_BANK_ACCOUNT_FAILED = 'LINK_BANK_ACCOUNT_FAILED'
export const LINK_BANK_ACCOUNT_SUCCESS = 'LINK_BANK_ACCOUNT_SUCCESS'
export const LOCK_OTP_5_MINUTES = 'LOCK_OTP_5_MINUTES'
export const LOCK_OTP_30_MINUTES = 'LOCK_OTP_30_MINUTES'
export const LOCK_OTP_60_MINUTES = 'LOCK_OTP_60_MINUTES'
export const LOCK_OTP_24_HOURS = 'LOCK_OTP_24_HOURS'
export const REQUEST_UPDATE_EKYC = 'REQUEST_UPDATE_EKYC'
export const CLIENT_SUBMIT_EKYC = 'CLIENT_SUBMIT_EKYC'
export const UPDATE_EKYC_FROM_ADMIN = 'UPDATE_EKYC_FROM_ADMIN'
export const APPROVAL_ACCOUNT_SUCCESS = 'APPROVAL_ACCOUNT_SUCCESS'
export const LOCK_ACCOUNT_FROM_SYSTEM = 'LOCK_ACCOUNT_FROM_SYSTEM'
export const BLOCK_ACCOUNT_2_MINUTES_WRONG_PIN = 'BLOCK_ACCOUNT_2_MINUTES_WRONG_PIN'
export const BLOCK_ACCOUNT_5_MINUTES_WRONG_PIN = 'BLOCK_ACCOUNT_5_MINUTES_WRONG_PIN'
export const BLOCK_ACCOUNT_10_MINUTES_WRONG_PIN = 'BLOCK_ACCOUNT_10_MINUTES_WRONG_PIN'
export const BLOCK_ACCOUNT_20_MINUTES_WRONG_PIN = 'BLOCK_ACCOUNT_20_MINUTES_WRONG_PIN'
export const BLOCK_ACCOUNT_60_MINUTES_WRONG_PIN = 'BLOCK_ACCOUNT_60_MINUTES_WRONG_PIN'
export const LOGIN = 'LOGIN'

export const RED = 'red'
export const BLUE = 'blue'
export const GRAY = 'gray'

export const POPUP_NOTE_TRANSACTION_CUSTOMER = 'POPUP_NOTE_TRANSACTION_CUSTOMER'
export const POPUP_NOTE_BACKLOG = 'POPUP_NOTE_BACKLOG'
export const POPUP_NOTE_TRANSACTION = 'POPUP_NOTE_TRANSACTION'

export const BACKLOG_NOTE = 'BACKLOG_NOTE'
export const PAYMENT_NOTE = 'PAYMENT_NOTE'
export const CUSTOMER_NOTE = 'CUSTOMER_NOTE'

export const FAILED = 'FAILED'
export const SUCCESS = 'SUCCESS'

export const FACEPAY = 'FACEPAY'
export const FACE_POS = 'FACE_POS'
export const TERMINAL = 'TERMINAL'
export const BANK = 'BANK'

// transaction
export const PROFILE_ID = "PROFILE_ID"
export const MERCHANT_TX_ID = "MERCHANT_TX_ID"
export const BANK_TX_ID = "BANK_TX_ID"
export const FACEPAY_TX_ID = "FACEPAY_TX_ID"
export const PAYME_TX_ID = "PAYME_TX_ID"
export const PHONE_NUMBER = "PHONE_NUMBER"
export const VOUCHER = "VOUCHER"
export const MERCHANT = "MERCHANT"
export const AMOUNT = "AMOUNT"
export const TIME_TRANSACTION = "TIME_TRANSACTION"
export const REFUND = "REFUND"

export const DEPOSIT = "DEPOSIT"
export const LINK_CARD = "LINKCARD"
export const ANOTHER_WALLET = "TOANOTHERWALLET"
export const TO_BANK = "TOBANK"
export const WITH_DRAWAL = "WITHDRAWAL"

// time
export const OTHER = "OTHER"
export const TODAY = "TODAY"
export const YESTERDAY = "YESTERDAY"
export const SEVEN_DAY = "SEVEN_DAY"
export const TWENTY_EIGHT_DAY = "TWENTY_EIGHT_DAY"
export const NINETY_DAY = "NINETY_DAY"

// code bank
export const CTG = 'CTG'
export const OCB = 'OCB'
export const SMARTPAY = 'SMARTPAY'
export const TPBANK = 'TPB'
export const PVCB = 'PVCB'
export const SGB = 'SGB'

// merchant
export const GS25 = 'GS25'

// type card wallet
export const WALLET = 'WALLET'
export const BANKCARD = 'BANKCARD'
export const BANKACCOUNT = 'BANKACCOUNT'

// SHOW POPUP DETAIL WALLET
export const DEPOSIT_LINKCARD = 'DEPOSIT_LINKCARD'
export const TRANSFER_TO_BANK_WALLET_FACEPOS = 'TRANSFER_TO_BANK_WALLET_FACEPOS'

export const TRANSFER_TO_WALLET = 'TRANSFERTOWALLET'
export const TRANSFER_TO_BANK = 'TRANSFERTOBANK'
export const TRANSFER_FROM_FACEPOS = 'TRANSFERFROMFACEPOS'

// device
export const DEVICE_ID = "DEVICE_ID"
export const STATUS_ID = "STATUS_ID"
export const DEVICE_NAME = "DEVICE_NAME"
export const STORE_LOCATION = "STORE_LOCATION"
export const DEVICE_VERSION = "DEVICE_VERSION"
export const DEVICE_MANAGER = "DEVICE_MANAGER"
export const HAS_TRANSACTION = "HAS_TRANSACTION"

export const DEVICE_APP_INFO = 'DEVICE_APP_INFO'
export const DEVICE_HARDWARE_INFO = 'DEVICE_HARDWARE_INFO'

export const ALL = "ALL"
export const CONNECTED = 'CONNECTED'
export const DISCONNECTED = 'DISCONNECTED'
export const TERMINATED = 'TERMINATED'
export const UNLINKED = 'UNLINKED'
export const LINKED = "LINKED"
export const ADV_PLAYING = "ADV_PLAYING"

export const SERIAL_NUMBER = "SERIAL_NUMBER"
export const TOTAL_MEMORY = "TOTAL_MEMORY"
export const FREE_MOMORY = "FREE_MOMORY"
export const FIRMWARE_DEVICE = "FIRMWARE_DEVICE"
export const LOCATION = "LOCATION"
export const FIRMWARE_CAMERA = "FIRMWARE_CAMERA"
export const VERSION_NAME = "VERSION_NAME"
export const VERSION_CODE = "VERSION_CODE"
export const VERSION_NAME_LAUNCHER = "VERSION_NAME_LAUNCHER"
export const VERSION_CODE_LAUNCHER = "VERSION_CODE_LAUNCHER"
export const LAST_ONLINE_TIME = "LAST_ONLINE_TIME"

export const REBOOT = "REBOOT"
export const EXIT_APP = "EXIT_APP"
export const SNAPSHOT = "SNAPSHOT"
export const SCREENSHOT = "SCREENSHOT"
export const UPDATE_FACEPAY = "UPDATE_FACEPAY"
export const UPDATE_LAUNCHER = 'UPDATE_LAUNCHER'
export const DELETE_DEVICE = 'DELETE_DEVICE'

export const POPUP_DEVICE = "POPUP_DEVICE"

// BUSINESS 
export const BUSINESS_WAITING = "BUSINESS_WAITING"
export const BUSINESS_WAITING_UPDATE = "BUSINESS_WAITING_UPDATE"
export const BUSINESS_REJECT = "BUSINESS_REJECT"
export const BUSSINESS_PPOFILE = "BUSSINESS_PPOFILE"
export const BACKLOG = "BACKLOG"

export const BUSINESS_ID = "BUSINESS_ID"
export const BUSINESS_NAME = "BUSINESS_NAME"
export const FULL_NAME = "FULL_NAME"
export const STORE_NAME = "STORE_NAME"

export const NEW = "NEW"  // đăng ký mới
export const REQUIRE_LICENSE_UPDATE = "REQUIRE_LICENSE_UPDATE" // Chờ cập nhật
export const LICENSE_UPDATED = "LICENSE_UPDATED" // Cập nhật giấy tờ

export const PENDING = "PENDING" // chờ phê duyệt
export const ACCEPTED = "ACCEPTED" // đã duyệt
export const DENIED = "DENIED" // đã từ chối

export const REJECT = "REJECT"
export const NOTE = "NOTE"

export const ADMIN_REQUEST_UPDATE_LICENSE = "ADMIN_REQUEST_UPDATE_LICENSE" // Yêu cầu cập nhật giấy tờ
export const ADMIN_REJECT_BUSINESS = "ADMIN_REJECT_BUSINESS" // Hồ sơ bị từ chối
