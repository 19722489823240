import React, { useState, useRef } from 'react'
import IcCallHistoryDefault from '../../../../../../../assets/images/IcCallHistoryDefault';

//help
import removeAccent from '../../../../../../../helper/removeAccent';
import trimStartAndStripStr from '../../../../../../../helper/trimStartAndStripStr';
import EmptyDataControl from '../../../../../../Control/EmptyDataControl';

//component
import InputSearch from '../../../../../../Control/InputSearch';

//styles
import { WrapCallHistory, WrapListCallHistory, TableContent } from './index.styles'

//mockData
import { listCallHistory } from '../../../../../../../mockData/listCustomers'
import CallHistoryItem from './CallHistoryItem';

const list = []

export default function CallHistory() {

  const inputSearchRef = useRef(null)
  const listRef = useRef();

  const [valueSearch, setValueSearch] = useState("")

  const [list, setList] = useState([])


  const _handleSearch = () => {
    // console.log(valueSearch);
  }

  return (
    <WrapCallHistory>
      <InputSearch
        className="input-search"
        value={valueSearch}
        type="text"
        placeholder="Nhập mã Call ID..."
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            _handleSearch();
          }
        }}
        handleSearch={_handleSearch}
        onChange={(e) => setValueSearch(removeAccent(trimStartAndStripStr(e.target.value)))}
      />

      <WrapListCallHistory>
        {
          list?.length === 0 ? (
            <div className="wrap-empty">
              <EmptyDataControl
                icon={<IcCallHistoryDefault />}
                text={"Khách hàng chưa có lịch sử cuộc gọi"}
              />
            </div>
          ) : (
              <TableContent>
                <div className="wrap-list-call-history" ref={listRef}>
                  <div className="list-call-history">
                    {list?.map((item, index) => (
                      <CallHistoryItem data={item} key={index} />
                    ))}
                  </div>
                </div>
              </TableContent>
            )
        }
      </WrapListCallHistory>

    </WrapCallHistory>
  )
}
