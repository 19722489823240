import React from 'react'

const IcUploadWhite = (props) => {
    return (
        <svg {...props} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.75 8.75V11.0833C12.75 11.3928 12.6271 11.6895 12.4083 11.9083C12.1895 12.1271 11.8928 12.25 11.5833 12.25H3.41667C3.10725 12.25 2.8105 12.1271 2.59171 11.9083C2.37292 11.6895 2.25 11.3928 2.25 11.0833V8.75" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.4173 4.66667L7.50065 1.75L4.58398 4.66667" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 1.75V8.75" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    )
}

export default IcUploadWhite
