import React from 'react'

const IcTransferToWalletFail = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 12H17" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20 9L17 12L20 15" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 14C6.13401 14 3 17.134 3 21H17C17 17.134 13.866 14 10 14Z" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3C7.79086 3 6 4.79086 6 7C6 9.20914 7.79086 11 10 11Z" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="21" cy="21" r="7.5" fill="#F45C5C" stroke="white"/>
      <path d="M23.625 18.375L18.375 23.625" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.375 18.375L23.625 23.625" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcTransferToWalletFail