import { CONNECTED, DISCONNECTED } from "../constant/keys";

const checkStatusOnline = (timeCurrent, timeUpdate) => {
    let ms = timeCurrent - timeUpdate
    const seconds  = Math.floor((ms / 1000))
    // const minutes = Math.floor((ms / 1000 / 60));
    // const hours = Math.floor(ms / 1000 / 60 / 60);
    // const totalMinutes = minutes + (hours * 60)
    if(seconds && seconds < 30){
        return CONNECTED
    }
    if(seconds > 30){
        return DISCONNECTED
    }
    
}

export default checkStatusOnline