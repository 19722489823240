import Axios from 'axios'

// //host
import { hostCustomer } from '../../../constant/host'

// //help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import mapErrors from '../../../helper/mapErrors'
import { hideLoading, showLoading } from '../../loading/action'

//types
import * as types from './types'

const apiGetListCustomerFacepay = `${hostCustomer}/customer/filterFacepayUser`
const apiGetListCustomerBank = `${hostCustomer}/customer/filterBankUser`

let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let isCalling = false
let lastQueryTimeApiGetListCustomerFacepay = ""
let lastQueryTimeApiGetListCustomerBank = ""

// PACEPAY
export const updateFacepayCustomerCondsFilter = ({

    condFiltersFacepay = {
        phone: "",
        profileId: "",
        lastItem: "",
        fullname: "",
        limit: 15,
    },
    isScroll,
    callbackSuccess
}) => async (dispatch) => {

    dispatch({
        type: types.UPDATE_FACEPAY_CUSTOMER_CONDFILTERS,
        condFiltersFacepay,
        isScroll,
    })
    callbackSuccess && callbackSuccess()
}

export const resetFacepayCustomerCondsFilter = () => async dispatch => {
    dispatch({
        type: types.RESET_FACEPAY_CUSTOMER_CONDFILTERS,
    })
}

export const getListCustomerFacepay =
    (
        {
            lastItem,
            limit,
            phone,
            profileId,
            fullname,
        },
        isScroll,
        callbackSuccess,
        callbackError,
    ) => async dispatch => {
        // if (isCalling) return
        // isCalling = true

        !isScroll && dispatch(showLoading(types.LOADING_GET_LIST_CUSTOMER))

        const data = {
            lastItem,
            limit,
            phone,
            profileId,
            fullname,
        }
        headerDataRequest.reqId = `${new Date().getTime()}`
        lastQueryTimeApiGetListCustomerFacepay = headerDataRequest.reqId
        
        const dataRequest = { ...headerDataRequest, data };
        try {
            const res = await Axios.post(apiGetListCustomerFacepay, dataRequest)
            const { data } = res
            if(lastQueryTimeApiGetListCustomerFacepay !== dataRequest.reqId) return
            if (checkIsSuccessAction(data.code)) {
                dispatch({
                    type: types.GET_LIST_CUSTOMER_FACEPAY,
                    payload: {
                        listCustomerFacepay: data.data.list || [],
                        total: data.data.total,
                        isScroll,
                    }
                })
                // isCalling = false
                callbackSuccess && callbackSuccess()
            }
            else {
                dispatch({
                    type: types.GET_LIST_CUSTOMER_FACEPAY_ERR,
                    payload: {
                        ...data
                    }
                })
                callbackError && callbackError()
            }
        }
        catch (err) {
            dispatch(mapErrors(err, types.GET_LIST_CUSTOMER_FACEPAY_ERR))
        }
        // isCalling = false
        !isScroll && setTimeout(() => {
            dispatch(hideLoading(types.LOADING_GET_LIST_CUSTOMER))
        }, 300);
    }

export const resetListCustomerFacepay = (callbackSuccess) => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_CUSTOMER_FACEPAY })
    callbackSuccess && callbackSuccess()
}


// BANK
export const updateBankCustomerCondsFilter = ({
    condFiltersBank = {
        limit: 15,
        skipItem: 0,
        phone: "",
        providers: []
    },
    isScroll,
    callbackSuccess
}) => async (dispatch) => {
    dispatch({
        type: types.UPDATE_BANK_CUSTOMER_CONDFILTERS,
        condFiltersBank,
        isScroll
    })
    callbackSuccess && callbackSuccess()
}

export const resetBankCustomerCondsFilter = (callbackSuccess) => async dispatch => {
    dispatch({
        type: types.RESET_BANK_CUSTOMER_CONDFILTERS,
    })
    callbackSuccess && callbackSuccess()
}

export const getListCustomerBank =
    (
        {
            limit,
            skipItem,
            phone,
            providers,
        },
        isScroll,
        callbackSuccess,
        callbackError,
    ) => async dispatch => {

        // if (isCalling) return
        // isCalling = true

        !isScroll && dispatch(showLoading(types.LOADING_GET_LIST_CUSTOMER))
        const data = {
            limit,
            skipItem,
            phone,
            providers,
        }
        
        headerDataRequest.reqId = `${new Date().getTime()}`
        lastQueryTimeApiGetListCustomerBank = headerDataRequest.reqId
        
        const dataRequest = { ...headerDataRequest, data };
        try {
            const res = await Axios.post(apiGetListCustomerBank, dataRequest)
            const { data } = res
            if(lastQueryTimeApiGetListCustomerBank !== dataRequest.reqId) return
            if (checkIsSuccessAction(data.code)) {
                dispatch({
                    type: types.GET_LIST_CUSTOMER_BANK,
                    payload: {
                        listCustomerBank: data.data.list || [],
                        total: data.data.total,
                        isScroll
                    }
                })
                // isCalling = false
                callbackSuccess && callbackSuccess()
            }
            else {
                dispatch({
                    type: types.GET_LIST_CUSTOMER_BANK_ERR,
                    payload: {
                        ...data
                    }
                })
                callbackError && callbackError()
            }
        }
        catch (err) {
            dispatch(mapErrors(err, types.GET_LIST_CUSTOMER_BANK_ERR))
        }
        // isCalling = false
        !isScroll && setTimeout(() => {
            dispatch(hideLoading(types.LOADING_GET_LIST_CUSTOMER))
        }, 300);
    }

export const resetListCustomerBank = (callbackSuccess) => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_CUSTOMER_BANK })
    callbackSuccess && callbackSuccess()
}


// reqType
export const reqTypeCustomer = ({reqType = '', callbackSuccess}) =>  async dispatch => {
    dispatch({
        type: types.GET_LIST_CUSTOMER_CHANGE_TAB,
        reqType
    })
    callbackSuccess && callbackSuccess()
}