import React from 'react'

const IcBusinessReferral = (props) => {
    return (
        <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={32} height={32} rx={16} fill="#EFF9FF" />
            <g clipPath="url(#clip0_9913_5154)">
                <path d="M22 16V23.5H10V16" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M23.5 12.25H8.5V16H23.5V12.25Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 23.5V12.25" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 12.25H19.375C19.8723 12.25 20.3492 12.0525 20.7008 11.7008C21.0525 11.3492 21.25 10.8723 21.25 10.375C21.25 9.87772 21.0525 9.40081 20.7008 9.04917C20.3492 8.69754 19.8723 8.5 19.375 8.5C16.75 8.5 16 12.25 16 12.25Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 12.25H12.625C12.1277 12.25 11.6508 12.0525 11.2992 11.7008C10.9475 11.3492 10.75 10.8723 10.75 10.375C10.75 9.87772 10.9475 9.40081 11.2992 9.04917C11.6508 8.69754 12.1277 8.5 12.625 8.5C15.25 8.5 16 12.25 16 12.25Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_9913_5154">
                    <rect width={18} height={18} fill="white" transform="translate(7 7)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcBusinessReferral