export const GET_DETAIL_CENSORSHIP = 'GET_DETAIL_CENSORSHIP'
export const GET_DETAIL_CENSORSHIP_ERR = 'GET_DETAIL_CENSORSHIP_ERR'

export const RESET_DETAIL_CENSORSHIP = 'RESET_DETAIL_CENSORSHIP'

export const GET_LIST_SAME_FACE = 'GET_LIST_SAME_FACE'
export const GET_LIST_SAME_FACE_ERR = 'GET_LIST_SAME_FACE_ERR'

export const RESET_LIST_SAME_FACE = 'RESET_LIST_SAME_FACE'


export const APPROVED_USER_SUCCESS = 'APPROVED_USER_SUCCESS'
export const APPROVED_USER_ERR = 'APPROVED_USER_ERR'
export const APPROVED_USER_WARNING = 'APPROVED_USER_WARNING'

export const REJECT_USER_SUCCESS = 'REJECT_USER_SUCCESS'
export const REJECT_USER_ERR = 'REJECT_USER_ERR'
