

import { useDispatch } from "react-redux";
import { DISCONNECTED, EXIT_APP, HAS_TRANSACTION, REBOOT, SCREENSHOT, SNAPSHOT, UPDATE_FACEPAY, UPDATE_LAUNCHER } from "../../../../../../constant/keys";
import { isCheckcSnapshot, isCheckcSreenshotDevice, listCheckUrlScreen, luancherAction, prevURL } from "../../../../../../redux/deviceV2/listDevicesByAdmin/action";

const HandleCallApiLuancherAction = (key, serialNumber, urlScreen, appStatus, status) => {
  const dispatch = useDispatch()

  const handleLuancherAction = () => {
    switch (key) {
      case SCREENSHOT:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          }),
          dispatch(isCheckcSreenshotDevice()),
          dispatch(listCheckUrlScreen(serialNumber?.toString())),
          dispatch(prevURL(urlScreen?.toString()))
        );
      case SNAPSHOT:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          }),
          dispatch(isCheckcSnapshot()),
          dispatch(listCheckUrlScreen(serialNumber?.toString())),
          dispatch(prevURL(urlScreen?.toString()))
        );
      case REBOOT:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          }, appStatus, status)
        );
      case EXIT_APP:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          }, appStatus, status)
        );
      case UPDATE_FACEPAY:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          }, appStatus, status)
        );
      case UPDATE_LAUNCHER:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          }, appStatus, status)
        );
      default:
        return null;
    }
  }

return handleLuancherAction

};

export default HandleCallApiLuancherAction;
