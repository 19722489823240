import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//component
import PopupDeviceInfos from '../../PopupDeviceInfos'
import CheckBoxControl from '../../../../../../Control/CheckBoxControl'

// data
import dataDeviceStatus from '../../../../../../../data/dataDeviceStatus'
import { POPUP_DEVICE } from '../../../../../../../constant/keys'

// styles
import { WrapDeviceItemByList } from './index.styles'

// helper
import { convertDateTime } from '../../../../../../../helper/convertDateTime'

// image
import LogoGS25 from '../../../../../../../assets/images/GS25.png'
import { iconCheckBox } from '../../../../BreadcrumbAndTool/PopupExportFileV2/ExportFileBankV2'
import IcMoreOption from '../../../../../../../assets/images/IcMoreOption'
import DropdownFeatureDevidce from '../../DropdownFeatureDevice'
import IcLocation from '../../../../../../../assets/images/IcLocation'
import { getAvatarMerchant } from '../../../../../../../redux/deviceV2/listDevicesByAdmin/action'
import IcMerchantLogoDefault from '../../../../../../../assets/images/IcMerchantLogoDefault'

const initialPos = {
    top: "50%",
    left: "50%",
    right: "initial",
    bottom: "initial",
}

const DeviceItemByList = ({ data, refList, handleSelectCheckbox, listIdCheckeds, ...props }) => {

    const dispatch = useDispatch()
    const wrapPopupRef = useRef(null)
    const wrapItemRef = useRef(null)

    const [isShowDropdownFeatureDevice, setIsShowDropdownFeatureDevice] = useState(false)

    const [isShowDropdownFeatureDevicePopup, setIsShowDropdownFeatureDevicePopup] = useState(false)
    const [isErrImg, setIsErrImg] = useState(false)

    const [imageUrl, setImageUrl] = useState()

    const [posPopup, setPosPopup] = useState({
        ...initialPos
    });

    const _handleMouseEnter = () => {
        showPopupDeviceInfo()
    }

    const _handleMouseLeave = () => {
        hidePopupDeviceInfo()
        setIsShowDropdownFeatureDevicePopup(false)
    }

    const showPopupDeviceInfo = () => {
        if(!wrapItemRef.current) return
        if(!wrapPopupRef.current) return
        const rectPopup = wrapPopupRef.current.getBoundingClientRect()
        
        const { height, top, left } = rectPopup
        const isOutY = height + top > window.innerHeight - 30
        const isOutX = 300 + left + 10 > window.innerWidth

        if (isOutY && isOutX) {
            wrapPopupRef.current.style.top = "initial"
            wrapPopupRef.current.style.left = "initial"
            wrapPopupRef.current.style.bottom = "50%"
            wrapPopupRef.current.style.right = "50%"
            wrapPopupRef.current.style.visibility = "visible"
            return;
        }

        if (isOutY) {
            const rectItem = wrapItemRef.current.getBoundingClientRect()
            let bottom =  -(window.innerHeight - rectItem.bottom - 30)
            wrapPopupRef.current.style.top = "initial"
            wrapPopupRef.current.style.left = "50%"
            wrapPopupRef.current.style.bottom = `${bottom}px`
            wrapPopupRef.current.style.right = "initial"
            wrapPopupRef.current.style.visibility = "visible"
            return;
        }

        if (isOutX) {
            setPosPopup({
                top: "50%",
                left: "initial",
                bottom: "50%",
                right: "50%",
            })
            wrapPopupRef.current.style.top = "50%"
            wrapPopupRef.current.style.left = "initial"
            wrapPopupRef.current.style.bottom = "50%"
            wrapPopupRef.current.style.right = "50%"
            wrapPopupRef.current.style.visibility = "visible"
            return;
        }

        wrapPopupRef.current.style.top = initialPos.top
        wrapPopupRef.current.style.left = initialPos.left
        wrapPopupRef.current.style.bottom = initialPos.bottom
        wrapPopupRef.current.style.right = initialPos.right
        wrapPopupRef.current.style.visibility = "visible"
    }

    const hidePopupDeviceInfo = () => {
        if(!wrapPopupRef.current) return
        wrapPopupRef.current.style.visibility = "hidden"
        wrapPopupRef.current.style.top = initialPos.top
        wrapPopupRef.current.style.left = initialPos.left
        wrapPopupRef.current.style.bottom = initialPos.bottom
        wrapPopupRef.current.style.right = initialPos.right
    }

    const objStatus = dataDeviceStatus[data.status] || {}

    useEffect(() => {
        if(data.appInfo?.merchantId){
            dispatch(getAvatarMerchant({id: data.appInfo?.merchantId}, getImageSuccess))
        }else{
            dispatch(getAvatarMerchant({id: data.appInfo?.shopId}, getImageSuccess))
        }
    },[])
    
    
    useEffect(() => {
        setIsErrImg(false)
    }, [imageUrl])

    const getImageSuccess = (image) => {
        setImageUrl(image)
    }

    return (
        <WrapDeviceItemByList {...props}
            colorStatus={objStatus.color} 
            ref={wrapItemRef}
            posPopup={posPopup}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_handleMouseLeave}
            className={`row ${data.isSelect ? 'active' : ""}`}
        >
            <CheckBoxControl 
                iconCheckBox={iconCheckBox}
                isChecked={data.isSelect}
                handleCheck={() => handleSelectCheckbox(data.serialNumber)}
            />
            <div className="wrap-status">
                <div className="status" />
                <div className="normal-text">{data.appInfo?.linkCode.substring(0,9).toUpperCase() || "-"}</div>
                <div className="wrap-device-infos" ref={wrapPopupRef}>
                    <PopupDeviceInfos
                        imageUrl={imageUrl}
                        type={POPUP_DEVICE}
                        data={data}
                        isShowDropdownFeatureDevicePopup={isShowDropdownFeatureDevicePopup}
                        setIsShowDropdownFeatureDevicePopup={setIsShowDropdownFeatureDevicePopup}
                    />
                </div>
            </div>
            <div className="normal-text" title={data.appInfo?.name}>{data.appInfo?.name || "-"}</div>
            <div className="normal">     
                {!isErrImg ? 
                    <img 
                        src={imageUrl || ""} 
                        className='logo-merchant' 
                        onError={() => setIsErrImg(true)
                    }/> : <IcMerchantLogoDefault />
                }
                <div className='normal-text'>{data.appInfo?.shopName || "-"}</div>
            </div>
            <div className="normal-text" title={data.appInfo?.shopAddress}>
                {data.appInfo?.shopAddress || "-"}
            </div>
            <a className='normal-icon' href={`https://www.google.com/maps/place/${data.appInfo?.lat},${data.appInfo?.lng}`} target='_blank'>
                <IcLocation />
            </a>
            <div className="normal-text">{data.appInfo?.versionName || "-"}</div>
            <div className="normal-text serial" title={data.serialNumber}>
                {data.serialNumber}
            </div>
            <div className="normal-text">{data.verName || "-"}</div>
            <div className="normal-text">{data.time ? convertDateTime(new Date(Number(data.time)), "HH:mm - DD/MM/YYYY") : "-"}</div>
            <div className='dropdown-feature'>
                <IcMoreOption className="icon" onClick={() => setIsShowDropdownFeatureDevice(true)} />
                {
                    isShowDropdownFeatureDevice ? 
                    <DropdownFeatureDevidce
                        onCloseDropdown={() => setIsShowDropdownFeatureDevice(false)}
                        data={data}
                        checkShowFeatureDevice={true}
                        isCheckOnClickOutside={true}
                    /> : ""
                }
            </div>
        </WrapDeviceItemByList>
    )
}

export default DeviceItemByList
