import styled from "styled-components";

const WrapPopupLocked = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    width: 336px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .popup-header{
        box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
        padding: 16px 13px 13px 20px;
        .popup-title{
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            .popup-value{
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #313131;
                margin-bottom: 8px;
            }
            .icon-close{
                cursor: pointer;
            }
        }
        .value{         
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #777777;
        }
    }
    .popup-content{
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 20px 18px 20px;
        .content-item{
            display: flex;
            align-items: center;
            gap: 10px;
            .content-value{
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
            }
            .content-icon{
                display: flex;
                align-items: center;
            }
        }
        .wrap-reasion{
            width: 296px;
            height: 54px;
            textarea{
                font-family: 'Open Sans';
                width: calc(100% - 12px);
                height: calc(100% - 36px);
                background: #FFFFFF;
                border-radius: 4px;
                border: 0.5px solid #B7B7B7;
                padding: 11px 0 25px 10px;
                outline: unset;
                resize: none;
                &::placeholder{
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #B7B7B7;
                }
            }
        }
    }
    
    .popup-btn{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 16px 20px 20px 20px;
        .btn-save{
            width: 56px;
            height: 36px;
            background: #F7F7F7;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #777777;
            opacity: 1;
        }
        .active{
            background: #F45C5C;
            border-radius: 4px;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #FFFFFF;
        }
    }
`

export {
    WrapPopupLocked
}