import styled from 'styled-components'
import { WrapTransHistoryDetailsItem } from '../../../../../CustomerV2/CustomerInfoExpand/TransactionHistory/TransHistoryItem/TransHistoryDetailsItem/index.styles'

const WrapPopupTransHistoryDetailsWalletTranfer = styled(WrapTransHistoryDetailsItem)`
        .wrap-info-transaction{
            .info-transaction{
                .block-info{
                    .wrap-info{
                        grid-template-columns: 220px 260px;
                    }
                }
                .block-info-customer{
                    margin-bottom: 6px;
                    &:last-child{
                        margin-bottom: 0;

                    }
                }
                .info-icon{
                    img{
                        width: 30px;
                        height: 30px;
                        border-radius: 50%
                    }
                }
            }
        }
`

export {
    WrapPopupTransHistoryDetailsWalletTranfer
}