import styled from "styled-components";

const WrapDropdownFilterStore = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    position: absolute;
    top: 40px;
    right: 5px;
    width: 340px;
    height: 406px;
    background: #FFFFFF;
    border: 1px solid #F7F7F7;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 1;

    .filter-store-search{
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
        
        .input-search{
            width: 100%;
            height: 48px;
            border: none;
        }
    }
    .list-filter-store-all{
        width: 100%;
        display: flex;
        box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);

    }
    .list-filter-store{
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        .title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 15px;

            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            
            .title-store{
                color: #313131;
            }
            .title-check{
                color: #33ADFF;
            }
        }
        .wrap-list-store{
            height: 100%;

            flex-grow: 1;
            overflow: overlay;
            position: relative;
            .list-store{
                display: flex;
                flex-direction: column;
                position: absolute;
            }
        }
    }
    .info-checkbox{
        gap: 6px;
        padding: 15px;
    }
    .filter-button{
        width: 340px;
        height: 42px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .filter-button-search{
            background: none;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #33ADFF;
        }
    }
`

export {
    WrapDropdownFilterStore
}