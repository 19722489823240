import * as types from './types'

// helper
import { FACEPAY_PORTAL_LOGIN } from '../../../helper/localStore'

const adminUser = JSON.parse(localStorage.getItem(FACEPAY_PORTAL_LOGIN))

const initialState = {
    listNote: [],
    isShowPopupNote: false,

    condFiltersFacepay: {
        adminId: adminUser?.uuid,
        adminUserName: adminUser?.username,
        uid: "",
        limit: 15,
        skip: 0
    },

    condFiltersBank: {
        adminId: adminUser?.uuid,
        adminUserName: adminUser?.username,
        bioId: "",
        limit: 15,
        skip: 0
    }
}


const customerNoteReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_CUSTOMER_NOTE:
        case types.GET_LIST_CUSTOMER_BANK_NOTE:
            return {
                ...state,
                ...action.payload,
                listNote: action.payload.isScroll ? [...state.listNote, ...action.payload.listNote] : action.payload.listNote
            }
        case types.RESET_LIST_CUSTOMER_NOTE:
        case types.RESET_LIST_CUSTOMER_BANK_NOTE:
            return {
                ...state,
                listNote: []
            }

        case types.SHOW_POPUP_NOTE:
                return {
                    ...state,
                    isShowPopupNote: true
                }
        case types.HIDE_POPUP_NOTE: 
                return {
                    ...state,
                    isShowPopupNote: false
                }
                
        default:
            return state
    }

}

export default customerNoteReducer