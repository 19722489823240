import React, { useState, useReducer, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

//style
import { WrapFilterBackLog, WrapDateRange, WrapDropDown } from './index.styles'

//image
import IcArrowDownGray from '../../../../../../../../assets/images/IcArrowDownGray';
import IcCalendarActive from '../../../../../../../../assets/images/IcCalendarActive';
import IcArrowGreenRight from '../../../../../../../../assets/images/IcArrowGreenRight';

//component
import DatePickerItem from '../../../../../../../Control/DatePickerItem';
import DropdownControl from '../../../../../../../Control/DropdownControl';
import { dataSelectionFacepayBacklog, dataSelectionBankBacklog } from '../../../../../../../../data/dataSelectionBacklog';
import { updateListBacklogCustomerCondsFilter } from '../../../../../../../../redux/customer/customerBacklog/action';
import { currentDay, startDay } from '../../../../../../../../redux/customer/customerBacklog/customerBacklogReducer';

// helper
import {FACEPAY_PORTAL_LOGIN } from '../../../../../../../../helper/localStore'
import { BANK, FACEPAY } from '../../../../../../../../constant/keys';

const FilterBackLog = () => {
    const userName = JSON.parse(localStorage.getItem(FACEPAY_PORTAL_LOGIN))
    
    const dispatch = useDispatch()

    const { condFilters, details, reqType } = useSelector((state) => ({
        condFilters: state.customerReducer.customerBacklogReducer.condFilters,
        reqType: state.customerReducer.listCustomerReducer.reqType,
        details: state.customerReducer.customerDetailReducer.details, 
    }))

    const [data, setData] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            adminId: condFilters.adminId,
            adminUserName: condFilters.adminUserName,
            uid: condFilters.uid,
            bioId: condFilters.bioId,
            skip: condFilters.skip,
            limit: condFilters.limit,
            type: condFilters.type,
            begin: condFilters.begin,
            end: condFilters.end,
        }
    );

    const _handleSetValueSelect = (val) => {
        setData({ type: val })
    }

    useEffect(() => {
        if (!details?.id) return;
        if(reqType === FACEPAY) {
            dispatch(updateListBacklogCustomerCondsFilter({
                condFilters: {
                    adminId: data.adminId,
                    adminUserName: data.adminUserName,
                    uid: data.uid,
                    type: data.type,
                    skip: data.skip,
                    limit: data.limit,
                    begin: data.begin,
                    end: data.end
                },
                isScroll: false
            }))
        }else{
            dispatch(updateListBacklogCustomerCondsFilter({
                condFilters: {
                    adminId: data.adminId,
                    adminUserName: data.adminUserName,
                    bioId: data.bioId,
                    type: data.type,
                    skip: data.skip,
                    limit: data.limit,
                    begin: data.begin,
                    end: data.end
                },
                isScroll: false
            }))
        }
    },[JSON.stringify(data)])

    useEffect(() => {
        setData({
            adminId: userName.uuid,
            adminUserName: userName.username,
            uid: details?.id,
            bioId: details?.id,
            skip: 0,
            limit: 15,
            type: reqType === FACEPAY ? dataSelectionFacepayBacklog[0] : dataSelectionBankBacklog[0],
            type: dataSelectionFacepayBacklog[0],
            begin: startDay,
            end: currentDay,
        })
    },[details?.id])

    return (
        <WrapFilterBackLog>
            <WrapDropDown>
                <DropdownControl
                    dataSelection={reqType === FACEPAY ? dataSelectionFacepayBacklog : dataSelectionBankBacklog}
                    valueSelected={data.type}
                    handleSetValueSelect={_handleSetValueSelect}
                    maxItemShow={6}
                    alignLeft={"-14px"}
                    maxWidth="286px"
                />
            </WrapDropDown>
            <WrapDateRange>
                <IcCalendarActive />
                <DatePickerItem
                    className="input-dropdown"
                    selected={data.begin}
                    onChange={(date) => setData({ begin: date ? moment(date).startOf('day').toDate() : undefined })}
                    maxDate={data.end ? data.end : new Date()}
                    textEmpty="Từ ngày"
                    dateFormat="dd/MM/yyyy"
                    showTimeSelect={false}
                />
                <IcArrowDownGray className="arrow-right" />
                <DatePickerItem
                    className="input-dropdown "
                    selected={data.end}
                    onChange={(date) => setData({ end: date ? moment(date).endOf('day').toDate() : undefined })}
                    minDate={data.begin}
                    maxDate={new Date()}
                    textEmpty="Đến ngày"
                    dateFormat="dd/MM/yyyy"
                    showTimeSelect={false}
                />
                {/* <IcArrowGreenRight className="icon" onClick={_handleSearch} /> */}
            </WrapDateRange>
        </WrapFilterBackLog>
    )
}

export default FilterBackLog
