import React from 'react'

const IcInfoDefault = (props) => {
    return (
        <svg {...props} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.49935 12.8346C10.721 12.8346 13.3327 10.223 13.3327 7.0013C13.3327 3.77964 10.721 1.16797 7.49935 1.16797C4.27769 1.16797 1.66602 3.77964 1.66602 7.0013C1.66602 10.223 4.27769 12.8346 7.49935 12.8346Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 9.33333V7" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 4.66797H7.50583" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    )
}

export default IcInfoDefault
