import React from 'react'

const IcMailBlueDefault = (props) => {
    return (
        <svg {...props} width={36} height={37} viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5256_41936)">
                <circle cx={18} cy="18.5" r={18} fill="#EFF9FF" />
                <path d="M12 12.5H24C24.825 12.5 25.5 13.175 25.5 14V23C25.5 23.825 24.825 24.5 24 24.5H12C11.175 24.5 10.5 23.825 10.5 23V14C10.5 13.175 11.175 12.5 12 12.5Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M25.5 14L18 19.25L10.5 14" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5256_41936">
                    <rect width={36} height={36} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcMailBlueDefault
