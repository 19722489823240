import styled, { keyframes } from "styled-components";

const expandAnimation = keyframes`
    from {
        max-height: 0;
    }
    to {
        max-height: 503px;
    }
`

const WrapListHistory = styled.div`
.list-history{
    display: flex;
    flex-direction: column;
    .wrap-infos{
        cursor: pointer;
        display: grid;
        grid-template-columns: 196px 247px 1fr 222px 94px;
        grid-gap: 20px;
        .info{
            margin-right: 0;
        }
    }
    .wrap-refuse{
        display: flex;
        align-items: center;
        min-width: 94px;
        .ic-close{
            height: 14px;
            width: 14px;
        }
        .refuse{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #F45C5C;
            padding: 0 8px 0 4px;
        }
        .expand {
            transform: rotate(90deg);
        }
    }
    .wrap-expand{
        overflow: hidden;
        transform-origin: top;
        animation: ${expandAnimation} 0.25s ease-in-out;
        .wrap-list-reason{
            display: flex;
            flex-direction: column;
            background: #FFEEEE;
            padding: 6px 24px;
            .list-reason{
                display: flex;
                margin-bottom: 14px;
                &:last-child{
                    margin-bottom: 0px;
                }
                .icon{
                    padding-top: 2px;
                }
                .text{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #F45C5C;
                    padding-left: 4px;
                }
            }
        }
        .wrap-details{
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
            padding: 13px 20px 35px 20px;
            box-sizing: border-box;
            min-height: 128px;
            .title{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                color: #313131;
                margin-bottom: 26px;
            }
            .row-detail{
                display: flex;
                align-items: center;
                .row{
                    margin-right: 44px;
                    display: flex;
                    align-items: center;
                    .ic-default{
                        height: 36px;
                        padding-right: 9px;
                    }
                    .detail{
                        display: flex;
                        flex-direction: column;
                        font-family: 'Open Sans';
                        font-style: normal;
                        .value{                        
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 20px;
                            color: #313131;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 24ch;
                        }
                        .name{
                            font-weight: 400;
                            font-size: 11px;
                            line-height: 15px;
                            color: #777777;
                        }
                    }
                }
            }
        }
    }
}

`

export {
    WrapListHistory
}