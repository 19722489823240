import styled from "styled-components";

const WrapItemNote = styled.div`    
    margin-bottom: 18px;
    .block-content-note{        
        box-sizing: border-box;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .text{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
            background: #F7F7F7;
            border-radius: 6px;
            padding: 8px;
        }
        .wrap-content{
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 6px;
            background: #F7F7F7;
            border-radius: 6px;
            padding: 8px;
            .image{
                width: 18px;
                height: 18px;
            }
            .content{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 187px;
            }
            .ic-export{
                cursor: pointer;
            }
        }
    }
    .block-item-note{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 8px;
        gap: 8px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #777777;

        .time{
            
        }
        .user{
            display: flex;
            align-items: center;
            gap: 2px;
            .icon{
                width: 11px;
                height: 11px;
            }
        }
    }
`
export { WrapItemNote }