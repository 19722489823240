import styled from 'styled-components'

const WrapPageItem = styled.div`
    width: 100%;
    box-sizing: border-box;
`

const WrapCustomer = styled(WrapPageItem)`
    min-width: 2460px;
    height: 100%;
    display: grid;
    grid-template-columns: 450px 1fr;
    font-family: 'Open Sans';
    .title{
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #313131;
        margin: 0 0 10px 8px;
    }
    .block{
        background: #fff;
        box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        flex-grow: 1;
        background: #fff;
    }
    .customer-content{
        display: grid;
        // grid-template-columns: 840px 590px 520px;
        grid-template-columns: minmax(860px, 1fr) minmax(590px, 1fr) minmax(520px, 1fr);
        grid-gap: 20px;
        flex-direction: column;
        position: relative;
    }
    .error-notify{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 100%;
        height: 34px;
        background: #F45C5C;
        position: absolute;
        cursor: pointer;
        .errro-text{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #FFFFFF;
            opacity: 0.85;
        }
    }
`

export { WrapPageItem, WrapCustomer }