import React, { useState, useRef } from "react";

// data
import dataDeviceStatus from "../../../../../../data/dataDeviceStatus";
import { useOnClickOutside } from "../../../../../../hook/useClickOutside";
import DropdownControl from "../../../../../Control/DropdownControl";

// component
import DropdownList from "../../../../../Control/DropdownList";

// styles
import { WrapSelectStatus } from "./index.styles";

export const dataStatus = Object.values(dataDeviceStatus).map((item) => ({
  ...item,
  key: item.type,
  text: item.vnText,
}));

const PLACEHOLDER = "Chọn trạng thái";

const SelectStatus = ({ objValue: {key, text}, handleSelectStatus, setStatus }) => {

  const eleRef = useRef();

  const [isShowDropdown, setIsShowDropdown] = useState(false);


  const _handleSelectStatus = (val) => {
    setStatus(val);
    setIsShowDropdown(false);
  };

  useOnClickOutside(eleRef, () => {
    setIsShowDropdown(false);
  });

  return (
    <WrapSelectStatus ref={eleRef}>
      <DropdownControl
        dataSelection={dataStatus}
        valueSelected={{ text, key }}
        handleSetValueSelect={_handleSelectStatus}
      />
    </WrapSelectStatus>
  );
};

export default SelectStatus;
