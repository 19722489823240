import React from 'react'

const IcNoDataNoteBacklog = (props) => {
    return (
        <svg {...props} width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="70.5" cy="70" r="70" fill="#B7B7B7"/>
        <path d="M70.5 96.668H100.5" stroke="#B7B7B7" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M85.5 41.6648C86.8261 40.3387 88.6246 39.5938 90.5 39.5938C91.4286 39.5938 92.3481 39.7766 93.206 40.132C94.0639 40.4874 94.8434 41.0082 95.5 41.6648C96.1566 42.3214 96.6775 43.1009 97.0328 43.9588C97.3882 44.8167 97.5711 45.7362 97.5711 46.6648C97.5711 47.5934 97.3882 48.5129 97.0328 49.3708C96.6775 50.2287 96.1566 51.0082 95.5 51.6648L53.8333 93.3315L40.5 96.6648L43.8333 83.3315L85.5 41.6648Z" fill="#B7B7B7"/>
        </svg>

    )
}

export default IcNoDataNoteBacklog
