import styled from "styled-components";

const WrapComfirmRefund = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    width: calc(280px - 40px);
    background: #fff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    .icon-close{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg{
            cursor: pointer;
        }
    }
    .comfirm-title{
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #313131;
    }
    .confirm-value{
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #313131;
        margin: 10px 0 15px 0;
        span{
            font-weight: 600;
        }
    }
    .confirm{
        display: flex;
        margin-bottom: 30px;
        .notify{
            color: #F45C5C;
        }
        p{
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #F45C5C;
        }
    }
    .btn-approved{
        height: 36px;
        background: #33ADFF;
        border-radius: 4px;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 10px;
    }
    .btn-cancel{
        height: 36px;
        background: #F7F7F7;
        border-radius: 4px;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #777777;
    }
`

export {
    WrapComfirmRefund
}