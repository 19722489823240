import { combineReducers } from "redux";

import listWaitingCensorshipReducer from './listWaitingCensorship'
import listRejectProfileReducer from './listRejectProfile'
import detailCensorshipReducer from './detailCensorship'
import listReasonReducer from './listReason'
// import getThumbnailUserReducer from './getThumbnailUser'
// import getFullFacelUserReducer from './getFullFacelUser'
import censorshipCountReducer from './censorshipCount'

const censorshipReducer = combineReducers({
    listWaitingCensorshipReducer,
    listRejectProfileReducer,
    detailCensorshipReducer,
    listReasonReducer,
    // getThumbnailUserReducer,
    // getFullFacelUserReducer,
    censorshipCountReducer,
})

export default censorshipReducer;