import styled from "styled-components"

const WrapPopupEdit = styled.div`
    font-family: 'Open Sans';
    width: 788px;
    border-radius: 4px;
    background: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .popup-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        background: #EFF9FF;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        padding: 0 20px;
        border-radius: 4px 4px 0 0;
        .icon{
            cursor: pointer;
        }
    }
    .popup-title{
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #313131;
        .title-value{
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #33ADFF;
            margin-left: 6px;
        }
    }
    .popup-img{
        padding: 0 20px 20px 20px;
        display: flex;
        gap: 28px;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        .info-img{
            width: 360px;
            height: 204px;
            display: flex;
            .wrapper{
                width: 300px;
               height: 204px;
               background: #F7F7F7;
               border-radius: 4px;
               display: flex;
               align-items: center;
               justify-content: center;   
               pointer-events: auto;
               cursor: auto;
            }
            .img-value{
                width: 60px;
                background: #EFF9FF;
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                letter-spacing: -0.01em;
                color: #33ADFF;
            }
            img{
                width: 300px;
            }
        }
    }
    .info-title{
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #313131;
        padding: 10px 0 14px 20px;
    }
    .popup-info{
        background: #FFFFFF;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        padding: 0 20px;
    }
    .info-container{
        display: flex;
        gap: 20px;
        .info-item{
            .info-input-item{
                width: 172px;
            }
            .info-text{
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                letter-spacing: -0.02em;
                color: #777777;
                margin-bottom: 5px;
            }
            .input-dropdown{
                width: 172px;
                .wrap-input{
                    padding: 10px;
                    width: 100%;
                }
                .wrap-dropdown{
                    left: 0;
                }
            }
            .wrap-input{
                input{
                    padding: 10px;
                }
            }
            .info-input{
                width: 364px;
            }
            .error{
                font-size: 11px;
                line-height: 10px;
                padding: 5px 0;
            }
        }
    }
    .info-ekyc{
        display: flex;
        gap: 20px;
        padding: 0 20px;
    }
    .info-btn{
        display: flex;
        justify-content: flex-end;
        padding: 15px 20px 11px 0;
        .btn-save{
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #FFFFFF;
            
        }
    }

`

export {
    WrapPopupEdit
}