import * as types from './type'
import { hostCustomer } from '../../../constant/host'
import mapErrors from '../../../helper/mapErrors'
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import Axios from 'axios'
import { dataBankType } from '../../../data/dataBankType'
import autoDownloadFromBlob from '../../../helper/autoDownloadFromBlob'


const apiGetListTransactionFacepay = `${hostCustomer}/transaction/bankandterminal`
const apiExportListTransactionFacepay = `${hostCustomer}/transaction/reportbankandteminal`

let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let lastQueryApiGetListTransactionFacepay = ""

const dataRequestApi = {
    limit: "",
    lastItemId: "",
    bankTrxCode: "",
    facepayTrxCode: "",
    merchantTrxCode: "",
    phoneNumber: "",
    profileId: "",
    code: "",
    storeId: ["*"],
    success: "",
    failed: "",
    isRefunded: "",
    refund: "",
    noRefund: "",
    promotion: "",
    noPromotion: "",
    supplier: [],
    begin: "",
    end: "",
}

export const getListTransactionFacepay = (
    data = dataRequestApi,
    isScroll,
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    lastQueryApiGetListTransactionFacepay = headerDataRequest.reqId

    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiGetListTransactionFacepay, dataRequest)
        const { data } = res
        if(lastQueryApiGetListTransactionFacepay !== dataRequest.reqId) return
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.GET_LIST_TRANSACTION_PACEPAY_TERMINAL,
                payload: {
                    listTransactionFacepay: data.data.list || [],
                    totalFacepay: data.data.total,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_LIST_TRANSACTION_PACEPAY_TERMINAL_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_TRANSACTION_PACEPAY_TERMINAL_ERR))
    }
}

export const updateCondFiltersTransactionFacepay = (
    condFiltersTransactionFacepay = dataRequestApi,
    callbackSuccess
) => async dispatch => {
    dispatch({
        type: types.UPDATE_CONDFILTERS_TRANSACTION_FACEPAY,
        condFiltersTransactionFacepay
    })
    callbackSuccess && callbackSuccess()
}

export const exportListTransactionFacepay = (
    data = {
        isFaccepay: "",
        facepayTrxCode: "",
        bankTrxCode: "",
        merchantTrxCode: "",
        profileId: "",
        merchantCode: "",
        supplier: "",
        transAmount:"",
        createdAt: "",
        codePromotion: "",
        refund: "",
        begin: "",
        end: ""
    },
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiExportListTransactionFacepay, dataRequest, {
            responseType: 'blob'
        })
        const { data } = res
        autoDownloadFromBlob(data, 'transaction')
        callbackSuccess && callbackSuccess()
    } catch (error) {
        dispatch(mapErrors(error, types.EXPORT_LIST_TRANSACTION_PACEPAY_TERMINAL_ERR))
        callbackError && callbackError()
    }
}

export const resetCondFiltersTransactionFacepay = (callbackSuccess) => async dispatch => {
    dispatch({type: types.RESET_CONDFILTERS_TRANSACTION_FACEPAY})
    callbackSuccess && callbackSuccess()
}
