import styled from "styled-components";

const WrapFilterBusiness = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    background: #fff;

    .date-title{
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #313131;
        margin-left: 4px;
    }
    .btn-reset{
        background: none;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #33ADFF;
    }
`

const FilterBusinessContent = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;
    .input-business{
        width: 380px;
    }
`

export {
    WrapFilterBusiness,
    FilterBusinessContent
}