import styled from "styled-components";

const WrapControlBlockItem = styled.div`
  font-family: "Open Sans";
  width: 100%;
  .block-success {
    margin-bottom: 26px;
    .success-title {
      height: 66px;
      background: #eff9ff;
      border-radius: 4px;
      position: relative;
      .line-success {
        position: absolute;
        width: 3px;
        height: 48px;
        left: 8px;
        top: calc(50% - 48px / 2);
        background: #33adff;
      }
    }

    .reject-title {
      height: 66px;
      background: #ffeeee;
      border-radius: 4px;
      position: relative;
      .line-success {
        position: absolute;
        width: 3px;
        height: 48px;
        left: 8px;
        top: calc(50% - 48px / 2);
        background: #f45c5c;
      }
    }

    .note-payment-content{
      padding: 13px 12px 0 20px;
      .note-payment-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
      }
      .note-payment-desc{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

    }

    .refresh-title {
      height: 66px;
      background: #f7f7f7;
      border-radius: 4px;
      position: relative;
      .line-refresh {
        position: absolute;
        width: 3px;
        height: 48px;
        left: 8px;
        top: calc(50% - 48px / 2);
      }
      &.gray{
        background: rgba(247, 247, 247, 0.5);
      }
      &.red{
          background: #FFEEEE;
      }
      &.blue{
          background: #EFF9FF;
      }
    }
  }



  .note-left {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 13px 0 0 20px;
    .gray{
      background: #777777;
    }
    .red{
        background: #F45C5C;
    }
    .blue{
        background: #33ADFF;
    }
    .success {
      display: flex;
      align-items: center;
      gap: 4px;
      svg{
        width: 18px;
        height: 18px;
      }
      .icon{
        display: flex;
        align-items: center;
      }
      .value {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #313131;
      }
      .text{
        font-family: 'Open Sans';
        font-style: normal;
        font-size: 13.5px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #313131;
        .value{
          margin-left: 3px;
        }
      }
    }
    .text {
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: #777777;
    }
  }
  .note-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding-right: 14px;
    .value {
      font-weight: 600;
      font-size: 13.5px;
      line-height: 18px;
      text-align: right;
      letter-spacing: -0.01em;
      color: #313131;
    }
    .text {
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      text-align: right;
      letter-spacing: -0.02em;
      color: #777777;
    }
  }
  .note-backlog {
    display: flex;
    gap: 6px;
    font-weight: 400;
    font-size: 13.5px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #313131;
    padding: 14px 12px;
    background: rgba(247, 247, 247, 0.5);
    
    .note-backlog-text{

      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  .note-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 12px;
    .value-user {
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: #777777;
    }
  }

  .success {
    display: flex;
    align-items: center;
    gap: 4px;
    svg{
      width: 18px;
      height: 18px;
    }
    .icon{
      display: flex;
      align-items: center;
    }
    .value {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13.5px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: #313131;
    }
    .text{
      font-family: 'Open Sans';
      font-style: normal;
      font-size: 13.5px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: #313131;
      .value{
        margin-left: 3px;
      }
    }
  }
  .value-amount{
    font-weight: 600;
    font-size: 13.5px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.01em;
    color: #313131;
  }
  .text {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #777777;
  }

  .note-backlog-content{
    padding: 0 12px;
    margin-bottom: 10px;
  }
`;

export { WrapControlBlockItem };
