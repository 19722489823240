export const GET_LIST_DEVICES_BY_ADMIN = 'GET_LIST_DEVICES_BY_ADMIN'
export const GET_LIST_DEVICES_BY_ADMIN_ERR = 'GET_LIST_DEVICES_BY_ADMIN_ERR'
export const GET_LIST_DEVICES_BY_ADMIN_TEMP = 'GET_LIST_DEVICES_BY_ADMIN_TEMP'

export const DETAIL_DEVICE = "DETAIL_DEVICE"

export const TOGGLE_LIST_VIEW_MODE = 'TOGGLE_LIST_VIEW_MODE'
export const UPDATE_DEVICE_CONDFILTERS = 'UPDATE_DEVICE_CONDFILTERS'

export const LUANCHER_ACTION_SUCCESS = 'LUANCHER_ACTION_SUCCESS'
export const LUANCHER_ACTION_HAS_TRANSACTION_SUCCESS = 'LUANCHER_ACTION_HAS_TRANSACTION_SUCCESS'
export const LUANCHER_ACTION_ERR = 'LUANCHER_ACTION_ERR'

export const UPDATE_LIST_ID_CHECKED_DEVICE = 'UPDATE_LIST_ID_CHECKED_DEVICE'
export const RESET_LIST_ID_CHECKED_DEVICE = 'RESET_LIST_ID_CHECKED_DEVICE'

export const RESET_LIST_VIEW_MODE = 'RESET_LIST_VIEW_MODE'
export const RESET_LIST_DEVICES_BY_ADMIN = 'RESET_LIST_DEVICES_BY_ADMIN'
export const RESET_DEVICE_CONDFILTERS = 'RESET_DEVICE_CONDFILTERS'
export const RESET_LIST_DEVICES_BY_ADMIN_LIST_TEMP = 'RESET_LIST_DEVICES_BY_ADMIN_LIST_TEMP'

export const IS_CHECK_SCREENSHOT = "IS_CHECK_SCREENSHOT"
export const RESET_IS_CHECK_SCREENSHOT = "RESET_IS_CHECK_SCREENSHOT"

export const IS_CHECK_SNAPSHOT = "IS_CHECK_SNAPSHOT"
export const RESET_IS_CHECK_SNAPSHOT = "RESET_IS_CHECK_SNAPSHOT"

export const GET_AVATAR_MERCHANT = "GET_AVATAR_MERCHANT"
export const GET_AVATAR_MERCHANT_ERR = "GET_AVATAR_MERCHANT_ERR"

export const LIST_CHECKED_URL_SCREEN = "LIST_CHECKED_URL_SCREEN"

export const GET_URL_SCREEN = "GET_URL_SCREEN"
