import React from 'react'

const IcBusinessTime = (props) => {
  return (
    <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width={32} height={32} rx={16} fill="#EFF9FF" />
      <path d="M21.25 10.5H10.75C9.92157 10.5 9.25 11.1716 9.25 12V22.5C9.25 23.3284 9.92157 24 10.75 24H21.25C22.0784 24 22.75 23.3284 22.75 22.5V12C22.75 11.1716 22.0784 10.5 21.25 10.5Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.25 15H22.75" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 9V12" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 9V12" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default IcBusinessTime