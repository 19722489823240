import styled from 'styled-components'

const WrapBackLog = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`
const WrapListBackLog = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
    margin: 26px 0 10px 0;
    
    .wrap-list-back-log {
        width: calc(100% + 10px);
        flex-grow: 1;
        overflow: overlay;
        position: relative;
        margin-left: 10px;
        .list-back-log {
            width: calc(100% - 10px);
            display: flex;
            flex-direction: column;
            position: absolute;            
        }
    }
    .wrap-empty{
        p{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #B7B7B7;
        }
    }
`

export {
    WrapBackLog,
    WrapListBackLog
}