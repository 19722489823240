import styled from "styled-components";
import { WrapBank } from "../Bank/index.styles";

const WrapWalletFacepay = styled(WrapBank)`

    .wrap-header{
        grid-template-columns: minmax(95px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(90px, 1fr) minmax(20px, 30px) minmax(90px, 1fr) minmax(100px, 1fr) minmax(95px, 1fr) 130px;
    }
    .checkbox{
        width: 180px !important;
    }
`

export {
    WrapWalletFacepay
}