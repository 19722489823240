import React from 'react'

const IcRefundWhite = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.41742 4.39703H12.8755" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.3652 1.88672L12.8755 4.39698L10.3652 6.90724" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.20758 9.64703H1.74945" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.25977 7.13672L1.7495 9.64698L4.25977 12.1572" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcRefundWhite
