import React from 'react'

const IcCloseBgGray = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#B7B7B7"/>
        <path d="M16 8L8 16" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 8L16 16" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcCloseBgGray
