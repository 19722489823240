import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useNavigate } from 'react-router-dom'

// action
import { popNotify } from '../../../redux/notifies/action'
import { logoutAction } from '../../../redux/login/action'

// constant
import { AUTHENTICATED_ERR } from '../../../redux/notifies/types'

// styles
import { WrapNotify } from './index.styles'

// component
import Toast from './Toast'
// import Toast1107 from './Toast1107.js'

// constant
import { toastTypes } from './Toast/index.styles'

// helpper
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'

const messages = {
    APPROVED_USER_SUCCESS: 'Phê duyệt tài khoản thành công',
    REJECT_USER_SUCCESS: 'Phê duyệt tài khoản thành công',

    LOCK_CUSTOMER_SUCCESS: 'Khoá tài khoản thành công',
    UN_LOCK_CUSTOMER_SUCCESS: 'Mở khoá tài khoản thành công',
    
    LOCK_CUSTOMER_BANK_SUCCESS: 'Khoá tài khoản thành công',
    UN_LOCK_CUSTOMER_BANK_SUCCESS: 'Mở khoá tài khoản thành công',

    REQUEST_UPDATE_CUSTOMER_SUCCESS: 'Gửi yêu cầu cập nhật thành công',
    
    UPDATE_PASSPORT_CUSTOMER_SUCCESS: 'Tuỳ chỉnh giấy tờ thành công',
    UPDATE_ID_CARD_CUSTOMER_SUCCESS: 'Tuỳ chỉnh giấy tờ thành công',

    LUANCHER_ACTION_SUCCESS: 'Máy chủ đã nhận lệnh',
    LUANCHER_ACTION_HAS_TRANSACTION_SUCCESS: 'Thiết bị đang thực hiện giao dịch',
    TRANSACTION_REFUND_SUCCESS: "Xác nhận hoàn tiền thành công"
}

const Notify = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { notifiesReducer } = useSelector(state => ({
        notifiesReducer: state.notifiesReducer
    }))
    const [currNotify, setCurrNotify] = useState(null);

    const _handleClosePopup = () => {
        setCurrNotify(null)
        dispatch(popNotify(currNotify.type))
    }

    useEffect(() => {
        if(currNotify) return;
        setCurrNotify(Object.values(notifiesReducer)[0])
    }, [notifiesReducer]);

    useEffect(() => {
        if(!currNotify) return;
        if(currNotify.type !== AUTHENTICATED_ERR) return;
        dispatch(logoutAction())
    }, [currNotify]);

    const notify = useMemo(() => {
        if(!currNotify) return null;

        const type = (() => {
            const isSuccess = /.*_SUCCESS/.test(currNotify.type)
            if(isSuccess)
                return toastTypes.SUCCESS
            
            // const isError = /.*_ERR/.test(currNotify.type)
            // if(isError) 
            return toastTypes.ERROR
        })()

        const code = currNotify?.payload?.code
        
        const message = (() => {
            if(checkIsSuccessAction(code)){
                return messages[currNotify.type]
            }else{
                return messages[code] ||  messages[currNotify.type] || `Đã có lỗi xảy ra, code ${code}`
            }
        })()
        if(!message) return null
        return <Toast type={type} onClose={_handleClosePopup}>{message}</Toast>
    }, [currNotify])

    return (
        <WrapNotify>
            {notify}
        </WrapNotify>
    )
}

export default Notify
