import React from 'react'

const IcField = (props) => {
    return (
        <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width={32} height={32} rx={16} fill="#EFF9FF" />
            <path d="M22 23.25V12.15C22 11.3099 22 10.8899 21.8365 10.569C21.6927 10.2868 21.4632 10.0573 21.181 9.91349C20.8601 9.75 20.4401 9.75 19.6 9.75H12.4C11.5599 9.75 11.1399 9.75 10.819 9.91349C10.5368 10.0573 10.3073 10.2868 10.1635 10.569C10 10.8899 10 11.3099 10 12.15V23.25M23.5 23.25H8.5" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.625 12.75H14.6875M12.625 15.75H14.6875M12.625 18.75H14.6875M17.3125 12.75H19.375M17.3125 15.75H19.375M17.3125 18.75H19.375" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcField