import styled from 'styled-components'

const WrapCallHistoryItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(247, 247, 247, 0.5);
    border-radius: 4px;
    padding: 10px 12px 11px 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    height: 74px;
    position: relative;
    .wrap-content{
        display: flex;
        flex-direction: column;
        font-family: 'Open Sans';
        font-style: normal;
        .content{
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .wrap-status{
                display: flex;
                align-items: center;
                padding: 3px 8px 3px 3px;
                border-radius: 20px;
                .text{
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #313131;
                    padding-left: 5px;
                }            
            }
            .call-id{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                color: #313131;
                padding-left: 4px;
            }        
        }
        .wrap-download{
            display: flex;
            align-items: center;
            cursor: pointer;
            .icon{
                margin-right: 5px;
            }
            .download{
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                color: #313131;
            }
        }
        .call-time{
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;            
            color: #313131;  
            display: flex;
            justify-content: flex-end;
            margin-bottom: 16px;                              
        }
        .time{            
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #777777;   
        }
    }
`
export { WrapCallHistoryItem }