import React from 'react'

const IcWaitUpdate = (props) => {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={12} cy={12} r={12} fill="#FF9533" />
            <path d="M4.66675 17.3334V13.3334H8.66675" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.3333 6.66663V10.6666H15.3333" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.34008 10C6.67819 9.04453 7.25284 8.19028 8.01039 7.51696C8.76795 6.84363 9.68372 6.37319 10.6723 6.14952C11.6608 5.92584 12.6899 5.95624 13.6635 6.23786C14.6372 6.51948 15.5236 7.04315 16.2401 7.76001L19.3334 10.6667M4.66675 13.3333L7.76008 16.24C8.47658 16.9569 9.363 17.4805 10.3366 17.7622C11.3102 18.0438 12.3393 18.0742 13.3279 17.8505C14.3164 17.6268 15.2322 17.1564 15.9898 16.4831C16.7473 15.8097 17.322 14.9555 17.6601 14" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcWaitUpdate