import React, { useRef, useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from 'react-redux'

//style
import {
  WrapWaitingCensorship,
  WrapWaitingList,
  TableWaitingList,
  TableHeader,
  TableContent,
} from "./index.styles";

//image
import IcCensorshipDefault from "../../../../../../assets/images/IcCensorshipDefault";

//data
import {
  headerWaitingCensorship,
  listNote,
  listWaitingCensorship,
} from "../../../../../../data/dataWaitingCensorship";

//component
import PageHeader from "../../PageHeader";
import EmptyDataControl from "../../../../../Control/EmptyDataControl";
import ItemWaitingCensorship from "./ItemWaitingCensorship";
import FilterWaitingCensorship from "./FilterWaitingCensorship";
import useScrollEnd from "../../../../../../hook/useScrollEnd";

//action
import { getListWaitingCensorship, updateWaitingCondsFilter } from "../../../../../../redux/censorship/listWaitingCensorship/action";
import { resetListRejectProfile, resetRejectCondsFilter} from "../../../../../../redux/censorship/listRejectProfile/action";

const WaitingCensorship = () => {

  const dispatch = useDispatch()

  const listRef = useRef();

  const { list, total, condFilters, isScroll } = useSelector(state => ({
    list: state.censorshipReducer.listWaitingCensorshipReducer.list,
    total: state.censorshipReducer.listWaitingCensorshipReducer.total,
    condFilters: state.censorshipReducer.listWaitingCensorshipReducer.condFilters,
    isScroll: state.censorshipReducer.listWaitingCensorshipReducer.isScroll
  }))

  useEffect(() => {
    dispatch(resetListRejectProfile())
    dispatch(resetRejectCondsFilter())
  
    return () => {
      
    }
  }, [])

  useEffect(() => {
    dispatch(getListWaitingCensorship({
      lastItem: condFilters.lastItem,
      limit: condFilters.limit,
      phone: condFilters.phone,
      documentId: condFilters.documentId,
      startTime: condFilters.startTime,
      endTime: condFilters.endTime,
      isNew: condFilters.isNew,
      isRepending: condFilters.isRepending
    }, isScroll))
    return () => {
      
    }
  }, [JSON.stringify(condFilters)])
  

  useScrollEnd(listRef, (callback) => {
    dispatch(updateWaitingCondsFilter({
      condFilters: {
        lastItem: list[list.length - 1].id,
        limit: 15
      },
      isScroll: true,
      callbackSuccess: callback
    }))
  }, [list.length], [list.length])

  return (
    <WrapWaitingCensorship>
      <div className="filter">
        <FilterWaitingCensorship />
      </div>
      <WrapWaitingList>
        <div className="wrap-page-header">
          <p className="title-header">Danh sách tài khoản ({total ? total : 0})</p>
          <div className="list-note">
            {listNote.map((item, i) => (
              <div className="note" key={i}>
                {item.icon && item.icon}
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
        <TableWaitingList>
          {list?.length === 0 ? (
            <div className="wrap-empty">
              <EmptyDataControl
                icon={<IcCensorshipDefault />}
                text={"Hiện tại chưa có hồ sơ mới"}
              />
            </div>
          ) : (
              <>
                <TableHeader>
                  {headerWaitingCensorship.map((item, i) => (
                    <div className="table-header" key={i}>
                      {item}
                    </div>
                  ))}
                </TableHeader>
                <TableContent>
                  <div className="wrap-list-waiting" ref={listRef}>
                    <div className="list-waiting">
                      {list?.map((item, i) => (
                        <ItemWaitingCensorship key={item.id} data={item} />
                      ))}
                    </div>
                  </div>
                </TableContent>
              </>
            )}
        </TableWaitingList>
      </WrapWaitingList>
    </WrapWaitingCensorship>
  );
};

export default WaitingCensorship;
