import React from 'react'

const IcDeleteGray = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 4H3.33333H14" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.33334 3.99992V2.66659C5.33334 2.31296 5.47382 1.97382 5.72387 1.72378C5.97392 1.47373 6.31305 1.33325 6.66668 1.33325H9.33334C9.68697 1.33325 10.0261 1.47373 10.2762 1.72378C10.5262 1.97382 10.6667 2.31296 10.6667 2.66659V3.99992M12.6667 3.99992V13.3333C12.6667 13.6869 12.5262 14.026 12.2762 14.2761C12.0261 14.5261 11.687 14.6666 11.3333 14.6666H4.66668C4.31305 14.6666 3.97392 14.5261 3.72387 14.2761C3.47382 14.026 3.33334 13.6869 3.33334 13.3333V3.99992H12.6667Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.33334 7.33325V11.3333" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.66666 7.33325V11.3333" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcDeleteGray