import React from 'react'

const IcCloseCircleRed = (props) => {
    return (
        <svg {...props} width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 14.25C10.9518 14.25 13.75 11.4518 13.75 8C13.75 4.54822 10.9518 1.75 7.5 1.75C4.04822 1.75 1.25 4.54822 1.25 8C1.25 11.4518 4.04822 14.25 7.5 14.25Z" stroke="#F45C5C" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.375 6.125L5.625 9.875M5.625 6.125L9.375 9.875" stroke="#F45C5C" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCloseCircleRed