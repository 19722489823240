import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-direction: row;
    gap: 14px;
    letter-spacing: -0.01em;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13.5px;
    line-height: 18px;
    color: #B7B7B7;
    .tab {
        padding: 0 6px 8px 6px;
        user-select: none;
        cursor: pointer;
        position: relative;
        color: #313131;

        &.selected {
            color: #313131;
            box-shadow: inset 0px -2px 0px #33ADFF;
            cursor: auto;
        }    
        .alert-not{
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #F45C5C;
            position: absolute;
            top: 0;
            right: 0;
        }
        /* &:hover {
            cursor: pointer;
        } */
    }
    
`

export {
    Bound
}