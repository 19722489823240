import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//component
import { convertDateTime } from "../../../../../../../../helper/convertDateTime";
import FaceRecognizeCustom from "./FaceRecognizeCustom";
import EmptyDataControl from "../../../../../../../Control/EmptyDataControl";

// image
import IcUserBlueBgWhite from '../../../../../../../../assets/images/IcUserBlueBgWhite'
import IcWarningRed from "../../../../../../../../assets/images/IcWarningRed";
import IcNoDataCustomCensoship from "../../../../../../../../assets/images/IcNoDataCustomCensorship";

//styles
import * as styles from "./index.styles";

// data
import { ACTIVE, dataCensorStatus, REJECT } from "../../../../../../../../data/dataCustomer";
// import { detaisWaitingCensorship } from "../../../../../../../../data/dataWaitingCensorship";

// component
import LoadingLocal from "../../../../../../../Control/LoadingLocal";

// action
import { getListVerifyHistoryCustomer, resetListDeviceHistoryCustomer, resetListEkycHistoryCustomer, resetListVerifyHistoryCustomer } from "../../../../../../../../redux/customer/customerDetail/action";
import { LOADING_GET_LIST_VERIFY_CUSTOMER } from "../../../../../../../../redux/customer/customerDetail/types";
import { FACEPAY } from '../../../../../../../../constant/keys'

export default function Censorship() {
  
  const dispatch = useDispatch()
  const { details, listVerifyHistory, isTypeLoading,
    reqType } = useSelector((state) => ({
    details: state.customerReducer.customerDetailReducer.details,
    listVerifyHistory: state.customerReducer.customerDetailReducer.listVerifyHistory,

    reqType: state.customerReducer.listCustomerReducer.reqType,

    isTypeLoading: state.loading[LOADING_GET_LIST_VERIFY_CUSTOMER]
  }))
  
  useEffect(() => {
    dispatch(resetListDeviceHistoryCustomer())
    dispatch(resetListEkycHistoryCustomer())
  },[])

  useEffect(() => {
    if(reqType !== FACEPAY) return
    if(details?.id && details.accountType === FACEPAY){
      dispatch(getListVerifyHistoryCustomer({uid: details?.id}))
    }
  },[details?.id])

  return (
    <styles.Bound>
      { isTypeLoading ? (
          <LoadingLocal />
        ) : (
          listVerifyHistory?.length === 0 && !isTypeLoading ? (
            <div className="wrap-emty">
              <EmptyDataControl 
                icon={<IcNoDataCustomCensoship />}
                text='Tài khoản không có dữ liệu kiểm duyệt'
              />
            </div>
          ) : (
            <div className="wrap-list-censorship">
              {listVerifyHistory?.map((item, index) => (
                <div className="wrap-container" key={index}>
                  <div className="wrap-info">
                    <div className={item?.status === ACTIVE ? "type-active" : "type-reject"}>
                      <div className="type-icon">
                        {dataCensorStatus[item?.status]?.icon}
                      </div>
                      <div className="type-text">
                        {dataCensorStatus[item?.status]?.text}
                      </div>
                    </div>
                    <div className="info">
                      <span className="value">
                        {item?.documentId || "-"}
                      </span>
                    </div>
                    <div className="info-email">
                      <IcUserBlueBgWhite />
                      <span className="email" title={item?.verifiedBy || ""}>
                        {item?.verifiedBy || "-"}
                      </span>
                    </div>
                    <div className="info">
                      <span className="value-time">
                        {item?.verifiedAt
                          ? convertDateTime(item?.verifiedAt, "HH:mm - DD/MM/YYYY")
                          : "-"}
                      </span>
                    </div>
                  </div>
                  {
                    item?.status === REJECT && (
                      <div className="list-reason">
                        {
                          item.reasons?.map((item, index) => (
                            <div className="reason" key={index}>
                              <IcWarningRed className="icon" />
                              <p className="text">{item}</p>
                            </div>
                          ))
                        }
                        {
                          !!item.optionalReason &&
                          <div className="reason">
                              <IcWarningRed className="icon" />
                              <p className="text">Lý do khác: {item.optionalReason}</p>
                          </div>
                        }
                      </div>
                    )
                    
                  }
                  <FaceRecognizeCustom item={item}/>
                </div>
              ))}
            </div>
          )
        )
      }
    </styles.Bound>
  );
}
