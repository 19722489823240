import Axios from 'axios'

// //host
import { host } from '../../../constant/host'

// //help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import mapErrors from '../../../helper/mapErrors'
import { disableLoading, enableLoading } from '../../loading/action'

//types
import * as types from './types'

//data
import { dataSelectionCensor } from '../../../components/Main/Content/Page/Censorship/WaitingCensorship/FilterWaitingCensorship/DropdownCensorship'

//action
import { getCensorshipCount } from '../censorshipCount/action'

const apiGetListRejectProfile = `${host}/facepay/user/reject/filter`

let isCalling = false

export const getListRejectProfile =
    (
        {
            lastItem,
            limit,
            phone,
            documentId,
            startTime,
            endTime,
            isRejectOnce,
            isRejectGreaterOne            
        },
        isScroll,
        callbackSuccess,
        callbackError,
    ) => async dispatch => {

        if (isCalling) return
        isCalling = true

        dispatch(getCensorshipCount())

        const dataRequest = JSON.stringify({
            lastItem,
            limit,
            phone,
            documentId,
            startTime,
            endTime,
            isRejectOnce,
            isRejectGreaterOne 
        })
        !isScroll && dispatch(enableLoading())
        try {
            const res = await Axios.post(apiGetListRejectProfile, dataRequest)
            const { data } = res
            if (checkIsSuccessAction(data.code)) {
                dispatch({
                    type: types.GET_LIST_REJECT_PROFILE,
                    payload: {
                        list: data.data.list || [],
                        total: data.data.total,
                        // condFilters,
                        isScroll
                    }
                })
                isCalling = false
                callbackSuccess && callbackSuccess()
            }
            else {
                dispatch({
                    type: types.GET_LIST_REJECT_PROFILE_ERR,
                    payload: {
                        ...data
                    }
                })
                callbackError && callbackError()
            }
        }
        catch (err) {
            dispatch(mapErrors(err, types.GET_LIST_REJECT_PROFILE_ERR))
        }
        isCalling = false
        !isScroll && setTimeout(() => {
            dispatch(disableLoading())
        }, 500);
    }



export const resetListRejectProfile = () => async (dispatch) => {
    dispatch({type: types.RESET_LIST_REJECT_PROFILE})
}


export const updateRejectCondsFilter = ({
    condFilters={
        optionSelected: dataSelectionCensor[0],
        phone: "",
        documentId: "",
        lastItem: "",
        limit: 15,
        startTime: "",
        endTime: "",
        isRejectOnce: true,
        isRejectGreaterOne : true
    }, 
    isScroll=false,
    callbackSuccess
    }) => async (dispatch) => {
    dispatch({ 
        type: types.UPDATE_REJECT_CONDFILTERS,
        condFilters,
        isScroll
    })
    callbackSuccess && callbackSuccess()
}

export const resetRejectCondsFilter = () => async (dispatch) => {
    dispatch({ type: types.RESET_REJECT_CONDFILTERS })
}