import React from 'react'

const IcViewList = ({...props}) => {
    return (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.6665 10.5H12.2498" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.75 10.5H1.75583" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.6665 7H12.2498" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.75 7H1.75583" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.6665 3.5H12.2498" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.75 3.5H1.75583" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

    )
}

export default IcViewList
