import React from "react";

//style
import { WrapTextArea } from './index.styles'

const TextArea = ({ placeholder, maxLength, onChange, value, className, rows, id, onKeyPress, ...props }) => {
  return (
    // <WrapTextArea>
    <textarea
      id={id}
      className={className}
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={onChange}
      value={value}
      rows={rows}
      wrap="hard"
      onKeyPress={onKeyPress}
    />
    // </WrapTextArea>
  );
}

export default TextArea