import React from 'react'

const IcLocation = (props) => {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_12921_16852)">
        <path d="M15.3036 7.58331C15.3036 12.5416 8.92859 16.7916 8.92859 16.7916C8.92859 16.7916 2.55359 12.5416 2.55359 7.58331C2.55359 5.89256 3.22524 4.27105 4.42078 3.07551C5.61633 1.87996 7.23783 1.20831 8.92859 1.20831C10.6193 1.20831 12.2408 1.87996 13.4364 3.07551C14.6319 4.27105 15.3036 5.89256 15.3036 7.58331Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.92859 9.70831C10.1022 9.70831 11.0536 8.75692 11.0536 7.58331C11.0536 6.40971 10.1022 5.45831 8.92859 5.45831C7.75498 5.45831 6.80359 6.40971 6.80359 7.58331C6.80359 8.75692 7.75498 9.70831 8.92859 9.70831Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_12921_16852">
        <rect width="17" height="17" fill="white" transform="translate(0.428589 0.5)"/>
        </clipPath>
        </defs>
    </svg>

  )
}

export default IcLocation