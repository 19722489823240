import React from 'react'

const IcBusinessEmail = (props) => {
    return (
        <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width={32} height={32} rx={16} fill="#EFF9FF" />
            <path d="M10 10.5H22C22.825 10.5 23.5 11.175 23.5 12V21C23.5 21.825 22.825 22.5 22 22.5H10C9.175 22.5 8.5 21.825 8.5 21V12C8.5 11.175 9.175 10.5 10 10.5Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23.5 12L16 17.25L8.5 12" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBusinessEmail