import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapApp } from './App.styles'

// component
import Login from './components/Login/Default'
import BankLogin from './components/Login/Bank'
import Main from './components/Main'
import PrivateRoute from './components/Control/PrivateRoute'

//Notication
import NotifyNewUser from './components/Notification/NotifyNewUser'

// constant
import { MAIN } from './constant/path'
import { AUTHENTICATED_ERR } from './redux/notifies/types'
import { env, WEE_ENV, BANK_ENV } from './constant/environmentTypes'

// action
import { logoutAction } from './redux/login/action'

const App = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  switch (env) {
    case BANK_ENV:
      document.title ="Facepay for Banks" 
      break;
  
    default:
      document.title ="Facepay BackOffice" 
      break;
  }

  const { notifiesReducer } = useSelector((state) => ({
    notifiesReducer: state.notifiesReducer
  }))

  useEffect(() => {
    if(Object.keys(notifiesReducer).includes(AUTHENTICATED_ERR)){
      dispatch(logoutAction(history, false))
    }
  }, [JSON.stringify(notifiesReducer)]);

  return (
    <WrapApp>
      <Switch>
        <Route exact path="/">
          {
            env === WEE_ENV
            ?  <Login />
            :  <BankLogin />
          }
        </Route>
        <PrivateRoute path={`/${MAIN}`}>
          <Main />
          <NotifyNewUser />
        </PrivateRoute>
        <Redirect to="/" />
      </Switch>
    </WrapApp>
  )
}

export default App
