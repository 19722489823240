import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//styles
import * as styles from "./index.styles";

//component
import TextArea from "../../../../../../Control/TextArea";
import EmptyDataControl from '../../../../../../Control/EmptyDataControl'
import ControlBlockItem from "./ControlBlockItem";
import LoadingLocal from '../../../../../../Control/LoadingLocal'

//image
import IcNoteDefault from '../../../../../../../assets/images/IcNoteDefault'
import IcNoDataNoteBacklog from '../../../../../../../assets/images/IcNoDataNoteBacklog'

// action
import { customerMakeNoteBankCS, customerMakeNoteCS, getListCustomerBankNote, getListCustomerNote, resetListCustomerNote } from "../../../../../../../redux/customer/customerNote/action";
import { LOADING_GET_LIST_CUSTOMER_NOTE } from "../../../../../../../redux/customer/customerNote/types";

// hook
import useScrollEnd from '../../../../../../../hook/useScrollEnd'

//constant
import { CUSTOMER_NOTE } from "../../../../../../../constant/keys";

// helper 
import trimStartAndStripStr from '../../../../../../../helper/trimStartAndStripStr'

const FACEPAY = 'FACEPAY'

export default function ControlBlock() {

  const dispatch = useDispatch()
  const listRef = useRef(null)

  const [valueTextArea, setValueTextArea] = useState("");

  const { listNote, condFiltersFacepay, condFiltersBank, details, isLoading,
    reqType } = useSelector((state) => ({
    details: state.customerReducer.customerDetailReducer.details,
    listNote: state.customerReducer.customerNoteReducer.listNote,
    isShowPopupNote: state.customerReducer.customerNoteReducer.isShowPopupNote,
    reqType: state.customerReducer.listCustomerReducer.reqType,
    isLoading: state.loading[LOADING_GET_LIST_CUSTOMER_NOTE],

    //FACEPAY
    condFiltersFacepay: state.customerReducer.customerNoteReducer.condFiltersFacepay,
    //BANK
    condFiltersBank: state.customerReducer.customerNoteReducer.condFiltersBank

  }))

  useEffect(() => {
    if(!details?.id) return

    if(reqType === FACEPAY){
      dispatch(getListCustomerNote({
        adminId: condFiltersFacepay.adminId,
        adminUserName: condFiltersFacepay.adminUserName,
        uid: details?.id,
        limit: condFiltersFacepay.limit,
        skip: condFiltersFacepay.skip
      },false))
    }else{
      dispatch(getListCustomerBankNote({
        adminId: condFiltersBank.adminId,
        adminUserName: condFiltersBank.adminUserName,
        bioId: details?.id,
        limit: condFiltersBank.limit,
        skip: condFiltersBank.skip
      },false))
    }
    setValueTextArea('')
  },[details?.id, condFiltersFacepay, condFiltersBank])

  useScrollEnd(listRef, (callback) => {
    if(reqType === FACEPAY) {
      dispatch(getListCustomerNote({
        adminId: condFiltersFacepay.adminId,
        adminUserName: condFiltersFacepay.adminUserName,
        uid: details?.id,
        limit: 15,
        skip: listNote.length
      },true, callback))
    }else{
      dispatch(getListCustomerBankNote({
        adminId: condFiltersBank.adminId,
        adminUserName: condFiltersBank.adminUserName,
        bioId: details?.id,
        limit: 15,
        skip: listNote.length
      },true, callback))
    }

  },[listNote],[listNote.length])

  const handleChange = (e) => {

    let value = e.target.value.trimStart()
    let isSpace = false
    for(let i = 0; i < value.length; i++){      
      if(value[i] === " "){
        if(isSpace){ 
          value = value.substring(0, i) + value.substring(i + 1)
          i--
        }
        else isSpace = true
      }
      else isSpace = false
    }
    setValueTextArea(value)
  }

  const callbackSuccess = () => {
    
    const callbackSuccessListNote = () => {
      if(reqType === FACEPAY){
        dispatch(getListCustomerNote({
          adminId: condFiltersFacepay.adminId,
          adminUserName: condFiltersFacepay.adminUserName,
          uid: details?.id,
          limit: 15,
          skip: 0
        },false))
      }else{
        dispatch(getListCustomerBankNote({
          adminId: condFiltersBank.adminId,
          adminUserName: condFiltersBank.adminUserName,
          bioId: details?.id,
          limit: condFiltersBank.limit,
          skip: condFiltersBank.skip
        },false))
      }
    }

    dispatch(resetListCustomerNote(callbackSuccessListNote))
    setValueTextArea("")
  }
  
  const handleSubmit = () => {
    if(!valueTextArea) return
    if(!details) return

    if(reqType === FACEPAY){
      dispatch(customerMakeNoteCS({
        adminId: condFiltersFacepay.adminId,
        adminUserName: condFiltersFacepay.adminUserName,
        uid: details?.id,
        noteType: CUSTOMER_NOTE,
        message: valueTextArea,
      }, callbackSuccess))
    }else{
      dispatch(customerMakeNoteBankCS({
        adminId: condFiltersBank.adminId,
        adminUserName: condFiltersBank.adminUserName,
        bioId: details?.id,
        noteType: CUSTOMER_NOTE,
        message: valueTextArea,
      }, callbackSuccess))
    }
    
  }

  useEffect(() => {
    if(isLoading) setValueTextArea('')
  },[isLoading])

  const tableContent = useMemo(() => (
    listNote?.length === 0 ?
      <div className="wrap-empty">
        <EmptyDataControl
          icon={<IcNoDataNoteBacklog />}
          text='Chưa có lịch sử ghi chú' />
      </div>
      :
      <div className="wrap-list-trans" ref={listRef}>
        <div className="list-trans">
          {
            listNote?.map((item, index) => (
              <ControlBlockItem item={item} key={index} />
            ))
          }
        </div>
      </div>
  ), [JSON.stringify(listNote)])

  return (
    <styles.Bound>
      <div className="wrap-reasion">
        <TextArea
          placeholder="Nhập nội dung ghi chú tối đa 120 ký tự"
          value={valueTextArea}
          onChange={handleChange}
          maxLength={120}
        />
        <div className={`note-btn ${!details ? "disable" : ""}`} onClick={handleSubmit}>
          <IcNoteDefault />
        </div>
      </div>
      <div className="wrap-content">
        {isLoading && listNote.length === 0 ? (
          <LoadingLocal />
        ) : tableContent}
      </div>
    </styles.Bound>
  );
}
