// data lỗi Facepay

export const dataErrorFacepay = {
    ["06"]:{text: "Tài khoản đang tạm khóa"},
    ["08"]:{text: "Giao dịch không thành công do lỗi yêu cầu không hợp lệ"},
    ["10"]:{text: "Khuôn mặt chưa được đăng ký"},
    ["11"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["2701010"]:{text: "Giao dịch không thành công do lỗi yêu cầu không hợp lệ"},
    ["21"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["40"]:{text: "Giao dịch không thành công do yêu cầu không hợp lệ"},
    ["43"]:{text: "Giao dịch không thành công do yêu cầu không hợp lệ"},
    ["46"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["53"]:{text: "Giao dịch không thành công do lỗi dịch vụ Facepay không khả dụng"},
    ["315001"]:{text: "Tài khoản chưa được Payme định danh."},
    ["315002"]:{text: "Tài khoản Facepay chưa liên kết thẻ"},
    ["315003"]:{text: "Số dư ví không khả dụng"},
    ["315004"]:{text: "Tài khoản đang tạm khóa"},
    ["315005"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["315006"]:{text: "Giao dịch không thành công do lỗi không xác thực OTP"},
    ["315007"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["315008"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["1104"]:{text: "Tài khoản Facepay chưa được xác thực"},
    ["1110"]:{text: "Tài khoản Facepay chưa được định danh"},
    ["1111"]:{text: "Tài khoản Facepay chưa liên kết thẻ"},
    ["1118"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["1116"]:{text: "Số tiền giao dịch chưa đạt mức tối thiểu"},
    ["1117"]:{text: "Vượt hạn mức/ giao dịch Facepay là 2,000,000đ."},
    ["1133"]:{text: "Tài khoản Facepay đang ngừng hoạt động"},
    ["601"]:{text: "Tài khoản tạm thời bị khóa do user yêu cầu"},
    ["600"]:{text: "Tài khoản tạm thời bị khóa do nghi ngờ các hành vi phát sinh trong hệ thống"},

    ["default"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["-99"]: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ["-100"]: {text: "Giao dịch không thành công do chưa hoàn tất."},
    ["-101"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-102"]: {text: "Khách hàng chưa nhập đúng số điện thoại"},
    ["-103"]: {text: "Khách hàng cancel giao dịch do không được áp dụng khuyến mãi"},

}