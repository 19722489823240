import dayjs from 'dayjs'
import * as types from './types'

import { dataSelectCheckboxBusiness } from '../../../data/dataSelectCheckbox'
import { dataSelectionBusiness } from '../../../components/Main/Content/Page/BusinessManage/FilterBusiness/DropdownFilterBusiness'

export const startDay = dayjs('1/1/2022').startOf('day').toDate()
export const currentDay = dayjs(new Date()).endOf('day').toDate()

const initialState = {
    isShowPopupUpdateRequest: false,
    listBussiness: [],
    listBacklogBusiness: [],
    listNoteBusiness: [],
    businessDetail: {
        businessInfo: {
            id: "",
            censorshipStatus: "",
            censorshipState: "",
            censorshipReason: [],
            businessCode: "",
            phoneNumber: "",
            businessName: "",
            businessTypeName: "",
            ownerName: "",
            email: "",
            referralCode: "",
            expiredTime: "",
            registeredAt: "",
            licenseContentType: "",
            licenseCode: "",
            licenseFile: [],
            storeNumber: "",
            businessLogo: "",
            address: ""
        },
        userInfo: {
            profileID: "",
            isLock: false,
            userName: "",
            userFullName: "",
            email: "",
            phoneNumber: "",
            registeredAt: "",
            thumbnailUrl: "",
            ekycInfo: {
                cardID: "",
                issueDate: "",
                issueAt: "",
                address: "",
                ekycUrl: []
            }
        }
    },

    condFiltersBusiness: {
        businessCode: "",
        phoneNumber: "",
        ownerName: "",
        businessName: "",
        begin: startDay,
        end: currentDay,
        limit: 15,
        lastItem: "",
        optionSelect: dataSelectionBusiness[0],
        optionSelectCheckboxBusiness: dataSelectCheckboxBusiness
    },

    condFiltersExportBusiness: {
        begin: startDay,
        end: currentDay
    },

    total: 0
}

const listBusinessManage = (state = initialState, action) => {
    switch (action.type) {
        case types.SHOW_POPUP_UPDATE_REQUEST:
            return {
                ...state,
                isShowPopupUpdateRequest: action.isShowPopupUpdateRequest
            }

        // GET LIST
        case types.GET_LIST_BUSINESS:
            return {
                ...state,
                listBussiness: action.payload.isScroll ? [...state.listBussiness, ...action.payload.listBussiness] : action.payload.listBussiness,
                total: action.payload.total
            }

        case types.UPDATE_CONDFILTER_BUSINESS:
            return {
                ...state,
                condFiltersBusiness: {
                    ...state.condFiltersBusiness,
                    ...action.condFiltersBusiness
                }
            }

        case types.RESET_CONDFILTER_BUSINESS:
            return {
                ...state,
                condFiltersBusiness: initialState.condFiltersBusiness
            }
        case types.RESET_LIST_BUSINESS:
            return {
                ...state,
                listBussiness: initialState.listBussiness
            }

        // BACKLOG
        case types.GET_LIST_BACKLOG_BUSINESS:
            return {
                ...state,
                listBacklogBusiness: action.payload.isScroll ? [...state.listBacklogBusiness, ...action.payload.listBacklogBusiness] : action.payload.listBacklogBusiness
            }
        case types.RESET_LIST_BACKLOG_BUSINESS:
            return {
                ...state,
                listBacklogBusiness: initialState.listBacklogBusiness
            }
        // GET LIST NOTE
        case types.GET_LIST_NOTE_BUSINESS:
            return {
                ...state,
                listNoteBusiness: action.payload.isScroll ? [...action.payload.listNoteBusiness, ...state.listNoteBusiness] : action.payload.listNoteBusiness
            }
        case types.RESET_LIST_NOTE_BUSINESS:
            return {
                ...state,
                listNoteBusiness: initialState.listNoteBusiness
            }

        // DETAIL
        case types.GET_DETAIL_BUSINESS:
            return {
                ...state,
                businessDetail: action.payload.businessDetail
            }
        case types.RESET_DETAIL_BUSINESS:
            return {
                ...state,
                businessDetail: initialState.businessDetail
            }

        // EXPORT
        case types.EXPORT_FILE_BUSINESS:
            return {
                ...state
            }

        default:
            return state
    }
}

export default listBusinessManage