import styled from 'styled-components'

const WrapFilterBackLog = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 0 0;

    .icon{
        cursor: pointer;
    }
`

const WrapDropDown = styled.div`
    width: 292px;
    height: 38px;
    border: 0.5px solid #B7B7B7;
    border-radius: 33px;
    padding: 10px 12px;
    box-sizing: border-box;

`
const WrapDateRange = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 33px;
    border: 0.5px solid #B7B7B7;
    box-sizing: border-box;
    padding: 9px 14px;
    width: 249px;
    height: 38px;
    margin-left: 5px;

    
`


export { WrapFilterBackLog, WrapDropDown, WrapDateRange }