import React, { useRef } from 'react'
import { useSelector } from 'react-redux'

//styled
import { OverlayFullScreen } from '../../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

// data
import { CCCD, CMND12ID, CMND9ID, dataPassportStatus, PASSPORT } from '../../../../../../../../../../data/dataCustomer'

// component
import PopupEditInfoCCCD from './PopupEditInfoCCCD'
import PopupEditInfoCMND from './PopupEditInfoCMND'
import PopupEditInfoPASSPORT from './PopupEditInfoPASSPORT'

const PopupEditInfo = ({ item , onClickClosePopup }) => {

    const { details } = useSelector((state) => ({
        details: state.customerReducer.customerDetailReducer.details,
      }))

    const handleShowPopupEdit = () => {
        switch (dataPassportStatus[item.ekycType].key) {
            case CMND9ID:
                return (
                    <PopupEditInfoCMND item={item} details={details} onClickClosePopup={onClickClosePopup}/>
                )
            case CMND12ID: 
                return (
                    <PopupEditInfoCCCD item={item} details={details} onClickClosePopup={onClickClosePopup}/>
                )
            case CCCD:
                return (
                    <PopupEditInfoCCCD item={item} details={details} onClickClosePopup={onClickClosePopup}/>
                )
            case PASSPORT:
                return (
                    <PopupEditInfoPASSPORT item={item} details={details} onClickClosePopup={onClickClosePopup}/>
                )
        }
    }

    return (
        <OverlayFullScreen>
            {handleShowPopupEdit()}
        </OverlayFullScreen>
    )
}

export default PopupEditInfo
