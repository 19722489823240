import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";

//style
import { WrapItem } from './index.styles'

//image
import IcFacePrintDefault from '../../../../../../../assets/images/IcFacePrintDefault'
import IcRefuseYellow from '../../../../../../../assets/images/IcRefuseYellow';
import IcRefuseRed from '../../../../../../../assets/images/IcRefuseRed';
import IcArrowRight from '../../../../../../../assets/images/IcArrowRight'

import { convertDateTime } from '../../../../../../../helper/convertDateTime'

//path
import * as path from '../../../../../../../constant/path'
import { dataCensorshipStatus } from '../../../../../../../data/dataCensorshipStatus';


const ItemRejectionProfile = ({ data }) => {

    const { verifiedBy, id, documentId, fullName, phone, thumbnailUrl, email, sameFace, verifiedAt } = data

    const history = useHistory()

    const [isErrImg, setIsErrImg] = useState(false)

    useEffect(() => {
        setIsErrImg(false)
    }, [id])

    const _handleClick = () => {
        history.push(`/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}/${id}`)
    }
    return (
        <WrapItem onClick={_handleClick}>
            <div className="wrap-status">
                {
                    data?.rejectCount > 1 ?
                        <IcRefuseRed className="icon" />
                        :
                        <IcRefuseYellow className="icon" />
                }
                <p className="document-id" title={documentId || ""}>{documentId || "-"}</p>
            </div>
            <div className="face-print">
                {
                    isErrImg ?
                        <IcFacePrintDefault className="face" />
                        :
                        <img
                            className="face"
                            src={thumbnailUrl}
                            alt="facePrint"
                            onError={() => setIsErrImg(true)}
                        />
                }
            </div>
            <div title={fullName || ""}>{fullName || "-"}</div>
            <div>{phone || "-"}</div>
            <div title={email || ""}>{email || "-"}</div>
            <div title={verifiedBy || ""}>{verifiedBy || "-"}</div>
            <div className="wrap-time">
                {verifiedAt ? convertDateTime(verifiedAt, "HH:mm - DD/MM/YYYY") : "-"}
                <IcArrowRight className="icon-arrow" />
            </div>
        </WrapItem>
    )
}

export default ItemRejectionProfile
