import styled from 'styled-components'

const WrapDeviceAdminByList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    .header-list{
        margin-top: 0;
    }
    .row {
        grid-template-columns: 50px minmax(90px, 120px) minmax(120px, 150px) 70px minmax(130px, 200px) minmax(270px, 1fr) 120px 130px 25px;
    }
    .wrap-empty {
        height: 100%;
        display: flex;
        align-items: center;
    }
`;

export {
    WrapDeviceAdminByList
}