import styled from "styled-components";

const WrapBusinessManage = styled.div`
    height: 100%;
    .tab-menu{
        padding: 0 30px;
        background: #fff;
    }
`

export {
    WrapBusinessManage
}