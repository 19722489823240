import React, { useEffect } from 'react'
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';

// styles
import { WrapTabListAndToolCustomer } from './index.styles'
import { FACEPAY, BANK} from '../../../../../../../constant/keys';
import { reqTypeCustomer } from '../../../../../../../redux/customer/listCustomer/actionV2';


const TabListAndToolCustomer = ({ listTabs }) => {

    const dispatch = useDispatch()
    const location = useLocation();
    
    useEffect(() => {
        for(let i = 0; i < listTabs.length; i++){
            const item = listTabs[i]
            if(item.component.path === location.pathname){
                dispatch(reqTypeCustomer({
                    reqType: item.key,
                }))
                return
            }
        }
        return () => {}
    },[listTabs])

    const handleSelect = (item) => {
        if(item.key === FACEPAY) {
            dispatch(reqTypeCustomer({
                reqType: FACEPAY,
            }))
            return
        }
        if(item.key === BANK){
            dispatch(reqTypeCustomer({
                reqType: BANK,
            }))
            return
        }
    }

    return (
        <WrapTabListAndToolCustomer>
            <div className="wrap-tab">
                {
                    listTabs.map((item, index) => {
                        return (
                            <NavLink
                                to={item.component.path}
                                key={index}
                                className="tab"
                                activeClassName="active"
                                onClick={() => handleSelect(item)}
                            >
                                <p>{item.title}</p>
                            </NavLink>
                        )
                    })
                }
            </div>
        </WrapTabListAndToolCustomer>
    )
}

export default TabListAndToolCustomer
