import React from 'react'

const IcCloseOutlineRed = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 12.8333C10.2217 12.8333 12.8333 10.2217 12.8333 6.99999C12.8333 3.77833 10.2217 1.16666 7 1.16666C3.77834 1.16666 1.16666 3.77833 1.16666 6.99999C1.16666 10.2217 3.77834 12.8333 7 12.8333Z" stroke="#F45C5C" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.75 5.25L5.25 8.75" stroke="#F45C5C" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.25 5.25L8.75 8.75" stroke="#F45C5C" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCloseOutlineRed
