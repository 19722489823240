import React from 'react'

const IcNoDataEkyc = (props) => {
    return (
        <svg {...props} width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="70" cy="70" r="70" fill="#B7B7B7"/>
        <path d="M100.001 43.332H40.0007C36.3188 43.332 33.334 46.3168 33.334 49.9987V89.9987C33.334 93.6806 36.3188 96.6654 40.0007 96.6654H100.001C103.683 96.6654 106.667 93.6806 106.667 89.9987V49.9987C106.667 46.3168 103.683 43.332 100.001 43.332Z" fill="#B7B7B7"/>
        <path d="M60.7366 53.332H45.9314C44.4969 53.332 43.334 54.4513 43.334 55.832V70.832C43.334 72.2127 44.4969 73.332 45.9314 73.332H60.7366C62.1711 73.332 63.334 72.2127 63.334 70.832V55.832C63.334 54.4513 62.1711 53.332 60.7366 53.332Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M96.6673 83.332H73.334" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M96.6673 70H73.334" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M96.6673 56.6641H73.334" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcNoDataEkyc
