import * as types from './types'

export const enableLoading = () => ({
    type: types.ENABLE_LOADING
})
export const disableLoading = () => ({
    type: types.DISABLE_LOADING
})


export const showLoading = (actionTypeLoading) => dispatch => {
    dispatch({
        type: types.SHOW_LOADING,
        payload: {
            actionTypeLoading
        }
    })
}

export const hideLoading = (actionTypeLoading) => dispatch => {
    dispatch({
        type: types.HIDE_LOADING,
        payload: {
            actionTypeLoading
        }
    })
}
