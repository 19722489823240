import React, { useState, useEffect } from 'react'

//style
import { WrapItemNote } from './index.styles'

//image
import IcExportFile from '../../../../../../../../assets/images/IcExportFile'
import IcInputUserActive from '../../../../../../../../assets/images/IcInputUserActive'
import { convertDateTime } from '../../../../../../../../helper/convertDateTime'
import downloadFileByLink from '../../../../../../../../helper/downloadFileByLink'
import IcFileWord from '../../../../../../../../assets/images/IcFileWord'
import IcFilePdf from '../../../../../../../../assets/images/IcFilePdf'
import IcFileImage from '../../../../../../../../assets/images/IcFileImage'

const IMG = "IMG"
const WORD = "WORD"
const PDF = "PDF"

const validFormatImg = ["png", "jpg", "jpeg"]
// const validFormatWord = ["msword", "vnd.openxmlformats-officedocument.wordprocessingml.document"]
const validFormatWord = ["doc", "docx"]
const validFormatPdf = ["pdf"]

const ItemNote = ({ data, scrollRef }) => {

    const { text, attachment, createdAt, adminUserName } = data
    const [checkFileType, setCheckFileType] = useState("")

    useEffect(() => {
        if (attachment === null) return
        if (attachment) {
            if (validFormatImg.includes(attachment?.fileName.split('.').pop())) {
                setCheckFileType(IMG)
                return
            }
            if (validFormatWord.includes(attachment?.fileName.split('.').pop())) {
                setCheckFileType(WORD)
                return
            }
            if (validFormatPdf.includes(attachment?.fileName.split('.').pop())) {
                setCheckFileType(PDF)
                return
            }
        }
    }, [attachment])

    const _handleExportFile = () => {
        if (attachment?.fileUrl) {
            downloadFileByLink(attachment?.fileUrl, false)
        }
    }

    return (
        <WrapItemNote ref={scrollRef}>
            <div className='block-content-note'>
                {
                    text ?
                        <p className='text'>{text}</p>
                        :
                        <div className='wrap-content'>
                            {
                                checkFileType === IMG
                                    ? < IcFileImage className='image' />
                                    : checkFileType === WORD
                                        ? <IcFileWord className='image' />
                                        : checkFileType === PDF
                                            ? <IcFilePdf className='image' />
                                            : null
                            }
                            <p className='content' title={attachment?.fileName}>{attachment?.fileName}</p>
                            <IcExportFile className='ic-export' onClick={_handleExportFile} />
                        </div>
                }
            </div>
            <div className='block-item-note'>
                <p className='time'>
                    {convertDateTime(createdAt, "HH:mm - DD/MM/YYYY")}
                </p>
                <p className='user'>
                    <IcInputUserActive className='icon' />
                    {adminUserName}
                </p>
            </div>
        </WrapItemNote>
    )
}

export default ItemNote