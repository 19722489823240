import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import IcCalendarGray from '../../../../../../../../../../../../assets/images/IcCalendarGray';
// styles
import { WrapDatePickerItem } from './index.styles';

const DatePickerItemEdit = ({ selected, ...props }) => {
    const datepickerRef = useRef(null)
    // const [errorBorder, setErrorBorder] = useState(false)
    // OPENS UP THE DATEPICKER WHEN THE CALENDAR ICON IS CLICKED FOR THE INPUT FIELD
    const handleClickDatepickerIcon = () => {
        const datepickerElement = datepickerRef.current;
        // console.log("datepickerElement = ", datepickerElement);
        datepickerElement.setFocus(true);
    }

    return (
        <WrapDatePickerItem >
            <DatePicker
                selected={selected}
                dateFormat="HH:mm - dd/MM/yy"
                showTimeSelect
                showPopperArrow={false}
                timeFormat="HH:mm"
                timeIntervals={60}
                popperPlacement="bottom"
                ref={datepickerRef}
                {...props}
            />
            <IcCalendarGray onClick={() => handleClickDatepickerIcon()} className='date-icon'/>
        </WrapDatePickerItem>
    )
}

export default DatePickerItemEdit
