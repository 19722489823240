import React from 'react'

const IcOCBMini = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="white" />
            <path d="M1.70103 10.8697C1.93372 12.8088 2.88237 14.5474 4.29639 15.8346C4.1353 15.4334 4.0816 15.0155 4.189 14.5976C4.45749 13.4441 5.72832 12.625 7.50033 12.3909V6.79068C2.90027 6.05513 1.41464 8.78002 1.70103 10.8697Z" fill="#E39717" />
            <path d="M18.2993 9.13537C18.0666 7.19524 17.118 5.45581 15.7039 4.16797C15.865 4.56937 15.9187 4.98751 15.8113 5.40564C15.5428 6.55968 14.272 7.37922 12.5 7.61337V13.2163C17.1001 13.9355 18.5857 11.226 18.2993 9.13537Z" fill="#E39717" />
            <path d="M1.66699 8.33464C2.83821 6.74652 4.93592 5.82158 8.81669 6.90359C15.6168 8.82329 17.8019 4.37308 13.9211 2.57554C12.7499 1.98218 11.4388 1.66805 10.0578 1.66805C5.94982 1.6506 2.54104 4.51269 1.66699 8.33464Z" fill="#E39717" />
            <path d="M18.3337 11.668C17.1624 13.2561 15.0647 14.181 11.184 13.099C4.38389 11.1793 2.19877 15.6295 6.07953 17.4271C7.23327 18.0204 8.56183 18.3346 9.94282 18.3346C14.0334 18.352 17.4596 15.4899 18.3337 11.668Z" fill="#E39717" />
        </svg>
    )
}

export default IcOCBMini
