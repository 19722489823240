import styled from 'styled-components'

const WrapDatePickerItem = styled.div`
    width: 172px;
    height: 40px;
    position: relative;
    .date-icon{
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .react-datepicker-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .react-datepicker__input-container{
            display: flex;
            height: 100%;
        }
        input{
            border: none;
            outline: none;
            width: 100%;
            height: calc(100% - 2px);
            font-family: "Open Sans";
            color: #313131;
            letter-spacing: -0.01em;
            font-size: 13px;
            line-height: 18px;
            padding: 0 10px 0 10px;
            border-radius: 4px;
            border: 0.5px solid #B7B7B7;
            
            &:focus{
                outline: none;
                border: 0.5px solid #33adff;
            }
        }
    }
`

export {
    WrapDatePickerItem
}