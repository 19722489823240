import styled from "styled-components";

const WrapWarningApproved = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    width: calc(280px - 40px);
    background: #fff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    .warning-title{
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #F45C5C;
    }
    .warning-content{
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #313131;
        margin: 10px 0 30px 0;
    }
    .btn-warning{
        background: #F45C5C;
        border-radius: 4px;
    }
`

export {
    WrapWarningApproved
}