import React from 'react'

const IcEthBlue = (props) => {
  return (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.94733 1.14288C1.95503 1.14288 1.14282 1.95509 1.14282 2.94739V13.0527C1.14282 14.045 1.95503 14.8572 2.94733 14.8572H13.0526C14.0449 14.8572 14.8571 14.045 14.8571 13.0527V2.94739C14.8571 1.95509 14.0449 1.14288 13.0526 1.14288H2.94733ZM3.30824 4.0301H12.6917V9.80454H11.2481V10.8872H9.80448V11.9699H6.19545V10.8872H4.75184V9.80454H3.30824V4.0301ZM4.39094 5.11281V7.63912H5.11275V5.11281H4.39094ZM5.47365 5.11281V7.63912H6.19545V5.11281H5.47365ZM6.55636 5.11281V7.63912H7.27816V5.11281H6.55636ZM7.63906 5.11281V7.63912H8.36087V5.11281H7.63906ZM8.72177 5.11281V7.63912H9.44357V5.11281H8.72177ZM9.80448 5.11281V7.63912H10.5263V5.11281H9.80448ZM10.8872 5.11281V7.63912H11.609V5.11281H10.8872Z" fill="#33ADFF"/>
  </svg>

  )
}

export default IcEthBlue