export const DAY_CHECK = 1

const checkTimeWarning = (timeCurrent, time) => {
    let timeUpdate = Date.parse(time)
    let ms = timeCurrent - timeUpdate
    const days = Math.floor((ms / 1000 / 60 / 60) / 24);
    const hours = Math.floor((ms / 1000 / 60 / 60) % 24);
    const minutes = Math.floor((((ms / 1000 / 60) % 60) % 60) % 60);
    let humanized = days >= DAY_CHECK ? days + " ngày" : pad(hours.toString() , 2) + ":" + pad(minutes.toString(), 2)
    let isExpired = days >= DAY_CHECK ? true : false
    return {value: humanized, isExpired: isExpired}
}

const pad = (numberStr, number) => {
    let padded = numberStr;
    while (padded.length < number) padded = `0${padded}`;
    return padded;
}

export default checkTimeWarning


