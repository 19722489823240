import React from 'react'

const IcConfirmGray = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99996 12.8333C10.2216 12.8333 12.8333 10.2217 12.8333 7C12.8333 3.77834 10.2216 1.16667 6.99996 1.16667C3.7783 1.16667 1.16663 3.77834 1.16663 7C1.16663 10.2217 3.7783 12.8333 6.99996 12.8333Z" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.625 5.25L6.01562 8.75L4.375 7.15909" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcConfirmGray