import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 14px 20px 0 20px;
    overflow: overlay;
    
`

export {
    Bound
}