import React, { useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

// images
import IcArrowDownGray from "../../../../../../../../assets/images/IcArrowDownGray";

// key
import {
  NINETY_DAY,
  OTHER,
  SEVEN_DAY,
  TODAY,
  TWENTY_EIGHT_DAY,
  YESTERDAY,
} from "../../../../../../../../constant/keys";
import { currentDay } from "../../../../../../../../redux/transactionV2/listTransactionBank/listTransactionBankReducer";
import { BANK, FACEPAYTERMINAL, WALLET_FACEPAY } from "../../..";

// control
import DatePickerItem from "../../../../../../../Control/DatePickerItem";
import { dataSelectionTime } from "../DropdownFilterTime";

// helper
import getDate from "../../../../../../../../helper/getDate";

// redux
import { updateCondFiltersTransactionFacepay } from "../../../../../../../../redux/transactionV2/listTransactionFacepayTerminal/action";
import { updateListTransactionBank } from "../../../../../../../../redux/transactionV2/listTransactionBank/action";
import { updateCondfiterTransactionFacepayWallet } from "../../../../../../../../redux/transactionV2/listTransactionWalletFacepay/action";

const DatePickerTransaction = ({
  filterCondTransactionTime,
  data,
  setData,
  setFilterCondTransactionTime,
  condFiltersTransaction,
  reqType,
}) => {
  const dispatch = useDispatch();
  const { key } = filterCondTransactionTime;
  const handleChangeStartDate = (date) => {
    setData({
      begin: date ? dayjs(date).startOf("day").toDate() : undefined,
    });
    setFilterCondTransactionTime(dataSelectionTime[0]);
  };

  const handleChangeEndDate = (date) => {
    setData({
      end: date ? dayjs(date).endOf("day").toDate() : undefined,
    });
    setFilterCondTransactionTime(dataSelectionTime[0]);
  };

  useEffect(() => {
    switch (key) {
      case OTHER : 
        return (
          setData({
              ...condFiltersTransaction,
              begin: data.begin,
              end: data.end
          })
        ) 
      case TODAY:
        return (
            setData({
                ...condFiltersTransaction,
                begin: getDate(0).startDay,
                end: currentDay
            })
        )
      case YESTERDAY:
        return (
            setData({
                ...condFiltersTransaction,
                begin: getDate(1).startDay,
                end: getDate(1).endDay
            })
        )
      case SEVEN_DAY:
        return (
            setData({
                ...condFiltersTransaction,
                begin: getDate(7).startDay,
                end: currentDay
            })
        )
      case TWENTY_EIGHT_DAY:
        return (
            setData({
                ...condFiltersTransaction,
                begin: getDate(28).startDay,
                end: currentDay
            })
        )
      case NINETY_DAY:
        return (
            setData({
                ...condFiltersTransaction,
                begin: getDate(90).startDay,
                end: currentDay
            })
        )
      default:
        return null
    }
  }, [filterCondTransactionTime]);

  useEffect(() => {
    const dataRequest = {
      begin:
        key === TODAY
          ? getDate(0).startDay
          : key === YESTERDAY
          ? getDate(1).startDay
          : key === SEVEN_DAY
          ? getDate(7).startDay
          : key === TWENTY_EIGHT_DAY
          ? getDate(28).startDay
          : key === NINETY_DAY
          ? getDate(90).startDay
          : data.begin,
      end:
        key === TODAY
          ? currentDay
          : key === YESTERDAY
          ? getDate(1).endDay
          : key === SEVEN_DAY
          ? currentDay
          : key === TWENTY_EIGHT_DAY
          ? currentDay
          : key === NINETY_DAY
          ? currentDay
          : data.end,
    };
    switch (reqType) {
      case FACEPAYTERMINAL:
        return dispatch(
          updateCondFiltersTransactionFacepay({
            ...condFiltersTransaction,
            begin: dataRequest.begin,
            end: dataRequest.end,
          })
        );
      case BANK:
        return dispatch(
          updateListTransactionBank({
            ...condFiltersTransaction,
            begin: dataRequest.begin,
            end: dataRequest.end,
          })
        );
      case WALLET_FACEPAY:
        return dispatch(
          updateCondfiterTransactionFacepayWallet({
            ...condFiltersTransaction,
            begin: dataRequest.begin,
            end: dataRequest.end,
          })
        );
      default:
        return null;
    }
  }, [data]);

  return (
    <>
      <DatePickerItem
        className="input-dropdown"
        selected={
          key === TODAY
            ? dayjs(new Date()).startOf("day").toDate()
            : key === YESTERDAY
            ? getDate(1).startDay
            : key === SEVEN_DAY
            ? getDate(7).startDay
            : key === TWENTY_EIGHT_DAY
            ? getDate(28).startDay
            : key === NINETY_DAY
            ? getDate(90).startDay
            : data.begin
        }
        onChange={handleChangeStartDate}
        maxDate={data.end}
        dateFormat="dd/MM/yyyy"
        showTimeSelect={false}
      />
      <IcArrowDownGray className="arrow-right" />
      <DatePickerItem
        className="input-dropdown"
        selected={
          key === TODAY
            ? currentDay
            : key === YESTERDAY
            ? getDate(1).endDay
            : key === SEVEN_DAY
            ? currentDay
            : key === TWENTY_EIGHT_DAY
            ? currentDay
            : key === NINETY_DAY
            ? currentDay
            : data.end
        }
        onChange={handleChangeEndDate}
        minDate={data.begin}
        maxDate={getDate(0).endDay}
        dateFormat="dd/MM/yyyy"
        showTimeSelect={false}
      />
    </>
  );
};

export default DatePickerTransaction;
