import styled from "styled-components"

const WrapBusinessWaitingUpdate = styled.div`
    height: calc(100% - 82px);


`

export {
    WrapBusinessWaitingUpdate
}