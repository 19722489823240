import React from 'react'

const IcBusinessStore = (props) => {
    return (
        <svg width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width={32} height={32} rx={16} fill="#EFF9FF" />
            <g clipPath="url(#clip0_9913_5132)">
                <path d="M9.25 15.75V19.1862C9.25 24 9.25 24 12.6231 24H18.0246C22.75 24 22.75 24 22.75 20.5638V15.75" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.0001 16.5C17.3726 16.5 18.3851 15.3825 18.2501 14.01L17.7551 9H14.2526L13.7501 14.01C13.6151 15.3825 14.6276 16.5 16.0001 16.5Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.7326 16.5C22.2476 16.5 23.3576 15.27 23.2076 13.7625L22.9976 11.7C22.7276 9.75 21.9776 9 20.0126 9H17.7251L18.2501 14.2575C18.3776 15.495 19.4951 16.5 20.7326 16.5Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.23 16.5C12.4675 16.5 13.585 15.495 13.705 14.2575L13.87 12.6L14.23 9H11.9425C9.97753 9 9.22753 9.75 8.95753 11.7L8.75503 13.7625C8.60503 15.27 9.71503 16.5 11.23 16.5Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 20.25C14.7475 20.25 14.125 20.8725 14.125 22.125V24H17.875V22.125C17.875 20.8725 17.2525 20.25 16 20.25Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_9913_5132">
                    <rect width={18} height={18} fill="white" transform="translate(7 7.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcBusinessStore