import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//style
import { WrapFacepayCustomerList, TableContent } from './index.styles'

//image
import IcCensorshipDefault from '../../../../../../../assets/images/IcCensorshipDefault'

//component
import EmptyDataControl from '../../../../../../Control/EmptyDataControl'
import CustomerItem from '../CustomerItem'
import FilterFacepayBlock from './FilterFacepayBlock'
import useScrollEnd from '../../../../../../../hook/useScrollEnd'
import LoadingLocal from '../../../../../../Control/LoadingLocal'

//action
import { getListCustomerFacepay, resetBankCustomerCondsFilter, resetListCustomerBank, updateFacepayCustomerCondsFilter } from '../../../../../../../redux/customer/listCustomer/actionV2'
import { clearDetailCustomer, getDetailCustomer, resetListDeviceHistoryCustomer, resetListEkycHistoryCustomer, resetListVerifyHistoryCustomer } from '../../../../../../../redux/customer/customerDetail/action'
import { LOADING_GET_LIST_CUSTOMER } from '../../../../../../../redux/customer/listCustomer/types'
import { LOADING_GET_LIST_EKYC_CUSTOMER, LOADING_GET_LIST_DEVICE_CUSTOMER, LOADING_GET_LIST_VERIFY_CUSTOMER } from '../../../../../../../redux/customer/customerDetail/types'
import { resetListTransactionBankOfCustomer, resetListTransactionFacepayOfCustomer, resetTransactionOfCustomerCondsFilter } from '../../../../../../../redux/customer/customerTransactionHistory/actionV2'
import { resetListCustomerFacepayBacklog, resetListCustomerBankBacklog, resetUpdateListBacklogCustomerCondsFilter } from '../../../../../../../redux/customer/customerBacklog/action'
import { resetListCustomerBankNote, resetListCustomerNote } from '../../../../../../../redux/customer/customerNote/action'
import { resetCustomerAnalytic, resetCustomerBankAnalytic } from '../../../../../../../redux/customer/customerAnalytic/action'
import { LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER } from '../../../../../../../redux/customer/customerTransactionHistory/types'
import { LOADING_GET_LIST_BACKLOG_CUSTOMER } from '../../../../../../../redux/customer/customerBacklog/types'
import { listProviders } from '../../../../../../../redux/customer/listCustomer/listCustomerReducerV2'
import resetListFilterBank from '../../../../../../../helper/resetListFilterBank'


const FacepayCustomerList = () => {

    const dispatch = useDispatch()
    const listRef = useRef()

    const [keySelected, setKeySelected] = useState("")

    const { listCustomerFacepay, isCheckList, condFiltersFacepay, isScroll, isTypeLoading, isTypeLoadingVerify, isTypeLoadingDevice, isTypeLoadingEkyc, isTypeLoadingTransaction, isTypeLoadingBacklog  } = useSelector(state => ({
        listCustomerFacepay: state.customerReducer.listCustomerReducer.listCustomerFacepay,
        condFiltersFacepay: state.customerReducer.listCustomerReducer.condFiltersFacepay,

        isScroll: state.customerReducer.listCustomerReducer.isScroll,
        isCheckList: state.customerReducer.listCustomerReducer.isCheckList,

        isTypeLoading: state.loading[LOADING_GET_LIST_CUSTOMER],
        isTypeLoadingVerify: state.loading[LOADING_GET_LIST_VERIFY_CUSTOMER],
        isTypeLoadingDevice: state.loading[LOADING_GET_LIST_DEVICE_CUSTOMER],
        isTypeLoadingEkyc: state.loading[LOADING_GET_LIST_EKYC_CUSTOMER],
        isTypeLoadingTransaction: state.loading[LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER],
        isTypeLoadingBacklog: state.loading[LOADING_GET_LIST_BACKLOG_CUSTOMER],

    }))

    const userSelected = listCustomerFacepay.filter(item => item.id === keySelected)

    useEffect(() => {
        if (!keySelected || userSelected.length === 0) {
            setKeySelected(listCustomerFacepay[0]?.id)
            dispatch(getDetailCustomer(listCustomerFacepay[0]))
        } else {
            setKeySelected(keySelected)
            dispatch(getDetailCustomer(userSelected[0]))
        }
    }, [listCustomerFacepay])

    useEffect(() => {
        dispatch(resetBankCustomerCondsFilter())
        dispatch(resetListCustomerBank())
        dispatch(resetListVerifyHistoryCustomer())
        dispatch(resetListEkycHistoryCustomer())
        dispatch(resetListDeviceHistoryCustomer())
        dispatch(resetListTransactionBankOfCustomer())
        dispatch(resetListTransactionFacepayOfCustomer())
        dispatch(resetTransactionOfCustomerCondsFilter())
        dispatch(resetListCustomerFacepayBacklog())
        dispatch(resetListCustomerBankBacklog())
        dispatch(resetUpdateListBacklogCustomerCondsFilter())
        dispatch(resetListCustomerBankNote())
        dispatch(resetListCustomerNote())
        dispatch(resetCustomerBankAnalytic())
        resetListFilterBank(listProviders)
    }, [])

    useEffect(() => {
        dispatch(resetListVerifyHistoryCustomer())
        dispatch(resetListEkycHistoryCustomer())
        dispatch(resetListDeviceHistoryCustomer())
        dispatch(resetListTransactionBankOfCustomer())
        dispatch(resetListTransactionFacepayOfCustomer())
        dispatch(resetTransactionOfCustomerCondsFilter())
        dispatch(resetListCustomerFacepayBacklog())
        dispatch(resetListCustomerBankBacklog())
        dispatch(resetUpdateListBacklogCustomerCondsFilter())
        dispatch(resetListCustomerBankNote())
        dispatch(resetListCustomerNote())
        dispatch(resetCustomerAnalytic())
    },[keySelected])

    useEffect(() => {
        dispatch(getListCustomerFacepay({
            lastItem: condFiltersFacepay.lastItem,
            limit: condFiltersFacepay.limit,
            phone: condFiltersFacepay.phone,
            profileId: condFiltersFacepay.profileId,
            fullname: condFiltersFacepay.fullname,
        }, isScroll))
    }, [JSON.stringify(condFiltersFacepay)])

    useEffect(() => {
        if(listCustomerFacepay.length === 0 && !isCheckList){
            dispatch(getListCustomerFacepay({
                lastItem: condFiltersFacepay.lastItem,
                limit: condFiltersFacepay.limit,
                phone: condFiltersFacepay.phone,
                profileId: condFiltersFacepay.profileId,
                fullname: condFiltersFacepay.fullname,
            }, isScroll))
            setKeySelected(listCustomerFacepay[0]?.id)
        }
    },[listCustomerFacepay])

    useScrollEnd(listRef, (callback) => {
        dispatch(getListCustomerFacepay({
            phone: condFiltersFacepay.phone,
            profileId: condFiltersFacepay.profileId,
            fullname: condFiltersFacepay.fullname,
            lastItem: listCustomerFacepay[listCustomerFacepay.length - 1].id,
            limit: condFiltersFacepay.limit,
        },true , callback))
    }, [listCustomerFacepay], [listCustomerFacepay.length])

    const _handleSelectActive = (item) => {
        if (!item) return
        if(isTypeLoadingVerify || isTypeLoadingDevice || isTypeLoadingEkyc || isTypeLoadingTransaction || isTypeLoadingBacklog) return
        setKeySelected(item.id)
        const callbackSuccess = () => {
            dispatch(getDetailCustomer(item))
        }
        dispatch(clearDetailCustomer(callbackSuccess))
    }

    return (
        <WrapFacepayCustomerList>
            <FilterFacepayBlock />
            {
                listCustomerFacepay?.length === 0 && isTypeLoading ?
                    <LoadingLocal />
                    :
                    <>
                        {
                            listCustomerFacepay?.length === 0 ?
                                (
                                    <div className="wrap-empty">
                                        <EmptyDataControl
                                            icon={<IcCensorshipDefault />}
                                            text={"Hiện tại chưa có khách hàng mới"}
                                        />
                                    </div>
                                ) : (
                                    <TableContent>
                                        <div className="wrap-list-customer" ref={listRef}>
                                            <div className="list-customer">
                                                {listCustomerFacepay?.map((item, index) => (
                                                    <div className="list" key={index}
                                                        onClick={() => _handleSelectActive(item)}>
                                                        <CustomerItem data={item} keySelected={keySelected} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </TableContent>
                                )
                        }
                    </>
            }
        </WrapFacepayCustomerList>
    )
}

export default FacepayCustomerList
