import React from 'react'

const IcCheckBgBlue = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#33ADFF"/>
        <path d="M14.4446 6.66406L8.33344 12.7752L5.55566 9.9974" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default IcCheckBgBlue
