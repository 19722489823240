import React, { useEffect, useMemo, useReducer, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import dayjs from "dayjs"

// styles
import {
  FilterCheckBox,
  FilterControlTime,
} from "../../Censorship/WaitingCensorship/FilterWaitingCensorship/index.styles"
import { FilterBusinessContent, WrapFilterBusiness } from "./index.styles"

// images
import IcUnCheckBox from "../../../../../../assets/images/IcUnCheckBox"
import IcCheckBoxActive from "../../../../../../assets/images/IcCheckBoxActive"
import IcCheckBoxDisabled from "../../../../../../assets/images/IcCheckBoxDisabled"
import IcArrowRight from "../../../../../../assets/images/IcArrowRight"
import IcCalendarActive from "../../../../../../assets/images/IcCalendarActive"
import IcRefresh from "../../../../../../assets/images/IcRefresh"

// key
import {
  BUSINESS_ID,
  BUSINESS_NAME,
  BUSINESS_WAITING_UPDATE,
  FULL_NAME,
  PHONE_NUMBER,
} from "../../../../../../constant/keys"

// component
import InputSearchBusiness from "./InputSearchBusiness"
import DropdownFilterBusiness from "./DropdownFilterBusiness"
import Button from "../../../../../Control/Button"
import CheckBoxControl from "../../../../../Control/CheckBoxControl"
import DatePickerItem from "../../../../../Control/DatePickerItem"
import FilterControl from "../../../../../Control/FilterControl"

// redux
import { resetCondFiltersBusiness, updatecondFiltersBusiness } from "../../../../../../redux/business/listBusinessManage/action"

const iconCheckBox = {
  icon: IcUnCheckBox,
  iconActive: IcCheckBoxActive,
  iconDisabled: IcCheckBoxDisabled,
};

const FilterBusinessManage = ({ isShowSelectCheckbox }) => {
  const dispatch = useDispatch();

  const { condFiltersBusiness } = useSelector((state) => ({
    condFiltersBusiness:
      state.businessReducer.listBusinessManage.condFiltersBusiness,
  }))

  const [valueSearch, setValueSearch] = useState("")

  const [filterCondBusiness, setFilterCondBusiness] = useState(
    condFiltersBusiness.optionSelect
  )

  const _handleChangeOptionFilter = (filterOption) => {
    setFilterCondBusiness(filterOption)
    setValueSearch("")
  };

  const _handleSearch = () => {
    const { key } = filterCondBusiness
    let businessCode = ""
    let phoneNumber = ""
    let ownerName = ""
    let businessName = ""
    switch (key) {
      case BUSINESS_ID: {
        businessCode = valueSearch
        break
      }
      case PHONE_NUMBER: {
        phoneNumber = valueSearch
        break
      }
      case FULL_NAME: {
        ownerName = valueSearch
        break
      }
      case BUSINESS_NAME: {
        businessName = valueSearch
        break
      }
      default:
        return
    }
    dispatch(
      updatecondFiltersBusiness({
        ...condFiltersBusiness,
        businessCode,
        phoneNumber,
        ownerName,
        businessName,
      })
    )
  }

  const dropdownFilterBusiness = useMemo(
    () => (
      <DropdownFilterBusiness
        filterCond={filterCondBusiness}
        setFilterCond={_handleChangeOptionFilter}
      />
    ),[filterCondBusiness, setFilterCondBusiness]
  )

    const filterComponent = useMemo(() => (
        <InputSearchBusiness 
          filterCondBusiness={filterCondBusiness}
          valueSearch={valueSearch}
          setValueSearch={setValueSearch}
          handleSearch={_handleSearch}
        />
    ),[filterCondBusiness, _handleSearch]
  )

  const handleSelectCheckbox = (checkboxSelected) => {
    if (!condFiltersBusiness.optionSelectCheckboxBusiness) return
    let checkboxCollection =
      condFiltersBusiness.optionSelectCheckboxBusiness.map((item) => {
        if (item.id !== checkboxSelected) {
          return {
            ...item,
          }
        } else {
          return {
            ...item,
            isSelect: !item.isSelect,
          }
        }
      })
    dispatch(
      updatecondFiltersBusiness({
        ...condFiltersBusiness,
        optionSelectCheckboxBusiness: checkboxCollection,
      })
    )
  }

  const handleRefresh = () => {
    setValueSearch("")
    dispatch(resetCondFiltersBusiness())
  }

  return (
    <WrapFilterBusiness>
      <FilterBusinessContent>
        <FilterControl
          className="input-business"
          dropdownFilter={dropdownFilterBusiness}
          filterComponent={filterComponent}
          handleSearch={_handleSearch}
          value={valueSearch}
          isShowSubmitBtn={false}
          isDebounce={false}
        />
        <FilterControlTime>
          <IcCalendarActive />
          <div className="date-title">Thời gian:</div>
          <DatePickerItem
            className="input-dropdown"
            selected={condFiltersBusiness.begin}
            onChange={(date) =>
              dispatch(updatecondFiltersBusiness({
                ...condFiltersBusiness,
                begin: date ? dayjs(date).startOf("day").toDate() : undefined,
              }))
            }
            maxDate={condFiltersBusiness.end ? condFiltersBusiness.end : new Date()}
            textEmpty="Từ ngày"
            dateFormat="dd/MM/yyyy"
            showTimeSelect={false}
          />
          <IcArrowRight />
          <DatePickerItem
            className="input-dropdown "
            selected={condFiltersBusiness.end}
            onChange={(date) =>
              dispatch(updatecondFiltersBusiness({
                ...condFiltersBusiness,
                end: date ? dayjs(date).endOf("day").toDate() : undefined,
              }))
            }
            minDate={condFiltersBusiness.begin}
            maxDate={new Date()}
            textEmpty="Đến ngày"
            dateFormat="dd/MM/yyyy"
            showTimeSelect={false}
          />
        </FilterControlTime>
        <FilterCheckBox>
          {isShowSelectCheckbox ? (
            condFiltersBusiness.optionSelectCheckboxBusiness.map(
              (item, index) => (
                !item.type.includes(BUSINESS_WAITING_UPDATE) && 
                  (
                    <CheckBoxControl
                      key={index}
                      title={item.name}
                      iconCheckBox={iconCheckBox}
                      className="info-check"
                      isChecked={(item.isDisabled !== true) & item.isSelect}
                      handleCheck={() => handleSelectCheckbox(item.id)}
                    />
                  )
                )
              ) 
          ) : "" }
        </FilterCheckBox>
      </FilterBusinessContent>
      <Button
        iconBtn={<IcRefresh />}
        text="Reset"
        className="btn-reset"
        onClick={handleRefresh}
      />
    </WrapFilterBusiness>
  )
}

export default FilterBusinessManage
