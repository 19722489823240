import React from 'react'

const IcRefundGray = (props) => {
  return (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.41669 4.39581H12.8748" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.3645 1.8855L12.8748 4.39576L10.3645 6.90602" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.20813 9.64581H1.75" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.26025 7.1355L1.74999 9.64576L4.26025 12.156" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcRefundGray