import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//style
import { WrapRejecProfileDetailItem } from './index.styles'

//data
import { dataCensorshipStatus } from '../../../../../../../data/dataCensorshipStatus'
import { convertDateTime } from '../../../../../../../helper/convertDateTime'

//image
import IcWarningRed from '../../../../../../../assets/images/IcWarningRed'
import IcUserBlueDefault from '../../../../../../../assets/images/IcUserBlueDefault'
import IcPhoneBlueDefault from '../../../../../../../assets/images/IcPhoneBlueDefault'
import IcMailBlueDefault from '../../../../../../../assets/images/IcMailBlueDefault'
import IcRefuseRed from "../../../../../../../assets/images/IcRefuseRed";
import IcRefuseYellow from "../../../../../../../assets/images/IcRefuseYellow";

//component
import FaceRecognizeInfos from "../../FaceRecognizeInfos";

const RejecProfileDetailItem = ({ data, index }) => {
    
    return (
        <WrapRejecProfileDetailItem>
            <div className="wrap-container">
                <div className="wrap-infos">
                    <div className="info">
                        {
                            index > 1 ?
                                <IcRefuseRed className="icon" />
                                :
                                <IcRefuseYellow className="icon" />
                        }
                        <span className="name">Document ID:</span>
                        <span className="value" title={data?.documentId || ""}>
                            {data?.documentId || "-"}
                        </span>
                    </div>
                    <div className="info">
                        <span className="name">Thời gian gửi:</span>
                        <span className="value" title={convertDateTime(data?.submitTime, "HH:mm - DD/MM/YYYY") || ""}>
                            {data?.submitTime ? convertDateTime(data?.submitTime, "HH:mm - DD/MM/YYYY") : "-"}
                        </span>
                    </div>
                    <div className="info">
                        <span className="name">Người kiểm duyệt: </span>
                        <span className="value" title={data?.verifiedBy || ""}>
                            {data?.verifiedBy || "-"}
                        </span>
                    </div>
                    <div className="info">
                        <span className="name">Thời gian kiểm duyệt:</span>
                        <span className="value" title={convertDateTime(data?.verifiedAt, "HH:mm - DD/MM/YYYY") || ""}>
                            {data?.verifiedAt ? convertDateTime(data?.verifiedAt, "HH:mm - DD/MM/YYYY") : "-"}
                        </span>
                    </div>
                </div>
                    < div className="wrap-list-reason">
                        {
                            data?.reasons?.map((reason, i) => {
                                return (

                                    <div className="list-reason" key={i}>
                                        <IcWarningRed className="icon" />
                                        <p className="text">{reason}</p>
                                    </div>
                                )
                            }
                            )
                        }
                        {
                            !!data.optionalReason &&
                            <div className="list-reason">
                                <IcWarningRed className="icon" />
                                <p className="text">Lý do khác: {data.optionalReason}</p>
                            </div>
                        }
                    </div>
                <div className="wrap-details">
                    <div className="title">Thông tin khách hàng</div>
                    <div className="row-detail">
                        <div className="row">
                            <IcUserBlueDefault className="ic-default" />
                            <div className="detail">
                                <p className="value" title={data?.fullName || ""}>{data?.fullName || "-"}</p>
                                <p className="name">Họ tên</p>
                            </div>
                        </div>
                        <div className="row">
                            <IcPhoneBlueDefault className="ic-default" />
                            <div className="detail">
                                <p className="value" title={data?.phone || ""}>{data?.phone || "-"}</p>
                                <p className="name">Số điện thoại</p>
                            </div>
                        </div>
                        <div className="row">
                            <IcMailBlueDefault className="ic-default" />
                            <div className="detail">
                                <p className="value" title={data?.email || ""}>{data?.email || "-"}</p>
                                <p className="name">Email</p>
                            </div>
                        </div>

                    </div>
                </div>
                <FaceRecognizeInfos thumbnailUrl={data?.thumbnailUrl} fullFaceUrl={data?.fullFaceUrl} listSameFace={data?.sameFaceObjList} />
            </div>
        </WrapRejecProfileDetailItem >
    )
}

export default RejecProfileDetailItem
