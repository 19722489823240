
import IcCloseRedMini from "../assets/images/IcCloseRedMini";
import IcInComingCall from "../assets/images/IcInComingCall";
import IcMissCall from "../assets/images/IcMissCall";

import IcCheckBgBlue from "../assets/images/IcCheckBgBlue"
// import IcDeviceStatusActive from "../assets/images/IcDeviceStatusActive";
// import IcDeviceStatusGray from "../assets/images/IcDeviceStatusGray";

// import IcActive from "../assets/images/IcActive"
// import IcLocked from '../assets/images/IcLockBgRed'

// export const CUSTOMACTIVE = "true"
// export const LOCKED = "false"

export const CMND9ID = "9_id_card"
export const CMND12ID = "12_id_card"
export const CCCD = "chip_id"
export const PASSPORT = "passport"

export const NEWKYC = "NEW-KYC"

export const MALE = "MALE"
export const FEMALE = "FEMALE"

export const ACTIVE = "APPROVED"
export const REJECT = "REJECTED"

// export const CONNECT = "CONNECT"
// export const PENDING = "PENDING"

export const dataCallStatus = {
    [1]: {
        icon: <IcInComingCall />,
        text: "Cuộc gọi đến",
        background: "#EFF9FF"
    },
    [2]: {
        icon: <IcInComingCall />,
        text: "Cuộc gọi đi",
        background: "#EFF9FF"
    },
    [3]: {
        icon: <IcMissCall />,
        text: "Cuộc gọi nhỡ",
        background: "#FFEEEE"

    },
}

export const dataCensorStatus = {
    [ACTIVE]: {
        icon: <IcCheckBgBlue />,
        text: "Phê duyệt"
    },
    [REJECT]: {
        icon: <IcCloseRedMini />,
        text: "Từ chối"
    }
}

export const dataPassportStatus = {
    [CMND9ID]: {     
        key: CMND9ID,   
        text: 'Chứng minh nhân dân 9 số'
    },
    [CMND12ID]: {  
        key: CMND12ID,      
        text: 'Chứng minh nhân dân 12 số'
    },
    [CCCD]: {
        key: CCCD,
        text: 'Căn cước công dân'
    },
    [PASSPORT]: {
        key: PASSPORT,
        text: 'Passport'
    },
}

// export const dataKYCStatus = {
//     [NEWKYC]: {
//         icon: <IcCheckBgBlue />,
//         text: "Dữ liệu mới"
//     }
// }

export const dataGender = {
    [MALE]:{
        key: MALE,
        text: "Nam"
    },
    [FEMALE]:{
        key: FEMALE,
        text: "Nữ"
    }
}

// export const dataDeviceStatus = {
//     [CONNECT]: {
//         icon: <IcDeviceStatusGray />,
//         text: 'Đã liên kết'
//     },
//     [PENDING]: {
//         icon: <IcDeviceStatusActive />,
//         text: 'Đang liên kết'
//     }
// }

// export const dataCustomerStatus = {
//     [CUSTOMACTIVE]: {
//         icon: <IcActive />,
//         text: 'Đang kích hoạt'
//     },
//     [LOCKED]: {
//         icon: <IcLocked />,
//         text: 'Đã khoá'
//     }
// }