import moment from 'moment'

import * as types from './types'

import { FACEPAY_PORTAL_LOGIN } from '../../../helper/localStore'

export const startDay = moment('1/1/2022').startOf('day').toDate()
export const currentDay = moment(new Date()).endOf("day").toDate()

const userName = JSON.parse(localStorage.getItem(FACEPAY_PORTAL_LOGIN))

const initialState = {
    listBacklog: [],
    condFilters: {
        adminId: userName?.uuid,
        adminUserName: userName?.username,
        uid: '',
        bioId: '',
        skip: 0,
        limit: 15,
        type: '',
        begin: startDay,
        end: currentDay,
    },
    reqType: 'FACEPAY'
}

const customerBacklogReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.GET_LIST_CUSTOMER_BACKLOG:
        case types.GET_LIST_CUSTOMER_BANK_BACKLOG:
            return {
                ...state,
                ...action.payload,
                listBacklog: action.payload.isScroll
                    ? [...state.listBacklog, ...action.payload.listBacklog]
                    : action.payload.listBacklog
            }

        case types.RESET_LIST_CUSTOMER_BACKLOG: 
        case types.RESET_LIST_CUSTOMER_BANK_BACKLOG:
            return {
                ...state,
                listBacklog: [],
            }

        case types.UPDATE_LIST_CUSTOMER_BACKLOG_CONDFILTERS:{
            return {
                ...state,
                condFilters: {
                    ...state.condFilters,
                    ...action.condFilters,
                },
                reqType: action.reqType,
                isScroll: action.isScroll
            }}

        case types.RESET_UPDATE_LIST_CUSTOMER_BACKLOG_CONDFILTERS: {
            return {
                ...state,
                condFilters: initialState.condFilters
            }
        }
        
        default:
            return state
    }
}

export default customerBacklogReducer