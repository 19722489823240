import React from 'react'

const IcNoteBlue = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 11.668H12.25" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.625 2.04212C9.85706 1.81006 10.1718 1.67969 10.5 1.67969C10.6625 1.67969 10.8234 1.71169 10.9735 1.77388C11.1237 1.83607 11.2601 1.92722 11.375 2.04212C11.4899 2.15703 11.5811 2.29344 11.6432 2.44358C11.7054 2.59371 11.7374 2.75462 11.7374 2.91712C11.7374 3.07963 11.7054 3.24054 11.6432 3.39067C11.5811 3.5408 11.4899 3.67722 11.375 3.79212L4.08333 11.0838L1.75 11.6671L2.33333 9.33379L9.625 2.04212Z" fill="#33ADFF" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcNoteBlue
