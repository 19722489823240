import styled from 'styled-components'
//style
import { WrapPageItem } from '../WaitingCensorship/index.styles'

const WrapDetail = styled(WrapPageItem)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    padding: 22px 30px 0 30px;
    .wrap-list-censorship{
        width: calc(100% + 10px);
        margin-left: 10px;
        position: relative;
        flex-grow: 1;
        overflow: overlay;        
        .list-censorship{
            width: calc(100% - 10px);   
            display: flex;
            flex-direction: column;
            position: absolute;
            .info-censorship{
                padding: 0 0 22px 0;
                .title-detail{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #313131;
                    padding: 0 0 12px 0;
                }
            }
        }
    }

`
export { WrapDetail }