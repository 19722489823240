import * as types from "./types";

const initialState = {
  details: {},
  eKycStatus: {},
  listVerifyHistory: [],
  listEkycHistory: [],
  listDevice: [],
  isPopupOpenLockCustomer: false,
  isPopupLockCustomer: false,
};
const customerDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DETAIL_CUSTOMER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.CLEAR_CUSTOMER_DETAILS: {
      return {
        ...state,
        details: {}
      }
    }

    case types.GET_LIST_VERIFY_HISTORY_CUSTOMER:
      return {
        ...state,
        listVerifyHistory: action.payload.listVerifyHistory,
      };
    case types.RESET_LIST_VERIFY_HISTORY_CUSTOMER:
      return {
        ...state,
        listVerifyHistory: [],
      };

    case types.GET_LIST_EKYC_HISTORY_CUSTOMER:
      return {
        ...state,
        listEkycHistory: action.payload.listEkycHistory,
        eKycStatus: action.payload.eKycStatus,
      };
    case types.RESET_LIST_EKYC_HISTORY_CUSTOMER:
      return {
        ...state,
        listEkycHistory: [],
        eKycStatus: {},
      };

    case types.GET_LIST_DEVICE_LOG_CUSTOMER:
      return {
        ...state,
        listDevice: action.payload.listDevice,
      };
    case types.RESET_LIST_DEVICE_HISTORY_CUSTOMER:
      return {
        ...state,
        listDevice: [],
      };

    case types.POPUP_OPEN_LOCK_DETAIL_CUSTOMER:
      return {
        ...state,
        isPopupOpenLockCustomer: true,
      };

    case types.CLOSED_POPUP_OPEN_LOCK_DETAIL_CUSTOMER:
      return {
        ...state,
        isPopupOpenLockCustomer: initialState.isPopupOpenLockCustomer,
      };

    case types.POPUP_LOCK_DETAIL_CUSTOMER:
      return {
        ...state,
        isPopupLockCustomer: true,
      };

    case types.CLOSED_POPUP_LOCK_DETAIL_CUSTOMER:
      return {
        ...state,
        isPopupLockCustomer: initialState.isPopupLockCustomer,
      };

    default:
      return state;
  }
};

export default customerDetailReducer;
