export const headerTransListData = [
    "Status",
    "Merchant Tx ID",
    "Bank Tx ID",
    "Facepay Tx ID",
    "Faceprint",
    "Ngày",
    "Thời gian",
    "Số tiền (đ)",
    "Merchant",
    "Ngân hàng",
    "Hoàn tiền"
]

export const headerBankTransListData = [
    "Facepay Tx ID",
    "Bank Tx ID",
    "Merchant Tx ID",
    "Faceprint",
    "Merchant",
    "Ngân hàng",
    "Số tiền (đ)",
    "Thời gian giao dịch",
    "Mã khuyến mãi",
    "Hoàn tiền",
    ""
]

export const headerFacepayTransListData = [
    "Facepay Tx ID",
    "Bank Tx ID",
    "Merchant Tx ID",
    "Faceprint",
    "Merchant",
    "Nguồn tiền",
    "Số tiền (đ)",
    "Thời gian giao dịch",
    "Mã khuyến mãi",
    "Hoàn tiền"
]

export const headerWalletFacepayTransListData = [
    "Facepay Tx ID",
    "Profile ID",
    "Username",
    "Tài khoản gửi",
    "",
    "Tài khoản nhận",
    "Số tiền (đ)",
    "Thời gian",
    "Trạng thái"
]