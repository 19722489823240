import IcLinkCard from "../assets/images/IcLinkCard";
import IcLinkCardFailed from "../assets/images/IcLinkCardFailed";
import IcDeposit from "../assets/images/IcDeposit";
import IcDepositFailed from "../assets/images/IcDepositFailed";
import { DEPOSIT, LINK_CARD, ANOTHER_WALLE, WITH_DRAWAL, TRANSFER_TO_BANK, TRANSFER_TO_WALLET, TRANSFER_FROM_FACEPOS } from "../constant/keys";
import IcTransferToWallet from "../assets/images/IcTransferToWallet";
import IcTransferToWalletFail from "../assets/images/IcTransferToWalletFail";
import IcTransferToBank from "../assets/images/IcTransferToBank";
import IcTransferToBankFail from "../assets/images/IcTransferToBankFail";
import IcTransferToFacePOS from "../assets/images/IcTransferToFacePOS";
import IcTransferToFacePOSFail from "../assets/images/IcTransferToFacePOSFail";

const FAILED = 0
const SUCCESS = 1
const REFUNDED = 2 // transaction is succeeded but it was refunded

const dataWalletFacepay = [
    {
        text: "Liên kết thẻ",
        iconSuccess: <IcLinkCard />,
        iconFailed: <IcLinkCardFailed />,
        key: LINK_CARD
    },
    {
        text: "Nạp tiền",
        iconSuccess: <IcDeposit />,
        iconFailed: <IcDepositFailed />,
        key: DEPOSIT
    },
    {
        text: "Chuyển tiền tới ngân hàng",
        iconSuccess: <IcTransferToBank />,
        iconFailed: <IcTransferToBankFail />,
        key: TRANSFER_TO_BANK
    },
    {
        text: "Chuyển tiền tới ví",
        iconSuccess: <IcTransferToWallet />,
        iconFailed: <IcTransferToWalletFail />,
        key: TRANSFER_TO_WALLET
    },
    {
        text: "Nhận tiền từ FacePOS",
        iconSuccess: <IcTransferToFacePOS />,
        iconFailed: <IcTransferToFacePOSFail />,
        key: TRANSFER_FROM_FACEPOS
    },
]

const getValueWalletFacepay = (type, status) => {
    const iconWallet = dataWalletFacepay.filter((item) => item.key === type).map((item) => {
        return (
            status === SUCCESS ? <div title={item.text} key={item.key} className="item-icon">{item.iconSuccess}</div> : <div title={item.text} key={item.key} className="item-icon">{item.iconFailed}</div>
        )
    })
    const valueWallet = dataWalletFacepay.filter((item) => item.key === type).map((item) => item.text)

    return { iconWallet: iconWallet , valueWallet: valueWallet}
}

export default getValueWalletFacepay