import moment from 'moment';
import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
// styles
import { WrapDatePickerItem, WrapSelectDate } from './index.styles';

// image
import IcArrowNext from '../../../assets/images/IcArrowNext'
import IcArrowPrev from '../../../assets/images/IcArrowPrev';

const weekDays = {
    'Monday': 'T2',
    'Tuesday': 'T3',
    'Wednesday': 'T4',
    'Thursday': 'T5',
    'Friday': 'T6',
    'Saturday': 'T7',
    'Sunday': 'CN',
}

const SelectDate = forwardRef(({ textEmpty, value, onClick }, ref) => {
    
    return (
        <WrapSelectDate className={value ? 'have-value' : 'empty'} onClick={onClick} ref={ref}>
            {value || textEmpty}
        </WrapSelectDate>
    )
});

const DatePickerItem = ({ textEmpty, ...props }) => {

    return (
        <WrapDatePickerItem >
            <DatePicker 
                customInput={<SelectDate textEmpty={textEmpty} />}
                dateFormat="HH:mm - dd/MM/yy"
                showTimeSelect
                showPopperArrow={false}
                timeFormat="HH:mm"
                timeIntervals={60}
                popperPlacement="bottom"
                formatWeekDay={formattedDate => weekDays[formattedDate]}
                renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className='header-calendar'>
                        <button
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                            aria-label="Previous Month">
                            <IcArrowPrev className='back-reverse'/>
                        </button>
                        <div className="title-header">Tháng {moment(date).format("M/YYYY")}</div>
                        <button
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                            aria-label="Next Month">
                            <IcArrowNext className="reverse" />
                        </button>
                    </div>
                )}
                {...props}
            />
        </WrapDatePickerItem>
    )
}

export default DatePickerItem
