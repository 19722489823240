import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Switch, Route, Redirect, NavLink } from "react-router-dom";

//styles
import * as styles from './index.styles'

//component
import FacepayCustomerList from './FacepayCustomerList'
import BankCustomerList from './BankCustomerList'
import TabListMenu from '../../../../../Control/TabListMenu'

// constant
import { DEV_SV, QC_SV, PROD_SV, SBX_SV } from '../../../../../../constant/environmentTypes'
import { reqTypeCustomer } from '../../../../../../redux/customer/listCustomer/actionV2'

// constant
import { BANK, FACEPAY } from '../../../../../../constant/keys'
import * as path from '../../../../../../constant/path'
import TabListAndToolCustomer from './TabListAndToolCustomer';


const dataTab = [
    {
        title: 'Facepay',
        key: FACEPAY,
        component: {
            type: 'FACEPAY',
            comp: <FacepayCustomerList />,
            path: `/${path.MAIN}/${path.CUSTOMER}/${path.CUSTOMER_FACEPAY}`
        },
        envServerValid: [
            DEV_SV,
            QC_SV,
            PROD_SV,
            SBX_SV
        ]
    },
    {
        title: 'Ngân hàng',
        key: BANK,
        component: {
            type: "BANK",
            comp: <BankCustomerList />,
            path: `/${path.MAIN}/${path.CUSTOMER}/${path.CUSTOMER_BANK}`
        },
        envServerValid: [
            DEV_SV,
            QC_SV,
            PROD_SV,
            SBX_SV
        ]
    },
]

export default function CustomerList() {
    
    return (
        <styles.Bound>
            <div className='block info-expand'>
                 <TabListAndToolCustomer listTabs={dataTab} />
                 <Switch>
                    <Route exact path={`/${path.MAIN}/${path.CUSTOMER}/${path.CUSTOMER_FACEPAY}`}>
                        <FacepayCustomerList />
                    </Route>
                    <Route exact path={`/${path.MAIN}/${path.CUSTOMER}/${path.CUSTOMER_BANK}`}>
                        <BankCustomerList />
                    </Route>
                    <Redirect to={`/${path.MAIN}/${path.CUSTOMER}/${path.CUSTOMER_FACEPAY}`} />
                </Switch>
            </div>
        </styles.Bound>
    )
}
