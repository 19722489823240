import styled, { css } from 'styled-components'

const HEIGHT_ITEM = 48;

const WrapDropdownList = styled.div`
    position: absolute;
    top: calc(100% + ${props => props.alignTop || "12px"});
    left: ${props => props.alignLeft || "-30px"};

    /* left: 50%; */
    /* transform: translate(-50%, 0); */
    background: #FFFFFF;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    // width: 140px;
    ${
        props => props.maxItemShow
            ? css`
                overflow-y: auto;
                max-height: ${HEIGHT_ITEM * props.maxItemShow}px;
            `
        : ''
    }

    & > * {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 10px 0 14px;
        height: ${HEIGHT_ITEM}px;
        background: #FFFFFF;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        
        span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: #313131;
            padding-right: 10px;
        }

        &:hover { 
            cursor: pointer;
        }
    }
`;

export {
    WrapDropdownList
}