// FACEPAY
export const GET_LIST_CUSTOMER_NOTE = 'GET_LIST_CUSTOMER_NOTE'
export const GET_LIST_CUSTOMER_NOTE_ERR = 'GET_LIST_CUSTOMER_NOTE_ERR'


export const CUSTOMER_MAKE_NOTE_PAYMENT = 'CUSTOMER_MAKE_NOTE_PAYMENT'
export const CUSTOMER_MAKE_NOTE_PAYMENT_ERR = 'CUSTOMER_MAKE_NOTE_PAYMENT_ERR'

export const CUSTOMER_MAKE_NOTE_BACKLOG = 'CUSTOMER_MAKE_NOTE_BACKLOG'
export const CUSTOMER_MAKE_NOTE_BACKLOG_ERR = 'CUSTOMER_MAKE_NOTE_BACKLOG_ERR'

export const CUSTOMER_MAKE_NOTE_CS = 'CUSTOMER_MAKE_NOTE_CS'
export const CUSTOMER_MAKE_NOTE_CS_ERR = 'CUSTOMER_MAKE_NOTE_CS_ERR'

export const RESET_LIST_CUSTOMER_NOTE = 'RESET_LIST_CUSTOMER_NOTE'


// BANK
export const GET_LIST_CUSTOMER_BANK_NOTE = 'GET_LIST_CUSTOMER_BANK_NOTE'
export const GET_LIST_CUSTOMER_NOTE_BANK_ERR = 'GET_LIST_CUSTOMER_NOTE_BANK_ERR'

export const CUSTOMER_MAKE_NOTE_BANK_PAYMENT = 'CUSTOMER_MAKE_NOTE_BANK_PAYMENT'
export const CUSTOMER_MAKE_NOTE_BANK_PAYMENT_ERR = 'CUSTOMER_MAKE_NOTE_BANK_PAYMENT_ERR'

export const CUSTOMER_MAKE_NOTE_BANK_BACKLOG = 'CUSTOMER_MAKE_NOTE_BANK_BACKLOG'
export const CUSTOMER_MAKE_NOTE_BANK_BACKLOG_ERR = 'CUSTOMER_MAKE_NOTE_BANK_BACKLOG_ERR'

export const CUSTOMER_MAKE_NOTE_BANK_CS = 'CUSTOMER_MAKE_NOTE_BANK_CS'
export const CUSTOMER_MAKE_NOTE_BANK_CS_ERR = 'CUSTOMER_MAKE_NOTE_BANK_CS_ERR'

export const RESET_LIST_CUSTOMER_BANK_NOTE = 'RESET_LIST_CUSTOMER_BANK_NOTE'

// WALLET

export const CUSTOMER_MAKE_NOTE_WALLET_PAYMENT = 'CUSTOMER_MAKE_NOTE_WALLET_PAYMENT'
export const CUSTOMER_MAKE_NOTE_WALLET_PAYMENT_ERR = 'CUSTOMER_MAKE_NOTE_WALLET_PAYMENT_ERR'


// LOADING
export const LOADING_GET_LIST_CUSTOMER_NOTE = 'LOADING_GET_LIST_CUSTOMER_NOTE'

//POPUP NOTE
export const SHOW_POPUP_NOTE = 'SHOW_POPUP_NOTE'
export const HIDE_POPUP_NOTE = 'HIDE_POPUP_NOTE'
