import React from 'react'

const IcRefreshBlue = (props) => {
    return (
        <svg {...props} width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.375 13V9.25H10.625" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.625 3V6.75H4.375" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.8062 6.12499C12.4893 5.22923 11.9505 4.42836 11.2403 3.79712C10.5301 3.16588 9.67158 2.72484 8.74482 2.51515C7.81806 2.30546 6.85328 2.33395 5.94051 2.59797C5.02773 2.86199 4.19672 3.35293 3.525 4.02499L0.625 6.74999M14.375 9.24999L11.475 11.975C10.8033 12.647 9.97227 13.138 9.05949 13.402C8.14672 13.666 7.18194 13.6945 6.25518 13.4848C5.32842 13.2751 4.46988 12.8341 3.75967 12.2028C3.04946 11.5716 2.51073 10.7707 2.19375 9.87499" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcRefreshBlue