import React from 'react'

const IcInfoGray = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00002 12.8333C10.2217 12.8333 12.8334 10.2216 12.8334 6.99996C12.8334 3.7783 10.2217 1.16663 7.00002 1.16663C3.77836 1.16663 1.16669 3.7783 1.16669 6.99996C1.16669 10.2216 3.77836 12.8333 7.00002 12.8333Z" fill="white" />
            <path d="M7 9.33333V7" stroke="#777777" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 4.66663H7.00583" stroke="#777777" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcInfoGray