// constant
import { DEV_ENV, WEE_ENV, env, buildVersion, qcVersion, branch,
        DEV_SV, QC_SV, PROD_SV, SBX_SV } from '../constant/environmentTypes';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";

import { getStorage, ref as storageRef, getDownloadURL, uploadBytes } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyC4JVcieETgJjCD84yxLqIJoy-FpA70eBU",
//   authDomain: "facepaydev-2b20f.firebaseapp.com",
//   databaseURL: "https://facepaydev-2b20f-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "facepaydev-2b20f",
//   storageBucket: "facepaydev-2b20f.appspot.com",
//   messagingSenderId: "583305342162",
//   appId: "1:583305342162:web:12769749c085398ef4110b",
//   measurementId: "G-5LHNHEXZ7X"
// };

export const { firebaseConfig } = (() => {
  switch(env) {
    case WEE_ENV:
      switch(process.env.REACT_APP_SERVER_ENV) {
        case DEV_SV:
          return {
            firebaseConfig: {
              apiKey: "AIzaSyC4JVcieETgJjCD84yxLqIJoy-FpA70eBU",
              authDomain: "facepaydev-2b20f.firebaseapp.com",
              databaseURL: "https://facepaydev-2b20f-default-rtdb.asia-southeast1.firebasedatabase.app",
              projectId: "facepaydev-2b20f",
              storageBucket: "facepaydev-2b20f.appspot.com",
              messagingSenderId: "583305342162",
              appId: "1:583305342162:web:12769749c085398ef4110b",
              measurementId: "G-5LHNHEXZ7X"
            }
          }
        case QC_SV:
          return{
            firebaseConfig: {
              apiKey: "AIzaSyDAGRbZAtpyeZxBFB3rB625cyk-MgL99bg",
              authDomain: "facepayqc-a1fd2.firebaseapp.com",
              databaseURL: "https://facepayqc-a1fd2-default-rtdb.asia-southeast1.firebasedatabase.app",
              projectId: "facepayqc-a1fd2",
              storageBucket: "facepayqc-a1fd2.appspot.com",
              messagingSenderId: "330905472123",
              appId: "1:330905472123:web:5e597ca7147a54b15126de",
              measurementId: "G-5XJNLN4DP2"
            }
          }
        case PROD_SV:
          return{
            firebaseConfig: {
              apiKey: "AIzaSyCWIqhKbb0xJRerEjm66GCJHtExcVODT8g",
              authDomain: "facepay-e77fe.firebaseapp.com",
              databaseURL: "https://facepay-e77fe-default-rtdb.asia-southeast1.firebasedatabase.app",
              projectId: "facepay-e77fe",
              storageBucket: "facepay-e77fe.appspot.com",
              messagingSenderId: "173310475544",
              appId: "1:173310475544:web:068921609054ba5b51b26d"
            }
          }
        case SBX_SV:
          return{
            firebaseConfig: {
              apiKey: "AIzaSyCQzZwrH9V1iQBAfrqx30xTtvSmhFefw_c",
              authDomain: "facepaysandbox.firebaseapp.com",
              databaseURL: "https://facepaysandbox-default-rtdb.asia-southeast1.firebasedatabase.app",
              projectId: "facepaysandbox",
              storageBucket: "facepaysandbox.appspot.com",
              messagingSenderId: "726733207167",
              appId: "1:726733207167:web:f2887df9d6906cb10b714a",
              measurementId: "G-W5WJNZSEJR"
            }
          }
        default:
          return {
            firebaseConfig: {
              apiKey: "AIzaSyCWIqhKbb0xJRerEjm66GCJHtExcVODT8g",
              authDomain: "facepay-e77fe.firebaseapp.com",
              databaseURL: "https://facepay-e77fe-default-rtdb.asia-southeast1.firebasedatabase.app",
              projectId: "facepay-e77fe",
              storageBucket: "facepay-e77fe.appspot.com",
              messagingSenderId: "173310475544",
              appId: "1:173310475544:web:068921609054ba5b51b26d"
            }
          }
        }
    default:
        return {
          firebaseConfig:{}
        }
  }
})()

// Initialize Firebase
const app = [DEV_ENV, WEE_ENV].includes(env) && initializeApp(firebaseConfig);
// Get Auth
const auth = getAuth();

signInAnonymously(auth)
  .then(() => {
    // Signed in..
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage)
    // ...
  });
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    console.log(user)
    // ...
  } else {
    // User is signed out
    // ...
  }
});


export const getFileStorage = async (pathFile, callbackSuccess, callbackError) => {
    //  console.log(pathFile)
    const storage = getStorage();
    //  console.log(storage)
    getDownloadURL(storageRef(storage, pathFile))
    .then((url) => {
        // console.log(url)
        // `url` is the download URL for 'images/stars.jpg'
        callbackSuccess && callbackSuccess(url)
        // This can be downloaded directly:
        // const xhr = new XMLHttpRequest();
        // xhr.responseType = 'blob';
        // xhr.onload = (event) => {
        //   const blob = xhr.response;
        // };
        // xhr.open('GET', url);
        // xhr.send();

        // Or inserted into an <img> element
        // const img = document.getElementById('myimg');
        // img.setAttribute('src', url);
    })
    .catch((error) => {
        // Handle any errors
        // console.log(error)
        callbackError && callbackError(error)
    });
}

export default app
// export const analytics = getAnalytics(app);
// export const database = getDatabase(app);