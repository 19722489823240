import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

// styled
import { WrapBusinessManage } from './index.styles'

// keys
import { BUSINESS_REJECT, BUSINESS_WAITING, BUSINESS_WAITING_UPDATE } from '../../../../../constant/keys'
import { DEV_SV, QC_SV, PROD_SV, SBX_SV } from '../../../../../constant/environmentTypes'

// component
import BusinessWaiting from './BusinessWaiting'
import BusinessReject from './BusinessReject'

// Control
import TabListMenu from '../../../../Control/TabListMenu'

// redux 
import { resetCondFiltersBusiness, resetListBusiness } from '../../../../../redux/business/listBusinessManage/action';
import BusinessWaitingUpdate from './BusinessWaitingUpdate'


const dataMenu = [
  {
      title: 'Chờ kiểm duyệt',
      key: BUSINESS_WAITING,
      component: {
          type: 'BUSINESS_WAITING',
          comp: <BusinessWaiting />
      },
      envServerValid: [
          DEV_SV, QC_SV, PROD_SV, SBX_SV
      ]
  },
  {
    title: 'Chờ cập nhật',
    key: BUSINESS_WAITING_UPDATE,
    component: {
        type: 'BUSINESS_WAITING_UPDATE',
        comp: <BusinessWaitingUpdate />
    },
    envServerValid: [
        DEV_SV, QC_SV, PROD_SV, SBX_SV
    ]
  },
  {
      title: 'Từ chối',
      key: BUSINESS_REJECT,
      component: {
          type: 'BUSINESS_REJECT',
          comp: <BusinessReject />
      },
      envServerValid: [
          DEV_SV, QC_SV, PROD_SV, SBX_SV
      ]
  },
]


const BusinessManage = () => {

  const dispatch = useDispatch()

  const [component, setComponent] = useState(null)
  
  const _handleChangeTab = (component) => {
    setComponent(component.comp)
    dispatch(resetCondFiltersBusiness())
    dispatch(resetListBusiness())
  }
  
  return (
    <WrapBusinessManage>
      <div className='tab-menu'>
        <TabListMenu componentSelected={_handleChangeTab} data={dataMenu} />
      </div>
      {component}
    </WrapBusinessManage>
  )
}

export default BusinessManage