import React from 'react'

const IceKYCGray = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#B7B7B7"/>
        <path d="M17.9993 6.66797H5.99935C5.26297 6.66797 4.66602 7.26492 4.66602 8.0013V16.0013C4.66602 16.7377 5.26297 17.3346 5.99935 17.3346H17.9993C18.7357 17.3346 19.3327 16.7377 19.3327 16.0013V8.0013C19.3327 7.26492 18.7357 6.66797 17.9993 6.66797Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.1465 8.66797H7.1855C6.89859 8.66797 6.66602 8.89183 6.66602 9.16797V12.168C6.66602 12.4441 6.89859 12.668 7.1855 12.668H10.1465C10.4334 12.668 10.666 12.4441 10.666 12.168V9.16797C10.666 8.89183 10.4334 8.66797 10.1465 8.66797Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.3327 14.668H12.666" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.3327 12H12.666" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.3327 9.33594H12.666" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IceKYCGray
