import React from 'react'

const IcRefreshBacklog = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#B7B7B7"/>
        <path d="M19.334 17.332V13.332H15.334" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.66602 6.66797V10.668H8.66602" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.6593 9.99842C17.3212 9.04295 16.7466 8.18869 15.989 7.51537C15.2315 6.84205 14.3157 6.3716 13.3272 6.14793C12.3386 5.92426 11.3095 5.95465 10.3359 6.23627C9.36226 6.51789 8.47585 7.04157 7.75935 7.75842L4.66602 10.6651M19.3327 13.3318L16.2393 16.2384C15.5229 16.9553 14.6364 17.479 13.6628 17.7606C12.6892 18.0422 11.6601 18.0726 10.6715 17.8489C9.68299 17.6252 8.76722 17.1548 8.00966 16.4815C7.2521 15.8082 6.67746 14.9539 6.33935 13.9984" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcRefreshBacklog
