
// wallet facepay
export const GET_LIST_TRANSACTION_PACEPAY_WALLET = 'GET_LIST_TRANSACTION_PACEPAY_WALLET'
export const GET_LIST_TRANSACTION_PACEPAY_WALLET_ERR = 'GET_LIST_TRANSACTION_PACEPAY_WALLET_ERR'

export const UPDATE_CONDFILTERS_TRANSACTION_FACEPAY_WALLET = "UPDATE_CONDFILTERS_TRANSACTION_FACEPAY_WALLET"
export const RESET_CONDFILTERS_TRANSACTION_FACEPAY_WALLET = "RESET_CONDFILTERS_TRANSACTION_FACEPAY_WALLET"

export const EXPORT_FILE_TRANSACTION_FACEPAY_WALLET = "EXPORT_FILE_TRANSACTION_FACEPAY_WALLET"
export const EXPORT_FILE_TRANSACTION_FACEPAY_WALLET_ERR = "EXPORT_FILE_TRANSACTION_FACEPAY_WALLET_ERR"
