import React from "react";
import { useSelector } from 'react-redux'

//style
import { WrapWaitingCensorDetailsItem } from './index.styles'

//data
import { convertDateTime } from '../../../../../../../helper/convertDateTime'

//image
import IcUserBlueDefault from '../../../../../../../assets/images/IcUserBlueDefault'
import IcPhoneBlueDefault from '../../../../../../../assets/images/IcPhoneBlueDefault'
import IcMailBlueDefault from '../../../../../../../assets/images/IcMailBlueDefault'

//data
import { PENDING, REPENDING } from '../../../../../../../data/dataCensorshipStatus'

//component
import FaceRecognizeInfos from "../../FaceRecognizeInfos";
import CensorshipHistoryItem from "./CensorshipHistoryItem";
import IcUserRegis from "../../../../../../../assets/images/IcUserRegis";
import IcRefreshWaiting from "../../../../../../../assets/images/IcRefreshWaiting";

const WaitingCensorDetailsItem = ({ data }) => {

    return (
        <WrapWaitingCensorDetailsItem>
            <div className="wrap-container">
                <div className="wrap-infos">
                    <div className="info">
                        {
                            data?.user?.status === PENDING ?
                                <IcUserRegis className="icon" />
                                :
                                data?.user?.status === REPENDING ?
                                    <IcRefreshWaiting className="icon" />
                                    : null
                        }
                        <span className="name">Document ID:</span>
                        <span className="value" title={data?.user?.documentId || ""}>
                            {data?.user?.documentId || "-"}
                        </span>
                    </div>
                    <div className="info">
                        <span className="name">Biometirc ID:</span>
                        <span className="value" title={data?.user?.bioId || ""}>
                            {data?.user?.bioId || "-"}
                        </span>
                    </div>
                    <div className="info">
                        <span className="name">Thời gian gửi:</span>
                        <span className="value" title={convertDateTime(data?.user?.submitTime, "HH:mm - DD/MM/YYYY") || ""}>
                            {data?.user?.submitTime ? convertDateTime(data?.user?.submitTime, "HH:mm - DD/MM/YYYY") : "-"}
                        </span>
                    </div>
                </div>
                <div className="wrap-details">
                    <div className="title">Thông tin khách hàng</div>
                    <div className="row-detail">
                        <div className="row">
                            <IcUserBlueDefault className="ic-default" />
                            <div className="detail">
                                <p className="value" title={data?.user?.fullName || ""}>{data?.user?.fullName || "-"}</p>
                                <p className="name">Họ tên</p>
                            </div>
                        </div>
                        <div className="row">
                            <IcPhoneBlueDefault className="ic-default" />
                            <div className="detail">
                                <p className="value" title={data?.user?.phone || ""}>{data?.user?.phone || "-"}</p>
                                <p className="name">Số điện thoại</p>
                            </div>
                        </div>
                        <div className="row">
                            <IcMailBlueDefault className="ic-default" />
                            <div className="detail">
                                <p className="value" title={data?.user?.email || ""}>{data?.user?.email || "-"}</p>
                                <p className="name">Email</p>
                            </div>
                        </div>

                    </div>
                </div>
                <FaceRecognizeInfos thumbnailUrl={data?.user?.thumbnailUrl} fullFaceUrl={data?.user?.fullFaceUrl} listSameFace={data?.user?.sameFaceObjList} />
                {
                    data?.rejectHistories?.length !== 0 && !!data?.rejectHistories &&
                    <div className="wrap-list-history">
                        <p className="title-history">Lịch sử kiểm duyệt ({data?.rejectHistories?.length || 0})</p>
                        {
                            data?.rejectHistories?.map((item, index) =>
                                <CensorshipHistoryItem item={item} key={index} />
                            )
                        }
                    </div>
                }
            </div>
        </WrapWaitingCensorDetailsItem>
    )
}

export default WaitingCensorDetailsItem
