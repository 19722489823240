import React from 'react'

const IcArrowRightBlack = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 7.5L5 12V3L10 7.5Z" fill="#313131" />
        </svg>
    )
}

export default IcArrowRightBlack
