import React from 'react'

const IcSearchDefault = (props) => {
    return (
        <svg {...props} width={101} height={100} viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="50.5" cy={50} r={50} fill="#B7B7B7" />
            <path d="M48.1667 66.3333C58.476 66.3333 66.8333 57.976 66.8333 47.6667C66.8333 37.3574 58.476 29 48.1667 29C37.8574 29 29.5 37.3574 29.5 47.6667C29.5 57.976 37.8574 66.3333 48.1667 66.3333Z" fill="white" stroke="#B7B7B7" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M71.4996 71.0016L61.3496 60.8516" stroke="#B7B7B7" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcSearchDefault
