import styled from 'styled-components'

// styles
import { WrapItem } from '../../../MerchantDetails/ListStores/StoreItem/index.styles';


const WrapDeviceItemByList = styled(WrapItem)`

    &:hover {
        .wrap-status {
            .wrap-device-infos {
                display: block;
            }
        }
    }
    :hover{
        padding: 9px 10px 9px 19px;
    }
    
    .wrap-status {
        position: relative;
        justify-self: flex-start;
        display: flex;
        align-items: center;
        gap: 6px;

        & > .status {
            width: 22px;
            height: 22px;
            background-color: ${props => props.colorStatus};
        }

        .wrap-device-infos {
            position: absolute;
            /* visibility: ${props => props.isVisible?'visible':'hidden'}; */
            top: ${props => props.posPopup.top};
            left: ${props => props.posPopup.left};
            right: ${props => props.posPopup.right};
            bottom: ${props => props.posPopup.bottom};
            display: none;
            z-index: 99;
        }
    }
    .image-merchant{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        img{
            height: 100%;
            border-radius: 50%;
        }
    }
    .dropdown-feature{
        position: relative;

        .icon{
            cursor: pointer;
        }
    }
    .serial{
        direction: rtl;
        text-align: right;
        margin-right: 15px;
    }
`;

export {
    WrapDeviceItemByList
}