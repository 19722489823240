import React from 'react'

const IcBusinessIdentify = (props) => {
  return (
    <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width={32} height={32} rx={16} fill="#EFF9FF" />
      <path d="M22.75 10.5H9.25C8.42157 10.5 7.75 11.1716 7.75 12V21C7.75 21.8284 8.42157 22.5 9.25 22.5H22.75C23.5784 22.5 24.25 21.8284 24.25 21V12C24.25 11.1716 23.5784 10.5 22.75 10.5Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.9156 12.75H10.5844C10.2617 12.75 10 13.0018 10 13.3125V16.6875C10 16.9982 10.2617 17.25 10.5844 17.25H13.9156C14.2383 17.25 14.5 16.9982 14.5 16.6875V13.3125C14.5 13.0018 14.2383 12.75 13.9156 12.75Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 19.5H16.75" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 16.5H16.75" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 13.5H16.75" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default IcBusinessIdentify