import React from "react";
import { WrapInfoKycPASSPORT } from "./index.styled";

// image
import IcInfoCustom from '../../../../../../../../../assets/images/IcInfoCustom'
import IcUserBlueDefault from '../../../../../../../../../assets/images/IcUserBlueDefault'
import IcCalendaDefault from '../../../../../../../../../assets/images/IcCalendaDefault'
import IcLocationDefault from '../../../../../../../../../assets/images/IcLocationDefault'
import IcSexDefault from '../../../../../../../../../assets/images/IcSexDefault'

// data
import { dataGender } from "../../../../../../../../../data/dataCustomer";

const InfoKycPASSPORT = ({ item }) => {

  return (
    <WrapInfoKycPASSPORT>
      <div className="info-item">
        <IcInfoCustom />
        <div className="info-value">
          <div className="value">{item.ekycInfoPassport.id || "-"}</div>
          <div className="text">Số giấy tờ</div>
        </div>
      </div>
      <div className="info-item">
        <IcInfoCustom />
        <div className="info-value">
          <div className="value">{item.ekycInfoPassport.issue_date || "-"}</div>
          <div className="text">Ngày cấp</div>
        </div>
      </div>
      <div className="info-item">
        <IcUserBlueDefault />
        <div className="info-value">
          <div className="value">{item.ekycInfoPassport.name || "-"}</div>
          <div className="text">Họ tên</div>
        </div>
      </div>
      <div className="info-item">
        <IcInfoCustom />
        <div className="info-value">
          <div className="value">{item.ekycInfoPassport.due_date || "-"}</div>
          <div className="text">Ngày hết hạn</div>
        </div>
      </div>
      <div className="info-item">
        <IcCalendaDefault />
        <div className="info-value">
          <div className="value">{item.ekycInfoPassport.dob || "-"}</div>
          <div className="text">Ngày sinh</div>
        </div>
      </div>
      <div className="info-item">
        <IcLocationDefault />
        <div className="info-value">
          <div className="value">{item.ekycInfoPassport.issued_at || "-"}</div>
          <div className="text">Nơi cấp</div>
        </div>
      </div>
      <div className="info-item">
        <IcSexDefault />
        <div className="info-value">
          <div className="value">{dataGender[item.ekycInfoPassport.gender.toUpperCase()]?.text || "-"}</div>
          <div className="text">Giới tính</div>
        </div>
      </div>
      <div className="info-item">
        <IcInfoCustom />
        <div className="info-value">
          <div className="value">{item.ekycInfoPassport.person_number || "-"}</div>
          <div className="text">Số giấy tờ tuỳ thân</div>
        </div>
      </div>
      <div className="info-item">
        <IcInfoCustom />
        <div className="info-value">
          <div className="value">{item.ekycInfoPassport.nationality || "-"}</div>
          <div className="text">Quốc tịch</div>
        </div>
      </div>    
      <div className="info-item">
        <IcInfoCustom />
        <div className="info-value">
          <div className="value">{item.ekycInfoPassport.place_of_birth || "-"}</div>
          <div className="text">Nơi sinh</div>
        </div>
      </div>
    </WrapInfoKycPASSPORT>
  );
};

export default InfoKycPASSPORT;
