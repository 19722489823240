import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';


//types
import * as types from './types'

const apiGetCensorshipCount = `${host}/facepay/users/count`

export const getCensorshipCount = () => async dispatch => {
    try {
        const res = await Axios.post(apiGetCensorshipCount)
        const { data } = res;
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.GET_CENSORSHIP_COUNT,
                payload: {
                    total: data.data || {}
                }
            })
        }
        else {
            dispatch({
                type: types.GET_CENSORSHIP_COUNT_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.GET_CENSORSHIP_COUNT_ERR))
    }
}

