import Axios from 'axios'
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import { hostCustomer } from '../../../constant/host'
import autoDownloadFromBlob from '../../../helper/autoDownloadFromBlob'
import * as types from './type'
import mapErrors from '../../../helper/mapErrors';

const apiGetListTransactionBank = `${hostCustomer}/transaction/bankandterminal`
const apiExportListTransactionBank = `${hostCustomer}/transaction/reportbankandteminal`


let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let isCalling = false
let lastQueryApiGetListTransactionBank = ""

const dataRequestApi = {
    limit: "",
    lastItemId: "",
    facepayTrxCode: "",
    bankTrxCode: "",
    merchantTrxCode: "",
    profileId: "",
    phoneNumber: "",
    code: "",
    storeId: ["*"],
    success: "",
    failed: "",
    isRefunded: "",
    refund: "",
    noRefund: "",
    promotion: "",
    noPromotion: "",
    supplier: [],
    begin: "",
    end: "",  
}

export const getListTransactionBank = (
    data = dataRequestApi,
    isScroll,
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    lastQueryApiGetListTransactionBank = headerDataRequest.reqId

    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiGetListTransactionBank, dataRequest)
        const { data } = res

        if(lastQueryApiGetListTransactionBank !== dataRequest.reqId) return

        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_TRANSACTION_BANK,
                payload: {
                    listTransactionBank: data.data.list || [],
                    totalBank: data.data.total,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_LIST_TRANSACTION_BANK_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) { 
        dispatch(mapErrors(error, types.GET_LIST_TRANSACTION_BANK_ERR))
    }
    isCalling = false
}

export const updateListTransactionBank = (
    condFiltersTransactionBank = dataRequestApi,
    callbackSuccess
) => async dispatch => {
    dispatch({
        type: types.UPDATE_LIST_TRANSACTION_BANK,
        condFiltersTransactionBank
    })
    callbackSuccess && callbackSuccess()
}


export const resetListTransactionBank = (callbackSuccess) => async dispatch => {
    dispatch({type: types.RESET_LIST_TRANSACTION_BANK})
    callbackSuccess && callbackSuccess()
}

export const resetTransactionBankCondfilters  = (callbackSuccess) => async dispatch => {
    dispatch({type: types.RESET_TRANSACTION_BANK_CONDFILTERS})
    callbackSuccess && callbackSuccess()
}

export const exportListTransactionBank = (
    data = {
        isFaccepay: "",
        facepayTrxCode: "",
        bankTrxCode: "",
        merchantTrxCode: "",
        profileId: "",
        merchantCode: "",
        supplier: "",
        transAmount:"",
        createdAt: "",
        codePromotion: "",
        refund: "",
        begin: "",
        end: ""
    },
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiExportListTransactionBank, dataRequest, {
            responseType: 'blob'
        })
        const { data } = res
        autoDownloadFromBlob(data, 'transaction')
        callbackSuccess && callbackSuccess()
    } catch (error) {
        dispatch(mapErrors(error, types.EXPORT_LIST_TRANSACTION_BANK_ERR))
        callbackError && callbackError()
    }
}

// show popup
export const showPopupExportTransactionBank = () => async dispatch => {
    dispatch({type: types.SHOW_POPUP_EXPORT_TRANSACTION_BANK})
}

// hide popup
export const hidePopupExportTransactionBank = () => async dispatch => {
    dispatch({type: types.HIDE_POPUP_EXPORT_TRANSACTION_BANK})
}