import styled from 'styled-components'

const InputFilter = styled.input`
    margin-left: 6px;
    outline: none;
    border: none;
    padding: 0;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #313131;
    flex-grow: 1;
    &::placeholder{
        color: #B7B7B7;
    }
`;

const WrapFilterControl = styled.div`
    display: flex;
    align-items: center;
    border-radius: 33px;
    border: 0.5px solid #B7B7B7;
    box-sizing: border-box;
    padding: 9px 14px;
    min-width: 152px;
    height: 36px;
    .icon{
        display: flex;
        align-items: center;
    }
    .ic-arrow-right {
        margin-left: 6px;

        &:hover {
            cursor: pointer;
        }
    }
`;

export {
    InputFilter,
    WrapFilterControl
}