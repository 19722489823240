import styled from 'styled-components'

const WrapSelectDate = styled.button`
    outline: none;
    border: none;
    background: none;
    width: 100%;
    text-align: left;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13.5px;
    line-height: 18px;
    letter-spacing: -0.01em;
    width: 80px;

    &.have-value {
        color: #313131;
    }

    &.empty {
        color: #33ADFF;
    }

    &:hover {
        cursor: pointer;
    }
`;

const WrapDatePickerItem = styled.div`
    .react-datepicker-popper[data-placement^=bottom] {
        z-index: 10;
    }
    .react-datepicker__header{
        background-color: #fff;
        border: none;
    }
    .react-datepicker__day-names{
        background: #F7F7F7;
    }
    .header-calendar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 11px;
        button{
            outline: none;
            border: none;
            cursor: pointer;
            background-color: #fff;
        }
        .title-header{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #313131;
        }
    }
`

export {
    WrapSelectDate,
    WrapDatePickerItem
}