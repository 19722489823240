import styled from 'styled-components'

const WrapListNote = styled.div`
    width: 320px;
    height: 624px;
    background: #FFFFFF;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #E7E7E7;
    .wrap-header{
        height: 46px;
        background: #FFFFFF;
        border-bottom: 1px solid #E7E7E7;
        padding: 14px 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            color: #313131;
        }
        .ic-chevron{
            cursor: pointer;
        }
    }
    .wrap-block-content{
        padding: 12px 16px 0 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: calc(100% - 12px);
        position: relative;
        .wrap-empty {            
            width: 100%;
            height: 100%;
            position: absolute;
            top: 200px;
            left: 50%;
            transform: translate(-50%, -50%);            
            .icon{
                width: 88px;
                height: 88px;
            }
            p{
                color: #B7B7B7;
                font-size: 13.5px;
            }
        }
        .wrap-list-note {
            width: 100%;
            flex-grow: 1;
            overflow: overlay;
            position: relative; 
            margin-left: 10px;

            .list-note {
                width: calc(100% - 10px);
                display: flex;
                flex-direction: column;
                position: absolute;
            }
        }
    }
    .wrap-input-text-area{
        background: #FFFFFF;
        border-top: 1px solid #E7E7E7;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        box-sizing: border-box;
        .input-text-area{
            position: relative;
            width: 242px;
            border-radius: 8px;
            box-sizing: border-box;
            background: #F7F7F7;
            display: flex;
            align-items: center;
            justify-content: center;
            &.url{
                height: 54px;
            }
           
            .wrap-attachment{
                box-sizing: border-box;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                background: #EBEBEB;
                border-radius: 4px;
                padding: 8px 10px; 
                box-sizing: border-box;
                width: 226px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .image{
                    width: 18px;
                    height: 18px;
                    z-index: 2;
                }
                .text{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 160px;
                    box-sizing: border-box;
                }
                .remove{
                    cursor: pointer;
                }
            }
            .text-area{
                width: 242px;
                min-height: 42px;
                max-height: 96px;
                border: none;
                outline: none;
                resize: none;
                border-radius: 8px;
                padding: 12px 8px 12px 12px;
                box-sizing: border-box;
                background: #F7F7F7;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
                position: relative;    
                ::placeholder{
                    color: #B7B7B7;
                }
            }           
        }
        .wrap-btn{
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-bottom: 10px;
            &.disabled{
                pointer-events: none;
            }
            .ic-paperclip{
                &.disabled{
                    opacity: 0.3;
                }
            }
        }
    }
`
export { WrapListNote }