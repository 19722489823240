import styled from 'styled-components'

const Bound = styled.div`   
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .info-expand{
        display: flex;
        padding: 14px 20px 0 20px;
        flex-grow: 1;
        flex-direction: column;
    }
`



export {
    Bound
}