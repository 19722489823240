import styled from 'styled-components'

const WrapFaceRecognizeInfo = styled.div`   
    background: #FFFFFF;
    height: 295px;
    box-sizing: border-box;
    position: relative;
    padding: 9px 17px 12px 20px;
    .title-value{
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #313131;
    }
    .title-img{
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #313131;
        .value{
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #313131;
        }
    }
    .title-face{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px; 
    }
    .face-infos {        
        display: flex;
        flex-direction: column;
        .face-registration {
            display: flex;
            .wrap-media {
                position: relative;
                width: 228px;
                img {
                    border-radius: 4px;
                    display: block;
                    width: 100%;
                    object-fit: cover;
                    box-sizing: border-box;
                    height: 228px;
                }
                .wrapper{
                    width: 228px;
                    height: 228px;
                    background: #F7F7F7;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;   
                    pointer-events: auto;
                    cursor: auto;
                }
                &.face-img {
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.recog-video {
                    margin-left: 30px;
                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.4);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
`
export { WrapFaceRecognizeInfo }