import React from 'react'

const IcWifi = (props) => {
  return (
    <svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 13H7.50625" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.3313 10.5688C5.9658 10.118 6.72484 9.87579 7.50317 9.87579C8.2815 9.87579 9.04055 10.118 9.67505 10.5688" stroke="#33ADFF" strokeWidth="2" strokeLinejoin="round"/>
        <path d="M3.125 8.34377C4.36035 7.31481 5.91725 6.75134 7.525 6.75134C9.13275 6.75134 10.6896 7.31481 11.925 8.34377" stroke="#33ADFF" strokeWidth="2" strokeLinejoin="round"/>
        <path d="M0.887451 6.12499C2.71397 4.51497 5.06514 3.62665 7.49995 3.62665C9.93477 3.62665 12.2859 4.51497 14.1125 6.12499" stroke="#33ADFF" strokeWidth="2" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcWifi