import React from 'react'

const IcLocationDefault = (props) => {
    return (
        <svg {...props} width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6062_61191)">
        <circle cx="18" cy="18.5" r="18" fill="#EFF9FF"/>
        <g clipPath="url(#clip1_6062_61191)">
        <path d="M25.6496 16.8023C25.6496 22.7523 17.9996 27.8523 17.9996 27.8523C17.9996 27.8523 10.3496 22.7523 10.3496 16.8023C10.3496 14.7734 11.1556 12.8276 12.5902 11.393C14.0249 9.95832 15.9707 9.15234 17.9996 9.15234C20.0285 9.15234 21.9743 9.95832 23.409 11.393C24.8436 12.8276 25.6496 14.7734 25.6496 16.8023Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.0002 19.35C19.4085 19.35 20.5502 18.2083 20.5502 16.8C20.5502 15.3917 19.4085 14.25 18.0002 14.25C16.5919 14.25 15.4502 15.3917 15.4502 16.8C15.4502 18.2083 16.5919 19.35 18.0002 19.35Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_6062_61191">
        <rect width="36" height="36" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        <clipPath id="clip1_6062_61191">
        <rect width="20.4" height="20.4" fill="white" transform="translate(7.7998 8.30078)"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default IcLocationDefault
