import styled from 'styled-components'

// styles
import { WrapTransHistoryItem } from '../../TransactionHistory/TransHistoryItem/index.styles';

const WrapCustomerItem = styled(WrapTransHistoryItem)`
    .wrap-infos {
        .general-infos {
            .left-infos {
                .wrap-id-trans {
                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }

            .payment-time {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
            }
        }
        &.payment-location-infos {
            .row {
                &.payment-location-row {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: inherit;
                    .payment-value {
                        display: flex;
                        .value-item{
                            margin-left: 44px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            .value {
                                font-size: 15px;
                            }
                        }
                        .value-bank{
                            font-size: 15px;
                            text-align: left;
                            line-height: 27px;
                            color: #313131;
                            font-weight: 600;
                            letter-spacing: -0.01em;
                        }
                        .wrap-logo{
                            display: flex;
                            margin-right: -35px;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }

`;

export {
    WrapCustomerItem
}