import React from 'react'
import Input from '../../../../../../../../../../../Control/Input';

export default function InputPlaceOfIssue({value, handleValidationUser, handleSetValueName, className}) {

    const _handleChangeValue = (event) => {
      const { value } = event.target;
      handleSetValueName && handleSetValueName(value)
    }
  return (
    <Input
        inputType="TEXT"
        value={value}
        onChange={_handleChangeValue}
        placeholder="Nơi sinh"
        handleValidationUser={handleValidationUser}
        className={className}
    />
  )
}
