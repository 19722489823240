import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//style
import { WrapRejectionProfile, WrapRejectionProfileList, TableRejectionProfileList, TableHeader, TableContent } from './index.styles'

//data
import { headerRejectionProfile, listRejectionProfile, listNote } from '../../../../../../data/dataRejectionProfile'

//image
import IcCensorshipDefault from '../../../../../../assets/images/IcCensorshipDefault'

//component
import EmptyDataControl from '../../../../../Control/EmptyDataControl'
import PageHeader from '../../PageHeader'
import ItemRejectionProfile from './ItemRejectionProfile'
import FilterRejectProfile from './FilterRejectProfile'

//action
import { getListRejectProfile, updateRejectCondsFilter } from '../../../../../../redux/censorship/listRejectProfile/action'
import { resetListWaitingCensorship, resetWaitingCondsFilter } from '../../../../../../redux/censorship/listWaitingCensorship/action'

//helper
import useScrollEnd from '../../../../../../hook/useScrollEnd'


const RejectionProfile = () => {

    const dispatch = useDispatch()

    const listRef = useRef()

    const { list, total, condFilters, isScroll } = useSelector(state => ({
        list: state.censorshipReducer.listRejectProfileReducer.list,
        total: state.censorshipReducer.listRejectProfileReducer.total,
        condFilters: state.censorshipReducer.listRejectProfileReducer.condFilters,
        isScroll: state.censorshipReducer.listRejectProfileReducer.isScroll
    }))

    useEffect(() => {
        dispatch(resetListWaitingCensorship())
        dispatch(resetWaitingCondsFilter())

        return () => {

        }
    }, [])

    useEffect(() => {
        dispatch(getListRejectProfile({
            lastItem: condFilters.lastItem,
            limit: condFilters.limit,
            phone: condFilters.phone,
            documentId: condFilters.documentId,
            startTime: condFilters.startTime,
            endTime: condFilters.endTime,
            isRejectOnce: condFilters.isRejectOnce,
            isRejectGreaterOne: condFilters.isRejectGreaterOne
        }, isScroll))
        return () => {

        }
    }, [JSON.stringify(condFilters)])

    useScrollEnd(listRef, (callback) => {
        dispatch(updateRejectCondsFilter({
            condFilters: {
                lastItem: list[list.length - 1].id,
                limit: 15
            },
            isScroll: true,
            callbackSuccess: callback
        }))
    }, [list.length], [list.length])

    return (
        <WrapRejectionProfile>
            <div className="filter">
                <FilterRejectProfile />
            </div>
            <WrapRejectionProfileList>
                <div className="wrap-page-header">
                    <p className="title-header">Danh sách tài khoản ({total ? total : 0})</p>
                    <div className="list-note">
                        {
                            listNote.map((item, i) => (
                                <div className="note" key={i}>
                                    {item.icon && item.icon}
                                    <p>{item.text}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <TableRejectionProfileList>
                    {
                        list?.length === 0 ?
                            <div className="wrap-empty">
                                <EmptyDataControl
                                    icon={<IcCensorshipDefault />}
                                    text={"Hiện tại chưa có hồ sơ mới"}
                                />
                            </div>
                            :
                            <>
                                <TableHeader>
                                    {
                                        headerRejectionProfile.map((item, i) => (
                                            <div className="table-header" key={i}>
                                                {item}
                                            </div>
                                        ))
                                    }
                                </TableHeader>
                                <TableContent>
                                    <div className="wrap-list-refuse" ref={listRef}>
                                        <div className="list-refuse">
                                            {
                                                list?.map((item, i) => (
                                                    <ItemRejectionProfile key={item.id} data={item} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </TableContent>
                            </>
                    }
                </TableRejectionProfileList>
            </WrapRejectionProfileList>
        </WrapRejectionProfile>
    )
}

export default RejectionProfile
