import styled from 'styled-components'

const WrapPageItem = styled.div`
    padding: 0 30px;
    width: calc(1280px - 100px);
    box-sizing: border-box;
`

const WrapCustomerList = styled(WrapPageItem)`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
`

const TableCustomerList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    .wrap-empty {
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
    }
`

const TableHeaderCustomer = styled.div`    
    display: grid;
    grid-template-columns: 
        minmax( 87px,97px)  
        minmax( 96px,106px)  
        minmax( 71px,81px)  
        minmax( 120px,160px)  
        minmax( 130px,1fr)
        minmax( 135px,145px)  
        minmax( 111px,121px) 
        minmax( 75px,85px);
    grid-gap: 5px; 
    padding: 0 12px 0 18px;
    box-sizing: border-box;
    width: 100%;
    .table-header {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #313131;
    }

`

const TableContentCustomer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;    
   
    .wrap-list-customer {
        /* width: 100%; */
        width: calc(100% + 10px);
        flex-grow: 1;
        overflow: auto;
        margin-top: 11px;
        margin-left: 10px;
        position: relative;
        .list-customer {
            /* width: 100%; */
            width: calc(100% - 10px);
            display: flex;
            flex-direction: column;
            position: absolute;
        }
    }
    
`

export { WrapPageItem, WrapCustomerList, TableCustomerList, TableHeaderCustomer, TableContentCustomer }