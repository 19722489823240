import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

//style
import { WrapListNote } from './index.styles'

//image
import IcChevronLeft from '../../../../../../../assets/images/IcChevronLeft'
import IcCloseGray from '../../../../../../../assets/images/IcCloseGray'
import IcFilePdf from '../../../../../../../assets/images/IcFilePdf'
import IcFileWord from '../../../../../../../assets/images/IcFileWord'
import IcMessageDefault from '../../../../../../../assets/images/IcMessageDefault'
import IcPaperClip from '../../../../../../../assets/images/IcPaperclip'

//component
import DragAndDrop from '../../../../../../Control/DragAndDrop'
import EmptyDataControl from '../../../../../../Control/EmptyDataControl'
import TextArea from '../../../../../../Control/TextArea'
import fileToBase64 from '../../../../../../../helper/fileToBase64'
import { businessAddNote, getListNoteBusiness } from '../../../../../../../redux/business/listBusinessManage/action'
import ItemNote from './ItemNote'
import IcFileImage from '../../../../../../../assets/images/IcFileImage'
import useScrollEnd from '../../../../../../../hook/useScrollEnd'
import useScrollStart from '../../../../../../../hook/useScrollStart'

const IMG = "IMG"
const WORD = "WORD"
const PDF = "PDF"

const MAX_SIZE = 5 * 1024 * 1024 // 5MB
const validFormatImg = ["png", "jpg", "jpeg"]
const validFormatWord = ["doc", "docx"]
const validFormatPdf = ["pdf"]

const BusinessNote = ({ setIsShowPopup }) => {

    const inputRef = useRef(null);
    const dispatch = useDispatch()
    const listRef = useRef()
    const refPopupBusinessNote = useRef(null)
    const scrollRef = useRef(null)


    const { businessInfo, userInfo, listNoteBusiness } = useSelector((state) => ({
        businessInfo: state.businessReducer.listBusinessManage.businessDetail.businessInfo,
        userInfo: state.businessReducer.listBusinessManage.businessDetail.userInfo,
        listNoteBusiness: state.businessReducer.listBusinessManage.listNoteBusiness
    }))

    const [valueTextArea, setValueTextArea] = useState('')
    const [urlImg, setUrlImg] = useState("");
    const [urlFile, setUrlFile] = useState({});
    const [checkFileType, setCheckFileType] = useState("")

    useEffect(() => {
        if (!urlFile.name) return
        if (validFormatImg.includes(urlFile.name.split('.').pop())) {
            setCheckFileType(IMG)
            return
        }
        if (validFormatWord.includes(urlFile.name.split('.').pop())) {
            setCheckFileType(WORD)
            return
        }
        if (validFormatPdf.includes(urlFile.name.split('.').pop())) {
            setCheckFileType(PDF)
            return
        }
    }, [urlFile, urlImg])

    useEffect(() => {
        const inputFile = inputRef.current = document.createElement('input');
        inputFile.type = 'file';
        inputFile.onchange = (e) => {
            _handleDropFiles(e.target.files)
        }
    }, [])

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(urlImg)
        }
    }, []);

    let observe;

    useEffect(() => {
        if (urlImg) return
        if (window.attachEvent) {
            observe = function (element, event, handler) {
                element.attachEvent('on' + event, handler);
            };
        }
        else {
            observe = function (element, event, handler) {
                element.addEventListener(event, handler, false);
            };
        }
        const init = () => {
            var text = document.getElementById('id-text');
            function resize() {
                text.style.height = '42px';
                text.style.height = text.scrollHeight + 'px';
            }
            /* 0-timeout to get the already changed text */
            function delayedResize(e) {
                if (e.key === "Enter") {
                    e.preventDefault()
                    return
                }
                window.setTimeout(resize, 0);
            }
            observe(text, 'change', resize);
            observe(text, 'cut', delayedResize);
            observe(text, 'paste', delayedResize);
            observe(text, 'drop', delayedResize);
            observe(text, 'keydown', delayedResize);
            text.focus();
            text.select();
            resize();
        }
        init()
    }, [urlImg]);

    const _handleClickUploadFile = () => {
        inputRef.current.click()
    }

    const _handleCreateImgUrl = async (files) => {
        if (urlImg) {
            URL.revokeObjectURL(urlImg);
        }
        try {
            const url = URL.createObjectURL(files[0]);
            setUrlImg(url)
            setUrlFile(files[0]);
        } catch (err) {
            setUrlImg("");
        }
    }

    const _handleDropFiles = (files) => {
        if (files.length !== 1) {
            inputRef.current.value = '';
            return;
        }
        const file = files[0]
        const { type, size } = file
        const validFormat = ["png", "jpg", "jpeg", "pdf", "msword", "vnd.openxmlformats-officedocument.wordprocessingml.document"]

        if (validFormat.includes(type.split("/")[1])) {
            if (size > MAX_SIZE) {
                inputRef.current.value = '';
                dispatch({
                    type: "MAX_SIZE_ERR",
                    payload: {
                        code: "MAX_SIZE_ERR"
                    }
                })
                return
            }
            _handleCreateImgUrl(files)
            inputRef.current.value = '';
            return
        }
        inputRef.current.value = '';
    }

    const _handleRemoveFile = () => {
        setValueTextArea("")
        setUrlImg("")
        setUrlFile({})
        setCheckFileType("")
    }

    const _handleSubmitNote = async () => {
        if (urlFile.name) {
            const base64 = await fileToBase64(urlFile.name ? urlFile : {})
            const dataRequest = {
                adminID: userInfo.profileID,
                adminUserName: businessInfo.censorshipAdmin,
                createdAt: dayjs(),
                businessID: businessInfo.id,
                text: "",
                attachment: {
                    fileName: urlFile.name,
                    fileData: base64.split(',')[1]
                }
            }
            const callbackSuccess = () => {
                dispatch(getListNoteBusiness({
                    businessID: businessInfo.id,
                    limit: listNoteBusiness.length + 1,
                    lastItem: ""
                }))
                setValueTextArea("")
                setUrlImg("")
                setUrlFile({})
                setCheckFileType("")
            }
            dispatch(businessAddNote(dataRequest, callbackSuccess))
        }
        if (valueTextArea) {
            const dataRequest = {
                adminID: userInfo.profileID,
                adminUserName: businessInfo.censorshipAdmin,
                createdAt: dayjs(),
                businessID: businessInfo.id,
                text: valueTextArea,
                attachment: {
                    fileName: "",
                    fileData: ""
                }
            }
            const callbackSuccess = () => {
                var text = document.getElementById('id-text');
                text.style.height = '42px';
                dispatch(getListNoteBusiness({
                    businessID: businessInfo.id,
                    limit: listNoteBusiness.length + 1,
                    lastItem: ""
                }, false))
                setValueTextArea("")
                setUrlImg("")
                setUrlFile({})
                setCheckFileType("")
            }
            dispatch(businessAddNote(dataRequest, callbackSuccess))
        }
    }

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [valueTextArea]);

    useEffect(() => {
        const onEnter = (e) => {
            if (e.key === "Enter") {
                _handleSubmitNote()
            }
        }
        window.addEventListener("keydown", onEnter)
        return () => {
            window.removeEventListener("keydown", onEnter)
        }
    }, [urlFile, valueTextArea])

    useScrollStart(listRef, (callback) => {
        dispatch(getListNoteBusiness({
            businessID: businessInfo.id,
            limit: 15,
            lastItem: listNoteBusiness[0].id
        }, true, callback))
    }, [listNoteBusiness], [listNoteBusiness.length])

    return (
        <WrapListNote>
            <div className='wrap-header'>
                <p className='header'>Ghi chú</p>
                <IcChevronLeft className='ic-chevron' onClick={() => setIsShowPopup("")} />
            </div>
            <div className='wrap-block-content'>
                {
                    listNoteBusiness.length === 0 ?
                        <div className="wrap-empty">
                            <EmptyDataControl
                                icon={<IcMessageDefault />}
                                text={"Hiện tại chưa có ghi chú mới"}
                            />
                        </div>
                        :
                        <div className='wrap-list-note' ref={listRef}>
                            <div className='list-note'>
                                {
                                    listNoteBusiness?.map((item, index) => (
                                        <ItemNote key={index} data={item} scrollRef={scrollRef} />
                                    ))
                                }
                            </div>
                        </div>
                }
            </div>
            <div className='wrap-input-text-area'>
                <div className={urlImg ? 'input-text-area url' : 'input-text-area'}>
                    {
                        urlImg ?
                            <div className='wrap-attachment'>
                                {
                                    checkFileType === IMG
                                        ? <IcFileImage className='image' />
                                        : checkFileType === WORD
                                            ? <IcFileWord className='image' />
                                            : checkFileType === PDF
                                                ? <IcFilePdf className='image' />
                                                : null
                                }
                                <p className='text' title={urlFile.name}>{urlFile.name}</p>
                                <IcCloseGray className="remove" onClick={_handleRemoveFile} />
                            </div>
                            :
                            <TextArea
                                id={"id-text"}
                                className='text-area'
                                placeholder='Nhập nội dung ghi chú...'
                                maxLength={300}
                                rows={1}
                                value={valueTextArea}
                                onChange={(e) => setValueTextArea(e.target.value.trimStart())}
                            />
                    }
                </div>
                <div className={valueTextArea ? 'wrap-btn disabled' : 'wrap-btn'}>
                    <DragAndDrop handleDropFiles={_handleDropFiles}>
                        <IcPaperClip className={valueTextArea ? 'ic-paperclip disabled' : 'ic-paperclip'} onClick={_handleClickUploadFile} />
                    </DragAndDrop>
                </div>
            </div>
        </WrapListNote>
    )
}

export default BusinessNote