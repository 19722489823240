import React from 'react'
import { BANK, BANK_TX_ID, FACEPAY_TX_ID, MERCHANT_TX_ID, PAYME_TX_ID, PHONE_NUMBER, PROFILE_ID, VOUCHER } from '../../../../../../../../constant/keys'
import DropdownControl from '../../../../../../../Control/DropdownControl'
import { FACEPAYTERMINAL } from '../../../index'

export const dataSelectionBankTransaction = [   
    {
        text: "Bank Tx ID",
        key: BANK_TX_ID
    },
    {
        text: "Facepay Tx ID",
        key: FACEPAY_TX_ID
    },
    {
        text: "Merchant Tx ID",
        key: MERCHANT_TX_ID
    },
    {
        text: "Số điện thoại",
        key: PHONE_NUMBER
    },
    {
        text: "Profile ID",
        key: PROFILE_ID
    },
    {
        text: "Mã khuyến mãi",
        key: VOUCHER
    },
]

export const dataSelectionFacepayTransaction = [   
    {
        text: "Facepay Tx ID",
        key: FACEPAY_TX_ID
    },
    {
        text: "Bank Tx ID",
        key: BANK_TX_ID
    },
    {
        text: "Merchant Tx ID",
        key: MERCHANT_TX_ID
    },
    {
        text: "Số điện thoại",
        key: PHONE_NUMBER
    },
    {
        text: "Profile ID",
        key: PROFILE_ID
    },
    {
        text: "Mã khuyến mãi",
        key: VOUCHER
    },
]

export const dataSelectionFacepayWalletTransaction = [   
    {
        text: "Facepay Tx ID",
        key: FACEPAY_TX_ID
    },
    {
        text: "Payme Tx ID",
        key: PAYME_TX_ID
    },
    {
        text: "Số điện thoại",
        key: PHONE_NUMBER
    },
    {
        text: "Profile ID",
        key: PROFILE_ID
    }
]


const DropdownFilterBank = ({filterCond: { text, key }, setFilterCond, reqType }) => {
    
    const handleSetValueSelect = (val) => {
        setFilterCond(val)
    }
    
    return (
        <DropdownControl
            dataSelection={reqType === BANK  ? dataSelectionBankTransaction : reqType === FACEPAYTERMINAL ? dataSelectionFacepayTransaction : dataSelectionFacepayWalletTransaction}
            valueSelected={{key, text}}
            handleSetValueSelect={handleSetValueSelect}
        />
    )
}

export default DropdownFilterBank
