import React from 'react'

const IcMessageDefault = (props) => {
    return (
        <svg {...props} width={88} height={88} viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path d="M77 55C77 56.9449 76.2274 58.8102 74.8521 60.1854C73.4768 61.5607 71.6116 62.3333 69.6667 62.3333H25.6667L11 77V18.3333C11 16.3884 11.7726 14.5231 13.1479 13.1479C14.5231 11.7726 16.3884 11 18.3333 11H69.6667C71.6116 11 73.4768 11.7726 74.8521 13.1479C76.2274 14.5231 77 16.3884 77 18.3333V55Z" fill="#E8E8E8" />
            </g>
        </svg>
    )
}

export default IcMessageDefault