import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { clearDetailTransaction, getDetailTransaction } from '../../../../../redux/transaction/transactionDetail/action'

// component
import PageHeader from '../PageHeader'
import TransHistoryItem from '../TransactionHistory/TransHistoryItem'

// styles
import { WrapTransactionDetails } from './index.styles'

const TransactionDetails = () => {

    const dispatch = useDispatch()

    const { facepayId } = useParams()  
   
    const { transactionDetail } = useSelector(state => ({
        transactionDetail: state.transactionReducer.transactionDetailReducer.details
    }))   

    useEffect(() => {
        dispatch(getDetailTransaction({ id: facepayId }))
    }, [])

    // useEffect(() => {
    //     return () => {
    //         dispatch(clearDetailTransaction())
    //     }
    // }, [])

   
    return (
        <WrapTransactionDetails>
            <PageHeader>Thông tin giao dịch</PageHeader>
            <TransHistoryItem 
                data={transactionDetail} 
                isExpandByDefault={true} 
                isExpand={true}
                isTransDetails={true}
            />
        </WrapTransactionDetails>
    )
}

export default TransactionDetails
