import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//component
import FilterControl from '../../../../Control/FilterControl'
import DropdownSelectTypeSearch from './DropdownSelectTypeSearch'
import SelectStatus, { dataStatus } from './SelectStatus'
import DropdownFilterStore from '../../Page/TransactionV2/Bank/FilterTransactionBank/DropdownFilterStore'
import { iconCheckBox } from '../PopupExportFileV2/ExportFileBankV2'

//styles
import { InputFilter } from '../../../../Control/FilterControl/index.styles';
import { FilterControlStoreDevice, WrapInputSearchDevice, WrapSelectStatus } from './index.styles';

// action
import { getListTempFirebaseDevices, resetListDevicesByAdminId, updateDeviceCondsFilter } from '../../../../../redux/deviceV2/listDevicesByAdmin/action';
import { getListStoreName, hidePopupDropdownStoreName, showPopupDropdownStoreName, updateListStoreNameCondFilters } from '../../../../../redux/transactionV2/transactionOther/action'

// data
import dataDeviceStatus from '../../../../../data/dataDeviceStatus'

// constant
import { DEVICE_ID, STATUS_ID, DEVICE_NAME, STORE_LOCATION, DEVICE_VERSION, UNLINKED, ALL, CONNECTED, DISCONNECTED, TERMINATED } from '../../../../../constant/keys'

// helper
import removeAccent from '../../../../../helper/removeAccent'
import trimStartAndStripStr from '../../../../../helper/trimStartAndStripStr'

// images
import IcArrowDownGray from '../../../../../assets/images/IcArrowDownGray'


const FilterDevices = () => {

    let isShowSubmitBtn = false

    const dispatch = useDispatch()
    
    
    const { listFirebase, condFilters, listStoreName, condFiltersStoreName, isShowDropdownListStore, listIdCheckeds } = useSelector(state => ({
        // device
        listFirebase: state.deviceReducer.listDevicesByAdmin.listFirebase,
        listIdCheckeds: state.deviceReducer.listDevicesByAdmin.listIdCheckeds,
        condFilters: state.deviceReducer.listDevicesByAdmin.condFilters,
        
        // Store 
        listStoreName: state.transactionReducer.transactionOtherReducer.listStoreName,
        condFiltersStoreName: state.transactionReducer.transactionOtherReducer.condFiltersStoreName,
        isShowDropdownListStore: state.transactionReducer.transactionOtherReducer.isShowDropdownListStore
    }))
    
    const [filterCond, setFilterCond] = useState(condFilters?.optionSelected)
    const [status, setStatus] = useState(condFilters.status)

    const [device , setDevice] = useState({
        deviceID: condFilters.deviceID,
        deviceName: condFilters.deviceName,
        deviceVersion: condFilters.deviceVersion,
    })
    
    useEffect(() => {
        dispatch(getListStoreName({
            name: [""]
        }))
    }, [])

    const dropdownFilter = useMemo(() => (
        <DropdownSelectTypeSearch 
            filterCond={filterCond} 
            setFilterCond={setFilterCond}
        />
    ), [filterCond, setFilterCond])

    const dropdownFilterStore = useMemo(() => (
        <DropdownFilterStore
            className="filter-device"
            listStoreName={listStoreName}
            iconCheckBox={iconCheckBox}
            onClosePopup={() => dispatch(hidePopupDropdownStoreName())}
        />
    ),[isShowDropdownListStore])


    let listDeviceFilter = []

    const listCheckTempFirebase = () => {
        listDeviceFilter = listFirebase.map((item) => {
            return {
                ...item,
                isSelect: listIdCheckeds.includes(item.serialNumber)
            }
        })
        return listDeviceFilter
    }

    useEffect(() => { 
        // xu ly filter
        switch (condFilters.optionSelected.key) {
            case DEVICE_ID:{
                if(condFilters.deviceID.length === 0){
                    listCheckTempFirebase()
                    break
                }
                let found = listFirebase.filter((item) => item.appInfo?.linkCode?.trim().toLowerCase().includes(condFilters.deviceID.trim().toLowerCase())).map((item) => {
                    return {
                        ...item,
                        isSelect: listIdCheckeds.includes(item.serialNumber)
                    }
                })
                if(found) listDeviceFilter = found
            }
                break
        
            case STATUS_ID:{

                if(status.type === ALL){
                    listCheckTempFirebase()
                    break
                }
                listDeviceFilter = listFirebase.filter((item) => {
                    const objStatus = dataDeviceStatus[item.status]?.type || {}
                    return condFilters.status.type === CONNECTED ? objStatus === CONNECTED : condFilters.status.type === DISCONNECTED ? objStatus === DISCONNECTED : condFilters.status.type === TERMINATED ? objStatus === TERMINATED : condFilters.status.type === UNLINKED ? objStatus === UNLINKED : objStatus === CONNECTED
                }).map((item) => {
                    return {
                        ...item,
                        isSelect: listIdCheckeds.includes(item.serialNumber)
                    }
                })
            }
                break

            case DEVICE_NAME:{
                if(condFilters.deviceName.length === 0){
                    listCheckTempFirebase()
                    break
                }
                let found = listFirebase.filter((item) => item.appInfo?.name?.trim().toLowerCase().includes(condFilters.deviceName.trim().toLowerCase())).map((item) => {
                    return {
                        ...item,
                        isSelect: listIdCheckeds.includes(item.serialNumber)
                    }
                })
                if(found) listDeviceFilter = found
            }
                break

            case DEVICE_VERSION:{
                if(condFilters.deviceVersion.length === 0){
                    listCheckTempFirebase()
                    break
                }
                let found = listFirebase.filter((item) => item.appInfo?.versionName?.trim().toLowerCase().includes(condFilters.deviceVersion.trim().toLowerCase())).map((item) => {
                    return {
                        ...item,
                        isSelect: listIdCheckeds.includes(item.serialNumber)
                    }
                })
                if(found) listDeviceFilter = found
            }
                break

            default:
                return null
        }

        // update list temp
        dispatch(getListTempFirebaseDevices(listDeviceFilter))  

    }, [listFirebase, condFilters])

    useEffect(() => {
        if (condFilters.optionSelected.key === STORE_LOCATION) {

            if (listStoreName.length) {
                let idCheckeds = []
                listStoreName.forEach((item) => {
                    if (item.isSelect) {
                        idCheckeds.push(item.id)
                    }
                })
                if(condFiltersStoreName.OptionSelectStore.isSelect) {
                    dispatch(getListTempFirebaseDevices(listCheckTempFirebase()))
                    return
                }
                if (idCheckeds.length) {
                    let found = listFirebase.filter((item) => {
                        return idCheckeds.includes(item.appInfo?.shopId)
                    }).map((item) => {
                        return {
                            ...item,
                            isSelect: listIdCheckeds.includes(item.serialNumber)
                        }
                    })
                    dispatch(getListTempFirebaseDevices(found))
                    return 
                }

                dispatch(getListTempFirebaseDevices([]))
            }
        }
    }, [listFirebase, listStoreName])
    
    const _handleSearch = () => {
        const { key } = filterCond
        switch(key) {
            case DEVICE_ID: {
                dispatch(updateDeviceCondsFilter({
                    condFilters: {
                        ...condFilters,
                        optionSelected: filterCond,
                        deviceID : device.deviceID
                    }
                }))
            }
                break

            case STATUS_ID: {
                dispatch(updateDeviceCondsFilter({
                    condFilters: {
                        ...condFilters,
                        optionSelected: filterCond,
                        status
                    }
                }))
            }
                break

            case DEVICE_NAME: {
                dispatch(updateDeviceCondsFilter({
                    condFilters: {
                        ...condFilters,
                        optionSelected: filterCond,
                        deviceName : device.deviceName
                    } 
                }))
            }
                break

            case DEVICE_VERSION: {
                dispatch(updateDeviceCondsFilter({
                    condFilters: {
                        ...condFilters,
                        optionSelected: filterCond,
                        deviceVersion : device.deviceVersion
                    }
                }))
            }
                break

            default:
                return null
        }
    }

    const _handleSearchDevice = () => {
        const { key } = filterCond

        switch(key) {
            case DEVICE_ID: {
                dispatch(resetListDevicesByAdminId())
                dispatch(updateDeviceCondsFilter({
                    condFilters: {
                        ...condFilters,
                        optionSelected: filterCond,
                        deviceID : device.deviceID
                    }
                }))
            }
                break

            case STATUS_ID: {
                dispatch(resetListDevicesByAdminId())
                dispatch(updateDeviceCondsFilter({
                    condFilters: {
                        ...condFilters,
                        optionSelected: filterCond,
                        status
                    }
                }))
            }
                break

            case DEVICE_NAME: {
                dispatch(resetListDevicesByAdminId())
                dispatch(updateDeviceCondsFilter({
                    condFilters: {
                        ...condFilters,
                        optionSelected: filterCond,
                        deviceName : device.deviceName
                    }
                }))
            }
                break

            case DEVICE_VERSION: {
                dispatch(resetListDevicesByAdminId())
                dispatch(updateDeviceCondsFilter({
                    condFilters: {
                        ...condFilters,
                        optionSelected: filterCond,
                        deviceVersion : device.deviceVersion
                    }
                }))
            }
                break

            default:
                return null
        }
    }

    const _handleChangeStatus = (val) => {
        setStatus(val)
        dispatch(updateDeviceCondsFilter({
            condFilters: {
                optionSelected: filterCond,
                deviceID: "",
                deviceName: "",
                deviceVersion: "",
                status: val
            }
        }))
    }

    useEffect(() => {
        if(filterCond){   
            dispatch(getListTempFirebaseDevices(listCheckTempFirebase()))
            dispatch(updateDeviceCondsFilter({
                condFilters: {
                    optionSelected: filterCond,
                    deviceID: "",
                    deviceName: "",
                    deviceVersion: "",
                    status: dataStatus[0]
                }
            }))
            dispatch(getListStoreName({
                name: [""]
            }))
            dispatch(updateListStoreNameCondFilters({
                condFiltersStoreName: {
                    OptionSelectStore: {
                    name: "Tất cả cửa hàng",
                    isSelect: true,
                    totalStoreSelect: 0
                }}
            }))
        }
    }, [JSON.stringify(filterCond)]);

    useEffect(() => {
        setDevice({
            deviceID: "",
            deviceName: "",
            deviceVersion: "",
        })
        setStatus(condFilters.status)
        setFilterCond(condFilters?.optionSelected)
    },[condFilters.status, condFilters.optionSelected])

    useEffect(() => {
        let listDeviceFilter = []
        if(listIdCheckeds.length === 0) {
            listDeviceFilter = listFirebase.map((item) => {
                return {
                    ...item,
                    isSelect: listIdCheckeds.includes(item.serialNumber)
                }
            })
            dispatch(getListTempFirebaseDevices(listDeviceFilter))
        }
    },[listIdCheckeds])

    const filterComponent = useMemo(() => {
        const { key, text } = filterCond
        switch(key) {
            case DEVICE_ID: {
                isShowSubmitBtn = false
                return  <InputFilter
                            value={device.deviceID  || ""}
                            type="text"
                            placeholder='Nhập mã thiết bị'
                            onKeyPress={(e) => {
                                if(e.key === 'Enter') {
                                    _handleSearchDevice()
                                }
                            }}
                            onChange={(e) => setDevice({deviceID: trimStartAndStripStr(removeAccent(e.target.value))})}
                        />
            }
            case STATUS_ID: {
                isShowSubmitBtn = true
                return <WrapSelectStatus>
                            <SelectStatus
                                objValue={status}
                                setStatus={_handleChangeStatus}
                            />
                        </WrapSelectStatus>
            }
            case DEVICE_NAME: {
                isShowSubmitBtn = false
                return  <InputFilter
                            value={device.deviceName || ""}
                            type="text"
                            placeholder={`Nhập ${text}`}
                            onKeyPress={(e) => {
                                if(e.key === 'Enter') {
                                    _handleSearchDevice()
                                }
                            }}
                            onChange={(e) => setDevice({deviceName: trimStartAndStripStr(removeAccent(e.target.value))})}
                        />
            }
            case STORE_LOCATION: {
                isShowSubmitBtn = true
                return  (
                    <FilterControlStoreDevice>
                        <div className="store-title" onClick={() => dispatch(showPopupDropdownStoreName())}>
                            <div className="value">{condFiltersStoreName.OptionSelectStore.isSelect ? condFiltersStoreName.OptionSelectStore.name : `Đã chọn ${condFiltersStoreName.OptionSelectStore.totalStoreSelect} cửa hàng` }</div>
                            <IcArrowDownGray className="arrow-right"/>
                        </div>
                        {isShowDropdownListStore && dropdownFilterStore}
                    </FilterControlStoreDevice>
                )
            }
            case DEVICE_VERSION: {
                isShowSubmitBtn = false
                return  <InputFilter
                            value={device.deviceVersion  || ""}
                            type="text"
                            placeholder='Nhập phiên bản app'
                            onKeyPress={(e) => {
                                if(e.key === 'Enter') {
                                    _handleSearchDevice()
                                }
                            }}
                            onChange={(e) => setDevice({deviceVersion: trimStartAndStripStr(removeAccent(e.target.value))})}
                        />
            }
            default: 
                return null;
        }
    }, [JSON.stringify(filterCond), JSON.stringify(status), _handleSearchDevice])

    return (
        <WrapInputSearchDevice>
            <FilterControl 
                className='input-search-device'
                dropdownFilter={dropdownFilter}
                filterComponent={filterComponent}
                handleSearch={_handleSearch}
                isShowSubmitBtn={isShowSubmitBtn}
                value={status}
            />  
        </WrapInputSearchDevice>
    )
}

export default FilterDevices
