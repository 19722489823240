import React from 'react'

const IcBusinessAvatar = (props) => {
    return (
        <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width={32} height={32} rx={16} fill="#EFF9FF" />
            <path d="M22 23.25V21.75C22 20.9544 21.6839 20.1913 21.1213 19.6287C20.5587 19.0661 19.7956 18.75 19 18.75H13C12.2044 18.75 11.4413 19.0661 10.8787 19.6287C10.3161 20.1913 10 20.9544 10 21.75V23.25" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 15.75C17.6569 15.75 19 14.4069 19 12.75C19 11.0931 17.6569 9.75 16 9.75C14.3431 9.75 13 11.0931 13 12.75C13 14.4069 14.3431 15.75 16 15.75Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBusinessAvatar