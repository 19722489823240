import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

// style
import { WrapPopupTransHistoryDetailsWalletTranfer } from './index.style';

import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles';

//hook
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'

// image
import IcCloseWhite from '../../../../../../../../../assets/images/IcCloseWhite'
import Logo_DefautBank from "../../../../../../../../../assets/images/Default_Bank.png";
import IcNoteBlue from '../../../../../../../../../assets/images/IcNoteBlue';
import IcSearchDefault from '../../../../../../../../../assets/images/IcSearchDefault';
import IcArrowLeftRight from '../../../../../../../../../assets/images/IcArrowLeftRight';
import IcTimeOutlineGray from '../../../../../../../../../assets/images/IcTimeOutlineGray';
import IcRefundDoneBgWhite from '../../../../../../../../../assets/images/IcRefundDoneBgWhite';
import IcCloseRedMini from '../../../../../../../../../assets/images/IcCloseRedMini';
import IcAmountDiscountTrans from '../../../../../../../../../assets/images/IcAmountDiscountTrans';
import IcPromotionTrans from '../../../../../../../../../assets/images/IcPromotionTrans';
import IcBankMini from '../../../../../../../../../assets/images/IcBankMini';
import IcCheckBgBlue from '../../../../../../../../../assets/images/IcCheckBgBlue';
import { convertDateTime } from '../../../../../../../../../helper/convertDateTime';
import IcRefundWhite from '../../../../../../../../../assets/images/IcRefundWhite';
import IcRefundGray from '../../../../../../../../../assets/images/IcRefundGray';
import IcNotifyError from '../../../../../../../../../assets/images/IcNotifyError';
import IcStoreMini from '../../../../../../../../../assets/images/IcStoreMini';
import IcMerchantLogoDefault from '../../../../../../../../../assets/images/IcMerchantLogoDefault';
import IcUserBlueDefault from '../../../../../../../../../assets/images/IcUserBlueDefault';
import IcFacePrintDefault from '../../../../../../../../../assets/images/IcFacePrintDefault';
import IcPhoneBlueDefault from '../../../../../../../../../assets/images/IcPhoneBlueDefault';
import IcTransPhotoDefault from '../../../../../../../../../assets/images/IcTransPhotoDefault';
import IcTransVerifyVideo from '../../../../../../../../../assets/images/IcTransVerifyVideo';
import IcPlayCircle from '../../../../../../../../../assets/images/IcPlayCircle';
import IcLocationDefault from '../../../../../../../../../assets/images/IcLocationDefault';
import IcCardDefault from '../../../../../../../../../assets/images/IcCardDefault';

// component
import PopupAttachNote from '../../../../../CustomerV2/CustomerInfoExpand/TransactionHistory/PopupAttachNote';
import Portal from '../../../../../../../../Control/Portal';
import EmptyDataControl from '../../../../../../../../Control/EmptyDataControl';
import Button from '../../../../../../../../Control/Button';
import PopupShowMedia, { IMAGE, VIDEO } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia';

// contants
import { ACCOUNTANT, CTG, POPUP_NOTE_TRANSACTION, TRANSFER_TO_WALLET, TRANSFER_FROM_FACEPOS, FACEPAY, WALLET, TPBANK, OCB, SMARTPAY, TERMINAL, POPUP_NOTE_BACKLOG, POPUP_NOTE_TRANSACTION_CUSTOMER } from '../../../../../../../../../constant/keys';
import { apiGetBankCustomerAvatar, apiGetBankCustomerTransactionVideo, apiGetPaymentImg } from '../../../../../../../../../constant/host';
import numberFormatToThousand from '../../../../../../../../../helper/numberFormatToThousand';
import { dataBankType, dataLogoMerchant } from '../../../../../../../../../data/dataBankType';
import { PVCOMBANK, VIETTINBANK } from '../../../../../../../../../constant/dataBank';

// dataErrorsBank
import { dataErrorTPB } from '../../../../../../../../../dataErrorBank/dataErrorTPB';
import { dataErrorOCB } from '../../../../../../../../../dataErrorBank/dataErrorOCB';
import { dataErrorVTB } from '../../../../../../../../../dataErrorBank/dataErrorVTB';
import { dataErrorSmartpay } from '../../../../../../../../../dataErrorBank/dataErrorSmartpay';
import { dataErrorFacePos } from '../../../../../../../../../dataErrorBank/dataErrorFacePos';
import { dataErrorFacepay } from '../../../../../../../../../dataErrorBank/dataErrorFacepay';
import { dataErrorPVC } from '../../../../../../../../../dataErrorBank/dataErrorPVC';
import PopupConfirmApprove from '../../../../../CustomerV2/CustomerInfoExpand/TransactionHistory/TransHistoryItem/TransHistoryDetailsItem/TransactionDetail/PopupComfirmApprove';
import { mapErrorTransaction } from '../../../../../../../../../helper/mapErrorTransaction';


const PopupDetailWalletTranfer = ({ dataTransaction, onClickClosePopup, logoMerchent, reqType, detailsTrans }) => {

    const dispatch = useDispatch()
    const refPopup = useRef()

    const [showMedia, setShowMedia] = useState(null);
    const [isErrImg, setIsErrImg] = useState(false)
    const [isErrCustomerPhoto, setIsErrCustomerPhoto] = useState(false);
    const [isErrCustomerVideo, setIsErrCustomerVideo] = useState(false);
    const [isShowPopupNote, setIsShowPopupNote] = useState(false)
    const [isShowPopupComfirmRefund, setIsShowPopupComfirmRefund] = useState(false)
    const [isErrImgLogoMerchant, setIsErrImgLogoMerchant] = useState(false)

    const accountant = localStorage.getItem('FACEPAY_PORTAL_LOGIN')
    let accountantShowRefund = JSON.parse(accountant)

    useEffect(() => {
        setIsErrImg(false)
    }, [dataTransaction.paymentInfo?.thumbnailId])

    useEffect(() => {
        setIsErrCustomerPhoto(false)
    }, [dataTransaction.paymentInfo?.thumbnailId])

    useEffect(() => {
        setIsErrCustomerVideo(false)
    }, [dataTransaction.paymentInfo?.videoId])

    useEffect(() => {
        setIsErrImgLogoMerchant(false)
    }, [dataTransaction.shopInfo?.logoUrl])

    const _handleZoomPhoto = () => {
        if (isErrCustomerPhoto) return;
        setShowMedia({
            type: IMAGE,
            url: dataTransaction.paymentInfo.thumbnailId ? apiGetBankCustomerAvatar(dataTransaction.paymentInfo.thumbnailId) : "",

        });
    };

    const _handleZoomVideo = () => {
        if (isErrCustomerVideo) return;
        setShowMedia({
            type: VIDEO,
            url: dataTransaction.paymentInfo.videoId ? apiGetBankCustomerTransactionVideo(dataTransaction.paymentInfo.videoId) : "",
        });
    };

    useOnClickOutside(refPopup, ()=> {
        if(isShowPopupComfirmRefund || showMedia) return
        onClickClosePopup()
    })

    window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          onClickClosePopup();
        }
    })

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupTransHistoryDetailsWalletTranfer ref={refPopup}>
                    <IcCloseWhite className="icon-close" onClick={onClickClosePopup} />
                    <div className={dataTransaction.paymentInfo.status === 1 ? "wrap-status" : "wrap-status fail"}>
                        <div className="content">
                            {dataTransaction.paymentInfo.status === 1 ? <IcCheckBgBlue className="icon" /> : <IcCloseRedMini className="icon" />}
                            <p className="facepay-id">{dataTransaction.paymentInfo.facepayTrxCode ? `#${dataTransaction.paymentInfo.facepayTrxCode}` : "-"}</p>
                        </div>
                        <div className="content">
                            <div className='value'>
                                {
                                    dataTransaction.transactionType.toUpperCase() === TRANSFER_FROM_FACEPOS ? 'Nhận tiền từ FacePOS' : dataTransaction.transactionType.toUpperCase() === TRANSFER_TO_WALLET ? 'Chuyển tiền tới tài khoản' : "Chuyển tiền tới ngân hàng"
                                }
                            </div>
                            <div className="time">{dataTransaction.paymentInfo.createdAt ? convertDateTime(dataTransaction.paymentInfo.createdAt, "HH:mm:ss - DD/MM/YYYY") : "-"}</div>
                            {
                                dataTransaction.paymentInfo.refund === 1 ? (
                                    <div>
                                        <IcRefundDoneBgWhite />
                                        <p>Đã hoàn tiền</p>
                                    </div>
                                )
                                : 
                                accountantShowRefund.privileges
                                .includes(ACCOUNTANT) ? 
                                (
                                    <Button
                                    text="Hoàn tiền"
                                    iconBtn={dataTransaction.paymentInfo.supplier === CTG ? <IcRefundWhite /> : <IcRefundGray />}
                                    className={dataTransaction.paymentInfo.supplier === CTG ? "btn-refund" : "disable"}
                                    onClick={() => setIsShowPopupComfirmRefund(true)}
                                    isDisable={dataTransaction.paymentInfo.supplier === CTG ? false : true}
                                    />
                                ) : ""
                            }
                        </div>
                    </div>
                    {
                        dataTransaction.paymentInfo.status === 0 &&
                        <div className="wrap-reason">
                            <IcNotifyError className="error-icon" />
                            <div className="errro-text">
                                {mapErrorTransaction({
                                    supplier: dataTransaction.paymentInfo.supplier,
                                    paymentCode: dataTransaction.paymentInfo.paymentCode,
                                    paymentDescription: dataTransaction.paymentInfo.paymentDescription
                                })}
                            </div>
                        </div>
                    }
                    {
                        dataTransaction.paymentInfo.refund === 1 ? (
                            <div className='wrap-refund'>
                                <div className='refund'>
                                    <div className='icon'>
                                        <IcArrowLeftRight />
                                    </div>
                                    <p>{`${dataTransaction.paymentInfo.refundBy || "-"} đã thực hiện hoàn tiền`}</p>
                                </div>
                                <div className='refund'>
                                    <div className='icon'>
                                        <IcTimeOutlineGray />
                                    </div>
                                    <p className='refund-time'>{dataTransaction.paymentInfo.refundAt ? convertDateTime(dataTransaction.paymentInfo.refundAt, "HH:mm:ss - DD/MM/YYYY") : "-"}</p>
                                </div>
                            </div>
                        ) : ""
                    }
                    <div className="wrap-info-transaction">
                        <div className="info-transaction">
                            <div className="block-info">
                                <p className="title">Thông tin giao dịch</p>
                                {
                                    dataTransaction.transactionType.toUpperCase() === TRANSFER_FROM_FACEPOS ? (
                                        <div className="wrap-info">
                                            <div className="info">
                                                <div className='icon'>
                                                {
                                                    dataTransaction.paymentInfo.supplier === FACEPAY ? dataBankType[dataTransaction.paymentInfo.supplier]?.icon : logoMerchent[dataTransaction.paymentInfo.supplier]?.icon || <img src={Logo_DefautBank} className="icon" />
                                                }   
                                                </div>
                                                <div className="content">
                                                    <p className="value">{dataLogoMerchant[dataTransaction.paymentInfo.supplier]?.text}</p>
                                                    <p className="text">Phương thức thanh toán</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcPromotionTrans className="icon" />
                                                <div className="content">
                                                    <p className="value">{numberFormatToThousand(dataTransaction.paymentInfo.transAmount) || "-"}</p>
                                                    <p className="text">Số tiền thanh toán</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcAmountDiscountTrans className="icon" />
                                                <div className="content">
                                                    <p className="value">{numberFormatToThousand(dataTransaction.paymentInfo.originAmount) || 0}</p>
                                                    <p className="text">Số tiền gốc</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcAmountDiscountTrans className="icon" />
                                                <div className="content">
                                                    <p className="value">{numberFormatToThousand(dataTransaction.paymentInfo.discount) || 0}</p>
                                                    <p className="text">Số tiền áp dụng KM (đ) </p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcBankMini className="icon" />
                                                <div className="content">
                                                    <p className="value" title={dataTransaction.paymentInfo.bankTxID}>{dataTransaction.paymentInfo.bankTxID || "-"}</p>
                                                    <p className="text">Bank Tx ID</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='wrap-info'>
                                            <div className="info">
                                                <IcPromotionTrans className="icon" />
                                                <div className="content">
                                                    <p className="value">{numberFormatToThousand(dataTransaction.paymentInfo.transAmount) || "-"}</p>
                                                    <p className="text">Số tiền (đ)</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                            <div className="icon">{
                                                 dataTransaction.paymentInfo.supplier === FACEPAY ? dataBankType[dataTransaction.paymentInfo.supplier]?.icon : logoMerchent[dataTransaction.paymentInfo.supplier]?.icon || <img src={Logo_DefautBank} className="icon" />
                                            }
                                            </div>
                                                <div className="content">
                                                    <p className="value"> 
                                                    {
                                                        dataTransaction.paymentInfo.supplier === FACEPAY ? dataLogoMerchant[dataTransaction.paymentInfo.supplier]?.text : 
                                                        logoMerchent[dataTransaction.paymentInfo.supplier]?.bankName
                                                    }
                                                    </p>
                                                    <p className="text">Nguồn tiền</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcCardDefault className="icon" />
                                                <div className="content">
                                                    <p className="value">
                                                        {
                                                            dataTransaction.paymentInfo.supplier === FACEPAY ? "-" : 
                                                            logoMerchent[dataTransaction.paymentInfo.supplier]?.bankName
                                                        }
                                                    </p>
                                                    <p className="text">Thẻ liên kết</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcCardDefault className="icon" />
                                                <div className="content">
                                                    <p className="value">{dataTransaction.paymentInfo.holderName || "-"}</p>
                                                    <p className="text">Chủ thẻ</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcCardDefault className="icon" />
                                                <div className="content">
                                                    <p className="value">{dataTransaction.paymentInfo.dayAt ? convertDateTime(dataTransaction.paymentInfo.dayAt, "MM/YY" ): "-"}</p>
                                                    <p className="text">Ngày cấp / ngày hết hạn</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                dataTransaction.transactionType.toUpperCase() === TRANSFER_FROM_FACEPOS ? (
                                    <div className="block-info">
                                        <p className="title">Cửa hàng</p>
                                        <div className="wrap-info">
                                            <div className="info">
                                                <div className='info-icon'>
                                                    <IcStoreMini className="icon" />
                                                </div>
                                                <div className="content">
                                                    <p className="value" title={dataTransaction.shopInfo.merchantTrxId}>
                                                        {
                                                            dataTransaction.shopInfo.merchantTrxId || "-"
                                                        }
                                                    </p>
                                                    <p className="text">Merchant Tx ID</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <div className='info-icon'>
                                                    {!isErrImgLogoMerchant ? <img src={dataTransaction.shopInfo?.logoUrl} alt="" onError={() => setIsErrImgLogoMerchant(true)}/> : <IcMerchantLogoDefault className='icon' />}
                                                </div>
                                                <div className="content">
                                                    <p className="value" title={dataTransaction.shopInfo.shopName}>
                                                        {
                                                            dataTransaction.shopInfo.shopName || "-"
                                                        }
                                                    </p>
                                                    <p className="text">Cửa hàng</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <div className="icon">
                                                    <IcLocationDefault />
                                                </div>
                                                <div className="content">
                                                    <p className="value">
                                                    {
                                                        dataTransaction.shopInfo ? (
                                                            <a className='field-value link' href={`https://www.google.com/maps/place/${dataTransaction.shopInfo?.lat},${dataTransaction.shopInfo?.lng}`} target='_blank'>
                                                                {`https://www.google.com/maps/place/${dataTransaction.shopInfo?.lat},${dataTransaction.shopInfo?.lng}`} 
                                                            </a>
                                                        ) : <div className='field-value'>-</div>
                                                    }
                                                    </p>
                                                    <p className="text">Địa điểm thanh toán</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""
                            }
                            <div className="block-info-customer">
                                <p className="title">{dataTransaction.transactionType.toUpperCase() === TRANSFER_FROM_FACEPOS ? "Tài khoản thanh toán" : "Tài khoản chuyển"}</p>
                                {
                                    dataTransaction.from.userInfo?.profileId ? (
                                        <div className="wrap-info-customer">
                                            <div className="info">
                                                {
                                                    isErrImg ?
                                                        <IcFacePrintDefault className="icon" /> :
                                                        <img
                                                            className="icon"
                                                            src={dataTransaction.from.userInfo.thumbnailUrl}
                                                            alt="facePrint"
                                                            onError={() => setIsErrImg(true)}

                                                        />
                                                }
                                                <div className="content">
                                                    <p className="value">{dataTransaction.from.userInfo?.profileId || "-"}</p>
                                                    <p className="text">Profile ID</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <div className='info-icon'>
                                                    <IcUserBlueDefault className="icon" />
                                                    
                                                </div>
                                            <div className="content">
                                                <p className="value">{dataTransaction.from.userInfo?.fullName || "-"}</p>
                                                <p className="text">Họ tên</p>
                                            </div>
                                            </div>
                                            <div className="info">
                                                <IcPhoneBlueDefault className="icon" />
                                                <div className="content">
                                                    <p className="value">{dataTransaction.from.userInfo?.phone || "-"}</p>
                                                    <p className="text">Số điện thoại</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <div className='info-icon'>
                                                    <IcUserBlueDefault className="icon" />
                                                
                                                </div>
                                                <div className="content">
                                                    <p className="value" title={dataTransaction.from.userInfo?.userName}>{dataTransaction.from.userInfo?.userName || "-"}</p>
                                                    <p className="text">Username</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <EmptyDataControl
                                            icon={<IcSearchDefault />}
                                            text={"Không tìm được thông tin khách hàng"}
                                        />
                                    )
                                }
                            </div>
                            <div className="block-info-customer">
                                <p className="title">Tài khoản nhận</p>
                            {
                                dataTransaction.to.type.toUpperCase() === WALLET ? (
                                    dataTransaction.to.userInfo.profileId ? (
                                        <div className="wrap-info-customer">
                                            <div className="info">
                                                <div className='info-icon'>
                                                    {
                                                        isErrImg ?
                                                            <IcFacePrintDefault className="icon" />
                                                            :
                                                            <img
                                                                className="icon"
                                                                src={dataTransaction.to.userInfo.thumbnailUrl}
                                                                alt="facePrint"
                                                                onError={() => setIsErrImg(true)}

                                                            />
                                                    }
                                                </div>
                                                <div className="content">
                                                    <p className="value">{dataTransaction.to.userInfo.profileId || "-"}</p>
                                                    <p className="text">Profile ID</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <div className='info-icon'>
                                                    <IcUserBlueDefault className="icon" />               
                                                </div>
                                                <div className="content">
                                                    <p className="value">{dataTransaction.to.userInfo.fullName || "-"}</p>
                                                    <p className="text">Họ tên</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcPhoneBlueDefault className="icon" />
                                                <div className="content">
                                                    <p className="value">{dataTransaction.to.userInfo.phone || "-"}</p>
                                                    <p className="text">Số điện thoại</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <div className='info-icon'>
                                                    <IcUserBlueDefault className="icon" />
                                                </div>
                                                <div className="content">
                                                    <p className="value" title={dataTransaction.to.userInfo.userName}>{dataTransaction.to.userInfo.userName || "-"}</p>
                                                    <p className="text">Username</p>
                                                </div>
                                            </div>
                                        </div>
                                    ): (
                                    <EmptyDataControl
                                        icon={<IcSearchDefault />}
                                        text={"Không tìm được thông tin khách hàng"}
                                    />
                                    )
                                ) : (
                                    <div className="wrap-info-customer">
                                        <div className="info">
                                            <div className='icon'>
                                                {
                                                    dataTransaction.to.supplier === FACEPAY ? dataBankType[dataTransaction.to.supplier]?.icon : logoMerchent[dataTransaction.to.supplier]?.icon || <img src={Logo_DefautBank} className="icon" />
                                                }
                                            </div>
                                            <div className="content">
                                                <p className="value">
                                                    {
                                                        dataTransaction.to.supplier === FACEPAY ? dataLogoMerchant[dataTransaction.to.supplier]?.text : 
                                                        logoMerchent[dataTransaction.to.supplier]?.bankName
                                                    }
                                                </p>
                                                <p className="text">Ngân hàng nhận</p>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <IcCardDefault className="icon" />
                                            <div className="content">
                                                <p className="value">{dataTransaction.to.accountInfo.holderNumber || "-"}</p>
                                                <p className="text">Số thẻ / số tài khoản</p>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <IcUserBlueDefault className="icon" />
                                            <div className="content">
                                                <p className="value">{dataTransaction.to.accountInfo.holderName || "-"}</p>
                                                <p className="text">Chủ thẻ</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            </div>
                        </div>
                        <div className="info-face-registration">
                            <div className="wrap-value-trans">
                                <div className="wrap-value-note">
                                    {/* <IcInfoTransCustomer className="ic-note" />
                                    <p className="note">Chi tiết khách hàng</p> */}
                                </div>
                                <div className="wrap-value-note">
                                    <IcNoteBlue className="ic-note" onClick={() => setIsShowPopupNote(true)} />
                                    <p className="note">Ghi chú</p>
                                </div>
                                {isShowPopupNote &&
                                <PopupAttachNote 
                                    onClickClosePopup={() => setIsShowPopupNote(false)}
                                    data={dataTransaction}
                                    isStylePopup={true}
                                    isShowContentPopup={reqType === FACEPAY ?POPUP_NOTE_TRANSACTION_CUSTOMER : POPUP_NOTE_TRANSACTION}
                                />}
                            </div>
                            <div className="face-registration">
                                <div className="wrap-media face-img" onClick={_handleZoomPhoto}>
                                    {
                                        isErrCustomerPhoto ?
                                            <div className="wrapper">
                                                <IcTransPhotoDefault />
                                            </div>
                                            :
                                            <img
                                                src={dataTransaction.transactionType.toUpperCase() === TRANSFER_FROM_FACEPOS ? apiGetBankCustomerAvatar(dataTransaction.paymentInfo.imageProof) : apiGetPaymentImg(dataTransaction.paymentInfo.imageProof)}
                                                alt="face registration"
                                                onError={() => setIsErrCustomerPhoto(true)}
                                            />
                                    }
                                    {/* <div className="tools">
                                        <IcZoomIn />
                                    </div> */}
                                </div>
                                <div className="wrap-media recog-video" onClick={_handleZoomVideo}>
                                    {
                                        isErrCustomerVideo ?
                                            <div className="wrapper">
                                                <IcTransVerifyVideo />
                                            </div>
                                            :
                                            <>
                                                <img
                                                    src={apiGetBankCustomerAvatar(dataTransaction.paymentInfo.videoId)}
                                                    alt="video registration"
                                                    onError={() => setIsErrCustomerVideo(true)}
                                                />
                                                <div className="overlay">
                                                    <IcPlayCircle />
                                                </div>
                                            </>
                                    }
                                </div>
                                {
                                    !!showMedia &&
                                    <Portal>
                                        <PopupShowMedia
                                            mediaType={showMedia.type}
                                            url={showMedia.url}
                                            closePopup={() => setShowMedia(null)}
                                        />
                                    </Portal>
                                }
                            </div>
                            <div className="wrap-value">
                                <p>Image Proof:</p>
                                <p className="value-bioId" title={dataTransaction.paymentInfo.imageProof}>{dataTransaction.paymentInfo.imageProof || "-"}</p>
                            </div>
                        </div>
                    </div>
                </WrapPopupTransHistoryDetailsWalletTranfer>
            </div>
            {
                isShowPopupComfirmRefund ? 
                <PopupConfirmApprove 
                    detailsTrans={detailsTrans}
                    dataTransacion={dataTransaction}
                    onClickClosePopupComfirm={() => setIsShowPopupComfirmRefund(false)}
                    reqType={reqType}
                /> : ""
            }
        </OverlayFullScreen>
    )
}

export default PopupDetailWalletTranfer
