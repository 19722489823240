import React, { useState } from "react";
import IcArrowRightBlack from "../../../../../../../../assets/images/IcArrowRightBlack";
import IcCloseRed from "../../../../../../../../assets/images/IcCloseRed";
import IcMailBlueDefault from "../../../../../../../../assets/images/IcMailBlueDefault";
import IcPhoneBlueDefault from "../../../../../../../../assets/images/IcPhoneBlueDefault";
import IcUserBlueDefault from "../../../../../../../../assets/images/IcUserBlueDefault";
import IcWarningRed from "../../../../../../../../assets/images/IcWarningRed";
import { convertDateTime } from "../../../../../../../../helper/convertDateTime";
import FaceRecognizeInfos from "../../../FaceRecognizeInfos";
import { WrapListHistory } from "./index.styles";

const CensorshipHistoryItem = ({ item }) => {

  const [isShowReason, setIsShowReason] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const _handleOpenReason = () => {
    setIsShowReason((pre) => !pre);
    setIsActive((pre) => !pre);
  };

  return (
    <WrapListHistory>
      <div className="list-history">
        <div className="wrap-infos" onClick={() => _handleOpenReason()}>
          <div className="info">
            <span className="name">Document ID:</span>
            <span className="value" title={item?.documentId || ""}>
              {item?.documentId || "-"}
            </span>
          </div>
          <div className="info">
            <span className="name">Thời gian đăng ký:</span>
            <span className="value" title={convertDateTime(item?.submitTime, "HH:mm - DD/MM/YYYY") || ""}>
              {item?.submitTime
                ? convertDateTime(item?.submitTime, "HH:mm - DD/MM/YYYY")
                : "-"}
            </span>
          </div>
          <div className="info">
            <span className="name">Người kiểm duyệt:</span>
            <span className="value" title={item?.verifiedBy || ""}>
              {item?.verifiedBy || "-"}
            </span>
          </div>
          <div className="info">
            <span className="name">TG phê duyệt:</span>
            <span className="value" title={convertDateTime(item?.verifiedAt, "HH:mm - DD/MM/YYYY") || ""}>
              {item?.verifiedAt
                ? convertDateTime(item?.verifiedAt, "HH:mm - DD/MM/YYYY")
                : "-"}
            </span>
          </div>
          <div className="wrap-refuse">
            <IcCloseRed className="ic-close" />
            <p className="refuse">Từ chối</p>
            <IcArrowRightBlack className={isActive ? "expand" : ""} />
          </div>
        </div>
        {isShowReason && item?.reasons?.length !== 0 && (
          <div className="wrap-expand">
            {
              item?.reasons?.length &&
              <div className="wrap-list-reason">
                {item?.reasons?.map((reason, j) => (
                  <div className="list-reason" key={j}>
                    <IcWarningRed className="icon" />
                    <p className="text">{reason}</p>
                  </div>
                ))}
                {
                  !!item.optionalReason &&
                  <div className="list-reason">
                    <IcWarningRed className="icon" />
                    <p className="text">Lý do khác: {item.optionalReason}</p>
                  </div>
                }
              </div>
            }
            <div className="wrap-details">
              <div className="title">Thông tin khách hàng</div>
              <div className="row-detail">
                <div className="row">
                  <IcUserBlueDefault className="ic-default" />
                  <div className="detail">
                    <p className="value" title={item?.fullName || ""}>{item?.fullName || "-"}</p>
                    <p className="name">Họ tên</p>
                  </div>
                </div>
                <div className="row">
                  <IcPhoneBlueDefault className="ic-default" />
                  <div className="detail">
                    <p className="value" title={item?.phone || ""}>{item?.phone || "-"}</p>
                    <p className="name">Số điện thoại</p>
                  </div>
                </div>
                <div className="row">
                  <IcMailBlueDefault className="ic-default" />
                  <div className="detail">
                    <p className="value" title={item?.email || ""}>{item?.email || "-"}</p>
                    <p className="name">Email</p>
                  </div>
                </div>

              </div>
            </div>
            <FaceRecognizeInfos fullFaceUrl={item.fullFaceUrl} thumbnailUrl={item.thumbnailUrl} listSameFace={item.sameFaceObjList} />
          </div>
        )}
      </div>
    </WrapListHistory>
  );
};

export default CensorshipHistoryItem;
