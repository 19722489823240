import styled from "styled-components";


const WrapPopupOpenLocked = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Open Sans';
    width: calc(280px - 40px);
    height: calc(256px - 58px);
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 10;
    padding: 20px;
    .popup-title{
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #313131;
    }
    .popup-value{
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #313131;
        margin: 10px 0 50px 0;
        span{
            font-weight: 600;
        }
    }
    .popup-btn{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .btn-return{
            background: #F7F7F7;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #777777;
        }
    }
`

export {
    WrapPopupOpenLocked
}