import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// style
import { WrapItemBusiness } from './index.styles'

// images
import IcNewRegister from '../../../../../../../assets/images/IcNewRegister'
import IcWaitUpdate from '../../../../../../../assets/images/IcWaitUpdate'
import IcUpdateDoc from '../../../../../../../assets/images/IcUpdateDoc'
import IcActive from '../../../../../../../assets/images/IcActive'
import IcLocked from '../../../../../../../assets/images/IcLockBgRed'
import IcLogoMerchantDefault from '../../../../../../../assets/images/IcLogoMerchantDefault'
import IcArrowRight from '../../../../../../../assets/images/IcArrowRight'

// constant
import { LICENSE_UPDATED, PENDING, REQUIRE_LICENSE, REQUIRE_LICENSE_UPDATE } from '../../../../../../../constant/keys'

// helper
import { convertDateTime } from '../../../../../../../helper/convertDateTime'

// redux
import { getBusinessDetail } from '../../../../../../../redux/business/listBusinessManage/action'

const BusinessItem = ({ data, setIsShowPopup }) => {

  const dispatch = useDispatch()

  const [timerExpired, setTimerExpired] = useState("")
  const [isErrImg, setIsErrImg] = useState(false)

  useEffect(() => {
    setIsErrImg(false)
  }, [data.businessInfo.businessLogo])

  const unitTime = (time, a, b) => {
    return String(Math.floor((time % a) / b)).padStart(2, '0');
  }

  const formatTimer = (time) => {
    if (Number.isNaN(parseInt(time, 10))) {
      return ''
    }

    const days = Math.floor(time / 86400)
    const hours = unitTime(time, 86400, 3600)
    // const minutes = unitTime(time, 3600, 60)
    // const seconds = unitTime(time, 60, 1)

    if (days > 0) {
      return (
        <div className='time-expired-new'>{`0${days} ngày ${hours} giờ`}</div>
      )
    }

    if (hours > 0) {
      return (
        <div className='time-expired-denied'>{`0${days} ngày ${hours} giờ`}</div>
      )
    }

    return (
      <div className='time-expired-gray'>00:00:00</div>
    )
  }

  useEffect(() => {
    const timeDeadline = new Date((new Date(data.businessInfo.expiredTime)).getTime())
    
    setInterval(() => {
      const timerRemainingTime = (timeDeadline - new Date()) / 1000
      setTimerExpired(formatTimer(timerRemainingTime))
    }, 1000)
    return () => {
      clearInterval()
      setTimerExpired("")
    }
  }, [])

  const handleClick = () => {
    dispatch(getBusinessDetail({ businessID: data.businessInfo.id }, () => setIsShowPopup(true)))
  }

  return (
    <WrapItemBusiness onClick={handleClick}>
      <div className='wrap-status'>
        {
          data.businessInfo.censorshipState === LICENSE_UPDATED ?
            <IcUpdateDoc className="icon" />
            : data.businessInfo.censorshipState === REQUIRE_LICENSE_UPDATE
              ? < IcWaitUpdate className="icon" />
              : <IcNewRegister className="icon" />
        }
        <p className='merchant-id'>{data.businessInfo.businessCode || "-"}</p>
      </div>
      <div className='wrap-status'>
        {
          isErrImg ?
            <IcLogoMerchantDefault className="logo" />
            :
            <img
              className="logo"
              src={data.businessInfo.businessLogo}
              alt="facePrint"
              onError={() => setIsErrImg(true)}
            />
        }
        <div className='merchant-id'>{data.businessInfo.storeName || "-"}</div>
      </div>
      <div className='merchant-id'>{data.businessInfo.businessName || "-"}</div>
      <div className='owner-name'>{data.businessInfo.ownerName || "-"}</div>
      <div className='merchant-phone'>{data.businessInfo.phoneNumber || "-"}</div>
      <div className='wrap-status'>
        {
          data.userInfo.isLock ? <IcLocked className="icon" /> : <IcActive className="icon" />
        }
        <div className='profile-id'>{data.userInfo.profileID || "-"}</div>
      </div>
      {timerExpired || "-"}
      <div className='time-register'>
        {convertDateTime(data.businessInfo.registeredAt, "HH:mm - DD/MM/YYYY") || "-"}
        <IcArrowRight className="icon-arrow" />
      </div>
    </WrapItemBusiness>
  )
}

export default BusinessItem