import styled from "styled-components";

const WrapDropdownListFeatureDevice = styled.div`
    position: absolute;
    top: 25px;
    right: 20px;
    width: 172px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 10;
    display: flex;
    flex-direction: column;

    .feature-item{
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 15px;
        box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
        cursor: pointer;
        span{
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
            &.disable{
                color: #B7B7B7;
            }
        }
    }
    .hide-feature{
        display: none;
    }

`

export {
    WrapDropdownListFeatureDevice
}