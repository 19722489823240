import styled from "styled-components";
import { WrapDeviceAppInfo } from "../DeviceAppInfo/index.styles";

const WrapDeviceHareWare = styled(WrapDeviceAppInfo)`
    gap: 1px;

    .version-name{
        display: flex;
        align-items: center;
        gap: 6px;

        .name{
            display: flex;
            flex-direction: column;
            font-family: 'Open Sans';
            font-style: normal;
            &-title{
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #777777;
            }
            &-value{
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
                
            }
            .disable{
                color: #B7B7B7 !important;
            }
        }
    }
    .app-version{
        margin: 32px 0 16px 0;
        font-family: 'Open Sans';
        font-style: normal;
        justify-content: space-between;
        &-item{
            flex-direction: column;
            gap: 6px;
        }
    }


`

export {
    WrapDeviceHareWare
}