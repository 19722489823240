import * as types from './type'

import { hostCustomer } from '../../../constant/host'
import mapErrors from '../../../helper/mapErrors'
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import Axios from 'axios'
import autoDownloadFromBlob from '../../../helper/autoDownloadFromBlob'

const apiGetListTransactionFacepayWallet = `${hostCustomer}/transaction/WalletFacePay`
const apiExportListTransactionFacepayWallet = `${hostCustomer}/transaction/reportWalletFacePay`

let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let lastQueryApiGetListTransactionFacepayWallet = ""

const dataRequestApi = {
    limit: "",
    lastItem: "",
    paymeTxID: "",
    facepayTrxCode: "",
    phoneNumber: "",
    profileId: "",
    success: "",
    failed: "",
    deposit: "",
    withDrawal: "",
    toAnotherWallet : "",
    toBank : "",
    linkCard : "",
    begin: "",
    end: "",
}

export const getListTransactionFacepayWallet = (
    data = dataRequestApi,
    isScroll,
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    lastQueryApiGetListTransactionFacepayWallet = headerDataRequest.reqId
    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiGetListTransactionFacepayWallet, dataRequest)
        const { data } = res

        if(lastQueryApiGetListTransactionFacepayWallet !== dataRequest.reqId) return

        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_TRANSACTION_PACEPAY_WALLET,
                payload: {
                    listTransactionFacepayWallet: data.data.list || [],
                    totalFacepayWallet: data.data.total,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }else {
            dispatch({
                type: types.GET_LIST_TRANSACTION_PACEPAY_WALLET_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_TRANSACTION_PACEPAY_WALLET_ERR))
    }

}

export const updateCondfiterTransactionFacepayWallet = (
    condFiltersTransactionFacepayWallet = dataRequestApi,
    callbackSuccess
) => async dispatch => {
    
    dispatch({
        type: types.UPDATE_CONDFILTERS_TRANSACTION_FACEPAY_WALLET,
        condFiltersTransactionFacepayWallet
    })
    callbackSuccess && callbackSuccess()

}

export const resetCondFilterTransactionFacepayWallet = (callbackSuccess) => async dispatch => {
    dispatch({type: types.RESET_CONDFILTERS_TRANSACTION_FACEPAY_WALLET})
    callbackSuccess && callbackSuccess()
}

export const exportListTransactionFacepayWallet = (
    data = {
        facepayTrxCode: "",
        profileId: "",
        userName: "",
        amount: "",
        from: "",
        to: "",
        createAt: "",
        status: "",
        begin: "",
        end: "",
    },
    callbackSuccess
) => async dispatch => {
    
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiExportListTransactionFacepayWallet, dataRequest, {
            responseType: 'blob'
        })
        const { data } = res
        autoDownloadFromBlob(data, 'transaction')
        callbackSuccess && callbackSuccess()
    } catch (error) {
        dispatch(mapErrors(error, types.EXPORT_FILE_TRANSACTION_FACEPAY_WALLET_ERR))
    }

}