import * as types from './types'
import Axios from 'axios';

// constant
import { hostCustomer } from '../../../constant/host'

// helper
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import mapErrors from '../../../helper/mapErrors'

import { hideLoading, showLoading } from '../../loading/action'

let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

const apiGetCustomerAnalytic = `${hostCustomer}/customer/analyze`
const apiGetCustomerBankAnalytic = `${hostCustomer}/customer/bank/Analyze`

// FACEPAY ACTION
export const getCustomerAnalytic = (
    data = {
        uid: "",
        adminId: "",
        adminUserName: ""
    }, callbackSuccess, callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    dispatch(showLoading(types.LOADING_CUSTOMER_ANALYTIC))
    
    try {
        const res = await Axios.post(apiGetCustomerAnalytic, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_CUSTOMER_ANALYTIC,
                payload: {
                    dataAnalytic: data.data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_CUSTOMER_ANALYTIC_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, types.GET_CUSTOMER_ANALYTIC_ERR))
    }

    dispatch(hideLoading(types.LOADING_CUSTOMER_ANALYTIC))

}

export const resetCustomerAnalytic = () => async dispatch => {
    dispatch({type: types.RESET_CUSTOMER_ANALYTIC})
}

// BANK ACTION
export const getCustomerBankAnalytic = (
    data = {
        bioId: "",
        adminId: "",
        adminUserName: ""
    }, callbackSuccess, callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    dispatch(showLoading(types.LOADING_CUSTOMER_ANALYTIC))
    
    try {
        const res = await Axios.post(apiGetCustomerBankAnalytic, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_CUSTOMER_BANK_ANALYTIC,
                payload: {
                    dataAnalytic: data.data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_CUSTOMER_BANK_ANALYTIC_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_CUSTOMER_BANK_ANALYTIC_ERR))
    }

    dispatch(hideLoading(types.LOADING_CUSTOMER_ANALYTIC))

}

export const resetCustomerBankAnalytic = () => async dispatch => {
    dispatch({type: types.RESET_CUSTOMER_BANK_ANALYTIC})
}
