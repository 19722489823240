import React from 'react'

const IcSexDefault = (props) => {
    return (
        <svg {...props} width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6062_61181)">
        <circle cx="18" cy="18.5" r="18" fill="#EFF9FF"/>
        <path d="M16.9492 22.0508V26.2508" stroke="#33ADFF" strokeLinecap="round"/>
        <path d="M19.0498 24.8516L14.8498 24.8516" stroke="#33ADFF" strokeLinecap="round"/>
        <path d="M21.1494 10.8516H23.9494V13.6516" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.9502 10.8516L20.4502 14.3515" stroke="#33ADFF"/>
        <circle cx="16.9498" cy="17.15" r="4.89998" stroke="#33ADFF"/>
        </g>
        <defs>
        <clipPath id="clip0_6062_61181">
        <rect width="36" height="36" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default IcSexDefault
