import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import moment from 'moment'

//style
import { WrapItem } from './index.styles'

//image
import IcFacePrintDefault from '../../../../../../../assets/images/IcFacePrintDefault'
import IcRefreshWaiting from '../../../../../../../assets/images/IcRefreshWaiting'
import IcUserRegis from '../../../../../../../assets/images/IcUserRegis'
import IcWarningMini from '../../../../../../../assets/images/IcWarningMini'
import IcArrowRight from '../../../../../../../assets/images/IcArrowRight'

import { convertDateTime } from '../../../../../../../helper/convertDateTime'
import checkTimeWarning from '../../../../../../../helper/checkTimeWarning'

//path
import * as path from '../../../../../../../constant/path'
import { dataCensorshipStatus } from '../../../../../../../data/dataCensorshipStatus';

//data
import { PENDING, REPENDING } from '../../../../../../../data/dataCensorshipStatus'

const ItemWaitingCensorship = ({ data }) => {


    const { id, status, documentId, fullName, phone, thumbnailUrl, email, sameFace, submitTime } = data

    const history = useHistory()

    const [isErrImg, setIsErrImg] = useState(false)
    const [waitingTime, setWaitingTime] = useState("")
    
    useEffect(() => {
        setIsErrImg(false)
    }, [id])

    useEffect(() => {
        let currentTime = Date.now()
        setWaitingTime(checkTimeWarning(currentTime, submitTime))
        const timer = setInterval(() => {
            currentTime = Date.now()
            setWaitingTime(checkTimeWarning(currentTime, submitTime))
        }, 10000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    const _handleClick = () => {
        history.push(`/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}/${id}`)
    }
    return (
        <WrapItem onClick={_handleClick}>
            <div className="wrap-status">
                {
                    status === PENDING ?
                        <IcUserRegis />
                        :
                        status === REPENDING ?
                            <IcRefreshWaiting />
                            : null
                }
                <p className="document-id" title={documentId || ""}>{documentId || "-"}</p>
            </div>
            <div className="face-print">
                {
                    isErrImg ?
                        <IcFacePrintDefault className="face" />
                        :
                        <img
                            className="face"                            
                            src={thumbnailUrl}
                            alt="facePrint"
                            onError={() => setIsErrImg(true)}
                        />
                }
            </div>
            <div title={fullName || ""}>{fullName || "-"}</div>
            <div>{phone || "-"}</div>
            <div title={email || ""}>{email || "-"}</div>
            <div className="wrap-warning">
                {
                    sameFace?.length === 0 || !sameFace ?
                        ""
                        :
                        <div className="warning">
                            <IcWarningMini className="ic-warning" />
                            <p>{`${sameFace.length}`} trùng khớp</p>
                        </div>
                }
            </div>
            <div className={`wrap-time ${waitingTime.isExpired ? "red" : ""}`}>
                {submitTime ? waitingTime.value : "-"}
                <IcArrowRight className="icon-arrow" />
            </div>
        </WrapItem>
    )
}

export default ItemWaitingCensorship
