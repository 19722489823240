import * as types from './types'
import dayjs from "dayjs";

// data
import { dataStatus } from '../../../components/Main/Content/BreadcrumbAndTool/FilterDevices/SelectStatus'
import { dataSelectCheckBoxExportDevice } from '../../../data/dataSelectCheckbox';
import { dataSelection } from '../../../data/dataDeviceFeature';

export const startDay = dayjs('1/1/2022').startOf('day').toDate()
export const currentDay = dayjs(new Date()).endOf("day").toDate()

const initialState = {
    listFirebase: [],
    listTempFirebase: [],
    listIdCheckeds: [],
    listUrlScreenCheckeds: "",
    prevURL: "",
    detailsDevice: {},

    condFilters: {
        optionSelected: dataSelection[0],
        deviceID: "",
        deviceName: "",
        deviceVersion: "",
        status: dataStatus[0]
    },

    condFiltersExportDevice: {
        begin: startDay,
        end: currentDay,
        OptionSelectCheckboxExportDevice: dataSelectCheckBoxExportDevice
    },

    totalDisconnect: 0,
    total: 0,
    isStatusView: true,
    isCheckcSreenshot: false,
    isCheckSnapshot: false
}
const listDevicesByAdmin = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_DEVICES_BY_ADMIN:
            return {
                ...state,
                listFirebase : action.payload.list,
            }
        
        case types.GET_LIST_DEVICES_BY_ADMIN_TEMP:
            return {
                ...state,
                listTempFirebase : action.payload.listTemp || [],
            }
        case types.RESET_LIST_DEVICES_BY_ADMIN_LIST_TEMP:
            return{
                ...state,
                listTempFirebase : [],
            }
        case types.TOGGLE_LIST_VIEW_MODE: {
            return {
                ...state,
                isStatusView: !state.isStatusView
            }
        }
        case types.RESET_LIST_VIEW_MODE: {
            return {
                ...state,
                isStatusView: initialState.isStatusView

            }
        }
        case types.RESET_LIST_DEVICES_BY_ADMIN:
            return {
                ...initialState,
                isStatusView: state.isStatusView,
                totalDisconnect: state.totalDisconnect,
            }
        case types.UPDATE_DEVICE_CONDFILTERS:
            return {
                ...state,
                condFilters: {
                    ...state.condFilters,
                    ...action.condFilters
                }
            }
        case types.RESET_DEVICE_CONDFILTERS:
            return {
                ...state,
                condFilters: initialState.condFilters,
            }
        
        case types.UPDATE_LIST_ID_CHECKED_DEVICE:
            return {
                ...state,
                listIdCheckeds: action.listIdCheckeds
            }

        case types.RESET_LIST_ID_CHECKED_DEVICE:
            return {
                ...state,
                listIdCheckeds: []
            }
        
        case types.DETAIL_DEVICE:
            return {
                ...state,
                detailsDevice: action.detailsDevice
            }

        case types.LUANCHER_ACTION_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
            
        // check screenshot
        case types.IS_CHECK_SCREENSHOT:
            return {
                ...state,
                isCheckcSreenshot: true
            }
        case types.RESET_IS_CHECK_SCREENSHOT:
            return {
                ...state,
                isCheckcSreenshot: false
            }

        // check snapshot
        case types.IS_CHECK_SNAPSHOT:
            return {
                ...state,
                isCheckSnapshot: true
            }
        case types.RESET_IS_CHECK_SNAPSHOT:
            return {
                ...state,
                isCheckSnapshot: false
            } 

        case types.LIST_CHECKED_URL_SCREEN:
            return {
                ...state,
                listCheckUrlScreen: action.listCheckUrlScreen
            }

        case types.GET_URL_SCREEN:
                return {
                    ...state,
                    prevURL: action.prevURL
                }

        default:
            return state;
    }

}

export default listDevicesByAdmin