import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    width: 320px;
    min-height: 452px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    .wrap-image{
        width: 320px;
        height: 320px;
        background: #F7F7F7;
        /* border-radius: 4px; */
        display: flex;
        align-items: center;
        justify-content: center;   
        pointer-events: auto;
        cursor: auto;
    }
    .avatar{
        object-fit: contain;
        border-radius: 4px 4px 0 0;
        width: 320px;
        height: 320px;
    }
    .wrap-content{
        background: #FFFFFF;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        min-height: 44px;
        padding: 13px 16px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        :last-child{
            border-radius: 0 0 4px 4px;
        }
        .name{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #777777;
        }
        .value{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            color: #313131;
            &.fullname{
                font-weight: 600;
            }
        }
    }

`
export { Bound }