import React from 'react'

const IcLicenseUrlDefault = (props) => {
    return (
        <svg {...props} width={176} height={176} viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={176} height={176} rx={4} fill="#F7F7F7" />
            <path d="M91.6666 69.6667H77C76.0275 69.6667 75.0949 70.053 74.4073 70.7406C73.7196 71.4283 73.3333 72.3609 73.3333 73.3334V102.667C73.3333 103.639 73.7196 104.572 74.4073 105.259C75.0949 105.947 76.0275 106.333 77 106.333H99C99.9724 106.333 100.905 105.947 101.593 105.259C102.28 104.572 102.667 103.639 102.667 102.667V80.6667L91.6666 69.6667Z" fill="#B7B7B7" />
            <path d="M95.3334 97.1667H80.6667" stroke="#F7F7F7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M95.3334 89.8333H80.6667" stroke="#F7F7F7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M84.3334 82.5H82.5H80.6667" stroke="#F7F7F7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M91.6667 69.6667V80.6667H102.667" fill="#B7B7B7" />
            <path d="M91.6667 69.6667V80.6667H102.667" stroke="#F7F7F7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcLicenseUrlDefault