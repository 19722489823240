import styled from "styled-components";
import { WrapTransHistoryDetailsItem } from "../index.styles";

const WrapCustomerTransHistoryDetailsItem = styled(WrapTransHistoryDetailsItem)`


    .info-icon{  
        width: 30px;
        height: 30px;      
        .icon {
            width: 100%;
            height: 100%;
            padding-left: 10px;
        }
        svg{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    .ic-note{
        cursor: pointer;
    }

    
`

export {
    WrapCustomerTransHistoryDetailsItem
}