import React, { useState } from 'react'

//style
import { WrapCallHistoryItem } from './index.styles'

//help
import { convertDateTime } from '../../../../../../../../helper/convertDateTime'

//image
import { dataCallStatus } from '../../../../../../../../data/dataCustomer'
import IcExportFileBlue from '../../../../../../../../assets/images/IcExportFileBlue'

export const SUCCESS = "SUCCESS"
export const FAIL = "FAIL"

const CallHistoryItem = ({ data }) => {

    const { id, status, callId, calltime, creatCall } = data

    return (
        <WrapCallHistoryItem>
            <div className="wrap-content">
                <div className="content">
                    <div className="wrap-status" style={{ background: dataCallStatus[status].background }}>
                        {status ? dataCallStatus[status].icon : ""}
                        <p className="text">{status ? dataCallStatus[status].text : ""}</p>
                    </div>
                    <p className="call-id">{callId || "-"}</p>
                </div>
                <div className="wrap-download">
                    <IcExportFileBlue className="icon" />
                    <p className="download">Tải ghi âm</p>
                </div>
            </div>
            <div className="wrap-content">
                <div className="call-time">{calltime ? convertDateTime(calltime, "HH:mm") : "-"}</div>
                <div className="time">{creatCall ? convertDateTime(creatCall, "HH:mm - DD/MM/YYYY") : "-"}</div>

            </div>
        </WrapCallHistoryItem>
    )
}

export default CallHistoryItem
