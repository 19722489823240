import React from 'react'

const IcLoginGray = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#B7B7B7"/>
        <path d="M10.666 15.3346L13.9993 12.0013L10.666 8.66797" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 12H6" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 6H16.6667C17.0203 6 17.3594 6.14048 17.6095 6.39052C17.8595 6.64057 18 6.97971 18 7.33333V16.6667C18 17.0203 17.8595 17.3594 17.6095 17.6095C17.3594 17.8595 17.0203 18 16.6667 18H14" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcLoginGray
