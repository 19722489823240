import React from 'react'
import { useSelector } from 'react-redux'

// styles
import { WrapDeviceAppInfo } from './index.styles'

// images
import IcDeviceBgBlue from '../../../../../../../assets/images/IcDeviceBgBlue'
import { dataFeatureDevice } from '../../../../../../../data/dataDeviceFeature'
import { DELETE_DEVICE } from '../../../../../../../constant/keys'
import handleCallApiLuancherAction from '../../HandleCallApiLuancherAction'

const DeviceAppInfo = () => {

  const { detailsDevice } = useSelector(state => ({
    detailsDevice: state.deviceReducer.listDevicesByAdmin.detailsDevice
  }))

  const renderContentDeviceFeatureApp = () => {
    return (
        dataFeatureDevice.map((item) => {
            return (
              !item.isShowPopupDeviceDetail && item.key !== DELETE_DEVICE ? (
                <div className='app-detail-feature' key={item.id} onClick={handleCallApiLuancherAction(item.key, [detailsDevice.serialNumber])}>
                    <div className='icon'>
                        {item.icon}
                    </div>
                    <div className="value">
                        {item.text}
                    </div>
                </div>
              ): ""
            )
        })
    )
}

  return (
    <WrapDeviceAppInfo>
        <div className='app-detail'>
          <div className='app-detail-left'>
            <div className='version-name'>
              <IcDeviceBgBlue />
              <div className='name'>
                <span className='name-title'>Tên phiên bản</span>
                <span className='name-value'>{detailsDevice.appInfo?.versionName || "-"}</span>
              </div>
            </div>
            <div className='version-name'>
              <IcDeviceBgBlue />
              <div className='name'>
                <span className='name-title'>Phiên bản Launcher</span>
                <span className='name-value'>{detailsDevice.verName || "-"}</span>
              </div>
            </div>
          </div>
          <div className='app-detail-right'>
            {renderContentDeviceFeatureApp()}
          </div>
        </div>
        <div className='app-version-item'>
          <div className='version-item'>
            <span className='version-title'>VersionCode</span>
            <span className='version-value'>{detailsDevice.appInfo?.versionCode || "-"}</span>
          </div>
          <div className='version-item'>
            <span className='version-title'>VersionCode Launcher</span>
            <span className='version-value'>{detailsDevice.verCode || "-"}</span>
          </div>
          <div className='version-item'>
            <span className='version-title'>Location</span>
            {
              detailsDevice.appInfo ? (
                <a className='version-value link' href={`https://www.google.com/maps/place/${detailsDevice.appInfo?.lat},${detailsDevice.appInfo?. lng}`} target='_blank'>
                  {`https://www.google.com/maps/place/${detailsDevice.appInfo?.lat},${detailsDevice.appInfo?.lng}`} 
                </a>
              ) : <div className='version-value'>-</div>
            }
            
          </div>
        </div>
        {
          dataFeatureDevice.map((item) => {
            return (
              !item.isShowPopupDeviceDetail && item.key === DELETE_DEVICE ? (
                <div className="app-detail-feature" key={item.id}>
                    <div className='icon'>
                        {item.isDisable ? item.iconDisable : item.icon}
                    </div>
                    <div className={`value ${item.isDisable ? 'disable' : ""}`}>
                        {item.text}
                    </div>
                </div>
              ): ""
            )
          })
        }
    </WrapDeviceAppInfo>
  )
}

export default DeviceAppInfo