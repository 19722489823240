import React, { useMemo } from 'react'

//style
import { Bound } from './index.styles'

const CheckBoxControl = ({
    children,
    isChecked,
    iconCheckBox,
    isCheckedSome,
    handleCheck,
    extendStyles,
    isDisabled,
    _onClickDisableItem,
    title,
    ...props
}) => {

    const _handleChecked = () => {
        if (isDisabled) {
            _onClickDisableItem && _onClickDisableItem()
            return;
        }

        if (isChecked || isCheckedSome) {
            const newCheck = false
            handleCheck && handleCheck(newCheck)
            return; 
        }
        const newCheck = true
        handleCheck && handleCheck(newCheck)
    };

    const renderCheckBox = useMemo(() => {
        if (isChecked) {
            return <iconCheckBox.iconActive className="icon" />
        }
        if (isDisabled) {
            return <iconCheckBox.iconDisabled className="icon" />
        }
        return <iconCheckBox.icon className="icon" />
    }, [isChecked, isCheckedSome, isDisabled])

    return (
        <Bound onClick={_handleChecked} extendStyles={extendStyles} {...props}>
            {renderCheckBox}
            <div className="text">{title}</div>
        </Bound>
    )
}

export default CheckBoxControl
