import IcUserRegis from "../assets/images/IcUserRegis"
import IcRefreshWaiting from "../assets/images/IcRefreshWaiting"
import IcRefuseYellow from "../assets/images/IcRefuseYellow"
import IcRefuseRed from "../assets/images/IcRefuseRed"

export const PENDING = "PENDING"
export const REPENDING = "REPENDING"
export const REFUSE_ONE_TIME = "REFUSE_ONE_TIME"
export const REFUSE_MANY_TIMES = "REFUSE_MANY_TIMES"

export const dataCensorshipStatus = {
    [PENDING]: {
        icon: <IcUserRegis />,
        text: "Đăng ký mới"
    },
    [REPENDING]: {
        icon: <IcRefreshWaiting />,
        text: "Chờ duyệt lại"
    },
    [REFUSE_ONE_TIME]: {
        icon: <IcRefuseYellow />,
        text: "Từ chối 1 lần"
    },
    [REFUSE_MANY_TIMES]: {
        icon: <IcRefuseRed />,
        text: "Từ chối nhiều lần"
    }
}
