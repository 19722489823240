import React from 'react'

const IcBankPVComBank = (props) => {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.8371 12.8269C23.5158 12.0631 21.9193 11.1736 20.886 10.7927C19.1618 10.1565 17.4375 9.86677 15.6465 9.67834V15.0597C19.0483 14.7173 21.7329 13.8825 22.8371 12.8269Z" fill="url(#paint0_linear_10566_53679)"/>
        <path d="M1.58569 12.8777L12.1883 23.4803L22.8557 12.8068C21.4171 14.2088 17.1866 15.228 12.1842 15.228C7.26687 15.228 3.09108 14.2433 1.58569 12.8777Z" fill="url(#paint1_linear_10566_53679)"/>
        <path d="M1.53923 10.6834C0.860485 11.4473 2.45908 12.3367 3.49036 12.7156C5.21458 13.3518 6.93676 13.6415 8.72986 13.83V8.45068C5.33007 8.79309 2.64345 9.62785 1.53923 10.6834Z" fill="url(#paint2_linear_10566_53679)"/>
        <path d="M22.7909 10.6327L12.1883 0.0301514L1.52087 10.7036C2.9594 9.30158 7.1899 8.28245 12.1923 8.28245C17.1117 8.28245 21.2875 9.26511 22.7909 10.6327Z" fill="url(#paint3_linear_10566_53679)"/>
        <defs>
        <linearGradient id="paint0_linear_10566_53679" x1="19.8548" y1="14.5906" x2="16.6814" y2="9.09421" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFDD00"/>
        <stop offset="0.3373" stopColor="#FDB614"/>
        <stop offset="0.6282" stopColor="#F8991D"/>
        <stop offset="0.8587" stopColor="#F6881F"/>
        <stop offset="1" stopColor="#F58220"/>
        </linearGradient>
        <linearGradient id="paint1_linear_10566_53679" x1="22.8022" y1="18.1434" x2="1.62636" y2="18.1434" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F58220"/>
        <stop offset="0.3373" stopColor="#FBA819"/>
        <stop offset="0.6282" stopColor="#FFC50B"/>
        <stop offset="0.8587" stopColor="#FFD700"/>
        <stop offset="1" stopColor="#FFDD00"/>
        </linearGradient>
        <linearGradient id="paint2_linear_10566_53679" x1="7.66936" y1="14.3702" x2="4.49597" y2="8.87377" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0072BC"/>
        <stop offset="0.1413" stopColor="#0076BF"/>
        <stop offset="0.3718" stopColor="#0084C9"/>
        <stop offset="0.6627" stopColor="#009EDA"/>
        <stop offset="1" stopColor="#44C8F5"/>
        </linearGradient>
        <linearGradient id="paint3_linear_10566_53679" x1="22.7372" y1="5.36706" x2="1.5617" y2="5.36706" gradientUnits="userSpaceOnUse">
        <stop stopColor="#44C8F5"/>
        <stop offset="0.1413" stopColor="#3DBFEF"/>
        <stop offset="0.3718" stopColor="#1DACE3"/>
        <stop offset="0.6627" stopColor="#0091D2"/>
        <stop offset="1" stopColor="#0072BC"/>
        </linearGradient>
        </defs>
        </svg>

    )
}

export default IcBankPVComBank
