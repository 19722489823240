import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

// component
import PageHeader from '../PageHeader'
import RevenueBarChart from './RevenueBarChart'
import IcArrowDownGray from '../../../../../assets/images/IcArrowDownGray'
import DropdownSelectTypeChart, { dataFilterChartSelect, KEY_DAY } from './DropdowSelectTypeChart'
import ChartPercentage, { legends, listColors } from './ChartPercentage'

// styles
import { WrapAnalyticPage } from './index.styles'

// helper
import numberFormatToThousand from '../../../../../helper/numberFormatToThousand'

// actions
import { getDataAnalytic } from '../../../../../redux/analytic/action'

const dataColByMonth = new Array(31).fill(null).map((_, index) => index + 100)
const dataColByYear = new Array(12).fill(null).map((_, index) => index + 100)
const listPercentage = [20, 25, 39, 15, 1]

const AnalyticPage = () => {
    const dispatch = useDispatch()

    const { list, maxAmount } = useSelector(state => ({
        list: state.analyticReducer.list,
        maxAmount: state.analyticReducer.maxAmount,
    }))

    const [filterType, setFilterType] = useState(dataFilterChartSelect[0]);

    const [day, setDay] = useState(Number(moment().format("D")));
    const [month, setMonth] = useState(Number(moment().format("M")));
    const [year, setYear] = useState(Number(moment().format("YYYY")));

    // const [dataColumn, setDataColumn] = useState([]);
    const [dataSelected, setDataSelected] = useState({});
    
    const callbackSuccess = (lst, key) => {
        // const { key } = filterType
        if(key === KEY_DAY) {
            setDataSelected(lst[day - 1])
            return;
        }
        setDataSelected(lst[month - 1])
    }

    useEffect(() => {
        dispatch(getDataAnalytic({
            type: filterType.key, 
            month,
            year
        }, callbackSuccess))
    }, []);

    useEffect(() => {
        setDay(Number(moment().format("D")))
        setMonth(Number(moment().format("M")))
        setYear(Number(moment().format("YYYY")))
    }, [JSON.stringify(filterType)]);

    const _handlePrev = () => {
        const { key } = filterType
        if(key === KEY_DAY) {
            const newMonth = month - 1
            if(newMonth > 0) {
                setMonth(newMonth)
                dispatch(getDataAnalytic({
                    type: key, 
                    month: newMonth,
                    year
                }, callbackSuccess))
                return;
            }
            setMonth(12)
            const newYear = year - 1
            if(newYear > 0) {
                setYear(newYear)
                dispatch(getDataAnalytic({
                    type: key, 
                    month: 12,
                    year: newYear
                }, callbackSuccess))
                return;
            }
            // setYear(0)
            // dispatch(getDataAnalytic({
            //     type: key, 
            //     newMonth,
            //     0
            // }))
            return;
        }

        // KEY_MONTH
        const newYear = year - 1
        if(newYear > 0) {
            setYear(newYear)
            dispatch(getDataAnalytic({
                type: key, 
                month,
                year: newYear
            }, callbackSuccess))
            return;
        }
        // setYear(0)
        // dispatch(getDataAnalytic({
        //     type: key, 
        //     month,
        //     year: 0
        // }))
    }

    const _handleNext = () => {
        const { key } = filterType
        if(key === KEY_DAY) {
            const newMonth = month + 1
            if(newMonth <= 12) {
                setMonth(newMonth)
                dispatch(getDataAnalytic({
                    type: key, 
                    month: newMonth,
                    year
                }, callbackSuccess))
                return;
            }
            setMonth(1)
            const newYear = year + 1
            setYear(newYear)
            dispatch(getDataAnalytic({
                type: key, 
                month: 1,
                year: newYear
            }, callbackSuccess))
            return;
        }

        // KEY_MONTH
        const newYear = year + 1
        setYear(newYear)
        dispatch(getDataAnalytic({
            type: key, 
            month,
            year: newYear
        }, callbackSuccess))
    }

    const _handleSelectFilterType = (filterType) => {
        setFilterType(filterType)
        const { key } = filterType
        dispatch(getDataAnalytic({
            type: key, 
            month: Number(moment().format("M")),
            year: Number(moment().format("YYYY"))
        }, callbackSuccess))
    }

    const showSelection = (() => {
        const { key } = filterType
        if(key === KEY_DAY) return `${moment().month(month - 1).format('MMMM')} ${year}`
        return `${year}`
    })()

    const showAnalyticDate = (() => {
        const { key } = filterType
        if(key === KEY_DAY) return `${("0" + day).slice(-2)}/${("0" + month).slice(-2)}/${year}`
        return `T${month}/${year}`
    })()

    return (
        <WrapAnalyticPage>
            <PageHeader>Transaction History</PageHeader>
            <div className="wrap-chart">
                <div className="chart-tools">
                    <div className="wrap-sort">
                        <span>Filter:</span>
                        <DropdownSelectTypeChart 
                            filterType={filterType}
                            setFilterType={_handleSelectFilterType}
                        />
                    </div>
                    <div className="chart-title">
                            {filterType?.key === KEY_DAY ?'Monthly Report: ':'Yearly Report: '}
                            {showSelection}
                    </div>
                </div>
                <RevenueBarChart 
                    maxValue={maxAmount}
                    dataColumn={list}
                    activeDefault={{
                        day,
                        month,
                        year
                    }} 
                    filterKey={filterType.key}
                    setDay={setDay}
                    setMonth={setMonth}
                    setYear={setYear}
                    setDataSelected={setDataSelected}
                />
                <div className="wrap-control-range">
                    <IcArrowDownGray className="left" onClick={_handlePrev} />
                    <span>{showSelection}</span>
                    <IcArrowDownGray className="right" onClick={_handleNext} />
                </div>
            </div>
            <div className="wrap-detail-column-selected">
                <div className="analytic">
                    <span className="analytic-title">Data Report: </span>
                    <span className="time">{showAnalyticDate}</span>
                </div>
                <div className="wrap-analytic-data">
                    <div className="wrap-data-number">
                        <div className="wrap-data">
                            <div className="value">{numberFormatToThousand(dataSelected.totalOrder || 0)}</div>
                            <div className="unit">Total Transaction</div>
                        </div>
                        <div className="wrap-data">
                            <div className="value">{numberFormatToThousand(dataSelected.totalAmount || 0)}</div>
                            <div className="unit">Total Revenue (vnđ)</div>
                        </div>
                        <div className="wrap-data">
                            <div className="value">{dataSelected.percentSuccess || 0}%<span> / {dataSelected.percentFailed || 0}%</span></div>
                            <div className="unit">Success / Fail Rate</div>
                        </div>
                    </div>
                    <div className="wrap-data-chart">
                        <ChartPercentage listPercentage={dataSelected.percentChartData || []} />
                        <div className="wrap-chart-legend">
                            {
                                legends.map((legend, index) => (
                                    <div className="legend" key={legend}><span style={{ background: listColors[index] }} /><span>{legend}</span></div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </WrapAnalyticPage>
    )
}

export default AnalyticPage
