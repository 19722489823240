import styled from 'styled-components'

const WrapPage = styled.div`
    /* padding: 0 30px; */
    height: 100%;
    .wrap-page{
        display: flex;
    }
`;

export {
    WrapPage
}