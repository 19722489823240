import React, { useEffect, useRef, useState } from "react";
import useDebounce from '../../../hook/useDebounce'

// component
import IcSearchGreen from "../../../assets/images/IcSearchGreen";

// styles
import { WrapInputSearch } from "./index.styles";
import { InputFilter } from "../FilterControl/index.styles";

const InputSearch = ({ value, isCheck, handleSearch, className, isDebounce = true, ...props }) => {

  const inputRef = useRef(null)
  const [valueInput, setValueInput] = useState("");
  const [valueDebounce, clearDebounce] = useDebounce(valueInput, 500)

  useEffect(() => {
    inputRef.current.value = value
    setValueInput(value)
  }, [value])

  useEffect(() => {
    if (!isDebounce) return
    handleSearch(valueDebounce)
    return () => {
      clearDebounce()
    }
  }, [valueDebounce, isDebounce]);

  return (
    <WrapInputSearch ref={inputRef} className={className}>
      <IcSearchGreen />
      <InputFilter
        value={value}
        {...props} />
    </WrapInputSearch>
  );
};

export default InputSearch;
