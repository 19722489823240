import React from 'react'

const IcBacklogDefault = (props) => {
    return (
        <svg {...props} width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="70" cy="70" r="70" fill="#B7B7B7"/>
        <path d="M40 46.668V93.3346C40 98.868 53.3333 103.335 70 103.335C86.6667 103.335 100 98.868 100 93.3346V46.668" fill="#B7B7B7"/>
        <path d="M103 71C103 77.0867 88.3333 82 70 82C51.6667 82 37 77.0867 37 71" stroke="#F8F8F8" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M70 57C88.2254 57 103 52.0751 103 46C103 39.9249 88.2254 35 70 35C51.7746 35 37 39.9249 37 46C37 52.0751 51.7746 57 70 57Z" fill="#B7B7B7" stroke="#F8F8F8" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcBacklogDefault
