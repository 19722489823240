import styled from "styled-components";
import { WrapBank } from '../Bank/index.styles'

const WrapFacepayTerminal = styled(WrapBank)`


`

export {
    WrapFacepayTerminal
}