import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { host } from "../../../../../../../../constant/host";

// css
import { WrapPopupLocked } from "./index.styles";
import { OverlayFullScreen } from "../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles";

// image
import IcClose from "../../../../../../../../assets/images/IcClose";
import IcCloseRed from "../../../../../../../../assets/images/IcCloseRed";
import IcRejectOther from "../../../../../../../../assets/images/IcRejectOther";

//component
import Button from "../../../../../../../Control/Button";
import TextArea from "../../../../../../../Control/TextArea";

// redux
import {
  closedPopupLockDetailCustomer,
  lockCustomer,
  lockCustomerBank,
} from "../../../../../../../../redux/customer/customerDetail/action";
import {
  getListCustomerBank,
  getListCustomerFacepay,
} from "../../../../../../../../redux/customer/listCustomer/actionV2";
import {
  getListCustomerBankBacklog,
  getListCustomerFacepayBacklog,
  resetListCustomerBankBacklog,
  resetListCustomerFacepayBacklog,
} from "../../../../../../../../redux/customer/customerBacklog/action";

// constant
import { BANK, FACEPAY } from "../../../../../../../../constant/keys";
import { useOnClickOutside } from "../../../../../../../../hook/useClickOutside";
import trimStartAndStripStr from "../../../../../../../../helper/trimStartAndStripStr";

const PopupLocked = ({ profileId, reason }) => {
  const listReason = [
    ...reason,
    {
      id: 2,
      reason: "Lý do khác",
      webReason: "thực hiện các giao dịch gian lận",
      reasonCode: "W-RS-OTHER",
      iconDefault: <IcRejectOther />,
      isActive: false,
    },
  ];

  const dispatch = useDispatch();
  const refPopup = useRef();

  const {
    condFiltersFacepay,
    listCustomerFacepay,
    condFiltersBank,
    condFiltersBacklog,
    listCustomerBank,
    reqType,
    details,
  } = useSelector((state) => ({
    details: state.customerReducer.customerDetailReducer.details,

    // FACEPAY
    condFiltersFacepay:
      state.customerReducer.listCustomerReducer.condFiltersFacepay,
    listCustomerFacepay:
      state.customerReducer.listCustomerReducer.listCustomerFacepay,

    // BANK
    condFiltersBank: state.customerReducer.listCustomerReducer.condFiltersBank,
    listCustomerBank:
      state.customerReducer.listCustomerReducer.listCustomerBank,

    // BACKLOG
    condFiltersBacklog:
      state.customerReducer.customerBacklogReducer.condFilters,

    // Type
    reqType: state.customerReducer.listCustomerReducer.reqType,
  }));

  const [list, setList] = useState(listReason);
  const [payload, setPayload] = useState({});
  const [valueTextArea, setValueTextArea] = useState("");

  const totalReject = list
    .filter((item) => item !== list[list.length - 1])
    .reduce((total, item) => {
      return item.isActive === true ? total + 1 : total;
    }, 0);

  const resetListReason = () => {
    let listDefault = [...list];
    listDefault = list.filter((item) => (item.isActive = false));
    return listDefault;
  };

  const callbackSuccess = () => {
    switch (reqType) {
      case FACEPAY:
        dispatch(
          getListCustomerFacepay({
            ...condFiltersFacepay,
            lastItem: "",
            limit:
              listCustomerFacepay.length === 0
                ? 15
                : listCustomerFacepay.length,
          })
        );
        const callbackSuccessListBacklogFacepay = () => {
          if (condFiltersBacklog.uid) {
            dispatch(
              getListCustomerFacepayBacklog({
                adminId: condFiltersBacklog.adminId,
                adminUserName: condFiltersBacklog.adminUserName,
                uid: condFiltersBacklog.uid,
                type: condFiltersBacklog.type.key,
                skip: condFiltersBacklog.skip,
                limit: condFiltersBacklog.limit,
                begin: condFiltersBacklog.begin,
                end: condFiltersBacklog.end,
              })
            );
          }
        };
        dispatch(
          resetListCustomerFacepayBacklog(callbackSuccessListBacklogFacepay)
        );
        break;

      case BANK:
        dispatch(
          getListCustomerBank({
            ...condFiltersBank,
            skipItem: 0,
            limit: listCustomerBank.length === 0 ? 15 : listCustomerBank.length,
          })
        );
        const callbackSuccessListBacklogBank = () => {
          if (condFiltersBacklog.bioId) {
            dispatch(
              getListCustomerBankBacklog({
                adminId: condFiltersBacklog.adminId,
                adminUserName: condFiltersBacklog.adminUserName,
                bioId: condFiltersBacklog.bioId,
                type: condFiltersBacklog.type.key,
                skip: condFiltersBacklog.skip,
                limit: condFiltersBacklog.limit,
                begin: condFiltersBacklog.begin,
                end: condFiltersBacklog.end,
              })
            );
          }
        };
        dispatch(resetListCustomerBankBacklog(callbackSuccessListBacklogBank));
        break;
    }
  };

  const handleSubmit = () => {
    if (reqType === FACEPAY) {
      dispatch(
        lockCustomer(
          {
            uid: profileId,
            mobileReason: payload.mobileReason,
            reason: payload.reason,
            reasonCode: payload.reasonCode,
          },
          callbackSuccess
        )
      );
    } else {
      dispatch(
        lockCustomerBank(
          {
            bioId: profileId,
            mobileReason: payload.mobileReason,
            reason: payload.reason,
            reasonCode: payload.reasonCode,
          },
          callbackSuccess
        )
      );
    }
    handleClose();
  };

  const handleClose = () => {
    dispatch(closedPopupLockDetailCustomer());
    setValueTextArea("");
    setList(resetListReason());
  };

  const handleClick = (index) => {
    const listTemp = [...list];
    for (let i = 0; i < listTemp.length; i++) {
      if (index === i) {
        listTemp[index].isActive = !listTemp[index].isActive;
      } else {
        listTemp[i].isActive = false;
      }
    }
    setValueTextArea("");
    setList(listTemp);
  };

  useEffect(() => {
    list
      .filter((item) => item.isActive)
      .map((item) => {
        if (list[list.length - 1].isActive === false) {
          setPayload({
            mobileReason: item.mobileReason,
            reason: item.webReason,
            reasonCode: item.reasonCode,
          });
        } else {
          setPayload({
            mobileReason: valueTextArea,
            reason: valueTextArea,
            reasonCode: item.reasonCode,
          });
        }
      });
  }, [valueTextArea, list]);

  useOnClickOutside(refPopup, () => handleClose());
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      handleClose();
    }
  });

  return (
    <OverlayFullScreen>
      <WrapPopupLocked ref={refPopup}>
        <div className="popup-header">
          <div className="popup-title">
            <p className="popup-value">Lý do khóa tài khoản</p>
            <div className="icon-close" onClick={handleClose}>
              <IcClose />
            </div>
          </div>
          <div className="value">
            Bạn vui lòng xác nhận lý do khóa tài khoản
          </div>
        </div>
        <div className="popup-content">
          {list?.map((item, index) => (
            <div className="content-item" key={index}>
              <div
                className="content-icon"
                onClick={() => {
                  handleClick(index);
                }}
              >
                {!item.isActive ? (
                  <>
                    {!item.iconDefault ? (
                      <img src={`${host}${item.url}`} />
                    ) : (
                      item.iconDefault
                    )}
                  </>
                ) : (
                  <IcCloseRed />
                )}
              </div>
              <div className="content-value">{item.reason}</div>
            </div>
          ))}
          {list[list.length - 1].isActive === true && (
            <div className="wrap-reasion">
              <TextArea
                placeholder="Nhập nội dung..."
                value={valueTextArea}
                maxLength={120}
                onChange={(e) => setValueTextArea(trimStartAndStripStr(e.target.value))}
              />
            </div>
          )}
        </div>
        <div className="popup-btn">
          <Button
            text="Lưu"
            className={`btn-save ${totalReject > 0 ||
              (list[list.length - 1].isActive && valueTextArea.length > 0)
              ? "active"
              : ""
              }`}
            isDisable={
              list[list.length - 1].isActive || totalReject > 0
                ? totalReject > 0 ||
                  (list[list.length - 1].isActive && valueTextArea.length > 0)
                  ? false
                  : true
                : valueTextArea.length > 0
                  ? false
                  : true
            }
            onClick={handleSubmit}
          />
        </div>
      </WrapPopupLocked>
    </OverlayFullScreen>
  );
};

export default PopupLocked;
