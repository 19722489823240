import React from 'react'

const IcUnlockSystem = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#33ADFF"/>
        <path d="M16.6667 11.332H7.33333C6.59695 11.332 6 11.929 6 12.6654V17.332C6 18.0684 6.59695 18.6654 7.33333 18.6654H16.6667C17.403 18.6654 18 18.0684 18 17.332V12.6654C18 11.929 17.403 11.332 16.6667 11.332Z" fill="white"/>
        <path d="M8.66602 11.3315V8.66482C8.66519 7.83818 8.97154 7.04073 9.5256 6.42726C10.0797 5.81379 10.8419 5.42808 11.6644 5.34501C12.4868 5.26193 13.3108 5.48742 13.9763 5.97769C14.6419 6.46796 15.1015 7.18805 15.266 7.99815" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcUnlockSystem
