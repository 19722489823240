import styled from 'styled-components'

const WrapPage = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    .wrap-expand-filter{
        background: #FFFFFF;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        padding: 15px 40px 22px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 38px;
        width: 100%;
        z-index: 1;
        .title-filter{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            color: #313131;
            margin-bottom: 15px;
        }
        .icon-close{
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;
        }
        .wrap-filter{
            display: flex;
            align-items: center;
            .filter{
                display: flex;
                flex-direction: column;
                :first-of-type{
                    margin: 0 56px 0 0;
                }
                .title{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 15px;
                    color: #777777;
                    margin: 0 0 7px 0;
                }
                .wrap-checkbox{
                    display: flex;
                    align-items: center;
                    .info-check{
                        margin-right: 14.5px;
                        .text{
                            margin-left: 4px;
                        }
                    }
                }
            }
        }
        .wrap-button{
            display: flex;
            align-items: center;
            justify-content: space-between;
            /* margin: 30px 0 0 0; */
            .btn-reset{
                display: flex;
                align-items: center;
                cursor: pointer;
                .reset{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #33ADFF;
                    padding-left: 4px;
                }
            }
            .btn-filter{
                padding: 7px 10.5px 7px 9.5px
            }
        }
    }
`

const WrapFilterTransHistory = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon{
        cursor: pointer;
    }
    
`
const WrapDateRange = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 33px;
    border: 0.5px solid #B7B7B7;
    box-sizing: border-box;
    padding: 9px 14px;
    width: 256px;
    height: 34px;
    .arrow-right {
        transform: rotate(-90deg);
        flex-shrink: 0;
    }
`


export { WrapPage, WrapFilterTransHistory, WrapDateRange }