// image
import IcFacepayMini from "../assets/images/IcFacepayMini"
import IcGS25 from "../assets/images/IcGS25"
import IcOCBMini from "../assets/images/IcOCBMini"
import IcTPBankMini from "../assets/images/IcTPBankMini"
import IcVietinbankMini from "../assets/images/IcVietinbankMini"
import IcBankBIDV from "../assets/images/IcBankBIDV"
import IcBankPVComBank from "../assets/images/IcBankPVComBank"
import IcBankSmartPay from "../assets/images/IcBankSmartPay"
import IcSGBMini from "../assets/images/IcSGBMini"

// constant
import { FACEPAY, PVCB, SGB, GS25 } from "../constant/keys"
import { BIDVVNVX, WBVNVNVX } from "../constant/dataSwiftCodeBank"
import { hostCustomer } from "../constant/host"


export const TPBANK = "TPB"
export const OCB = "OCB"
export const VIETINBANK = "CTG"
export const SMARTPAY = 'SMARTPAY'

export const dataBankType = {
    [FACEPAY]: {
        icon: <IcFacepayMini />,
        text: "Ví Facepay"
    },
    [TPBANK]: {
        icon: <IcTPBankMini />,
        text: "TPBank"
    },
    [OCB]: {
        icon: <IcOCBMini />,
        text: "OCB"
    },
    [VIETINBANK]: {
        icon: <IcVietinbankMini />,
        text: "Vietinbank"
    },
    [BIDVVNVX]: {
        icon: <IcBankBIDV />,
        text: "Facepay-BIDV"
    },
    [WBVNVNVX]: {
        icon: <IcBankPVComBank />,
        text: "Facepay-PVComBank"
    },
    [SMARTPAY]: {
        icon: <IcBankSmartPay />,
        text: "SmartPay"
    },
    [PVCB]: {
        icon: <IcBankPVComBank />,
        text: "PVComBank"
    },
    [SGB]: {
        icon: <IcSGBMini />,
        text: "Sài Gòn Bank"
    },
}

export const dataLogoBank = (listBank) => {
    const logo = listBank.reduce((a, v) =>({...a, [v.supplier] : {
        icon: <img src={`${hostCustomer}/images/banks/1x/${v.logoIconic}.png`} alt="" />,
        bankName: v.bankName
    }}),{})
    return logo
}  

export const dataLogoMerchant = {
    [GS25]: {
        icon: <IcGS25 />,
        text: "GS25"
    },
    [FACEPAY]: {
        icon: <IcFacepayMini />,
        text: "Facepay"
    },
}