import styled from 'styled-components'

const WrapNotifyNewUser = styled.div`
    position: fixed;
    top: 76px;
    right: 0;
    z-index: 20;
`;

export {
    WrapNotifyNewUser
}