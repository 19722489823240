import React from 'react'

const IcLinkCard = (props) => {
    return (
        <svg {...props} width="35" height="29" viewBox="0 0 35 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 21V15M16 18H22M22 10H2M22 12V8.2C22 7.0799 22 6.51984 21.782 6.09202C21.5903 5.7157 21.2843 5.40974 20.908 5.21799C20.4802 5 19.9201 5 18.8 5H5.2C4.0799 5 3.51984 5 3.09202 5.21799C2.7157 5.40973 2.40973 5.71569 2.21799 6.09202C2 6.51984 2 7.0799 2 8.2V15.8C2 16.9201 2 17.4802 2.21799 17.908C2.40973 18.2843 2.71569 18.5903 3.09202 18.782C3.51984 19 4.0799 19 5.2 19H12" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="25.5" cy="19.5" r="9" fill="#33ADFF" stroke="white"/>
        <path d="M29.2777 16.6665L24.0833 21.8609L21.7222 19.4998" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcLinkCard
