import React from 'react'

const IcRefreshWaitingMini = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={8} cy={8} r={8} fill="#FFBA33" />
            <g clipPath="url(#clip0_5256_41375)">
                <path d="M3.11133 11.5573V8.89062H5.77799" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.8893 4.44531V7.11198H10.2227" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.22688 6.66952C4.45229 6.03254 4.83539 5.46303 5.34042 5.01415C5.84546 4.56527 6.45598 4.25164 7.11501 4.10253C7.77404 3.95341 8.46011 3.97367 9.10919 4.16142C9.75827 4.34917 10.3492 4.69828 10.8269 5.17619L12.8891 7.11397M3.11133 8.89174L5.17355 10.8295C5.65122 11.3074 6.24216 11.6565 6.89124 11.8443C7.54033 12.032 8.22639 12.0523 8.88542 11.9032C9.54445 11.7541 10.155 11.4404 10.66 10.9916C11.165 10.5427 11.5481 9.97317 11.7735 9.33619" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5256_41375">
                    <rect width="10.6667" height="10.6667" fill="white" transform="translate(2.66699 2.66797)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcRefreshWaitingMini
