import React from 'react'

const IcNoteDefault = (props) => {
    return (
        <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z" fill="#33ADFF"/>
        <path d="M14 20H20.75" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.375 7.62615C17.6734 7.32778 18.078 7.16016 18.5 7.16016C18.7089 7.16016 18.9158 7.20131 19.1088 7.28126C19.3019 7.36122 19.4773 7.47841 19.625 7.62615C19.7727 7.77388 19.8899 7.94927 19.9699 8.1423C20.0498 8.33533 20.091 8.54221 20.091 8.75115C20.091 8.96008 20.0498 9.16696 19.9699 9.35999C19.8899 9.55302 19.7727 9.72841 19.625 9.87615L10.25 19.2511L7.25 20.0011L8 17.0011L17.375 7.62615Z" fill="white"/>
        </svg>

    )
}

export default IcNoteDefault
