import axios from "axios";

export const downloadByLink = (localLink) => {
    const aEle = document.createElement('a')
    aEle.href = localLink
    aEle.download = ""
    document.body.appendChild(aEle);
    aEle.click()
    document.body.removeChild(aEle);
}

const downloadFileByLink = async (link, isLocalLink=true) => {
    let localLink = ''
    if(!isLocalLink) {
        try {
            const res = await axios.get(link, {
                responseType: 'blob'
            })
            const { data } = res
            localLink = URL.createObjectURL(data)
            downloadByLink(localLink)
            return Promise.resolve(localLink)
        }
        catch(err) {
            console.log(err)
            return Promise.reject(err)
        }
        return;
    }
    localLink = link
    downloadByLink(localLink)
    return Promise.resolve(localLink)
}

export default downloadFileByLink