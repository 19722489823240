import styled from 'styled-components'

const WrapDeviceAdminByStatusView = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    .wrap-list{
        flex-grow: 1;
        position: relative;
        // overflow-x: auto;
        // overflow-y: hidden;
        .wrap-empty {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .wrap-inner-list{   
            // position: absolute;
            width: 100%;
            display: grid;
            grid-auto-columns: 48px;
            grid-template-columns: repeat(auto-fit, 48px);
            grid-auto-rows: 48px;
            column-gap: 4px;
            row-gap: 6px;
        }
    }
`;

export {
    WrapDeviceAdminByStatusView
}