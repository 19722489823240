import styled from 'styled-components'

const WrapCallHistory = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .input-search{
        margin: 20px 0 0 0;
        width: 100%;
        height: 40px;
    }
`
const WrapListCallHistory = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 22px 0 10px 0;
    .wrap-empty {
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
        p{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            color: #B7B7B7;
        }
    }
`

const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;   
    
    .wrap-list-call-history {
        width: calc(100% + 10px);
        flex-grow: 1;
        overflow: overlay;
        position: relative;
        margin-left: 10px;
        .list-call-history {
            width: calc(100% - 10px);
            display: flex;
            flex-direction: column;
            position: absolute;
        }
    }
    
`

export {
    WrapCallHistory,
    WrapListCallHistory,
    TableContent
}