import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//styles
import { WrapCustomer } from './index.styles'

// image
import IcNotifyError from '../../../../../assets/images/IcNotifyError'

//component
import CustomerList from './CustomerList'
import CustomerDetail from './CustomerDetail'
import CustomerInfoExpand from './CustomerInfoExpand'
import CustomerNote from './CustomerNote'
import { popupOpenLockDetailCustomer } from '../../../../../redux/customer/customerDetail/action'
import { envServer, PROD_SV } from '../../../../../constant/environmentTypes'

const filterSize = 20

const CustomerV2 = () => {

    const dispatch = useDispatch()

    const { details } = useSelector(state => ({
        details: state.customerReducer.customerDetailReducer.details,
    }))

    const handleOpenLockCustomer = () => {
        dispatch(popupOpenLockDetailCustomer())
    }

    return (
        <WrapCustomer>
            <CustomerList></CustomerList>

            <div className="customer-content">
                {
                    details?.kycStatus?.state === "REJECTED" ?
                    <div className="error-notify"> 
                        <IcNotifyError className="error-icon" />
                        <div className="errro-text">Dữ liệu yêu cầu cập nhật lại từ phía PayME</div>
                    </div>
                    :
                    details?.isLock && (
                        <div className="error-notify" onClick={handleOpenLockCustomer}> 
                            <IcNotifyError className="error-icon" />
                            <div className="errro-text">Tài khoản bị khóa do {details?.lockReason}</div>
                        </div>
                    )
                }
                <CustomerDetail></CustomerDetail>
                <CustomerInfoExpand></CustomerInfoExpand>
                <CustomerNote></CustomerNote>
            </div>

        </WrapCustomer>
    )
}

export default CustomerV2
