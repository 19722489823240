import React from 'react'

const IcDeposit = (props) => {
    return (
        <svg {...props} width="35" height="29" viewBox="0 0 35 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 12H16" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19 9L16 12L19 15" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 15.8C22 16.9201 22 18.4802 21.782 18.908C21.5903 19.2843 21.2843 19.5903 20.908 19.782C20.4802 20 19.9201 20 18.8 20L7.2 20C6.0799 20 5.51984 20 5.09202 19.782C4.71569 19.5903 4.40973 19.2843 4.21799 18.908C4 18.4802 4 17.9201 4 16.8V14.5M4 9.5V7.2C4 6.0799 4 5.51984 4.21799 5.09202C4.40973 4.7157 4.71569 4.40974 5.09202 4.21799C5.51984 4 6.0799 4 7.2 4H18.8C19.9201 4 20.4802 4 20.908 4.21799C21.2843 4.40973 21.5903 4.71569 21.782 5.09202C22 5.51984 22 6.0799 22 7.2V8.6M9 12C9 11.5353 9 11.303 8.96157 11.1098C8.80376 10.3164 8.18356 9.69624 7.39018 9.53843C7.19698 9.5 6.96465 9.5 6.5 9.5H4.5C4.03534 9.5 3.80302 9.5 3.60982 9.53843C2.81644 9.69624 2.19624 10.3164 2.03843 11.1098C2 11.303 2 11.5353 2 12C2 12.4647 2 12.697 2.03843 12.8902C2.19624 13.6836 2.81644 14.3038 3.60982 14.4616C3.80302 14.5 4.03534 14.5 4.5 14.5H6.5C6.96466 14.5 7.19698 14.5 7.39018 14.4616C8.18356 14.3038 8.80376 13.6836 8.96157 12.8902C9 12.697 9 12.4647 9 12Z" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="25.5" cy="19.5" r="9" fill="#33ADFF" stroke="white"/>
        <path d="M29.2777 16.6665L24.0833 21.8609L21.7222 19.4998" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcDeposit
