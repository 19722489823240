import styled from 'styled-components'

const WrapUserAnalytics = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    .wrap-content{
        .wrap-title{
            background: rgba(247, 247, 247, 0.5);
            border-radius: 4px;
            padding: 11px 0 10px 16px;
            box-sizing: border-box;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            color: #313131;
            &.top{
                margin-top: 20px;
            }
        }
        .wrap-transaction{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 65px);
            grid-gap: 34px 20px;
            padding: 24px 0 24px 16px;
            .content{                
                .value{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                    color: #313131;
                    padding-bottom: 2px;
                }
                .text{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 15px;
                    color: #777777;
                    &.frist{
                        width: 100px;
                    }
                }
            }
        }
        .wrap-chart-container{
            padding: 30px 16px 0 16px;
            .wrap-chart-legend {
                display: flex;
                justify-content: space-between;

                .legend {
                    display: flex;
                    flex-direction: column;
                    .legend-percent{
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 33px;
                        display: flex;
                        align-items: center;
                        letter-spacing: -0.01em;
                        color: #000000;
                    }
                    span:first-child {
                        margin-right: 3px;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                    }
                    span:last-child {
                        font-family: "Open Sans";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 16px;
                        color: #777777;
                        letter-spacing: -0.02em;
                    }
                }
            }
        }
    }
`

export {
    WrapUserAnalytics
}