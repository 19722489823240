import { CTG, FACEPAY, OCB, PVCB, SMARTPAY, TPBANK } from "./keys"


export const BIDVVNVX = 'BIDVVNVX'
export const WBVNVNVX = 'WBVNVNVX'

export const dataSwiftCodeBank = [
    {  
        key: TPBANK,
        title: 'TPBank',
        isSelect: true
    },
    {
        key: OCB,
        title: 'OCB',
        isSelect: true
    },
    {
        key: CTG,
        title: 'Vietinbank',
        isSelect: true
    },
    {
        key: PVCB,
        title : 'PVCombank',
        isSelect: true,
    },
    {
        key: SMARTPAY,
        title: 'Smartpay',
        isSelect: true
    },
]


export const dataSwiftCodeBankFacepay = [
    {
        key: FACEPAY,
        title : 'ví Facepay',
        isSelect: true,
    },

]

