import React from "react";

const IcBankSmartPay = (props) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="white"/>
    <path d="M10.8907 14.5928C13.1057 14.2608 14.8978 13.7443 16.5322 12.0472C17.7915 10.8388 18.4483 9.10649 18.3172 7.33961C17.9516 3.73883 13.9301 1.2301 9.34945 1.73184C4.2025 2.32951 0.152328 6.6165 2.21684 11.9217C3.34229 14.8142 5.97312 17.0942 8.24551 17.6402C7.77957 16.4596 7.99462 15.5963 8.85483 15.1462C9.50116 14.8533 10.1881 14.6666 10.8907 14.5928Z" fill="#0AB2A9"/>
    <path d="M12.5959 10.1562V11.4045C12.5959 11.5382 12.5682 11.6399 12.5129 11.7095C12.4516 11.7812 12.3584 11.8199 12.2626 11.8134C12.2433 11.8134 12.2187 11.8134 12.1895 11.8093C12.1376 11.802 12.0862 11.7911 12.0358 11.7768C11.9526 11.7506 11.8844 11.6921 11.8473 11.6152C11.7957 11.5269 11.7699 11.3928 11.7699 11.2126V10.1562H11.1523V11.5071C11.1523 11.747 11.2281 11.9377 11.3796 12.079C11.5359 12.2229 11.7452 12.2996 11.9604 12.2919C12.038 12.2901 12.1154 12.2826 12.1919 12.2695C12.2947 12.2515 12.3916 12.2101 12.4747 12.1486L12.5841 12.057V12.1999C12.5841 12.3718 12.5249 12.5006 12.4064 12.5864C12.2879 12.6722 12.0985 12.7151 11.8383 12.7151L11.7209 12.7068V13.1785L11.8595 13.1908C12.7637 13.1908 13.2159 12.855 13.2159 12.1834V10.1562H12.5959Z" fill="white"/>
    <path d="M10.7465 11.0915C10.7465 10.8483 10.7262 10.5791 10.5383 10.3988C10.3375 10.2041 9.98294 10.1421 9.70939 10.1589C8.96982 10.2055 8.89199 10.9685 8.89199 10.9685H9.60762C9.60762 10.9685 9.57953 10.7048 9.86136 10.7295C10.1432 10.7542 10.0373 11.0556 10.0373 11.0556C9.33776 11.0407 9.03014 11.1768 8.89614 11.4749C8.75798 11.7866 8.91225 12.2291 9.25395 12.32C9.55789 12.401 9.8586 12.2398 10.0433 11.999L10.0516 11.9878L10.1856 12.2925H10.8404L11.0306 11.6743H10.7488L10.7465 11.0915ZM10.0483 11.5439C10.0373 11.5762 10.0233 11.6074 10.0064 11.637C9.93267 11.7714 9.78692 11.8486 9.63571 11.8331C9.46026 11.8215 9.34697 11.6147 9.49756 11.4796C9.60486 11.3827 10.046 11.4055 10.046 11.4055H10.0856C10.0856 11.4055 10.0525 11.5359 10.0497 11.5452" fill="white"/>
    <path d="M8.48595 9.57589C8.24696 9.39769 7.9044 9.30859 7.45828 9.30859H6.29688V12.3432H7.01937V11.4475H7.47897C7.85369 11.4475 8.15105 11.378 8.37105 11.2391C8.59104 11.1002 8.72509 10.9367 8.7732 10.7488C8.81379 10.6078 8.83801 10.4624 8.84536 10.3156C8.85574 10.0227 8.72037 9.74442 8.48595 9.57684M7.90823 10.5668C7.80344 10.6753 7.68854 10.7083 7.54653 10.7295C7.50148 10.7342 7.45744 10.7461 7.416 10.7648C7.36085 10.7936 7.34706 10.8487 7.37693 10.9242C7.23124 10.8893 7.06257 10.7436 6.99041 10.5588C6.85805 10.2199 7.11772 9.94596 7.44771 9.90778C7.7414 9.87572 7.99923 10.036 8.02267 10.2661C8.03108 10.3789 7.98896 10.4896 7.90823 10.5668Z" fill="white"/>
    <path d="M5.92464 8.42078C5.86736 8.53879 5.78362 8.64258 5.67966 8.7244C5.56558 8.81289 5.43462 8.87816 5.29447 8.91639C5.12745 8.96309 4.95438 8.98565 4.78071 8.98337C4.60296 8.9857 4.42591 8.9611 4.25582 8.91044C4.11061 8.86768 3.97578 8.79645 3.8595 8.70108C3.74975 8.60905 3.66314 8.49343 3.60642 8.36323C3.54503 8.21528 3.51522 8.05657 3.51885 7.8969H4.42336C4.41553 8.01023 4.45291 8.12211 4.52763 8.20895C4.60041 8.28261 4.70202 8.32202 4.80653 8.3171C4.88596 8.32185 4.96439 8.29764 5.02671 8.24913C5.08112 8.20161 5.11054 8.13243 5.10668 8.06111C5.10823 7.9813 5.06589 7.90684 4.99583 7.86614C4.88424 7.8002 4.76664 7.74457 4.64456 7.69994C4.51953 7.65033 4.39451 7.60072 4.26898 7.55111C4.14782 7.50116 4.03355 7.43649 3.92884 7.35862C3.82926 7.28584 3.74518 7.19466 3.68133 7.09023C3.61439 6.9712 3.58167 6.8366 3.58668 6.70078C3.58193 6.56182 3.617 6.42433 3.68791 6.3039C3.75645 6.19499 3.84962 6.10299 3.96022 6.03501C4.07884 5.96386 4.20918 5.9135 4.34541 5.88618C4.49245 5.85375 4.64265 5.83711 4.79337 5.83657C4.98274 5.83121 5.17149 5.86013 5.35015 5.9219C5.48612 5.97052 5.60988 6.04715 5.71307 6.14663C5.8034 6.23497 5.87256 6.3419 5.91553 6.45968C5.95559 6.57127 5.97629 6.68865 5.97678 6.80695H5.07277C5.07944 6.706 5.0532 6.60559 4.99786 6.5202C4.94379 6.45168 4.8582 6.4144 4.77008 6.42098C4.70036 6.4193 4.63186 6.43908 4.5742 6.47754C4.51698 6.51817 4.48552 6.58511 4.49119 6.65415C4.48832 6.73286 4.52646 6.80761 4.59242 6.85259C4.69046 6.91611 4.79645 6.96697 4.90776 7.0039L5.27777 7.14133C5.40423 7.1878 5.52441 7.24928 5.63562 7.32439C5.74351 7.39695 5.835 7.49051 5.90439 7.59923C5.97957 7.72771 6.01608 7.87443 6.00968 8.02241C6.01137 8.15937 5.9818 8.29498 5.92312 8.41929" fill="white"/>
    <path d="M6.27246 6.41816H7.04071V6.82754C7.19656 6.51709 7.42458 6.3596 7.71925 6.3596C8.0313 6.3596 8.24996 6.52164 8.37525 6.84571C8.51857 6.52164 8.76363 6.3596 9.10791 6.3596C9.2624 6.35559 9.41359 6.40493 9.53639 6.49943C9.65463 6.59042 9.73944 6.71846 9.77744 6.86338C9.80418 6.99964 9.81611 7.13843 9.81302 7.2773V8.85121H9.04527L9.04828 7.47063C9.04828 7.20057 8.94805 7.0668 8.74459 7.0668C8.53461 7.0668 8.42736 7.21824 8.42736 7.52565V8.84667H7.66112V7.47063C7.66112 7.20057 7.56089 7.0668 7.35743 7.0668C7.14795 7.0668 7.04071 7.21824 7.04071 7.52565V8.84667H6.27246V6.41816Z" fill="white"/>
    <path d="M14.2915 7.60731C13.9398 7.60731 13.6546 7.32795 13.6546 6.98334V8.85121H12.8301V6.4033H13.6546V6.98284C13.6549 6.6383 13.9402 6.35919 14.2919 6.35938C14.6436 6.35956 14.9286 6.63897 14.9285 6.98351C14.9284 7.32806 14.6432 7.60731 14.2915 7.60731Z" fill="white"/>
    <path d="M16.3955 8.12065L16.1815 8.85238H15.0596V5.95147L15.9094 5.83594V6.42762H16.5022L16.2717 7.17535H15.9014V8.12065H16.3955Z" fill="white"/>
    <path d="M12.3607 8.22644V7.55962C12.3607 7.28211 12.336 6.97689 12.1106 6.76814C11.8709 6.54702 11.4444 6.47628 11.1183 6.49508C10.2304 6.54455 10.1373 7.41864 10.1373 7.41864H10.9912C10.9912 7.41864 10.9573 7.11788 11.2958 7.14607C11.6343 7.17427 11.5072 7.51609 11.5072 7.51609C10.6671 7.49878 10.2983 7.6541 10.1373 7.99592C9.97006 8.35159 10.1568 8.85666 10.5668 8.96004C10.9315 9.05205 11.2922 8.86853 11.5139 8.59398C11.517 8.58953 11.5206 8.58558 11.5242 8.58112L11.6847 8.92739H12.4707L12.6992 8.22644H12.3607ZM11.5226 8.035C11.5095 8.07291 11.4922 8.10938 11.4712 8.14383C11.3827 8.29719 11.2076 8.38498 11.0262 8.36693C10.8163 8.35407 10.6805 8.11959 10.859 7.96426C10.9881 7.85296 11.5175 7.87918 11.5175 7.87918H11.5653C11.5628 7.87918 11.5247 8.02758 11.5216 8.03748" fill="white"/>
    </svg>
    
  )
};

export default IcBankSmartPay
