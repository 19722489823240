import React from 'react'

const IcChevronLeft = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 12.75L4.5 9L8.25 5.25" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.5 12.75L9.75 9L13.5 5.25" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcChevronLeft