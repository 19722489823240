import styled from 'styled-components'

const Bound = styled.div`
    font-family: 'Open Sans';
    padding: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .wrap-reasion{
        position: relative;
        height: 88px;
        textarea{
            font-family: 'Open Sans';
            width: calc(100% - 22px);
            height: calc(100% - 42px);
            background: #FFFFFF;
            border-radius: 4px;
            border: 0.5px solid #B7B7B7;
            padding: 10px 10px 30px 10px;
            outline: unset;
            resize: none;
            &::placeholder{
                font-family: 'Open Sans';
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #B7B7B7;
            }
        }
        .note-btn{
            position: absolute;
            right: 10px;
            bottom: 4px;
            cursor: pointer;
            &.disable{
                opacity: 0.5;
            }
        }
    }
    .wrap-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        width: 100%;
        height: 100%;
        position: relative;

        .wrap-empty {
            flex-grow: 1;
            display: flex;
            align-items: center;
            position: absolute;
            top: 200px;
            p{
                font-family: 'Open Sans';
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #B7B7B7;
            }
        }
        .wrap-list-trans {
            width: calc(100% + 10px);
            flex-grow: 1;
            overflow: overlay;
            margin-top: 11px;
            z-index: 0;
            margin-left: 10px;
            position: relative;
            .list-trans {
                width: calc(100% - 10px);
                position: absolute;
                display: flex;
                flex-direction: column;
            }
        }
    }

`

export {
    Bound
}