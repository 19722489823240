import React, { useReducer, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

// styled
import { WrapPopupExportFileBusiness } from './index.styles'

// images
import IcExportFileWhite from '../../../../../assets/images/IcExportFileWhite'
import IcCalendarActive from '../../../../../assets/images/IcCalendarActive'
import IcArrowRight from '../../../../../assets/images/IcArrowRight'
import IcClose from '../../../../../assets/images/IcClose'

// control
import Button from '../../../../Control/Button'
import DatePickerItem from '../../../../Control/DatePickerItem'
import { useOnClickOutside } from '../../../../../hook/useClickOutside'
import { exportListBusiness } from '../../../../../redux/business/listBusinessManage/action'



const PopupExportFileBusiness = ({ onClosePopup }) => {

    const refPopup = useRef(null)
    const dispatch = useDispatch()

    const { condFiltersExportBusiness } = useSelector((state) => ({
        condFiltersExportBusiness: state.businessReducer.listBusinessManage.condFiltersExportBusiness
    }))

    const [data, setData] = useReducer(
        (state, action) => ({...state, ...action}), 
        {
            begin: condFiltersExportBusiness.begin,
            end: condFiltersExportBusiness.end,
        }
    )

    const handleExportFile = () => {
        dispatch(exportListBusiness({
            begin: data.begin,
            end: data.end
        }))
        onClosePopup()
    }

    useOnClickOutside(refPopup, () => onClosePopup())

    return (
        <WrapPopupExportFileBusiness ref={refPopup}>
                <div className="icon-close">
                    <IcClose onClick={onClosePopup}/>
                </div>
            <div className='export-content'>
                <div className='export-content-title'>Xuất File</div>
                <div className='export-content-value'>Bạn vui lòng chọn thời gian muốn xuất file?</div>
                <div className="export-content-date">
                    <IcCalendarActive />
                    <div className="date-title">Thời gian:</div>
                    <DatePickerItem
                    className="input-dropdown"
                    selected={data.begin}
                    onChange={(date) => setData({ begin: date ? dayjs(date).startOf('day').toDate() : undefined })}
                    maxDate={data.end ? data.end : new Date()}
                    textEmpty="Từ ngày"
                    dateFormat="dd/MM/yyyy"
                    showTimeSelect={false}
                    />
                    <IcArrowRight />
                    <DatePickerItem
                    className="input-dropdown "
                    selected={data.end}
                    onChange={(date) => setData({ end: date ? dayjs(date).endOf('day').toDate() : undefined })}
                    minDate={data.begin}
                    maxDate={new Date()}
                    textEmpty="Đến ngày"
                    dateFormat="dd/MM/yyyy"
                    showTimeSelect={false}
                    />
                </div>
                <div className='export-content-btn'>
                    <Button
                        text='Xuất file Excel' 
                        iconBtn={<IcExportFileWhite />}
                        onClick={handleExportFile}
                    />
                </div>
            </div>
        </WrapPopupExportFileBusiness>
    )
}

export default PopupExportFileBusiness