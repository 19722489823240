import React from 'react'

const IcStatusView = (props) => {
  return (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.25 8.16667H8.16667V12.25H12.25V8.16667Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.83333 8.16667H1.75V12.25H5.83333V8.16667Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.25 1.75H8.16667V5.83333H12.25V1.75Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.83333 1.75H1.75V5.83333H5.83333V1.75Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcStatusView