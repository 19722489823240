import React from 'react'
import IcInputEmail from '../../../../../assets/images/IcInputEmail'
import IcInputEmailActive from '../../../../../assets/images/IcInputEmailActive'
import IcInputEmailError from '../../../../../assets/images/IcInputEmailError'
import Input from '../../../../Control/Input'

const iconEmail = {
    icon: IcInputEmail,
    iconActive: IcInputEmailActive,
    iconError: IcInputEmailError,
}

const InputEmail = ({ valueInput, onChange, placeholder, ...props }) => {
    return (
        <Input
            type="text"
            placeholder={placeholder}
            inputType="TEXT"
            value={valueInput}
            onChange={onChange}
            iconInput={iconEmail}
            {...props}
        />
    )
}

export default InputEmail
