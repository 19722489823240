import React from 'react'

const IcMaximize = (props) => {
    return (
        <svg {...props} width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.90625 11.5053L0.901046 8.50008L3.90625 5.49487" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.901074 8.50005H7.20488" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.0938 5.49487L16.099 8.50008L13.0938 11.5053" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.0989 8.50005H9.79512" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcMaximize