import React from 'react'

const IcUpdateDoc = (props) => {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={12} cy={12} r={12} fill="#FFBA33" />
            <path d="M13.3334 5.33337H8.00008C7.64646 5.33337 7.30732 5.47385 7.05727 5.7239C6.80722 5.97395 6.66675 6.31309 6.66675 6.66671V17.3334C6.66675 17.687 6.80722 18.0261 7.05727 18.2762C7.30732 18.5262 7.64646 18.6667 8.00008 18.6667H16.0001C16.3537 18.6667 16.6928 18.5262 16.9429 18.2762C17.1929 18.0261 17.3334 17.687 17.3334 17.3334V9.33337L13.3334 5.33337Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6666 15.3334H9.33325" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6666 12.6666H9.33325" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6666 10H9.99992H9.33325" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3333 5.33337V9.33337H17.3333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcUpdateDoc