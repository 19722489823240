import React from 'react'

const IcVietinbankMini = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5543 7.53775H12.4406V11.8211C14.2594 12.0966 15.9786 12.6765 17.5438 13.5036C18.0533 12.432 18.3379 11.2333 18.3379 9.96936C18.3379 5.38434 14.6015 1.66797 9.99745 1.66797C5.3934 1.66797 1.66211 5.38434 1.66211 9.96936C1.66211 11.2306 1.94436 12.4269 2.45139 13.4982C4.01935 12.6714 5.73585 12.0939 7.5543 11.8184V7.53775Z" fill="#005992" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.99744 18.2515C12.9134 18.2515 15.4794 16.7614 16.9714 14.5038C14.8889 13.4066 12.5114 12.7852 9.98957 12.7852C7.47351 12.7852 5.10084 13.4039 3.02344 14.499C4.51275 16.7587 7.07903 18.2515 9.99744 18.2515Z" fill="#D71249" />
        </svg>
    )
}

export default IcVietinbankMini
