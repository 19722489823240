import React, { useEffect, useMemo, useReducer, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'

//styles
import { WrapFilterFacepayBlock, WrapFilterSearch, WrapCheckBox } from './index.styles'

//image
import IcExportFileBlue from "../../../../../../../../assets/images/IcExportFileBlue";

//helper
import removeAccent from "../../../../../../../../helper/removeAccent";
import trimStartAndStripStr from "../../../../../../../../helper/trimStartAndStripStr";

//component
import FilterControl from "../../../../../../../Control/FilterControl";
import { InputFilter } from "../../../../../../../Control/FilterControl/index.styles";
import DropdownFilterCustomer, { FULLNAME_ID, PROFILE_ID, PHONE_NUMBER } from "./DropdownFilterCustomer";

// action
import { updateFacepayCustomerCondsFilter } from "../../../../../../../../redux/customer/listCustomer/actionV2";
import { DEV_SV, envServer } from "../../../../../../../../constant/environmentTypes";


export default function FilterFacepayBlock() {

  const dispatch = useDispatch()
  const inputSearchRef = useRef(null)

  const { condFiltersFacepay } = useSelector(state => ({
    condFiltersFacepay: state.customerReducer.listCustomerReducer.condFiltersFacepay
  }))

  const [filterCond, setFilterCond] = useState(condFiltersFacepay.optionSelected);
  const [valueSearch, setValueSearch] = useState(
    condFiltersFacepay.optionSelected.key === PHONE_NUMBER ? condFiltersFacepay.phone
      :
      condFiltersFacepay.optionSelected.key === PROFILE_ID ? condFiltersFacepay.profileId
        :
        condFiltersFacepay.optionSelected.key === FULLNAME_ID ? condFiltersFacepay.fullname : ""
  );

  useEffect(() => {
    setValueSearch(condFiltersFacepay.optionSelected.key === PHONE_NUMBER ? condFiltersFacepay.phone
      :
      condFiltersFacepay.optionSelected.key === PROFILE_ID ? condFiltersFacepay.profileId
        :
        condFiltersFacepay.optionSelected.key === FULLNAME_ID ? condFiltersFacepay.fullname : "")
  }, [condFiltersFacepay])

  const _handleChangeOptionFilter = (filterOption) => {
    setFilterCond(filterOption)
    setValueSearch("")
    if (inputSearchRef.current) inputSearchRef.current.value = ''
  }

  const dropdownFilter = useMemo(
    () => (
      <DropdownFilterCustomer
        filterCond={filterCond}
        setFilterCond={_handleChangeOptionFilter}
      />
    ),
    [filterCond, setFilterCond]
  );
  
  const _handleSearch = () => {
    const { key } = filterCond
    let phone = ""
    let profileId = ""
    let fullname = ""
    switch (key) {
      case PHONE_NUMBER: {
        phone = valueSearch
        break;
      }
      case PROFILE_ID: {
        profileId = valueSearch
        break;
      }
      case FULLNAME_ID: {
        fullname = valueSearch
        break;
      }
      default:
        return;
    }
    dispatch(updateFacepayCustomerCondsFilter({
      condFiltersFacepay: {
        optionSelected: filterCond,
        lastItem: "",
        limit: 15,
        phone,
        profileId,
        fullname,
      },
      isScroll: false
    }))
  }

  const filterComponent = useMemo(() => {
    const { key } = filterCond;
    switch (key) {
      case PHONE_NUMBER: {
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder="Nhập số điện thoại..."
            maxLength={10}
            ref={inputSearchRef}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                _handleSearch();
              }
            }}
            onChange={(e) => setValueSearch(removeAccent(trimStartAndStripStr(e.target.value)))}
          />
        );
      }
      case PROFILE_ID: {
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder="Nhập profile ID..."
            ref={inputSearchRef}
            // maxLength={20}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                _handleSearch();
              }
            }}
            onChange={(e) => setValueSearch(removeAccent(trimStartAndStripStr(e.target.value)))}
          />
        );
      }
      case FULLNAME_ID: {
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder="Nhập họ tên..."
            ref={inputSearchRef}
            // maxLength={20}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                _handleSearch();
              }
            }}
            onChange={(e) => setValueSearch(trimStartAndStripStr(e.target.value))}
          />
        );
      }
      default:
        return null;
    }
  }, [JSON.stringify(filterCond), _handleSearch])

  return (
    <WrapFilterFacepayBlock>
      <WrapFilterSearch>
        <FilterControl
          className="filter-search"
          dropdownFilter={dropdownFilter}
          filterComponent={filterComponent}
          handleSearch={_handleSearch}
          isCheck={true}
          value={valueSearch}
          isShowSubmitBtn={true}
        />
        {/* {
          envServer === DEV_SV &&
          <div title="Xuất file dữ liệu">
            <IcExportFileBlue className="icon-export" />
          </div>
        } */}
      </WrapFilterSearch>
    </WrapFilterFacepayBlock>
  )
}
