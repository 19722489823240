import Axios from 'axios'
//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import mapErrors from '../../../helper/mapErrors'
import { disableLoading, enableLoading } from '../../loading/action'
//types
import * as types from './types'

const apiGetDetailsCensorship = `${host}/facepay/user/userbyid`
const apiApprovedUser = `${host}/facepay/user/approved`
const apiRejectUser = `${host}/facepay/user/reject`
const apiGetListSameFace = `${host}/facepay/user/userbyids`

let isCalling = false

export const getDetailsCensorship = ({ uid }, callbackSuccess, callbackError) => async dispatch => {

    if (isCalling) return
    isCalling = true

    const dataRequest = JSON.stringify({
        uid,
    })
    dispatch(enableLoading())
    try {
        const res = await Axios.post(apiGetDetailsCensorship, dataRequest)

        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_DETAIL_CENSORSHIP,
                payload: {
                    details: data.data || {}
                }
            })
            isCalling = false
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_DETAIL_CENSORSHIP_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_DETAIL_CENSORSHIP_ERR))
    }
    isCalling = false
    dispatch(disableLoading())

}

export const resetDetailCensorship = () => async (dispatch) => {
    dispatch({ type: types.RESET_DETAIL_CENSORSHIP })
}

export const apiGetApprovedUser = ({ uid }, callbackSuccess, callbackError) => async dispatch => {

    const dataRequest = JSON.stringify({
        uid,
    })
    dispatch(enableLoading())
    try {
        const res = await Axios.post(apiApprovedUser, dataRequest)

        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.APPROVED_USER_SUCCESS,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            if(data.code === 221){
                dispatch({
                    type: types.APPROVED_USER_WARNING,
                })
            }else {
                dispatch({
                    type: types.APPROVED_USER_ERR,
                })
            }

            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.APPROVED_USER_ERR))
    }
    dispatch(disableLoading())

}

export const apiGetRejectUser = ({ uid, reasons, optionalReason }, callbackSuccess, callbackError) => async dispatch => {

    const dataRequest = JSON.stringify({
        uid,
        reasons,
        optionalReason
    })
    dispatch(enableLoading())
    try {
        const res = await Axios.post(apiRejectUser, dataRequest)

        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.REJECT_USER_SUCCESS,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.REJECT_USER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.REJECT_USER_ERR))
    }
    dispatch(disableLoading())
}

export const getListSameFace = ({ uids }, callbackSuccess, callbackError) => async dispatch => {

    const dataRequest = JSON.stringify({
        uids,
    })

    if (isCalling) return
    isCalling = true

    try {
        const res = await Axios.post(apiGetListSameFace, dataRequest)

        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_SAME_FACE,
                payload: {
                    listSameFace: data.data || []
                }
            })
            isCalling = false
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_LIST_SAME_FACE_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_SAME_FACE_ERR))
    }
    isCalling = false
}

export const resetListSameFace = () => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_SAME_FACE })
}