import styled from 'styled-components'

const WrapInputSearch = styled.div`
    display: flex;
    align-items: center;
    border-radius: 33px;
    border: 0.5px solid #B7B7B7;
    box-sizing: border-box;
    padding: 8px 12px;
    /* width: 224px; */
    height: 34px;
`;

export {
    WrapInputSearch
}