import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

//styles
import * as styles from './index.styles'

//component
import TabListMenu from '../../../../../../Control/TabListMenu'
import Censorship from './Censorship'
import EKYC from './eKYC'
import Device from './Device'

//constant
import { DEV_SV, QC_SV, PROD_SV, SBX_SV } from '../../../../../../../constant/environmentTypes'
import { FACEPAY } from '../../../../../../../constant/keys'

const dataTab = [
  {
    title: 'Kiểm duyệt tài khoản',
    key: 'censorship',
    component: <Censorship />,
    isAlert: false,
    envServerValid: [
      DEV_SV,
      QC_SV,
      PROD_SV,
      SBX_SV
    ]
  },
  {
    title: 'Dữ liệu eKYC',
    key: 'ekyc',
    component: <EKYC />,
    isAlert: false,
    envServerValid: [
      DEV_SV,
      QC_SV,
      PROD_SV,
      SBX_SV
    ]
  },
  {
    title: 'Thiết bị',
    key: 'device',
    component: <Device />,
    isAlert: false,
    envServerValid: [
      DEV_SV,
      QC_SV,
      PROD_SV,
      SBX_SV
    ]
  },
]

export default function MoreInfo() {

  const [component, setComponent] = useState(null)
  const [tabs, setTabs] = useState(dataTab)
  const { reqType, details } = useSelector((state) => ({
    reqType: state.customerReducer.listCustomerReducer?.reqType,
    details: state.customerReducer.customerDetailReducer.details
  }))

  useEffect(() => {
    let listTabs = dataTab
    let tabEKYC = listTabs.find(({key})=>key === 'ekyc')
    if(!tabEKYC) return
    if(!details?.kycStatus || details?.kycStatus?.state !== "REJECTED") tabEKYC.isAlert = false
    else tabEKYC.isAlert = true
    setTabs(listTabs)
    return () => {
      
    }
  }, [details])
  

  const _handleChangeTab = (component) => {
    setComponent(component)
  }

  return (
    <styles.Bound className='block'>
        {reqType === FACEPAY && <TabListMenu data={tabs} componentSelected={_handleChangeTab} />}
        {reqType === FACEPAY && component}
    </styles.Bound>
  )
}
