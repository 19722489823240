import React from 'react'

const IcMinimize = (props) => {
    return (
        <svg {...props} width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.61475 11.5053L7.61995 8.50008L4.61475 5.49487" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.61968 8.50005H1.31587" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.3853 5.49487L9.38005 8.50008L12.3853 11.5053" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.38032 8.50005H15.6841" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcMinimize