import React from 'react'

// Control
import DropdownControl from '../../../../../../Control/DropdownControl'

// key
import { BUSINESS_ID, BUSINESS_NAME, FULL_NAME, PHONE_NUMBER, STORE_NAME } from '../../../../../../../constant/keys'

export const dataSelectionBusiness = [   
    {
        text: "BusinessID",
        key: BUSINESS_ID
    },
    {
        text: "Số điện thoại",
        key: PHONE_NUMBER
    },
    {
        text: "Họ tên người đại diện",
        key: FULL_NAME
    },
    {
        text: "Tên doanh nghiệp",
        key: BUSINESS_NAME
    },
    {
        text: "Tên cửa hàng",
        key: STORE_NAME
    }
]



const DropdownFilterBusiness = ({filterCond: { text, key }, setFilterCond }) => {
    
    const handleSetValueSelect = (val) => {
        setFilterCond(val)
    }
    
    return (
        <DropdownControl
            dataSelection={dataSelectionBusiness}
            valueSelected={{key, text}}
            handleSetValueSelect={handleSetValueSelect}
        />
    )
}

export default DropdownFilterBusiness
