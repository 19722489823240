import React from 'react'

const IcRefuseYellowMini = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={8} cy={8} r={8} fill="#FFBA33" />
            <path d="M8.44434 3.55859H5.33322C5.09748 3.55859 4.87138 3.65224 4.70469 3.81894C4.53799 3.98564 4.44434 4.21173 4.44434 4.44748V11.5586C4.44434 11.7943 4.53799 12.0204 4.70469 12.1871C4.87138 12.3538 5.09748 12.4475 5.33322 12.4475H10.6666C10.9023 12.4475 11.1284 12.3538 11.2951 12.1871C11.4618 12.0204 11.5554 11.7943 11.5554 11.5586V6.66971L8.44434 3.55859Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.44434 3.55859V6.6697H11.5554" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcRefuseYellowMini
