import React from 'react'

const IcEditGray = ({props}) => {
  return (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12554_4334)">
      <path d="M6.41666 2.33337H2.33332C2.0239 2.33337 1.72716 2.45629 1.50837 2.67508C1.28957 2.89388 1.16666 3.19062 1.16666 3.50004V11.6667C1.16666 11.9761 1.28957 12.2729 1.50837 12.4917C1.72716 12.7105 2.0239 12.8334 2.33332 12.8334H10.5C10.8094 12.8334 11.1062 12.7105 11.3249 12.4917C11.5437 12.2729 11.6667 11.9761 11.6667 11.6667V7.58337" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.7917 1.45838C11.0237 1.22632 11.3385 1.09595 11.6667 1.09595C11.9948 1.09595 12.3096 1.22632 12.5417 1.45838C12.7737 1.69045 12.9041 2.0052 12.9041 2.33338C12.9041 2.66157 12.7737 2.97632 12.5417 3.20838L6.99999 8.75005L4.66666 9.33338L5.24999 7.00005L10.7917 1.45838Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_12554_4334">
      <rect width="14" height="14" fill="white"/>
      </clipPath>
      </defs>
    </svg>

  )
}

export default IcEditGray