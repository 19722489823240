import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// styles
import { SIZE_TOOLTIP, WrapChart } from './index.styles';

// constant
import { KEY_MONTH } from '../DropdowSelectTypeChart';

// helper
import numberFormatToThousand from '../../../../../../helper/numberFormatToThousand';

const COLOR_ACTIVE = "#33ADFF"
const COLOR_BAR_COL_NORMAL = "#F7F7F7"
const COLOR_TICK_AND_DATA_LABEL_NORMAL = "#777777"

Chart.defaults.font.family = "Open Sans"

const genArrByCond = (len, valNormal, valActive, isFilterMonth, month, day) => new Array(len).fill(valNormal).map((item, index) => (
    isFilterMonth 
        ? month - 1 === index
            ? valActive
            : item
        : day - 1 === index
            ? valActive
            : item
))

const initialPosTooltip = {
    top: "-100%",
    left: 0
}

const RevenueBarChat = ({ 
    maxValue,
    dataColumn=[], 
    activeDefault: { day, month, year }, 
    filterKey,
    setDay,
    setMonth,
    setYear,
    setDataSelected
}) => {

    const canvasRef = useRef()
    const chartRef = useRef(null)
    const wrapChart = useRef(null)
    const tooltipRef = useRef(null)

    // const [posTooltip, setPosTooltip] = useState({...initialPosTooltip});
    const [dataTooltip, setDataTooltip] = useState({
        date: '',
        totalRevenue: '',
        totalTransaction: ''
    });

    const _handleGetCol = (indexCol) => {
        setDataSelected(dataColumn[indexCol])
        const isFilterMonth = filterKey === KEY_MONTH
        if(isFilterMonth) {
            setMonth(indexCol + 1)
            return;
        }
        setDay(indexCol + 1)
    }

    const _updateChartWhenClickCol = (chart, index) => {
        chart.activeColumn = index
        _handleGetCol(index)

        // set color column
        chart.data.datasets[0].backgroundColor = 
            chart.data.datasets[0].backgroundColor.map((item, index) => 
                index === index ? COLOR_ACTIVE : COLOR_BAR_COL_NORMAL
            )
        
        // set color tick
        chart.config.options.scales.x.ticks.font.weight = 
            chart.config.options.scales.x.ticks.font.weight.map((item, index) => 
                index === index ? 'bold' : 'normal'
            )
        chart.config.options.scales.x.ticks.color = 
            chart.config.options.scales.x.ticks.color.map((item, index) => 
                index === index ? COLOR_ACTIVE : COLOR_TICK_AND_DATA_LABEL_NORMAL
            )
        chart.update()
    }

    const _configChart = (isFilterMonth=false) => {

        const data = {
            labels: new Array(dataColumn.length).fill(null).map((_, index) => index + 1),
            datasets: [{
                data: dataColumn.map(item => item.totalAmountBarChart),
                backgroundColor: genArrByCond(dataColumn.length, COLOR_BAR_COL_NORMAL, COLOR_ACTIVE, isFilterMonth, month, day),
                barPercentage: 0.5,
            }],
        }

        const options = {
            // responsive: isFilterMonth,
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawBorder: false
                    },
                    ticks: {
                        font: {
                            weight: genArrByCond(dataColumn.length, 'normal', 'bold', isFilterMonth, month, day)
                        },
                        color: genArrByCond(dataColumn.length, COLOR_TICK_AND_DATA_LABEL_NORMAL, COLOR_ACTIVE, isFilterMonth, month, day)
                    }
                },
                y: {
                    display: false,
                    max: maxValue ?  maxValue * 1.25 : 100
                }
            },
            onClick: function(e) {
                // console.log(this)
                const points = this.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);

                if (points.length) {
                    const firstPoint = points[0];
                    const label = this.data.labels[firstPoint.index];
                    const value = this.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
                    // console.log(label)
                    // console.log(value)


                    this.activeColumn = firstPoint.index
                    _handleGetCol(firstPoint.index)

                    // set color column
                    this.data.datasets[firstPoint.datasetIndex].backgroundColor = 
                        this.data.datasets[firstPoint.datasetIndex].backgroundColor.map((item, index) => 
                            index === firstPoint.index ? COLOR_ACTIVE : COLOR_BAR_COL_NORMAL
                        )
                    
                    // set color tick
                    this.config.options.scales.x.ticks.font.weight = 
                        this.config.options.scales.x.ticks.font.weight.map((item, index) => 
                            index === firstPoint.index ? 'bold' : 'normal'
                        )
                    this.config.options.scales.x.ticks.color = 
                        this.config.options.scales.x.ticks.color.map((item, index) => 
                            index === firstPoint.index ? COLOR_ACTIVE : COLOR_TICK_AND_DATA_LABEL_NORMAL
                        )
                    this.update()
                    // _updateChartWhenClickCol(this, firstPoint.index)
                }
            },
            onHover: function(e) {
                // console.log(e)
                const { x, y } = e
                const points = this.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);
                // console.log(points)
                const point = points[0]
                if(!point) {
                    tooltipRef.current.style.top = "-100%"
                    tooltipRef.current.style.left = 0
                    return;
                }
                const { index } = points[0] || {}
                const dataCol = dataColumn[index]
                if(!dataCol) {
                    tooltipRef.current.style.top = "-100%"
                    tooltipRef.current.style.left = 0
                    return;
                }
                // console.log(dataCol)
                // console.dir(canvasRef.current)
                const { offsetHeight, offsetWidth } = canvasRef.current
                
                const newTop = (() => {
                    const newY = y - SIZE_TOOLTIP/2
                    if(newY < 0) {
                        tooltipRef.current.classList.remove('bottom')
                        tooltipRef.current.classList.add('top')
                        return y + 10
                    }
                    const outOfHeight = newY + SIZE_TOOLTIP > offsetHeight 
                    if(outOfHeight) {
                        tooltipRef.current.classList.remove('top')
                        tooltipRef.current.classList.add('bottom')
                        return y - SIZE_TOOLTIP - 10
                    }
                    
                    tooltipRef.current.classList.remove('top')
                    tooltipRef.current.classList.remove('bottom')
                    return newY
                })()
                
                const newLeft = (() => {
                    const outOfWidth = SIZE_TOOLTIP + x + 20 > offsetWidth
                    if(outOfWidth) {
                        tooltipRef.current.classList.add('reverse')
                        return x - SIZE_TOOLTIP - 20
                    }
                    tooltipRef.current.classList.remove('reverse')
                    return x + 20
                })()

                tooltipRef.current.style.top = `${newTop}px`
                tooltipRef.current.style.left = `${newLeft}px`

                const { totalAmount, totalOrder } = dataCol
                setDataTooltip({
                    date: filterKey === KEY_MONTH 
                        ? `${("0" + (index + 1)).slice(-2)}/${year}`
                        : `${("0" + (index + 1)).slice(-2)}/${("0" + month).slice(-2)}/${year}`,
                    totalRevenue: numberFormatToThousand(totalAmount),
                    totalTransaction: numberFormatToThousand(totalOrder),
                })
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                },
                datalabels: {
                    display: false,
                    // anchor: 'end',
                    // align: 'top',
                    // display: function(context) {
                    //     return context.dataIndex === context.chart.activeColumn
                    // },
                    // formatter: function(value, context) {
                    //     // return `${new Intl.NumberFormat('de-DE').format(value)} triệu\n`;
                    //     return `${new Intl.NumberFormat('de-DE').format(value)}\n`;
                    // },
                    // color: (context) => {
                    //     return context.dataIndex === context.chart.activeColumn
                    //     ? COLOR_ACTIVE
                    //     : COLOR_TICK_AND_DATA_LABEL_NORMAL
                    // },
                    // font: (context) => {
                    //     return context.dataIndex === context.chart.activeColumn
                    //     ? { weight: 'bold' }
                    //     : { weight: 'normal' }
                    // },
                    // listeners: {
                    //     click: function(context) {
                    //         const dataIndex = context.dataIndex
                    //         context.chart.activeColumn = dataIndex
                    //         _handleGetCol(dataIndex)

                    //         // set color column
                    //         context.chart.data.datasets[0].backgroundColor = 
                    //             context.chart.data.datasets[0].backgroundColor.map((item, index) => 
                    //                 index === dataIndex ? COLOR_ACTIVE : COLOR_BAR_COL_NORMAL
                    //             )
                            
                    //         // set color tick
                    //         context.chart.config.options.scales.x.ticks.font.weight = 
                    //             context.chart.config.options.scales.x.ticks.font.weight.map((item, index) => 
                    //                 index === dataIndex ? 'bold' : 'normal'
                    //             )
                    //         context.chart.config.options.scales.x.ticks.color = 
                    //             context.chart.config.options.scales.x.ticks.color.map((item, index) => 
                    //                 index === dataIndex ? COLOR_ACTIVE : COLOR_TICK_AND_DATA_LABEL_NORMAL
                    //             )
                    //         context.chart.update()
                    //         // _updateChartWhenClickCol(context.chart, dataIndex)
                    //     }
                    // }
                }
            }
        }
        
        chartRef.current = new Chart(canvasRef.current, {
            plugins: [ChartDataLabels],
            type: 'bar',
            data,
            options,
        })
    }

    useEffect(() => {
        _configChart()
    }, []);

    useEffect(() => {
        // console.log(chartRef.current)
        chartRef.current.destroy()
        const isFilterMonth = filterKey === KEY_MONTH
        _configChart(isFilterMonth)
        setTimeout(() => {
            chartRef.current.activeColumn = isFilterMonth ? month - 1 : day - 1
            // console.log(chartRef.current)
            chartRef.current.update()
        }, 500)
    }, [JSON.stringify(dataColumn)]);

    // useEffect(() => {
    //     const transformScroll = (event) => {
    //         if (!event.deltaY) {
    //             return;
    //         }
            
    //         event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
    //         event.preventDefault();
    //     }
        
    //     wrapChart.current.addEventListener('wheel', transformScroll);
    // }, []);

    return (
        <WrapChart ref={wrapChart}>
            <canvas ref={canvasRef} width="2900" height="500" />
            <div className="tooltip-col" ref={tooltipRef}>
                <div className="date">{dataTooltip.date}</div>
                <div className="wrap-data">
                    <div className="wrap-total">
                        <div className="value">{dataTooltip.totalRevenue}</div>
                        <div className="title">Total Revenue (vnđ)</div>
                    </div>
                    <div className="wrap-total">
                        <div className="value">{dataTooltip.totalTransaction}</div>
                        <div className="title">Total Transaction</div>
                    </div>
                </div>
            </div>
        </WrapChart>
    )
}

export default RevenueBarChat
