import React from 'react'

const IcCheckOutlineBlue = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.5" cy="8" r="8" fill="#33ADFF"/>
        <path d="M12.5 5L7 10.5L4.5 8" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcCheckOutlineBlue
