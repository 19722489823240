export const GET_LIST_CUSTOMER = "GET_LIST_CUSTOMER"
export const GET_CUSTOMER_SEARCH_BIOMERTRIC_ID = "GET_CUSTOMER_SEARCH_BIOMERTRIC_ID"
export const GET_CUSTOMER_SEARCH_PROFILE_ID = "GET_CUSTOMER_SEARCH_PROFILE_ID"
export const GET_CUSTOMER_SEARCH_INTERVAL = "GET_CUSTOMER_SEARCH_INTERVAL"

export const GET_LIST_CUSTOMER_ERR = "GET_LIST_CUSTOMER_ERR"
export const GET_CUSTOMER_SEARCH_BIOMERTRIC_ID_ERR = "GET_CUSTOMER_SEARCH_BIOMERTRIC_ID_ERR"
export const GET_CUSTOMER_SEARCH_PROFILE_ID_ERR = "GET_CUSTOMER_SEARCH_PROFILE_ID_ERR"
export const GET_CUSTOMER_SEARCH_INTERVAL_ERR = "GET_CUSTOMER_SEARCH_INTERVAL_ERR"

export const RESET_LIST_CUSTOMER = "RESET_LIST_CUSTOMER" 

export const UPDATE_FACEPAY_CUSTOMER_CONDFILTERS = "UPDATE_FACEPAY_CUSTOMER_CONDFILTERS"
export const RESET_FACEPAY_CUSTOMER_CONDFILTERS = "RESET_FACEPAY_CUSTOMER_CONDFILTERS"

export const GET_LIST_CUSTOMER_FACEPAY = "GET_LIST_CUSTOMER_FACEPAY"
export const GET_LIST_CUSTOMER_FACEPAY_ERR = "GET_LIST_CUSTOMER_FACEPAY_ERR"
export const RESET_LIST_CUSTOMER_FACEPAY = "RESET_LIST_CUSTOMER_FACEPAY"

export const UPDATE_BANK_CUSTOMER_CONDFILTERS = "UPDATE_BANK_CUSTOMER_CONDFILTERS"
export const RESET_BANK_CUSTOMER_CONDFILTERS = "RESET_BANK_CUSTOMER_CONDFILTERS"

export const GET_LIST_CUSTOMER_BANK = "GET_LIST_CUSTOMER_BANK"
export const GET_LIST_CUSTOMER_BANK_ERR = "GET_LIST_CUSTOMER_BANK_ERR"
export const RESET_LIST_CUSTOMER_BANK = "RESET_LIST_CUSTOMER_BANK"

export const LOADING_GET_LIST_CUSTOMER = "LOADING_GET_LIST_CUSTOMER"

export const GET_LIST_CUSTOMER_CHANGE_TAB = "GET_LIST_CUSTOMER_CHANGE_TAB"

