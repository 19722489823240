import React, { useState, useEffect } from 'react'

//style
import { Bound } from './index.styles'

//image
import IcTransPhotoDefault from '../../../../../../../assets/images/IcTransPhotoDefault';


const PopupMatchImage = ({ data, className }) => {


    const [isErrAvatar, setIsErrAvatar] = useState(false);

    useEffect(() => {
        setIsErrAvatar(false)
    }, [data.id])


    return (
        <Bound className={className}>
            {
                isErrAvatar ?
                    <div className="wrap-image">
                        <IcTransPhotoDefault />
                    </div>
                    :
                    <img
                        className="avatar"
                        src={data?.fullFaceUrl || ""}
                        alt="face-customer"
                        onError={() => setIsErrAvatar(true)}
                    />
            }
            <div className="wrap-content">
                <p className="name">Họ tên</p>
                <p className="value fullname">{data?.fullName || "-"}</p>
            </div>
            <div className="wrap-content">
                <p className="name">Profile ID</p>
                <p className="value">{data?.profileId || "-"}</p>
            </div>
            <div className="wrap-content">
                <p className="name">Số điện thoại</p>
                <p className="value">{data?.phone || "-"}</p>
            </div>
        </Bound>
    )
}

export default PopupMatchImage
