import React from 'react'
import IcInputUser from '../../../../../assets/images/IcInputUser'
import IcInputUserActive from '../../../../../assets/images/IcInputUserActive'
import IcInputUserError from '../../../../../assets/images/IcInputUserError'
import Input from '../../../../Control/Input'



const iconUserName = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}

const InputUserName = ({ valueInput, onChange, placeholder, ...props }) => {
    return (
        <Input
            type="text"
            placeholder={placeholder}
            inputType="TEXT"
            value={valueInput}
            onChange={onChange}
            iconInput={iconUserName}
            {...props}
        />
    )
}

export default InputUserName
