import styled from 'styled-components'
import { WrapTransHistoryDetailsItem } from '../../../../../CustomerV2/CustomerInfoExpand/TransactionHistory/TransHistoryItem/TransHistoryDetailsItem/index.styles'

const WrapPopupTransHistoryDetailsWalletDepositLinkCard = styled(WrapTransHistoryDetailsItem)`
    .wrap-info-transaction{
        display: block;
        .info-transaction{
            width: 100%;
            .block-info{
                .wrap-info{
                    grid-template-columns: 220px 220px 220px;
                }
            }
            .block-info-customer{
                margin-bottom: 6px;
                .wrap-info-customer{
                    grid-template-columns: 220px 220px 220px;
                }
            }
            .info-customer{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #33ADFF;
                margin-top: 50px;
            }
            .wrap-value-title{
                display: flex;
                justify-content: space-between;
                .wrap-value-note{
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    position: relative;
                    .wrap-note-transaction{
                        .note-title{
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 11px;
                            line-height: 15px;
                            letter-spacing: -0.02em;
                            color: #313131;
                        }
                    }
                    .ic-note{
                        cursor: pointer;
                    }
                    .note{
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 11px;
                        line-height: 15px;
                        letter-spacing: -0.02em;
                        color: #313131;
                    }
                }
            }
        }
    }
`

export {
    WrapPopupTransHistoryDetailsWalletDepositLinkCard
}