import React, { useRef } from 'react'

// component
import IcCompareArrow from '../../../../../../../../assets/images/IcCompareArrow'
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'
import Button from '../../../../../../../Control/Button'

// styles
import { OverlayFullScreen } from '../../PopupShowMedia/index.styles'
import { WrapPopupConfirmRefund } from './index.styles'

const PopupConfirmRefund = ({
    bankTrxCode, 
    handleClose, 
    handleSubmit 
}) => {
    const refPopup = useRef()

    useOnClickOutside(refPopup, ()=>handleClose())
    window.addEventListener("keydown",(e)=>{
        if (e.key === "Escape"){
            handleClose()
        }
    })

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupConfirmRefund ref= {refPopup}>
                    <div className="title">Xác nhận hoàn tiền</div>
                    <div className="sub-title">
                        Bạn vui lòng xác nhận hoàn tiền giao dịch <span title={`#${bankTrxCode}`}>“#{bankTrxCode}”</span>
                    </div>
                    <Button
                        text="Hoàn tiền"
                        iconBtn={<IcCompareArrow />}
                        className="btn-del"
                        onClick={handleSubmit}
                    />
                    <Button
                        text="Hủy bỏ"
                        className="btn-cancel"
                        onClick={handleClose}
                    />
                </WrapPopupConfirmRefund>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupConfirmRefund
