import dayjs from "dayjs";

const getDate = (prevDays) => {
    const now = dayjs();
    const startDay = now.subtract(prevDays, "day").startOf("day").toDate();
    const endDay = now.subtract(prevDays, "day").endOf("day").toDate();
    return {
      startDay: startDay,
      endDay: endDay,
    };
};

export default getDate