import React from 'react'

const IcResetBlue = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.416 11.668V8.16797H9.91602" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.583984 2.33203V5.83203H4.08398" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.9532 5.24862C11.6573 4.41258 11.1545 3.66511 10.4916 3.07595C9.82877 2.48679 9.02746 2.07515 8.16248 1.87944C7.29751 1.68372 6.39704 1.71032 5.54512 1.95674C4.6932 2.20316 3.91759 2.66137 3.29065 3.28862L0.583984 5.83195M13.4173 8.16529L10.7107 10.7086C10.0837 11.3359 9.3081 11.7941 8.45618 12.0405C7.60426 12.2869 6.7038 12.3135 5.83882 12.1178C4.97384 11.9221 4.17253 11.5105 3.50967 10.9213C2.84681 10.3321 2.344 9.58466 2.04815 8.74862" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcResetBlue
