import React, {forwardRef} from 'react'

//style
import { ButtonWrapper } from './index.styles'

const Button = forwardRef(({ text, className, onClick, iconBtn, isDisable, ...props}, ref) => {
    return (
        <ButtonWrapper className={`${className}${isDisable ? " disable" : ""}`} ref={ref} onClick={onClick} isDisable={isDisable} {...props}>
            {
                iconBtn && iconBtn
            }
            {text}
        </ButtonWrapper>
    )
})

export default Button
