import React from 'react'

const IcInfoTransCustomer = (props) => {
    return (
        <svg {...props} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99996 13.3332C10.2216 13.3332 12.8333 10.7215 12.8333 7.49984C12.8333 4.27818 10.2216 1.6665 6.99996 1.6665C3.7783 1.6665 1.16663 4.27818 1.16663 7.49984C1.16663 10.7215 3.7783 13.3332 6.99996 13.3332Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 9.83333V7.5" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 5.1665H7.00583" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcInfoTransCustomer
