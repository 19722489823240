import styled from 'styled-components'

const WrapFaceRecognizeInfo = styled.div`   
    font-family: 'Open Sans';
    background: #FFFFFF;
    height: 295px;
    box-sizing: border-box;
    position: relative;
    padding: 9px 17px 12px 20px;

    .title-value{
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #313131;
    }
    .title-face{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;
        position: relative;
        .title-time-update{
            font-family: 'Open Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            gap: 4px;
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #777777;
        }
        .title-right{
            display: flex;
            gap: 15px;
            .title-btn{
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                display: flex;
                align-items: center;
                gap: 5px;
                color: #313131;
                cursor: pointer;
                .value{
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: -0.02em;
                    color: #313131;
                }
            }
        }
    }
    .face-infos {        
        display: flex;
        flex-direction: column;
        .face-kyc {
            display: flex;
            gap: 20px;
            margin-bottom: 30px;
            .wrap-media {
                position: relative;
                width: 300px;
                height: 204px;
                img {
                    border-radius: 4px;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    box-sizing: border-box;
                }
                .wrapper{
                    height: 204px;
                    background: #F7F7F7;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;   
                    pointer-events: auto;
                    cursor: auto;
                }
                &.face-img {
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.recog-video {
                    margin-left: 30px;
                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.4);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
`
export { WrapFaceRecognizeInfo }