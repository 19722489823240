import React from 'react'

const IcBtnNote = (props) => {
    return (
        <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="#33ADFF" />
            <path d="M16 22H22.75H16Z" fill="white" />
            <path d="M16 22H22.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.375 9.62615C19.6734 9.32778 20.078 9.16016 20.5 9.16016C20.7089 9.16016 20.9158 9.20131 21.1088 9.28126C21.3019 9.36122 21.4773 9.47841 21.625 9.62615C21.7727 9.77388 21.8899 9.94927 21.9699 10.1423C22.0498 10.3353 22.091 10.5422 22.091 10.7511C22.091 10.9601 22.0498 11.167 21.9699 11.36C21.8899 11.553 21.7727 11.7284 21.625 11.8761L12.25 21.2511L9.25 22.0011L10 19.0011L19.375 9.62615Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBtnNote
