import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//styles
import { WrapBackLog, WrapListBackLog } from './index.styles'

//component
import FilterBackLog from './FilterBackLog'
import BackLogItem from './BackLogItem'
import EmptyDataControl from '../../../../../../Control/EmptyDataControl'
import LoadingLocal from '../../../../../../Control/LoadingLocal'

// image
import IcBacklogDefault from '../../../../../../../assets/images/IcBacklogDefault'

// redux
import { LOADING_GET_LIST_BACKLOG_CUSTOMER } from '../../../../../../../redux/customer/customerBacklog/types'
import { getListCustomerFacepayBacklog, resetListCustomerFacepayBacklog, resetUpdateListBacklogCustomerCondsFilter, resetListCustomerBankBacklog, getListCustomerBankBacklog, updateListBacklogCustomerCondsFilter } from '../../../../../../../redux/customer/customerBacklog/action'
import { resetListTransactionBankOfCustomer, resetListTransactionFacepayOfCustomer, resetTransactionOfCustomerCondsFilter } from '../../../../../../../redux/customer/customerTransactionHistory/actionV2'

// hook
import useScrollEnd from '../../../../../../../hook/useScrollEnd'
import { resetCustomerAnalytic, resetCustomerBankAnalytic } from '../../../../../../../redux/customer/customerAnalytic/action'


export default function BackLog() {

    const dispatch = useDispatch()
    const listRef = useRef()

    const [isShowPopup, setIsShowPopup] = useState("")
    const { condFilters, listBacklog, isTypeLoading, details } = useSelector((state) => ({
      condFilters: state.customerReducer.customerBacklogReducer.condFilters,
      listBacklog: state.customerReducer.customerBacklogReducer.listBacklog,
      details: state.customerReducer.customerDetailReducer.details, 
      isTypeLoading: state.loading[LOADING_GET_LIST_BACKLOG_CUSTOMER],
    }))

    useEffect(() => {
      dispatch(resetListTransactionBankOfCustomer())
      dispatch(resetListTransactionFacepayOfCustomer())
      dispatch(resetTransactionOfCustomerCondsFilter())
      dispatch(resetCustomerAnalytic())
      dispatch(resetCustomerBankAnalytic())
    },[])

    useEffect(() => {
      if (!details && !details?.id) {
        dispatch(resetListCustomerFacepayBacklog())
        dispatch(resetListCustomerBankBacklog())
        dispatch(resetUpdateListBacklogCustomerCondsFilter())
      }
    }, [details?.id])

    useEffect(() => {
      if(condFilters.uid){
        dispatch(getListCustomerFacepayBacklog(
          {
              adminId: condFilters.adminId,
              adminUserName: condFilters.adminUserName,
              uid: condFilters.uid,
              type: condFilters.type.key,
              limit: condFilters.limit,
              skip: condFilters.skip,
              begin: condFilters.begin,
              end: condFilters.end
          }, false
        ))
        return
      }
      if(condFilters.bioId){
        dispatch(getListCustomerBankBacklog(
          {
              adminId: condFilters.adminId,
              adminUserName: condFilters.adminUserName,
              bioId: condFilters.bioId,
              type: condFilters.type.key,
              limit: condFilters.limit,
              skip: condFilters.skip,
              begin: condFilters.begin,
              end: condFilters.end
          }, false
        ))
        return
      }
    },[JSON.stringify(condFilters)])

    useScrollEnd(listRef, (callback) => {
      if(condFilters.uid){
        dispatch(getListCustomerFacepayBacklog(
          {
              adminId: condFilters.adminId,
              adminUserName: condFilters.adminUserName,
              uid: condFilters.uid,
              type: condFilters.type.key,
              limit: condFilters.limit,
              skip: listBacklog.length,
              begin: condFilters.begin,
              end: condFilters.end
          }, true, callback
        ))
        return
      }
      if(condFilters.bioId){
        dispatch(getListCustomerBankBacklog(
          {
              adminId: condFilters.adminId,
              adminUserName: condFilters.adminUserName,
              bioId: condFilters.bioId,
              type: condFilters.type.key,
              limit: condFilters.limit,
              skip: listBacklog.length,
              begin: condFilters.begin,
              end: condFilters.end
          }, true, callback
        ))
        return
      }
    },[listBacklog], [listBacklog.length])
   
  return (
    <WrapBackLog>
      <FilterBackLog />
      <WrapListBackLog>
        {
          listBacklog?.length === 0 && isTypeLoading ? (
            <LoadingLocal />
          ) : (
            listBacklog?.length === 0 ? (
              <div className="wrap-empty">
                <EmptyDataControl
                  icon={<IcBacklogDefault />}
                  text={"Khách hàng chưa có lịch sử tài khoản"}
                />
              </div>
            ) : (
              <div className="wrap-list-back-log" ref={listRef}>
                <div className="list-back-log">
                    {
                      listBacklog?.map((item, index) => (
                        <BackLogItem data={item} key={index}
                          listRef={listRef}
                          isShowPopup={isShowPopup}
                          setIsShowPopup={setIsShowPopup} />
                      ))
                    }
                </div>
              </div>
            )
          ) 
        }
      </WrapListBackLog>
    </WrapBackLog>
  )
}
