import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapPopupSubmitUpdate } from './index.styles'

// images
import IcClose from '../../../../../../../../assets/images/IcClose'
import IcConfirm from '../../../../../../../../assets/images/IcConfirm'
import Button from '../../../../../../../Control/Button'
import IcCloseOutlineGray from '../../../../../../../../assets/images/IcCloseOutlineGray'
import { OverlayFullScreen } from '../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { businessRequestUpdate, getBusinessDetail, getListBusiness, setIsShowPopupUpdateRequest } from '../../../../../../../../redux/business/listBusinessManage/action'
import TextArea from '../../../../../../../Control/TextArea'
import trimStartAndStripStr from '../../../../../../../../helper/trimStartAndStripStr'
import IcConfirmGray from '../../../../../../../../assets/images/IcConfirmGray'

const PopupUpdateRequest = ({ businessInfo }) => {

    const dispatch = useDispatch()

    const { condFiltersBusiness } = useSelector((state) => ({
        condFiltersBusiness: state.businessReducer.listBusinessManage.condFiltersBusiness,
    }))
    const [valueTextArea, setValueTextArea] = useState('')

    const dataRequest = (condFiltersBusiness, isScroll, listBussiness) => {
        const checkIsSuccessAction = condFiltersBusiness.optionSelectCheckboxBusiness.reduce((a, v) => ({ ...a, [v.key]: v.isSelect }), {})
        return {
            ...checkIsSuccessAction,
            isDenied: false,
            businessCode: condFiltersBusiness.businessCode,
            phoneNumber: condFiltersBusiness.phoneNumber,
            ownerName: condFiltersBusiness.ownerName,
            businessName: condFiltersBusiness.businessName,
            begin: condFiltersBusiness.begin,
            end: condFiltersBusiness.end,
            limit: condFiltersBusiness.limit,
            lastItem: isScroll ? listBussiness.at(-1).id : condFiltersBusiness.lastItem,
        }
    }

    const onClickClosePopup = () => {
        dispatch(setIsShowPopupUpdateRequest({ isShowPopupUpdateRequest: false }))
    }

    const _handleUpdateRequest = () => {
        const callbackSucess = () => {
            dispatch(getBusinessDetail({ businessID: businessInfo.id }))
            dispatch(getListBusiness(dataRequest(condFiltersBusiness, false), false))
            onClickClosePopup()
        }
        dispatch(businessRequestUpdate({
            businessID: businessInfo.id,
            reason: valueTextArea
        }, callbackSucess))
    }

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupSubmitUpdate>
                    <div className='wrap-header'>
                        <div className='wrap-header-text'>
                            Yêu cầu cập nhật hồ sơ
                        </div>
                        <IcClose className='wrap-header-icon' onClick={onClickClosePopup} />
                    </div>
                    <div className='wrap-content'>
                        <div className='wrap-content-value'>
                            Nhập loại giấy tờ yêu cầu cập nhật tới tài khoản BusinessID: <span>{businessInfo.businessCode}</span>
                        </div>
                    </div>
                    <TextArea
                        className={`wrap-content-note ${valueTextArea.length > 0 ? 'active' : ""}`}
                        placeholder='Nhập lý do và loại giấy tờ bạn muốn yêu cầu cập nhật...'
                        maxLength={120}
                        value={valueTextArea}
                        onChange={(e) => setValueTextArea(trimStartAndStripStr(e.target.value))}
                    />
                    <div className='wrap-btn'>
                        <Button
                            iconBtn={valueTextArea ? <IcConfirm /> : <IcConfirmGray />}
                            className={valueTextArea ? 'btn-approved' : 'btn-approved disabled'}
                            text="Xác nhận"
                            onClick={_handleUpdateRequest}
                        />
                    </div>
                </WrapPopupSubmitUpdate>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupUpdateRequest