import * as types from './types'

const initialState = {
    total: {
        pendingCount: 0,
        pendingThanCount: 0,
        rejectCount: 0
    },
}

const censorshipCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CENSORSHIP_COUNT:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

export default censorshipCountReducer;