import styled from 'styled-components'

const WrapFaceRecognizeInfo = styled.div`   
    background: #FFFFFF;
    box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
    display: grid;
    grid-template-columns: 480px 1fr;
    grid-column-gap: 80px;
    height: 295px;
    padding: 13px 20px 23px 20px;
    box-sizing: border-box;
    position: relative;
    .title{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #313131;
    }        
    .face-registration-infos {        
        display: flex;
        flex-direction: column;
        .face-registration {
            margin-top: 16px;
            display: flex;
            .wrap-media {
                position: relative;
                width: 228px;
                img {
                    border-radius: 4px;
                    display: block;
                    width: 100%;
                    object-fit: cover;
                    box-sizing: border-box;
                    height: 228px;
                }
                .wrapper{
                    width: 228px;
                    height: 228px;
                    background: #F7F7F7;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;   
                    pointer-events: auto;
                    cursor: auto;
                }
                &.face-img {                           
                    img {
                        border: 2px solid #33ADFF;
                    }
                    .tools {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        width: 100%;
                        padding: 0 12px 11px 11px;
                        box-sizing: border-box;
                        .accuracy {
                            font-family: "Open Sans";
                            font-style: normal;
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 20px;
                            letter-spacing: -0.01em;
                            color: #33ADFF;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.recog-video {
                    margin-left: 30px;
                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.4);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .match-warning{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
        .title{
            padding-bottom: 16px;
        }
        .list-match{
            overflow: scroll;
            max-height: calc(55px * 4);
            min-height: 225px;
            width: fit-content;
            box-sizing: border-box;
            .empty-data{
                .icon {
                    width:100px;
                    height:100px;
                }
                p{
                    width: 220px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #B7B7B7;
                }
            }
            .wrap-list-same-face{
                .wrap-match-info{
                    width: fit-content;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .match-info{
                        display: flex;
                        align-items: center;
                        .face-print{
                            height: 36px;
                            .face{
                                width: 36px;
                                height: 100%;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }
                        .info-print{
                            display: flex;
                            flex-direction: column;
                            font-family: 'Open Sans';
                            font-style: normal;
                            margin-left: 9px;
                            .full-name{                            
                                font-weight: 600;
                                font-size: 15px;
                                line-height: 20px;
                                color: #313131;
                                padding-bottom: 4px;
                            }
                            .phone-number{
                                font-weight: 400;
                                font-size: 11px;
                                line-height: 15px;
                                color: #777777;
                            }
                        }
                    }
                }
                .popup-match{
                    position: absolute;
                    bottom: 5px;
                    left: 795px;
                    z-index: 1;
                    visibility: hidden;
                }    
                &:hover{                    
                    cursor: pointer;
                    .popup-match{
                        visibility: visible;
                    }
                }
            }
        }
    }
`
export { WrapFaceRecognizeInfo }