import React from 'react'

const IcWarningRed = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99967 14.6654C11.6816 14.6654 14.6663 11.6806 14.6663 7.9987C14.6663 4.3168 11.6816 1.33203 7.99967 1.33203C4.31778 1.33203 1.33301 4.3168 1.33301 7.9987C1.33301 11.6806 4.31778 14.6654 7.99967 14.6654Z" stroke="#F45C5C" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 10.668H8.00667" stroke="#F45C5C" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 5.33203V7.9987" stroke="#F45C5C" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcWarningRed
