import React from 'react'

const IcMoreOptionBlue = (props) => {
  return (
    <svg {...props} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.49999 14.6667C8.89119 14.6667 9.20832 14.3495 9.20832 13.9583C9.20832 13.5671 8.89119 13.25 8.49999 13.25C8.10879 13.25 7.79166 13.5671 7.79166 13.9583C7.79166 14.3495 8.10879 14.6667 8.49999 14.6667Z" fill="#33ADFF" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.49999 9.70829C8.89119 9.70829 9.20832 9.39116 9.20832 8.99996C9.20832 8.60876 8.89119 8.29163 8.49999 8.29163C8.10879 8.29163 7.79166 8.60876 7.79166 8.99996C7.79166 9.39116 8.10879 9.70829 8.49999 9.70829Z" fill="#33ADFF" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.49999 4.75004C8.89119 4.75004 9.20832 4.43291 9.20832 4.04171C9.20832 3.65051 8.89119 3.33337 8.49999 3.33337C8.10879 3.33337 7.79166 3.65051 7.79166 4.04171C7.79166 4.43291 8.10879 4.75004 8.49999 4.75004Z" fill="#33ADFF" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcMoreOptionBlue