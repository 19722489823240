import { combineReducers } from "redux";
import transactionOtherReducer from './transactionOther/transactionOtherReducer'
import listTransactionBankReducer from './listTransactionBank/listTransactionBankReducer'
import listTransactionFacepayReducer from './listTransactionFacepayTerminal/listTransactionFacepayTerminalReducer'
import listTransactionFacepayWalletReducer from './listTransactionWalletFacepay/listTransactionWalletFacepayReducer'

const transactionReducer = combineReducers({
    listTransactionBankReducer,
    listTransactionFacepayReducer,
    listTransactionFacepayWalletReducer,
    transactionOtherReducer,
})

export default transactionReducer