import React from 'react'

const IcUserRegis = (props) => {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={12} cy={12} r={12} fill="#33ADFF" />
            <path d="M18 18.75V17.25C18 16.4544 17.6839 15.6913 17.1213 15.1287C16.5587 14.5661 15.7956 14.25 15 14.25H9C8.20435 14.25 7.44129 14.5661 6.87868 15.1287C6.31607 15.6913 6 16.4544 6 17.25V18.75" fill="white" />
            <path d="M12 11.25C13.6569 11.25 15 9.90685 15 8.25C15 6.59315 13.6569 5.25 12 5.25C10.3431 5.25 9 6.59315 9 8.25C9 9.90685 10.3431 11.25 12 11.25Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcUserRegis
