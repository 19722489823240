import { combineReducers } from "redux";

import customerCountReducer from './customerCount/customerCountReducer'
// import listFacepayCustomerReducer from './listCustomer/listFacepayCustomerReducer'
// import listBankCustomerReducer from './listCustomer/listBankCustomerReducer'
import customerDetailReducer from "./customerDetail/customerDetailReducer";
import customerTransactionHistoryReducer from "./customerTransactionHistory/customerTransactionHistoryReducer";
import customerBacklogReducer from "./customerBacklog/customerBacklogReducer";
import customerNoteReducer from "./customerNote/customerNoteReducer";
import customerAnalyticReducer from "./customerAnalytic/customerAnalyticReducer";
import listCustomerReducer from './listCustomer/listCustomerReducerV2'

const customerReducer = combineReducers({
    customerCountReducer,
    // listFacepayCustomerReducer,
    // listBankCustomerReducer,
    customerDetailReducer,
    customerTransactionHistoryReducer,
    customerBacklogReducer,
    customerNoteReducer,
    customerAnalyticReducer,
    listCustomerReducer
})

export default customerReducer;