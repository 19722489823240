import React from 'react'

const IcBusinessManage = (props) => {
  return (
    <svg {...props} width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3 3.5H7.7C6.5799 3.5 6.01984 3.5 5.59202 3.71799C5.21569 3.90973 4.90973 4.21569 4.71799 4.59202C4.5 5.01984 4.5 5.5799 4.5 6.7V21.5H20.5V6.7C20.5 5.5799 20.5 5.01984 20.282 4.59202C20.0903 4.21569 19.7843 3.90973 19.408 3.71799C18.9802 3.5 18.4201 3.5 17.3 3.5Z" fill="#33ADFF" />
      <path d="M20.5 21.5V6.7C20.5 5.5799 20.5 5.01984 20.282 4.59202C20.0903 4.21569 19.7843 3.90973 19.408 3.71799C18.9802 3.5 18.4201 3.5 17.3 3.5H7.7C6.57989 3.5 6.01984 3.5 5.59202 3.71799C5.21569 3.90973 4.90973 4.21569 4.71799 4.59202C4.5 5.01984 4.5 5.5799 4.5 6.7V21.5M22.5 21.5H2.5" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 7.5H10.75M8 11.5H10.75M8 15.5H10.75M14.25 7.5H17M14.25 11.5H17M14.25 15.5H17" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

export default IcBusinessManage