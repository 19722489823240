import React from 'react'

const IcFacepay = (props) => {
    return (
        <svg {...props} width={48} height={49} viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.9322 16.5667L0 48.3682L0 32.5007L15.9661 16.5667L0 0.632812H15.9661L31.9322 16.5667Z" fill="url(#paint0_linear_9607_57013IcFacepay)" />
            <path d="M17.9678 32.5007H33.9005L48 16.5667L33.9672 0.632812H17.9678L33.9672 16.5667L17.9678 32.5007Z" fill="url(#paint1_linear_9607_57013IcFacepay)" />
            <defs>
                <linearGradient id="paint0_linear_9607_57013IcFacepay" x1="3.31555" y1="49.9874" x2="20.7647" y2="-7.58131" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00328526" stopColor="#0044C8" />
                    <stop offset="0.8243" stopColor="#2FD1F7" />
                </linearGradient>
                <linearGradient id="paint1_linear_9607_57013IcFacepay" x1="17.9668" y1="16.5614" x2="47.9991" y2="16.5614" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00328526" stopColor="#0044C8" />
                    <stop offset="0.4423" stopColor="#1480DC" />
                    <stop offset={1} stopColor="#2FD1F7" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default IcFacepay
