import React from 'react'

const IcTransHistoryDefault = (props) => {
    return (
        <svg {...props} width={140} height={140} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx={70} cy={70} r={70} fill="#B7B7B7" />
            <path d="M50 36.668L40 50.0013V96.668C40 98.4361 40.7024 100.132 41.9526 101.382C43.2029 102.632 44.8986 103.335 46.6667 103.335H93.3333C95.1014 103.335 96.7971 102.632 98.0474 101.382C99.2976 100.132 100 98.4361 100 96.668V50.0013L90 36.668H50Z" fill="#B7B7B7" />
            <path d="M83.3337 63.332C83.3337 66.8683 81.9289 70.2596 79.4284 72.7601C76.9279 75.2606 73.5365 76.6654 70.0003 76.6654C66.4641 76.6654 63.0727 75.2606 60.5722 72.7601C58.0717 70.2596 56.667 66.8683 56.667 63.332" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40 50H100" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcTransHistoryDefault
