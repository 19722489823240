import React, { useEffect, useRef, useState } from "react";

// component
import IcSearchGreen from "../../../assets/images/IcSearchGreen";
import IcArrowGreenRight from "../../../assets/images/IcArrowGreenRight";

// styles
import { WrapFilterControl } from "./index.styles";
import useDebounce from '../../../hook/useDebounce'
import { dataStatus } from "../../Main/Content/BreadcrumbAndTool/FilterDevices/SelectStatus";

const FilterControl = ({ value, className, isShowSubmitBtn, dropdownFilter, filterComponent, handleSearch, isDebounce = true }) => {

  const inputRef = useRef(null)

  const [valueInput, setValueInput] = useState(dataStatus[0]);
  const [valueDebounce, clearDebounce] = useDebounce(valueInput, 500)

  useEffect(() => {
    inputRef.current.value = value
    setValueInput(value)
  }, [value])

  useEffect(() => {
    if (!isDebounce) return
    handleSearch(valueDebounce)
    return () => {
      clearDebounce()
    }
  }, [valueDebounce]);

  return (
    <WrapFilterControl ref={inputRef} className={className}>
      <div className="icon">
        <IcSearchGreen />
      </div>
      {dropdownFilter}
      {filterComponent}
      {
        !isShowSubmitBtn && <IcArrowGreenRight className="ic-arrow-right" onClick={handleSearch}
        />
      }
      
    </WrapFilterControl>
  );
};

export default FilterControl;
