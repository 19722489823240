import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 16px 0 22px 0;
    .note{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-top: 20px;
    }
`

export {
    Bound
}