export const BANK_ENV = 'bank'
export const WEE_ENV = 'wee'
export const DEV_ENV = 'dev'

export const DEV_SV = 'dev'
export const QC_SV = 'qc'
export const PROD_SV = 'prod'
export const SBX_SV = 'sandbox'

export const env = process.env.REACT_APP_BUILD_ENV || BANK_ENV
export const envServer = process.env.REACT_APP_SERVER_ENV || DEV_SV
export const buildVersion = "21032023"
export const qcVersion = "1.10_QC01"
export const branch = "feature/detail_wallet_transaction"

console.log(`website: ${env === WEE_ENV?"admin":"bank"}`)
console.log(`environment: ${process.env.REACT_APP_SERVER_ENV}`)
console.log(`build: ${buildVersion}`)
console.log(`qc build: ${qcVersion}`)
console.log(`branch: ${branch}`)