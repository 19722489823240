import { dataSelectionFacepayWalletTransaction } from '../../../components/Main/Content/Page/TransactionV2/Bank/FilterTransactionBank/DropdownFilterBank'
import { dataSelectionTime } from '../../../components/Main/Content/Page/TransactionV2/Bank/FilterTransactionBank/DropdownFilterTime'
import { dataSelectCheckBoxExportWallet, dataSelectCheckBoxTypeTransaction, dataSelectCheckboxWallet } from '../../../data/dataSelectCheckbox'
import { currentDay, startDay } from '../listTransactionBank/listTransactionBankReducer'
import * as types from './type'


const intitialState = {
    listTransactionFacepayWallet: [],
    condFiltersTransactionFacepayWallet: {
        limit: 15,
        lastItem: "",
        paymeTxID: "",
        facepayTrxCode: "",
        phoneNumber: "",
        profileId: "",
        begin: startDay,
        end: currentDay,
        OptionSelectCheckbox: dataSelectCheckboxWallet,
        OptionSelectCheckboxTypeTransaction: dataSelectCheckBoxTypeTransaction,
        OptionSelectTime: dataSelectionTime[0],
        OptionSelect: dataSelectionFacepayWalletTransaction[0]
    },

    condFiltersExportTransactionWalletFacepay: {
        begin: startDay,
        end: currentDay,
        OptionSelectCheckboxExportWallet: dataSelectCheckBoxExportWallet
    },

    totalFacepayWallet: {
        totalFailed: 0,
        totalSuccess: 0,
        totalTransaction: 0
    }
}

const listTransactionFacepayWalletReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_LIST_TRANSACTION_PACEPAY_WALLET:
            return {
                ...state,
                listTransactionFacepayWallet: action.payload.isScroll ? [...state.listTransactionFacepayWallet, ...action.payload.listTransactionFacepayWallet] : action.payload.listTransactionFacepayWallet,
                totalFacepayWallet: action.payload.totalFacepayWallet
            }
        
        case types.UPDATE_CONDFILTERS_TRANSACTION_FACEPAY_WALLET:
            return {
                ...state,
                condFiltersTransactionFacepayWallet: {
                    ...state.condFiltersTransactionFacepayWallet,
                    ...action.condFiltersTransactionFacepayWallet
                }
            }
        case types.RESET_CONDFILTERS_TRANSACTION_FACEPAY_WALLET:
            return {
                ...state,
                condFiltersTransactionFacepayWallet: intitialState.condFiltersTransactionFacepayWallet
            }
        default:
            return state
    }
}

export default listTransactionFacepayWalletReducer