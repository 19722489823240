import styled from "styled-components";

const WrapBackLog = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px 10px 16px;
    width: calc(100% - 32px);
    height: 540px;
    overflow: auto;
    .backlog-content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .wrap-empty{
            height: 100%;
            p{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                text-align: center;
                letter-spacing: -0.01em;
                color: #B7B7B7;
            }
        }
        .wrap-list-backlog {
            width: 100%;
            flex-grow: 1;
            position: relative;
            overflow: overlay;
            margin-left: 10px;
            
            .list-backlog {
                width: calc(100% - 10px);
                display: flex;
                flex-direction: column;
                position: absolute;

                /* min-width: 1120px; */
                .backlog-item{
                    margin-top: 14px;

                    &-reason{
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        background: #F7F7F7;
                        border-radius: 6px;
                        padding: 8px;
                        &-icon{
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            svg{
                                weight: 14px;
                                height: 14px;
                            }
                        }
                        &-value{
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13.5px;
                            line-height: 18px;
                            letter-spacing: -0.01em;
                            color: #313131;
                        }
                        &-reject{
                            display: flex;
                            flex-direction: column;
                            background: #F7F7F7;
                            border-radius: 6px;
                            padding: 8px;
                            
                        }
                    }
                    .reason-reject{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                    }
                    &-note{
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 10px;
                        margin-top: 4px;
                        p{
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 14px;
                            letter-spacing: -0.02em;
                            color: #777777;
                        }
                        &-user{
                            display: flex;
                            align-items: center;
                            svg{
                                weight: 11px;
                                height: 11px;
                            }
                        }
                    }
                }
            }
        }
        
    }
    
    

`
export { WrapBackLog }