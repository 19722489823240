
export const GET_LIST_TRANSACTION_BANK = 'GET_LIST_TRANSACTION_BANK'
export const GET_LIST_TRANSACTION_BANK_ERR = 'GET_LIST_TRANSACTION_BANK_ERR'


export const RESET_LIST_TRANSACTION_BANK = 'RESET_LIST_TRANSACTION_BANK'

export const UPDATE_LIST_TRANSACTION_BANK = 'UPDATE_LIST_TRANSACTION_BANK'
export const RESET_TRANSACTION_BANK_CONDFILTERS = 'RESET_TRANSACTION_BANK_CONDFILTERS'

export const EXPORT_LIST_TRANSACTION_BANK_ERR = 'EXPORT_LIST_TRANSACTION_BANK_ERR'

// POPUP
export const SHOW_POPUP_EXPORT_TRANSACTION_BANK = 'SHOW_POPUP_EXPORT_TRANSACTION_BANK'
export const HIDE_POPUP_EXPORT_TRANSACTION_BANK = 'HIDE_POPUP_EXPORT_TRANSACTION_BANK'