import React, { useRef, useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from 'react-redux'

//styles
import { WrapPage, WrapListTrasHistory, TableContent } from './index.styles'

//image
import IcTransHistoryDefault from "../../../../../../../assets/images/IcTransHistoryDefault";

//component
import FilterTransHistory from './FilterTransHistory'
import EmptyDataControl from '../../../../../../Control/EmptyDataControl'
import TransHistoryItem from "./TransHistoryItem";

//action
import { LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER } from "../../../../../../../redux/customer/customerTransactionHistory/types";
import { getListTransactionBankOfCustomer, getListTransactionFacepayOfCustomer, resetListTransactionBankOfCustomer, resetListTransactionFacepayOfCustomer, resetTransactionOfCustomerCondsFilter } from "../../../../../../../redux/customer/customerTransactionHistory/actionV2";
import LoadingLocal from "../../../../../../Control/LoadingLocal";

// helper
import numberRoundToTwo from '../../../../../../../helper/numberRoundToTwo'
import { BANK, FACEPAY } from "../../../CustomerV2/CustomerList";
import { resetListCustomerFacepayBacklog, resetUpdateListBacklogCustomerCondsFilter, resetListCustomerBankBacklog } from "../../../../../../../redux/customer/customerBacklog/action";
import useScrollEnd from "../../../../../../../hook/useScrollEnd";
import { resetCustomerAnalytic, resetCustomerBankAnalytic } from "../../../../../../../redux/customer/customerAnalytic/action";

const TransactionHistory = () => {

  const listRef = useRef(null);
  const dispatch = useDispatch()

  const { details, list, condFilters, total, totalSuccess, isScroll, isTypeLoading, reqType } = useSelector(state => ({
    details: state.customerReducer.customerDetailReducer.details,
    list: state.customerReducer.customerTransactionHistoryReducer.list,
    condFilters: state.customerReducer.customerTransactionHistoryReducer.condFilters,
    reqType: state.customerReducer.customerTransactionHistoryReducer.reqType,
  
    total: state.customerReducer.customerTransactionHistoryReducer.total,
    totalSuccess: state.customerReducer.customerTransactionHistoryReducer.totalSuccess,
    isScroll: state.customerReducer.customerTransactionHistoryReducer.isScroll,
    isTypeLoading: state.loading[LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER],
  }))

  const [isShowPopup, setIsShowPopup] = useState("");

  useEffect(() => {
    dispatch(resetUpdateListBacklogCustomerCondsFilter())
    dispatch(resetListCustomerFacepayBacklog())
    dispatch(resetListCustomerBankBacklog())
    dispatch(resetCustomerAnalytic())
    dispatch(resetCustomerBankAnalytic())
  },[])

  useEffect(() => {
    if (!details && !details?.id) {
      dispatch(resetListTransactionBankOfCustomer())
      dispatch(resetListTransactionFacepayOfCustomer())
      dispatch(resetTransactionOfCustomerCondsFilter())
    }
  }, [details?.id])
  
  useEffect(() => {
    if (condFilters.bioId) {
      dispatch(getListTransactionBankOfCustomer(
        {
          bioId: condFilters.bioId,
          facepayTrxCode: condFilters.facepayTrxCode,
          begin: condFilters.begin,
          end: condFilters.end,
          isSuccess: condFilters.isSuccess,
          isFail: condFilters.isFail,
          limit: condFilters.limit,
          skip: condFilters.skip
        }, isScroll))
        return
    }
    if(condFilters.uid){
      dispatch(getListTransactionFacepayOfCustomer(
        {
          uid: condFilters.uid,
          supplier: condFilters.supplier,
          facepayTrxCode: condFilters.facepayTrxCode,
          begin: condFilters.begin,
          end: condFilters.end,
          isSuccess: condFilters.isSuccess,
          isFail: condFilters.isFail,
          limit: condFilters.limit,
          skip: condFilters.skip
        }, isScroll))
        return
    }
  }, [JSON.stringify(condFilters)])

  useScrollEnd(listRef, (callback) => {
    if (condFilters.bioId) {
      dispatch(getListTransactionBankOfCustomer(
        {
          bioId: condFilters.bioId,
          facepayTrxCode: condFilters.facepayTrxCode,
          begin: condFilters.begin,
          end: condFilters.end,
          isSuccess: condFilters.isSuccess,
          isFail: condFilters.isFail,
          limit: condFilters.limit,
          skip: list.length
        }, true, callback))
        return
    }
    if(condFilters.uid){
      dispatch(getListTransactionFacepayOfCustomer(
        {
          uid: condFilters.uid,
          supplier: condFilters.supplier,
          facepayTrxCode: condFilters.facepayTrxCode,
          begin: condFilters.begin,
          end: condFilters.end,
          isSuccess: condFilters.isSuccess,
          isFail: condFilters.isFail,
          limit: condFilters.limit,
          skip: list.length
        }, true, callback))
        return
    }
  },[list], [list.length])

  return (
    <WrapPage>
      <div className="list-trans-history-total">
        <div className="block-total" title="Tổng số giao dịch&#13;&#10;đã thực hiện">
          <p className="num">{total || "0"}</p>
          <p className="text">Tổng giao dịch</p>
        </div>
        <div className="block-total" title="Tổng số giao dịch&#13;&#10;thành công">
          <p className="num">{totalSuccess || "0"}</p>
          <p className="text">Thành công</p>
        </div>
        <div className="block-total" title="Tổng số giao dịch&#13;&#10;thất bại">
          <p className="num">{total ? (total - totalSuccess) : "0"}</p>
          <p className="text">Thất bại</p>
        </div>
        <div className="block-total" title="Tỷ lệ giao dịch&#13;&#10;thành công">
          <p className="num percent">{total ? `${numberRoundToTwo(totalSuccess * 100 / total)}%` : "0%"}</p>
          <p className="text">Tỷ lệ thành công</p>
        </div>
      </div>
      <FilterTransHistory />
      <WrapListTrasHistory>
        {
          list?.length === 0 && isTypeLoading ?
            <LoadingLocal />
            :
            list?.length === 0 && !isTypeLoading ?
              (
                <div className="wrap-empty">
                  <EmptyDataControl
                    icon={<IcTransHistoryDefault />}
                    text={"Khách hàng chưa có lịch sử giao dịch"}
                  />
                </div>
              ) : (
                <TableContent>
                  <div className="wrap-list-trans-history" ref={listRef} >
                    <div className="list-trans-history">
                      {list?.map((item, index) => (
                        <div className="list" key={index}>
                          <TransHistoryItem
                            listRef={listRef}
                            data={item}
                            isShowPopup={isShowPopup}
                            setIsShowPopup={setIsShowPopup}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </TableContent>
              )
        }
      </WrapListTrasHistory>

    </WrapPage>
  )
}

export default TransactionHistory;
