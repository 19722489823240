import Axios from 'axios'

// //host
import { host } from '../../../constant/host'

// //help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import mapErrors from '../../../helper/mapErrors'
import { disableLoading, enableLoading } from '../../loading/action'

//types
import * as types from './types'

//action
import { getCensorshipCount } from '../censorshipCount/action'

//data
import { dataSelectionCensor } from '../../../components/Main/Content/Page/Censorship/WaitingCensorship/FilterWaitingCensorship/DropdownCensorship'

const apiGetListWaitingCensorship = `${host}/facepay/user/pending/filter`

let isCalling = false

export const getListWaitingCensorship =
    (
        {
            lastItem,
            limit,
            phone,
            documentId,
            startTime,
            endTime,
            isNew,
            isRepending
        },
        isScroll,
        callbackSuccess,
        callbackError,
    ) => async dispatch => {

        if (isCalling) return
        isCalling = true

        dispatch(getCensorshipCount())
        !isScroll && dispatch(enableLoading())

        // const condFilters = {
        //     lastItem,
        //     limit,
        //     phone,
        //     documentId,
        //     startTime,
        //     endTime,
        //     isNew,
        //     isRepending
        // }

        const dataRequest = JSON.stringify({
            lastItem,
            limit,
            phone,
            documentId,
            startTime,
            endTime,
            isNew,
            isRepending
        })
        
        try {
            const res = await Axios.post(apiGetListWaitingCensorship, dataRequest)
            const { data } = res
            if (checkIsSuccessAction(data.code)) {
                dispatch({
                    type: types.GET_LIST_WAITING_CENSORSHIP,
                    payload: {
                        list: data.data.list || [],
                        total: data.data.total,
                        // condFilters,
                        isScroll
                    }
                })
                isCalling = false
                callbackSuccess && callbackSuccess()
            }
            else {
                dispatch({
                    type: types.GET_LIST_WAITING_CENSORSHIP_ERR,
                    payload: {
                        ...data
                    }
                })
                callbackError && callbackError()
            }
        }
        catch (err) {
            dispatch(mapErrors(err, types.GET_LIST_WAITING_CENSORSHIP_ERR))
        }
        isCalling = false
        !isScroll && setTimeout(() => {
            dispatch(disableLoading())    
        }, 500);
    }


export const resetListWaitingCensorship = (callbackSuccess) => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_WAITING_CENSORSHIP })
    callbackSuccess && callbackSuccess()
}

export const updateWaitingCondsFilter = ({
    condFilters={
        optionSelected: dataSelectionCensor[0],
        phone: "",
        documentId: "",
        lastItem: "",
        limit: 15,
        startTime: "",
        endTime: "",
        isNew: true,
        isRepending: true
    }, 
    isScroll=false,
    callbackSuccess
    }) => async (dispatch) => {
    dispatch({ 
        type: types.UPDATE_WAITING_CONDFILTERS,
        condFilters,
        isScroll
    })
    callbackSuccess && callbackSuccess()
}

export const resetWaitingCondsFilter = () => async (dispatch) => {
    dispatch({ type: types.RESET_WAITING_CONDFILTERS })
}