import React from 'react'

const IcUnCheckBox = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.1665" y="2.1665" width="15.6667" height="15.6667" rx="1.5" stroke="#BDBDBD" />
        </svg>
    )
}

export default IcUnCheckBox
