import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

// component
import IcClose from '../../../../../../../assets/images/IcClose'
import IcSend from '../../../../../../../assets/images/IcSend'
import Button from '../../../../../../Control/Button'
import SelectReason, { KEY_OTHER } from './SelectReason'
import InputOther from './InputOther'
import Portal from '../../../../../../Control/Portal'
import PopupConfirmRefund from './PopupConfirmRefund'

// styles
import { OverlayFullScreen } from '../PopupShowMedia/index.styles'
import { WrapPopupReasonRefund } from './index.styles'

// action
import { getDetailTransaction, requestRefund } from '../../../../../../../redux/transaction/transactionDetail/action'
import { useOnClickOutside } from '../../../../../../../hook/useClickOutside'

const PopupReasonRefund = ({
    data: {
        bankTrxCode,
        facepayTrxCode,
        supplier
    },
    handleClose
}) => {
    const refPopup = useRef()
    const dispatch = useDispatch()
    const { facepayId } = useParams()
    const [isDisableSubmit, setIsDisableSubmit] = useState(false);
    const [selected, setSelected] = useState(null);
    const [otherReason, setOtherReason] = useState("");
    const [isShowConfirm, setIsShowConfirm] = useState(false);

    const _handleSubmit = () => {
        setIsShowConfirm(true)
    }

    const _handleRefundSuccess = () => {
        dispatch(getDetailTransaction({ id: facepayId }))
        handleClose()
    }

    const _handleRefund = () => {
        dispatch(requestRefund({
            supplier,
            trxCode: facepayTrxCode,
            remark: selected.key === KEY_OTHER ? otherReason : otherReason,
            remarkCode: selected.key
        }, _handleRefundSuccess))
    }

    useEffect(() => {
        const isDisableSubmit = selected?.key === KEY_OTHER
            ? !otherReason
            : !selected
        setIsDisableSubmit(isDisableSubmit)
    }, [otherReason, selected]);
    
    useOnClickOutside(refPopup, ()=>handleClose())
    window.addEventListener("keydown",(e)=>{
        if (e.key === "Escape"){
            handleClose()
        }
    })

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupReasonRefund ref={refPopup}>
                    <IcClose className="ic-close" onClick={handleClose} />
                    <div className="title">Hoàn tiền giao dịch</div>
                    <div className="desc">Vui lòng chọn lý do hoàn tiền</div>

                    <div className="content">
                        <SelectReason 
                            supplier={supplier}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {
                            selected?.key === KEY_OTHER &&
                            <InputOther 
                                value={otherReason}
                                onChange={(e) => setOtherReason(e.target.value)}
                            />
                        }
                    </div>
                    <Button 
                        className="btn-send-reason"
                        text="Gửi lý do"
                        isDisable={isDisableSubmit}
                        iconBtn={<IcSend />}
                        onClick={_handleSubmit}
                    />
                </WrapPopupReasonRefund>
                {
                    isShowConfirm &&
                    <Portal>
                        <PopupConfirmRefund 
                            bankTrxCode={bankTrxCode}
                            handleSubmit={_handleRefund}
                            handleClose={() => setIsShowConfirm(false)}
                        />
                    </Portal>
                }
            </div>
        </OverlayFullScreen>
    )
}

export default PopupReasonRefund
