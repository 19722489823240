import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//component
import DeviceItemByList from './DeviceItemByList'
import EmptyDataControl from '../../../../../Control/EmptyDataControl'
import IcDeviceDefault from '../../../../../../assets/images/IcDeviceDefault'
import CheckBoxControl from '../../../../../Control/CheckBoxControl'
import { iconCheckBox } from '../../../BreadcrumbAndTool/PopupExportFileV2/ExportFileBankV2'

// styles
import { LabelCol, RowLabelColumn, WrapRow } from '../../MerchantDetails/ListStores/index.styles'
import { WrapDeviceAdminByList } from './index.styles'

// redux
import { getAvatarMerchant, getListTempFirebaseDevices, updateListIdCheckeds } from '../../../../../../redux/deviceV2/listDevicesByAdmin/action'

const headerTable = [
    {key: "device-id", value: "Devices ID"},
    {key: "device-name", value: "Tên thiết bị"},
    {key: "merchant", value: "Merchant"},
    {key: "location", value: "Location"},
    {key: "none", value: " "},
    {key: "app-version", value: "Phiên bản app"},
    {key: "serial-no", value: "Serial No"},
    {key: "launcher-version", value: "Phiên bản Launcher"},
    {key: "last-connect", value: "Lần cuối kết nối"}
]

const DeviceAdminByListView = () => {
    const listRef = useRef()
    const dispatch = useDispatch()

    const [isCheckAllDevice, setIsCheckAllDevice] = useState(false)

    const { listTempFirebase, listIdCheckeds } = useSelector(state => ({
        listTempFirebase: state.deviceReducer.listDevicesByAdmin.listTempFirebase,
        listIdCheckeds: state.deviceReducer.listDevicesByAdmin.listIdCheckeds
    }))

    const handleSelectCheckbox = (checkboxSelected) => {
        if(!checkboxSelected) return
        let itemToggle = null;
        const checkboxColection = listTempFirebase.map((item) => {
            if (item.serialNumber === checkboxSelected) {
                itemToggle = (
                    {
                        ...item,
                        isSelect: !item.isSelect
                    }
                )
            }
            return item.serialNumber !== checkboxSelected ? item : itemToggle
        })

        const checkedTemp = itemToggle && itemToggle.isSelect ? [...listIdCheckeds, itemToggle.serialNumber]  : listIdCheckeds.filter(item => item != itemToggle.serialNumber)
 
        dispatch(updateListIdCheckeds(checkedTemp))
        dispatch(getListTempFirebaseDevices(checkboxColection))
    }


    const handleCheckAllDevice = () => {
        setIsCheckAllDevice(!isCheckAllDevice)

        let idCheckeds = []
        let listShowCurrent = []
        listTempFirebase.forEach((item) => {
            !isCheckAllDevice && idCheckeds.push(item.serialNumber) 
        })
        listShowCurrent = listTempFirebase.map((item) => {
            return {
                ...item,
                isSelect: !isCheckAllDevice
            }
        })
        dispatch(updateListIdCheckeds(idCheckeds))
        dispatch(getListTempFirebaseDevices(listShowCurrent)) 
    }
    
    useEffect(() => {
        const  listStoreDefault = listTempFirebase.map((item) => {
              return {
                  ...item,
                  isSelect: listIdCheckeds.includes(item.serialNumber)
              }
          })
          dispatch(getListTempFirebaseDevices(listStoreDefault))
          if(listIdCheckeds.length === listTempFirebase.length) {
            setIsCheckAllDevice(true)
          }else{
            setIsCheckAllDevice(false)
          }
    }, [listIdCheckeds.length])

    return (
        <WrapDeviceAdminByList>
            {
                listTempFirebase.length === 0
                ? (
                    <div className="wrap-empty">
                        <EmptyDataControl
                            icon={<IcDeviceDefault />}
                            text={"Hiện tại chưa có thiết bị mới"}
                        />
                    </div>
                )
                : (
                    <>
                        <RowLabelColumn className="row header-list">
                            <CheckBoxControl
                                iconCheckBox={iconCheckBox}
                                isChecked={isCheckAllDevice}
                                handleCheck={handleCheckAllDevice}
                            />
                            {
                                headerTable.map((item, i) => (
                                    <LabelCol key={i} className={item.key==="serial-no" && "serial-head"}>{item.value}</LabelCol>
                                ))
                            }
                        </RowLabelColumn>
                        <WrapRow ref={listRef}>
                            <div className="wrap-inner">
                                {
                                    listTempFirebase.map((item, index) => (
                                        <DeviceItemByList
                                            key={index}
                                            className="row"
                                            data={item}
                                            refList={listRef}
                                            handleSelectCheckbox={handleSelectCheckbox}
                                            listIdCheckeds={listIdCheckeds}
                                        />
                                    ))
                                }
                            </div>
                        </WrapRow>
                    </>
                )
            }
        </WrapDeviceAdminByList>
    )
}

export default DeviceAdminByListView
