import React, { useState, useEffect } from 'react'
import { useHistory, NavLink } from "react-router-dom";

// styles
import { WrapTabListAndToolCensorship } from './index.styles'

//path
import * as path from '../../../../../../constant/path'


export const WAITING_CENSORSHIP = 'waiting-censorship'
export const REJECTION_PROFILE = 'rejection-profile'

export const listTabs = [
    {
        path: `/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}`,
        type: WAITING_CENSORSHIP,
        name: 'Chờ kiểm duyệt'
    },
    {
        path: `/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}`,
        type: REJECTION_PROFILE,
        name: 'Hồ sơ từ chối'
    },
]

const TabListAndToolCensorship = () => {

    return (
        <WrapTabListAndToolCensorship>
            <div className="wrap-tab">
                {
                    listTabs.map((item) => {
                        const { type, name, path } = item
                        return (
                            <NavLink
                                to={path}
                                key={type}
                                className="tab"
                                activeClassName="active"
                            >
                                <p>{name}</p>
                            </NavLink>
                        )
                    })
                }
            </div>
        </WrapTabListAndToolCensorship>
    )
}

export default TabListAndToolCensorship
