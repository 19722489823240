import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// style
import { WrapFacepayTerminal } from './index.style'
import { TableContent } from '../Bank/index.styles'

// images
import IcTransactionDefault from '../../../../../../assets/images/IcTransactionDefault'

// component
import EmptyDataControl from '../../../../../Control/EmptyDataControl'
import TransactionItem from '../Bank/TransactionItem'
import FilterTransactionFacepay from './FilterTransactionFacepay'
import useScrollEnd from '../../../../../../hook/useScrollEnd'
import { headerFacepayTransListData } from '../../../../../../data/headerTransListData'

// redux
import { resetTransactionBankCondfilters } from '../../../../../../redux/transactionV2/listTransactionBank/action'
import { getListTransactionFacepay } from '../../../../../../redux/transactionV2/listTransactionFacepayTerminal/action'
import { getListStoreName, resetCondFiltersStoreName, resetIsCheckSearchStore } from '../../../../../../redux/transactionV2/transactionOther/action'
import { resetCondFilterTransactionFacepayWallet } from '../../../../../../redux/transactionV2/listTransactionWalletFacepay/action'


const FacepayTerminal = () => {

    const dispatch = useDispatch()
    const listRef = useRef()
  
    const { listTransactionFacepay, condFiltersTransactionFacepay, condFiltersStoreName } = useSelector((state) => ({
      listTransactionFacepay: state.transactionReducer.listTransactionFacepayReducer.listTransactionFacepay,
      condFiltersTransactionFacepay: state.transactionReducer.listTransactionFacepayReducer.condFiltersTransactionFacepay,

      // condFiltersTransactionFacepay: state.transactionReducer.listTransactionFacepayReducer.condFiltersTransactionFacepay,

      condFiltersStoreName: state.transactionReducer.transactionOtherReducer.condFiltersStoreName
  
    }));

    useEffect(() => {
      const callbackSuccess = () => {
        dispatch(getListStoreName({
            name: [condFiltersStoreName.name]
          }))
      }
      dispatch(resetCondFiltersStoreName(callbackSuccess))
      dispatch(resetTransactionBankCondfilters())
      dispatch(resetCondFilterTransactionFacepayWallet())
      dispatch(resetIsCheckSearchStore())
    },[])

    const dataRequest = (condFiltersTransactionFacepay, scroll, listTransactionFacepay) => {
      const checkIsSuccessCheckbox = condFiltersTransactionFacepay.OptionSelectCheckbox.reduce((a, v) => ({...a, [v.key] : v.isSelect}),{})
      return {
        ...checkIsSuccessCheckbox,
        limit:  condFiltersTransactionFacepay.limit,
        lastItemId: scroll ? listTransactionFacepay.at(-1).id : condFiltersTransactionFacepay.lastItemId,
        facepayTrxCode: condFiltersTransactionFacepay.facepayTrxCode,
        bankTrxCode: condFiltersTransactionFacepay.bankTrxCode,
        merchantTrxCode: condFiltersTransactionFacepay.merchantTrxCode,
        profileId: condFiltersTransactionFacepay.profileId,
        phoneNumber: condFiltersTransactionFacepay.phoneNumber,
        code: condFiltersTransactionFacepay.code,
        storeId: condFiltersTransactionFacepay.storeId,
        supplier: condFiltersTransactionFacepay.supplier.filter((item) => item.isSelect).map((item) => item.key),
        begin: condFiltersTransactionFacepay.begin,
        end: condFiltersTransactionFacepay.end,
      }
    }
    
    useEffect(() => {
        dispatch(getListTransactionFacepay(dataRequest(condFiltersTransactionFacepay, false), false))
    }, [condFiltersTransactionFacepay])
    
    useScrollEnd(listRef, (callback) => {
      dispatch(getListTransactionFacepay(dataRequest(condFiltersTransactionFacepay, true, listTransactionFacepay), true, callback))
    }, [listTransactionFacepay], [listTransactionFacepay.length])

    const renderTableContent = useMemo(() => (
        <TableContent>
          <div className="wrap-list-transaction" ref={listRef}>
            <div className="list-transaction">
              {listTransactionFacepay.map((item, index) => (
                <div className="transaction-item" key={index}>
                  <TransactionItem item={item} />
                </div>
              ))}
            </div>
          </div>
        </TableContent>
      ), [listTransactionFacepay])

    return (
        <WrapFacepayTerminal>
          <FilterTransactionFacepay />
            {
                listTransactionFacepay.length === 0 ?
                <div className="wrap-empty">
                    <EmptyDataControl
                    icon={<IcTransactionDefault />}
                    text="Hiện tại chưa có giao dịch mới"
                    />
                </div>
                :
                <>
                    <div className="wrap-header">
                    {headerFacepayTransListData.map((item, index) => (
                        <div className="header-item" key={index}>{item}</div>
                    ))}
                    </div>
                    {renderTableContent}
                </>
            }
        </WrapFacepayTerminal>
    )
}

export default FacepayTerminal
