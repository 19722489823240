import * as types from './types'
import moment from 'moment'

//data
import { dataSelectionCensor } from '../../../components/Main/Content/Page/Censorship/WaitingCensorship/FilterWaitingCensorship/DropdownCensorship'

const date = new Date()
const startDay = moment(date).startOf('month').toDate()

const currentDay = moment(date).endOf("day").toDate()

const initialState = {
    list: [],
    condFilters: {
        optionSelected: dataSelectionCensor[0],
        phone: "",
        documentId: "",
        lastItem: "",
        limit: 15,
        startTime: startDay,
        endTime: currentDay,
        isRejectOnce: true,
        isRejectGreaterOne: true
    },
    isScroll: false,
    total: 0
}

const listRejectProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_REJECT_PROFILE:
            return {
                ...state,
                ...action.payload,
                list: action.payload.isScroll 
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list
            }
        case types.RESET_LIST_REJECT_PROFILE:
            return {
                ...state,
                list: []
            }
        case types.UPDATE_REJECT_CONDFILTERS: 
            return {
                ...state,
                condFilters: {
                    ...state.condFilters,
                    ...action.condFilters,
                },
                isScroll: action.isScroll
            }
        case types.RESET_REJECT_CONDFILTERS:
            return {
                ...state,
                condFilters: initialState.condFilters,
                isScroll: false
            }
        default:
            return state;
    }
}

export default listRejectProfileReducer;