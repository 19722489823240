import React from 'react'

const IcEmailBgBlue = (props) => {
    return (
        <svg {...props} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="#EFF9FF"/>
        <path d="M11.2 11.1992H24.8C25.735 11.1992 26.5 11.9642 26.5 12.8992V23.0992C26.5 24.0342 25.735 24.7992 24.8 24.7992H11.2C10.265 24.7992 9.5 24.0342 9.5 23.0992V12.8992C9.5 11.9642 10.265 11.1992 11.2 11.1992Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.5 12.9023L18 18.8523L9.5 12.9023" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcEmailBgBlue
