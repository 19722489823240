import * as types from './type'
import moment from 'moment';

import { dataSwiftCodeBank } from '../../../constant/dataSwiftCodeBank'
import { dataSelectionTime } from '../../../components/Main/Content/Page/TransactionV2/Bank/FilterTransactionBank/DropdownFilterTime';
import { dataSelectionBankTransaction } from '../../../components/Main/Content/Page/TransactionV2/Bank/FilterTransactionBank/DropdownFilterBank'
import { dataSelectCheckbox, dataSelectCheckBoxExportBank } from '../../../data/dataSelectCheckbox';

export const startDay = moment('1/1/2022').startOf('day').toDate()
export const currentDay = moment(new Date()).endOf("day").toDate()

const intitialState = {
    listTransactionBank: [],
    condFiltersTransactionBank : {
        limit: 15,
        lastItemId: "",
        bankTrxCode: "",
        facepayTrxCode: "",
        merchantTrxCode: "",
        phoneNumber: "",
        profileId: "",
        code: "",
        storeId: ["*"],
        OptionSelectCheckbox : dataSelectCheckbox,
        supplier: dataSwiftCodeBank,
        begin: startDay,
        end: currentDay,
        OptionSelect: dataSelectionBankTransaction[0],
        OptionSelectTime: dataSelectionTime[0],
    },

    condFiltersExportTransactionBank: {
        isFacepay: false,
        begin: startDay,
        end: currentDay,
        OptionSelectCheckboxExportBank: dataSelectCheckBoxExportBank,
    },

    totalBank: {
        totalAmount: 0,
        totalFailed: 0,
        totalSuccess: 0,
        totalTransaction: 0
    },

    isShowPopupExportBank: false,
}

const listTransactionBankReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_LIST_TRANSACTION_BANK:
            return {
                ...state,
                listTransactionBank: action.payload.isScroll ? [...state.listTransactionBank, ...action.payload.listTransactionBank] : action.payload.listTransactionBank,
                totalBank: action.payload.totalBank
            }
    
        case types.UPDATE_LIST_TRANSACTION_BANK:
            return {
                ...state,
                condFiltersTransactionBank: {
                    ...state.condFiltersTransactionBank,
                    ...action.condFiltersTransactionBank
                }
            }
        case types.RESET_LIST_TRANSACTION_BANK:
            return {
                ...state,
                listTransactionBank: []
            }
        case types.RESET_TRANSACTION_BANK_CONDFILTERS:
            return {
                ...state,
                condFiltersTransactionBank: intitialState.condFiltersTransactionBank
            }

        case types.SHOW_POPUP_EXPORT_TRANSACTION_BANK: 
            return {
                ...state,
                isShowPopupExportBank: !state.isShowPopupExportBank
            }
        case types.HIDE_POPUP_EXPORT_TRANSACTION_BANK: 
            return {
                ...state,
                isShowPopupExportBank: false
            }   
        default:
            return state
    }
}

export default listTransactionBankReducer