import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// style
import { WrapDropdownFilterStore } from './index.styles'

//images
import IcSearchGreen from '../../../../../../../../assets/images/IcSearchGreen'
import IcTransactionDefault from '../../../../../../../../assets/images/IcTransactionDefault'

// component
import InputSearch from '../../../../../../../Control/InputSearch'
import EmptyDataControl from '../../../../../../../Control/EmptyDataControl'
import CheckBoxControl from '../../../../../../../Control/CheckBoxControl'
import Button from '../../../../../../../Control/Button'

// helper
import trimStartAndStripStr from '../../../../../../../../helper/trimStartAndStripStr'

// hook
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'

// keys
import { BANK } from '../../../../../../../../constant/keys'

// redux
import { updateCondFiltersTransactionFacepay } from '../../../../../../../../redux/transactionV2/listTransactionFacepayTerminal/action'
import { isCheckSearchStore, updateListStoreNameCondFilters, updateListStoreNameTemp } from '../../../../../../../../redux/transactionV2/transactionOther/action'
import { updateListTransactionBank } from '../../../../../../../../redux/transactionV2/listTransactionBank/action'
import { resetListDevicesByAdminId } from '../../../../../../../../redux/deviceV2/listDevicesByAdmin/action'

const DropdownFilterStore = ({reqType, iconCheckBox, onClosePopup, listStoreName }) => {

    const filterRef = useRef()
    const dispatch = useDispatch()

    const { total, condFiltersStoreName, isCheckSearch } = useSelector((state) => ({
        total: state.transactionReducer.transactionOtherReducer.total,
        condFiltersStoreName: state.transactionReducer.transactionOtherReducer.condFiltersStoreName,
        isCheckSearch: state.transactionReducer.transactionOtherReducer.isCheckSearch,
    }))

    const [valueSearch, setValueSearch] = useState("")
    const [dataStore, setDataStore] = useState(listStoreName)
    const [listStore, setListStore] = useState(listStoreName)
    const [checkeds, setCheckeds] = useState([])

    const [allStore, setAllStore] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            id: condFiltersStoreName.OptionSelectStore.id,
            name: condFiltersStoreName.OptionSelectStore.name,
            isSelect: condFiltersStoreName.OptionSelectStore.isSelect
        }
    )
    
    useEffect(() => {
        let idCheckeds = []
        listStoreName.forEach((item) => {
            if (item.isSelect) {
                idCheckeds.push(item.id)
            }})
        setCheckeds(idCheckeds)
    }, [listStoreName])

    const handleSelectCheckBox = (checkboxSelected) => {
        if (!checkboxSelected) return
        if (!listStoreName) return
        let itemToggle = null;
        const listStoreDefault = dataStore.map((item) => {
            if (item.id === checkboxSelected) {
                itemToggle = (
                    {
                        ...item,
                        isSelect: !item.isSelect
                    }
                )
            }
            return item.id !== checkboxSelected ? item : itemToggle
        })
        
        const checkedTemp = itemToggle && itemToggle.isSelect ? [...checkeds, itemToggle.id]  : checkeds.filter(item => item != itemToggle.id)

        setCheckeds(checkedTemp)
        setAllStore({isSelect: checkedTemp === total})
        setDataStore(listStoreDefault)
    }

    const handleSearchStore = () => {

        if(reqType === BANK) {
            dispatch(updateListTransactionBank({
                storeId: allStore.isSelect ? ["*"] : checkeds
            }))
        }else{
            dispatch(updateCondFiltersTransactionFacepay({
                storeId: allStore.isSelect ? ["*"] : checkeds
            }))
        }

        let listStoreDefault = listStore.map((item) => {
            return {
                ...item,
                isSelect: checkeds.includes(item.id)
            }
        })

        dispatch(updateListStoreNameTemp(listStoreDefault))

        dispatch(updateListStoreNameCondFilters({
            condFiltersStoreName: {
                OptionSelectStore: {
                ...allStore,
                totalStoreSelect: checkeds.length
            }}
        }))
        dispatch(resetListDevicesByAdminId())

        dispatch(isCheckSearchStore())
        onClosePopup()
    }

    const handleSelectAllStore = () => {
        setAllStore({isSelect: !allStore.isSelect})
        let idCheckeds = []
        let listShowCurrent = []
        listStore.forEach((item) => {
            !allStore.isSelect && idCheckeds.push(item.id) 
        })
        listShowCurrent = dataStore.map((item) => {
            return {
                ...item,
                isSelect: !allStore.isSelect
            }
        })
        setCheckeds(idCheckeds)
        setDataStore(listShowCurrent)
    }

    const handleSearchValue = () => {
        let listStoreDefault = listStore.filter((item) => {
            return (item).Name.trim().toLowerCase().includes(valueSearch.trim().toLowerCase())
        })

        setDataStore(listStoreDefault)
    }

    useEffect(() => {
      const  listStoreDefault = listStore.map((item) => {
            return {
                ...item,
                isSelect: checkeds.includes(item.id)
            }
        })
        setListStore(listStoreDefault)
    }, [checkeds])

    useOnClickOutside(filterRef, () => {
        if(!isCheckSearch) {
            const listStoreDefault = listStore.filter((item) => item.isSelect = true)
            setDataStore(listStoreDefault)
            setAllStore({isSelect: true})
        }
        onClosePopup()
    })

    useEffect(() => {
        if (checkeds.length === total) setAllStore({ isSelect: true })
    }, [checkeds.length])

    const tableContent = useMemo(() => (
        dataStore.length === 0 ? (
            <div className="wrap-empty">
              <EmptyDataControl
                icon={<IcTransactionDefault />}
                text="Hiện tại chưa có cửa hàng nào"
              />
            </div>
          ) : (
            <div className="wrap-list-store">
              <div className="list-store">
                {dataStore.map((item) => (
                  <div className="store-item" key={item.id}>
                    <CheckBoxControl
                        className='info-checkbox'
                        iconCheckBox={iconCheckBox}
                        title={item.Name}
                        isChecked={item.isSelect}
                        handleCheck={() => handleSelectCheckBox(item.id)}
                    />
                  </div>
                ))}
              </div>
            </div>
        )
    ),[dataStore])

    return (
        <WrapDropdownFilterStore ref={filterRef}>
            <div className="filter-store-search">
                <InputSearch
                    className="input-search"
                    value={valueSearch}
                    type='text'
                    placeholder="Tìm kiếm tên cửa hàng, mã cửa hàng..."
                    onChange={(e) => setValueSearch(trimStartAndStripStr(e.target.value))}
                    handleSearch={handleSearchValue}
                />
            </div>
            <div className="list-filter-store-all">
                <CheckBoxControl
                    className='info-checkbox'
                    iconCheckBox={iconCheckBox}
                    title={allStore.name}
                    isChecked={allStore.isSelect}
                    handleCheck={handleSelectAllStore}
                />
            </div>
            <div className="list-filter-store">
                <div className="title">
                    <div className="title-store">Danh sách cửa hàng ({listStoreName.length})</div>
                    <div className="title-check">Đã chọn {checkeds.length}</div>
                </div>
                {tableContent}
            </div>
            <div className="filter-button">
                <Button
                    className="filter-button-search"
                    text='Tìm kiếm'
                    iconBtn={<IcSearchGreen />}
                    onClick={handleSearchStore}
                />
            </div>
        </WrapDropdownFilterStore>
    )
}

export default DropdownFilterStore
