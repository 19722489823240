import React from 'react'

// data
import { dataSelection } from '../../../../../../data/dataDeviceFeature'

// component
import DropdownControl from '../../../../../Control/DropdownControl'


const DropdownSelectTypeSearch = ({ filterCond: { text, key }, setFilterCond }) => {

    const _handleSetValueSelect = (val) => {
        setFilterCond(val)
    }

    return (
        <DropdownControl
            dataSelection={dataSelection}
            valueSelected={{ text, key }}
            handleSetValueSelect={_handleSetValueSelect}
        />
    )
}

export default DropdownSelectTypeSearch
