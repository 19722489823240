import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// styles
import { WrapDeviceItem } from './index.styles'

//images
import DevicesInfo from '../../../../../../assets/images/img_devices_info.png'
import IcAmountActive from '../../../../../../assets/images/IcAmountActive'
import IconAmount from '../../../../../../assets/images/IcAmount'
import IcAmountError from '../../../../../../assets/images/IcAmountError'
import IcSentTransaction from '../../../../../../assets/images/IcSentTransaction'

//component
import  Input  from '../../../../../Control/Input'
import Button from '../../../../../Control/Button'

// helper
import { convertDateTime } from '../../../../../../helper/convertDateTime'
import numberFormatToThousand from '../../../../../../helper/numberFormatToThousand'

// data
import dataDeviceStatus from '../../../../../../data/dataDeviceStatus'

// constant
import { CONNECTED, DISCONNECTED, TERMINATED } from '../../../../../../constant/deviceStatusTypes'

// action
import { requestPayment } from '../../../../../../redux/device/listDevices/action'

const iconAmount = {
    icon: IconAmount,
    iconActive: IcAmountActive,
    iconError: IcAmountError,
}

const mapStatus = {
    0: DISCONNECTED,
    1: CONNECTED,
    2: TERMINATED
}

const MIN_VAL = 10_000
const MAX_VAL = 1_000_000

const getAmountByStr = (str) => Number(str.replace(/,/g, ''))
const checkAmountValid = (amountNumber) => amountNumber >= MIN_VAL && amountNumber <= MAX_VAL
const errors = {
    outOfRange: 'Money must be between 10,000 and 1,000,000'
}

const DeviceItem = ({ dataItem }) => {
    const dispatch = useDispatch()
    const [valueAmount, setValueAmount] = useState("")
    const [isDisabledBtnSendTrans, setIsDisabledBtnSendTrans] = useState(true);

    useEffect(() => {
        const isEnable = valueAmount
        setIsDisabledBtnSendTrans(!isEnable)
    }, [valueAmount]);
    

    const _handleChange = (e) => {
        const numBefore = getAmountByStr(e.target.value)
        if(isNaN(numBefore)) {
            return;
        }
        if(!numBefore) {
            setValueAmount("")
            return;
        }
        const num = numberFormatToThousand(numBefore)
        setValueAmount(num)
    }

    const _handleValidationAmount = (val, callback) => {
        if(!val) return;
        const amountNum = getAmountByStr(val)
        if(!checkAmountValid(amountNum)) {
            callback(errors.outOfRange)
        }
    }

    const _handleSendTrans = () => {
        const { id } = dataItem
        const amount = getAmountByStr(valueAmount)
        if(!checkAmountValid(amount)) {
            return
        }
        dispatch(requestPayment({ id, amount }, () => setValueAmount("")))
    }

    const objStatus = dataDeviceStatus[mapStatus[dataItem.status]] || {}

    const { type, text, color } = objStatus

    return (
        <WrapDeviceItem colorByStatus={color}>
            <div className="wrap-data wrap-device-infos">
                <div className="title">Device Info</div>
                <div className="content">
                    <div className="image">
                        <img src={DevicesInfo} alt="logo_devices"/>
                    </div>
                    <div className="content-right">
                        <div className="right-first">
                            <div className="content-item">
                                <p className="item-info status">{text}</p>
                                <p className="title">Status</p>
                            </div>
                            <div className="content-item">
                                <p className="item-info">{dataItem.id?.substring(0, 9).toUpperCase()}</p>
                                <p className="title">DeviceID</p>
                            </div>
                        </div>
                        <div className="right-middle">
                            <div className="content-item">
                                <p className="item-info name" title={dataItem.name}>{dataItem.name}</p>
                                <p className="title">Device Name</p>
                            </div>
                            <div className="content-item">
                                <p className="item-info">{dataItem.lastPingTime ? convertDateTime(dataItem.lastPingTime, "HH:mm - DD/MM/YYYY") : "Null"}</p>
                                <p className="title">Last Ping Time</p>
                            </div>
                        </div>
                        <div className="right-last">
                            <div className="content-item">
                                <p className="item-info version" title={dataItem.appVersion}>{dataItem.appVersion}</p>
                                <p className="title">App Version</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-data wrap-transaction">
                <div className="title">Send Transaction</div>
                <Input 
                    placeholder="Enter amount"
                    inputType="TEXT"
                    value={valueAmount}
                    onChange={_handleChange}
                    iconInput={iconAmount}
                    handleValidationUser={_handleValidationAmount}
                    className="input-sent"
                    disabled={type !== CONNECTED}
                />
                <Button 
                    text="Send Transaction"
                    iconBtn={<IcSentTransaction />}
                    isDisable={isDisabledBtnSendTrans}
                    onClick={_handleSendTrans}
                    className="btn-sent"
                />
            </div>
        </WrapDeviceItem>
    )
}

export default DeviceItem
