import styled from 'styled-components'

const WrapCustomerItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background: rgba(247, 247, 247, 0.5);
    border-radius: 6px;
    margin-bottom: 7px;
    padding: 12px 16px 12px 12px;
    box-sizing: border-box;
    cursor: pointer;
    &.active{
        background: #33ADFF;
        .block-left{
            .wrap-fullname{
                color: #FFFFFF;
                .type{
                    color: #F7F7F7;
                }
            }

        }
        .phone{
            color: #FFFFFF;
        }
    }
    .block-left{
        display: flex;
        align-items: center;
        .wrap-avatar{
            position: relative;
            width: 36px;
            height: 36px;
            &.lock{
                background: rgba(0, 0, 0);
                width: 36px;
                height: 36px;
                border-radius: 50%;
                .face{
                    opacity: 0.5;
                }
            }
            .face{
                width: 36px;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
            .icon-bank{
                position: absolute;
                right: -5px;
                bottom: -1px;
                width: 20px;
                height: 20px;
            }
            .icon-lock{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .wrap-fullname{
            display: flex;
            flex-direction: column;
            font-family: 'Open Sans';
            font-style: normal;
            margin-left: 10px;
            color: #313131;
            .fullname{               
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                padding-bottom: 3px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 250px;
            }
            .type{
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                color: #777777;
            }
        }
    }
    .phone{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        color: #313131;
    }
`

export {
    WrapCustomerItem
}