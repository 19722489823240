import React from 'react'
import DropdownControl from '../../../../../../../../Control/DropdownControl'

// component
// import DropdownControl from '../../../../../../../Control/DropdownControl'


export const PHONE_NUMBER = "PHONE_NUMBER"
export const PROFILE_ID = "PROFILE_ID"
export const FULLNAME_ID = "FULLNAME_ID"


export const dataSelectionCustomer = [
    {
        text: "Số điện thoại",
        key: PHONE_NUMBER
    },
    {
        text: "Profile ID",
        key: PROFILE_ID
    },
    {
        text: "Họ tên",
        key: FULLNAME_ID
    },
]

const DropdownFilterCustomer = ({filterCond: { text, key }, setFilterCond }) => {

    const _handleSetValueSelect = (val) => {
        setFilterCond(val)
    }

    return (
        <DropdownControl 
            dataSelection={dataSelectionCustomer}
            valueSelected={{ text, key }}
            handleSetValueSelect={_handleSetValueSelect}
        />
    )
}

export default DropdownFilterCustomer
