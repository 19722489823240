import styled, { keyframes } from "styled-components";

const WrapBusinessProfile = styled.div`   
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    height: 540px;
    &.is-reason{
        height: 506px;
    } 
    .wrap-list-reason{
        background: #FFEEEE;
        padding: 8px 12px;
        width: calc(100% - 24px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .wrap-list{
            display: flex;
            align-items: center;
            gap: 4px;
            .errro-text{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                color: #F45C5C;
            }
        }
        .ic-arrow{
            cursor: pointer;           
        }
        :hover{
            .ic-arrow{
                transform: rotate(90deg);
            }
            .wrap-reason{
                visibility: visible;
            }
        }
        .wrap-reason{
            position: absolute;
            top: 34px;
            left: 0;
            display: flex;
            flex-direction: column;
            padding: 0 12px 8px 12px;
            box-sizing: border-box;
            gap: 8px;
            background: #FFEEEE;
            width: 100%;
            z-index: 9;
            visibility: hidden;
            .reason{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                color: #313131;
            }
        }
    }
    .wrap-content{
        display: grid;
        grid-template-columns: 200px 600px;
        height: 100%;
        width: 100% ;
        &.expand{
            grid-template-columns: 600px 200px;
        }
        .wrap-identify{
            border-right: 1px solid #E7E7E7;
            padding: 7px 12px 16px 12px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            height: 100%;
            .wrap-title{
                height: 31px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                color: #313131;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .icon-expand{
                    cursor: pointer;
                }
            }
            .wrap-list-personal-papers{
                display: flex;
                flex-direction: column;
                width: calc(100% + 10px);
                flex-grow: 1;
                overflow: auto;
                position: relative;
                &.expand{
                    width: 100%;
                    flex-direction: row;
                    overflow: unset ;
                }
                .img-select{
                    width: 576px;
                    height: 441px;
                    border-radius: 4px;
                    background: #000000;
                    object-fit: contain;
                    box-sizing: border-box;
                    &.reason{
                        height: 406px;
                    }
                }
                .wrap-icon-info{
                    position: absolute;
                    right: 5px;
                    bottom: 50px;
                    .tool-tip{
                        display: none;
                        flex-direction: column;
                        align-items: center;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 11px;
                        line-height: 15px;
                        color: #FFFFFF;
                        background: #313131;
                        border-radius: 4px;                                
                        padding: 10px;
                        box-sizing: border-box;
                        position: absolute;
                        right: 20px;
                        bottom: 20px;
                        word-wrap: break-word;
                        width: 190px;
                    }
                    :hover{
                        .tool-tip{
                            display: block;
                        }
                    }
                }
                .list-personal{
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    ::-webkit-scrollbar {
                        width: 2px;
                        height: 2px;
                        background-color: transparent;
                    }
                    &.expand{
                        bottom: 0;
                        right: 0;
                        flex-direction: row;
                        gap: 5px;
                        max-width: 350px;
                    }                   
                        .licenseUrl-default {
                            border-radius: 4px;
                            margin-bottom: 10px;
                            cursor: pointer;
                            &.expand{
                                display: none;
                            }
                        }
                        .list-image{
                            position: relative;
                        }
                        .ic-refresh{
                            position: absolute;
                            bottom: 4px;
                            left: 2px;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            z-index: 1;
                            &.expand{
                                width: 16px;
                                height: 16px;
                                bottom: 2px;
                            }
                        }
                        .wrap-image{
                            position: relative;
                            border-radius: 4px;                            
                            :last-child{
                                margin-bottom: 0;
                            }
                            &.expand{
                                margin-bottom: 0;
                                height: 34px;
                                width: 34px;
                            }                            
                            .wrap-icon-info{
                                position: absolute;
                                right: 0px;
                                bottom: 4px;
                                cursor: pointer;
                                &.expand{
                                    display: none;
                                }
                                .tool-tip{
                                    display: none;
                                    flex-direction: column;
                                    align-items: center;
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 11px;
                                    line-height: 15px;
                                    color: #FFFFFF;
                                    background: #313131;
                                    border-radius: 4px;                                
                                    padding: 10px;
                                    box-sizing: border-box;
                                    position: absolute;
                                    right: 10px;
                                    bottom: 20px;
                                    word-wrap: break-word;
                                    width: 150px;
                                }
                                :hover{
                                    .tool-tip{
                                        display: block;
                                    }
                                }
                            }
                            .image-identify{
                                border-radius: 4px;                            
                                object-fit: cover;
                                cursor: pointer;
                                width: 176px;

                                &.license{
                                    height: 176px;
                                }
                                &.ekyc{
                                    height: 114px;
                                }
                                &.expand{
                                    width: 34px;
                                    height: 34px;
                                    /* margin-right: 5px; */
                                    object-fit: contain;
                                    box-sizing: border-box;
                                }
                                &.active{
                                    border: 2px solid #33ADFF;
                                    box-sizing: border-box;
                                }
                                
                            }
                            
                        }
                    /* } */
                }
            }
            .wrap-btn-request{
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 12px 0 0 0;
                gap: 12px;
                z-index: 2;
                &.expand{
                    justify-content: flex-start;
                    position: absolute;
                    bottom: 16px;
                    left: 12px;
                    margin: 0 0 0 0;
                }
                .ic-export{
                    display: none;
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                    &.expand{
                        display: block;
                    }
                }
                .btn-request{
                    padding: 8px 16px;
                    gap: 4px;
                    width: 176px;
                    height: 34px;
                    background: #EFF9FF;
                    border-radius: 6px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #33ADFF;
                }               
            }
        }
        .wrap-business-info{
            padding: 7px 12px 16px 12px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            height: calc(100% - 16px);
            overflow: auto;
            .wrap-block{
                display: flex;
                flex-direction: column;
                .title-info{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 15px;
                    color: #313131;
                    height: 31px;
                    display: flex;
                    align-items: center;
                }
                .wrap-info{
                    .wrap-logo{
                        display: flex;
                        gap: 10px;
                        margin-bottom: 10px;
                        .logo{
                            width: 80px;
                            height: 80px;
                            border-radius: 4px;
                            object-fit: contain;
                            &.expand{
                                width: 44px;
                                height: 44px;
                            }
                        }
                        .wrap-name{
                            display: flex;
                            flex-direction: column;
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 25px;
                            letter-spacing: -0.01em;
                            .name{
                                color: #000000;
                                &.expand{
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 120px;
                                }
                            }
                            .type{
                                font-weight: 400;
                                font-size: 11px;
                                line-height: 15px;
                                color: #777777;
                                &.expand{
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 120px;
                                }
                            }
                        }
                    }
                    .wrap-avatar{
                        display: flex;
                        gap: 8px;
                        background: #EFF9FF;
                        border-radius: 4px;
                        padding: 11px 12px;
                        margin-bottom: 10px;
                        .avatar{
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                        .wrap-name{
                            display: flex;
                            flex-direction: column;
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 11px;
                            line-height: 15px;                            
                            .name{
                                color: #777777;                                
                            }
                            .type{
                                font-weight: 600;
                                font-size: 13.5px;
                                line-height: 18px;
                                color: #000000;
                                &.expand{
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 120px;
                                }
                               
                            }
                        }
                    }
                    .wrap-business-content{
                        display: grid;
                        grid-template-columns: repeat(2, 274px);
                        grid-template-rows: repeat(4, 67px);
                        align-items: center;
                        grid-column-gap: 28px;
                        &.expand{
                            grid-template-columns: none;
                            grid-template-rows: repeat(6, 67px);
                        }
                        .block-content{
                            display: flex;
                            gap: 8px;
                            .icon{
                                flex-shrink: 0;
                            }
                            .wrap-text{
                                .text{
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 11px;
                                    line-height: 15px;
                                    color: #777777;
                                    margin-bottom: 2px;
                                    &.expand{
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 135px;
                                   }
                                }
                                .value{
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 13.5px;
                                    line-height: 18px;
                                    color: #313131;
                                    &.expand{
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 135px;
                                    }
                                    &.address{
                                        white-space: unset;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        max-width: 135px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`
export { WrapBusinessProfile }