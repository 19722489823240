import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// style
import { WrapBank, TableContent } from "./index.styles";

// images
import IcTransactionDefault from "../../../../../../assets/images/IcTransactionDefault";

//component
import FilterTransactionBank from "./FilterTransactionBank";
import TransactionItem from "./TransactionItem";
import EmptyDataControl from "../../../../../Control/EmptyDataControl";
import useScrollEnd from "../../../../../../hook/useScrollEnd";
import { headerBankTransListData } from "../../../../../../data/headerTransListData";

// redux 
import { getListTransactionBank } from "../../../../../../redux/transactionV2/listTransactionBank/action";
import { getListStoreName, resetCondFiltersStoreName, resetIsCheckSearchStore } from "../../../../../../redux/transactionV2/transactionOther/action";
import { resetCondFiltersTransactionFacepay } from "../../../../../../redux/transactionV2/listTransactionFacepayTerminal/action";
import { resetCondFilterTransactionFacepayWallet } from "../../../../../../redux/transactionV2/listTransactionWalletFacepay/action";

const Bank = () => {

  const dispatch = useDispatch()
  const listRef = useRef()

  const { listTransactionBank, condFiltersTransactionBank, condFiltersStoreName } = useSelector((state) => ({
    listTransactionBank: state.transactionReducer.listTransactionBankReducer.listTransactionBank,
    condFiltersTransactionBank: state.transactionReducer.listTransactionBankReducer.condFiltersTransactionBank,

    condFiltersStoreName: state.transactionReducer.transactionOtherReducer.condFiltersStoreName
  }));

  useEffect(() => {
    const callbackSuccess = () => {
      dispatch(getListStoreName({
          name: [condFiltersStoreName.name]
        }))
    }
    dispatch(resetCondFiltersStoreName(callbackSuccess))
    dispatch(resetCondFiltersTransactionFacepay())
    dispatch(resetCondFilterTransactionFacepayWallet())
    dispatch(resetIsCheckSearchStore())
  },[])

  const dataRequest = (condFiltersTransactionBank, scroll, listTransactionBank) => {
    const checkIsSuccessCheckbox = condFiltersTransactionBank.OptionSelectCheckbox.reduce((a, v) => ({...a, [v.key] : v.isSelect}),{})
    
    return {
      ...checkIsSuccessCheckbox,
      limit: condFiltersTransactionBank.limit,
      lastItemId: scroll ? listTransactionBank.at(-1).id : condFiltersTransactionBank.lastItemId,
      facepayTrxCode: condFiltersTransactionBank.facepayTrxCode,
      bankTrxCode: condFiltersTransactionBank.bankTrxCode,
      merchantTrxCode: condFiltersTransactionBank.merchantTrxCode,
      profileId: condFiltersTransactionBank.profileId,
      phoneNumber: condFiltersTransactionBank.phoneNumber,
      code: condFiltersTransactionBank.code,
      storeId: condFiltersTransactionBank.storeId,
      supplier: condFiltersTransactionBank.supplier.filter((item) => item.isSelect).map((item) => item.key),
      begin: condFiltersTransactionBank.begin,
      end: condFiltersTransactionBank.end,
    }
  }
  
  useEffect(() => {
    dispatch(getListTransactionBank(dataRequest(condFiltersTransactionBank, false), false))
  }, [condFiltersTransactionBank])

  useScrollEnd(listRef, (callback) => {
    dispatch(getListTransactionBank(dataRequest(condFiltersTransactionBank, true, listTransactionBank), true, callback))
  }, [listTransactionBank], [listTransactionBank.length])


  const renderTableContent = useMemo(() => (
    <TableContent>
      <div className="wrap-list-transaction" ref={listRef}>
        <div className="list-transaction">
          {listTransactionBank.map((item, index) => (
            <div className="transaction-item" key={index}>
              <TransactionItem item={item} />
            </div>
          ))}
        </div>
      </div>
    </TableContent>
  ), [listTransactionBank])

  return (
    <WrapBank>
      <FilterTransactionBank />
      {
        listTransactionBank.length === 0 ?
          <div className="wrap-empty">
            <EmptyDataControl
              icon={<IcTransactionDefault />}
              text="Hiện tại chưa có giao dịch mới"
            />
          </div>
          :
          <>
            <div className="wrap-header">
              {headerBankTransListData.map((item, index) => (
                <div className="header-item" key={index}>{item}</div>
              ))}
            </div>
            {renderTableContent}
          </>
      }
    </WrapBank>
  );
};

export default Bank;
