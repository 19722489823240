import React from 'react'

const IcUserBlueDefault = (props) => {
    return (
        <svg {...props} width={36} height={37} viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5256_41930)">
                <circle cx={18} cy="18.5" r={18} fill="#EFF9FF" />
                <path d="M24 25.25V23.75C24 22.9544 23.6839 22.1913 23.1213 21.6287C22.5587 21.0661 21.7956 20.75 21 20.75H15C14.2044 20.75 13.4413 21.0661 12.8787 21.6287C12.3161 22.1913 12 22.9544 12 23.75V25.25" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 17.75C19.6569 17.75 21 16.4069 21 14.75C21 13.0931 19.6569 11.75 18 11.75C16.3431 11.75 15 13.0931 15 14.75C15 16.4069 16.3431 17.75 18 17.75Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5256_41930">
                    <rect width={36} height={36} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcUserBlueDefault
