import React from 'react'

const IcLocked = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#F45C5C"/>
        <g clipPath="url(#clip0_6062_56370)">
        <path d="M13.3056 9.52734H6.69444C6.17284 9.52734 5.75 9.95019 5.75 10.4718V13.7773C5.75 14.2989 6.17284 14.7218 6.69444 14.7218H13.3056C13.8272 14.7218 14.25 14.2989 14.25 13.7773V10.4718C14.25 9.95019 13.8272 9.52734 13.3056 9.52734Z" fill="white" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.63867 9.52734V7.63845C7.63867 7.01225 7.88743 6.41169 8.33023 5.9689C8.77302 5.5261 9.37358 5.27734 9.99978 5.27734C10.626 5.27734 11.2265 5.5261 11.6693 5.9689C12.1121 6.41169 12.3609 7.01225 12.3609 7.63845V9.52734" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_6062_56370">
        <rect width="11.3333" height="11.3333" fill="white" transform="translate(4.33301 4.33203)"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default IcLocked
