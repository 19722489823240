import styled, { css, keyframes } from 'styled-components'

const TIME_VISIBILITY = 5000
const TIME_ANIMATION = 1000
const TIME_DELAY_CLOSE = TIME_VISIBILITY + TIME_ANIMATION

const toastTypes = {
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
}

const moveDown = keyframes`
    0% {
        transform: translate(0, -100%);
    }

    8% {
        transform: translate(0, 80px);
    }

    92% {
        transform: translate(0, 80px);
    }

    100% {
        transform: translate(0, -100%);
    }
`

const configToastError = css`
    border-color: #F45C5C;
    background: #FFEEEE;
`
const configToastSuccess = css`
    border-color: #33ADFF;
    background: #EFF9FF;
`

const configToast = {
    [toastTypes.ERROR]: configToastError,
    [toastTypes.SUCCESS]: configToastSuccess
}

const WrapToast = styled.div`
    min-width: 424px;
    width: 424px;
    margin: 0 auto;
    border: 0.75px solid;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${moveDown} ${TIME_DELAY_CLOSE}ms ease-in-out forwards;

    span {
        margin-left: 4px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #313131;
    }

    ${props => configToast[props.type]}
`;

export {
    TIME_DELAY_CLOSE,
    moveDown,
    toastTypes,
    WrapToast
}