import React from 'react'

const IcDefaultBusiness = (props) => {
  return (
    <svg {...props} width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="70" cy="70" r="70" fill="#B7B7B7"/>
        <g opacity="0.5">
        <path d="M85.9998 40H53.9998C50.2662 40 48.3993 40 46.9732 40.7266C45.7188 41.3658 44.699 42.3856 44.0598 43.6401C43.3332 45.0661 43.3332 46.933 43.3332 50.6667V100H96.6665V50.6667C96.6665 46.933 96.6665 45.0661 95.9399 43.6401C95.3007 42.3856 94.2809 41.3658 93.0264 40.7266C91.6004 40 89.7335 40 85.9998 40Z" fill="#B7B7B7"/>
        <path d="M96.6665 100V50.6667C96.6665 46.933 96.6665 45.0661 95.9399 43.6401C95.3007 42.3856 94.2809 41.3658 93.0264 40.7266C91.6004 40 89.7335 40 85.9998 40H53.9998C50.2662 40 48.3993 40 46.9732 40.7266C45.7188 41.3658 44.699 42.3856 44.0598 43.6401C43.3332 45.0661 43.3332 46.933 43.3332 50.6667V100M103.333 100H36.6665" stroke="#B7B7B7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M55 53.3334H64.1667M55 66.6667H64.1667M55 80H64.1667M75.8333 53.3334H85M75.8333 66.6667H85M75.8333 80H85" stroke="#F7F7F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>

  )
}

export default IcDefaultBusiness