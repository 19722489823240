import React from 'react'

const IcRefuseWhite = (props) => {
    return (
        <svg {...props} width={15} height={14} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.50033 12.8346C10.722 12.8346 13.3337 10.223 13.3337 7.0013C13.3337 3.77964 10.722 1.16797 7.50033 1.16797C4.27866 1.16797 1.66699 3.77964 1.66699 7.0013C1.66699 10.223 4.27866 12.8346 7.50033 12.8346Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.25 5.25L5.75 8.75M5.75 5.25L9.25 8.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcRefuseWhite
