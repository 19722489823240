export const GET_DETAIL_CUSTOMER = "GET_DETAIL_CUSTOMER"
export const GET_DETAIL_CUSTOMER_ERR = "GET_DETAIL_CUSTOMER_ERR"

export const GET_LIST_VERIFY_HISTORY_CUSTOMER = "GET_LIST_VERIFY_HISTORY_CUSTOMER"
export const GET_LIST_VERIFY_HISTORY_CUSTOMER_ERR = "GET_LIST_VERIFY_HISTORY_CUSTOMER_ERR"

export const GET_LIST_EKYC_HISTORY_CUSTOMER = "GET_EKYC_HISTORY_CUSTOMER"
export const GET_LIST_EKYC_HISTORY_CUSTOMER_ERR = "GET_EKYC_HISTORY_CUSTOMER_ERR"

export const GET_LIST_DEVICE_LOG_CUSTOMER = "GET_DEVICE_LOG_CUSTOMER"
export const GET_LIST_DEVICE_LOG_CUSTOMER_ERR = "GET_DEVICE_LOG_CUSTOMER_ERR"

export const LOCK_CUSTOMER_SUCCESS = "LOCK_CUSTOMER_SUCCESS"
export const LOCK_CUSTOMER_ERR = "LOCK_CUSTOMER_ERR"

export const LOCK_CUSTOMER_BANK_SUCCESS = "LOCK_CUSTOMER_BANK_SUCCESS"
export const LOCK_CUSTOMER_BANK_ERR = "LOCK_CUSTOMER_BANK_ERR"

export const UN_LOCK_CUSTOMER_SUCCESS = "UN_LOCK_CUSTOMER_SUCCESS"
export const UN_LOCK_CUSTOMER_ERR = "UN_LOCK_CUSTOMER_ERR"

export const UN_LOCK_CUSTOMER_BANK_SUCCESS = "UN_LOCK_CUSTOMER_BANK_SUCCESS"
export const UN_LOCK_CUSTOMER_BANK_ERR = "UN_LOCK_CUSTOMER_BANK_ERR"

export const REQUEST_UPDATE_CUSTOMER_SUCCESS = "REQUEST_UPDATE_CUSTOMER_SUCCESS"
export const REQUEST_UPDATE_CUSTOMER_ERR = "REQUEST_UPDATE_CUSTOMER_ERR"

export const UPDATE_ID_CARD_CUSTOMER_SUCCESS = "UPDATE_ID_CARD_CUSTOMER_SUCCESS"
export const UPDATE_ID_CARD_CUSTOMER_ERR = "UPDATE_ID_CARD_CUSTOMER_ERR"

export const UPDATE_PASSPORT_CUSTOMER_SUCCESS = "UPDATE_PASSPORT_CUSTOMER_SUCCESS"
export const UPDATE_PASSPORT_CUSTOMER_ERR = "UPDATE_PASSPORT_CUSTOMER_ERR"

export const RESET_LIST_VERIFY_HISTORY_CUSTOMER = "RESET_LIST_VERIFY_HISTORY_CUSTOMER"
export const RESET_LIST_EKYC_HISTORY_CUSTOMER = "RESET_LIST_EKYC_HISTORY_CUSTOMER"
export const RESET_LIST_DEVICE_HISTORY_CUSTOMER = "RESET_LIST_DEVICE_HISTORY_CUSTOMER"

export const CLEAR_CUSTOMER_DETAILS = "CLEAR_CUSTOMER_DETAILS"

export const LOADING_GET_LIST_VERIFY_CUSTOMER = "LOADING_GET_LIST_VERIFY_CUSTOMER"
export const LOADING_GET_LIST_EKYC_CUSTOMER = "LOADING_GET_LIST_EKYC_CUSTOMER"
export const LOADING_GET_LIST_DEVICE_CUSTOMER = "LOADING_GET_LIST_DEVICE_CUSTOMER"
export const LOADING_DETAIL_CUSTOMER = "LOADING_DETAIL_CUSTOMER"


export const POPUP_OPEN_LOCK_DETAIL_CUSTOMER = 'POPUP_OPEN_LOCK_DETAIL_CUSTOMER'
export const CLOSED_POPUP_LOCK_DETAIL_CUSTOMER = 'CLOSED_POPUP_OPEN_LOCK_DETAIL_CUSTOMER'

export const POPUP_LOCK_DETAIL_CUSTOMER = 'POPUP_LOCK_DETAIL_CUSTOMER'
export const CLOSED_POPUP_OPEN_LOCK_DETAIL_CUSTOMER = 'CLOSED_POPUP_LOCK_DETAIL_CUSTOMER'