import React from 'react'

const IcPhoneBlueDefault = (props) => {
    return (
        <svg {...props} width={36} height={37} viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5256_41933)">
                <circle cx={18} cy="18.5" r={18} fill="#EFF9FF" />
                <path d="M25.5001 22.1901V24.4401C25.5009 24.6489 25.4581 24.8557 25.3745 25.0471C25.2908 25.2385 25.168 25.4103 25.0141 25.5515C24.8602 25.6927 24.6785 25.8002 24.4806 25.8671C24.2828 25.934 24.0731 25.9589 23.8651 25.9401C21.5572 25.6893 19.3403 24.9007 17.3926 23.6376C15.5804 22.4861 14.0441 20.9497 12.8926 19.1376C11.6251 17.181 10.8363 14.9533 10.5901 12.6351C10.5713 12.4277 10.596 12.2186 10.6624 12.0213C10.7289 11.8239 10.8358 11.6426 10.9761 11.4888C11.1165 11.335 11.2874 11.2121 11.4779 11.128C11.6684 11.0438 11.8743 11.0003 12.0826 11.0001H14.3326C14.6966 10.9965 15.0494 11.1254 15.3254 11.3627C15.6014 11.6001 15.7816 11.9297 15.8326 12.2901C15.9275 13.0101 16.1037 13.7171 16.3576 14.3976C16.4585 14.666 16.4803 14.9578 16.4205 15.2382C16.3607 15.5187 16.2217 15.7762 16.0201 15.9801L15.0676 16.9326C16.1352 18.8102 17.6899 20.3649 19.5676 21.4326L20.5201 20.4801C20.724 20.2784 20.9814 20.1395 21.2619 20.0796C21.5424 20.0198 21.8341 20.0417 22.1026 20.1426C22.783 20.3965 23.49 20.5726 24.2101 20.6676C24.5744 20.719 24.9071 20.9025 25.145 21.1832C25.3828 21.4639 25.5092 21.8223 25.5001 22.1901Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5256_41933">
                    <rect width={36} height={36} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcPhoneBlueDefault
