import React from 'react'

const IcNotifyErrorRed = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10029_7753)">
                <path d="M7 12.8333C10.2217 12.8333 12.8333 10.2216 12.8333 6.99996C12.8333 3.7783 10.2217 1.16663 7 1.16663C3.77834 1.16663 1.16666 3.7783 1.16666 6.99996C1.16666 10.2216 3.77834 12.8333 7 12.8333Z" stroke="#F45C5C" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 9.33337H7.00583" stroke="#F45C5C" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 4.66663V6.99996" stroke="#F45C5C" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_10029_7753">
                    <rect width={14} height={14} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcNotifyErrorRed