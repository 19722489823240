import styled from "styled-components";

const WrapPopupLocked = styled.div`
    width: 320px;
    background: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 4px;;
    position: absolute;
    top: 36px;
    right: 0;
    z-index: 1;
    
    .wrap-header{
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #E7E7E7;

        .header-title{
            display: flex;
            justify-content: space-between;
            padding: 16px 16px 0 16px;
            .value{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #313131;
            }
            .icon{
                cursor: pointer;
            }
        }
        .header-value{
            padding: 0 16px 16px 16px;
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #777777;
        }
    }
    .wrap-content{
        padding: 16px;
        border-bottom: 1px solid #E7E7E7;
        &-item{
            display: flex;
            align-items: center;
            gap: 6px;
            margin-bottom: 20px;
            &-icon{
                width: 34px;
                height: 34px;
                display: flex;
                align-items: center;
                cursor: pointer;

                svg{
                    width: 100%;
                    height: 100%;
                }
            }
            &-text{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
            }
        }

        &-note{
            resize: none;
            width: calc(100% - 22px);
            height: 56px;
            border: 1px solid #DDDDDD;
            outline: none;
            border-radius: 4px;
            padding: 8px 8px 8px 12px;
            gap: 8px;
            background: #F7F7F7;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
        }
        .active{
            background: #FFFFFF;
        }
    }

    .wrap-btn{
        padding: 16px;
        display: flex;
        justify-content: flex-end;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        border-radius: 4px;
        .disable{
            opacity: 1;
        }
        .btn-reject{
            background: #F45C5C;
            color: #FFFFFF;
        }
        .btn-disable{
            background: #F7F7F7;
            color: #B7B7B7;
        }
    }
`

export { 
    WrapPopupLocked
}