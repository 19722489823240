import Axios from 'axios'

//host
import { hostCustomer } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';
import { hideLoading, showLoading } from '../../loading/action';

//types
import * as types from './types'


const apiLockCustomer = `${hostCustomer}/customer/lock`
const apiUnLockCustomer = `${hostCustomer}/customer/unlock`

const apiLockCustomerBank = `${hostCustomer}/customer/lockUserBank`
const apiUnLockCustomerBank = `${hostCustomer}/customer/unlockUserBank`

const apiGetVerifyHistoryCustomer = `${hostCustomer}/customer/getVerifyHistoryByUID`
const apiGetEkycHistoryByCustomer = `${hostCustomer}/customer/getEkycHistoryByUID`
const apiGetDeviceLogByCustomer = `${hostCustomer}/customer/getDeviceLogByUID`
const apiRequestUpdateByCustomer = `${hostCustomer}/customer/ekyc/requestUpdate`
const apiUpdateIdCardByCustomer = `${hostCustomer}/customer/ekyc/updateIdCard`
const apiUpdatePassportByCustomer = `${hostCustomer}/customer/ekyc/updatePassport`

let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let isCalling = false

export const getDetailCustomer = (details) => dispatch => {

    dispatch(showLoading(types.LOADING_DETAIL_CUSTOMER))

    dispatch({
        type: types.GET_DETAIL_CUSTOMER,
        payload: {
            details
        }
    }) 

    dispatch(hideLoading(types.LOADING_DETAIL_CUSTOMER))
}

export const clearDetailCustomer = (callbackSuccess) => async dispatch => {
    dispatch({type: types.CLEAR_CUSTOMER_DETAILS})
    callbackSuccess && callbackSuccess()
}

export const lockCustomer = (data = {
    uid: "",
    reason: "",
    reasonCode: ""
},callbackSuccess, callbackError
) => async (dispatch) => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiLockCustomer , dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.LOCK_CUSTOMER_SUCCESS,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.LOCK_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.LOCK_CUSTOMER_ERR))
    }

}

export const unLockCustomer = (data = { uid: "" }, callbackSuccess, callbackError) => async (dispatch) => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiUnLockCustomer , dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.UN_LOCK_CUSTOMER_SUCCESS,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.UN_LOCK_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.UN_LOCK_CUSTOMER_ERR))
    }
}

export const lockCustomerBank = (
    data = {
        bioId: "",
        reason: "",
        mobileReason: ""
    },
    callbackSuccess,
    callbackError
) => async (dispatch) => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiLockCustomerBank, dataRequest)
        const {data} = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.LOCK_CUSTOMER_BANK_SUCCESS,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.LOCK_CUSTOMER_BANK_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.LOCK_CUSTOMER_BANK_ERR))
    }
}

export const unLockCustomerBank = (
    data = { bioId: "" },
    callbackSuccess,
    callbackError
) => async (dispatch) => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiUnLockCustomerBank, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.UN_LOCK_CUSTOMER_BANK_SUCCESS,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.UN_LOCK_CUSTOMER_BANK_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.UN_LOCK_CUSTOMER_BANK_ERR))
    }
}


export const getListVerifyHistoryCustomer = (data = { uid: "" }, callbackSuccess, callbackError) => async (dispatch) => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = { ...headerDataRequest, data};
    if (isCalling) return
    isCalling = true
    dispatch(showLoading(types.LOADING_GET_LIST_VERIFY_CUSTOMER))
    try {
        const res = await Axios.post(apiGetVerifyHistoryCustomer, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_VERIFY_HISTORY_CUSTOMER,
                payload: {
                    listVerifyHistory: data.data.list || []
                }
            })
            isCalling = false
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_VERIFY_HISTORY_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_VERIFY_HISTORY_CUSTOMER_ERR))
    } 
    isCalling = false
    setTimeout(() => {
        dispatch(hideLoading(types.LOADING_GET_LIST_VERIFY_CUSTOMER))
    }, 300);
}

export const resetListVerifyHistoryCustomer= () => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_VERIFY_HISTORY_CUSTOMER })
}


export const getListEkycHistoryByCustomer = (data = {uid: ""}, callbackSuccess, callbackError) => async (dispatch) => {
    dispatch(showLoading(types.LOADING_GET_LIST_EKYC_CUSTOMER))
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiGetEkycHistoryByCustomer, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_EKYC_HISTORY_CUSTOMER,
                payload: {
                    listEkycHistory: data.data.list || [],
                    eKycStatus: data.data.eKycStatus || {}
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_LIST_EKYC_HISTORY_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_EKYC_HISTORY_CUSTOMER_ERR))
    }
    setTimeout(() => {
        dispatch(hideLoading(types.LOADING_GET_LIST_EKYC_CUSTOMER))
    }, 300);
}

export const resetListEkycHistoryCustomer= () => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_EKYC_HISTORY_CUSTOMER })
}


export const getListDeviceLogByCustomer = (data = {uid: ""}, callbackSuccess, callbackError) => async (dispatch) => {
    dispatch(showLoading(types.LOADING_GET_LIST_DEVICE_CUSTOMER))
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}    
    try {
        const res = await Axios.post(apiGetDeviceLogByCustomer, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_DEVICE_LOG_CUSTOMER,
                payload: {
                    listDevice: data.data.list || []
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_LIST_DEVICE_LOG_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_DEVICE_LOG_CUSTOMER_ERR))
    }
    setTimeout(() => {
        dispatch(hideLoading(types.LOADING_GET_LIST_DEVICE_CUSTOMER))
    }, 300);
}

export const resetListDeviceHistoryCustomer= () => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_DEVICE_HISTORY_CUSTOMER })
}


export const requestUpdateByCustomer = (data = {uid: ""}, callbackSuccess, callbackError) => async (dispatch) => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiRequestUpdateByCustomer, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.REQUEST_UPDATE_CUSTOMER_SUCCESS,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.REQUEST_UPDATE_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.REQUEST_UPDATE_CUSTOMER_ERR))
    }
}

export const updateIdCardByCustomer = (
    data = {
        uid: "",
        cardType: "",
        id: "",
        due_date: "",
        issue_date: "",
        issued_at: "",
        name: "",
        dob: "",
        gender: "",
        hometown: "",
        address: ""
    }, callbackSuccess, callbackError
) => async (dispatch) => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiUpdateIdCardByCustomer, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.UPDATE_ID_CARD_CUSTOMER_SUCCESS,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.UPDATE_ID_CARD_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.UPDATE_ID_CARD_CUSTOMER_ERR))
    }
}

export const updatePassportByCustomer = (
    data = {
        uid: "",
        cardType: "",
        id: "",
        due_date: "",
        issue_date: "",
        issued_at: "",
        name: "",
        dob: "",
        gender: "",
        nationality: "",
        place_of_birth: "",
        person_number: ""
    }, callbackSuccess, callbackError
) => async (dispatch) => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiUpdatePassportByCustomer, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.UPDATE_PASSPORT_CUSTOMER_SUCCESS,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.UPDATE_PASSPORT_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.UPDATE_PASSPORT_CUSTOMER_ERR))
    }
}


export const popupOpenLockDetailCustomer = () => async dispatch => {
    dispatch({type: types.POPUP_OPEN_LOCK_DETAIL_CUSTOMER})
}

export const closedPopupOpenLockDetailCustomer = () => async dispatch => {
    dispatch({type: types.CLOSED_POPUP_OPEN_LOCK_DETAIL_CUSTOMER})
}

export const popupLockDetailCustomer = () => async dispatch => {
    dispatch({type: types.POPUP_LOCK_DETAIL_CUSTOMER})
}

export const closedPopupLockDetailCustomer = () => async dispatch => {
    dispatch({type: types.CLOSED_POPUP_LOCK_DETAIL_CUSTOMER})
}


