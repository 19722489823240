import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-grow: 1;
    padding: 16px 0 22px 0;
    flex-direction: column;
    margin-left: 20px;
    .wrap-detail{
        flex-grow: 1;
        display: grid;
        grid-template-rows: 244px 1fr;
        grid-gap: 4px;
    }
`

export {
    Bound
}