import React from 'react'

const IcLogoMerchantDefault = (props) => {
  return (
    <svg {...props} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10092_4726)">
        <circle cx="16.6667" cy="16" r="16" fill="#F7F7F7" />
        <g clipPath="url(#clip1_10092_4726)">
          <path d="M9.8667 15.2445V18.7062C9.8667 23.5556 9.8667 23.5556 13.2648 23.5556H18.7063C23.4667 23.5556 23.4667 23.5556 23.4667 20.0939V15.2445" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16.6668 16C18.0494 16 19.0694 14.8742 18.9334 13.4916L18.4348 8.44446H14.9063L14.4001 13.4916C14.2641 14.8742 15.2841 16 16.6668 16Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.4343 16C22.9605 16 24.0788 14.7609 23.9277 13.2422L23.7161 11.1645C23.4441 9.20001 22.6885 8.44446 20.709 8.44446H18.4045L18.9334 13.7409C19.0619 14.9876 20.1877 16 21.4343 16Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.8615 16C13.1082 16 14.2339 14.9876 14.3548 13.7409L14.5211 12.0711L14.8837 8.44446H12.5793C10.5997 8.44446 9.84417 9.20001 9.57217 11.1645L9.36817 13.2422C9.21706 14.7609 10.3353 16 11.8615 16Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16.6667 19.7778C15.4049 19.7778 14.7778 20.4049 14.7778 21.6667V23.5556H18.5556V21.6667C18.5556 20.4049 17.9285 19.7778 16.6667 19.7778Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10092_4726">
          <rect width={32} height={32} fill="white" transform="translate(0.666748)" />
        </clipPath>
        <clipPath id="clip1_10092_4726">
          <rect width="18.1333" height="18.1333" fill="white" transform="translate(7.6001 6.93335)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IcLogoMerchantDefault