import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'


// component
import IcPlayCircle from '../../../../../../assets/images/IcPlayCircle'
import IcStatusFalse from '../../../../../../assets/images/IcStatusFalse'
import IcStatusTrue from '../../../../../../assets/images/IcStatusTrue'
import IcZoomIn from '../../../../../../assets/images/IcZoomIn'
import PopupShowMedia, { IMAGE, VIDEO } from './PopupShowMedia'
import Portal from '../../../../../Control/Portal'
import IcMerchantLogoDefault from '../../../../../../assets/images/IcMerchantLogoDefault'
import IcAlertCircle from '../../../../../../assets/images/IcAlertCircle'
import IcClock from '../../../../../../assets/images/IcClock'
import IcTransPhotoDefault from '../../../../../../assets/images/IcTransPhotoDefault'
import IcTransVerifyVideo from '../../../../../../assets/images/IcTransVerifyVideo'
import Button from '../../../../../Control/Button'
import IcCompareArrow from '../../../../../../assets/images/IcCompareArrow'
import PopupReasonRefund from './PopupReasonRefund'

// helper
import numberFormatToThousand from '../../../../../../helper/numberFormatToThousand'
import { convertDateTime } from '../../../../../../helper/convertDateTime';

// styles
import { WrapTransHistoryItem } from './index.styles'

// action
import { apiMerchantLogo, apiSupplierLogo, apiTransactionPhoto, apiTransactionVerifyVideo } from '../../../../../../constant/host'

// constant
import { DEFAULT_VAL } from '../../../../../../constant/keys'
import { BANK_ENV, env, WEE_ENV } from '../../../../../../constant/environmentTypes'
import { REFUNDED, SUCCESS } from '../../../../../../constant/transactionStatusTypes'
import { listMerchant } from '../../../../../../constant/dataMerchant';


const IMG_PAYMENT_AREA = "https://picsum.photos/36/36"
const IMG_FACE_PAYMENT = "https://picsum.photos/228/228"
const UUID_DEFAULT = "000000000000000000000000"

const isWeeEnv = env === WEE_ENV
const isBankEnv = env === BANK_ENV

const TransHistoryItem = ({ 
    data, 
    isExpandByDefault = false, 
    isExpand = false, 
    setIsExpand,
    isTransDetails=false // check if component is used in detail or history
}) => {
    
    // const [isExpand, setIsExpand] = useState(isExpandByDefault);
    const [showMedia, setShowMedia] = useState(null);
    const [isErrMerchantLogo, setIsErrMerchantLogo] = useState(false)
    const [isErrTransPhoto, setIsErrTransPhoto] = useState(false)
    const [showFillReasonRefund, setShowFillReasonRefund] = useState(false);
    // const [isErrTransVerifyVideo, setIsErrTransVerifyVideo] = useState(false)
    const [isErrSupplierLogo, setIsErrSupplierLogo] = useState(false)

    useEffect(() => {
        setIsErrMerchantLogo(false)
    }, [data?.matchedTo])

    useEffect(() => {
        setIsErrTransPhoto(false)
    }, [data?.matchedTo])
    useEffect(() => {
        setIsErrSupplierLogo(false)
    }, [data?.matchedTo])

    // useEffect(() => {
    //     setIsErrTransVerifyVideo(false)
    // }, [data?.matchedTo])

    // const [showMedia, setShowMedia] = useState({
    //     type: "VIDEO",
    //     url: "https://www.w3schools.com/html/mov_bbb.mp4"
    // });

    const _handleZoomPhoto = () => {
        if(isErrTransPhoto) return
        setShowMedia({
            type: IMAGE,
            url: apiTransactionPhoto(data.id)
        })
    }

    const _handleZoomVideo = () => {
        if(isErrTransPhoto) return
        setShowMedia({
            type: VIDEO,
            url: apiTransactionVerifyVideo(data.id)
        })
    }

    const isTransSuccess = SUCCESS === data.status
    const isTransRefunded =  REFUNDED === data.status
    const isShowBtnRefunded = isWeeEnv && isTransDetails && isTransSuccess

    return (
        <WrapTransHistoryItem isExpandByDefault={isExpandByDefault} isExpand={isExpand}>
            <div className="wrap-infos" onClick={() => setIsExpand && setIsExpand()}>
                <div className="general-infos">
                    <div className="left-infos">
                        {
                            isTransSuccess ||
                            isTransRefunded ?
                                <IcStatusTrue />
                                :
                                <IcStatusFalse />
                        }
                        <div className="wrap-id-trans">
                            <span className="id-name">Bank Tx ID:</span>
                            <span className="id-value" title={data?.bankTrxCode || ""}>{data?.bankTrxCode || DEFAULT_VAL}</span>
                        </div>
                        <div className="wrap-id-trans">
                            <span className="id-name">Merchant Tx ID:</span>
                            <span className="id-value" title={data?.merchantTrxCode || ""}>{data?.merchantTrxCode || DEFAULT_VAL}</span>
                        </div>
                        <div className="wrap-id-trans">
                            <span className="id-name">Facepay Tx ID:</span>
                            <span className="id-value" title={data?.facepayTrxCode || ""}>{data?.facepayTrxCode || DEFAULT_VAL}</span>
                        </div>
                        <div className="wrap-id-trans">
                            <span className="id-name">Thời gian:</span>
                            <span className="id-value">{data?.trxRequestTime ? convertDateTime(data.trxRequestTime, "HH:mm - DD/MM/YYYY") : DEFAULT_VAL}</span>
                        </div>
                    </div>
                    {
                        isShowBtnRefunded &&
                        <>
                            <Button 
                                className="btn-refund"
                                text="Hoàn tiền"
                                iconBtn={<IcCompareArrow />}
                                onClick={() => setShowFillReasonRefund(true)}
                            />
                            {
                                showFillReasonRefund &&
                                <Portal>
                                    <PopupReasonRefund 
                                        data={data}
                                        handleClose={() => setShowFillReasonRefund(false)}
                                    />
                                </Portal>
                            }
                        </>
                    }
                    {
                        !isTransRefunded &&
                        !isShowBtnRefunded &&
                        <div className="refunded">
                            None
                        </div>
                    }
                    {
                        isTransRefunded &&
                        <div className="refunded">
                            <span>Refunded</span>
                        </div>
                    }
                </div>
                {
                    isExpand && 
                    isTransRefunded &&
                    <div className="refunded-infos">
                        <div className="reason-refund">
                            <IcAlertCircle />
                            <span>{data.remark || "-"}</span>
                        </div>
                        <div className="time-refund">
                            <IcClock />
                            <span>{data.remarkTime ? convertDateTime(data.remarkTime, "HH:mm - DD/MM/YYYY") : "--:-- - --/--/----"}</span>
                        </div>
                    </div>
                }
            </div>
            {/* {
                isExpand && 
                <div className="not-enough-money">
                    <IcAlertCircle />
                    <span>Không đủ số dư để thực hiện giao dịch</span>
                </div>
            } */}
            {
                isExpand &&
                <div className="wrap-details">
                    <div className="wrap-infos payment-location-infos">
                        <div className="title">Điểm giao dịch</div>
                        <div className="row payment-location-row">
                            <div className="payment-location">
                                <div className="wrap-logo">
                                    {
                                        isErrMerchantLogo ?
                                            <IcMerchantLogoDefault />
                                            :
                                            <img
                                                className="logo"
                                                src={ listMerchant.filter((mer,i)=>mer.key === data?.merchant?.merchantId)[0]?.logo || listMerchant[0].logoDefault }
                                                // src={data?.merchant?.merchantId ? apiMerchantLogo(data.merchant.merchantId) : ""}
                                                alt="logo"
                                                onError={() => setIsErrMerchantLogo(true)}
                                            />
                                    }
                                </div>
                                <div className="wrap-location-infos">
                                    <div className="name" title={data?.merchant?.name || ""}>
                                        {data?.merchant?.name || DEFAULT_VAL}
                                    </div>
                                    <div className="address" title={data?.merchant?.address || ""}>
                                        {data?.merchant?.address || DEFAULT_VAL}
                                    </div>
                                </div>
                            </div>
                            <div className="payment-value">
                                <div className="value" title={data?.trxAmount ? numberFormatToThousand(data.trxAmount) : ""}>
                                    {data?.trxAmount ? numberFormatToThousand(data.trxAmount) : DEFAULT_VAL}
                                </div>
                                <div className="unit">Số tiền (đ)</div>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-infos face-recognize-infos">
                        <div className="title">
                            <p>Video xác thực</p>
                                <div className="wrap-id-trans">
                                    <span className="id-name">Image Proof :</span>
                                    <span className="id-value">{data.matchedTo === UUID_DEFAULT || !data.matchedTo ? DEFAULT_VAL : data.matchedTo }</span>
                                </div>
                        </div>
                        <div className="row face-recognize">
                            <div className="wrap-media face-img" onClick={_handleZoomPhoto}>
                                {
                                    isErrTransPhoto ?
                                        <div className="wrapper">
                                            < IcTransPhotoDefault />
                                        </div>
                                        :
                                        <img
                                            src={data?.id ? apiTransactionPhoto(data.id) : ""}
                                            alt="face recognize"
                                            onError={() => setIsErrTransPhoto(true)}
                                        />
                                }
                                <div className="tools">
                                    {/* <div className="accuracy">92%</div> */}
                                    <IcZoomIn />
                                </div>
                            </div>
                            <div className="wrap-media recog-video" onClick={_handleZoomVideo}>
                                {
                                    isErrTransPhoto ?
                                        <div className="wrapper">
                                            < IcTransVerifyVideo />
                                        </div>
                                        :
                                        <>
                                            <img
                                                src={data?.id ? apiTransactionPhoto(data.id) : ""}
                                                alt="video recognize"
                                                onError={() => setIsErrTransPhoto(true)}
                                            />
                                            <div className="overlay">
                                                <IcPlayCircle />
                                            </div>
                                        </>
                                }
                            </div>
                            {
                                !!showMedia &&
                                <Portal>
                                    <PopupShowMedia
                                        mediaType={showMedia.type}
                                        url={showMedia.url}
                                        closePopup={() => setShowMedia(null)}
                                    />
                                </Portal>
                            }
                        </div>
                    </div>
                </div>
            }
        </WrapTransHistoryItem>
    )
}

export default TransHistoryItem
