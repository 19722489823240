import React, { useEffect, useMemo, useReducer, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";

//style
import { WrapFilterRejectProfile, FilterControlTime, FilterCheckBox } from "./index.styles";

//component
import FilterControl from "../../../../../../Control/FilterControl";
import { InputFilter } from "../../../../../../Control/FilterControl/index.styles";
import DropdownSelectSearchCensorship, {
  dataSelectionCensor,
  DOCUMENT_ID,
  PHONE_NUMBER,
} from "./DropdownCensorship";
import DatePickerItem from "../../../../../../Control/DatePickerItem";
import CheckBoxControl from '../../../../../../Control/CheckBoxControl'

//image
import IcArrowRight from "../../../../../../../assets/images/IcArrowRight";
import IcCalendarActive from "../../../../../../../assets/images/IcCalendarActive";
import IcCheckBoxActive from "../../../../../../../assets/images/IcCheckBoxActive";
import IcUnCheckBox from "../../../../../../../assets/images/IcUnCheckBox";

//help
import removeAccent from "../../../../../../../helper/removeAccent";
import trimStartAndStripStr from "../../../../../../../helper/trimStartAndStripStr";

//action
import { resetListRejectProfile, updateRejectCondsFilter } from "../../../../../../../redux/censorship/listRejectProfile/action";

const iconCheckBox = {
  icon: IcUnCheckBox,
  iconActive: IcCheckBoxActive,
};
const FilterRejectProfile = () => {

  const dispatch = useDispatch()

  const inputSearchRef = useRef(null)

  const { condFilters } = useSelector(state => ({
    condFilters: state.censorshipReducer.listRejectProfileReducer.condFilters
  }))

  const [filterCond, setFilterCond] = useState(condFilters.optionSelected);
  const [valueSearch, setValueSearch] = useState(
    condFilters.optionSelected.key === DOCUMENT_ID ? condFilters.documentId 
    :
    condFilters.optionSelected.key === PHONE_NUMBER ? condFilters.phone :"" 
  );

  const [data, setData] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      phone: condFilters.phone,
      documentId: condFilters.documentId,
      startTime: condFilters.startTime,
      endTime: condFilters.endTime,
      isRejectOnce: condFilters.isRejectOnce,
      isRejectGreaterOne: condFilters.isRejectGreaterOne,
    }
  );


  useEffect(() => {
    dispatch(updateRejectCondsFilter({
      condFilters: {
        optionSelected: condFilters.optionSelected,
        lastItem: "",
        limit: 15,
        phone: condFilters.phone,
        documentId: condFilters.documentId,
        startTime: data.startTime,
        endTime: data.endTime,
        isRejectOnce: data.isRejectOnce,
        isRejectGreaterOne: data.isRejectGreaterOne
      }}
    ))
    return () => {
      // dispatch(resetListRejectProfile())
    }
  }, [JSON.stringify(data)])

  const _handleCheckRejectOnly = (val) => {
    setData({ isRejectOnce: !val });
  };
  const _handleCheckRejectMany = (val) => {
    setData({ isRejectGreaterOne: !val });
  };

  const _handleSearch = () => {
    const { key } = filterCond
    let phone = ""
    let documentId = ""
    switch (key) {
      case PHONE_NUMBER: {
        phone = valueSearch
        break;
      }
      case DOCUMENT_ID: {
        documentId = valueSearch
        break;
      }
      default:
        return;
    }
    dispatch(updateRejectCondsFilter({
      condFilters: {
        optionSelected: filterCond,
        lastItem: "",
        limit: 15,
        phone,
        documentId,
        startTime: condFilters.startTime,
        endTime: condFilters.endTime,
        isRejectOnce: condFilters.isRejectOnce,
        isRejectGreaterOne: condFilters.isRejectGreaterOne
      }}
    ))
  };

  const _handleChangeOptionFilter = (filterOption) => {
    setFilterCond(filterOption)
    setValueSearch("")
    if (inputSearchRef.current) inputSearchRef.current.value = ''
  }

  const dropdownFilter = useMemo(
    () => (
      <DropdownSelectSearchCensorship
        filterCond={filterCond}
        setFilterCond={_handleChangeOptionFilter}
      />
    ),
    [filterCond, setFilterCond]
  ); 

  const filterComponent = useMemo(() => {
    const { key } = filterCond;
    switch (key) {
      case PHONE_NUMBER: {
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder="Nhập số điện thoại"
            maxLength={10}
            ref={inputSearchRef}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                _handleSearch();
              }
            }}
            onChange={(e) => setValueSearch(removeAccent(trimStartAndStripStr(e.target.value)))}
          />
        );
      }
      case DOCUMENT_ID: {
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder="Nhập Document ID ..."
            ref={inputSearchRef}
            maxLength={20}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                _handleSearch();
              }
            }}
            onChange={(e) => setValueSearch(removeAccent(trimStartAndStripStr(e.target.value)))}
          />
        );
      }
      default:
        return null;
    }
  }, [JSON.stringify(filterCond), _handleSearch]);

  return (
    <WrapFilterRejectProfile>
      <FilterControl
        dropdownFilter={dropdownFilter}
        filterComponent={filterComponent}
        handleSearch={_handleSearch}
        isDebounce={false}
      />
      <FilterControlTime>
        <IcCalendarActive />
        <div className="title">Thời gian:</div>
        <DatePickerItem
          className="input-dropdown"
          selected={data.startTime}
          onChange={(date) => setData({ startTime: date ? moment(date).startOf('day').toDate() : undefined })}
          maxDate={data.endTime ? data.endTime : new Date()}
          textEmpty="Từ ngày"
          dateFormat="dd/MM/yyyy"
          showTimeSelect={false}
        />
        <IcArrowRight />
        <DatePickerItem
          className="input-dropdown "
          selected={data.endTime}
          onChange={(date) => setData({ endTime: date ? moment(date).endOf('day').toDate() : undefined })}
          minDate={data.startTime}
          maxDate={new Date()}
          textEmpty="Đến ngày"
          dateFormat="dd/MM/yyyy"
          showTimeSelect={false}
        />
      </FilterControlTime>

      <FilterCheckBox>
        <CheckBoxControl
          extendStyles={{ width: "80px" }}
          iconCheckBox={iconCheckBox}
          className="info-check border-left"
          isChecked={data.isRejectOnce}
          handleCheck={() => {
            _handleCheckRejectOnly(data.isRejectOnce);
          }}
          title="Từ chối 1 lần"
        />
        <CheckBoxControl
          extendStyles={{ width: "80px" }}
          iconCheckBox={iconCheckBox}
          className="info-check"
          isChecked={data.isRejectGreaterOne}
          handleCheck={() => {
            _handleCheckRejectMany(data.isRejectGreaterOne);
          }}
          title="Từ chối nhiều lần"
        />
      </FilterCheckBox>
    </WrapFilterRejectProfile>
  );
};

export default FilterRejectProfile;
