import React from 'react'

const IcLogoFacepayBlue = (props) => {
    return (
        <svg {...props} width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={64} height={64} fill="#F7F7F7" />
            <path d="M38.6103 25.2783L12 51.7796V38.5566L25.3051 25.2783L12 12H25.3051L38.6103 25.2783Z" fill="url(#paint0_linear_5483_2305)" />
            <path d="M26.9727 38.5566H40.25L51.9996 25.2783L40.3056 12H26.9727L40.3056 25.2783L26.9727 38.5566Z" fill="url(#paint1_linear_5483_2305)" />
            <defs>
                <linearGradient id="paint0_linear_5483_2305" x1="14.763" y1="53.1289" x2="29.304" y2="5.15488" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00328526" stopColor="#0044C8" />
                    <stop offset="0.8243" stopColor="#2FD1F7" />
                </linearGradient>
                <linearGradient id="paint1_linear_5483_2305" x1="26.9719" y1="25.2739" x2="51.9988" y2="25.2739" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00328526" stopColor="#0044C8" />
                    <stop offset="0.4423" stopColor="#1480DC" />
                    <stop offset={1} stopColor="#2FD1F7" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default IcLogoFacepayBlue
