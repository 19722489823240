import React from 'react'

const IcPaymentFirst = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#33ADFF"/>
        <path d="M17.3327 18.6654C17.7009 18.6654 17.9993 18.3669 17.9993 17.9987C17.9993 17.6305 17.7009 17.332 17.3327 17.332C16.9645 17.332 16.666 17.6305 16.666 17.9987C16.666 18.3669 16.9645 18.6654 17.3327 18.6654Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.0007 18.6654C10.3688 18.6654 10.6673 18.3669 10.6673 17.9987C10.6673 17.6305 10.3688 17.332 10.0007 17.332C9.63246 17.332 9.33398 17.6305 9.33398 17.9987C9.33398 18.3669 9.63246 18.6654 10.0007 18.6654Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.5917 14.3722C9.34794 14.176 9.18096 13.9003 9.12 13.5933L8 8H19.3333L18.2667 13.5933C18.2057 13.9003 18.0387 14.176 17.795 14.3722C17.5512 14.5684 17.2462 14.6727 16.9333 14.6667H10.4533C10.1405 14.6727 9.83546 14.5684 9.5917 14.3722Z" fill="white"/>
        <path d="M4.66602 4.66797H7.33268L9.11935 13.5946C9.18031 13.9016 9.34729 14.1773 9.59105 14.3735C9.8348 14.5697 10.1398 14.674 10.4527 14.668H16.9327C17.2455 14.674 17.5506 14.5697 17.7943 14.3735C18.0381 14.1773 18.2051 13.9016 18.266 13.5946L19.3327 8.0013H7.99935" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default IcPaymentFirst
