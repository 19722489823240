import React from 'react'

const IcCheckBoxActive = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.6665" y="2.6665" width="14.6667" height="14.6667" rx={1} fill="#33ADFF" stroke="#33ADFF" strokeWidth={2} />
            <path d="M15 6.25L8.125 13.125L5 10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCheckBoxActive
