import React from 'react'
import Input from '../../../../../../../../../../../Control/Input';

export default function InputNumberIDPassport({value, handleValidationUser, handleSetValueName , className}) {

    const _handleChangeValue = (event) => {
      const { value } = event.target;
      handleSetValueName && handleSetValueName(value)
    }
  return (
    <Input
        inputType="TEXT"
        value={value}
        onChange={_handleChangeValue}
        placeholder="Số giấy tờ tuỳ thân"
        handleValidationUser={handleValidationUser}
        className={className}
    />
  )
}
