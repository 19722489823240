export const GET_CUSTOMER_HISTORY = "GET_CUSTOMER_HISTORY"
export const GET_CUSTOMER_HISTORY_ERR = "GET_CUSTOMER_HISTORY_ERR"

export const CLEAR_CUSTOMER_HISTORY = "CLEAR_CUSTOMER_HISTORY"


export const UPDATE_LIST_TRANSACTION_OF_CUSTOMER_CONDFILTERS = "UPDATE_LIST_TRANSACTION_OF_CUSTOMER_CONDFILTERS"
export const RESET_LIST_TRANSACTION_OF_CUSTOMER_CONDFILTERS = "RESET_LIST_TRANSACTION_OF_CUSTOMER_CONDFILTERS"

export const GET_LIST_TRANSACTION_BANK_OF_CUSTOMER = "GET_LIST_TRANSACTION_BANK_OF_CUSTOMER"
export const GET_LIST_TRANSACTION_BANK_OF_CUSTOMER_ERR = "GET_LIST_TRANSACTION_BANK_OF_CUSTOMER_ERR"

export const GET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER = "GET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER"
export const GET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER_ERR = "GET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER_ERR"

export const RESET_LIST_TRANSACTION_OF_CUSTOMER = "RESET_LIST_TRANSACTION_OF_CUSTOMER"
export const RESET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER = "RESET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER"

export const LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER = "LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER"

export const EXPORT_LIST_TRANSACTION_OF_CUSTOMER_ERR = 'EXPORT_LIST_TRANSACTION_OF_CUSTOMER_ERR'
export const EXPORT_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER_ERR = 'EXPORT_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER_ERR'