import styled from 'styled-components'

const WrapBackLogContent = styled.div`
    position: relative;
    margin-bottom: 6px;
    .wrap-content-popup{
        position: absolute;
        right: 0;
        bottom: ${props => props.propsPopup};
        z-index: 10;
    }
`

const WrapBackLogItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    color: #313131;
    // background: rgba(247, 247, 247, 0.5);
    border-radius: 4px;
    /* position: relative; */
    .wrap-content{
        display: flex;
        align-items: center;
        border-radius: 4px;
        .icon{
            height: 24px;
            width: 24px;
            border-radius: 50%;
            margin-right: 8px;
        }
        .text{            
            font-size: 13.5px;
            line-height: 18px;
            .value{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
            }
        }
        .time{
            font-size: 11px;
            line-height: 15px;
        }
        .icon-note{
            margin-left: 5px;
            display: none;
        }
        
    }
    &.active{
        border: 2px solid #33ADFF;
        box-sizing: border-box;
        height: 48px;
        .wrap-content{
            .icon-note{
                display: block;
            }
        }
    }
    &.gray{
        background: rgba(247, 247, 247, 0.5);
    }
    &.red{
        background: #FFEEEE;
    }
    &.blue{
        background: #EFF9FF;
    }
    &:hover{
        cursor: pointer;
        border: 2px solid #33ADFF;
        box-sizing: border-box;
        height: 48px;
        .wrap-content{
            .icon-note{
                display: block;
            }
        }
    }
    
`

export { WrapBackLogContent, WrapBackLogItem }