import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//styles
import { WrapDeviceListByAdmin } from './index.styles'

//data
import { deviceStatus } from '../../../../../data/dataDeviceStatus'

// image
import IcStatusView from '../../../../../assets/images/IcStatusView'
import IcViewList from '../../../../../assets/images/IcViewList'
import IcMoreOptionBlue from '../../../../../assets/images/IcMoreOptionBlue'
import IcClose from '../../../../../assets/images/IcClose'

//component
import Button from '../../../../Control/Button'
import PageHeader from '../PageHeader'
import DeviceAdminByStatusView from './DeviceAdminByStatusView'
import DeviceAdminByListView from './DeviceAdminByListView'
import DropdownFeatureDevidce from './DropdownFeatureDevice'

// action
import { toggleListViewMode, resetListViewMode, resetListIdChecked} from '../../../../../redux/deviceV2/listDevicesByAdmin/action'

const DeviceByAdminV2 = () => {

    const dispatch = useDispatch()

    const [isShowDropdownFeatureDevice, setIsShowDropdownFeatureDevice] = useState(false)
    const [ dataItemDevice, setDataItemDevice] = useState({})
    
    const { listTempFirebase, isStatusView, listIdCheckeds, listFirebase } = useSelector(state => ({
        listTempFirebase: state.deviceReducer.listDevicesByAdmin.listTempFirebase,
        listFirebase: state.deviceReducer.listDevicesByAdmin.listFirebase,

        listIdCheckeds: state.deviceReducer.listDevicesByAdmin.listIdCheckeds,

        isStatusView: state.deviceReducer.listDevicesByAdmin.isStatusView, 
    }))
    
    useEffect(() => {
        return () => {
            dispatch(resetListViewMode())
        }
    }, [])

    const btnViewProp = useMemo(() => {
        const iconBtn = isStatusView ? <IcViewList /> : <IcStatusView />
        const text = isStatusView ? "List view" : "Status view"
        const onClick = () => dispatch(toggleListViewMode())
        return {
            iconBtn,
            text,
            onClick
        }
    }, [isStatusView])

    const handleResetListCheckbox = () => {
        dispatch(resetListIdChecked())
    }

    const handleClickOpenDropdownFeature = () => {
        setIsShowDropdownFeatureDevice(!isShowDropdownFeatureDevice)
        listFirebase.map((item) => {
            if(listIdCheckeds.includes(item.serialNumber)){
                setDataItemDevice(item)
            }
        })
    }

    return (
        <WrapDeviceListByAdmin>
            <div className="wrap-header">
                <PageHeader>Danh sách thiết bị ({listTempFirebase.length})</PageHeader>
                <div className="wrap-status-device">
                    {
                        Object.values(deviceStatus).map(({type, vnText, color}) =>(
                            <div className="devices-status" key={type}>
                                <div style={{ background:color }} className="block-status"></div>
                                <p>{vnText}</p>
                            </div>
                        ))
                    }
                </div>
                <div className="wrap-view-type">
                    {
                        listIdCheckeds.length > 0 && !isStatusView ? (
                            <div className='wrap-view-active'>
                                <div className='total-device-checkbox'>
                                    {`${listIdCheckeds.length} đang chọn`}
                                </div>
                                <IcMoreOptionBlue className='icon' onClick={handleClickOpenDropdownFeature} />
                                <div className='icon' onClick={handleResetListCheckbox}><IcClose /></div>
                                {
                                    isShowDropdownFeatureDevice ? <DropdownFeatureDevidce 
                                        onCloseDropdown={() => setIsShowDropdownFeatureDevice(false)} 
                                        checkShowFeatureDevice={listIdCheckeds.length > 1 ? false : true}
                                        data={listIdCheckeds.length === 1 ? dataItemDevice : listIdCheckeds}
                                    /> : ""
                                }
                            </div>
                        ) : ""
                    }

                    <Button
                        className="btn-header" 
                        {...btnViewProp}
                    />
                </div>
            </div>
            {
                isStatusView 
                ?   <DeviceAdminByStatusView />
                :   <DeviceAdminByListView />
            }
        </WrapDeviceListByAdmin>
    )
}

export default DeviceByAdminV2
