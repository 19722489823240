import React from 'react'

const IcInfoCustom = (props) => {
    return (
        <svg {...props} width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6062_61172)">
        <circle cx="18" cy="18.5" r="18" fill="#EFF9FF"/>
        <path d="M25.6494 11.6992H10.3494C9.41053 11.6992 8.64941 12.4603 8.64941 13.3992V23.5992C8.64941 24.5381 9.41053 25.2992 10.3494 25.2992H25.6494C26.5883 25.2992 27.3494 24.5381 27.3494 23.5992V13.3992C27.3494 12.4603 26.5883 11.6992 25.6494 11.6992Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.6369 14.25H11.8616C11.4958 14.25 11.1992 14.5354 11.1992 14.8875V18.7125C11.1992 19.0646 11.4958 19.35 11.8616 19.35H15.6369C16.0027 19.35 16.2992 19.0646 16.2992 18.7125V14.8875C16.2992 14.5354 16.0027 14.25 15.6369 14.25Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.7996 21.8984H18.8496" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.7996 18.5H18.8496" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.7996 15.0977H18.8496" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_6062_61172">
        <rect width="36" height="36" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default IcInfoCustom
