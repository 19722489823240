import React from 'react'

const IcUserCheckActive = (props) => {
    return (
        <svg {...props} width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5020_12757)">
                <path d="M16.5 21.5V19.5C16.5 18.4391 16.0786 17.4217 15.3284 16.6716C14.5783 15.9214 13.5609 15.5 12.5 15.5H5.5C4.43913 15.5 3.42172 15.9214 2.67157 16.6716C1.92143 17.4217 1.5 18.4391 1.5 19.5V21.5" fill="#33ADFF" />
                <path d="M17.5 11.5L19.5 13.5L23.5 9.5" stroke="#33ADFF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 11.5C11.2091 11.5 13 9.70914 13 7.5C13 5.29086 11.2091 3.5 9 3.5C6.79086 3.5 5 5.29086 5 7.5C5 9.70914 6.79086 11.5 9 11.5Z" fill="#33ADFF" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5020_12757">
                    <rect width={24} height={24} fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcUserCheckActive