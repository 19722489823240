import React from 'react'
import { useSelector } from 'react-redux'

import { FACEPAYTERMINAL, BANK, WALLET_FACEPAY } from '../../Page/TransactionV2'
import ExportFileBankV2 from './ExportFileBankV2'
import ExportFilePacepayV2 from './ExportFileFacepayV2'
import ExportFileWalletPacepayV2 from './ExportFileWalletFacepayV2'

const PopupExportFileV2 = ({ onClickClosePopup }) => {

    const { reqType } = useSelector((state) => ({
        reqType: state.transactionReducer.transactionOtherReducer.reqType
    }))
    
    const handleShowPopupExportTransaction = (reqType) => {
        switch (reqType) {
            case FACEPAYTERMINAL:
                return (
                    <ExportFilePacepayV2 onClickClosePopup={onClickClosePopup}/>
                )
            case BANK:
                return (
                    <ExportFileBankV2 onClickClosePopup={onClickClosePopup}/>
                )
            case WALLET_FACEPAY:
                return (
                    <ExportFileWalletPacepayV2 onClickClosePopup={onClickClosePopup}/>
                )
            default:
                return null
        }
    }

    return (
        <>
            {handleShowPopupExportTransaction(reqType)}
        </>
    )
}

export default PopupExportFileV2
