import React from 'react'

const IcActive = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#33ADFF"/>
        <g clipPath="url(#clip0_6062_57339)">
        <path d="M13.3056 9.52734H6.69444C6.17284 9.52734 5.75 9.95019 5.75 10.4718V13.7773C5.75 14.2989 6.17284 14.7218 6.69444 14.7218H13.3056C13.8272 14.7218 14.25 14.2989 14.25 13.7773V10.4718C14.25 9.95019 13.8272 9.52734 13.3056 9.52734Z" fill="white" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.63867 9.52582V7.63693C7.63808 7.05139 7.85508 6.48653 8.24754 6.05199C8.64 5.61745 9.17993 5.34424 9.76249 5.2854C10.3451 5.22655 10.9287 5.38627 11.4001 5.73355C11.8716 6.08082 12.1972 6.59088 12.3137 7.1647" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_6062_57339">
        <rect width="11.3333" height="11.3333" fill="white" transform="translate(4.33301 4.33203)"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default IcActive
