import React from 'react'

const IcCameraGray = (props) => {
  return (
    <svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.375 12.375C14.375 12.7065 14.2433 13.0245 14.0089 13.2589C13.7745 13.4933 13.4565 13.625 13.125 13.625H1.875C1.54348 13.625 1.22554 13.4933 0.991117 13.2589C0.756696 13.0245 0.625 12.7065 0.625 12.375V5.5C0.625 5.16848 0.756696 4.85054 0.991117 4.61612C1.22554 4.3817 1.54348 4.25 1.875 4.25H4.375L5.625 2.375H9.375L10.625 4.25H13.125C13.4565 4.25 13.7745 4.3817 14.0089 4.61612C14.2433 4.85054 14.375 5.16848 14.375 5.5V12.375Z" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 11.125C8.88071 11.125 10 10.0057 10 8.625C10 7.24429 8.88071 6.125 7.5 6.125C6.11929 6.125 5 7.24429 5 8.625C5 10.0057 6.11929 11.125 7.5 11.125Z" stroke="#B7B7B7" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcCameraGray