import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Data
import dataDeviceStatus from "../../../../../../../data/dataDeviceStatus";

// styles
import { WrapDeviceItemByStatus } from "./index.styles";

// component
import PopupDeviceInfos from "../../PopupDeviceInfos";
import { POPUP_DEVICE } from "../../../../../../../constant/keys";
import { getAvatarMerchant } from "../../../../../../../redux/deviceV2/listDevicesByAdmin/action";

const initialPos = {
  top: "50%",
  left: "50%",
  right: "initial",
  bottom: "initial",
};

const DeviceItemByStatus = ({ data }) => {

  const dispatch = useDispatch()

  const wrapPopupRef = useRef(null);
  const wrapItemRef = useRef(null)

  const [isShowDropdownFeatureDevice, setIsShowDropdownFeatureDevice] = useState(false)

  const objStatus = dataDeviceStatus[data.status] || {}

  const [imageUrl, setImageUrl] = useState();

  const _handleMouseEnter = () => {
    showPopupDeviceInfo()
  };

  const _handleMouseLeave = () => {
    hidePopupDeviceInfo()
    setIsShowDropdownFeatureDevice(false)
  };

  const showPopupDeviceInfo = () => {
    if(!wrapItemRef.current) return
    if(!wrapPopupRef.current) return
    const rectPopup = wrapPopupRef.current.getBoundingClientRect()
    
    const { height, top, left } = rectPopup
    const isOutY = height + top > window.innerHeight - 30
    const isOutX = 300 + left + 10 > window.innerWidth

    if (isOutY && isOutX) {
      const rectItem = wrapItemRef.current.getBoundingClientRect()
      let bottom =  -(window.innerHeight - rectItem.bottom - 30)
      wrapPopupRef.current.style.top = "initial"
      wrapPopupRef.current.style.left = "initial"
      wrapPopupRef.current.style.bottom = `${bottom}px`
      wrapPopupRef.current.style.right = "50%"
      wrapPopupRef.current.style.visibility = "visible"
      return;
    }

    if (isOutY) {
      const rectItem = wrapItemRef.current.getBoundingClientRect()
      let bottom =  -(window.innerHeight - rectItem.bottom - 30)
      wrapPopupRef.current.style.top = "initial"
      wrapPopupRef.current.style.left = "50%"
      wrapPopupRef.current.style.bottom = `${bottom}px`
      wrapPopupRef.current.style.right = "initial"
      wrapPopupRef.current.style.visibility = "visible"
      return;
    }

    if (isOutX) {
      wrapPopupRef.current.style.top = "50%"
      wrapPopupRef.current.style.left = "initial"
      wrapPopupRef.current.style.bottom = "50%"
      wrapPopupRef.current.style.right = "50%"
      wrapPopupRef.current.style.visibility = "visible"
      return;
    }

    wrapPopupRef.current.style.top = initialPos.top
    wrapPopupRef.current.style.left = initialPos.left
    wrapPopupRef.current.style.bottom = initialPos.bottom
    wrapPopupRef.current.style.right = initialPos.right
    wrapPopupRef.current.style.visibility = "visible"
  }

  const hidePopupDeviceInfo = () => {
    if(!wrapPopupRef.current) return
    wrapPopupRef.current.style.visibility = "hidden"
    wrapPopupRef.current.style.top = initialPos.top
    wrapPopupRef.current.style.left = initialPos.left
    wrapPopupRef.current.style.bottom = initialPos.bottom
    wrapPopupRef.current.style.right = initialPos.right
  }

  useEffect(() => {
    if(data.appInfo?.merchantId){
      dispatch(getAvatarMerchant({id: data.appInfo?.merchantId}, getImageSuccess))
    }else{
        dispatch(getAvatarMerchant({id: data.appInfo?.shopId}, getImageSuccess))
    }
  },[])

  const getImageSuccess = (image) => {
    setImageUrl(image)
  }

  return (
    <WrapDeviceItemByStatus
      color={objStatus?.color}
      onMouseEnter={_handleMouseEnter}
      onMouseLeave={_handleMouseLeave}
      ref={wrapItemRef}
    >
      <div className="wrap-device-infos" ref={wrapPopupRef}>
        <PopupDeviceInfos
          imageUrl={imageUrl}
          data={data} 
          type={POPUP_DEVICE}
          isShowDropdownFeatureDevicePopup={isShowDropdownFeatureDevice} setIsShowDropdownFeatureDevicePopup={setIsShowDropdownFeatureDevice} 
        />
      </div>
    </WrapDeviceItemByStatus>
  );
};

export default DeviceItemByStatus;
