import React from 'react'
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

//data
import dataMenu from '../../../data/dataMenu';

//const
import { env, envServer } from '../../../constant/environmentTypes'; 

// styles
import { WrapSideMenu } from './index.styles'

// component
import IcWeeDigital from '../../../assets/images/IcWeeDigital';
import IcHeart from '../../../assets/images/IcRedHeart';

//action
import { resetListRejectProfile, resetRejectCondsFilter } from '../../../redux/censorship/listRejectProfile/action';
import { resetListDeviceHistoryCustomer, resetListEkycHistoryCustomer, resetListVerifyHistoryCustomer } from '../../../redux/customer/customerDetail/action';
import { resetDeviceCondsFilter, resetListDevicesByAdminId } from '../../../redux/deviceV2/listDevicesByAdmin/action';
import { resetListWaitingCensorship, resetWaitingCondsFilter } from '../../../redux/censorship/listWaitingCensorship/action';
import { reqTypeCustomer, resetBankCustomerCondsFilter, resetFacepayCustomerCondsFilter, resetListCustomerBank, resetListCustomerFacepay } from '../../../redux/customer/listCustomer/actionV2';
import { clearDetailCustomer } from '../../../redux/customer/customerDetail/action';
import { resetTransactionOfCustomerCondsFilter } from '../../../redux/customer/customerTransactionHistory/actionV2';
import { resetUpdateListBacklogCustomerCondsFilter } from '../../../redux/customer/customerBacklog/action';
import { resetCustomerAnalytic } from '../../../redux/customer/customerAnalytic/action';
import { resetListTransactionBank, resetTransactionBankCondfilters } from '../../../redux/transactionV2/listTransactionBank/action';
import { resetCondFiltersTransactionFacepay } from '../../../redux/transactionV2/listTransactionFacepayTerminal/action';
import { getListStoreName, resetCondFiltersStoreName } from '../../../redux/transactionV2/transactionOther/action';
import { resetCondFilterTransactionFacepayWallet } from '../../../redux/transactionV2/listTransactionWalletFacepay/action';
import { resetCondFiltersBusiness } from '../../../redux/business/listBusinessManage/action';
import { FACEPAY } from '../../../constant/keys';
// import { dataSelectionCensor } from '../Content/Page/Censorship/WaitingCensorship/FilterWaitingCensorship/DropdownCensorship';

const SideMenu = () => {

    const dispatch = useDispatch()
    const { condFiltersStoreName } = useSelector((state) => ({
        condFiltersStoreName: state.transactionReducer.transactionOtherReducer.condFiltersStoreName
      }));

    const callbackSuccess = () => {
        dispatch(getListStoreName({
            name: [condFiltersStoreName.name]
          }))
    }

    const _handleClickBtn = (key) => {
        // console.log(key)
        dispatch(resetListDevicesByAdminId())
        dispatch(resetDeviceCondsFilter())
        dispatch(resetListWaitingCensorship())
        dispatch(resetWaitingCondsFilter())
        dispatch(resetListRejectProfile())
        dispatch(resetRejectCondsFilter())
        dispatch(resetFacepayCustomerCondsFilter())
        dispatch(resetListCustomerFacepay())
        dispatch(resetListVerifyHistoryCustomer())
        dispatch(resetListEkycHistoryCustomer())
        dispatch(resetListDeviceHistoryCustomer())
        dispatch(resetListCustomerBank())
        dispatch(resetBankCustomerCondsFilter())
        dispatch(resetTransactionOfCustomerCondsFilter())
        dispatch(clearDetailCustomer())
        dispatch(resetUpdateListBacklogCustomerCondsFilter())
        dispatch(resetCustomerAnalytic())

        dispatch(resetTransactionBankCondfilters())
        dispatch(resetCondFiltersTransactionFacepay())
        dispatch(resetCondFilterTransactionFacepayWallet())
        dispatch(resetCondFiltersStoreName(callbackSuccess))

        dispatch(resetCondFiltersBusiness())
        dispatch(reqTypeCustomer({
            reqType: FACEPAY,
        }))
    }

    return (
        <WrapSideMenu>
            <div className="wrap-menu">
            {
                dataMenu.map((item,index) =>(
                    (item.envServerValid.includes(envServer) &&
                    item.envValid.includes(env)) &&
                    <NavLink
                        to={item.path}
                        key={index}
                        className="link-item"
                        activeClassName="selected"
                        onClick={()=>_handleClickBtn(item.key)}
                    >
                        <item.IconActive className="icon active"/>
                        <item.Icon className="icon"/>
                        <p>{item.name}</p>
                    </NavLink>
                ))
            }
            </div>
            <div className="crafted">
                <div>
                    <span>crafted with</span>
                    <IcHeart className="ic-heart" />
                    <span>by</span>
                </div>
                <IcWeeDigital />
            </div>
        </WrapSideMenu>
    )
}

export default SideMenu
