import React from 'react'

const IcCallHistoryDefault = (props) => {
    return (
        <svg {...props} width={140} height={140} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx={70} cy={70} r={70} fill="#B7B7B7" />
            <path d="M103.334 86.4016V96.4016C103.338 97.33 103.147 98.2489 102.775 99.0994C102.404 99.95 101.858 100.714 101.174 101.341C100.49 101.969 99.6823 102.447 98.8029 102.744C97.9235 103.041 96.9917 103.152 96.0671 103.068C85.8099 101.954 75.9571 98.4488 67.3004 92.835C59.2465 87.7172 52.4182 80.8889 47.3004 72.835C41.667 64.139 38.1612 54.2383 37.0671 43.935C36.9838 43.0132 37.0933 42.0842 37.3888 41.207C37.6842 40.3299 38.159 39.5239 38.783 38.8404C39.407 38.1568 40.1664 37.6107 41.0131 37.2367C41.8597 36.8627 42.7749 36.6692 43.7004 36.6683H53.7004C55.3181 36.6524 56.8864 37.2252 58.113 38.2801C59.3395 39.3349 60.1407 40.7998 60.3671 42.4016C60.7892 45.6018 61.5719 48.744 62.7004 51.7683C63.1489 52.9614 63.246 54.258 62.9801 55.5046C62.7143 56.7511 62.0966 57.8953 61.2004 58.8016L56.9671 63.035C61.7123 71.3801 68.6219 78.2898 76.9671 83.035L81.2004 78.8016C82.1067 77.9054 83.2509 77.2878 84.4975 77.0219C85.744 76.7561 87.0407 76.8531 88.2338 77.3016C91.258 78.4301 94.4002 79.2129 97.6004 79.635C99.2196 79.8634 100.698 80.679 101.756 81.9266C102.813 83.1742 103.374 84.7669 103.334 86.4016Z" fill="#B7B7B7" />
        </svg>
    )
}

export default IcCallHistoryDefault
