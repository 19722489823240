import React from 'react'
import { useSelector } from 'react-redux'

import IcStore from '../../../../../../assets/images/IcStore'
import IcStoreActive from '../../../../../../assets/images/IcStoreActive'
import InputDropdown from '../../../../../Control/InputDropdown'

const iconStore = {
    icon: IcStore,
    iconActive: IcStoreActive,
    iconError: IcStoreActive,
}

const InputDropdownStore = ({ valueDropdown, categoryProps }) => {

    const { listCategories } = useSelector(state => ({
        listCategories: state.merchantReducer.listMerchantReducer.listCategories
    }))
    
    const objCategory = listCategories[[categoryProps]] 
    // console.log(Object.values(listCategories))

    const _handleSetValueDropdown = (val) =>{
        valueDropdown && valueDropdown(val)
    }

    return (
        <InputDropdown 
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            placeholder="Loại chuỗi"
            dataFilterOption={Object.values(listCategories)}
            objCategory={objCategory ? objCategory : null}
        />
    )
}

export default InputDropdownStore
