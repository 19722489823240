import React from "react";
import { useSelector } from "react-redux";

// images
import IcDeviceBgBlue from "../../../../../../../assets/images/IcDeviceBgBlue";
import { DELETE_DEVICE } from "../../../../../../../constant/keys";
import { dataFeatureDevice } from "../../../../../../../data/dataDeviceFeature";

// styles
import { WrapDeviceHareWare } from "./index.styles";

const DeviceHareWare = () => {

  const { detailsDevice } = useSelector(state => ({
    detailsDevice: state.deviceReducer.listDevicesByAdmin.detailsDevice
  }))
  
  return (
    <WrapDeviceHareWare>
      <div className="version-name">
        <IcDeviceBgBlue />
        <div className="name">
          <span className="name-title">SerialNumber</span>
          <span className="name-value">{detailsDevice.serialNumber}</span>
        </div>
      </div>
      <div className="app-version">
        <div className="app-version-item">
          <span className="version-title">Tổng bộ nhớ</span>
          <span className="version-value">{`${Math.floor(detailsDevice.totalMemory)} (MB)`} </span>
        </div>
        <div className="app-version-item">
          <span className="version-title">Bộ nhớ trống</span>
          <span className="version-value">{`${Math.floor(detailsDevice.freeMemory)} (MB)`} </span>
        </div>
        <div className="app-version-item">
          <span className="version-title">Firmware thiết bị</span>
          <span className="version-value">{detailsDevice.appInfo?.rsFirmware || "-"}</span>
        </div>
        <div className="app-version-item">
          <span className="version-title">Firmware camera</span>
          <span className="version-value">{detailsDevice.appInfo?.rsFirmware || "-"}</span>
        </div>
      </div>
      <div className="app-version-item">
        <span className="version-title">Các thiết bị plugin</span>
        <span className="version-value">{detailsDevice.peripheralConnections?.map((item) => item) || "-"}</span>
      </div>
      {dataFeatureDevice.map((item) => {
        return !item.isShowPopupDeviceDetail && item.key === DELETE_DEVICE ? (
          <div className="app-detail-feature" key={item.id}>
            <div className="icon">{item.isDisable ? item.iconDisable : item.icon}</div>
            <div className={`value ${item.isDisable ? 'disable' : ""}`}>{item.text}</div>
          </div>
        ) : (
          ""
        );
      })}
    </WrapDeviceHareWare>
  );
};

export default DeviceHareWare;
