import styled from 'styled-components'

const WrapDateRange = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    & > *:not(.arrow-right) {
        flex-grow: 1;
    }

    .arrow-right {
        transform: rotate(-90deg);
        flex-shrink: 0;
    }

    & > *:not(:first-child) {
        margin-left: 7px;
    }
 
`

const WrapInputSearchTransaction = styled.div`
    display: flex;
    .input-search-transaction{
        width: 432px;
        height: 36px;
    }
`

export {
    WrapDateRange,
    WrapInputSearchTransaction
}