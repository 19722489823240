import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route, Redirect } from "react-router-dom";

//style
import { WrapCensorshipList } from './index.styles'

//component
import TabListAndToolCensorship from './TabListAndToolCensorship';
import WaitingCensorship from './WaitingCensorship';
import RejectionProfile from './RejectionProfile';

//path
import * as path from '../../../../../constant/path'
// import { getListReason } from '../../../../../redux/censorship/listReason/action';
import { resetListWaitingCensorship, resetWaitingCondsFilter } from '../../../../../redux/censorship/listWaitingCensorship/action';

const Censorship = () => {

    // const dispatch = useDispatch()

    useEffect(() => {
        
        return () => {
            
        }
    }, [])

    return (
        <WrapCensorshipList>
            <div className="bread-row">
                <TabListAndToolCensorship />
            </div>
            <Switch>
                <Route exact path={`/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}`}>
                    <WaitingCensorship />
                </Route>
                <Route exact path={`/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}`}>
                    <RejectionProfile />
                </Route>
                <Redirect to={`/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}`} />
            </Switch>
        </WrapCensorshipList>
    )
}

export default Censorship
