import IcDeviceBacklog from "../assets/images/Backlog/IcDeviceBacklog"
import IcDisableAccount from "../assets/images/Backlog/IcDisableAccount"
import IcLock from "../assets/images/Backlog/IcLock"
import IcRefreshBacklog from "../assets/images/Backlog/IcRefreshBacklog"
import IcUnlockSystem from "../assets/images/Backlog/IcUnlockSystem"
import IcUnlinkBank from "../assets/images/Backlog/IcUnlinkBank"
import IcLinkBankFail from "../assets/images/Backlog/IcLinkBankFail"
import IcLinkBankSuccess from "../assets/images/Backlog/IcLinkBankSuccess"
import IcLinkBankGray from "../assets/images/Backlog/IcLinkBankGray"
import IceKYCGray from "../assets/images/Backlog/IceKYCGray"
import IcCheckGray from "../assets/images/Backlog/IcCheckGray"
import IcLoginGray from "../assets/images/Backlog/IcLoginGray"
import IcCheckBgBlue from "../assets/images/IcCheckBgBlue"
import IcPaymentFirst from "../assets/images/Backlog/IcPaymentFirst"
import IcCloseBgGray from "../assets/images/Backlog/IcCloseBgGray"

import { APPROVAL_ACCOUNT_SUCCESS, BLOCK_ACCOUNT_10_MINUTES_WRONG_PIN, BLOCK_ACCOUNT_20_MINUTES_WRONG_PIN, BLOCK_ACCOUNT_2_MINUTES_WRONG_PIN, BLOCK_ACCOUNT_5_MINUTES_WRONG_PIN, BLOCK_ACCOUNT_60_MINUTES_WRONG_PIN, BLUE, CLIENT_SUBMIT_EKYC, DISABLE_ACCOUNT, GRAY, LINK_BANK_ACCOUNT_FAILED, LINK_BANK_ACCOUNT_SUCCESS, LOCK_ACCOUNT_FROM_ADMIN, LOCK_ACCOUNT_FROM_SYSTEM, LOCK_OTP_24_HOURS, LOCK_OTP_5_MINUTES, LOCK_OTP_60_MINUTES, LOGIN, LOGIN_ANOTHER_DEVICE, OTP_CARD_SUCCESS, RED, REQUEST_UPDATE_EKYC, UNLINK_BANK_ACCOUNT, UNLOCK_ACCOUNT_FROM_ADMIN, UPDATE_EKYC_FROM_ADMIN, UPDATE_PIN, OTP_FAIL_3_TIMES, FACEPAY_ACTIVATED, FIRST_TIME_PAYMENT, REJECTED_FACE, REUPLOAD_AVATAR } from "../constant/keys"

const typeBacklog = [
    {
        text: "Tạo mã PIN mới",
        key: UPDATE_PIN,
        value: '',
        icon: <IcRefreshBacklog />,
        style: GRAY
    },
    {
        text: "Đăng nhập tài khoản từ thiết bị khác",
        key: LOGIN_ANOTHER_DEVICE,
        value: '',
        icon: <IcDeviceBacklog />,
        style: RED
    },
    {
        text: "Tạm ngưng tài khoản",
        key: DISABLE_ACCOUNT,
        value: '',
        icon: <IcDisableAccount />,
        style: RED
    },
    {
        text: "Mở khoá từ hệ thống bởi",
        key: UNLOCK_ACCOUNT_FROM_ADMIN,
        value: '',
        icon: <IcUnlockSystem />,
        style: BLUE
    },
    {
        text: "Khoá từ hệ thống bởi",
        key: LOCK_ACCOUNT_FROM_ADMIN,
        value: '',
        icon: <IcLock />,
        style: RED
    },
    {
        text: "Khách hàng huỷ liên kết thẻ",
        key: UNLINK_BANK_ACCOUNT,
        value: '',
        icon: <IcUnlinkBank />,
        style: RED
    },
    {
        text: "Liên kết thẻ ngân hàng thất bại",
        key: LINK_BANK_ACCOUNT_FAILED,
        value: '',
        icon: <IcLinkBankFail />,
        style: RED
    },
    {
        text: "Liên kết thẻ ngân hàng thành công",
        key: LINK_BANK_ACCOUNT_SUCCESS,
        value: '',
        icon: <IcLinkBankSuccess />,
        style: BLUE
    },
    {
        text: "Khoá nhập OTP 24 giờ do",
        value: 'Nhập sai OTP liên kết thẻ 9 lần',
        key: LOCK_OTP_24_HOURS,
        icon: <IcLock />,
        style: RED
    },
    {
        text: "Khoá nhập OTP 60 phút do",
        value: 'Nhập sai OTP liên kết thẻ 6 lần',
        key: LOCK_OTP_60_MINUTES,
        icon: <IcLock />,
        style: RED
    },
    {
        text: "Khoá nhập OTP 5 phút do",
        value: 'Nhập sai OTP liên kết thẻ 3 lần',
        key: LOCK_OTP_5_MINUTES,
        icon: <IcLock />,
        style: RED
    },
    {
        text: "Xác thực OTP liên kết thẻ thành công",
        key: OTP_CARD_SUCCESS,
        value: '',
        icon: <IcLinkBankGray />,
        style: GRAY
    },
    {
        text: "Yêu cầu cập nhật giấy tờ từ hệ thống bởi",
        key: REQUEST_UPDATE_EKYC,
        value: '',
        icon: <IcRefreshBacklog />,
        style: GRAY
    },
    {
        text: "Tùy chỉnh giấy tờ bởi",
        key: UPDATE_EKYC_FROM_ADMIN,
        value: '',
        icon: <IcRefreshBacklog />,
        style: GRAY
    },
    {
        text: "Gửi thông tin eKYC",
        key: CLIENT_SUBMIT_EKYC,
        value: '',
        icon: <IceKYCGray />,
        style: GRAY
    },
    {
        text: "Khoá từ hệ thống",
        key: LOCK_ACCOUNT_FROM_SYSTEM,
        value: '',
        icon: <IcLock />, 
        style: RED
    },
    {
        text: "Khoá tài khoản 60 phút do",
        key: BLOCK_ACCOUNT_60_MINUTES_WRONG_PIN,
        value: 'Nhập sai PIN quá 9 lần',
        icon: <IcLock />,
        style: RED
    },
    {
        text: "Khoá tài khoản 20 phút do",
        key: BLOCK_ACCOUNT_20_MINUTES_WRONG_PIN,
        value: 'Nhập sai PIN quá 8 lần',
        icon: <IcLock />,
        style: RED
    },
    {
        text: "Khoá tài khoản 10 phút do",
        key: BLOCK_ACCOUNT_10_MINUTES_WRONG_PIN,
        value: 'Nhập sai PIN quá 7 lần',
        icon: <IcLock />,
        style: RED
    },
    {
        text: "Khoá tài khoản 5 phút do",
        key: BLOCK_ACCOUNT_5_MINUTES_WRONG_PIN,
        value: 'Nhập sai PIN quá 6 lần',
        icon: <IcLock />,
        style: RED
    },
    {
        text: "Khoá tài khoản 2 phút do",
        key: BLOCK_ACCOUNT_2_MINUTES_WRONG_PIN,
        value: 'Nhập sai PIN quá 5 lần',
        icon: <IcLock />,
        style: RED
    },
    {
        text: "Kiểm duyệt tài khoản thành công bởi",
        key: APPROVAL_ACCOUNT_SUCCESS,
        value: '',
        icon: <IcCheckGray />,
        style: GRAY
    },
    {
        text: "Đăng nhập tài khoản",
        key: LOGIN,
        value: '',
        icon: <IcLoginGray />,
        style: GRAY
    },
    {
        text: "Xác thực mã OTP thất bại",
        key: OTP_FAIL_3_TIMES,
        value: '',
        icon: <IcLock />,
        style: RED
    },
    {
        text: "Cập nhật hình ảnh kiểm duyệt",
        key: REUPLOAD_AVATAR,
        value: '',
        icon: <IcRefreshBacklog />,
        style: GRAY
    },
    {
        text: "Từ chối kiểm duyệt tài khoản bởi",
        key: REJECTED_FACE,
        value: '',
        icon: <IcCloseBgGray />,
        style: GRAY
    },

    // BANK
    {
        text: "Kích hoạt Facepay lần đầu",
        key: FACEPAY_ACTIVATED,
        value: '',
        icon: <IcCheckBgBlue />,
        style: BLUE
    },
    {
        text: "Thanh toán Facepay lần đầu",
        key: FIRST_TIME_PAYMENT,
        value: '',
        icon: <IcPaymentFirst />,
        style: BLUE
    },
]

const convertTypeBacklogToStr = (type, value, className) => {
    
    const info = value?.map((item) => item)
    
    const str = typeBacklog.map((item, index) => {
        return item.key === type ? (
            item.text.length > 0 && (
                <div className={className} key={index}>
                    <div className="icon">{item.icon}</div>
                    <div className="text">
                        {item.text} {item.value.length === 0 ? <span className='value'>{info || ""}</span> : <span className='value'>{item.value}</span>}
                    </div>
                </div>  
            )
        ) : ""
    }).filter((item) => item && item)

    const style = typeBacklog.map((item) => {return item.key === type ? item.style : ""}).filter((item) => item.length > 0)

    return {str: str, style: style, info: info}
}

export default convertTypeBacklogToStr