import React from 'react'

const IcCensorshipDefault = (props) => {
    return (
        <svg {...props} width={141} height={140} viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="70.5" cy={70} r={70} fill="#B7B7B7" />
            <path d="M77.1668 36.668H50.5002C48.7321 36.668 47.0364 37.3703 45.7861 38.6206C44.5359 39.8708 43.8335 41.5665 43.8335 43.3346V96.668C43.8335 98.4361 44.5359 100.132 45.7861 101.382C47.0364 102.632 48.7321 103.335 50.5002 103.335H90.5002C92.2683 103.335 93.964 102.632 95.2142 101.382C96.4644 100.132 97.1668 98.4361 97.1668 96.668V56.668L77.1668 36.668Z" fill="#B7B7B7" />
            <path d="M83.8332 86.668H57.1665" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M83.8332 73.332H57.1665" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M63.8332 60H60.4998H57.1665" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M77.1665 36.668V56.668H97.1665" fill="white" />
        </svg>
    )
}

export default IcCensorshipDefault
