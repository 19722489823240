import styled from "styled-components";

const WrapDeviceAppInfo = styled.div`
    margin: 24px 0 0px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 42px;
    overflow: hidden;
    .app-detail-feature{
        display: flex;
        align-item: center;
        justify-content: flex-end;
        gap: 4px;
        cursor: pointer;
        margin-top: 14px;
        .icon{
            display: flex;
            align-items: center;
        }
        .value{
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #33ADFF;
        }
    }

    .app-detail{
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-left{
            display: flex;
            flex-direction: column;
            gap: 18px;
            .version-name{
                display: flex;
                align-items: center;
                gap: 6px;

                .name{
                    display: flex;
                    flex-direction: column;
                    font-family: 'Open Sans';
                    font-style: normal;
                    &-title{
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 15px;
                        letter-spacing: -0.02em;
                        color: #777777;
                    }
                    &-value{
                        font-weight: 600;
                        font-size: 13.5px;
                        line-height: 18px;
                        letter-spacing: -0.01em;
                        color: #313131;
                    }
                }
            }
        }

        &-right{
            display: flex;
            flex-direction: column;
            gap: 35px;
        }
    }

    .app-version{
        display: flex;
        align-items: center;
        gap: 18px;
        margin: 32px 0 37px 0;
        &-item{
            display: flex;
            font-family: 'Open Sans';
            font-style: normal;
            gap: 14px;
            .version-item{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
            .version-title{
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #777777;
            }

            .version-value{
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
                white-space: pre-wrap;
                &.link{
                    color: #33ADFF;
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    text-align: right;
                    letter-spacing: -0.01em;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                }
            }
        }
    }
`

export {
    WrapDeviceAppInfo
}