import styled from 'styled-components'

const Bound = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: overlay;
    position: relative;
    .wrap-emty{
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
        p{
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #B7B7B7;
        }
    }
    .wrap-list-device{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        .wrap-header{
            background: rgba(247, 247, 247, 0.5);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 17px;
            padding: 8px;

            .header-left{
                display: flex;
                align-items: center;
                gap: 4px;
                .type-active{
                    display: flex;
                    align-items: center;
                    padding: 3px 8px 3px 3px;
                    gap: 3px;
                    background: #EFF9FF;
                    border-radius: 20px;
                }
                .type-pending{
                    display: flex;
                    align-items: center;
                    padding: 3px 8px 3px 8px;
                    gap: 3px;
                    background: #F7F7F7;
                    border-radius: 20px;
                }
                .type-icon{
                    display: flex;
                    align-items: center;
                }
                .type-text{
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
                .value{
                    font-weight: 600;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
            }
            .info-time{
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
                display: flex;
                gap: 5px;
            }

        }
        .device-title{
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #313131;
            padding: 9px 0 0 10px;
        }
        .device{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 60px;
            padding: 34px 0 34px 10px;
            .device-item{
                display: flex;
                align-items: center;
                gap: 10px;
            }
            .device-value{
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #313131;
                margin-bottom: 2px;
            }
            .device-text{
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #777777;
            }
        }
    }
`

export {
    Bound
}