import styled from "styled-components";

const WrapPopupExportFileBusiness = styled.div`
    position: absolute;
    top: 42px;
    right: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 1;
    .icon-close{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .export-content{
        font-family: 'Open Sans';
        font-style: normal;
        padding: 20px;

        &-title{
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
        }
        &-value{
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #313131;
            margin: 6px 0 20px 0;
        }
        &-date{
            width: 320px;
            height: 36px;
            display: flex;
            align-items: center;
            border-radius: 33px;
            border: 0.5px solid #B7B7B7;
            box-sizing: border-box;
            padding: 9px 14px;
            margin-bottom: 40px;
            .date-title{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #313131;
                margin: 0 12px 0 6px;
            }
        }
        &-btn{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
`

export {
    WrapPopupExportFileBusiness
}