import React from 'react'

const IcDefaultBacklog = (props) => {
  return (
    <svg {...props} width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="70" cy="70" r="70" fill="#B7B7B7"/>
        <path d="M56.6667 90H100" stroke="#B7B7B7" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M40 90H40.0333" stroke="#B7B7B7" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M56.6667 70H100" stroke="#B7B7B7" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M40 70H40.0333" stroke="#B7B7B7" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M56.6667 50H100" stroke="#B7B7B7" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M40 50H40.0333" stroke="#B7B7B7" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcDefaultBacklog