import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// styles
import { WrapPopupLocked } from './index.styles'

// constant
import { host } from "../../../../../../../constant/host";
import trimStartAndStripStr from '../../../../../../../helper/trimStartAndStripStr'

// images
import IcReasonBusiness from '../../../../../../../assets/images/IcReasonBusiness'
import IcClose from '../../../../../../../assets/images/IcClose'
import IcCloseRed from '../../../../../../../assets/images/IcCloseRed'
import IcCloseOutlineWhite from '../../../../../../../assets/images/IcCloseOutlineWhite'
import IcCloseOutlineGray from '../../../../../../../assets/images/IcCloseOutlineGray'

// control
import TextArea from '../../../../../../Control/TextArea'
import Button from '../../../../../../Control/Button'

// redux
import { businessReject, getBusinessDetail, getListBusiness } from '../../../../../../../redux/business/listBusinessManage/action';

// hook
import { useOnClickOutside } from '../../../../../../../hook/useClickOutside'

const PopupLocked = ({ businessId, reason, onClickClosePopupDetails, onClickClosePopupLock }) => {
    const listReason = [
        ...reason,
        {
            id: 2,
            reason: "Lý do khác",
            iconDefault: <IcReasonBusiness />,
            isActive: false
        }
    ]

    const refPopup = useRef(null)
    const dispatch = useDispatch()

    const { condFiltersBusiness } = useSelector((state) => ({
        condFiltersBusiness: state.businessReducer.listBusinessManage.condFiltersBusiness,
    }))

    const [list, setList] = useState(listReason)
    const [valueTextArea, setValueTextArea] = useState('')

    const dataRequest = (condFiltersBusiness, isScroll, listBussiness) => {
        const checkIsSuccessAction = condFiltersBusiness.optionSelectCheckboxBusiness.reduce((a, v) => ({ ...a, [v.key]: v.isSelect }), {})

        return {
            ...checkIsSuccessAction,
            isDenied: false,
            businessCode: condFiltersBusiness.businessCode,
            phoneNumber: condFiltersBusiness.phoneNumber,
            ownerName: condFiltersBusiness.ownerName,
            businessName: condFiltersBusiness.businessName,
            begin: condFiltersBusiness.begin,
            end: condFiltersBusiness.end,
            limit: condFiltersBusiness.limit,
            lastItem: isScroll ? listBussiness.at(-1).id : condFiltersBusiness.lastItem,
        }
    }

    const totalReject = list.filter((item) => {
        if (item !== list.at(-1)) {
            return item.isActive
        }
    }).length

    const handleClick = (index) => {
        const listTemp = [...list]
        listTemp[index].isActive = !listTemp[index].isActive
        if (!list.at(-1).isActive) setValueTextArea("")
        setList(listTemp)
    }

    const handleSubmit = () => {
        let payload = []
        list
            .filter((item) => item !== list.at(-1))
            .map((item) => {

                if (item.isActive && !list.at(-1).isActive && valueTextArea.length === 0) {
                    payload.push(item.reason)
                    return
                }
                if (!item.isActive && list.at(-1).isActive && valueTextArea.length > 0) {
                    payload.push(valueTextArea)
                    return
                }
                if (item.isActive && list.at(-1).isActive && valueTextArea.length > 0) {
                    payload.push(item.reason, valueTextArea)
                    return
                }
            })
        const callbackSucess = () => {
            dispatch(getBusinessDetail({ businessID: businessId }))
            dispatch(getListBusiness(dataRequest(condFiltersBusiness, false), false))
            onClickClosePopupDetails()
        }
        dispatch(businessReject({
            businessID: businessId,
            reasons: Array.from(new Set(payload))
        }, callbackSucess))
    }

    const handleClose = () => {
        onClickClosePopupLock()
        let listTemp = [...list]
        listTemp = list.filter((item) => item.isActive = false)
        setList(listTemp)
    }

    useOnClickOutside(refPopup, () => handleClose())

    return (
        <WrapPopupLocked ref={refPopup}>
            <div className='wrap-header'>
                <div className='header-title'>
                    <div className='value'>Từ chối hồ sơ</div>
                    <IcClose className='icon' onClick={handleClose} />
                </div>
                <div className='header-value'>Bạn vui lòng xác nhận lý do từ chối hồ sơ </div>
            </div>
            <div className='wrap-content'>
                {
                    list.map((item, index) => (
                        <div className='wrap-content-item' key={index}>
                            <div className='wrap-content-item-icon' onClick={() => handleClick(index)}>
                                {
                                    !item.isActive ? (
                                        <>
                                            {!item.iconDefault ? <img src={`${host}${item.url}`} /> : item.iconDefault}
                                        </>) : <IcCloseRed />
                                }
                            </div>
                            <div className='wrap-content-item-text'>
                                {item.reason}
                            </div>
                        </div>
                    ))
                }
                {
                    list.at(-1).isActive ?
                        <TextArea
                            className={`wrap-content-note ${valueTextArea.length > 0 ? 'active' : ""}`}
                            placeholder='Nhập nội dung ghi chú tối đa 120 ký tự'
                            maxLength={120}
                            value={valueTextArea}
                            onChange={(e) => setValueTextArea(trimStartAndStripStr(e.target.value))}
                        />
                        : ""
                }
            </div>
            <div className='wrap-btn'>
                <Button
                    iconBtn={list.at(-1).isActive && valueTextArea.length > 0 ? <IcCloseOutlineWhite /> : totalReject > 0 && (list.at(-1).isActive && valueTextArea.length > 0) ? <IcCloseOutlineWhite /> : totalReject > 0 && (!list.at(-1).isActive && valueTextArea.length === 0) ? <IcCloseOutlineWhite /> : <IcCloseOutlineGray />}
                    className={`${list.at(-1).isActive && valueTextArea.length > 0 ? 'btn-reject' : totalReject > 0 && (list.at(-1).isActive && valueTextArea.length > 0) ? 'btn-reject' : totalReject > 0 && (!list.at(-1).isActive && valueTextArea.length === 0) ? 'btn-reject' : 'btn-disable'}`}
                    text='Từ chối'
                    isDisable={
                        list.at(-1).isActive && valueTextArea.length > 0 ? false : totalReject > 0 && (list.at(-1).isActive && valueTextArea.length > 0) ? false : totalReject > 0 && (!list.at(-1).isActive && valueTextArea.length === 0) ? false : true
                    }
                    onClick={handleSubmit}
                />
            </div>
        </WrapPopupLocked>
    )
}

export default PopupLocked