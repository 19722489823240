
// constant
import * as path from '../constant/path'
import { env, WEE_ENV, BANK_ENV, DEV_ENV } from '../constant/environmentTypes'

// component
import AnalyticPage from '../components/Main/Content/Page/AnalyticPage'
import TransactionsList from '../components/Main/Content/Page/TransactionsList'
import TransactionHistory from '../components/Main/Content/Page/TransactionHistory'
import TransactionDetails from '../components/Main/Content/Page/TransactionDetails'
import DeviceList from '../components/Main/Content/Page/DeviceList'
import MerchantList from '../components/Main/Content/Page/MerchantList'
import MerchantDetails from '../components/Main/Content/Page/MerchantDetails'
import BankList from '../components/Main/Content/Page/BankList'
import BankDetails from '../components/Main/Content/Page/BankDetails'
import DeviceListByAdmin from '../components/Main/Content/Page/DeviceListByAdmin'
import Permission from '../components/Main/Content/Page/Permission'
import CustomerList from '../components/Main/Content/Page/CustomerList'
import CustomerV2 from '../components/Main/Content/Page/CustomerV2'
import CustomerDetails from '../components/Main/Content/Page/CustomerDetails'
import CustTransHistory from '../components/Main/Content/Page/CustTransHistory'
import DeviceMapByAdmin from '../components/Main/Content/Page/DeviceMapByAdmin'
import Censorship from '../components/Main/Content/Page/Censorship'
import WaitingCensorshipDetail from '../components/Main/Content/Page/Censorship/WaitingCensorshipDetail'
import RejectionProfileDetail from '../components/Main/Content/Page/Censorship/RejectionProfileDetail'
import WaitingCensorship from '../components/Main/Content/Page/Censorship/WaitingCensorship'
import RejectionProfile from '../components/Main/Content/Page/Censorship/RejectionProfile'

import AdminTransactionsList from '../components/Main/Content/Page/AdminTransactionList'
import TransactionV2 from '../components/Main/Content/Page/TransactionV2'
import DeviceByAdminV2 from '../components/Main/Content/Page/DeviceByAdminV2'
import BusinessManage from '../components/Main/Content/Page/BusinessManage'

const dataRoutes = [
    // {
    //     exact: false,
    //     path: `/${path.MAIN}/${path.ANALYTIC}`,
    //     Component: AnalyticPage,
    //     envValid: [DEV_ENV, WEE_ENV, BANK_ENV]
    // },
    // {
    //     exact: true,
    //     path: `/${path.MAIN}/${path.TRANSACTION}`,
    //     Component: 
    //         env === BANK_ENV ? 
    //         TransactionsList 
    //         : 
    //         AdminTransactionsList
    //     ,
    //     envValid: [DEV_ENV, WEE_ENV, BANK_ENV]
    // },
    {
        exact: true,
        path: `/${path.MAIN}/${path.TRANSACTION}`,
        Component: TransactionV2,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        exact: true,
        path: `/${path.MAIN}/${path.TRANSACTION}/:facepayId/${path.HISTORY}/:biometricId`,
        Component: TransactionHistory,
        envValid: [DEV_ENV, WEE_ENV, BANK_ENV]
    },
    {
        exact: true,
        path: `/${path.MAIN}/${path.TRANSACTION}/:facepayId`,
        Component: TransactionDetails,
        envValid: [DEV_ENV, WEE_ENV, BANK_ENV]
    },
    {
        exact: true,
        path: `/${path.MAIN}/${path.DEVICE}`,
        Component: DeviceByAdminV2,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        exact: true,
        path: `/${path.MAIN}/${path.DEVICE}/:deviceId/${path.LOCATION}`,
        Component: DeviceMapByAdmin,
        envValid: [DEV_ENV, WEE_ENV]
    },
    // {
    //     exact: true,
    //     path: `/${path.MAIN}/${path.PAYMENT_TERMINAL}`,
    //     Component: DeviceList,
    //     envValid: [DEV_ENV, BANK_ENV]
    // },
    {
        exact: true,
        path: [`/${path.MAIN}/${path.CUSTOMER}`, `/${path.MAIN}/${path.CUSTOMER}/${path.CUSTOMER_FACEPAY}`, `/${path.MAIN}/${path.CUSTOMER}/${path.CUSTOMER_BANK}`],
        Component: CustomerV2,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        exact: true,
        path: `/${path.MAIN}/${path.CUSTOMER}/:custId/${path.HISTORY}`,
        Component: CustTransHistory,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        exact: true,
        path: `/${path.MAIN}/${path.CUSTOMER}/:custId`,
        Component: CustomerDetails,
        envValid: [DEV_ENV, WEE_ENV]
    },
    // {
    //     exact: true,
    //     path: `/${path.MAIN}/${path.MERCHANT}`,
    //     Component: MerchantList,
    //     envValid: [DEV_ENV, WEE_ENV]
    // },
    // {
    //     exact: false,
    //     path: `/${path.MAIN}/${path.MERCHANT}/:merchantId`,
    //     Component: MerchantDetails,
    //     envValid: [DEV_ENV, WEE_ENV]
    // },
    // {
    //     exact: true,
    //     path: `/${path.MAIN}/${path.BANK}`,
    //     Component: BankList,
    //     envValid: [DEV_ENV, WEE_ENV]
    // },
    // {
    //     exact: false,
    //     path: `/${path.MAIN}/${path.BANK}/:bankId`,
    //     Component: BankDetails,
    //     envValid: [DEV_ENV, WEE_ENV]
    // },
    // {
    //     exact: true,
    //     path: `/${path.MAIN}/${path.PERMISSION}`,
    //     Component: Permission,
    //     envValid: [DEV_ENV, BANK_ENV]
    // },
    {
        exact: true,
        path: [`/${path.MAIN}/${path.CENSORSHIP}`, `/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}`, `/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}`],
        Component: Censorship,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        exact: false,
        path: `/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}/:censorshipId`,
        Component: WaitingCensorshipDetail,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        exact: false,
        path: `/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}/:censorshipId`,
        Component: RejectionProfileDetail,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        exact: true,
        path: `/${path.MAIN}/${path.BUSSINESS_MANAGE}`,
        Component: BusinessManage,
        envValid: [DEV_ENV, WEE_ENV]
    },
]

export default dataRoutes