import React from 'react'

const IcAvararDefault = (props) => {
    return (
        <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10206_9079)">
                <circle opacity="0.3" cx={18} cy={18} r={18} fill="#B7B7B7" />
                <path d="M24.8 25.65V23.95C24.8 23.0483 24.4417 22.1834 23.8041 21.5458C23.1665 20.9082 22.3017 20.55 21.4 20.55H14.6C13.6982 20.55 12.8334 20.9082 12.1958 21.5458C11.5582 22.1834 11.2 23.0483 11.2 23.95V25.65" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.0001 17.15C19.8779 17.15 21.4001 15.6277 21.4001 13.75C21.4001 11.8722 19.8779 10.35 18.0001 10.35C16.1223 10.35 14.6001 11.8722 14.6001 13.75C14.6001 15.6277 16.1223 17.15 18.0001 17.15Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_10206_9079">
                    <rect width={36} height={36} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcAvararDefault