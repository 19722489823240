import React from 'react'

const IcBankMini = (props) => {
    return (
        <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6071_67036)">
                <circle cx={16} cy="16.5" r={16} fill="#EFF9FF" />
                <path d="M16.2804 9.05705L23.0804 11.777C23.3449 11.8828 23.5564 12.2001 23.5564 12.4797V14.9881C23.5564 15.4037 23.2164 15.7437 22.8009 15.7437H9.20087C8.78531 15.7437 8.44531 15.4037 8.44531 14.9881V12.4797C8.44531 12.2001 8.65687 11.8828 8.92132 11.777L15.7213 9.05705C15.8724 8.99661 16.1293 8.99661 16.2804 9.05705Z" stroke="#33ADFF" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M23.5564 24.0535H8.44531V21.7868C8.44531 21.3712 8.78531 21.0312 9.20087 21.0312H22.8009C23.2164 21.0312 23.5564 21.3712 23.5564 21.7868V24.0535Z" stroke="#33ADFF" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.95703 21.0311V15.7422" stroke="#33ADFF" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.9785 21.0311V15.7422" stroke="#33ADFF" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 21.0311V15.7422" stroke="#33ADFF" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.0234 21.0311V15.7422" stroke="#33ADFF" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22.0449 21.0311V15.7422" stroke="#33ADFF" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.0005 13.8565C16.6264 13.8565 17.1339 13.3491 17.1339 12.7232C17.1339 12.0973 16.6264 11.5898 16.0005 11.5898C15.3746 11.5898 14.8672 12.0973 14.8672 12.7232C14.8672 13.3491 15.3746 13.8565 16.0005 13.8565Z" stroke="#33ADFF" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_6071_67036">
                    <rect width={32} height={32} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcBankMini
