import React, { useState, useRef, useEffect } from 'react'

//style
import { WrapDropdownMultiChoice } from './index.styles'

//image
import IcArrowDownGray from '../../../assets/images/IcArrowDownGray';

//component
import CheckBoxControl from '../CheckBoxControl';
import { useOnClickOutside } from '../../../hook/useClickOutside';
import { dataBankType } from '../../../data/dataBankType';

const DropdownMultiChoise = ({
    className,
    iconCheckBox,
    // listChecked,
    // labelCheck,
    value,
    list,
    handleCheckItem
}) => {

    const dropdownRef = useRef()
    const wrapDropdownRef = useRef()

    const [dataLocal, setDataLocal] = useState([]);
    const [isShowDropdown, setIsShowDropdown] = useState(false);

    useEffect(() => {
        setDataLocal(list)
    }, [list])

    useOnClickOutside(dropdownRef, () => {
        setIsShowDropdown(false)
    })   

    return (
        <WrapDropdownMultiChoice ref={dropdownRef} className={className}>
            <div className="combo-box" onClick={() => setIsShowDropdown(pre => !pre)}>
                <div className="value" title={value}>{value}</div>
                <IcArrowDownGray className="icon" />
            </div>
            {
                isShowDropdown &&
                <div className="wrap-dropdown" ref={wrapDropdownRef}>
                    <div className="wrap-selection">
                        {
                            dataLocal.length !== 0 &&
                            dataLocal.map((item, index) => {
                                const { title, isSelect} = item
                                // const listKeyChecked = listChecked?.map((item, index) => {
                                //     return item.type
                                // })
                                // const isChecked = listKeyChecked?.includes(type)

                                return (
                                    <CheckBoxControl
                                        key={index}
                                        iconCheckBox={iconCheckBox}
                                        className="checkbox"
                                        isChecked={isSelect}
                                        handleCheck={() => handleCheckItem(index)}
                                        title={title}>
                                        {title}
                                    </CheckBoxControl>
                                )
                            })
                        }
                    </div>
                </div>
            }

        </WrapDropdownMultiChoice>
    )
}

export default DropdownMultiChoise
