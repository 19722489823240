import React from 'react'

const IcReasonBusiness = (props) => {
  return (
    <svg {...props} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="34" height="34" rx="17" fill="#FFEEEE"/>
        <path d="M15.7175 10.895L9.36495 21.5C9.23398 21.7268 9.16468 21.9839 9.16395 22.2459C9.16321 22.5078 9.23107 22.7653 9.36077 22.9929C9.49048 23.2204 9.6775 23.41 9.90324 23.5429C10.129 23.6757 10.3856 23.7471 10.6475 23.75H23.3525C23.6144 23.7471 23.8709 23.6757 24.0967 23.5429C24.3224 23.41 24.5094 23.2204 24.6391 22.9929C24.7688 22.7653 24.8367 22.5078 24.836 22.2459C24.8352 21.9839 24.7659 21.7268 24.635 21.5L18.2825 10.895C18.1488 10.6746 17.9605 10.4923 17.7359 10.3658C17.5112 10.2394 17.2578 10.1729 17 10.1729C16.7422 10.1729 16.4887 10.2394 16.2641 10.3658C16.0394 10.4923 15.8512 10.6746 15.7175 10.895V10.895Z" stroke="#F45C5C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 14.75V17.75" stroke="#F45C5C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 20.75H17.0075" stroke="#F45C5C" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcReasonBusiness