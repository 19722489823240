import React from 'react'

const IcLinkBankGray = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#B7B7B7"/>
        <path d="M17.9993 6.66797H5.99935C5.26297 6.66797 4.66602 7.26492 4.66602 8.0013V16.0013C4.66602 16.7377 5.26297 17.3346 5.99935 17.3346H17.9993C18.7357 17.3346 19.3327 16.7377 19.3327 16.0013V8.0013C19.3327 7.26492 18.7357 6.66797 17.9993 6.66797Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.66602 10.668H19.3327" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.66602 10.668H19.3327" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcLinkBankGray
