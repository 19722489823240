import Axios from 'axios'

// helper
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

// host
import { hostCustomer } from '../../../constant/host'

import * as types from './types'
import { hideLoading, showLoading } from '../../loading/action';

// FACEPAY
const apiGetListCustomerFacepayBacklog = `${hostCustomer}/customer/getbacklogs`
// BANK
const apiGetListCustomerBankBacklog = `${hostCustomer}/customer/bank/getbacklogs`

let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let isCalling = false


// FACEPAY ACTION
export const getListCustomerFacepayBacklog = (
    data = {
        adminId: "",
        adminUserName: "",
        uid: "",
        type: "",
        skip: "",
        limit: "",
        begin: "",
        end: ""
    },
    isScroll = false,
    callbackSuccess,
    callbackError
) => async dispatch => {

    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    if(isCalling) return
    isCalling = true

    !isScroll && dispatch(showLoading(types.LOADING_GET_LIST_BACKLOG_CUSTOMER))

    try {
        const res = await Axios.post(apiGetListCustomerFacepayBacklog, dataRequest)
        const { data } = res
        
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_CUSTOMER_BACKLOG,
                payload: {
                    listBacklog: data.data.list || [],
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
            isCalling = false
        }else{
            dispatch({
                type: types.GET_LIST_CUSTOMER_BACKLOG_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_CUSTOMER_BACKLOG_ERR))
    }

    isCalling = false
    !isScroll && setTimeout(() => {
        dispatch(hideLoading(types.LOADING_GET_LIST_BACKLOG_CUSTOMER))
    }, 300);
}

export const resetListCustomerFacepayBacklog = (callbackSuccess) => async dispatch => {
    dispatch({type: types.RESET_LIST_CUSTOMER_BACKLOG})
    callbackSuccess && callbackSuccess()
}


// BANK ACTION
export const getListCustomerBankBacklog = (
    data = {
        adminId: "",
        adminUserName: "",
        bioId: "",
        type: "",
        skip: "",
        limit: "",
        begin: "",
        end: ""
    },
    isScroll = false,
    callbackSuccess,
    callbackError
) => async dispatch => {

    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    if(isCalling) return
    isCalling = true

    !isScroll && dispatch(showLoading(types.LOADING_GET_LIST_BACKLOG_CUSTOMER))

    try {
        const res = await Axios.post(apiGetListCustomerBankBacklog, dataRequest)
        const { data } = res
        
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_CUSTOMER_BANK_BACKLOG,
                payload: {
                    listBacklog: data.data.list || [],
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
            isCalling = false
        }else{
            dispatch({
                type: types.GET_LIST_CUSTOMER_BANK_BACKLOG_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_CUSTOMER_BANK_BACKLOG_ERR))
    }

    isCalling = false
    !isScroll && setTimeout(() => {
        dispatch(hideLoading(types.LOADING_GET_LIST_BACKLOG_CUSTOMER))
    }, 300);
}

export const resetListCustomerBankBacklog = (callbackSuccess) => async dispatch => {
    dispatch({type: types.RESET_LIST_CUSTOMER_BANK_BACKLOG})
    callbackSuccess && callbackSuccess()
}

export const updateListBacklogCustomerCondsFilter = (
    {condFilters = {
        adminId: "",
        adminUserName: "",
        uid: "",
        bioId: "",
        type: "",
        skip: "",
        limit: "",
        begin: "",
        end: ""
    },
    isScroll,
    callbackSuccess,
    reqType = 'FACEPAY'
}
) => async dispatch => {
    
    dispatch({
        type: types.UPDATE_LIST_CUSTOMER_BACKLOG_CONDFILTERS,
        condFilters,
        reqType,
        isScroll
    })
    callbackSuccess && callbackSuccess()
}

export const resetUpdateListBacklogCustomerCondsFilter = () => async dispatch => {
    dispatch({type: types.RESET_UPDATE_LIST_CUSTOMER_BACKLOG_CONDFILTERS})
}