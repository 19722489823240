import styled from 'styled-components'

const WrapCensorshipList = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .bread-row{
        background: #FFFFFF;
        padding: 0 30px;
        min-height: 36px;
    }
`
export { WrapCensorshipList }