import * as types from './types'

const initialState = {
    details: {},
    isCheckTypeSameFace: false
    // listSameFace: []
}

const detailCensorshipReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.GET_DETAIL_CENSORSHIP:
            return {
                ...state,
                ...action.payload,
                isCheckTypeSameFace: false
            }
        case types.RESET_DETAIL_CENSORSHIP:
            return {
                ...state,
                details: {}
            }
        case types.GET_LIST_SAME_FACE:
            return {
                ...state,
                ...action.payload
            }

        case types.RESET_LIST_SAME_FACE:
            return {
                ...state,
                listSameFace: []
            }
        case types.APPROVED_USER_WARNING: 
            return {
                ...state,
                isCheckTypeSameFace: true
            }
        default:
            return state;
    }
}

export default detailCensorshipReducer;