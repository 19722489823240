import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: overlay;
    position: relative;
    .wrap-emty{
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
        p{
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #B7B7B7;
        }
    }
    .wrap-list-censorship{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        .wrap-container{
            margin-bottom: 20px;
    
            .wrap-info{
                display: grid;
                grid-template-columns: 100px 130px 1fr 130px;
                align-items: center;
                border-radius: 4px;
                background: rgba(247,247,247,0.5);
                padding: 8px;
                .type-active{
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    background: #EFF9FF;
                    border-radius: 20px;
                    padding: 3px;
                }
                .info{
                    margin-left: 4px;
                }
                .type-reject{
                    width: 80px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    background: #FFEEEE;
                    border-radius: 20px;
                }
                .type-icon{
                    display: flex;
                    padding-top: 2px;
                }
                .type-text{
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
                .value{
                    font-weight: 600;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
                .info-email{
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    .email{
                        font-weight: 400;
                        font-size: 13.5px;
                        line-height: 18px;
                        letter-spacing: -0.01em;
                        color: #313131;
                    }
                }
                .value-time{
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
            }
            .list-reason{
                display: flex;
                flex-direction: column;
                .reason{
                    display: flex;
                    padding: 8px;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    .icon{
                        padding-top: 2px;
                    }
                    .text{
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13.5px;
                        line-height: 18px;
                        color: #F45C5C;
                        padding-left: 4px;
                    }
            }
            }
        } 
    }
`

export {
    Bound
}