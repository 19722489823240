// data lỗi PVC

export const dataErrorPVC = {
    ["06"]:{text: "Tài khoản Facepay của khách hàng đang tạm khóa"},
    ["10"]:{text: "Khuôn mặt chưa được đăng ký Facepay "},
    ["21"]:{text: "Không tìm được khuôn mặt trùng khớp trong hệ thống"},
    ["40"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["43"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["46"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["53"]:{text: "Giao dịch bị lỗi kết nối hệ thống Facepay"},
    ["4999"]:{text: "Giao dịch bị Timeout hoăc không xác định do lỗi hệ thống Payme"},
    ["5601"]:{text: "Tài khoản tạm thời bị khóa do user yêu cầu"},
    ["5600"]:{text: "Tài khoản tạm thời bị khóa do nghi ngờ các hành vi phát sinh trong hệ thống"},
    ["5008"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["5010"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["5011"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["6104"]:{text: "Tài khoản chưa được CS kiểm duyệt"},
    ["6110"]:{text: "Tài khoản chưa được định danh"},
    ["6111"]:{text: "Tài khoản Facepay chưa liên kết thẻ"},
    ["6118"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["6116"]:{text: "Số tiền giao dịch chưa đạt mức tối thiểu 10,000đ"},
    ["6133"]:{text: "Tài khoản Facepay đang ngừng hoạt động"},
    ["315001"]:{text: "Giao dịch không thành công do trùng Transaction ID"},
    ["315002"]:{text: "Tài khoản Facepay chưa liên kết thẻ"},
    ["315003"]:{text: "Số dư không khả dụng"},
    ["315004"]:{text: "Tài khoản đang tạm khóa"},
    ["315005"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["315006"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["315007"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["315008"]:{text: "Giao dịch không thành công do lỗi hệ thống"},
    ["317001"]:{text: "Khách hàng thanh toán vượt quá hạn mức 30 giao dịch/ngày "},
    ["317002"]:{text: "Không thể nạp tiền từ thẻ PVcom để giao dịch do vượt quá hạn mức/giao dịch"},

    ["-99"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-100"]: {text: "Giao dịch không thành công do chưa hoàn tất."},
    ["-101"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-102"]: {text: "Khách hàng chưa nhập đúng số điện thoại"},
    ["-103"]: {text: "Khách hàng cancel giao dịch do không được áp dụng khuyến mãi"},

}