export const EXPORT_FILE_BUSINESS = 'EXPORT_FILE_BUSINESS'

export const UPDATE_CONDFILTER_BUSINESS = 'UPDATE_CONDFILTER_BUSINESS'
export const RESET_CONDFILTER_BUSINESS = 'RESET_CONDFILTER_BUSINESS'
export const RESET_LIST_BACKLOG_BUSINESS = 'RESET_LIST_BACKLOG_BUSINESS'

export const GET_LIST_BUSINESS = 'GET_LIST_BUSINESS'
export const GET_LIST_BUSINESS_ERR = 'GET_LIST_BUSINESS_ERR'
export const RESET_LIST_BUSINESS = 'RESET_LIST_BUSINESS'

export const GET_LIST_BACKLOG_BUSINESS = 'GET_LIST_BACKLOG_BUSINESS'
export const GET_LIST_BACKLOG_BUSINESS_ERR = 'GET_LIST_BACKLOG_BUSINESS_ERR'

export const GET_DETAIL_BUSINESS = 'GET_DETAIL_BUSINESS'
export const GET_DETAIL_BUSINESS_ERR = 'GET_DETAIL_BUSINESS_ERR'
export const RESET_DETAIL_BUSINESS = 'RESET_DETAIL_BUSINESS'

export const BUSINESS_REJECT = 'BUSINESS_REJECT'
export const BUSINESS_REJECT_ERR = 'BUSINESS_REJECT_ERR'

export const BUSINESS_APPROVED = 'BUSINESS_APPROVED'
export const BUSINESS_APPROVED_ERR = 'BUSINESS_APPROVED_ERR'

export const SHOW_POPUP_UPDATE_REQUEST = 'SHOW_POPUP_UPDATE_REQUEST'
export const BUSINESS_REQUEST_UPDATE = 'BUSINESS_REQUEST_UPDATE'
export const BUSINESS_REQUEST_UPDATE_ERR = 'BUSINESS_REQUEST_UPDATE_ERR'

export const EXPORT_LIST_BUSINESS_ERR = 'EXPORT_LIST_BUSINESS_ERR'

export const BUSINESS_ADD_NOTE = 'BUSINESS_ADD_NOTE'
export const BUSINESS_ADD_NOTE_ERR = 'BUSINESS_ADD_NOTE_ERR'

export const GET_LIST_NOTE_BUSINESS = 'GET_LIST_NOTE_BUSINESS'
export const GET_LIST_NOTE_BUSINESS_ERR = 'GET_LIST_NOTE_BUSINESS_ERR'
export const RESET_LIST_NOTE_BUSINESS = 'RESET_LIST_NOTE_BUSINESS'

