import React from 'react'

const IcRejectOther = (props) => {
    return (
        <svg {...props} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="#FFEEEE"/>
        <path d="M24.75 20.25C24.75 20.6478 24.592 21.0294 24.3107 21.3107C24.0294 21.592 23.6478 21.75 23.25 21.75H14.25L11.25 24.75V12.75C11.25 12.3522 11.408 11.9706 11.6893 11.6893C11.9706 11.408 12.3522 11.25 12.75 11.25H23.25C23.6478 11.25 24.0294 11.408 24.3107 11.6893C24.592 11.9706 24.75 12.3522 24.75 12.75V20.25Z" stroke="#F45C5C" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcRejectOther
