import React from 'react'

const IcCardDefault = (props) => {
  return (
    <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_14630_98512)">
        <circle cx="16" cy="16" r="16" fill="#EFF9FF"/>
        <path d="M22.8001 9.95557H9.20008C8.36551 9.95557 7.68896 10.6321 7.68896 11.4667V20.5333C7.68896 21.3679 8.36551 22.0445 9.20008 22.0445H22.8001C23.6346 22.0445 24.3112 21.3679 24.3112 20.5333V11.4667C24.3112 10.6321 23.6346 9.95557 22.8001 9.95557Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.68896 14.4888H24.3112" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.68896 14.4888H24.3112" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_14630_98512">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
    </svg>

  )
}

export default IcCardDefault