import React from 'react'

const IcLinkedID = (props) => {
  return (
    <svg {...props} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_14645_106040)">
        <circle cx="16" cy="16.5" r="16" fill="#EFF9FF"/>
        <path d="M14.4889 17.2555C14.8134 17.6893 15.2273 18.0483 15.7027 18.308C16.1781 18.5677 16.7038 18.7222 17.2441 18.7609C17.7845 18.7996 18.3268 18.7216 18.8344 18.5323C19.3419 18.3429 19.8028 18.0467 20.1858 17.6635L22.4524 15.3969C23.1406 14.6844 23.5214 13.7301 23.5128 12.7396C23.5042 11.7491 23.1069 10.8015 22.4064 10.1011C21.706 9.40068 20.7585 9.00338 19.768 8.99477C18.7774 8.98616 17.8232 9.36694 17.1107 10.0551L15.8111 11.3471" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.5111 15.744C17.1866 15.3102 16.7726 14.9513 16.2972 14.6915C15.8219 14.4318 15.2962 14.2774 14.7558 14.2387C14.2155 14.2 13.6732 14.2779 13.1656 14.4673C12.6581 14.6566 12.1972 14.9529 11.8142 15.336L9.54753 17.6026C8.85937 18.3151 8.4786 19.2694 8.4872 20.2599C8.49581 21.2505 8.89312 22.198 9.59355 22.8984C10.294 23.5988 11.2415 23.9961 12.232 24.0047C13.2225 24.0134 14.1768 23.6326 14.8893 22.9444L16.1813 21.6524" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_14645_106040">
        <rect width="32" height="32" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
    </svg>

  )
}

export default IcLinkedID