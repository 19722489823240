import { CONNECTED, DISCONNECTED, TERMINATED, ALL, UNLINKED } from '../constant/keys'

const dataDeviceStatus = {
    [ALL]: {
        type: ALL,
        text: 'All',
        color: '#33ADFF',
        vnText: 'Tất cả',
    },
    [CONNECTED]: {
        type: CONNECTED,
        text: 'Connected',
        color: '#33ADFF',
        vnText: 'Đang hoạt động',
    },
    [DISCONNECTED]: {
        type: DISCONNECTED,
        text: 'Disconnected',
        color: '#F45C5C',
        vnText: 'Mất kết nối',
    },
    [TERMINATED]: {
        type: TERMINATED,
        text: 'Warning',
        color: '#FFBA33',
        vnText: 'Đã thoát app',
    },
    [UNLINKED]: {
        type: UNLINKED,
        text: 'Unlinked',
        color: '#B7B7B7',
        vnText: 'Chưa link với Merchant',
    }
}

const deviceStatus = {
    [CONNECTED]: {
        type: CONNECTED,
        text: 'Connected',
        color: '#33ADFF',
        vnText: 'Đang hoạt động',
    },
    [DISCONNECTED]: {
        type: DISCONNECTED,
        text: 'Disconnected',
        color: '#F45C5C',
        vnText: 'Mất kết nối',
    },
    [TERMINATED]: {
        type: TERMINATED,
        text: 'Warning',
        color: '#FFBA33',
        vnText: 'Đã thoát app',
    },
    [UNLINKED]: {
        type: UNLINKED,
        text: 'Unlinked',
        color: '#B7B7B7',
        vnText: 'Chưa link với Merchant',
    }
}

export {
    deviceStatus
}

export default dataDeviceStatus