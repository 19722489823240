import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import markerIcon from 'leaflet/dist/images/marker-icon.png'

// styles
import { WrapDeviceMapByAdmin } from './index.styles'

// action
import { getDetailsDeviceByAdmin, resetDetailsDeviceByAdmin } from '../../../../../redux/device/detailsByAdmin/action'
import { setTotalDisconnected } from '../../../../../redux/device/listDevicesByAdmin/action'

// hook
import useCallOnlyOnce from '../../../../../hook/useCallOnlyOnce'

// constant
import { apiMerchantLogo } from '../../../../../constant/host'

const MOCK_LAT = 10.8390563
const MOCK_LNG = 106.6758143
const ZOOM = 17

const DeviceMapByAdmin = () => {
    const dispatch = useDispatch()
    const wrapMap = useRef(null)
    const { deviceId } = useParams()

    const details = useSelector(state => state.deviceReducer.detailsByAdmin)
    
    useCallOnlyOnce(() => {
        if(!Object.values(details).length) return false;
        const {
            merchantId,
            name,
            id,
            address,
            lat,
            lng
        } = details

        // map configs
        const deviceMap = L.map(wrapMap.current, {
            zoomControl: false,
            doubleClickZoom: false,
            dragging: false,
            scrollWheelZoom: false,
            tap: false,
            keyboard: false
        }).setView([lat, lng], ZOOM);
        
        // map image
        // const api = "https://maps.vietmap.vn/api/tm/{z}/{x}/{y}.png?apikey=309bccbd2ace9c6f4c87515fe32948e7e5963226c8c4c546"
        // L.tileLayer(api, {
        //     id: 'device-map',
        //     tileSize: 256,
        // }).addTo(deviceMap)

        // tooltip
        const markIcon = L.icon({
            // iconUrl: markerIcon,
            iconUrl: apiMerchantLogo(merchantId),
            // iconSize: [25, 41],
            iconSize: [48, 48],
            popupAnchor: [0, -24],
        });
        const marker = L.marker([lat, lng], {
            icon: markIcon
        }).addTo(deviceMap);
        marker.bindPopup(`
            <div class="row row-title">
                <img src="${apiMerchantLogo(merchantId)}" alt="merchant" />
                <div class="general-infos">
                    <div class="name" title="${name}">${name}</div>
                    <div class="code">${id?.substring(0,9).toUpperCase()}</div>
                </div>
            </div>
            <div class="row row-addr">
                <div class="label">Địa chỉ</div>
                <div class="value">${address}</div>
            </div>
            <div class="row row-location">
                <div class="label">Location</div>
                <div class="value">${lat},${lng}</div>
            </div>

        `, {
            closeButton: false,
            minWidth: 300,
            className: "tip-popup"
        }).openPopup()
        return true;
    }, [JSON.stringify(details)]);


    useEffect(() => {
        dispatch(getDetailsDeviceByAdmin({ deviceId }))
        return () => {
            dispatch(resetDetailsDeviceByAdmin())
            // dispatch(setTotalDisconnected(0))
        }
    }, []);

    return (
        <WrapDeviceMapByAdmin>
            <div className="wrap-map" ref={wrapMap} />
        </WrapDeviceMapByAdmin>
    )
}

export default DeviceMapByAdmin
