import React from 'react'
import IcInputLock from '../../../../../assets/images/IcInputLock'
import IcInputLockActive from '../../../../../assets/images/IcInputLockActive'
import IcInputLockError from '../../../../../assets/images/IcInputLockError'
import Input from '../../../../Control/Input'

const iconPassword = {
    icon: IcInputLock,
    iconActive: IcInputLockActive,
    iconError: IcInputLockError,
}

const InputOldPassword = ({ valueInput, onChange, placeholder, ...props }) => {
    return (
        <Input
            type="text"
            placeholder={placeholder}
            type="password"
            inputType="TEXT"
            value={valueInput}
            onChange={onChange}
            iconInput={iconPassword}
            {...props}
        />
    )
}

export default InputOldPassword
