// FACEPAY
export const GET_LIST_CUSTOMER_BACKLOG = 'GET_LIST_CUSTOMER_BACKLOG'
export const GET_LIST_CUSTOMER_BACKLOG_ERR = 'GET_LIST_CUSTOMER_BACKLOG_ERR'
export const RESET_LIST_CUSTOMER_BACKLOG = 'RESET_LIST_CUSTOMER_BACKLOG'

// BANK
export const GET_LIST_CUSTOMER_BANK_BACKLOG = 'GET_LIST_CUSTOMER_BANK_BACKLOG'
export const GET_LIST_CUSTOMER_BANK_BACKLOG_ERR = 'GET_LIST_CUSTOMER_BANK_BACKLOG_ERR'
export const RESET_LIST_CUSTOMER_BANK_BACKLOG = 'RESET_LIST_CUSTOMER_BANK_BACKLOG'


// CONDFILTERS 
export const UPDATE_LIST_CUSTOMER_BACKLOG_CONDFILTERS = 'UPDATE_LIST_CUSTOMER_BACKLOG_CONDFILTERS'
export const RESET_UPDATE_LIST_CUSTOMER_BACKLOG_CONDFILTERS = 'RESET_UPDATE_LIST_CUSTOMER_BACKLOG_CONDFILTERS'

// LOADING
export const LOADING_GET_LIST_BACKLOG_CUSTOMER = 'LOADING_GET_LIST_BACKLOG_CUSTOMER'