import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
 
// style
import { FilterControlTime, WrapFilterTransaction, WrapFilterTransactionCheckbox, WrapFilterTransactionControl, WrapFilterTransactionSearch } from '../../Bank/FilterTransactionBank/index.styles';

// images
import IcUnCheckBox from '../../../../../../../assets/images/IcUnCheckBox';
import IcCheckBoxActive from '../../../../../../../assets/images/IcCheckBoxActive';
import IcCheckBoxDisabled from '../../../../../../../assets/images/IcCheckBoxDisabled';
import IcRefresh from '../../../../../../../assets/images/IcRefresh';
import IcCalendarActive from '../../../../../../../assets/images/IcCalendarActive';

// component
import DropdownFilterBank from '../../Bank/FilterTransactionBank/DropdownFilterBank';
import DropdownFilterTime from '../../Bank/FilterTransactionBank/DropdownFilterTime';
import FilterControl from '../../../../../../Control/FilterControl';
import Button from '../../../../../../Control/Button';
import CheckBoxControl from '../../../../../../Control/CheckBoxControl';
import InputDropdownBank from '../../../CustomerV2/CustomerList/BankCustomerList/FilterBankBlock/InputDropDownBank';
import DatePickerTransaction from '../../Bank/FilterTransactionBank/DatePickerTransaction';
import InputSearchTransaction from '../../Bank/FilterTransactionBank/InputSearchTransaction';

// keys
import { WALLET_FACEPAY } from '../../index'
import { FACEPAY_TX_ID, PAYME_TX_ID, PHONE_NUMBER, PROFILE_ID } from '../../../../../../../constant/keys';

// redux
import { currentDay, startDay } from '../../../../../../../redux/transactionV2/listTransactionBank/listTransactionBankReducer';

// dataa
import { dataSelectCheckBoxTypeTransaction, dataSelectCheckboxWallet } from '../../../../../../../data/dataSelectCheckbox';
import { updateCondfiterTransactionFacepayWallet, resetCondFilterTransactionFacepayWallet } from '../../../../../../../redux/transactionV2/listTransactionWalletFacepay/action';

// helper

const iconCheckBox = {
    icon: IcUnCheckBox,
    iconActive: IcCheckBoxActive,
    iconDisabled: IcCheckBoxDisabled
};

const FilterTransactionWallet = () => {

    const dispatch = useDispatch()

    const { condFiltersTransactionFacepayWallet } = useSelector((state) => ({
        condFiltersTransactionFacepayWallet: state.transactionReducer.listTransactionFacepayWalletReducer.condFiltersTransactionFacepayWallet,
    }))

    const [valueSearch, setValueSearch] = useState('')

    const [filterCondTransaction, setFilterCondTransaction] = useState(condFiltersTransactionFacepayWallet.OptionSelect)
    const [filterCondTransactionTime, setFilterCondTransactionTime] = useState(condFiltersTransactionFacepayWallet.OptionSelectTime)

    
    const [data, setData] = useReducer(
        (state, action) => ({...state, ...action}), 
        {
            limit: condFiltersTransactionFacepayWallet.limit,
            lastItem: condFiltersTransactionFacepayWallet.lastItem,
            paymeTxID: condFiltersTransactionFacepayWallet.paymeTxID,
            facepayTrxCode: condFiltersTransactionFacepayWallet.facepayTrxCode,
            phoneNumber: condFiltersTransactionFacepayWallet.phoneNumber,
            profileId: condFiltersTransactionFacepayWallet.profileId,
            begin: condFiltersTransactionFacepayWallet.begin,
            end: condFiltersTransactionFacepayWallet.end,
            OptionSelectCheckbox: condFiltersTransactionFacepayWallet.OptionSelectCheckbox,
            OptionSelectCheckboxTypeTransaction: condFiltersTransactionFacepayWallet.OptionSelectCheckboxTypeTransaction,
        }
    )

    const _handleChangeOptionFilter = (filterOption) => {
        setFilterCondTransaction(filterOption)
        setValueSearch("")
    }

    const _handleChangeOptionFilterTime = (filterOption) => {
        setFilterCondTransactionTime(filterOption)
    }

    const _handleSearch = () => {
        const { key } = filterCondTransaction
        let paymeTxID = ""
        let facepayTrxCode = ""
        let profileId = ""
        let phoneNumber = ""
        switch (key) {
          case PAYME_TX_ID: {
            paymeTxID = valueSearch
            break;
          }
          case FACEPAY_TX_ID: {
            facepayTrxCode = valueSearch
            break;
          }
          case PROFILE_ID: {
            profileId = valueSearch
            break;
          }
          case PHONE_NUMBER: {
            phoneNumber = valueSearch
            break;
          }
          default:
            return;
        }
        dispatch(updateCondfiterTransactionFacepayWallet({
            ...condFiltersTransactionFacepayWallet,
            paymeTxID,
            facepayTrxCode,
            profileId,
            phoneNumber,
        }))
    }

    const dropdownFilterBank = useMemo(() => (
        <DropdownFilterBank
            reqType={WALLET_FACEPAY}
            filterCond={filterCondTransaction}
            setFilterCond={_handleChangeOptionFilter}
        />
    ),[filterCondTransaction])

    const dropdownFilterTime = useMemo(() => (
        <DropdownFilterTime 
            filterCond={filterCondTransactionTime}
            setFilterCond={_handleChangeOptionFilterTime}
        />
    ),[filterCondTransactionTime])

    const filterComponent = useMemo(() => (
        <InputSearchTransaction 
            filterCondTransaction={filterCondTransaction}
            valueSearch={valueSearch}
            setValueSearch={setValueSearch}
        />
    ),[filterCondTransaction, _handleSearch])

    const filterComponentTime = useMemo(() => (
        <DatePickerTransaction
            filterCondTransactionTime={filterCondTransactionTime}
            setFilterCondTransactionTime={setFilterCondTransactionTime}
            data={data}
            setData={setData}
            condFiltersTransaction={condFiltersTransactionFacepayWallet}
            reqType={WALLET_FACEPAY}
        />
    ),[filterCondTransactionTime, data.begin, data.end])
    
    const handleSelectCheckboxTypeTransaction = (checkboxSelected) => {
        if(!condFiltersTransactionFacepayWallet.OptionSelectCheckboxTypeTransaction) return
        let checkboxCollection = condFiltersTransactionFacepayWallet.OptionSelectCheckboxTypeTransaction.map((item, index) => {
            if(index !== checkboxSelected){
                return {
                    ...item
                }
            }else {
                return {
                    ...item,
                    isSelect: !item.isSelect
                }
            }
        })
        dispatch(updateCondfiterTransactionFacepayWallet({
            ...condFiltersTransactionFacepayWallet,
            OptionSelectCheckboxTypeTransaction: checkboxCollection
        }))
    }

    const handleSelectCheckbox = (checkboxSelected) => {
        if(!condFiltersTransactionFacepayWallet.OptionSelectCheckbox) return
        let checkboxCollection = condFiltersTransactionFacepayWallet.OptionSelectCheckbox.map((item) => {
            if(item.id !== checkboxSelected){
                return {
                    ...item
                }
            }else {
                return {
                    ...item,
                    isSelect: !item.isSelect
                }
            }
        })
        dispatch(updateCondfiterTransactionFacepayWallet({
            ...condFiltersTransactionFacepayWallet,
            OptionSelectCheckbox: checkboxCollection
        }))
    }

    const handleRefresh = () => {
        setFilterCondTransactionTime(condFiltersTransactionFacepayWallet.OptionSelectTime)
        setData({
            begin: startDay,
            end: currentDay,
        })
        dispatch(resetCondFilterTransactionFacepayWallet())
        setValueSearch("")
    }
    
    return (
        <WrapFilterTransaction>
            <WrapFilterTransactionSearch>
                <WrapFilterTransactionControl>
                    <FilterControl
                        className='input-transaction'
                        dropdownFilter={dropdownFilterBank}
                        filterComponent={filterComponent}
                        handleSearch={_handleSearch}
                        value={valueSearch}
                        isShowSubmitBtn={true}
                    />
                    <FilterControlTime>
                        <IcCalendarActive />
                        {dropdownFilterTime}
                        {filterComponentTime}
                    </FilterControlTime>
                </WrapFilterTransactionControl>
                <WrapFilterTransactionCheckbox>
                    <div className="filter-checkbok checkbok-wallet">
                        {
                            condFiltersTransactionFacepayWallet.OptionSelectCheckbox.map((item, index) => (
                                <CheckBoxControl 
                                    key={index}
                                    title={item.name}
                                    iconCheckBox={iconCheckBox}
                                    className="info-check"
                                    isChecked={ item.isSelect}
                                    handleCheck={() => handleSelectCheckbox(item.id)}
                                />
                            ))
                        }
                    </div>
                    <div className="filter-checkbok">
                        <div className="filter-checkbok-text">Loại giao dịch: </div>
                        <div className="filter-checkbok-bank">
                            <InputDropdownBank
                                reqType={WALLET_FACEPAY}
                                iconCheckBox={iconCheckBox}
                                listDropdown={condFiltersTransactionFacepayWallet.OptionSelectCheckboxTypeTransaction}
                                listChecked={condFiltersTransactionFacepayWallet.OptionSelectCheckboxTypeTransaction.filter((item) => item.isSelect).map((item) => item.title)}
                                handleCheckItem={handleSelectCheckboxTypeTransaction}
                            />
                        </div>
                    </div>
                </WrapFilterTransactionCheckbox>
                <Button
                    iconBtn={<IcRefresh />}
                    text="Reset"
                    className="btn-reset"
                    onClick={handleRefresh}
                /> 
            </WrapFilterTransactionSearch>
        </WrapFilterTransaction>
    )
}

export default FilterTransactionWallet
