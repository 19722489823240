import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// helper
import { convertDateTime } from '../../../../../../../../helper/convertDateTime'

// styles
import * as styles from './index.styles'

// data
import { CCCD, CMND12ID, CMND9ID, dataPassportStatus, PASSPORT } from '../../../../../../../../data/dataCustomer'
// import { dataKYC } from '../../../../../../../../mockData/dataKYC'

// image
import IcLockBgGray from '../../../../../../../../assets/images/IcLockBgGray'
import IcDataNew from '../../../../../../../../assets/images/IcDataNew'
import IcNoDataEkyc from '../../../../../../../../assets/images/IcNoDataEkyc'

// component
import InfoKycCMND from './InfoKycCMND'
import InfoKycCCCD from './InfoKycCCCD'
import InfoKycPASSPORT from './InfoKycPASSPORT'
import FaceZoomInfoKYC from './FaceZoomInfoKYC'
import EmptyDataControl from '../../../../../../../Control/EmptyDataControl'
import LoadingLocal from '../../../../../../../Control/LoadingLocal'

// redux
import { getListEkycHistoryByCustomer, resetListDeviceHistoryCustomer, resetListEkycHistoryCustomer, resetListVerifyHistoryCustomer } from '../../../../../../../../redux/customer/customerDetail/action'
import { LOADING_GET_LIST_EKYC_CUSTOMER } from '../../../../../../../../redux/customer/customerDetail/types'
import { FACEPAY } from '../../../../../../../../constant/keys'
import IcAlertCircle from '../../../../../../../../assets/images/IcAlertCircle'

export default function EKYC() {

  const dispatch = useDispatch()

  const { details, listEkycHistory, isTypeLoading, reqType } = useSelector((state) => ({
    details: state.customerReducer.customerDetailReducer.details,
    listEkycHistory: state.customerReducer.customerDetailReducer.listEkycHistory,

    reqType: state.customerReducer.listCustomerReducer.reqType,

    isTypeLoading: state.loading[LOADING_GET_LIST_EKYC_CUSTOMER],
  }))

  useEffect(() => {
    dispatch(resetListVerifyHistoryCustomer())
    dispatch(resetListDeviceHistoryCustomer())
  },[])

  useEffect(() => {
    if(reqType !== FACEPAY) return

    if(details?.id){
      dispatch(getListEkycHistoryByCustomer({uid: details.id}))
    }else {
      dispatch(resetListEkycHistoryCustomer())
    }
  },[details])

  const handleShowInfoKyc = (item) => {
    switch (dataPassportStatus[item.ekycType].key){
      case CMND9ID:
        return (
          <InfoKycCMND item={item}/>
        )
      case CMND12ID:
        return (
          <InfoKycCCCD item={item}/>
        )
      case CCCD:
        return (
          <InfoKycCCCD item={item} />
        )
      case PASSPORT:
        return (
          <InfoKycPASSPORT item={item} />
        )
    }
  }

  return (  
    <styles.Bound>
      { isTypeLoading ? (
          <LoadingLocal />
        ) : (
          listEkycHistory?.length === 0 && !isTypeLoading ? (
            <div className="wrap-emty">
              <EmptyDataControl 
                icon={<IcNoDataEkyc />}
                text='Tài khoản chưa cập nhật dữ liệu'
              />
          </div>
          ) : (
            <div className="wrap-list-kyc">
              {
                details?.kycStatus?.state === "REJECTED" &&
                <div className='reject-reason'>
                  <IcAlertCircle className='icon-alert'/> 
                  {
                    details?.kycStatus?.reason ?
                      details?.kycStatus?.reason
                      :
                      "Dữ liệu yêu cầu cập nhật lại từ phía PayME"
                  }
                </div>
              }
              {listEkycHistory?.map((item, index) => (
                <div className="wrap-container" key={index}>
                  <div className="wrap-info">
                    {
                      index === 0 ? (
                        <div className="type-active">
                          <IcDataNew className="type-icon"/>
                          <div className="type-text">Dữ liệu mới</div>
                        </div>
                      ) : (
                        <div className="type-old">
                          <IcLockBgGray />
                          <div className="type-text">Dữ liệu cũ</div>
                        </div>
                      )
                    }
                    <div className="info">
                      <span className="value">
                        {dataPassportStatus[item.ekycType].text || "-"}
                      </span>
                    </div>
                    <div className="info">
                      <span className="value-time">
                        {item.createdAt ? convertDateTime(item.createdAt, "HH:mm - DD/MM/YYYY") : "-"}
                      </span>
                    </div>
                  </div>
                  <FaceZoomInfoKYC item={item} index={index} />
                  <div className="info-register">
                      <div className="info-title">
                        Thông tin giấy tờ đăng ký
                      </div>
                      {handleShowInfoKyc(item)}
                  </div>
                </div>
              ))}
            </div>
          )
        )
      }
      
    </styles.Bound>
  )
}
