import React from 'react'

const IcTransferToFacePOS = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14815_28471)">
    <path d="M3 11V15.5816C3 22 3 22 7.4975 22H14.6995C21 22 21 22 21 17.4184V11" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 12C13.83 12 15.18 10.51 15 8.68L14.34 2H9.66999L8.99999 8.68C8.81999 10.51 10.17 12 12 12Z" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.3098 12C20.3298 12 21.8098 10.36 21.6098 8.35L21.3298 5.6C20.9698 3 19.9698 2 17.3498 2H14.2998L14.9998 9.01C15.1698 10.66 16.6598 12 18.3098 12Z" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.63988 12C7.28988 12 8.77988 10.66 8.93988 9.01L9.15988 6.8L9.63988 2H6.58988C3.96988 2 2.96988 3 2.60988 5.6L2.33988 8.35C2.13988 10.36 3.61988 12 5.63988 12Z" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 17C10.33 17 9.5 17.83 9.5 19.5V22H14.5V19.5C14.5 17.83 13.67 17 12 17Z" stroke="#33ADFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <circle cx="21" cy="21" r="7.5" fill="#33ADFF" stroke="white"/>
    <path d="M24.1114 18.6665L19.8336 22.9443L17.8892 20.9998" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <defs>
    <clipPath id="clip0_14815_28471">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default IcTransferToFacePOS