import React, { useEffect, useRef, useState } from "react";

//style
import { WrapTransHistoryContent, WrapTransHistoryItem } from "./index.styles";

//help
import { convertDateTime } from "../../../../../../../../helper/convertDateTime";
import numberFormatToThousand from "../../../../../../../../helper/numberFormatToThousand";

//image
import IcCheckBgBlue from "../../../../../../../../assets/images/IcCheckBgBlue";
import IcCloseRedMini from "../../../../../../../../assets/images/IcCloseRedMini";
import IcNoteBlue from "../../../../../../../../assets/images/IcNoteBlue";
import TransHistoryDetailsItem from "./TransHistoryDetailsItem";
import PopupAttachNote from "../PopupAttachNote";
import Portal from "../../../../../../../Control/Portal";
import {
  DEPOSIT,
  DEPOSIT_LINKCARD,
  LINK_CARD,
  POPUP_NOTE_TRANSACTION_CUSTOMER,
  TRANSFER_FROM_FACEPOS,
  TRANSFER_TO_BANK,
  TRANSFER_TO_BANK_WALLET_FACEPOS,
  TRANSFER_TO_WALLET,
} from "../../../../../../../../constant/keys";
import { useDispatch, useSelector } from "react-redux";
import {
  hidePopupNote,
  showPopupNote,
} from "../../../../../../../../redux/customer/customerNote/action";
import { CUSTOMER, TRANSACTION } from "../../../../../../../../constant/path";
import {
  dataBankType,
  dataLogoBank,
} from "../../../../../../../../data/dataBankType";
import PopupDetailWalletDepositLinkCard from "../../../../TransactionV2/WalletFacepay/TransactionWalletItem/PopupDetailWallet/PopupDetailWalletDepositLinkCard";
import PopupDetailWalletTranfer from "../../../../TransactionV2/WalletFacepay/TransactionWalletItem/PopupDetailWallet/PopupDetailWalletTranfer";

export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";

const initialPos = {
  top: "0%",
  left: "100%",
  right: "initial",
  bottom: "initial",
};
const DETAILS = "DETAILS";
const NOTE = "NOTE";

const TransHistoryItem = ({ data, isShowPopup, setIsShowPopup, listRef }) => {
  const {
    facepayTransCode,
    status,
    storeName,
    storeAddress,
    createdAt,
    transAmount,
  } = data.info;

  const dispatch = useDispatch();
  const wrapPopupRef = useRef(null);
  const wrapItemRef = useRef(null);

  const refNote = useRef(null);
  const refPopup = useRef(null);

  const [isOutList, setIsOutList] = useState("");
  const [isShowPopupWalletFacepay, setIsShowPopupWalletFacepay] = useState("");

  const [isKeyFrom, setIsKeyFrom] = useState({});

  const [isEnter, setIsEnter] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [posPopup, setPosPopup] = useState({
    ...initialPos,
  });

  const { details, reqType, isShowPopupNote, reqTypeTransaction, listBank } =
    useSelector((state) => ({
      details: state.customerReducer.customerDetailReducer.details,
      reqType: state.customerReducer.listCustomerReducer.reqType,
      isShowPopupNote:
        state.customerReducer.customerNoteReducer.isShowPopupNote,
      listBank: state.transactionReducer.transactionOtherReducer.listBank,
      reqTypeTransaction:
        state.transactionReducer.transactionOtherReducer.reqType,
    }));

  const logoMerchent = dataLogoBank(listBank);

  const _handleOpenPopupNote = (e) => {
    e.stopPropagation();
    dispatch(showPopupNote());
    setIsShowPopup(
      data.info.facepayTransCode
        ? data.info.facepayTransCode
        : data.info.paymentInfo?.facepayTrxCode
    );
    setIsKeyFrom(NOTE);
  };

  const _handleOpenPopupDetail = () => {
    if (!data.isWallet) {
      setIsKeyFrom(DETAILS)
      setIsShowPopup(data.info.facepayTransCode);
      dispatch(hidePopupNote());
      return;
    }

    if (
      (data.isWallet &&
        data.info.transactionType.toUpperCase() === LINK_CARD) ||
      data.info.transactionType.toUpperCase() === DEPOSIT
    ) {
      setIsShowPopupWalletFacepay(DEPOSIT_LINKCARD);
      dispatch(hidePopupNote());
      setIsShowPopup(data.info.paymentInfo?.facepayTrxCode);
      return;
    }

    if (
      (data.isWallet &&
        data.info.transactionType.toUpperCase() === TRANSFER_TO_WALLET) ||
      data.info.transactionType.toUpperCase() === TRANSFER_TO_BANK ||
      data.info.transactionType.toUpperCase() === TRANSFER_FROM_FACEPOS
    ) {
      setIsShowPopupWalletFacepay(TRANSFER_TO_BANK_WALLET_FACEPOS);
      dispatch(hidePopupNote());
      setIsShowPopup(data.info.paymentInfo?.facepayTrxCode);
      return;
    }
  };

  useEffect(() => {
    if (listRef && isKeyFrom === NOTE) {
      const listRect = listRef.current.getBoundingClientRect(); // ref list
      const { height, top } = listRect;
      const noteRect = refNote.current.getBoundingClientRect();
      const PopupRect = refPopup.current.getBoundingClientRect(); // ref popup
      const hightY = height + top;
      const isOutY = PopupRect.top + PopupRect.height;
      if (isOutY > hightY) {
        let newTopPopup = noteRect.bottom - 184;
        if (newTopPopup < top) {
          let delta = top - newTopPopup;
          setIsOutList(`${delta}%`);
        } else {
          setIsOutList("0px");
        }
      }
    } else {
      setIsOutList("");
    }
  }, [listRef, isKeyFrom]);

  const handleMouseEnter = () => {
    setIsEnter(true);
  };

  const handleMouseLeave = () => {
    setIsEnter(false);
    setIsVisible(false);
    setPosPopup({
      ...initialPos,
    });
  };

  const _onClickClosePopup = () => {
    dispatch(hidePopupNote());
    setIsKeyFrom("");
    setIsShowPopup("");
  };

  useEffect(() => {
    _onClickClosePopup();
  }, [details?.id, reqType]);

  useEffect(() => {
    if (isShowPopupNote && isKeyFrom === NOTE) {
      listRef.current?.addEventListener("scroll", _onClickClosePopup);
    }
    return () => {
      listRef.current?.removeEventListener("scroll", _onClickClosePopup);
    };
  }, [isShowPopupNote]);

  return (
    <WrapTransHistoryContent ref={refNote} propsPopup={isOutList}>
      <WrapTransHistoryItem
        ref={wrapItemRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        posPopup={posPopup}
        isVisible={isVisible}
        onClick={_handleOpenPopupDetail}
      >
        <div className="wrap-content">
          <div className="content">
            {data.isWallet ? (
              data.info.paymentInfo?.status === 1 ? (
                <IcCheckBgBlue className="icon" />
              ) : (
                <IcCloseRedMini className="icon" />
              )
            ) : status === 1 ? (
              <IcCheckBgBlue className="icon" />
            ) : (
              <IcCloseRedMini className="icon" />
            )}
            <p className="value bold">
              {data.isWallet
                ? data.info.paymentInfo?.facepayTrxCode
                : facepayTransCode || "-"}
            </p>
          </div>
          <div className="value">
            {data.isWallet
              ? data.info.paymentInfo?.createdAt &&
                convertDateTime(
                  data.info.paymentInfo?.createdAt,
                  "HH:mm - DD/MM/YYYY"
                )
              : (createdAt &&
                  convertDateTime(createdAt, "HH:mm - DD/MM/YYYY")) ||
                "-"}
          </div>
        </div>
        <div className="wrap-content right">
          <div className="content">
            <p className="value bold">
              {data.isWallet
                ? numberFormatToThousand(data.info.paymentInfo?.transAmount)
                : numberFormatToThousand(transAmount) || 0}
            </p>
            <IcNoteBlue
              className="icon-note"
              onClick={(e) => {
                _handleOpenPopupNote(e);
              }}
            />
          </div>
          <p className="value">{storeName || "-"}</p>
        </div>
      </WrapTransHistoryItem>
      {data.info.facepayTransCode === isShowPopup ||
        (data.info.paymentInfo?.facepayTrxCode === isShowPopup &&
          isKeyFrom === NOTE &&
          isShowPopupNote && (
            <div className="wrap-content-popup" ref={refPopup}>
              <PopupAttachNote
                isStylePopup={false}
                isShowContentPopup={POPUP_NOTE_TRANSACTION_CUSTOMER}
                data={data.info}
                onClickClosePopup={_onClickClosePopup}
              />
            </div>
          ))}
      {data.info.facepayTransCode === isShowPopup && isKeyFrom === DETAILS && (
        <Portal>
          <TransHistoryDetailsItem
            data={data.info}
            onClickClosePopup={_onClickClosePopup}
            detailsTrans={CUSTOMER}
          />
        </Portal>
      )}
      {data.info.paymentInfo?.facepayTrxCode === isShowPopup &&
        isShowPopupWalletFacepay === DEPOSIT_LINKCARD && (
          <Portal>
            <PopupDetailWalletDepositLinkCard
              reqType={reqType}
              detailsTrans={TRANSACTION}
              dataTransaction={data.info}
              logoMerchent={logoMerchent}
              onClickClosePopup={() => setIsShowPopupWalletFacepay("")}
            />
          </Portal>
        )}
      {data.info.paymentInfo?.facepayTrxCode === isShowPopup &&
        isShowPopupWalletFacepay === TRANSFER_TO_BANK_WALLET_FACEPOS && (
          <Portal>
            <PopupDetailWalletTranfer
              reqType={reqType}
              detailsTrans={TRANSACTION}
              dataTransaction={data.info}
              logoMerchent={logoMerchent}
              onClickClosePopup={() => setIsShowPopupWalletFacepay("")}
            />
          </Portal>
        )}
    </WrapTransHistoryContent>
  );
};

export default TransHistoryItem;
