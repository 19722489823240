import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// style
import { WrapWalletFacepay } from './index.styles'
import { TableContent } from '../Bank/index.styles'

// images
import IcTransactionDefault from '../../../../../../assets/images/IcTransactionDefault'

// component
import { headerWalletFacepayTransListData } from '../../../../../../data/headerTransListData'
import EmptyDataControl from '../../../../../Control/EmptyDataControl'
import FilterTransactionWallet from './FilterTransactionWallet'
import TransactionWalletItem from './TransactionWalletItem'

// hook
import useScrollEnd from '../../../../../../hook/useScrollEnd'

// redux
import { getListTransactionFacepayWallet } from '../../../../../../redux/transactionV2/listTransactionWalletFacepay/action'
import { resetTransactionBankCondfilters } from '../../../../../../redux/transactionV2/listTransactionBank/action'
import { resetCondFiltersTransactionFacepay } from '../../../../../../redux/transactionV2/listTransactionFacepayTerminal/action'
import { resetIsCheckSearchStore } from '../../../../../../redux/transactionV2/transactionOther/action'

const WalletFacepay = () => {
  
    const dispatch = useDispatch()
    const listRef = useRef()

    const { listTransactionFacepayWallet, condFiltersTransactionFacepayWallet } = useSelector((state) => ({
      condFiltersTransactionFacepayWallet: state.transactionReducer.listTransactionFacepayWalletReducer.condFiltersTransactionFacepayWallet,
      listTransactionFacepayWallet: state.transactionReducer.listTransactionFacepayWalletReducer.listTransactionFacepayWallet,
    }))

    useEffect(() => {
      dispatch(resetTransactionBankCondfilters())
      dispatch(resetCondFiltersTransactionFacepay())
      dispatch(resetIsCheckSearchStore())
    },[])

    const dataRequest = (condFiltersTransactionFacepayWallet, scroll, listTransactionFacepayWallet) => {
      const checkIsSuccessCheckboxOption = condFiltersTransactionFacepayWallet.OptionSelectCheckbox.reduce((a, v) => ({...a, [v.key] : v.isSelect}), {})
      const checkIsSuccessCheckboxTypeTransaction = condFiltersTransactionFacepayWallet.OptionSelectCheckboxTypeTransaction.reduce((a, v) => ({...a, [v.key] : v.isSelect}), {})
      return {
        ...checkIsSuccessCheckboxOption,
        ...checkIsSuccessCheckboxTypeTransaction,
        limit: condFiltersTransactionFacepayWallet.limit,
        lastItem: scroll ? listTransactionFacepayWallet.at(-1).facepayTrxCode : condFiltersTransactionFacepayWallet.lastItem,
        paymeTxID: condFiltersTransactionFacepayWallet.paymeTxID,
        facepayTrxCode: condFiltersTransactionFacepayWallet.facepayTrxCode,
        phoneNumber: condFiltersTransactionFacepayWallet.phoneNumber,
        profileId: condFiltersTransactionFacepayWallet.profileId,
        begin: condFiltersTransactionFacepayWallet.begin,
        end: condFiltersTransactionFacepayWallet.end,
      }
    }

    useEffect(() => {
      dispatch(getListTransactionFacepayWallet(dataRequest(condFiltersTransactionFacepayWallet, false), false))
    },[condFiltersTransactionFacepayWallet])

    useScrollEnd(listRef, (callback) => {
      dispatch(getListTransactionFacepayWallet(dataRequest(condFiltersTransactionFacepayWallet, true, listTransactionFacepayWallet), true, callback))
    },[listTransactionFacepayWallet],[listTransactionFacepayWallet.length])

    const renderTableContent = useMemo(() => (
        <TableContent>
          <div className="wrap-list-transaction" ref={listRef}>
            <div className="list-transaction">
              {listTransactionFacepayWallet.map((item, index) => (
                <div className="transaction-item" key={index}>
                  <TransactionWalletItem item={item} />
                </div>
              ))}
            </div>
          </div>
        </TableContent>
    ),[listTransactionFacepayWallet])

    return (
        <WrapWalletFacepay>
          <FilterTransactionWallet />
            {
                listTransactionFacepayWallet.length === 0 ?
                <div className="wrap-empty">
                    <EmptyDataControl
                    icon={<IcTransactionDefault />}
                    text="Hiện tại chưa có giao dịch mới"
                    />
                </div>
                :
                <>
                    <div className="wrap-header">
                    {headerWalletFacepayTransListData.map((item, index) => (
                        <div className="header-item" key={index}>{item}</div>
                    ))}
                    </div>
                    {renderTableContent}
                </>
            }
        </WrapWalletFacepay>
    )
}

export default WalletFacepay
