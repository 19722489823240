import React from 'react'

const IcRefuseYellow = (props) => {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={12} cy={12} r={12} fill="#FFBA33" />
            <path d="M12.667 5.33203H8.00033C7.6467 5.33203 7.30756 5.47251 7.05752 5.72256C6.80747 5.9726 6.66699 6.31174 6.66699 6.66536V17.332C6.66699 17.6857 6.80747 18.0248 7.05752 18.2748C7.30756 18.5249 7.6467 18.6654 8.00033 18.6654H16.0003C16.3539 18.6654 16.6931 18.5249 16.9431 18.2748C17.1932 18.0248 17.3337 17.6857 17.3337 17.332V9.9987L12.667 5.33203Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.667 5.33203V9.9987H17.3337" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcRefuseYellow
