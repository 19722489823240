import styled from 'styled-components'

const WrapRejecProfileDetailItem = styled.div`    
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .wrap-container{
        display: flex;
        flex-direction: column;
        .wrap-infos{
            display: flex;
            align-items: center;
            box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
            background: #FFFFFF;
            padding: 16px 20px;
            box-sizing: border-box;
            min-height: 56px;
            .info{
                display: flex;
                align-items: center;
                font-family: "Open Sans";
                font-style: normal;
                color: #313131;
                letter-spacing: -0.01em;
                min-width: 0;
                margin-right: 24px;
                .icon{
                    padding-right: 8px;
                    height: 24px;
                }
                .name {
                    font-weight: normal;
                    font-size: 13.5px;
                    line-height: 18px;
                    white-space: nowrap;
                }
                .value {
                    margin-left: 8px;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 24ch;
                }
            }
        }
        .wrap-list-reason{
            display: flex;
            flex-direction: column;
            background: #FFEEEE;
            padding: 6px 24px;
            .list-reason{
                display: flex;
                margin-bottom: 14px;
                &:last-child{
                    margin-bottom: 0px;
                }
                .icon{
                    padding-top: 2px;
                }
                .text{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #F45C5C;
                    padding-left: 4px;
                }
            }
        }       
        .wrap-details{
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
            padding: 13px 20px 35px 20px;
            box-sizing: border-box;
            min-height: 128px;
            .title{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                color: #313131;
                margin-bottom: 26px;
            }
            .row-detail{
                display: flex;
                align-items: center;
                .row{
                    margin-right: 44px;
                    display: flex;
                    align-items: center;
                    .ic-default{
                        height: 36px;
                        padding-right: 9px;
                    }
                    .detail{
                        display: flex;
                        flex-direction: column;
                        font-family: 'Open Sans';
                        font-style: normal;
                        .value{                        
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 20px;
                            color: #313131;
                            /* white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 24ch; */
                        }
                        .name{
                            font-weight: 400;
                            font-size: 11px;
                            line-height: 15px;
                            color: #777777;
                        }
                    }
                }
            }
        }
    }
`
export {
    WrapRejecProfileDetailItem
} 