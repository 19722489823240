import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//style
import { WrapFaceRecognizeInfo } from './index.styles'

//image
import IcTransPhotoDefault from '../../../../../../assets/images/IcTransPhotoDefault'
import IcTransVerifyVideo from '../../../../../../assets/images/IcTransVerifyVideo'
import IcZoomIn from '../../../../../../assets/images/IcZoomIn'
import IcFacePrintDefault from '../../../../../../assets/images/IcFacePrintDefault'
import IcPlayCircle from '../../../../../../assets/images/IcPlayCircle'
import IcSearchDefault from '../../../../../../assets/images/IcSearchDefault'

//component
import EmptyDataControl from '../../../../../Control/EmptyDataControl'
import Portal from '../../../../../Control/Portal'
import PopupShowMedia, { IMAGE, VIDEO } from '../../TransactionHistory/TransHistoryItem/PopupShowMedia'
import PopupMatchImage from './PopupMatchImage'

const FaceRecognizeInfos = ({ listSameFace, thumbnailUrl, fullFaceUrl }) => {

    const [showMedia, setShowMedia] = useState(null);
    const [isErrAvatar, setIsErrAvatar] = useState(false);
    const [isErrCustomerPhoto, setIsErrCustomerPhoto] = useState(false);
    const [isErrCustomerVideo, setIsErrCustomerVideo] = useState(false);

    useEffect(() => {
        setIsErrCustomerPhoto(false);
    }, [thumbnailUrl]);

    useEffect(() => {
        setIsErrAvatar(false);
    }, [thumbnailUrl]);

    const _handleZoomPhoto = () => {
        if (isErrCustomerPhoto) return;
        setShowMedia({
            type: IMAGE,
            url: fullFaceUrl,
        });
    };

    const _handleZoomVideo = () => {
        // if (isErrCustomerVideo) return;
        // setShowMedia({
        //     type: VIDEO,
        //     url: "",
        // });
    };

    return (
        <WrapFaceRecognizeInfo>
            <div className="face-registration-infos">
                <div className="title">Hình ảnh & video đăng ký</div>
                <div className="face-registration">
                    <div className="wrap-media face-img" onClick={_handleZoomPhoto}>
                        {
                            isErrCustomerPhoto ?
                                <div className="wrapper">
                                    <IcTransPhotoDefault />
                                </div>
                                :
                                <img
                                    src={thumbnailUrl || ""}
                                    alt="face registration"
                                    onError={() => setIsErrCustomerPhoto(true)}
                                />
                        }
                        <div className="tools">
                            <IcZoomIn />
                        </div>
                    </div>
                    <div className="wrap-media recog-video" onClick={_handleZoomVideo}>
                        {
                            isErrCustomerVideo ?
                                <div className="wrapper">
                                    <IcTransVerifyVideo />
                                </div>
                                :
                                <>
                                    <img
                                        src={""}
                                        alt="video registration"
                                        onError={() => setIsErrCustomerVideo(true)}
                                    />
                                    <div className="overlay">
                                        <IcPlayCircle />
                                    </div>
                                </>
                        }
                    </div>
                    {
                        !!showMedia &&
                        <Portal>
                            <PopupShowMedia
                                mediaType={showMedia.type}
                                url={showMedia.url}
                                closePopup={() => setShowMedia(null)}
                            />
                        </Portal>
                    }
                </div>
            </div>
            <div className="match-warning">
                <div className="title">Cảnh báo trùng khớp</div>
                <div className="list-match">
                    {
                        listSameFace?.length === 0 || !listSameFace
                            ?
                            <EmptyDataControl
                                className={"empty-data"}
                                icon={<IcSearchDefault />}
                                text={"Không có hình ảnh nào trùng khớp trong cơ sở dữ liệu."}
                            />
                            :
                            <>
                                {
                                    listSameFace?.map((item, i) => (
                                        <div className="wrap-list-same-face" key={i}>
                                            <div className="wrap-match-info">
                                                <div className="match-info">
                                                    <div className="face-print">
                                                        {
                                                            isErrAvatar ?
                                                                <IcFacePrintDefault className="face" />
                                                                :
                                                                <img
                                                                    className="face"
                                                                    src={item?.thumbnailUrl || ""}
                                                                    alt="facePrint"
                                                                    onError={() => setIsErrAvatar(true)}
                                                                />
                                                        }
                                                    </div>
                                                    <div className="info-print">
                                                        <p className="full-name">{item?.fullName}</p>
                                                        <p className="phone-number">{item?.phone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <PopupMatchImage className="popup-match" data={item} />
                                        </div>
                                    ))
                                }
                            </>
                    }
                </div>
            </div>
        </WrapFaceRecognizeInfo>


    )
}

export default FaceRecognizeInfos
