import React from "react";
import { useSelector } from "react-redux";

//component
import DeviceAdminByStatus from "./DeviceItemByStatus";
import EmptyDataControl from "../../../../../Control/EmptyDataControl";
import IcDeviceDefault from '../../../../../../assets/images/IcDeviceDefault'

// styles
import { WrapDeviceAdminByStatusView } from "./index.styles";

const DeviceAdminByStatusView = () => {

  const { listTempFirebase } = useSelector(state => ({
    listTempFirebase: state.deviceReducer.listDevicesByAdmin.listTempFirebase,
  }))
  
  return (
    <WrapDeviceAdminByStatusView>
      <div className="wrap-list">
        {
          listTempFirebase.length === 0 ? (
            <div className="wrap-empty">
              <EmptyDataControl
                icon={<IcDeviceDefault />}
                text={"Hiện tại chưa có thiết bị mới"}
              />
            </div>
          ) : (
            <div className="wrap-inner-list">
              {listTempFirebase.map((data, index) => (
                <DeviceAdminByStatus key={index} data={data} />
              ))}
            </div>
          )
        }
      </div>
    </WrapDeviceAdminByStatusView>
  );
};

export default DeviceAdminByStatusView;
