import React from 'react'

const IcWarningMini = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00033 12.8346C10.222 12.8346 12.8337 10.223 12.8337 7.0013C12.8337 3.77964 10.222 1.16797 7.00033 1.16797C3.77866 1.16797 1.16699 3.77964 1.16699 7.0013C1.16699 10.223 3.77866 12.8346 7.00033 12.8346Z" fill="#F45C5C" />
            <path d="M7 9.33333V7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 4.66797H7.00583" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcWarningMini
