import styled from 'styled-components'

const WrapPage = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .list-trans-history-total{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 10px;
        .block-total{
            display: flex;
            flex-direction: column;
            font-family: 'Open Sans';
            font-style: normal;            
            .num{                
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                color: #313131;
                padding-bottom: 2px;
                &.percent{
                    color: #33ADFF;
                }
            }
            .text{
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                color: #777777;
            }
        }
    }

`
const WrapListTrasHistory = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 22px 0 10px 0;
    position: relative;
    .wrap-empty {
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
        p{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            color: #B7B7B7;
        }
    }

`

const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
    .wrap-list-trans-history {
        width: calc(100% + 10px);
        flex-grow: 1;
        overflow: overlay;
        position: relative;
        margin-left: 10px;
        .list-trans-history {
            width: calc(100% - 10px);
            display: flex;
            flex-direction: column;
            position: absolute;
            .list{
                position: relative;
                /* .popup-image{
                    position: absolute;
                    top: 75px;
                    right: 0;
                    z-index: 1;
                    display: none;
                }    
                &:hover{                    
                    cursor: pointer;
                    .popup-image{
                        display: block;
                    }
                } */
            }
        }
    }
    
`



export {
    WrapPage, WrapListTrasHistory, TableContent
}