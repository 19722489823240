import React from "react";

// helper
import removeAccent from "../../../../../../../helper/removeAccent"
import trimStartAndStripStr from "../../../../../../../helper/trimStartAndStripStr"
// Control
import { InputFilter } from "../../../../../../Control/FilterControl/index.styles"

// key
import { BUSINESS_ID, BUSINESS_NAME, FULL_NAME, PHONE_NUMBER } from "../../../../../../../constant/keys";

const InputSearchBusiness = ({
  filterCondBusiness,
  valueSearch,
  setValueSearch,
  handleSearch
}) => {

  const { text , key } = filterCondBusiness

  const showContentInputSearch = () => {
    switch (key) {
      case BUSINESS_ID:
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder={`Nhập ${text}`}
            onChange={(e) =>
              setValueSearch(trimStartAndStripStr(removeAccent(e.target.value)))
            }
            onKeyPress={(e) => {
              if(e.key === 'Enter') {
                handleSearch()
              }
            }}
            maxLength={10}
          />
        )
      case PHONE_NUMBER:
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder={`Nhập ${text}`}
            onChange={(e) =>
              setValueSearch(trimStartAndStripStr(removeAccent(e.target.value)))
            }
            onKeyPress={(e) => {
              if(e.key === 'Enter') {
                handleSearch()
              }
            }}
            maxLength={10}
          />
        )
      case FULL_NAME:
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder={`Nhập ${text}`}
            onChange={(e) =>
              setValueSearch(trimStartAndStripStr(e.target.value))
            }
            onKeyPress={(e) => {
              if(e.key === 'Enter') {
                handleSearch()
              }
            }}
            maxLength={70}
          />
        )
      case BUSINESS_NAME:
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder={`Nhập ${text}`}
            onChange={(e) =>
              setValueSearch(trimStartAndStripStr(e.target.value))
            }
            onKeyPress={(e) => {
              if(e.key === 'Enter') {
                handleSearch()
              }
            }}
            maxLength={70}
          />
        )
      default:
        return null
    }
  };

  return <>{showContentInputSearch()}</>;
};

export default InputSearchBusiness
