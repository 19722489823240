import React from 'react'
import DropdownControl from '../../../../../../../Control/DropdownControl'

// component


export const PHONE_NUMBER = "PHONE_NUMBER"
export const DOCUMENT_ID = "DOCUMENT_ID"

export const dataSelectionCensor = [
    {
        text: "Số điện thoại",
        key: PHONE_NUMBER
    },
    {
        text: "Document ID",
        key: DOCUMENT_ID
    },
]

const DropdownSelectSearchConsorship = ({filterCond: { text, key }, setFilterCond }) => {

    const _handleSetValueSelect = (val) => {
        setFilterCond(val)
    }

    return (
        <DropdownControl 
            dataSelection={dataSelectionCensor}
            valueSelected={{ text, key }}
            handleSetValueSelect={_handleSetValueSelect}
        />
    )
}

export default DropdownSelectSearchConsorship
