import React from 'react'

const IcBusinessPhone = (props) => {
    return (
        <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width={32} height={32} rx={16} fill="#EFF9FF" />
            <path d="M23.5 20.19V22.44C23.5009 22.6488 23.4581 22.8556 23.3744 23.047C23.2907 23.2383 23.168 23.4101 23.0141 23.5513C22.8602 23.6926 22.6784 23.8001 22.4806 23.867C22.2827 23.9339 22.073 23.9588 21.865 23.94C19.5571 23.6892 17.3403 22.9006 15.3925 21.6375C13.5804 20.4859 12.044 18.9496 10.8925 17.1375C9.62499 15.1809 8.83619 12.9532 8.59001 10.635C8.57127 10.4276 8.59592 10.2185 8.66239 10.0212C8.72886 9.82382 8.83569 9.64247 8.97609 9.48867C9.11648 9.33487 9.28736 9.21198 9.47785 9.12784C9.66834 9.0437 9.87427 9.00015 10.0825 8.99995H12.3325C12.6965 8.99637 13.0494 9.12526 13.3253 9.3626C13.6013 9.59994 13.7816 9.92954 13.8325 10.29C13.9275 11.01 14.1036 11.717 14.3575 12.3975C14.4584 12.6659 14.4803 12.9576 14.4204 13.2381C14.3606 13.5186 14.2217 13.776 14.02 13.98L13.0675 14.9325C14.1352 16.8101 15.6899 18.3648 17.5675 19.4325L18.52 18.48C18.7239 18.2783 18.9814 18.1393 19.2619 18.0795C19.5423 18.0197 19.8341 18.0415 20.1025 18.1425C20.783 18.3964 21.49 18.5725 22.21 18.6675C22.5743 18.7188 22.9071 18.9024 23.1449 19.1831C23.3828 19.4638 23.5091 19.8221 23.5 20.19Z" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBusinessPhone