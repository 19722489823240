import styled from "styled-components";

const WrapTransaction = styled.div`
    height: 100%;
    overflow: hidden;
    .list-menu{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        background: #FFFFFF;
        .total-transaction{
            display: flex;
            align-items: center;
            gap: 14px;
            .text{
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                display: flex;
                align-items: center;
                gap: 6px;
                color: #000000;
                .bold{
                    font-weight: 600;
                    color: #313131;
                }
                .blue{
                    font-weight: 600;
                    color: #33ADFF;
                }
                .green{
                    font-weight: 600;
                    color: #15BA39;
                }
                .red{
                    font-weight: 600;
                    color: #F45C5C;
                }
            }
            
        }
    }
`

export {
    WrapTransaction
}