import styled from "styled-components"

const WrapBusinessWaiting = styled.div`
    height: calc(100% - 82px);


`
const WrapPageItem = styled.div`
    min-width: calc(1280px - 100px);
    box-sizing: border-box;
    
`


const WrapWaitingListBusiness = styled(WrapPageItem)`
    height: 100%;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 30px; */
    .wrap-page-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .title-header{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            color: #313131;
            padding: 22px 0 23px 8px;
        }
        .list-note{
            display: flex;
            align-items: center;
            .note{
                display: flex;
                align-items: center;
                padding-left: 16px;
                svg{
                    width: 18px;
                    height: 18px;
                }
                p{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #313131;
                    padding-left: 4px;
                }
            }
        }
    }
`

const TableWaitingListBusiness = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 30px;
    .wrap-empty {
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
        p{
            color: #B7B7B7;
            font-size: 13.5px;
        }
    }
`

const TableHeaderBusiness = styled.div`    
    display: grid;
    grid-template-columns: minmax(120px,150px) minmax(120px,1fr) minmax(150px,1fr) minmax(100px,180px) minmax(120px,180px) minmax(130px,185px) 120px 150px;
    grid-gap: 10px;
    padding: 0px 12px 0px 14px;
    box-sizing: border-box;
    width: 100%;

    .table-header {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #313131;
    }

`

const TableContentBusiness = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;    
   
    .wrap-list-waiting {
        width: calc(100% + 10px);
        flex-grow: 1;
        margin-top: 18px;
        margin-left: 10px;
        position: relative;
        // overflow: overlay;
        
        .list-waiting {
            width: calc(100% - 10px);
            display: flex;
            flex-direction: column;
            position: absolute;

            /* min-width: 1120px; */
        }
    }
`

export {
    WrapBusinessWaiting,
    WrapWaitingListBusiness,
    TableWaitingListBusiness,
    TableHeaderBusiness,
    TableContentBusiness
}