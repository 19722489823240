import React from 'react'

//styles
import * as styles from './index.styles'

//component
import ControlBlock from './ControlBlock'

export default function CustomerNote() {
  return (
    <styles.Bound>
        <p className="title">Ghi chú khách hàng</p>
        <div className='block note'>
          <ControlBlock />          
        </div>
    </styles.Bound>
  )
}
