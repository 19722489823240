import styled from "styled-components";
import { WrapTransHistoryDetailsItem } from "../index.styles";

const WrapCustomerTransHistoryDetailsItem = styled(WrapTransHistoryDetailsItem)`

    .ic-note{
        cursor: pointer;
    }

`

export {
    WrapCustomerTransHistoryDetailsItem
}