import React from 'react'

const IcSGBMini = (props) => {
  return (
    <svg {...props} width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6503 0C11.7513 0.0198699 11.8529 0.037597 11.9534 0.0601941C12.3082 0.139479 12.6126 0.310711 12.8693 0.566877C16.0688 3.76223 19.2684 6.95758 22.4671 10.1537C23.2188 10.9047 23.2081 12.1169 22.4431 12.8819C21.0995 14.2259 19.7542 15.5681 18.4098 16.911C16.5757 18.743 14.7412 20.5745 12.9077 22.407C12.5114 22.803 12.0367 23.0103 11.4748 22.9996C10.9544 22.9897 10.509 22.7951 10.1409 22.4275C7.74103 20.031 5.34171 17.6341 2.9422 15.2375C2.15621 14.4524 1.37002 13.6674 0.584032 12.8821C-0.195522 12.1031 -0.194547 10.907 0.586177 10.1272C3.77168 6.9455 6.95738 3.76379 10.1427 0.581682C10.4645 0.260257 10.8479 0.0699343 11.3014 0.0216231C11.3271 0.0188959 11.3519 0.00740251 11.3771 0C11.4681 0 11.5592 0 11.6503 0ZM11.5294 22.3137C11.8621 22.3079 12.1592 22.1836 12.4051 21.9379C15.3195 19.0268 18.2341 16.1159 21.1485 13.2047C21.4243 12.9293 21.703 12.6565 21.9751 12.3774C22.2409 12.1047 22.3593 11.7762 22.3273 11.3942C22.3004 11.0716 22.1572 10.8084 21.931 10.5827C18.7656 7.4218 15.6005 4.26034 12.4355 1.09908C12.4195 1.0831 12.4035 1.06674 12.387 1.05116C12.1506 0.828497 11.8717 0.704018 11.5456 0.696615C11.175 0.688239 10.8637 0.826354 10.6026 1.08739C7.42917 4.2582 4.25478 7.42842 1.08059 10.599C1.06674 10.6129 1.05251 10.6261 1.03944 10.6405C0.782967 10.9207 0.663606 11.2469 0.699688 11.6286C0.730503 11.9551 0.88341 12.2155 1.1116 12.4432C4.26843 15.5948 7.42429 18.7473 10.5805 21.8996C10.608 21.927 10.6361 21.9541 10.665 21.9804C10.9055 22.1992 11.1887 22.31 11.5294 22.3137Z" fill="#0064B1"/>
        <path d="M16.1912 8.3299C15.9695 8.19977 15.7656 8.07393 15.5562 7.95822C14.7856 7.53218 13.9739 7.21232 13.1095 7.03368C12.3202 6.87063 11.5326 6.87706 10.7445 7.05336C10.1572 7.18466 9.58245 7.34965 9.03986 7.61303C8.11072 8.064 7.38928 8.72107 6.99356 9.6945C6.8465 10.0562 6.73748 10.4295 6.73065 10.8238C6.72051 11.4167 6.91223 11.9519 7.22506 12.4461C7.72533 13.2362 8.41517 13.8237 9.23295 14.2611C9.53447 14.4224 9.85959 14.5406 10.1759 14.6733C10.2399 14.7002 10.2705 14.7171 10.2426 14.796C10.1061 15.1835 9.97622 15.5731 9.83931 15.9761C9.63862 15.8949 9.4432 15.8193 9.2505 15.7371C8.23808 15.3058 7.26739 14.7989 6.38641 14.1339C5.89941 13.7661 5.45746 13.3527 5.10972 12.8456C4.8078 12.4054 4.66289 11.9073 4.55699 11.3938C4.48248 11.033 4.45596 10.667 4.48151 10.3005C4.53261 9.56826 4.78888 8.9108 5.25307 8.34178C5.94993 7.48738 6.84046 6.90531 7.87141 6.53168C8.48674 6.30863 9.12353 6.17071 9.77573 6.12279C10.2577 6.08753 10.7421 6.06064 11.2248 6.0698C11.9909 6.08421 12.75 6.1859 13.5009 6.34135C14.3881 6.52505 15.2527 6.7857 16.0974 7.11297C16.1635 7.13849 16.1941 7.16751 16.1932 7.24388C16.1889 7.60095 16.1912 7.95783 16.1912 8.3299Z" fill="#0064B1"/>
        <path d="M19.2243 12.1857C19.1113 12.4161 18.9881 12.6306 18.8999 12.8587C18.8186 13.0695 18.6908 13.1266 18.4697 13.1328C17.805 13.1515 17.1411 13.2023 16.4768 13.2386C16.0717 13.2608 15.6664 13.2779 15.2614 13.2999C14.7073 13.3299 14.1534 13.3633 13.5991 13.3936C13.4013 13.4046 13.2034 13.4127 13.0054 13.418C12.9276 13.4199 12.8853 13.4466 12.8585 13.5255C12.3963 14.8829 11.9304 16.2391 11.4679 17.5965C11.4399 17.6789 11.4053 17.7136 11.3119 17.7124C10.8407 17.707 10.3695 17.7101 9.8983 17.7097C9.86339 17.7097 9.82848 17.7062 9.77563 17.7033C10.2412 16.3194 10.7024 14.9476 11.1625 13.5801C10.9876 13.5801 10.8235 13.5746 10.6601 13.5812C10.3683 13.5931 10.077 13.6138 9.78518 13.6268C9.75983 13.628 9.7218 13.6073 9.70893 13.5855C9.44465 13.1314 9.18331 12.6756 8.92157 12.2201C8.91728 12.2126 8.91728 12.2024 8.91357 12.1857C12.3468 12.1857 15.7764 12.1857 19.2243 12.1857Z" fill="#0064B1"/>
        <path d="M10.4627 11.4475C9.51443 11.4637 8.58684 11.4796 7.64404 11.4958C7.66316 11.4607 7.67291 11.4379 7.68676 11.4181C7.90754 11.1042 8.13729 10.7982 8.43374 10.5491C8.56246 10.4409 8.70289 10.3741 8.87764 10.3846C8.9734 10.3905 9.07053 10.3745 9.17838 10.3677C9.17838 10.2364 9.18209 10.1137 9.17682 9.99134C9.1739 9.92433 9.19808 9.89842 9.26108 9.87543C9.52866 9.77764 9.79313 9.67128 10.0592 9.56901C10.1329 9.54056 10.16 9.49206 10.1551 9.4118C10.1481 9.29531 10.1483 9.17784 10.1549 9.06135C10.1596 8.98031 10.1251 8.96141 10.0527 8.96239C9.83526 8.96492 9.6178 8.96336 9.3857 8.96336C9.40287 8.93453 9.40989 8.91388 9.42393 8.90083C9.62618 8.70875 9.81654 8.50187 10.0346 8.32986C10.48 7.97844 10.983 7.71974 11.4975 7.48344C11.5535 7.45773 11.5956 7.45851 11.6469 7.49961C11.9894 7.77331 12.3335 8.04526 12.6804 8.3133C12.7514 8.36804 12.7818 8.42415 12.7797 8.51571C12.7733 8.79798 12.7805 9.08063 12.7754 9.3629C12.7739 9.44803 12.8 9.48738 12.8833 9.51466C13.641 9.76264 14.3946 10.0241 15.1556 10.2611C15.375 10.3295 15.4538 10.4676 15.4844 10.6646C15.5139 10.8535 15.511 10.858 15.6978 10.8828C15.9003 10.9094 16.1039 10.9276 16.3069 10.9498C16.3225 10.9515 16.3379 10.9562 16.3592 10.9609C16.3592 11.114 16.3592 11.2648 16.3592 11.4298C16.3178 11.4298 16.28 11.4298 16.2424 11.4298C14.8381 11.4294 13.4338 11.4292 12.0296 11.4282C11.9844 11.4282 11.9391 11.4224 11.8937 11.4191C11.7244 11.4068 11.7236 11.4066 11.725 11.2404C11.7267 11.0294 11.7285 10.8185 11.7331 10.6075C11.7345 10.5457 11.723 10.5134 11.6493 10.5126C11.2889 10.5091 10.9282 10.5023 10.5678 10.4922C10.4888 10.49 10.4588 10.5113 10.4602 10.5941C10.4652 10.8728 10.4627 11.1518 10.4627 11.4475Z" fill="#0064B1"/>
        <path d="M12.449 16.0975C12.4579 15.9962 12.465 15.9006 12.4751 15.8051C12.4989 15.5831 12.5221 15.361 12.5514 15.1397C12.5551 15.1124 12.5929 15.0707 12.6187 15.0678C13.2718 14.993 13.8903 14.7964 14.4918 14.5401C15.0611 14.2974 15.5941 13.9903 16.099 13.6337C16.1246 13.6155 16.1511 13.5988 16.1886 13.5739C16.173 13.7447 16.1595 13.9017 16.1443 14.0587C16.1205 14.3069 16.093 14.5547 16.0729 14.8031C16.0657 14.8931 16.0405 14.9579 15.9573 15.0088C15.1055 15.5266 14.1924 15.8784 13.2049 16.0282C12.9842 16.0617 12.7608 16.079 12.5385 16.1026C12.5137 16.1049 12.4882 16.0999 12.449 16.0975Z" fill="#0064B1"/>
    </svg>
    
  )
}

export default IcSGBMini