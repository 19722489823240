import React, { useRef, useEffect, useState } from 'react'
import axios from 'axios'

// component
import IcCloseWhite from '../../../../../../../assets/images/IcCloseWhite'

//hook
import { useOnClickOutside } from '../../../../../../../hook/useClickOutside'

// styles
import { OverlayFullScreen, WrapPopupShowMedia } from './index.styles'

export const VIDEO = "VIDEO"
export const IMAGE = "IMAGE"

const PopupShowMedia = ({ mediaType, url, closePopup }) => {

    const refDropdown = useRef(null)
    const [mediaLink, setMediaLink] = useState("");

    useOnClickOutside(refDropdown, () => {
        closePopup()
    })

    window.addEventListener("keydown",(e)=>{
        if (e.key === "Escape"){
            closePopup()
        }
    })

    useEffect(() => {
        switch (mediaType) {
            case VIDEO:{
                const requestGetMedia = async () => {
                    try {
                        const res = await axios.get(url, { 
                            responseType: 'blob'
                        })
                        const { data } = res
                        // console.log(data)
                        const mediaLink = URL.createObjectURL(data)
                        setMediaLink(mediaLink)
                    }
                    catch(err) {
                        console.log(err)
                    }
                }
                requestGetMedia()
            }
                break;
            case IMAGE: {
                setMediaLink(url)
            }
            default:
                break;
        }
        
    }, [url]);

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(mediaLink)
        }
    }, []);

    return (
        <OverlayFullScreen >
            <div className="wrap-inner-screen" >
                <WrapPopupShowMedia ref={refDropdown}>
                    <IcCloseWhite className="ic-close" onClick={closePopup} />
                    {
                        !!mediaLink && 
                        (
                            mediaType === VIDEO
                            ? (
                                <video controls>
                                    <source src={mediaLink} />
                                </video>
                            )
                            : (
                                <img src={mediaLink} alt="face-recognize" />
                            )
                        )
                    }
                </WrapPopupShowMedia>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupShowMedia
