import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'


const apiGetDetailTransaction = `${host}/transaction/detail`
const apiRequestRefund = `${host}/transaction/requestRefund`
const apiGetReasonRefund = (supplier) => `${host}/transaction/requestRefund?q=reason&supplier=${supplier}`

export const getDetailTransaction = ({ id }, callbackSuccess) => async dispatch => {
   
    const dataRequest = JSON.stringify({ id });

    try {
        const res = await Axios.post(apiGetDetailTransaction, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_DETAIL_TRANSACTION,
                payload: {
                    details: {
                        ...data
                    }
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_DETAIL_TRANSACTION_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_DETAIL_TRANSACTION_ERR))
    }  
}

export const requestRefund = ({ 
    supplier,
    trxCode,
    remark,
    remarkCode,
}, callbackSuccess) => async dispatch => {

    const dataRequest = JSON.stringify({ 
        supplier,
        trxCode,
        remark,
        remarkCode,
        terminalId: "",
        channel:"WEB",
        clientIP:"",
        language:"vi",
        mac:""
    });

    try {
        const res = await Axios.post(apiRequestRefund, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.REQUEST_REFUND,
                payload: {
                    details: {
                        ...data
                    }
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.REQUEST_REFUND_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.REQUEST_REFUND_ERR))
    }  
}

export const getReasonRefund = (supplier, callbackSuccess) => async dispatch => {
    try {
        const res = await Axios.get(apiGetReasonRefund(supplier));
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_REASON_REFUND,
                payload: {
                    refundReasons: data.refundReasons || []
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_REASON_REFUND_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_REASON_REFUND_ERR))
    } 
}

export const clearReasonRefund = () => ({
    type: types.CLEAR_REASON_REFUND
})

export const clearDetailTransaction = () => ({
    type: types.CLEAR_TRANSACTION_DETAILS
})
