import React from 'react'
import InputDropdown from '../../../../../../../../../../../Control/InputDropdown'


const InputDropdownGender = ({ valueDropdown , objChoice, listDropDown, className}) => {
    const _handleSetValueDropdown = (val) =>{
        valueDropdown && valueDropdown(val)
    }

    return (
        <InputDropdown 
            getValueDropdown={_handleSetValueDropdown}
            dataFilterOption={listDropDown}
            objCategory={listDropDown.find((item) => item.key === objChoice)}
            className={className}
        />
    )
}

export default InputDropdownGender
