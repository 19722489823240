import styled from 'styled-components'
const Bound = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    
    .icon {
        flex-shrink: 0;
    }
    
    & > .text {
        font-family: "Open Sans";
        font-style: normal;        
        font-weight: normal;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #191919;
    }

    ${props => props.extendStyles}
`
export { Bound }