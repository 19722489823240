import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-grow: 1;
    padding: 16px 0 22px 0;
    flex-direction: column;
    .info-expand{
        display: flex;
        padding: 14px 20px 0 20px;
        flex-grow: 1;
        flex-direction: column;
    }
`

export {
    Bound
}