import React from 'react'

const IcTimeMini = (props) => {
    return (
        <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6071_67024)">
                <circle cx={16} cy="16.5" r={16} fill="#EFF9FF" />
                <path d="M16.0009 24.0564C20.1737 24.0564 23.5564 20.6737 23.5564 16.5009C23.5564 12.328 20.1737 8.94531 16.0009 8.94531C11.828 8.94531 8.44531 12.328 8.44531 16.5009C8.44531 20.6737 11.828 24.0564 16.0009 24.0564Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 11.9688V16.5021L19.0222 18.0132" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_6071_67024">
                    <rect width={32} height={32} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcTimeMini
