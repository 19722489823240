const resetListFilterBank = (listProviders) => {
    let listDefault = [...listProviders];
    listDefault = listProviders
        .filter((item) => {
            return (item.isSelect = true);
        })
        .map((item) => item.type)
    return listDefault
};

export default resetListFilterBank