import React from 'react'

const IcMissCall = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={10} cy={10} r={10} fill="#F45C5C" />
            <g clipPath="url(#clip0_6189_18400)">
                <path d="M16.1107 3.88672L12.7773 7.22005" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.7773 3.88672L16.1107 7.22005" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.5548 12.7303V14.397C15.5554 14.5517 15.5237 14.7049 15.4618 14.8467C15.3998 14.9884 15.3089 15.1157 15.1949 15.2203C15.0808 15.3249 14.9462 15.4045 14.7997 15.4541C14.6531 15.5036 14.4978 15.5221 14.3437 15.5081C12.6342 15.3224 10.992 14.7382 9.54926 13.8026C8.20694 12.9496 7.06889 11.8116 6.21592 10.4692C5.27702 9.01991 4.69273 7.36979 4.51037 5.65257C4.49649 5.49894 4.51474 5.3441 4.56398 5.19792C4.61322 5.05173 4.69235 4.9174 4.79635 4.80347C4.90035 4.68955 5.02693 4.59852 5.16803 4.53619C5.30913 4.47387 5.46167 4.44161 5.61592 4.44146H7.28259C7.55221 4.43881 7.81359 4.53428 8.01801 4.71009C8.22244 4.8859 8.35597 5.13004 8.3937 5.39702C8.46405 5.93039 8.59451 6.45409 8.78259 6.95813C8.85734 7.15697 8.87351 7.37308 8.82921 7.58084C8.7849 7.7886 8.68196 7.9793 8.53259 8.13035L7.82704 8.8359C8.6179 10.2268 9.76951 11.3784 11.1604 12.1692L11.8659 11.4637C12.017 11.3143 12.2077 11.2114 12.4154 11.1671C12.6232 11.1228 12.8393 11.1389 13.0381 11.2137C13.5422 11.4018 14.0659 11.5322 14.5993 11.6026C14.8691 11.6406 15.1156 11.7766 15.2918 11.9845C15.468 12.1925 15.5616 12.4579 15.5548 12.7303Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_6189_18400">
                    <rect width="13.3333" height="13.3333" fill="white" transform="translate(3.33301 3.33203)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcMissCall
