import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

//style
import { WrapDetail } from './index.styles'

//component
// import PageHeader from '../../PageHeader'
import RejecProfileDetailItem from './RejecProfileDetailItem'

//mockData
import { detaisRejectionProfile } from '../../../../../../data/dataRejectionProfile'

//action
import { getDetailsCensorship, getListSameFace, resetDetailCensorship, resetListSameFace } from '../../../../../../redux/censorship/detailCensorship/action'

//constant
import * as path from "../../../../../../constant/path";
import * as userStatus from '../../../../../../constant/userStatus'
import { resetListRejectProfile, resetRejectCondsFilter } from '../../../../../../redux/censorship/listRejectProfile/action'

const RejectionProfileDetail = () => {

    const { censorshipId } = useParams();

    const dispatch = useDispatch()
    const history = useHistory()

    const { details } = useSelector(state => ({
        details: state.censorshipReducer.detailCensorshipReducer.details,
    }))

    useEffect(() => {
        dispatch(resetListRejectProfile())
        dispatch(resetRejectCondsFilter())
    }, [])

    useEffect(() => {
        if (!details) return
        if (!details.user) return
        if (![userStatus.REJECTED].includes(details.user.status)) {
            history.push(`/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}`)
        }
        return () => {

        }
    }, [details])

    useEffect(() => {
        dispatch(getDetailsCensorship({ uid: censorshipId }))
        return () => {
            dispatch(resetDetailCensorship())
        }
    }, [])

    return (
        <WrapDetail>
            <div className="wrap-list-censorship" >
                <div className="list-censorship">
                    {
                        details?.rejectHistories?.map((item, i) => {
                            const index = details?.rejectHistories.length - i
                            return (
                                <div className="info-censorship" key={i}>
                                    <p className="title-detail">Thông tin kiểm duyệt lần {index}</p>
                                    <RejecProfileDetailItem data={item} index={index} />
                                </div>
                            )
                        }
                        )
                    }
                </div>
            </div>
        </WrapDetail>
    )
}

export default RejectionProfileDetail
