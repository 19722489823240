import styled from 'styled-components'

const WrapFilterFacepayBlock = styled.div`
    display: flex;
    flex-direction: column;
    /* height: 85px; */
    /* padding: 0 20px; */
    margin-bottom: 13px;
`

const WrapFilterSearch = styled.div`
    display: flex;
    align-items: center;
    .filter-search{
        width: 100%;
        height: 40px;
    }
    .icon-export{
        cursor: pointer;
        margin-left: 15px;
    }

`

const WrapCheckBox = styled.div`
    display: flex;
    align-items: center;
    margin: 15px 0 0 15px;
    .info-check{
        margin-right: 25px;
    }
`

export {
    WrapFilterFacepayBlock,
    WrapFilterSearch,
    WrapCheckBox
}