import * as types from './types'
import moment from 'moment'
import { dataSwiftCodeBank } from '../../../constant/dataSwiftCodeBank'


const date = new Date()
export const startDay = moment('1/1/2022').startOf('day').toDate()
export const currentDay = moment(date).endOf("day").toDate()

const initialState = {
    list: [],
    condFilters: {
        bioId: "",
        uid: "",
        supplier: ["*"],
        facepayTrxCode: "",
        begin: startDay,
        end: currentDay,
        isSuccess: true,
        isFail: true,
        limit: 15,
        skip: 0
    },
    total: 0,
    totalSuccess: 0,
    isScroll: false
}

const customerTransactionHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_TRANSACTION_BANK_OF_CUSTOMER:
        case types.GET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER: {
            const { list, total, ...payload } = action.payload
            return {
                ...state,
                ...action.payload,
                total: total,
                list: action.payload.isScroll
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list
            }
        }
        case types.RESET_LIST_TRANSACTION_OF_CUSTOMER:
        case types.RESET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER: {
            return {
                ...state,
                list: [],
                total: 0,
                totalSuccess: 0,
            }
        }
        case types.UPDATE_LIST_TRANSACTION_OF_CUSTOMER_CONDFILTERS:{
            return {
                ...state,
                condFilters: {
                    ...state.condFilters,
                    ...action.condFilters,
                },
                isScroll: action.isScroll
            }}
        case types.RESET_LIST_TRANSACTION_OF_CUSTOMER_CONDFILTERS:
            return {
                ...state,
                condFilters: initialState.condFilters,
                isScroll: false
            }
        default:
            return state;
    }

}

export default customerTransactionHistoryReducer