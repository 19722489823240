import React from 'react'

const IcDataNew = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#33ADFF"/>
        <path d="M7.22266 12.7782L12.7782 7.22266" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.22266 7.22266H12.7782V12.7782" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcDataNew
