import styled from 'styled-components'

const WrapPopupAttachNote = styled.div`
    width: 380px;
    min-height: 230px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 8px 12px 12px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;

    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    position: ${props => props.isStylePopup ? 'absolute' : "" };
    top: 20px;
    
    .icon-close{
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }
    .title-popup{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #313131;
    }
    .block-content{
        width: 100%;
        height: 66px;
        border-radius: 4px;
        margin: 7px 0 14px 0;
        padding: 8px 14px 8px 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        &.success{
            background: #EFF9FF;
        }
        &.fail{
            background: #FFEEEE;
        }    
    }
    .block-content-backlog{
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 4px;
        margin: 7px 0 14px 0;
        padding: 8px 14px 8px 8px;
        box-sizing: border-box;
        &.gray{
            background: rgba(247, 247, 247, 0.5);
        }
        &.red{
            background: #FFEEEE;
        }
        &.blue{
            background: #EFF9FF;
        }

        .gray{
            border-left: 3px solid #777777;
        }
        .red{
            border-left: 3px solid #F45C5C;
        }
        .blue{
            border-left: 3px solid #33ADFF;
        }
    }
    .wrap-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: 'Open Sans';
        font-style: normal;
        align-items:flex-start;
        &.success{
            border-left: 3px solid #33ADFF;
        }
        &.fail{
            border-left:  3px solid #F45C5C
        }
        &.right{
            align-items: flex-end;
            margin: 4px 0 0 0;
        }
        &.right-backlog{
            justify-content: flex-end;
            align-items: flex-end;
            margin: 4px 0 0 0;
        }
        .content{
            display: flex;
            align-items: center;
            margin: 4px 0 8px 8px;
        }
        .value{
            font-family: 'Open Sans';
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #777777;
            padding: 0 0 5px 8px;
            &.bold{
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                color: #313131;
                padding: 0 0 0 4px;
            }
        }
        .content{
            .text{
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
                padding: 0 0 0 4px;
                .value{
                    font-weight: 600;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #313131;
                    padding-left: 0;
                }
            }
            .icon{
                width: 18px;
                height: 18px;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &.backlog-time-value{
            flex-direction: row;
            .value-backlog{
                font-family: 'Open Sans';
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                color: #777777;
                padding: 0 0 5px 8px;
            }
        }
    }
    .wrap-text-note{
        width: 100%;
        height: 100%;
        textarea{
            width: 100%;
            height: 55px;
            background: #FFFFFF;
            border-radius: 4px;
            outline: unset;
            border: none;
            resize: none;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            color: #313131;
            &::placeholder{
                color: #B7B7B7;
            }
        }
    }
    .wrap-btn{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .note{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #777777;
        }
        .btn-note{
            cursor: pointer;
        }
    }
    

`

const WrapPopupAttachNoteTransaction = styled.div`
    position: absolute;
    width: 340px;
    height: 130px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 10;
    padding: 12px;
    top: 20px;
    right: 0;
    .wrap-note-transaction{
        display: flex;
        flex-direction: column;
        gap: 13px;
        .note-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon-close-transaction{
                cursor: pointer;
            }
        }
        .note-content {
            width: 340px;
            height: 100px;
            textarea{
                width: calc(100% - 45px);
                height: calc(100px - 41px);
                position: absolute;
                font-family: 'Open Sans';
                background: #FFFFFF;
                border-radius: 4px;
                border: 0.5px solid #B7B7B7;
                padding: 10px 10px 30px 10px;
                outline: unset;
                resize: none;

            }
            .wrap-btn-transaction{
                position: absolute;
                right: 24px;
                bottom: 20px;
                cursor: pointer;
            }
        }
    
}


`


export { WrapPopupAttachNote, WrapPopupAttachNoteTransaction }